<template>
    <div class="game-machine-component-left">
        <!-- cock -->
        <CockResult :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 16"/>
        <!-- klaklork -->
        <KlakloukResult :sResultKk="sResultKk" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 5"/>
        <!-- baccarat -->
        <BaccaratResult :scanPlayer="scanPlayer" :scanBanker="scanBanker" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 6 || channelType == 7"/>
        <!-- bac_cock -->
        <BaccaratCockResult :scanPlayer="scanPlayer" :scanBanker="scanBanker" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 18"/>
        <!-- dragon_tiger -->
        <DragonTigerResult :scanPlayer="scanPlayer" :scanBanker="scanBanker" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 8 || channelType == 9"/>
        <!-- dt_cock -->
        <DragonTigerCockResult :scanPlayer="scanPlayer" :scanBanker="scanBanker"  :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 19"/>
        <!-- lotto -->
        <LottoResult :scanResult="scanResult" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 10"/>
        <!-- yuki -->
        <YukiResult :scanResult="scanResult" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 11"/>
        <!-- apong -->
        <ApongResult :scanResult="scanResult" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 12 || channelType == 17"/>
        <!-- fantan -->
        <FantanResult :scanResult="scanResult" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 13"/>
        <!-- taisiev -->
        <TaisievResult :sResultKk="sResultKk" :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 14"/>
        <!-- ham2 -->
        <HanResult :updateChInfo="updateChInfo" :passUrl="passUrl" :passChDesc="passChDesc" :passIndex="passIndex"  :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 20 || channelType == 21"/>
    </div>
</template>
<script>
import CockResult from "../GameResult/CockResult.vue";
import KlakloukResult from "../GameResult/KlakloukResult.vue";
import BaccaratResult from "../GameResult/BaccaratResult.vue";
import BaccaratCockResult from "../GameResult/CockResult.vue";
import DragonTigerResult from "../GameResult/DragonTigerResult.vue";
import DragonTigerCockResult from "../GameResult/DragonTigerResult.vue";
import LottoResult from "../GameResult/LottoResult.vue";
import YukiResult from "../GameResult/YukiResult.vue";
import ApongResult from "../GameResult/ApongResult.vue";
import FantanResult from "../GameResult/FantanResult.vue";
import TaisievResult from "../GameResult/TaisievResult.vue";
import HanResult from "../GameResult/HanResult.vue";

export default {
  components: {
    DragonTigerResult,
    BaccaratResult,
    BaccaratCockResult,
    DragonTigerCockResult,
    LottoResult,
    YukiResult,
    ApongResult,
    FantanResult,
    TaisievResult,
    HanResult,
    KlakloukResult,
    CockResult
  },
  props:['updateChInfo', 'sResultKk', 'scanPlayer', 'scanBanker', 'passUrl', 'passGame' , 'passIndex', 'channelType', 'resultObjS', 'passChDesc' , 'totalB', 'totalP', 'scanResult']
}
</script>
<style>
  .v-application .pb-4{
    margin-bottom: 0 !important;
  }
  .trend-legend-custome{
    padding: 6px !important;
  }
  /* p.count-label{
    font-size: smaller;
  } */
  .v-icon .v-icon{
    font-size: 12px !important
  }
  .v-application .pb-3{
    margin-bottom: 0 !important;
  }
</style>