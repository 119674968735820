<template>
    <div>
        <CockFightReportComponent v-if="_selectedGame(channelId) == 'cock'"
            :passLoadingTodayReport="passLoadingTodayReport" />
        <KlakloukReportBetting v-if="_selectedGame(channelId) == 'klaklouk'"
            :passLoadingTodayReport="passLoadingTodayReport" />
        <DragonTigerLiveReportComponent v-if="_selectedGame(channelId) == 'tiger-dragon'"
            :passLoadingTodayReport="passLoadingTodayReport" />
    </div>
</template>

<script>

import CockFightReportComponent from "./_ReportBettingComponent/CockFightReportComponent.vue";
import KlakloukReportBetting from "./_ReportBettingComponent/KlakloukReportBetting.vue";
import DragonTigerLiveReportComponent from "./_ReportBettingComponent/DragonTigerLiveReportComponent.vue";
import Mixin from "../../../../mixin/MX_ShareFunctionCollection.js";

import { mapGetters } from "vuex";

export default {
    components: {
        CockFightReportComponent,
        KlakloukReportBetting,
        DragonTigerLiveReportComponent
    },
    props: ['passLoadingTodayReport', 'passSelectedChannel'],
    data() {
        return {
            mixins: [Mixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            channelId: "getChannelId",
        }),
    },
}
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(40, 53, 89, .8);
    border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(78, 106, 181, .2)
}

@keyframes rotate {

    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }

}


.refresh-start {

    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;

}

.text-meron {
    color: #f93c3c !important
}

.txt-white {
    color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
    color: #FFC008
}

.txt-grey {
    color: #6C757D
}
</style>