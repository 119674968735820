var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"ma-0 w-100"},[_c('div',{staticClass:"cock-three-section machine-blue-title machine-play-blue-bg",class:_vm.getSelectedCockBtn && _vm.getSelectedCockBtn.button 
      && _vm.getSelectedCockBtn.button.name == _vm.passBetType[2].name 
      && _vm.getSelectedCockBtn.channelType == _vm.passChannelType ?'machine-game-active':'',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[2])}}},[_c('p',{staticClass:"machine-payout-name cock-casino-button-font"},[_vm._v(_vm._s(_vm.passBetType ? _vm.passBetType[2].payout : 'x.xx'))]),_c('p',{staticClass:"machine-button-name cock-casino-button-font white--text"},[_vm._v(_vm._s(_vm.passBetType[2] ? _vm.passBetType[2].name : 'Meron'))])]),_c('v-spacer'),_c('div',{staticClass:"cock-three-section machine-green-title machine-play-green-bg",class:_vm.getSelectedCockBtn && _vm.getSelectedCockBtn.button 
      && _vm.getSelectedCockBtn.button.name == _vm.passBetType[1].name 
      && _vm.getSelectedCockBtn.channelType == _vm.passChannelType?'machine-game-active':'',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[1])}}},[_c('p',{staticClass:"machine-payout cock-casino-button-font"},[_vm._v(_vm._s(_vm.passBetType ? _vm.passBetType[1].payout : 'x.xx'))]),_c('p',{staticClass:"machine-button-name cock-casino-button-font white--text"},[_vm._v(_vm._s(_vm.passBetType[1] ? _vm.passBetType[1].name : 'DRAW'))])]),_c('v-spacer'),_c('div',{staticClass:"cock-three-section machine-red-title machine-play-red-bg",class:_vm.getSelectedCockBtn && _vm.getSelectedCockBtn.button 
      && _vm.getSelectedCockBtn.button.name == _vm.passBetType[0].name
      && _vm.getSelectedCockBtn.channelType == _vm.passChannelType?'machine-game-active':'',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[0])}}},[_c('p',{staticClass:"machine-payout-name cock-casino-button-font"},[_vm._v(_vm._s(_vm.passBetType ? _vm.passBetType[0].payout : 'x.xx'))]),_c('p',{staticClass:"machine-button-name cock-casino-button-font white--text"},[_vm._v(_vm._s(_vm.passBetType[0] ? _vm.passBetType[0].name : 'DRAGON'))])])],1),_c('CockXCasinoSubmitButton',{attrs:{"passChannelType":_vm.passChannelType,"selectedSubmit":'selectedSubmitCockFight'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }