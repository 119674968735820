export default {
    methods: {
      _disconnectSocketCock(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
      },
      _connectSocketCock(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
        this.$connectSocket();
        this.listenerSocketCock()
      },
      
      listenerSocketCock() {
        this.$socket.on("client-status-channel-type", (data) => {
          if (data) {
            this.UPDATE_CHANNEL_STATUS(data);
          }
        });
        this.$socket.on("client-update-channel-info", (data) => {
          if (data) {
            this.updateChannel(data);
          }
        });
        this.$socket.on("client-defind-result-and-new-channel", async (data) => {
          await this.fetchTodayBetting();
          if (data) {
            await this.updateResult(data);
          }
        });
        this.$socket.on("client-update-betting-amount", (data) => {
          if (data) {
            if (this.channel.is_open) {
              this.fetchCurrentBetting();
              let currentcy = this.userInfor.currency_type - 1;
              this.UPDATE_AMOUNT(data[currentcy]);
            }
          }
        });
        this.$socket.on("client-update-payout", (data) => {
          if (data) {
            if (this.channel.is_open || this.channel.is_open == null) {
              if (this.userInfor.currency_type == data.currency_type) {
                this.UPDATE_PAYOUT(data);
              }
            }
          }
        });
        this.$socket.on("client-reserves-result", (data) => {
          if (data) {
            this.UPDATE_TODAY_BETTING(data);
          }
        });
        this.$socket.on("client-reset-amount", (data) => {
          if (data) {
            this.RESET_AMOUNT();
            if (this.userInfor.currency_type == 1) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_usd)
            } else if (this.userInfor.currency_type == 2) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_kh)
            } else if (this.userInfor.currency_type == 3) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_tb)
            }
          }
        });
        this.$socket.on("client-notification", (data) => {
          if (data) {
            this.getNotification();
          }
        });
      },
    }
}