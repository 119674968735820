<template>
    <div class="history limit-on-tab">
        <v-tabs style="color: white !important; margin-top: 16px; border: 1px solid #34495E;" fixed-tabs>
            <v-tabs-slider style="height:4px !important; color: #FFC008 !important;"></v-tabs-slider>
            <v-tab @click="callCurrentBetting" class="g-header">
                <!-- <v-icon left style="color:white"> mdi mdi-cast </v-icon> -->
                <i class="bi-coin" style="font-size: 1.5rem; color: white; padding: 5px;"></i>
                <span style="font-family: 'khmer mef1'; color:white">
                    {{ $t("title.current_betting") }}
                </span>
            </v-tab>
            <v-tab @click="callTodayReport" class="g-header">
                <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
                <i class="bi-clipboard-data" style="font-size: 1.5rem; color: white; padding: 5px;"></i>
                <span style="font-family: 'khmer mef1'; color:white">
                    {{ $t("title.today_report") }}
                </span>
            </v-tab>
            <v-tab-item>
                <v-simple-table style="font-family: 'khmer mef1'; background-color: rgba(0, 0, 0, 0.6);"
                    class="elevation-1 theme--dark" fixed-header height="279.1" min-height="279.1">
                    <template v-slot:default>
                        <thead style="background-color: rgba(0, 0, 0, 0.6);">
                            <tr>
                                <th class="text-left">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.return") }}({{ userInfor.currencySymbol }})
                                </th>
                                <th class="text-left">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="current in currentBetting.list" :key="current._id">
                                <td>
                                    #{{ current.fight_no }}
                                </td>
                                <td>
                                    <v-chip label :class="current.bet_color"
                                        style="padding:4px;color:#ffffff; font-size:12px;height:22px">
                                        <span class="white--text">
                                            {{ $t('title.' + current.betting.replace(/\s+/g, '')) }}
                                        </span>
                                    </v-chip>
                                </td>
                                <td :class="'c' + current.bet_color">
                                    {{ formatMoney(current.bet_amount) + ' x ' + current.payout + '= ???' }}
                                </td>
                                <td>
                                    <print-betting :current="current" />
                                </td>
                            </tr>
                        </tbody>
                        <!-- <div class="py-3 text-center gp-panel-bg mx-2">
                            <div class="bet d-flex my-2 align-items-center " v-for="today in passCurrentBetting.list" :key="today._id">
                                <div>
                                    <span class="fight-circle font-weight-bold " :class="'report-' + today.bet_color">
                                        #{{ today.fight_no }}
                                    </span>
                                </div>
                                <div class="flex-grow-1 pl-0 pr-2 px-sm-2 w-100">
                                    <div class="d-flex justify-content-between align-items-center text-white">
                                        <div class="pl-1">
                                            {{ today.amount }}
                                        </div>
                                        <div class="d-flex flex-column align-items-end">
                                            <div class="d-inline " :class="today.betting == 'WALA' ? 'text-wala' : 'text-meron'">
                                                {{ today.betting }}
                                            </div>
                                            <small class="font-italic text-muted">
                                                {{ today.time }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </template>
                </v-simple-table>
            </v-tab-item>
            <v-tab-item>
                <v-simple-table style="font-family: 'khmer mef1'; background-color: rgba(0, 0, 0, 0.6);"
                    class="elevation-1 theme--dark" fixed-header height="279.1" min-height="279.1">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.time") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.return") }} ({{ userInfor.currencySymbol }})
                                </th>
                                <th class="text-left">
                                    {{ $t("title.result") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="today in todayBetting" :key="today._id">
                                <td class="text-left">
                                    #{{ today.fight_no }}
                                </td>
                                <td class="text-left">
                                    {{ grtDate(today.time) }}
                                </td>
                                <td class="text-left" :class="'c' + today.bet_color">
                                    <v-chip label :class="today.bet_color"
                                        style="padding:4px;color:#ffffff; font-size:12px;height:22px">
                                        <span class="white--text">
                                            {{ $t('title.' + today.betting.replace(/\s+/g, '')) }}
                                            
                                        </span>
                                    </v-chip>
                                </td>
                                <td 
                                    v-if="today.is_win==null"
                                    :class="_computeStyle(today)"
                                    class="text-left">
                                    
                                    {{ formatMoney(today.bet_amount) + ' x ' + today.payout + ' = ' + formatMoney(today.bet_amount) }}
                                </td>
                                <td 
                                     v-else-if="today.is_win==true"
                                    :class="today.is_win==true
                                        ? 'c' + today.result_color
                                        : today.is_win
                                            ? ''
                                            : 'ccir-red'" 
                                    class="text-left">
                                    {{ formatMoney(today.bet_amount) + ' x ' + today.payout + ' = ' + formatMoney(today.case) }}
                                </td>
                                <td 
                                v-else-if="today.is_win==false"
                               :class="today.is_win==true
                                   ? 'c' + today.result_color
                                   : today.is_win
                                       ? ''
                                       : 'ccir-red'" 
                               class="text-left">
                               {{ formatMoney(today.amount_win) }}
                           </td>
                                <td :class="'c' + today.result_color" class="text-left">
                                    <v-chip label :class="today.result_color"
                                        style="padding:4px;color:#ffffff; font-size:12px;height:22px">
                                        <span class="black--text">
                                            {{ today.result1_name ? _getResultClass(today.result1_name).n+", " :"" }}
                                            {{ today.result1_name ? _getResultClass(today.result2_name).n+", " :"" }}
                                            {{ today.result1_name ? _getResultClass(today.result3_name).n :"" }}
                                        </span>
                                    </v-chip>
                                </td>
                                <td class="text-left">
                                    <print-today-report :todayReport="today" />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-tab-item>
        </v-tabs>
    </div>
</template>
<script>

//import "@/assets/css/homepage.css";
import { mapActions, mapGetters } from "vuex";
import PrintBetting from "../PrintBetting.vue";
import PrintTodayReport from "../PrintTodayReport.vue";
import clickMixin from "../../../../../mixin/MX_ShareFunctionCollection.js"
export default {
    props: ['passLoadingTodayReport'],
    components: {
        PrintBetting,
        PrintTodayReport
    },
    data() {
        return {
            mixins: [clickMixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_dashboard", {
            todayBetting: "getTodayBetting",
            currentBetting: "getCurrentBetting"
        }),
    },
    methods: {
      _computeStyle(today){
        if(today.is_win== null) return 'c' + today.result_color;
        else if(today.is_win == true) return "ccir-blue";
        else return 'ccir-red';
      },
        _resultMoney(today) {
            if (today.amount_win <= 0) return this.formatMoney(today.amount_win)
            else return this.formatMoney(today.case)
        },
        grtDate(date) {
            let time = date.split(" ")
            if (time[1]) {
                return time[1];
            }
            return date
        },
        async callCurrentBetting() {
            await this.fetchCurrentBetting();
        },
        async callTodayReport() {
            await this.fetchTodayBetting();
        },
        _sentRefreshTodayReport() {
            this.$emit("sentRefreshTodayReport");
        },
        _sentRefreshCurrentReport() {
            this.$emit("sentRefreshCurrentReport");
        },
        ...mapActions("$_dashboard_live", [
            "fetchTodayBetting",
            "fetchCurrentBetting"
        ]),

        _getResultClass(x) {
            switch (x) {
                case 'Banker 1':
                    return { c: 'han2-r-td-banker', n: 'B1', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Banker 2':
                    return { c: 'han2-r-td-banker', n: 'B2', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Banker 3':
                    return { c: 'han2-r-td-banker', n: 'B3', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Player 1':
                    return { c: 'han2-r-td-player', n: 'P1', f: 'P', cf: 'han2-r-td-player-f' }
                case 'Player 2':
                    return { c: 'han2-r-td-player', n: 'P2', f: 'P', cf: 'han2-r-td-player-f' }
                case 'Player 3':
                    return { c: 'han2-r-td-player', n: 'P3', f: 'P', cf: 'han2-r-td-player-f' }
                case 'CANCEL':
                    return { c: 'han2-r-td-cancel', n: 'C', f: 'C', cf: 'han2-r-td-cancel-f' }
                case 'Tie':
                    return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 1':
                    return { c: 'han2-r-td-tie', n: 'T1', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 2':
                    return { c: 'han2-r-td-tie', n: 'T2', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 3':
                    return { c: 'han2-r-td-tie', n: 'T3', f: 'T', cf: 'han2-r-td-tie-f' }
                default:
                    return ''
            }
        },
    }
}
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(40, 53, 89, .8);
    border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(78, 106, 181, .2)
}

@keyframes rotate {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.refresh-start {
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
}

.text-meron {
    color: #f93c3c !important
}

.txt-white {
    color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
    color: #FFC008
}

.txt-grey {
    color: #6C757D
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    height: 32px !important;
}</style>