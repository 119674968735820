<template>
  <div>
    <KlakloukMachineTableResult v-if="checkerGame(_selectedGame(channelId)) == 'klaklouk'" :rows="6" :cols="40" />
    <TigerDragonMachineTableResult v-if="checkerGame(_selectedGame(channelId) )== 'dragon'" :rows="6" :cols="40" />
    <BaccaratMachineTableResult v-if="checkerGame(_selectedGame(channelId)) == 'baccarat'" :rows="6" :cols="40"  />
    <DodenMachineTableResult v-if="checkerGame(_selectedGame(channelId)) == 'doden'" :rows="6" :cols="40"  />
    <SicBoMachineTableResult v-if="checkerGame(_selectedGame(channelId)) == 'sicbo'" :rows="6" :cols="40"  />
    <TaiXiuMachineTableResult v-if="checkerGame(_selectedGame(channelId)) == 'taixiu'" :rows="6" :cols="40"  />
  </div>
</template>

<script>
import KlakloukMachineTableResult from "./_TableResultComponent/KlakloukMachineTableResult.vue";
import TigerDragonMachineTableResult from "./_TableResultComponent/TigerDragonMachineTableResult.vue";
import BaccaratMachineTableResult from "./_TableResultComponent/BaccaratMachineTableResult.vue"
import DodenMachineTableResult from "./_TableResultComponent/DodenMachineTableResult.vue"
import SicBoMachineTableResult from "./_TableResultComponent/SicBoMachineTableResult.vue"
import TaiXiuMachineTableResult from "./_TableResultComponent/TaiXiuMachineTableResult.vue"
import Mixin from "~mixin/MX_ShareFunctionCollection.js"
import { mapGetters } from "vuex";
export default {
  components: {DodenMachineTableResult, SicBoMachineTableResult, TaiXiuMachineTableResult, KlakloukMachineTableResult, TigerDragonMachineTableResult, BaccaratMachineTableResult },
  data() {
    return {
      mixins: [Mixin],
    }
  },
  computed: {
    ...mapGetters("$_modules", {
      channelId: "getChannelId"
    }),
  },
  methods:{
    checkerGame(page){
      switch(true){
        case (page =="slot-baccarat" || page =="slot-baccarat10" ||page =="slot-baccarat2" ||page =="slot-baccarat3" ||page =="slot-baccarat4" ||page =="slot-baccarat5" ||page =="slot-baccarat6" ||page =="slot-baccarat7" ||page =="slot-baccarat8" ||page =="slot-baccarat9" )
        : return 'baccarat';
        case (page =="slot-dragon" || page =="slot-dragon10" ||page =="slot-dragon2" ||page =="slot-dragon3" ||page =="slot-dragon4" ||page =="slot-dragon5" ||page =="slot-dragon6" ||page =="slot-dragon7" ||page =="slot-dragon8" ||page =="slot-dragon9" )
        : return 'dragon';
        case (page =="slot-klaklouk" || page =="slot-klaklouk10" ||page =="slot-klaklouk2" ||page =="slot-klaklouk3" ||page =="slot-klaklouk4" ||page =="slot-klaklouk5" ||page =="slot-klaklouk6" ||page =="slot-klaklouk7" ||page =="slot-klaklouk8" ||page =="slot-klaklouk9" )
        : return 'klaklouk'
        case (page =="slot-doden" || page =="slot-doden10" ||page =="slot-doden2" ||page =="slot-doden3" ||page =="slot-doden4" ||page =="slot-doden5" ||page =="slot-doden6" ||page =="slot-doden7" ||page =="slot-doden8" ||page =="slot-doden9" )
        : return 'doden'
        case (page =="slot-taixiu" || page =="slot-taixiu10" ||page =="slot-taixiu2" ||page =="slot-taixiu3" ||page =="slot-taixiu4" ||page =="slot-taixiu5" ||page =="slot-taixiu6" ||page =="slot-taixiu7" ||page =="slot-taixiu8" ||page =="slot-taixiu9" )
        : return 'taixiu'
        case (page =="slot-sicbo" || page =="slot-sicbo10" ||page =="slot-sicbo2" ||page =="slot-sicbo3" ||page =="slot-sicbo4" ||page =="slot-sicbo5" ||page =="slot-sicbo6" ||page =="slot-sicbo7" ||page =="slot-sicbo8" ||page =="slot-sicbo9" )
        : return 'sicbo'
      }
    },
  }
}
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(40, 53, 89, .8);
  border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(78, 106, 181, .2)
}

@keyframes rotate {

  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }

}


.refresh-start {

  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;

}

.text-meron {
  color: #f93c3c !important
}

.txt-white {
  color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
  color: #FFC008
}

.txt-grey {
  color: #6C757D
}
</style>