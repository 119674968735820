<template>
  <div >
    <v-row class="ma-0 w-100">
      <div class="cock-three-section machine-red-title machine-play-red-bg" 
        :class="getSelectedCockBtn && getSelectedCockBtn.button 
        && getSelectedCockBtn.button.name == passBetType[0].name 
        && getSelectedCockBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[0])"> 
        <p class="machine-payout-name cock-casino-button-font">{{ payout && payout.meron ? payout.meron.payout : 'x.xx'}}</p>
        <p class="machine-button-name cock-casino-button-font white--text">{{passBetType && passBetType[0] ? passBetType[0].name : 'Meron'}}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="cock-three-section machine-green-title machine-play-green-bg"
        :class="getSelectedCockBtn && getSelectedCockBtn.button 
        && getSelectedCockBtn.button.name == passBetType[2].name 
        && getSelectedCockBtn.channelType == passChannelType?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[2])"> 
        <p class="machine-payout cock-casino-button-font">{{payout ? "1 : 8" : 'x.xx'}}</p>
        <p class="machine-button-name cock-casino-button-font white--text">{{passBetType && passBetType[2] ? passBetType[2].name : 'DRAW'}}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="cock-three-section machine-blue-title machine-play-blue-bg"
      :class="getSelectedCockBtn && getSelectedCockBtn.button 
        && getSelectedCockBtn.button.name == passBetType[1].name
        && getSelectedCockBtn.channelType == passChannelType?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[1])"> 
        <p class="machine-payout-name cock-casino-button-font">{{payout && payout.wala ? payout.wala.payout : 'x.xx'}}</p>
        <p class="machine-button-name cock-casino-button-font white--text">{{passBetType && passBetType[1] ? passBetType[1].name : 'DRAGON'}}</p>
      </div>
    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitCockFight'"/>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations } from "vuex";
import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"

export default {
  components: {
    CockXCasinoSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  data() {
    return {
      payout: {},
      error: false
    }
  },
  computed:{
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedCockBtn: "getSelectedCockBtn",
    }),
  },
  watch: {
    // resultObjS: function() { 
    //   this.getResult(this.channelType);
    // }
  },
  created(){
    // this.getPayout(this.passChannelType);
  },
  methods:{
    async getPayout(channelType) {
      // console.log(`getPayout >>>>${channelType}`)
      try {
        let response = await this.fetchCockXCasinoPayout(channelType);
        if(response.data.code) {
          this.payout = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_COCK_BUTTON_IN_ALL(data);
    },
    ...mapActions("$_cockfight_casino_live", ["fetchCockXCasinoPayout"]),
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_COCK_BUTTON_IN_ALL",
    ]),
  }
}
</script>