export default {
    permission: 'សិទ្ធអ្នកប្រើប្រាស់',
    role: 'ប្រភេទអ្នកប្រើប្រាស់',
    description: 'បរិយាយ',
    menu_name: 'ឈ្មោះ Menu',
    icon: 'រូបតំណាង',
    order: 'លេខលំដាប់',
    active: 'សកម្ម',
    actions: 'សកម្មភាព',
    balance: 'Balance',
    created_at: 'កាលបរិច្ឆេទ',
    bank_account: 'ព័ត៌មានគណនីធនាគារ',
    user_name: 'Tên tài khoản',
    deposit_withdraw: 'ដក/ដាក់ប្រាក់',
    no :"Số thứ tự",
    game:"ហ្គេម",
    date:"ngày",
    result:"លទ្ធផល",
    detail:"លំអិត",
    headtail:"ធំ / តូច",
    range:"ចន្លោះ",
    id:"លេខសំគាល់",
    account:"គណនី",
    bet:"ចាក់ចំនួន",
    type:"ចាក់ប្រភេទ",
    payout:"ផលសង",
    winLose:"ឈ្នះ / ចាញ់",
    oldBalance:"លុយចាស់",
    newBalance:"លុយថ្មី",
    amount: "បានដាក់",
    amountw: "បានដក",
    small_large: "តូច / ធំ",
    invoice_no: "លេខវិកាយប័ត្រ",
    turn_over: "ប្រាក់សងត្រលប់",
    total_cast:"ប្រាក់សងត្រលប់",
    bettingType:'ចាក់ប្រភេទ',
    post:'ប៉ុស្តិ៍',
    game_no:'លេខហ្គេម',
    group: 'ក្រុម',
    action:'សកម្មភាព',
    old_balance:'Số dư cũ',
    new_balance:'Số dư mới',


    all_statement: 'All Statement',
    total_win_lose: 'Total Win/Lose',
    start_date: 'Start Date',
    end_date: 'End Date',
    fight_no: 'Fight No',
    channel: 'Channel',
    group_no: 'Group No',
    date_time: 'Date1Time',
    cast: 'Cash',
    amount_win: 'Amount Win',


    see_detail:"Chi Tiết",
    invoice_id:"không có hoa đơn",
};
