<template>
  <div>
    <div id="sport-menu-container" class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center">
      <div class="menu-space">
        <v-row class="ma-0 pl-17 w-100">
          <div class="w-100 d-flex align-center">
            <div class="row ma-0">

              <div class="text-center d-flex align-items-center menu-slot-item " v-for="i in subMenuSport"
                :key="i.name">
                <div class="nav-sport-main-menu nav-icon-wr row ma-0 pt-0" :class="_findRouteParent($route.path) == i.name
                ? 'main-menu-span-active'
                : ''
                " v-if="userInfo &&
                subMenuSport &&
                subMenuSport.length > 0 && i.is_enable
                ">
                  <img :src="_urlToImg(i.name)" class="sport-main-game-image dancing-image" />
                  <p class="text-uppercase">{{ i.name }} </p>
                </div>
              </div>
            </div>
          </div>
        </v-row>

      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/sport-tabs.css";
import lotteryMenuFunction from "~mixin/MX_LotteryMenuFunctionCollection.js";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  mixins: [lotteryMenuFunction],
  props: ['passIsSubGame'],
  data: () => ({
    audioLogItems: [],
    chosenName: "",
    subMenuSport: [
      {
        name: "Soccer",
        is_enable: true
      },
      {
        name: "Boxing",
        is_enable: true
      }

    ]
  }),
  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      channelId: "channelId",
      getMainMenu: "getMainMenu",
      getSubMenuMachineGame: 'getSubMenuMachineGame'
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  mounted() {
    this.UPDATE_CHANNEL_ID(this.$cookies.get('sbc_channel_type'));
  },
  methods: {
    _calculateActive(channelId, menuId) {
      if (channelId == menuId) return true;
      else return false
    },
    _checkHeightSubmenu() {
      let submenu = this.userInfo.menu.menu3.games[
        this._calculateMenuArray(this.$route.path)
      ].channels;
      for (let i in submenu) {
        if (submenu[i].is_enable == true) return true;
      }
      return false;
    },
    _checkHeightMenu() {
      let submenu = this.userInfo.menu.menu3.games[
        this._calculateMenuArray(this.$route.path)
      ].channels;
      for (let i in submenu) {
        if (submenu[i].is_enable == true) return true;
      }
      return false;
    },
    _goToRoute(path) {
      var selectedChannel = 90;
      this.UPDATE_EXPAND_MENU(true);
      if (path != 'back') {
        if (path == '/LOTTO' || path == "/LOTTO160S") {
          path = '/LOTTO';
          selectedChannel = 90
          this.$cookies.set("sbc_channel_type", selectedChannel);
          this.UPDATE_CHANNEL_ID(selectedChannel);
        } else if (path == "/LOTTO100S") {
          path = '/LOTTO';
          selectedChannel = 901
          this.$cookies.set("sbc_channel_type", selectedChannel);
          this.UPDATE_CHANNEL_ID(selectedChannel);
        } else if (path == "/LOTTO30S") {
          selectedChannel = 902;
          path = '/LOTTO';
          this.$cookies.set("sbc_channel_type", selectedChannel);
          this.UPDATE_CHANNEL_ID(selectedChannel);
        } else if (path == "/YUKI" || path == "/YUKI160S") {
          path = '/YUKI';
          selectedChannel = 91
          this.$cookies.set("sbc_channel_type", selectedChannel);
          this.UPDATE_CHANNEL_ID(selectedChannel);
        } else if (path == "/YUKI100S") {
          selectedChannel = 911;
          path = '/YUKI';
          this.$cookies.set("sbc_channel_type", selectedChannel);
          this.UPDATE_CHANNEL_ID(selectedChannel);
        } else if (path == "/YUKI30S") {
          selectedChannel = 912;
          path = '/YUKI';
          this.$cookies.set("sbc_channel_type", selectedChannel);
          this.UPDATE_CHANNEL_ID(selectedChannel);
        } else if (path == "/LOTTERY") {
          this.$cookies.set("sbc_channel_type", selectedChannel);
          this.UPDATE_CHANNEL_ID(selectedChannel);
        }
        this.$router.push({ path }).catch(() => {
          /* ignore */
        });
      }
      else {
        this.$socket.removeAllListeners()
        this.$socket.disconnect()
        this.$router.push({ path: '/machine-games/ALL' }).catch(() => {
          /* ignore */
        });
      }

    },

    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_EXPAND_MENU"
    ]),
  },
};
</script>
<style></style>
