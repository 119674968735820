<template>
  <div class="bg-white">
    <SummaryResult></SummaryResult>
      <div class="table-responsive">
        <table class="table-result-style-1">
          <tbody>
            <tr v-for="row in rows" :key="row">
              <td v-for="col in (channel.fix_column + 18)" :key="col">
                <div v-if="channel.column - channel.fix_column <= 0">
                  <div v-for="(result, i) in resultObj.results" :key="i">
                    <span v-if="col == result.cid && row == result.rid">
                      <div class="item-circle" :class="'' + _resultColor(passSelectedChannel, result.color)">
                        {{ result.name != 'CANCEL' ? result.fight_no : 'x' }}
                      </div>
                    </span>
                  </div>
                </div>
                <div v-else>
                  <div v-for="(result, i) in resultObj.results" :key="i">
                    <span v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid">
                      <div class="item-circle" :class="'' + _resultColor(passSelectedChannel, result.color)">
                        {{ result.name != 'CANCEL' ? result.fight_no : 'x' }}
                      </div>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Mixin from "~mixin/index";
import bullet_label_mixin from "~mixin/bullet_label";

import SummaryResult from "./Summary_Result/SR_C_15_BaccaratLive.vue";
export default {
  props: ["rows", "cols"],
  mixins: [Mixin, bullet_label_mixin],
  components: { SummaryResult },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters("$_casino_live", {
      resultObj: "getResult",
      channel: "getChannel",
    }),
    ...mapGetters("$_modules", {
      passSelectedChannel: "getChannelId"
    }),
    orderResult() {
      return this.getListResults();
    },
  },
  methods: {
    getListResults() {
      if (this.resultObj.results !== null && this.resultObj.results.length > 0) {
        let col = this.channel.column;
        let fixCol = this.channel.fix_column
        if (col - fixCol <= 0) {
          return this.resultObj.results;
        } else {
          for (var index = this.resultObj.results.length - 1; index >= 0; index--) {
            this.resultObj.results[index].cid = index + (col - fixCol)
          }
          return this.resultObj.results;
        }
      }
      return this.resultObj.results;
    },

  },
};
</script>

<style scoped>
.trend-table {
  border-spacing: 0px !important;
}

.trend-table td {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 10px;
  text-align: center;
  border: 1px solid #c5c5c5;
  padding: 1px;
}

td>div {
  width: 29px;
}
</style>