<template>
  <div class="flexi-machine-left" >
    <div v-if="passPage == 'ALL'">
      <div
        v-for="(item, index) in getSubMenuMachineGameMenuAll"
        :key="index"
      >
        <GameElement
          v-if="item.is_enable"
          :passChDesc="item.desc"
          :passChannelInfo="item"
          :passPage="passPage"
          :passItem="item.namespace"
          :passBetType="item.bet_type"
          :channelType="item.channel_type"
          :passGame="index + 1"
          :passIndex="index + 1"
        />
      </div>
    </div>
    <div v-if="passPage == 'BACCARAT'">
      <div
        v-for="(item, index) in getSubMenuMachineGameMenuBaccarat"
        :key="index"
      >
        <GameElement
          v-if="item.is_enable"
          :passChDesc="item.desc"
          :passChannelInfo="item"
          :passPage="passPage"
          :passItem="item.namespace"
          :passGame="index + 1"
          :passIndex="index + 1"
          :passBetType="item.bet_type"
          :channelType="item.channel_type"
        />
      </div>
    </div>
    <div v-if="passPage == 'DragonTiger'">
      <div
        v-for="(item, index) in getSubMenuMachineGameMenuDragonTiger"
        :key="index"
      >
        <GameElement
          v-if="item.is_enable"
          :passChDesc="item.desc"
          :passChannelInfo="item"
          :passItem="item.namespace"
          :passPage="passPage"
          :passGame="index + 1"
          :passIndex="index + 1"
          :passBetType="item.bet_type"
          :channelType="item.channel_type"
        />
      </div>
    </div>
    <div v-if="passPage == 'TaiXiu'">
      <div
        v-for="(item, index) in getSubMenuMachineGameMenuTaiXiu"
        :key="index"
      >
        <GameElement
          v-if="item.is_enable"
          :passChDesc="item.desc"
          :passItem="item.namespace"
          :passChannelInfo="item"
          :passPage="passPage"
          :passGame="index + 1"
          :passIndex="index + 1"
          :passBetType="item.bet_type"
          :channelType="item.channel_type"
        />
      </div>
    </div>
    <div v-if="passPage == 'DoDen'">
      <div
        v-for="(item, index) in getSubMenuMachineGameMenuDoden"
        :key="index"
      >
        <GameElement
          v-if="item.is_enable"
          :passChDesc="item.desc"
          :passChannelInfo="item"
          :passItem="item.namespace"
          :passPage="passPage"
          :passGame="index + 1"
          :passIndex="index + 1"
          :passBetType="item.bet_type"
          :channelType="item.channel_type"
        />
      </div>
    </div>
    <div v-if="passPage == 'SicBo'">
      <div
        v-for="(item, index) in getSubMenuMachineGameMenuSicBo"
        :key="index"
      >
        <GameElement
          v-if="item.is_enable"
          :passChDesc="item.desc"
          :passChannelInfo="item"
          :passItem="item.namespace"
          :passPage="passPage"
          :passGame="index + 1"
          :passIndex="index + 1"
          :passBetType="item.bet_type"
          :channelType="item.channel_type"
        />
      </div>
    </div>
    <div v-if="passPage == 'KlaKlouk'">
      <div
        v-for="(item, index) in getSubMenuMachineGameMenuKlaklouk"
        :key="index"
      >
        <GameElement
          v-if="item.is_enable"
          :passChDesc="item.desc"
          :passChannelInfo="item"
          :passItem="item.namespace"
          :passPage="passPage"
          :passGame="index + 1"
          :passIndex="index + 1"
          :passBetType="item.bet_type"
          :channelType="item.channel_type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GameElement from "../GamePlayComponent/GameElement.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    GameElement,
  },
  props: ["passPage"],
  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      channelId: "channelId",
      getMainMenu: "getMainMenu",
      getSubMenuMachineGameMenuAll:"getSubMenuMachineGameMenuAll",
      getSubMenuMachineGameMenuBaccarat:"getSubMenuMachineGameMenuBaccarat",
      getSubMenuMachineGameMenuDragonTiger:"getSubMenuMachineGameMenuDragonTiger",
      getSubMenuMachineGameMenuDoden:"getSubMenuMachineGameMenuDoden",
      getSubMenuMachineGameMenuTaiXiu:"getSubMenuMachineGameMenuTaiXiu",
      getSubMenuMachineGameMenuSicBo:"getSubMenuMachineGameMenuSicBo",
      getSubMenuMachineGameMenuKlaklouk:"getSubMenuMachineGameMenuKlaklouk"
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  async created(){
    if(this.userInfo && this.userInfo.menu &&  this.userInfo.menu.menu4 && this.userInfo.menu.menu4.games[0]) await this.prepareToExit()
  },
  methods: {
    fintPrototypeSocket(i) {
      switch (i) {
        case 1:
          return this.$socket1 ? this.$socket1 : false;
        case 2:
          return this.$socket2 ? this.$socket2 : false;
        case 3:
          return this.$socket3? this.$socket3 : false;
        case 4:
          return this.$socket4 ? this.$socket4 : false;
        case 5:
          return this.$socket5 ? this.$socket5 : false;
        case 6:
          return this.$socket6 ? this.$socket6 : false;
        case 7:
          return this.$socket7 ? this.$socket7 : false;
        case 8:
          return this.$socket8 ? this.$socket8 : false;
        case 9:
          return this.$socket9 ? this.$socket9 : false;
        case 10:
        return this.$socket10 ? this.$socket10 : false;
      }
    },
    prepareToExit() {
      if(this.userInfo && this.userInfo.menu && this.userInfo.menu.menu4.games){
      switch (this.passPage) {
        case "ALL":
          for (
            let i = 1;
            i <= this.userInfo.menu.menu4.games[0].channels.length;
            i++
          ) {
            if(this.fintPrototypeSocket(i) != false){
              this.fintPrototypeSocket(i).disconnect();
            this.fintPrototypeSocket(i).removeAllListeners();
            }
          }
          break;
        case "BACCARAT":
          for (
            let i = 1;
            i <= this.userInfo.menu.menu4.games[1].channels.length;
            i++
          ) {
            if(this.fintPrototypeSocket(i) != false){
              this.fintPrototypeSocket(i).disconnect();
            this.fintPrototypeSocket(i).removeAllListeners();
            }
          }
          break;
        case "DragonTiger":
          for (
            let i = 1;
            i <= this.userInfo.menu.menu4.games[2].channels.length;
            i++
          ) {
            if(this.fintPrototypeSocket(i) != false){
              this.fintPrototypeSocket(i).disconnect();
            this.fintPrototypeSocket(i).removeAllListeners();
            }
          }
          break;
        case "TaiXiu":
          for (
            let i = 1;
            i <= this.userInfo.menu.menu4.games[3].channels.length;
            i++
          ) {
            if(this.fintPrototypeSocket(i) != false){
              this.fintPrototypeSocket(i).disconnect();
            this.fintPrototypeSocket(i).removeAllListeners();
            }
          }
          break;
        case "Doden":
          for (
            let i = 1;
            i <= this.userInfo.menu.menu4.games[4].channels.length;
            i++
          ) {
            if(this.fintPrototypeSocket(i) != false){
              this.fintPrototypeSocket(i).disconnect();
            this.fintPrototypeSocket(i).removeAllListeners();
            }
          }
          break;
        case "SicBo":
          for (
            let i = 1;
            i <= this.userInfo.menu.menu4.games[5].channels.length;
            i++
          ) {
            if(this.fintPrototypeSocket(i) != false){
              this.fintPrototypeSocket(i).disconnect();
            this.fintPrototypeSocket(i).removeAllListeners();
            }
          }
          break;
        case "Klaklouk":
          for (
            let i = 1;
            i <= this.userInfo.menu.menu4.games[6].channels.length;
            i++
          ) {
            if(this.fintPrototypeSocket(i) != false){
              this.fintPrototypeSocket(i).disconnect();
            this.fintPrototypeSocket(i).removeAllListeners();
            }
          }
          break;
      }
    }
    },
  },
};
</script>