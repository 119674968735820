<template>
    <div class="justify-content-center row text-center ml-4 mr-3 mt-0 mb-1 px-0 ">
        <div class="row ma-0 w-100 mx-auto text-center " >
            <div class="row coin-container ma-0 px-0 gp-scroll">
                <div v-for="(button, index) in getButton" :key="button._id" class=" ma-0 pa-0 col-2" style="min-width:20%">
                    <div @click="_inputAmount(button.amount)">
                        <img :src="_detectCoin(index)" class="btn-coin text-center" alt="">
                        <div :style="`color:${_detectColor(index)};`" class="text btn-bet-circle prevent-select " >
                            {{ button.label }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    props: [
        'passPlaceCoin',
    ],
    data() {
        return {
            selectedButton: {},
        }
    },
    computed: {
        ...mapGetters("$_dashboard", {
            getButton: "getButton",
            passChannel: "getChannel",
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
        _detectColor(index) {
            switch (index) {
                case 0:
                    return 'black';
                case 1:
                    return 'black';
                case 2:
                    return 'black';
                case 3:
                    return 'black';
                case 4:
                    return 'black';
                case 5:
                    return 'black';
                case 6:
                    return 'black';
                case 7:
                    return 'black';
                case 99:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectCoin(index) {
            switch (index) {
                case 0:
                    return '/images/a_game_access_global/coins/1.png';
                case 1:
                    return '/images/a_game_access_global/coins/2.png';
                case 2:
                    return '/images/a_game_access_global/coins/3.png';
                case 3:
                    return '/images/a_game_access_global/coins/4.png';
                case 4:
                    return '/images/a_game_access_global/coins/5.png';
                case 5:
                    return '/images/a_game_access_global/coins/6.png';
                case 6:
                    return '/images/a_game_access_global/coins/7.png';
                case 7:
                    return '/images/a_game_access_global/coins/8.png';
                case 33:
                    return '/images/a_game_access_global/coins/grey.png';
                default:
                    return ''
            }
        },
        _inputAmount(money) {
            this.UPDATE_BETTING_AMOUNT(money)
        },
        formatCoinCurrency(amount) {
            switch (amount) {
                case 1000:
                    return '1ព';
                case 5000:
                    return '5ព';
                case 10000:
                    return '1ម';
                case 20000:
                    return '2ម';
                case 50000:
                    return '5ម';
                case 100000:
                    return '10ម';
                case 200000:
                    return '20ម';
                case 500000:
                    return '50ម';
                case 1000000:
                    return '1ល';
                case 5000000:
                    return '5ល';
                default:
                    return amount;
            }
        },
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <= 999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <= 99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
        formatMoney(amount){
            return amount.toLocaleString();
        },
        ...mapMutations("$_dashboard", [
            "BETTING_DIALOG"
        ]),
        ...mapMutations("$_modules", [
      "UPDATE_BETTING_AMOUNT",
    ]),
    }
}
</script>
<style scoped>
@media(max-width:760px) {
    .btn-coin {
        width: 90%;
        height: auto;
        position: relative;
        padding: 0.2rem;
        margin: 0 !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-coin-cover {
        width: 100%
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 3px 0px 7px rgba(236, 235, 229, 0.3), -3px 0px 7px rgba(228, 226, 220, 0.2), 0px 4px 7px rgba(221, 216, 199, 0.3);
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0px;
        margin: 0px !important;
        margin-top:-2px!important;
        text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
    }
}

@media(min-width:760px) {
    .btn-coin {
        width: 90%;
        height: auto;
        position: relative;
        padding: 0.25rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 3px 0px 7px rgba(236, 235, 229, 0.3), -3px 0px 7px rgba(228, 226, 220, 0.2), 0px 4px 7px rgba(221, 216, 199, 0.3);
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0px;
        margin: 0px !important;
        margin-top:-2px!important;
        text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;
    }
}

@media(min-width:960px) {
    .btn-coin {
        width: 90%;

        height: auto;
        position: relative;
        padding: 0.25rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 1px 0px 1px #012d98;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0px;
        margin: 0px !important;
        margin-top:-2px!important;
        text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;

    }
}

@media(min-width:1280px) {
    .btn-coin {
        width: 90%;

        height: auto;
        position: relative;
        padding: 0.15rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .money-in-coin-position {
        margin-top: -52px;
        margin-bottom: 25px;
        z-index: 100;
        font-weight: 900;
        font-size: 16px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1.2rem;
        font-weight: 800!important;
        text-shadow: 15px -2px 30px rgb(170, 151, 104);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0px;
        margin: 0px !important;
        margin-top:-3px!important;
        text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff;

    }
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}
.hold-coin{
    position: sticky; 
    left:0px;
    z-index: 100; 
    background: #013366;
    font-weight: bold;
    border-right : 2px dashed white; 
}
.coin-container {
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
}
.coin-container:hover{
    cursor: pointer;
}
.display-money{
    background-color:rgb(255, 255, 255, .9);
    height:40px;
    border-radius: 5px;
    margin:5px 0px 7px 0px;
    color: #212427;
    font-weight:bolder;
    text-shadow: 1px 0.2px #406386;
    font-size:1.3rem;
    padding-top:5px;
}
</style>