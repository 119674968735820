<template>
    <div class="w-100">
        <v-row class="ma-0">
            <p class="w-50 keno-credit">{{ $t('title.credit') }}: {{ formatMoney(userInfor.balance)}} </p>
            <p class="w-50 keno-limit-betting">{{ $t('title.min') }} : {{ formatMoney(getLimit.amount_min)}}
                 |  {{ $t('title.max') }} :  {{ formatMoney(getLimit.amount_max)}} </p>
        </v-row>
        <v-row class="ma-0 pl-2">
            <input
            :value="amount"
            placeholder="ENTER BET AMOUNT"
            type="number"
            pattern="[0-9]*"
            min="1"
            onlynumber="true"
            @input="event => inputAmount(event.target.value)"
            class="keno-input-amount"
            color="white"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
          <div class="keno-btn-clear" @click="inputAmount(0)"><p class="pa-0 ma-0"><v-icon class="ma-0 pa-0" >mdi mdi-close-thick</v-icon></p></div>
          <div class="keno-button-money-container">
          <div v-for="i in getButtonMoney" :key="i._id" class="keno-button-money" @click="addAmountByBtn(i.amount)"> <p class="ma-0 pa-0">{{ i.label }}</p></div>
            </div>
        </v-row>
    </div>
</template>

<script>

// IMPORT STYLE
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import clickMixin from "~mixin"

export default {
  components: {

  },
  data() {
    return {
        mixins: [clickMixin],
        coinClickCounter:0,
    };
  },

  computed: {
    ...mapState("$_keno", {
      amount: "getBettingAmount",
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    
    }),
    ...mapGetters("$_keno", {
        getLimit : "getLimit",
        amount:"getBettingAmount",
        getButtonMoney:'getButtonMoney',
        getSelectedBtnType:'getSelectedBtnType'
    }),
  },
  methods: {
    async _initialData() {
    //   await this.fetchUserInfo();
    },
    addAmountByBtn(money){
      const totalAmount = parseInt(this.amount) + parseInt(money);
      this.INPUT_BETTING_AMOUNT(parseInt(totalAmount));
    },
    inputAmount(money) {
      // this._playSoundInsertCoin()
      // this.coinClickCounter++;
      // this.UPDATE_SELECT_COIN(this.coinClickCounter);
      this.INPUT_BETTING_AMOUNT(parseInt(money));
    },

    ...mapMutations("$_keno", [
      "INPUT_BETTING_AMOUNT"
    ]),

    ...mapActions("$_keno", [
    //   "fetchLastResults",
    ]),
    ...mapActions("$_modules", ["fetchUserInfo"]),
    }
  }

</script>