<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <div class="game-machine-component">
    <v-row class="ma-0">
      <h5 class=" game-machine-component-title display-desc-mobile pt-3 pl-2">{{ passIndex}}.{{ passChDesc }}</h5>  
      <GameResult :baccaratScanCard="baccaratScanCard" :passTime="time" :tigerCard="tigerCard" :dragonCard="dragonCard" :passGame="passGame" :passIndex="passIndex" :channelType="channelType" :resultObjS="newGame" :passChDesc="passChDesc" :showResult="resultObj"/>
      <GameInfo :passGame="passGame" :resultObjS="resultObj" :passChannelInfo="passChannelInfo" :channelType="channelType" :passTime="time" :chInfo="chInfo"/>
      <GameButton :passBetType="passBetType" :channelType="channelType" :passGame="passGame" :passTime="time"/>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import GameButton from "../SubComponent/GameButton/index.vue";
import GameInfo from "../SubComponent/GameInfo.vue";
import GameResult from "../SubComponent/GameResult/index.vue";
import { io } from "socket.io-client";

export default {
  components: {
    GameButton,
    GameInfo,
    GameResult,
  },
  props: [
    "passGame",
    "passIndex",
    "passBetType",
    "channelType",
    "passItem",
    "passPage",
    "passChDesc",
    "passChannelInfo"
  ],
  data() {
    return {
      time: {
        is_countdown: null,
        is_open_betting: null,
        time: null,
      },
      chInfo: this.passChannelInfo,
      resultObj: {},
      newGame: {},
      dragonCard:[],
      tigerCard:[],
      statement:{
        rows_per_page: 10,
        page: 1
      },
      baccaratScanCard:{}
    };
  },
  mounted() {
    this._powerSocket();
    this.listenerSocket();
  },
  watch: {
    passPage: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.toDisconnectSocket(this.fintPrototypeSocket());
        await this._powerSocket();
        await this.listenerSocket();
      }
    },
  },
  methods: {
    fintPrototypeSocket() {
      switch (this.passIndex) {
        case 1:
          return this.$socket1 ? this.$socket1 : false;
        case 2:
          return this.$socket2 ? this.$socket2 : false;
        case 3:
          return this.$socket3? this.$socket3 : false;
        case 4:
          return this.$socket4 ? this.$socket4 : false;
        case 5:
          return this.$socket5 ? this.$socket5 : false;
        case 6:
          return this.$socket6 ? this.$socket6 : false;
        case 7:
          return this.$socket7 ? this.$socket7 : false;
        case 8:
          return this.$socket8 ? this.$socket8 : false;
        case 9:
          return this.$socket9 ? this.$socket9 : false;
        case 10:
          return this.$socket10 ? this.$socket10 : false;
        case 11:
          return this.$socket11 ? this.$socket11 : false;
        case 12:
          return this.$socket12 ? this.$socket12 : false;
        case 13:
          return this.$socket13 ? this.$socket13 : false;
        case 14:
          return this.$socket14 ? this.$socket14 : false;
        case 15:
          return this.$socket15 ? this.$socket15 : false;
        case 16:
          return this.$socket16 ? this.$socket16 : false;
        case 17:
          return this.$socket17 ? this.$socket17 : false;
        case 18:
          return this.$socket18 ? this.$socket18 : false;
        case 19:
          return this.$socket19 ? this.$socket19 : false;
        case 20:
          return this.$socket20 ? this.$socket20 : false;
        case 21:
          return this.$socket21 ? this.$socket21 : false;
      }
    },
    toDisconnectSocket(value) {
      try {
        if(value != false){
          this.time= {
            is_countdown: null,
            is_open_betting: null,
            time: null,
          };
          value.disconnect();
          value.removeAllListeners();
        }
        
      } catch (e) {
        //ignore
      }
    },
    listenerSocket() {
      try {
        let data = this.fintPrototypeSocket();
        data.on("client-time", (data) => {
          // console.log('client-time', `${JSON.stringify(data)}`);
          if (data) {
            this.time = data;
            this.resultObj = {};
          }
        });
        data.on("client-result", (data) => {
          // console.log('client-result', `${JSON.stringify(data)}`);
          if (data) {
            this.resultObj = data;
          }
        });
        data.on("client-update-channel-info", (data) => {
          // console.log('client-update-channel-info', `${JSON.stringify(data)}`);
          if (data) {
            this.chInfo = data;
          }
        });
        data.on("client-new-game", (data) => {
          // console.log('client-new-game', `${JSON.stringify(data)}`);
          this.baccaratScanCard = {}
          this.newGame = data;
          this.resultObj = {};
          this.tigerCard = [];
          this.dragonCard = [];
          this.fetchMachineStatement(this.statement);
        });
        data.on("client-scan-card", (data) => {
          // console.log('client-scan-card', `${JSON.stringify(data)}`);
          if (data) {
            this.baccaratScanCard = data;
            // this.totalbaccaratResult(data);
          }
        });
        data.on("tiger-card", (data) => {
          console.log('tiger-card', `${JSON.stringify(data)}`);
          if (data) {
            this.tigerCard = data;
            // this.UPDATE_TIGER_CARD(data);
          }
        });
        data.on("dragon-card", (data) => {
          console.log('dragon-card', `${JSON.stringify(data)}`);
          if (data) {
            this.dragonCard = data;
            // this.UPDATE_DRAGON_CARD(data);
          }
        });
      }
      catch (e) {
        //ignore
      }
    },

    _powerSocket() {
      try{
        let token = this.$cookies.get("sbc_token");
        const socket = io(
          `${process.env.VUE_APP_WEBSOCKET_URL + this.passItem}`,
          {
            extraHeaders: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        socket.on("connect", () => {
          console.log("socket: Connectd" + this.passItem);
        });
        socket.on("connect_error", (err) => {
          console.log("socket: Message err == ", err);
        });
        socket.on("disconnect", () => {
          console.log(`socket: Disconnection`, this.passItem);
        });
        socket.on("error", (err) => {
          console.log("socket: Error connecting to server", err);
        });
        socket.on("reconnect", (number) => {
          console.log("socket: Reconnected to server", number);
        });
        socket.on("reconnect_attempt", () => {
          console.log("socket: Reconnect Attempt", "alert problem");
        });
        socket.on("reconnecting", (number) => {
          console.log("socket: Reconnecting to server", number);
        });
        socket.on("reconnect_error", (err) => {
          console.log("socket: Reconnect Error", err);
        });
        socket.on("reconnect_failed", () => {
          console.log("socket: Reconnect failed", "offline");
        });
        switch (this.passIndex) {
          case 1:
            Vue.prototype.$socket1 = socket;
            break;
          case 2:
            Vue.prototype.$socket2 = socket;
            break;
          case 3:
            Vue.prototype.$socket3 = socket;
            break;
          case 4:
            Vue.prototype.$socket4 = socket;
            break;
          case 5:
            Vue.prototype.$socket5 = socket;
            break;
          case 6:
            Vue.prototype.$socket6 = socket;
            break;
          case 7:
            Vue.prototype.$socket7 = socket;
            break;
          case 8:
            Vue.prototype.$socket8 = socket;
            break;
          case 9:
            Vue.prototype.$socket9 = socket;
            break;
          case 10:
            Vue.prototype.$socket10 = socket;
            break;
        }
      }catch(e){
        //ignore
      }
    },
    totalbaccaratResult(data){
      for (let i in data.player_cards) {
        console.log("data.player_cards[i].barcode",data.player_cards[i].value)
        this.totalP = this.totalP + data.player_cards[i].value;
        this.totalP = this.totalP % 10
    }
    for (let j in data.banker_cards) {
        console.log("data.banker_cards[j]",data.banker_cards[j].value)
        this.totalB = this.totalB + data.banker_cards[j].value;
        this.totalB = this.totalB % 10
    }
    },
    ...mapActions("$_machine_games", [
      "fetchMachineStatement"
    ]),
    ...mapMutations("$_machine_games", [
      "UPDATE_BACCARAT_CARD"
    ]),
  },
};
</script>