<template>
  <div>
    <ChannelInfo />
    <ButtonBetting v-on:sentSubmitBetting="receiveSubmitBetting" />
    <input-amount-money/>
    <div class="col col-12 text-left row py-0 pr-0 mx-auto justify-content-end">
        <LimitBetting/>
    </div>
    <ListCoins />

    <ReportBetting :passLoadingTodayReport="passLoadingTodayReport" />
    <div class="show-on-mobile">
      <TableResult :rows="6" :cols="35" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import ChannelInfo from "../../../../../../components/component_cock/ChannelInfo.vue";
import ButtonBetting from "./ButtonBetting/ButtonBettingKlaklouk.vue";
import ReportBetting from "../../ReportBetting.vue";
import Mixin from "../../../../../../mixin/MX_ShareFunctionCollection.js";
import TableResult from "../../TableResults.vue";
import LimitBetting from "../../../../../../components/component_cock/LimitBetting.vue";
import ListCoins from "../../../../../../components/component_cock/ListCoins.vue";
import InputAmountMoney from '../../../../../../components/component_cock/InputAmountMoney.vue';

export default {
  components: {
    ChannelInfo,
    ReportBetting,
    TableResult,
    LimitBetting,
    ButtonBetting,
    ListCoins,
    InputAmountMoney,
  },
  props: [
    "passChannel",
    "passResult",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passTodayReport",
    "passLoadingTodayReport",
    "passChannelList",
    "passSelectedChannel",
    "passCurrentBetting",
    "passNewChannelList",
    "passChannelType",
    "passUserCurrency",
  ],
  data() {
    return {
      mixins: [Mixin],
      bettingMoney: 0,
      selectedButton: {},
      activeItem: null,
      selectedBtnClassMeron: false,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedCoin: false,
    };
  },
  computed: {
    meronButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[0];
      else return {};
    },
    walaButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[1];
      else return {};
    },
    tieButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[2];
      else return {};
    },
    ...mapGetters("$_dashboard", {
      channelObj: "getChannel",
      getButton: "getButton",
      currentBetting: "getCurrentBetting",
      loading: "getLoading",
      errorMessage: "getErrorMessage",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
    }),
  },
  methods: {
    receiveSubmitBetting(recieveBettingData) {
      this.$emit("sentSubmitBetting", recieveBettingData);
    },
    _switchChannel(selectedChannel) {
      this.$emit("sentSetChannel", selectedChannel);
    },
    _allowBtnBet(selectedBtn) {
      switch (selectedBtn) {
        case "b1":
          this.selectedButton = this.meronButton;
          this._submitBetting();
          break;
        case "b2":
          this.selectedButton = this.walaButton;
          this._submitBetting();
          break;
        case "b3":
          this.selectedButton = this.tieButton;
          this._submitBetting();
          break;
      }
    },
    _checkAllowSubmit() {
      if (
        this.bettingMoney > 0 &&
        (this.selectedBtnClassSix ||
          this.selectedBtnClassFive ||
          this.selectedBtnClassFour ||
          this.selectedBtnClassThree ||
          this.selectedBtnClassTwo ||
          this.selectedBtnClassOne ||
          this.selectedBtnClassTwoThree ||
          this.selectedBtnClassThreeFour ||
          this.selectedBtnClassFourFive)
      )
        return true;
      else return false;
    },
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT",
    ]),
  },
};
</script>

<style>
.btn-switch-channel:has(.to-hidden) {
  display: none !important;
}

.video-section {
  position: relative;
  overflow: hidden;
  background-color: #012d98
}
.show-on-mobile {
  display: none;
}
@media (max-width: 768px) {
  .show-on-mobile {
    display: block;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::placeholder
 {
    color: white!important;
  }
  
</style>