var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grid-container",attrs:{"cols":"12"}},_vm._l((80),function(number){return _c('div',{key:number,staticClass:"lotto-number number"},[_vm._l((_vm.passClientRandom.lotto),function(result,i){return _c('div',{key:i,class:{
          'blink':
            _vm.passClientRandom.lotto[
            _vm.passClientRandom.lotto.length - 1
            ] == number,
          'l-number-active': result == number,
        }},[_vm._v(" "+_vm._s(result == number ? number : "")+" ")])}),_c('span',{staticStyle:{"color":"#212427!important"}},[_vm._v(_vm._s(number ? number : 0))])],2)}),0)],1),_c('TableResult',{staticClass:"hide-lotto-on-mobile",attrs:{"passLastTwentyResults":_vm.passLastTwentyResults}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }