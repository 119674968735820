import { mapMutations } from "vuex";
export default {
    methods: {
      _disconnectSocketCasinoLive(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
      },
      _connectSocketCasinoLive(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
        this.$connectSocket();
        this.listenerSocketCasinoLive()
      },
      listenerSocketCasinoLive() {
        
        this.$globalSocket.on("client-update-user-info", (data) => {
          if (data) {
            if (data.is_suspend) {
              this.$toastr.e(`Your account have been blooked!`)
              this.onLogout()
            }
            // data.channel_list = this.channelList;
            // this.updateUserInfo(data);
            this.updateBalance(data.balance);
          }
        });
        this.$globalSocket.on("client-status-channel-type", (data) => {
          if (data) {
            this.UPDATE_CHANNEL_STATUS(data);
          }
        });
        this.$socket.on("client-update-channel-info", (data) => {
          
          // console.log(`socket on client-update-channel-info: ${JSON.stringify(data)}`);
          if (data) {
            if (data.is_open) {
              this.playSound('place_your_bet_please_3.m4a')
            }
            this.updateChannel(data);
          }
        });
        this.$socket.on("client-status-channel-type", (data) => {
          // console.log(`socket on client-status-channel-type: ${JSON.stringify(data)}`);
          if (data) {
            // console.log(`socket on client-status-channel-type: ${JSON.stringify(data)}`);
            this.UPDATE_CHANNEL_STATUS(data);
          }
        });
        this.$socket.on("client-defind-result-and-new-channel", (data) => {
          console.log(`socket on client-defind-result-and-new-channel: ${JSON.stringify(data)}`);
   
          if (data) {
            this.updateResult(data);
            this.fetchCurrentBetting();
            this.fetchTodayBetting();
            this.fetchUserInfo();
            this.RESET_CARD();
          }
        });
        this.$socket.on("client-update-user-info", (data) => {
          // console.log(`socket on client-update-user-info: ${JSON.stringify(data)}`);
          if (data) {
            if (data.is_suspend) {
              this.$toastr.e(`Your account have been blooked!`)
              this.onLogout()
            }
            this.updateBalance(data.balance);
          }
        });
        this.$socket.on("client-update-betting-amount", (data) => {
          // console.log(`socket on client-update-betting-amount: ${JSON.stringify(data)}`);
          if (data) {
            if (this.channel.is_open) {
              this.fetchCurrentBetting();
              let currentcy = this.userInfor.currency_type - 1;
              this.UPDATE_AMOUNT(data[currentcy]);
            }
          }
        });
        this.$socket.on("client-update-payout", (data) => {
          // console.log(`socket on client-update-payout: ${JSON.stringify(data)}`);
          // console.log(`socket on client-update-payout channel: ${this.channel.is_open}`);
          if (data) {
            // console.log(`socket on client-update-payout type: ${this.userInfor.currency_type} :type:${data.currency_type}`);
            if (this.channel.is_open || this.channel.is_open == null) {
              if (this.userInfor.currency_type == data.currency_type) {
                this.UPDATE_PAYOUT(data);
              }
            }
          }
        });
        this.$socket.on("client-reserves-result", (data) => {
          // console.log(`socket on client-reserves-result: ${JSON.stringify(data)}`);
          if (data) {
            this.UPDATE_TODAY_BETTING(data);
          }
        });
        this.$socket.on("client-reset-amount", (data) => {
          // console.log(`socket on client-reset-amount: ${JSON.stringify(data)}`);
          if (data) {
            this.RESET_AMOUNT();
            if (this.userInfor.currency_type == 1) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_usd)
            } else if (this.userInfor.currency_type == 2) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_kh)
            } else if (this.userInfor.currency_type == 3) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_tb)
            }
          }
        });
        if (this.$cookies.get('sbc_live_channel') != 15 || this.$cookies.get('sbc_live_channel') != 16 || this.$cookies.get('sbc_live_channel') != 1 || this.$cookies.get('sbc_live_channel') != 2 || this.$cookies.get('sbc_live_channel') != 3 || this.$cookies.get('sbc_live_channel') != 4) {
          this.$socket.on("client-start-count-down", (data) => {
            if (data) {
              this.UPDATE_COUNTDOWN(data);
              if (data.countDown == 6) {
                this.playSound('no_more_bet_en.m4a');
              }
              if (data.countDown <= 10 && data.countDown >= 5) {
                this.playSound('ting1s.mp3');
                this.RESET_CARD();
              }
            }
          });
        }
        this.$socket.on("client-scan-player", data => {
          if (data) {
            this.playSound('bet-touch.mp3');
            if (this.$cookies.get('sbc_channel_type') == 6
              || this.$cookies.get('sbc_channel_type') == 7) this.UPDATE_PLAYER_CARD(data);
            if (this.$cookies.get('sbc_channel_type') == 8
              || this.$cookies.get('sbc_channel_type') == 9) this.UPDATE_DRAGON_CARD(data.image);
          }
        });
        this.$socket.on("client-scan-banker", data => {
          if (data) {
            this.playSound('bet-touch.mp3');
            if (this.$cookies.get('sbc_channel_type') == 6
              || this.$cookies.get('sbc_channel_type') == 7) this.UPDATE_BANKER_CARD(data);
            if (this.$cookies.get('sbc_channel_type') == 8
              || this.$cookies.get('sbc_channel_type') == 9) this.UPDATE_TIGER_CARD(data.image);
          }
        });
        this.$socket.on("client-scan-result", (data) => {
          if (data) {
            if (this.$cookies.get('sbc_channel_type') == 5) this.UPDATE_KLAKLOUK_CARD(data);
            if (this.$cookies.get('sbc_channel_type') == 10) this.UPDATE_LOTTO_CARD(data);
            if (this.$cookies.get('sbc_channel_type') == 11) {
              this.UPDATE_YUKI_CARD(data);
            }
            if (this.$cookies.get('sbc_channel_type') == 12 || this.$cookies.get('sbc_channel_type') == 17) this.UPDATE_ABONG_CARD(data);
            if (this.$cookies.get('sbc_channel_type') == 13) this.UPDATE_FANTAN(data);
            if (this.$cookies.get('sbc_channel_type') == 14) this.UPDATE_TAISIV(data);
          }
        });
      },
      ...mapMutations("$_casino_live", [
        "UPDATE_CHANNEL_STATUS",
      ]),
    },
}