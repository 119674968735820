<template>
  <div class="keno-right">
    <v-row class="ma-0">
      <v-col cols="12" sm="12" md="6" class="pa-0">
        <DisplayEightyResultAnimation :passClientRandom="passClientRandom" :passLastTwentyResults="passLastTwentyResults" :passRandomNumber="passRandomNumber"/>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pa-0">
        <BettingType :passClientRandom="passClientRandom" :passRandomNumber="passRandomNumber"/>
      </v-col>
      <InputAmountSection/>
      <TableResult :passLastTwentyResults="passLastTwentyResults"/>
    </v-row>
  </div>
</template>

<script>

import DisplayEightyResultAnimation from "./SubComponent/RightComponents/DisplayEightyResultAnimation.vue";
import BettingType from "./SubComponent/RightComponents/BettingType.vue";
import InputAmountSection from "./SubComponent/RightComponents/InputAmountSection.vue";
import TableResult from './SubComponent/RightComponents/TableResult.vue';
export default {
  components: {
    DisplayEightyResultAnimation,
    BettingType,
    InputAmountSection,
    TableResult
  },
  props: [
    "passClientRandom", "passLastTwentyResults", 'passRandomNumber'
  ],
  data() {
    return {
    };
  },
};
</script>
