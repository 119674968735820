<template>
  <v-row no-gutters>
    <v-col cols="12">
      <!-- {{ getMultiBetNumber }}
      {{ getSelectedChannelPost }}{{ getTotalInvoice }} -->
      <div style="margin: 5px 0px;">
        <div style="width: 100%;">
          <v-btn style="font-size: 1.6rem;" @click="selectLo()" :class="btnLoSelected ? 'btn-lotery3-all-lo-dis' : 'btn-lotery3-all-lo'" prepend-icon="$vuetify">
            LO
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-row style="margin: 5px 0px;">
        <div class="lottery3d-channel-column">
          <v-btn style="font-size: 1.6rem;" @click="selectChannel('a')" :class="btnASelected ? 'btn-lotery3-channel-dis' : 'btn-lotery3-channela'">
            A
          </v-btn>
        </div>
        <div class="lottery3d-channel-column">
          <v-btn style="font-size: 1.6rem;" @click="selectChannel('b')" :class="btnBSelected ? 'btn-lotery3-channel-dis' : 'btn-lotery3-channelb'">
            B
          </v-btn>
        </div>
        <div class="lottery3d-channel-column">
          <v-btn style="font-size: 1.6rem;" @click="selectChannel('c')" :class="btnCSelected ? 'btn-lotery3-channel-dis' : 'btn-lotery3-channelc'">
            C
          </v-btn>
        </div>
        <div class="lottery3d-channel-column">
          <v-btn style="font-size: 1.6rem;" @click="selectChannel('d')" :class="btnDSelected ? 'btn-lotery3-channel-dis' : 'btn-lotery3-channeld'">
            D
          </v-btn>
        </div>
        <div  class="lottery3d-channel-column">
          <v-btn style="font-size: 1.6rem;" @click="selectChannel('e')" :class="btnESelected ? 'btn-lotery3-channel-dis' : 'btn-lotery3-channele'">
            E
          </v-btn>
        </div>
      </v-row>
    </v-col>
    <v-col cols="12">
      <div style="margin: 5px 0px;">
        <div style="width: 100%;">
          <v-btn style="font-size: 1.6rem;" @click="selectAll()" :class="btnAllSelected ? 'btn-lotery3-all-lo-dis' : 'btn-lotery3-all-lo'"  prepend-icon="$vuetify">
            ALL(A, B, C, D, E)
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-row style="margin: 5px 0px;">
        <div style="width: 50%;">
          <v-btn style="font-size: 1.6rem;" @click="selectNormal()" :disabled="btnNormalSelected" class="btn-lotery3-50" prepend-icon="$vuetify">
            ធម្មតា
          </v-btn>
        </div>
        <div style="width: 50%;">
          <v-btn style="font-size: 1.6rem;" @click="selectMulti()" :disabled="btnMultiSelected" class="btn-lotery3-50" prepend-icon="$vuetify">
            គុណ
          </v-btn>
        </div>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row style="margin: 5px 0px;" class="betting-number-box">
        <div  style="width: 90%;">
          <p style="padding-top: 7px;" class="betting-number-text">{{numberTemp}}</p>
        </div>
        <span style="width: 10%; ">
          <v-icon  style="width: 48px; height: 100%;" @click="handleClear">mdi-history</v-icon>
        </span>
      </v-row>
    </v-col>
    <!-- <v-col cols="12" class="plus-content-right-textbox">
      <ul>
          <li class="input-bet mt-3">
            <input
              :value="amount"
              placeholder="ENTER BET AMOUNT"
              type="number"
              pattern="[0-9]*"
              min="1"
              onlynumber="true"
              @input="event => inputAmount(event.target.value)"
              style="border-radius: 5px"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />
            <span><v-icon @click="handleClear">mdi-history</v-icon></span>
          </li>
        </ul>
    </v-col> -->
    <v-col cols="12">
      <v-row style="margin: 5px 0px;">
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(0)" class="btn-lotery3-cus">
            0
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(1)" class="btn-lotery3-cus">
            1
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(2)" class="btn-lotery3-cus">
            2
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(3)" class="btn-lotery3-cus">
            3
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(4)" class="btn-lotery3-cus">
            4
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(5)" class="btn-lotery3-cus">
            5
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(6)" class="btn-lotery3-cus">
            6
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(7)" class="btn-lotery3-cus">
            7
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(8)" class="btn-lotery3-cus">
            8
          </v-btn>
        </div>
        <div class="lottery3d-betting-number">
          <v-btn style="font-size: 1.6rem;" @click="numSelect(9)" class="btn-lotery3-cus">
            9
          </v-btn>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
  import { mapGetters, mapMutations } from "vuex";
  export default {
      data() {
          return {
            betNumber: "",
            numberTemp: "",
            results :[]
              // countDown: {},
          }
      },
      computed: {
          ...mapGetters("$_lottery3d", {
            getLotteryChannel: "getLotteryChannel",
            getBettingAmount: "getBettingAmount",
            getNormalBetNumber: "getNormalBetNumber",
            getMultiBetNumber: "getMultiBetNumber",
            getSelectedChannelPost: "getSelectedChannelPost",
            btnMultiSelected: "getselectedMulti",
            btnLoSelected: "getselectedLo",
            getTotalInvoice: "getTotalInvoice",
            btnNormalSelected: "getselectedNormal",
            btnAllSelected: "getSelectAllPost",
            btnASelected:"getselectedA",
            btnBSelected:"getselectedB",
            btnCSelected:"getselectedC",
            btnDSelected:"getselectedD",
            btnESelected:"getselectedE",
            getClearBettingNumber:"getClearBettingNumber"
          }),
      },
      watch: {
        getClearBettingNumber: async function () {
          this.handleClear();
        },
      },
      methods: {
        selectChannel(channel){
          if(this.betNumber.length == 3){
            this.UPDATED_CHANNEL_POST(channel)
            if(channel == 'a'){
              this.SET_BET_A(!this.btnASelected);
            }else if(channel == 'b'){
              this.SET_BET_B(!this.btnBSelected);
            }else if(channel == 'c'){
              this.SET_BET_C(!this.btnCSelected);
            }else if(channel == 'd'){
              this.SET_BET_D(!this.btnDSelected);
            }else if(channel == 'e'){
              this.SET_BET_E(!this.btnESelected);
            }
          }else{
            this.$toastr.e("Please input number!")
          }
        },
        selectAll(){
          if(this.betNumber.length == 3){
            this.UPDATED_SELECT_ALL_CHANNEL_POST();
          }else{
            this.$toastr.e("Please input number!")
          }
        },
        selectLo(){
          if(this.betNumber.length == 3){
            this.UPDATE_SELECTED_LO()
            if(this.btnLoSelected){
              // this.UPDATED_SELECT_ALL_CHANNEL_POST(this.btnAllSelected)
              this.SET_BET_A(false);
              this.SET_BET_B(false);
              this.SET_BET_C(false);
              this.SET_BET_D(false);
              this.SET_BET_E(false);
            }
          }else{
            this.$toastr.e("Please input number!")
          }
        },
        selectNormal(){
          this.results = [];
          this.numberTemp = this.betNumber;
          this.UPDATE_SELECTED_MULTI(false);
          this.SET_MULTI_BET_NUMBER([this.betNumber]);
          this.UPDATE_SELECTED_NORMAL(true);
        },
        selectMulti(){
          if(this.betNumber.length == 3){
            let temp = "";
            for (const perm of this.subsetPermutations(this.betNumber)) {
              this.results.push(perm.join(""));
              temp = temp + " " + perm.join("");
            }
            this.numberTemp = temp;
            this.SET_MULTI_BET_NUMBER(this.results);
            this.UPDATE_SELECTED_NORMAL(false);
            this.UPDATE_SELECTED_MULTI(true);
          }else{
            this.$toastr.e("Please input number!")
          }
        },
        numSelect(num){
          if(this.betNumber.length<3){
            this.betNumber = this.betNumber + num;
            this.numberTemp = this.betNumber;
            if(this.betNumber.length == 3){
              this.SET_NORMAL_BET_NUMBER([this.betNumber]);
              this.SET_MULTI_BET_NUMBER([this.betNumber])
            }
          }
        },
        handleClear() {
          this.betNumber = "";
          this.numberTemp = "";
          this.RESET_BETTING()
        },
        subsetPermutations(number) {
          const counts = {};
          const size = 3;
          const arr = Array.from(number);
          for (const el of arr) {
              counts[el] = (counts[el] ?? 0) + 1;
          }
          const unique = Object.keys(counts);
          const result = Array.from({length: size});
          function* recurse(depth) {
              if (depth == size) {
                  yield result;
              } else {
                  for (const el of unique) {
                      if (counts[el]) {
                          result[depth] = el;
                          counts[el]--;
                          yield* recurse(depth+1)
                          counts[el]++;
                      }
                  }
              }
          }
          return recurse(0);
        },
        ...mapMutations("$_lottery3d", [
          "SET_NORMAL_BET_NUMBER",
          "SET_MULTI_BET_NUMBER",
          "UPDATED_CHANNEL_POST",
          "UPDATE_SELECTED_NORMAL",
          "UPDATED_SELECT_ALL_CHANNEL_POST",
          "UPDATE_SELECTED_MULTI",
          "UPDATE_SELECTED_LO",
          "SET_BET_A",
          "SET_BET_B",
          "SET_BET_C",
          "SET_BET_D",
          "SET_BET_E",
          "RESET_BETTING"
        ]),
    }
  }
</script>
<style scoped>
.btn-lotery3-cus{
  width: 95%;
  margin: 0 2px;
  background-color: #013064 !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-all-lo{
  width: 99.5%;
  margin: 0 2px;
  background-color: #013064 !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-all-lo-dis{
  width: 99.5%;
  margin: 0 2px;
  background-color: #898989 !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-50{
  width: 99%;
  margin: 0 2px;
  background-color: #013064 !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-channela{
  width: 98%;
  margin: 0 2px;
  background-color: rgb(244, 201, 5) !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-channelb{
  width: 98%;
  margin: 0 2px;
  background-color: rgb(29, 203, 38) !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-channelc{
  width: 98%;
  margin: 0 2px;
  background-color: rgb(199, 5, 210) !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-channeld{
  width: 98%;
  margin: 0 2px;
  background-color: rgb(213, 2, 1) !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-channele{
  width: 98%;
  margin: 0 2px;
  background-color: rgb(2, 195, 183) !important;
  color: white !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.btn-lotery3-channel-dis{
  width: 98%;
  margin: 0 2px;
  color: white !important;
  background-color: #898989 !important;
  font-size: 1.9rem;
  font-weight: 800;
}
.betting-number-box{
  width: 100%; 
  height: 48px;
  color: #013064;
  border: 3px solid #013064;
  border-radius: 8px;
  text-align: center;
  line-height: 27px;
  margin-top: 0px;
}
.betting-number-text{
  color: #013064;
  font-size: 1.9rem;
  font-weight: 800;
}

.plus-content-right-textbox {
  padding-top: 16px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.plus-content-right-textbox ul {
  list-style: none;
  padding-left: 0 !important;
}
.plus-content-right-textbox ul li:first-child {
  color: white;
}
.plus-content-right-textbox ul li .item-right {
  float: right;
}
.plus-content-right-textbox .text-yellow {
  color: yellow;
}
.plus-content-right-textbox .text-bg {
  font-size: 0.85rem;
}
.plus-content-right-textbox .text-sm {
  font-size: 0.85rem;
}
.plus-content-right-textbox ul .input-bet {
  padding-top: 6px;
  position: relative;
}
.plus-content-right-textbox ul .input-bet input {
  border: 1px solid #013064;
  padding: 8px 62px 8px 8px;
  width: 100%;
  color: #013064 !important;
  border-radius: 5px;
}

.plus-content-right-textbox ul .input-bet span {
  border: 1px solid #013064;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 62px;
  height: 42px;
  position: absolute;
  right: 0;
  text-align: center;
  line-height: 30px;
}
.plus-content-right-textbox ul .input-bet span button {
  color: yellow;
  font-size: 2.5rem;
}
.plus-content-right-textbox ul .input-bet span:hover {
  cursor: pointer;
}
</style>
