/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'
let request = new Request;
const fetchLastResults = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_keno/result',
        data
    })
    if (response.data.code) {
        let getLastFiveResults = response.data.data.slice(0, 5);
        commit('LATEST_FIVE_RESULTS', getLastFiveResults);
        commit('LATEST_TWENTY_RESULTS', response.data.data);
        commit('LATEST_ONE_RESULTS',  response.data.data[0]);

    }
};

const fetchButton = async ({commit}) => {
    const response = await request.getSlot({
        url: 'm_keno/button'
    })
    if (response.data.code) {
        commit('GET_BUTTON', response.data.data);
       
    }
};


const fetchCurrentBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_keno/betting/current',
        data
    })
    if (response.data.code) {
        commit('CURRENT_BETTING', response.data.data);
    }
};

const fetchPreviousBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_keno/betting/previous',
        data
    })
    if (response.data.code) {
        commit('PREVIOUS_BETTING', response.data.data);
    }
};

const fetchCurrentGame = async ({commit}, data) => {
    
    const response = await request.getSlot({
        url: 'm_keno/game',
        data
    })
    if (response.data.code) {
        commit('CURRENT_GAME', response.data.data);
    }
};

const fetchBettingType = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_keno/type',
        data
    })
    if (response.data.code) {
        
        commit('BUTTON_TYPE', response.data.data);
    }
};

const createBet = async ({commit}, { data}) => {
    commit('IS_BETTING', true);
    const response = await request.postSlot({
        url: 'm_keno/betting',
        data
    })
    if (response.data.code) {
        commit('IS_BETTING', false);
        commit('APPEND_CURRENT_BETTING', response.data.data);
        return response
    }else{
        commit('IS_BETTING', false);
        return response
    }
};
const fetchLimit = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_keno/limit',
        data
    })
    if (response.data.code) {
        commit('UPDATE_KENO_LIMIT', response.data.data);
    }
};
const fetchDetailInvoice = async ({commit}, data) => {
    commit('UPDATE_LOADING_DIALOG_INVOICE', true);
    const filter = data.data;
    const id = data.id
    const response = await request.getSlot({
        url: `m_keno/report/detail?module_bet_id=${id}`,
        filter
    })
    if (response.data.code) {
        commit('LIST_DETAIL_INVOICE', response.data.data);
        commit('UPDATE_DIALOG_INVOICE', true);
    }
    commit('UPDATE_LOADING_DIALOG_INVOICE', false);
};


export default {
    fetchDetailInvoice,
	fetchLastResults,
    fetchCurrentBetting,
    fetchPreviousBetting,
    fetchCurrentGame,
    fetchBettingType,
    createBet,
    fetchButton,
    fetchLimit
};
