<template>
  <div class="pcoded-main-container">
    <div class="main-body card-to-center">
      <div class="page-wrapper" style="overflow:hidden">
        <div class="page-body" style="overflow:hidden; po">
          <v-card elevation="2" outlined shaped class="linea-container mx-auto pb-6" style="max-width:800px;">
            <v-card-header class="withdraw-card-header">
              <v-icon left class="txt-white">mdi-cash-multiple</v-icon> {{ $t('title.withdraw') }}
            </v-card-header>
            <template>
          <v-form class="position-relative">
            <v-container >
              <v-row class="form-position" style="margin-top: 0px !important">
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model.number="withdrawAmount"
                    :label="$t(`title.amount`)+' '+userInfor.currencySymbol"
                    outlined
                    type="number"
                    required
                    :rules="[numberRule]"
                    class="input-field-style"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="withdrawNote"
                    :label="$t('title.note')"
                    outlined
                    required
                    class="input-field-style"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  style="width: 100%; text-align: center"
                >
                  <v-btn
                  style="width: 70vw; margin: auto; max-width: 400px;"
                    @click="createWithdraw()"
                    :loading="loading"
                    class="btn btn-danger"
                  >
                    <span class="txt-black">
                      {{ $t("button.submit") }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
      </v-card>
    </div>
  </div>
</div>
</div>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s | WITHDRAW REQUEST`
    },
  data() {
    return {
      aba:"aba.png",
      depositAmount: 0,
      withdrawAmount: 0,
      depositNote: "",
      withdrawNote: "",
      selectedAgent: null,
      loading: false,
      dialog: false,
      requestDeposit: {
        agent_account_id: null,
        amount: null,
        note: null
      },
      requestWithdraw: {
        amount: null,
        note: null
      },
      numberRule: amount => {
        if(amount < 0) return 'Please enter a positive number'
        return true
      }
    };
  },
  item: {
    type: Object,
    default: null,
  },
  async created() {
    await this.getAgentAccountList();
  },
  watch: {
    dataIsUpdateUser: function () {},
    dataIsChangePwd: function () {},
  },
  computed: {
    ...mapGetters("$_myinfo", {
      dataReportBetting: "getReportBetting",
      dataReportWin: "getReportWin",
      dataReportStatement: "getReportStatement",
      dataIsUpdateUser: "getIsUpdateUser",
      dataIsChangePwd: "getIsChangePwd",
      bankList: "getBankList"
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
      getAgentList: "getAgentAccountList",
      userNotList: "getNotList",
    }),
    ...mapGetters("$_myinfo", ["getLoading"]),
  },
  methods: {
    async getAgentAccountList() {
      await this.fetchAgentAccountList();
    },
    async createDeposit() {
      if(!this.selectedAgent){
          this.$toastr.e(this.$t('title.inputAmount'));
      }else{
        if(this.depositAmount <= 0){
          this.$toastr.e(this.$t('title.inputAmount'));
        }else{
          try {
            this.loading = true;
            this.requestDeposit = {
              agent_account_id: this.selectedAgent._id,
              amount: parseInt(this.depositAmount),
              note: this.depositNote
            }
            const response = await this.fetchCreateDeposit(this.requestDeposit);
            if (response.data.code) {
              this.$toastr.s(this.$t('title.SUCCESS'));
              this.selectedAgent = null;
              this.depositAmount = 0;
              this.depositNote = "";
              this.UPDATE_DEPOSIT(response.data.data);
              await this.getNotification();
              await this.fetchUserInfo();
              this.loading = false;
            } else {
              this.$toastr.e(this.$t('title.PENDING'));
              if(response.data.message.code == 204){
                await this.$router.push({ path: "/my-info" });
              }
              this.error = true;
            }
          } catch (error) {
            this.error = true;
            return error;
          } finally {
            this.loading = false;
          }
        }
      }
    },
    async createWithdraw() {
      if(this.withdrawAmount <= 0){
        this.$toastr.e(this.$t('title.inputAmount'));
      }else{
        try {
          this.loading = true;
          this.requestWithdraw = {
            amount: parseInt(this.withdrawAmount),
            note: this.withdrawNote
          }
          const response = await this.fetchCreateWithdraw(this.requestWithdraw);
          if (response.data.code) {
            this.$toastr.s(this.$t('title.SUCCESS'));
            this.UPDATE_WITHDRAW(response.data.data)
            await this.getNotification();
            await this.fetchUserInfo();
            this.loading = false;
            this.withdrawAmount = 0;
            this.withdrawNote = "";
          } else {
            this.$toastr.e(this.$t('title.PENDING'));
            if(response.data.message.code == 204){
              await this.$router.push({ path: "/my-info" });
            }
            this.error = true;
          }
        } catch (error) {
          this.error = true;
          return error;
        } finally {
          this.loading = false;
        }
      }
      
    },
    async getNotification(){
            let data = {
            rows_per_page: 6,
            page: 1
            };
            this.UPDATE_NOTI_DATA([]);
            await this.fetchNotList(data);
            await this.fetchNotCount();
            this.UPDATE_NOTI_DATA(this.userNotList.reports);
        },  
    ...mapMutations("$_modules", [
      "UPDATE_USER_INFO",
      "UPDATE_DEPOSIT",
      "UPDATE_WITHDRAW",
      "UPDATE_NOTI_DATA",
    ]),
    ...mapMutations("$_myinfo", [
      "UPDATE_MY_INFO",
      "CHANGE_PASSWORD",
      "IS_UPDATE_USER",
      "IS_CHANGE_PASSWORD"
    ]),
    ...mapActions("$_modules", [
      "fetchAgentAccountList",
      "fetchCreateDeposit",
      "fetchCreateWithdraw",
      "fetchNotCount",
      "fetchNotList",
      "refreshNotiList",
      "fetchUserInfo"
    ]),
    ...mapActions("$_myinfo", [
      "fetchMyInfo",
      "fetchUpdateMyInfo",
      "fetchChangePassword",
      "fetchBankList"
    ]),
  },
};
</script>
<style>
.input-field-style input:focus{
  padding:0px!important;
}
.input-field-style input{
  padding:0px!important;
}
.v-tabs-bar {
  background: rgba(0, 0, 0, 0.2) !important;
  color: white !important;
}

.theme--light.v-tabs-items {
  background: rgba(0, 0, 0, 0.0) !important;
}

@media (max-width: 649px) {
  .tab-container {
    max-width: 100%;
    margin: auto;
    padding-top: 0px;
  }

  .input-field-style {
    max-width: 100%;
    width: 70vw;
    margin: auto !important;
  }

  .btn-submit-style {
    width: 70vw;
    background-color: rgba(255, 128, 171, 0.5) !important;
    border-color: 2px solid rgba(77, 16, 36, 0.7) !important;
  }
  .v-slide-group__wrapper {
    /* margin-left:-35px!important; */
  }
  .cl-icon {
    display: none;
  }
}

@media (min-width: 650px) {
  .tab-container {
    max-width: 100%;
    margin: auto;
    margin-top: -6px;
  }

  .input-field-style {
    max-width: 100%;
    width: 400px;
    margin: auto !important;
  }

  .btn-submit-style {
    width: 400px;
    background-color: rgba(255, 128, 171, 0.5) !important;
    border-color: 2px solid rgba(77, 16, 36, 0.7) !important;
  }
}

.txt-white {
  color: white !important;
}

.card-backround {
  background-color: #132148;
  border-radius: 0px;
}

.form-position {
  margin-top: 70px !important;
}
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(256, 256, 256, 0.33) !important;
}
.v-label {
  color: rgba(256, 256, 256, 0.33) !important;
}
.theme--light.v-counter {
  color: rgba(256, 256, 256, 0.33) !important;
}

.custom-placeholer-color input::placeholder {
  color: white !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: white;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: white !important;
}

.v-label {
  color: white !important;
}


.v-messages__message {
    color: white !important;
}

.withdraw-card-header {
  display: block;
  color: white;
  background: linear-gradient(25deg, #f32525, rgb(224, 32, 51) 50%);
  min-width: 100%;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  text-shadow: -1px 0 7px black;
  font-size: 1.3rem;
}
</style>