export default {
    ticket_id : "លេខសំបុត្រ",
	game_id: "លេខហ្គាម",
	type_betting : "ប្រភេទនៃការភ្នាល់",
	bet_point : "ផលសង",
	win_loss: "ឈ្នះ/ចាញ់",
	print:"បោះពុម្ព",
	cancel :"បោះបង់",
	account : 'គណនី',
	invoice_no:"លេខ សំបុត្រ",
	number:"លេខ",
	time:"ម៉ោង",
	bet_point2:"ការភ្នាល់",
	date:"កាលបរិច្ឆេទ"
};
