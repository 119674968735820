/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'
let request = new Request;

const fetchChannel = async ({commit}, data) => {
    const response = await request.get({
        url: 'channel',
        data
    })
    if (response.data.code) {
        
        if(response.data.data.results) commit('UPDATE_KLAKLOUK', response.data.data.results);
        commit('UPDATE_AMOUNT_MIN', response.data.data.amount_min);
        commit('UPDATE_AMOUNT_MX', response.data.data.amount_max);
        commit('LATEST_CHANNEL', response.data.data);
        commit('UPDATE_CHANNEL_STATUS', response.data.data.channel_status);
    }
};
const getCockXCasinoGameButton = async ({commit}, data) => {
    const response = await request.get({
        url: 'cock_casinolive/button',
        data
    })
    if (response.data.code) {
        commit('UPDATE_COCK_X_CASINO_BUTTON', response.data.data);
    }
};
const fetchNumberMaster = async ({commit}, data) => {
    const response = await request.get({
        url: 'number',
        data
    })
    if (response.data.code) {
        commit('LATEST_NUMBER_MASTER', response.data.data);
    }
};
const fetchBettingTypeMaster = async ({commit}, data) => {
    const response = await request.get({
        url: 'type',
        data
    })
    if (response.data.code) {
        commit('LATEST_BETTING_TYPE_MASTER', response.data.data);
    }
};
const fetchResult = async ({commit}, data) => {
    const response = await request.get({
        url: 'cock_casinolive/result',
        data
    })
    if (response.data.code) {
        commit('LATEST_RESULT', response.data.data);
    }
};
const fetchTodayBetting = async ({commit}, data) => {

    const response = await request.get({
        url: 'betting/today',
        data
    })
    if (response.data.code) {
        commit('TODAY_BETTING', response.data.data);
 
    }
};
const fetchCurrentBetting = async ({commit}, data) => {
    commit('LOADING_TODAY_REPORT', true);
    const response = await request.get({
        url: 'betting/current',
        data
    })
    if (response.data.code) {
        commit('CURRENT_BETTING', response.data.data);
    }
    commit('LOADING_TODAY_REPORT', false);
};
const fetchButton = async ({commit}, data) => {
    const response = await request.get({
        url: 'button',
        data
    })
    if (response.data.code) {
        commit('LATEST_BUTTON', response.data.data);
    }
};
const fetchCreateBetting = async ({commit}, data) => {
    commit('BETTING_DIALOG', true);
    return await request.post({
        url: 'betting',
        data
    })
};

const updateCurrentBetting = async({ commit }, bettingItem) => {
    commit('UPDATE_CURRENT_BETTING', bettingItem);
};
const updateChannel = async({ commit }, channel) => {
    commit('UPDATE_CHANNEL', channel);
};
const updateResult = async({ commit }, result) => {
    commit('UPDATE_RESULT', result);
};
const fetchCockXCasinoPayout = async ({}, data) => {
    const channelType = data;
    const response = await request.getMachine({
        url: 'weight',
        data,
        channelType
    })
    if (response.data.code) {
        return response
    }
};

const createBetting = async ({commit}, {vm, data}) => {
    const channelType = data.channelType
    commit('LOADING', true);
    const response = await request.postMachine({
        url: 'cock_casinolive/betting',
        data,
        channelType
    })
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`);
        commit('LOADING', false);
        return response
    }else{
        vm.$toastr.e(`${response.data.message.descriptions}`)
        commit('LOADING', false);
        return response
    }
};
const fetchMaBaChannel= async ({}, channelType) => {
    //console.log(`fetchMaBaResult >>>>${channelType}`)
    return await request.getMachine({
        url: 'channel',
        channelType
    })
    
};
const fetchMaBaResult = async ({}, channelType) => {
    //console.log(`fetchMaBaResult >>>>${channelType}`)
    return await request.getMachine({
        url: 'cock_casinolive/result',
        channelType
    })
    
};
const fetchMachineResult = async ({commit}, data, channelType) => {
    //header{channel-type}
    const response = await request.getMachine({
        url: 'cock_casinolive/result',
        data,
        channelType
    })
    if (response.data.code) {
        commit('UPDATE_MACHINE_RESULT', response.data.data);
    }
};
const fetchStatement = async ({commit}, data) => {
    //body{rows_per_page,page} header{channel-type}
    const response = await request.postMachine({
        url: 'cock_casinolive/statement',
        data
    })
    if (response.data.code) {
        commit('UPDATE_COCK_X_CASINO_STATEMENT', response.data.data);
    }
};
const fetchLoadMoreMachineStatement = async ({commit}, data) => {
    //body{rows_per_page,page} header{channel-type}
    const response = await request.postMachine({
        url: 'cock_casinolive/statement',
        data
    })
    if (response.data.code) {
        commit('UPDATE_STATEMENT_LOADMORE_DATA', response.data.data);
    }
};
const fetchVideoToken = async ({}, channelType) => {
    return await request.getMachine({
        url: 'user/video',
        channelType
    })
};
const fetchChannelInfo = async ({}, channelType) => {
    return await request.getMachine({
        url: 'channel',
        channelType
    })
};


export default {
    getCockXCasinoGameButton,
    fetchLoadMoreMachineStatement,
	fetchChannel,
    fetchNumberMaster,
    fetchBettingTypeMaster,
    fetchResult,
    fetchTodayBetting,
    fetchCurrentBetting,
    fetchCreateBetting,
    updateCurrentBetting,
    updateChannel,
    updateResult,
    fetchCockXCasinoPayout,
    fetchButton,
    fetchVideoToken,
    createBetting,
    fetchMachineResult,
    fetchStatement,
    fetchMaBaResult,
    fetchChannelInfo,
    fetchMaBaChannel
};
