import LayoutRegister from '@/layouts/login.vue';
import Register from '../../../modules/FolderAccounts/Register';

const routes = [
    {
        path: '',
        component: LayoutRegister,
        children: [
            {
                path: 'register',
                name: 'register',
                component: Register
            }
        ]
    }
]

export default  routes ;