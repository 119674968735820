const getMyInfo = (state) => state.myInfo;
const getUpdateMyInfo = (state) => state.updateMyInfo;
const getChangePassword = (state) => state.changePassword;
const getReportBetting = (state) => state.reportBetting;
const getReportWin = (state) => state.reportWin;
const getReportStatement = (state) => state.reportStatement;
const getLoading = (state) => state.loading;
const getUserInfo = (state) => state.updateUserInfo;
const getIsUpdateUser = (state) => state.isUpdateUser;
const getIsChangePwd = (state) => state.isChangePwd;
const getBankList = (state) => state.bankList;

export default {
	getLoading,
	getMyInfo,
	getUpdateMyInfo,
	getChangePassword,
	getReportBetting,
	getReportWin,
	getReportStatement,
	getUserInfo,
	getIsUpdateUser,
	getIsChangePwd,
	getBankList
};
