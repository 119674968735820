<template>
    <div class="bullet-container">
        <BulletLabel :bulletLabelColor="getBulletColor(passSelectedChannel, 'meron')"
            :bulletLabelValue="results.summary.MERON" :bulletLabelName="$t('title.banker')" />
        <BulletLabel :bulletLabelColor="getBulletColor(passSelectedChannel, 'wala')"
            :bulletLabelValue="results.summary.WALA" :bulletLabelName="$t('title.player')" />
        <BulletLabel :bulletLabelColor="getBulletColor(passSelectedChannel, 'tie')"
            :bulletLabelValue="results.summary.TIE" :bulletLabelName="$t('title.draw')" />
        <BulletLabel :bulletLabelColor="'bg-secondary'" :bulletLabelValue="results.summary.CANCEL"
            :bulletLabelName="$t('title.cancel')" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import "~css/in_game/bullet/share_screen_style.css";
import "~css/in_game/bullet/screen_max_480.css";
import "~css/in_game/bullet/screen_min_481.css";
import "~css/in_game/bullet/screen_min_768.css";
import "~css/in_game/bullet/screen_min_993.css";
import "~css/in_game/bullet/screen_min_1025.css";
import "~css/in_game/bullet/screen_min_1281.css";
import "~css/in_game/bullet/screen_min_1441.css";
import "~css/in_game/bullet/screen_min_1661.css";
import "~css/in_game/bullet/screen_min_1824.css";

import bullet_label_mixin from "~mixin/bullet_label";
import BulletLabel from "~global_components/BulletLabel.vue";
export default {
    mixins: [bullet_label_mixin],
    components: { BulletLabel },

    computed: {
        ...mapGetters("$_casino_live", {
            results: "getResult",
        }),
        ...mapGetters("$_modules", {
            passSelectedChannel: "getChannelId"
        }),
    },
};
</script>