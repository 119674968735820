<template>
  <div class="pcoded-main-container">

        <div class="page-body" style="overflow: hidden">
          <DesktopScreenContainer
            v-on:sentSubmitBetting="createBetting"
            :passSelectedChannel="channelSelected"
            :passBettingTypeMaster="bettingTypeMaster"
            :passChannelStatus="channelStatus"
            v-on:sentSetChannel="setChannelType"
          >
          </DesktopScreenContainer>
        </div>

  </div>
</template>
<script>
import "@/assets/css/cockfight/screen_max_480.css";
import "@/assets/css/cockfight/screen_min_481.css";
import "@/assets/css/cockfight/screen_min_768.css";
import "@/assets/css/cockfight/screen_min_1025.css";
import "@/assets/css/cockfight/screen_min_1281.css";
import "@/assets/css/cockfight/screen_min_1441.css";
import "@/assets/css/cockfight/screen_min_1661.css";
import "@/assets/css/cockfight/screen_min_1824.css";
import "@/assets/css/cockfight/cock_share_style.css";
import DesktopScreenContainer from "./components/DesktopScreenComponent/DesktopScreenContainer.vue";

import dashboardFunc from "../../../mixin/MX_ShareFunctionCollection.js";
import MX_Socket_Cock from "@/mixin/SocketGame/MX_Socket_Cock.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  mixins: [dashboardFunc, MX_Socket_Cock],
  components: {
    DesktopScreenContainer,
  },
  metaInfo() {
      // if no subcomponents specify a metaInfo.title, this title will be used
      const titleTemplate = `%s |  COCK FIGHT-CH ` + this.correctDisplayCockChannelType(this.channelSelected);
      return {titleTemplate}
    },
  data() {
    return {
      channelSelected: null,
      loading: false,
      error: false,
      bettingObj: {
        number_id: null,
        amount: null,
      },
      isBetsuccess: true,
      isBetting: false,
      data: {
        rows_per_page: 6,
        page: 1,
      },
    };
  },
  watch: {
    channelId: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.channelSelected = this.$cookies.get("sbc_channel_type");
        this.RELOAD_PAGE();
        this.UPDATE_EXPAND_MENU(true);
        this._connectSocketCock();
        await this._initialData();
      }
    },
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
      channelStatus: "getChannelStatus",
      resultObj: "getResult",
      bettingTypeMaster: "getBettingTypeMaster",
      todayBetting: "getTodayBetting",
      currentBetting: "getCurrentBetting",
      weightObj: "getWeight",
      getButton: "getButton",
      loadingTodayReport: "getLoadingTodayReport",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      isSound: "getIsSound",

    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
    getStatus() {
      return this.checkSattus();
    },
    getStatusColor() {
      return this.checkSattusColor();
    },
    getResultStatus() {
      return this.resultStatue();
    },
    getChannelsColor(index) {
      return this.resultChannelColor(index);
    },
  },

  async created() {
    if (this.$cookies.get("sbc_channel_type") != null) {
      this.channelSelected = this.$cookies.get("sbc_channel_type");
      this.UPDATE_CHANNEL_ID(this.$cookies.get("sbc_channel_type"));
    } else {
      this.channelSelected = 1;
      this.UPDATE_CHANNEL_ID(1);
    }

    this._connectSocketCock();
    this.channelSelected ? await this._initialData() : "";
  },
  methods: {
    correctDisplayCockChannelType(ct){
    switch(ct){
      case '1': return '1';
      case '2': return '2';
      case '3': return '3';
      case '4': return '4';
      case '15': return '5';
      case '16': return '6';
    }
  },
  
    validateBeforeBetting(submitBettingData) {
      const money = submitBettingData.amount;
      const userBalance = this.userInfo.balance;
      if (
        this.getBettingStatus() &&
        submitBettingData.amount > 0 &&
        userBalance >= money &&
        this.isBetting == false
      ) {
        return true;
      } else if (!this.getBettingStatus()) {
        this.bettingStatus = "Game is not open";
        this.isBetsuccess = false;
        this.UPDATE_ERROR_MESSAGE(this.$t("title.game_is_close"));
        this.$toastr.e(this.$t("title.game_is_close"));
        return false;
      } else if (
        submitBettingData.amount == 0 ||
        submitBettingData.amount == null
      ) {
        // console.log(submitBettingData)
        this.bettingStatus = this.$t("title.inputAmount");
        this.isBetsuccess = false;
        this.UPDATE_ERROR_MESSAGE(this.$t("title.inputAmount"));
        this.$toastr.e(this.$t("title.inputAmount"));
        return false;
      } else if (userBalance < money) {
        this.bettingStatus = "Not enought Balance";
        this.isBetsuccess = false;
        this.UPDATE_ERROR_MESSAGE(this.$t("title.not_enought_balance"));
        this.$toastr.e(this.$t(`title.not_enought_balance`));
        return false;
      } else if (this.isBetting == true) {
        this.bettingStatus = "We processing your previous betting, please wait";
        this.isBetsuccess = false;
        this.UPDATE_ERROR_MESSAGE(this.$t("title.loading"));
        this.$toastr.e(this.$t("title.loading"));
        return false;
      } else {
        this.UPDATE_ERROR_MESSAGE(this.$t(`something when wrong`));
        this.$toastr.e(`something when wrong`);
        return false;
      }
    },
    async createBetting(submitBettingData) {
      this.bettingObj = submitBettingData;
      if (this.validateBeforeBetting(submitBettingData)) {
        try {
          this.isBetting = true;
          this.LOADING(true);
          const response = await this.fetchCreateBetting(this.bettingObj);
          if (response.data.code) {
            this.CLEAR_BETTING_AMOUNT();
            this.CLEAR_SELECTED_BTN();
            this.$toastr.s(this.$t("title.SUCCESS"));
            this.UPDATE_ERROR_MESSAGE();
            this.bettingStatus = "Betting is success";
            await this.updateBalance(response.data.data.balance);
            this.BETTING_DIALOG(false);
            this.LOADING(false);
            this.isBetting = false;
            this.UPDATE_BET_AMOUNT(response.data.data);
            this.UPDATE_CURRENT_BETTING(response.data.data);
            this.UPDATE_PAYOUT(response.data.data);
          } else {
            if (response.data.message.descriptions == "Channel is not open") {
              this.UPDATE_ERROR_MESSAGE(this.$t("title.game_is_close"));
              this.UPDATE_CH_STATUS(false);
            } else if (response.data.message.descriptions == "Tie is limit") {
              this.UPDATE_ERROR_MESSAGE(this.$t("title.limit"));
              this.$toastr.e(this.$t("title.limit"));
            } else if (
              response.data.message.descriptions ==
              "Betting count is over limit"
            ) {
              this.UPDATE_ERROR_MESSAGE(
                this.$t("title.betting_count_over_limit")
              );
              this.$toastr.e(this.$t("title.betting_count_over_limit"));
            } else {
              this.$toastr.e(`${response.data.message.descriptions}`);
              this.UPDATE_ERROR_MESSAGE(
                this.$t("title." + response.data.message.descriptions)
              );
            }
            this.isBetting = false;
            this.LOADING(false);
          }
        } catch (error) {
          this.CLEAR_SELECTED_BTN();
          this.UPDATE_ERROR_MESSAGE(this.$t("title.fales"));
          this.isBetsuccess = false;
          this.isBetting = false;
          this.LOADING(false);
          this.error = true;
          return error;
        } finally {
          this._resetBetting();
        }
      }
    },
    async _initialData() {
      await this.fetchChannel();
      await this.fetchResult();
      await this.fetchButton();
      if (
        this.channelSelected == 1 ||
        this.channelSelected == 2 ||
        this.channelSelected == 3 ||
        this.channelSelected == 4 ||
        this.channelSelected == 15 ||
        this.channelSelected == 16
      )
        await this.fetchWeight();
      await this.fetchBettingTypeMaster();
      await this.fetchCurrentBetting();
      await this.fetchTodayBetting();
      this._resetBetting();
    },
    setChannelType(channelType) {
      if (this.channelSelected != channelType) {
        this.channelSelected = channelType;
        this.$cookies.set("sbc_channel_type", channelType);
        this.UPDATE_CHANNEL_ID(channelType);
        this.UPDATE_IS_REPORT(true);
      }
    },
    getBettingStatus() {
      if (
        this.channel.is_open &&
        this.userInfo.enable &&
        this.channel.enable
      ) {
        return true;
      }
      return false;
    },
    checkSattus() {
      if (this.channel.is_open == null) {
        return "Not Open";
      } else {
        if (this.channel.is_open) {
          return "Open";
        }
        return "Close";
      }
    },
    resultStatue() {
      if (this.channel.is_open == null && this.resultObj.results[0]) {
        return true;
      } else {
        return false;
      }
    },
    resultChannelColor(index) {
      if (this.channelStatus) {
        for (var i = 0; i < this.channelStatus.length; i++) {
          if (this.channelStatus[i].channel_type == index) {
            if (this.channelStatus[i].status == null) {
              return "orange";
            } else {
              if (this.channelStatus[i].status) {
                return "green";
              }
              return "red";
            }
          }
        }
      }
    },

    checkSattusColor() {
      if (this.channel.is_open == null) {
        return "text-warning";
      } else {
        if (this.channel.is_open) {
          return "text-success";
        }
        return "text-danger";
      }
    },
    _resetBetting() {
      this.CLEAR_BETTING_AMOUNT();
      this.isBetting = false;
      this.LOADING(false);
      this.UPDATE_ERROR_MESSAGE();
    },

    ...mapMutations("$_dashboard", [
      "CLEAR_SELECTED_BTN",
      "LATEST_RESULT",
      "UPDATE_CURRENT_BETTING",
      "UPDATE_CHANNEL",
      "UPDATE_RESULT",
      "UPDATE_AMOUNT",
      "UPDATE_PAYOUT",
      "UPDATE_TODAY_BETTING",
      "RESET_AMOUNT",
      "UPDATE_CHANNEL_STATUS",
      "RELOAD_PAGE",
      "CLEAR_CACH",
      "UPDATE_AMOUNT_MAX",
      "UPDATE_BET_AMOUNT",
      "UPDATE_CH_STATUS",
      "BETTING_DIALOG",
      "UPDATE_DRAGON_CARD",
      "UPDATE_TIGER_CARD",
      "UPDATE_FIST_LOAD_BACARAT_CARD",
      "RESET_CARD",
      "UPDATE_FIST_LOAD_TIGER_DRAGON_CARD",
      "UPDATE_IS_REPORT",
      "UPDATE_COUNTDOWN",
      "UPDATE_PLAYER_CARD",
      "UPDATE_BANKER_CARD",
      "RESET_COUNTDOWN",
      "UPDATE_KLAKLOUK_CARD",
      "UPDATE_LOTTO_CARD",
      "UPDATE_YUKI_CARD",
      "UPDATE_ABONG_CARD",
      "UPDATE_TAISIV",
      "UPDATE_FANTAN",
      "LOADING",
      "UPDATE_ERROR_MESSAGE",
    ]),
    ...mapActions("$_modules", [
      "updateBalance",
      "updateUserInfo",
      "fetchUserInfo",
      "fetchNotList",
      "fetchNotCount",
      "refreshNotiList",
    ]),
    ...mapActions("$_dashboard", [
      "fetchResult",
      "fetchChannel",
      "fetchBettingTypeMaster",
      "fetchTodayBetting",
      "fetchCurrentBetting",
      "fetchCreateBetting",
      "updateCurrentBetting",
      "updateChannel",
      "updateResult",
      "fetchWeight",
      "fetchButton",
    ]),
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_USER_INFO",
      "UPDATE_NOTI_DATA",
      "CLEAR_BETTING_AMOUNT",
      "UPDATE_EXPAND_MENU",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this._disconnectSocketCock();
    this.CLEAR_CACH();
    this.UPDATE_ERROR_MESSAGE();
    next();
  },
};
</script>