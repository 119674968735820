<template>
    <div>
      <v-row class="ma-0 w-100">
        <div class="machine-sicbo-three-section machine-blue-title text-center ">
          <div class="machine-sicbo-button pt-0 machine-play-blue-bg"      
            :class="getSelectedSicboBtn && getSelectedSicboBtn.button && getSelectedSicboBtn.button.name == passBetType[0].name && this.getSelectedSicboBtn.channelType == this.passChannelType?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[0])"> 
          <p class="sicbo-payout"> {{ passBetType[0].payout}}</p>
          <p class="sicbo-name-blue">{{ $t('title.small_tx') }}</p>
          </div>
          <div class="machine-sicbo-button mt-3 pt-0 machine-play-red-bg"
            :class="getSelectedSicboBtn && getSelectedSicboBtn.button && getSelectedSicboBtn.button.name == passBetType[3].name && this.getSelectedSicboBtn.channelType == this.passChannelType?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[3])"> 
            <p class="sicbo-payout">{{passBetType[3].payout}}</p>
            <p class="sicbo-name-blue">{{$t('title.even')}}</p>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="machine-sicbo-three-section machine-green-title text-center ">
          <div class="machine-sicbo-button pt-0 machine-play-blue-bg" 
          :class="getSelectedSicboBtn && getSelectedSicboBtn.button && getSelectedSicboBtn.button.name == passBetType[1].name && this.getSelectedSicboBtn.channelType == this.passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[1])"> 
            <p class="sicbo-payout">{{ passBetType[1].payout }}</p>
            <p class="sicbo-name-red">{{ $t('title.large_tx') }}</p>
          </div>
          <div class="machine-sicbo-button mt-3 pt-0 machine-play-red-bg"
          :class="getSelectedSicboBtn && getSelectedSicboBtn.button && getSelectedSicboBtn.button.name == passBetType[2].name && this.getSelectedSicboBtn.channelType == this.passChannelType?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[2])"> 
            <p class="sicbo-payout">{{ passBetType[2].payout }}</p>
            <p class="sicbo-name-red">{{ $t('title.odd') }}</p>
          </div>
        </div>
      </v-row>
      <MachineSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitSicbo'"/>
    </div>
  </template>
  <script>
  import {mapGetters, mapMutations } from "vuex";
  import MachineSubmitButton from "~global_components/component_machine_game/machine_game_submit_button.vue"
  
  export default {
    components: {
      MachineSubmitButton,
    },
    props:['passBetType', 'passChannelType'],
    computed:{
      ...mapGetters("$_machine_games", {
        getSelectedSicboBtn: "getSelectedSicboBtn",
      }),
    },
  
    methods:{
      _updatedSelectedBtn(button){
        let data = {
          button : button,
          channelType : this.passChannelType
        }
        this.UPDATE_SELECTED_SICBO_BUTTON_IN_ALL(data);
      },
      ...mapMutations("$_machine_games", [
        "UPDATE_SELECTED_SICBO_BUTTON_IN_ALL",
      ]),
    }
  }
  </script>