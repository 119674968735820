import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    lastResults:[],
    currentBetting:[],
    previousBetting:[],
    currentGame:[],
    lotteryChannel:[],
    lastOneResult:[],
    lastTwentyResult:[],
    is_Betting : false,
    buttonMoney:[],
    lastFiveResults:[],
    bettingAmount:0,
    bettingData:[],
    numbers : ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15',
    '16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39',
    '40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59','60',
    '61','62','63','64','66','65','67','68','69','70','71','72','73','74','75','76','77','78','79','80','81','82','83','84','85','86','87','88','89','90',
    '91','92','93','94','95','96','97','98','99'],
    selectedNumber:[],
    bettingType:1,
    selectedPost:[],
    selectedListBettingNumber:['3'],
    selectNormalBetNumber:[],
    selectMultiBetNumber:[],
    selectedAllPost:false,
    totalInvoice:0,
    clientTime: {
        is_countdown: null,
        is_open_betting: null,
        time: null,
      },
    limitLottery3Betting:[],
    ListDetailInvoice :[],
    dialogDetailInovice: false,
    loadingDetailDialog:false,
    output:'',
    result_a:[],
    result_b:[],
    result_c:[],
    result_d:[],
    result_e:[],
    betA: false,
    betB: false,
    betC: false,
    betD: false,
    betE: false,
    selectedLo: false,
    selectedMulti: false,
    selectedNormal: true,
    clearBettingNumber: true,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
