<template>
  <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0 pt-0">
    <ChannelInfo :passChannel="passChannel" />
    <div>
      <div class="g-summary">
        <div
          class="sides-wrapper gp-panel-bg rounded"
          :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
        >
          <div class="d-flex sides px-2 justify-center w-100"></div>

          <div class="row w-100 justify-center mx-auto mt-2">
            <!-- 1 -->
            <div
              class="side-section-range large-section pb-1 side-1 range-betting-container"
              :class="
                selectedBtnClassOne ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedOne')"
            >
              <div class="text-center yuki-betting-header yuki-bg-blue">
                <img
                  :src="_detectImg(0)"
                  class="betting-range"

                />
              </div>
              <div class="yuki-betting-payout">
                {{ oneButton ? `1 : ${oneButton.payout} ` : "" }}
              </div>
            </div>
            <!-- 2 -->
            <div
              class="side-section-range large-section mx-5 pb-1 side-1 range-betting-container mx-1"
              :class="
                selectedBtnClassTwo ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedTwo')"
            >
              <div class="text-center yuki-betting-header yuki-bg-blue">
                <img :src="_detectImg(1)" class="betting-range" />
              </div>
              <div class="yuki-betting-payout">
                {{ twoButton ? `1 : ${twoButton.payout} ` : "" }}
              </div>
            </div>
            <!-- 3 -->
            <div
              class="side-section-range large-section pb-1 side-1 range-betting-container"
              :class="
                selectedBtnClassThree ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedThree')"
            >
              <div class="text-center yuki-betting-header yuki-bg-blue">
                <img :src="_detectImg(2)" class="betting-range" />
              </div>
              <div class="yuki-betting-payout">
                {{ threeButton ? `1 : ${threeButton.payout} ` : "" }}
              </div>
            </div>
            <!-- Thin -->
            <div
              v-if="thinButton"
              class="side-section-range large-section pb-1 side-1 ml-5 range-betting-container"
              :class="
                selectedBtnClassThin ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedThin')"
            >
              <div
                class="text-center yuki-betting-header pt-3"
                style="background-color: #ffba41; color: white !important"
              >
                {{
                  thinButton && thinButton.name
                    ? thinButton.name.toUpperCase()
                    : ""
                }}
              </div>
              <div class="yuki-betting-payout">
                {{ thinButton ? `1 : ${thinButton.payout} ` : "" }}
              </div>
            </div>
          </div>
          <div class="row w-100 justify-center mx-auto mt-6">
            <div
              class="side-section-range large-section pb-1 side-1 range-betting-container"
              :class="
                selectedBtnClassSeven ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedSeven')"
            >
              <div class="text-center yuki-betting-header yuki-bg-blue">
                <img
                  :src="_detectImg(6)"
                  class="betting-range"

                />
              </div>
              <div class="yuki-betting-payout">
                {{ sevenButton ? `1 : ${sevenButton.payout} ` : "" }}
              </div>
            </div>
            <div
              class="side-section-range large-section mx-5 pb-1 side-1 range-betting-container mx-1"
              :class="
                selectedBtnClassEight ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedEight')"
            >
              <div class="text-center yuki-betting-header yuki-bg-blue">
                <img :src="_detectImg(7)" class="betting-range" />
              </div>
              <div class="yuki-betting-payout">
                {{ eightButton ? `1 : ${eightButton.payout} ` : "" }}
              </div>
            </div>
            <div
              class="side-section-range large-section pb-1 side-1 range-betting-container"
              :class="
                selectedBtnClassNine ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedNine')"
            >
              <div class="text-center yuki-betting-header yuki-bg-blue">
                <img :src="_detectImg(8)" class="betting-range" />
              </div>
              <div class="yuki-betting-payout">
                {{ nineButton ? `1 : ${nineButton.payout} ` : "" }}
              </div>
            </div>
            <div
              v-if="blueButton"
              class="side-section-range large-section pb-1 side-1 ml-5 range-betting-container"
              :class="
                selectedBtnClassBlue ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedBlue')"
            >
              <div
                class="text-center yuki-betting-header pt-3"
                style="background-color: blue"
              >
                {{
                  blueButton && blueButton.name
                    ? blueButton.name.toUpperCase()
                    : ""
                }}
              </div>
              <div class="yuki-betting-payout">
                {{ blueButton ? `1 : ${blueButton.payout} ` : "" }}
              </div>
            </div>
          </div>
          <div class="d-flex sides justify-center w-100 mt-6">
            <div
              class="side-section-range large-section pb-1 side-1 range-betting-container"
              :class="
                selectedBtnClassFour ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedFour')"
            >
              <div class="text-center yuki-betting-header yuki-bg-red">
                <img
                  :src="_detectImg(3)"
                  class="betting-range"

                />
              </div>
              <div class="yuki-betting-payout">
                {{ fourButton ? `1 : ${fourButton.payout} ` : "" }}
              </div>
            </div>
            <div
              class="side-section-range large-section pb-1 side-1 mx-5 range-betting-container"
              :class="
                selectedBtnClassFive ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedFive')"
            >
              <div class="text-center yuki-betting-header  yuki-bg-red">
                <img :src="_detectImg(4)" class="betting-range" />
              </div>
              <div class="yuki-betting-payout">
                {{ fiveButton ? `1 : ${fiveButton.payout} ` : "" }}
              </div>
            </div>
            <div
              class="side-section-range large-section pb-1 side-1 range-betting-container"
              :class="
                selectedBtnClassSix ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedSix')"
            >
              <div class="text-center yuki-betting-header  yuki-bg-red">
                <img
                  :src="_detectImg(5)"
                  class="betting-range"
                  style="margin-top: 1px"
                />
              </div>
              <div class="yuki-betting-payout">
                {{ sixButton ? `1 : ${sixButton.payout} ` : "" }}
              </div>
            </div>
            <div
              v-if="redButton"
              class="side-section-range large-section pb-1 side-1 range-betting-container ml-5"
              :class="
                selectedBtnClassRed ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedRed')"
            >
              <div
                class="text-center yuki-betting-header pt-3"
                style="background: red"
              >
                {{
                  redButton && redButton.name
                    ? redButton.name.toUpperCase()
                    : ""
                }}
              </div>
              <div class="yuki-betting-payout">
                {{ redButton ? `1 : ${redButton.payout} ` : "" }}
              </div>
            </div>
          </div>
          <div class="d-flex sides justify-center w-100 mt-3">
            <div
              class="side-section-range large-section pb-1 side-1 range-betting-container"
              :class="
                selectedBtnClassTen ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedTen')"
            >
              <div class="text-center yuki-betting-header  yuki-bg-red">
                <img
                  :src="_detectImg(9)"
                  class="betting-range"

                />
              </div>
              <div class="yuki-betting-payout">
                {{ tenButton ? `1 : ${tenButton.payout} ` : "" }}
              </div>
            </div>
            <div
              class="side-section-range large-section pb-1 side-1 mx-5 range-betting-container"
              :class="
                selectedBtnClassEleven ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedEleven')"
            >
              <div class="text-center yuki-betting-header  yuki-bg-red">
                <img :src="_detectImg(10)" class="betting-range" />
              </div>
              <div class="yuki-betting-payout">
                {{ elevenButton ? `1 : ${elevenButton.payout} ` : "" }}
              </div>
            </div>
            <div
              class="side-section-range large-section pb-1 side-1 range-betting-container"
              :class="
                selectedBtnClassTwelve ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedTwelve')"
            >
              <div class="text-center yuki-betting-header  yuki-bg-red">
                <img
                  :src="_detectImg(11)"
                  class="betting-range"
                  style="margin-top: 1px"
                />
              </div>
              <div class="yuki-betting-payout">
                {{ twelveButton ? `1 : ${twelveButton.payout} ` : "" }}
              </div>
            </div>
            <div
              v-if="thickButton"
              class="side-section-range large-section pb-1 side-1 range-betting-container ml-5"
              :class="
                selectedBtnClassThick ? 'range-betting-container-active' : ''
              "
              @click="_allowBtnBet('selectedThick')"
            >
              <div
                class="text-center yuki-betting-header pt-3 "
                style="background-color: #3a6305; color: white !important"
              >
                {{
                  thickButton && thickButton.name
                    ? thickButton.name.toUpperCase()
                    : ""
                }}
              </div>
              <div class="yuki-betting-payout">
                {{ thickButton ? `1 : ${thickButton.payout} ` : "" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Group Coin -->
      <CoinButton
        :passGetButton="passGetButton"
        :passChannel="passChannel"
        :passPlaceCoin="bettingMoney"
        v-on:handleInputMonay="_inputAmount"
      />
      <SubmitBtn
        :passCheckAllowSubmit="_checkAllowSubmit"
        v-on:handleSubmitBtn="_submitBetting"
        v-on:handleClearBtn="_clearMoney"
      />
      <LimitBetting />
    </div>
  </div>
</template>

<script>
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_share_style.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_max_480.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_min_481.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_min_768.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_min_993.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_min_1025.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_min_1281.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_min_1441.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_min_1661.css";
import "@/assets/css/casino_live/yuki_casino_live/yuki_casino_screen_min_1824.css";
import CoinButton from "../../../../../components/CoinBettingButton.vue";
import ChannelInfo from "../../../../../components/ChannelInfoLive.vue";
import LimitBetting from "../../../../../components/LimitBettingLive.vue";
import SubmitBtn from "../../../../../components/SubmitBtn.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn },
  props: [
    "passChannel",
    "passCurrentBetting",
    "passChannelStatus",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passSelectedChannel",
  ],
  data() {
    return {
      selectedButton: {},
      bettingMoney: 0,
      valueTiger: "",
      resultTiger: "",
      valueDragon: "",
      resultDragon: "",
      selectedBtnClassSmall: false,
      selectedBtnClassTie: false,
      selectedBtnClasslarge: false,
      countDown: {},
      selectedBtnClassOne: false,
      selectedBtnClassTwo: false,
      selectedBtnClassThree: false,
      selectedBtnClassFour: false,
      selectedBtnClassFive: false,
      selectedBtnClassSix: false,
      selectedBtnClassSeven: false,
      selectedBtnClassEight: false,
      selectedBtnClassNine: false,
      selectedBtnClassTen: false,
      selectedBtnClassEleven: false,
      selectedBtnClassTwelve: false,
      selectedBtnClassThin: false,
      selectedBtnClassThick: false,
      selectedBtnClassBlue: false,
      selectedBtnClassRed: false,
      selectedCoin: false,
    };
  },

  computed: {
    oneButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[0];
      else return {};
    },
    twoButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[1];
      else return {};
    },
    threeButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[2];
      else return {};
    },
    fourButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[3];
      else return {};
    },
    fiveButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[4];
      else return {};
    },
    sixButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[5];
      else return {};
    },
    sevenButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[6];
      else return {};
    },
    eightButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[7];
      else return {};
    },
    nineButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[8];
      else return {};
    },
    tenButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[9];
      else return {};
    },
    elevenButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[10];
      else return {};
    },
    twelveButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[11];
      else return {};
    },

    thinButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[12];
      else return {};
    },
    thickButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[13];
      else return {};
    },
    blueButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[19];
      else return {};
    },
    redButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[18];
      else return {};
    },
    _checkAllowSubmit() {
      if (
        this.bettingMoney > 0 &&
        (this.selectedBtnClassFive ||
          this.selectedBtnClassFour ||
          this.selectedBtnClassThree ||
          this.selectedBtnClassTwo ||
          this.selectedBtnClassOne ||
          this.selectedBtnClassSmall ||
          this.selectedBtnClasslarge ||
          this.selectedBtnClassTwelve ||
          this.selectedBtnClassEleven ||
          this.selectedBtnClassTen ||
          this.selectedBtnClassNine ||
          this.selectedBtnClassEight ||
          this.selectedBtnClassSeven ||
          this.selectedBtnClassSix ||
          this.selectedBtnClassThin ||
          this.selectedBtnClassThick ||
          this.selectedBtnClassBlue ||
          this.selectedBtnClassRed)
      )
        return true;
      else return false;
    },
    ...mapGetters("$_casino_live", {
      dialogBetting: "getDialogBetting",
      amountMin: "getAmountMin",
      amountMax: "getAmountMax",
      getCountdown: "getCountdown",
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
  },

  methods: {
    formatBtnCurrencyKh(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "ព";
        case amount >= 10000 && amount <= 999999:
          return amount / 10000 + "ម";
        case amount >= 1000000 && amount <= 99999999999:
          return amount / 1000000 + "ល";
        default:
          return amount;
      }
    },
    formatBtnCurrency(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "K";
        default:
          return amount;
      }
    },

    _detectCurrentMoneyCoinlarge(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorlarge(money) {
      switch (true) {
        case money < 5:
          return "black";
        case money < 10:
          return "black";
        case money < 20:
          return "black";
        case money < 50:
          return "black";
        case money < 100:
          return "black";
        case money < 99999999:
          return "black";
        default:
          return "";
      }
    },
    _detectImg(index) {
      switch (index) {
        case 0:
          return "/images/a_each_game/newYuki/Animal bet/Asset 1.png";
        case 1:
          return "/images/a_each_game/newYuki/Animal bet/Asset 2.png";
        case 2:
          return "/images/a_each_game/newYuki/Animal bet/Asset 3.png";
        case 3:
          return "/images/a_each_game/newYuki/Animal bet/Asset 4.png";
        case 4:
          return "/images/a_each_game/newYuki/Animal bet/Asset 5.png";
        case 5:
          return "/images/a_each_game/newYuki/Animal bet/Asset 6.png";
        case 6:
          return "/images/a_each_game/newYuki/Animal bet/Asset 7.png";
        case 7:
          return "/images/a_each_game/newYuki/Animal bet/Asset 8.png";
        case 8:
          return "/images/a_each_game/newYuki/Animal bet/Asset 9.png";
        case 9:
          return "/images/a_each_game/newYuki/Animal bet/Asset 10.png";
        case 10:
          return "/images/a_each_game/newYuki/Animal bet/Asset 11.png";
        case 11:
          return "/images/a_each_game/newYuki/Animal bet/Asset 12.png";
        default:
          return "";
      }
    },

    _inputAmount(money) {
      this.selectedCoin = true;
      this.bettingMoney = parseInt(this.bettingMoney);
      this.bettingMoney += parseInt(money);
    },
    _clearMoney() {
      this.bettingMoney = 0;
      this.selectedBtnClassSmall = false;
      this.selectedBtnClassOne = false;
      this.selectedBtnClasslarge = false;
      this.selectedBtnClassTwo = false;
      this.selectedBtnClassThree = false;
      this.selectedBtnClassFour = false;
      this.selectedBtnClassFive = false;
      this.selectedBtnClassSix = false;
      this.selectedBtnClassSeven = false;
      this.selectedBtnClassEight = false;
      this.selectedBtnClassNine = false;
      this.selectedBtnClassTen = false;
      this.selectedBtnClassEleven = false;
      this.selectedBtnClassTwelve = false;
      this.selectedBtnClassThin = false;
      this.selectedBtnClassThick = false;
      this.selectedBtnClassBlue = false;
      this.selectedBtnClassRed = false;
      this.selectedCoin = false;
    },
    _submitBetting() {
      let submitBettingData = {
        type_id: this.selectedButton._id,
        amount: this.bettingMoney,
      };
      this.$emit("sentSubmitBetting", submitBettingData);
      this._clearMoney();
    },
    _allowBtnBet(selectedBtn) {
      if (!this.selectedCoin) {
        this.$toastr.e(this.$t("title.selectCoin"));
        return false;
      }
      switch (selectedBtn) {
        case "selectedOne":
          this.selectedButton = this.oneButton;
          this.selectedBtnClassOne = true;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedTwo":
          this.selectedButton = this.twoButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = true;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedThree":
          this.selectedButton = this.threeButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = true;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedFour":
          this.selectedButton = this.fourButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = true;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedFive":
          this.selectedButton = this.fiveButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = true;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedSix":
          this.selectedButton = this.sixButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = true;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedSeven":
          this.selectedButton = this.sevenButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = true;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedEight":
          this.selectedButton = this.eightButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = true;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedNine":
          this.selectedButton = this.nineButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = true;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedTen":
          this.selectedButton = this.tenButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = true;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedEleven":
          this.selectedButton = this.elevenButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = true;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedTwelve":
          this.selectedButton = this.twelveButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = true;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedThin":
          this.selectedButton = this.thinButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = true;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedThick":
          this.selectedButton = this.thickButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = true;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = false;
          break;
        case "selectedBlue":
          this.selectedButton = this.blueButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = true;
          this.selectedBtnClassRed = false;
          break;
        case "selectedRed":
          this.selectedButton = this.redButton;
          this.selectedBtnClassOne = false;
          this.selectedBtnClassTwo = false;
          this.selectedBtnClassThree = false;
          this.selectedBtnClassFour = false;
          this.selectedBtnClassFive = false;
          this.selectedBtnClassSix = false;
          this.selectedBtnClassSeven = false;
          this.selectedBtnClassEight = false;
          this.selectedBtnClassNine = false;
          this.selectedBtnClassTen = false;
          this.selectedBtnClassEleven = false;
          this.selectedBtnClassTwelve = false;
          this.selectedBtnClassThin = false;
          this.selectedBtnClassThick = false;
          this.selectedBtnClassBlue = false;
          this.selectedBtnClassRed = true;
          break;
      }
    },
    ...mapMutations("$_casino_live", [
      "BETTING_DIALOG",
      "UPDATE_YUKI_CARD",
      "RESET_YUKI_CARD",
    ]),
  },
};
</script>