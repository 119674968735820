import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[...routes]
})

router.beforeEach((to, from, next) => {
	const token = Vue.$cookies.get('sbc_token');
	if (!token && to.name != 'login' && to.name != 'register' ) {
		next({ name: 'login' });
	} else if ((token && to.name == 'login') || to.path == '/') {
		next({ name: '' });
	
	} else if ((to.path == '/' || to.path == '/cockfight') && ( parseInt(Vue.$cookies.get("sbc_channel_type")) >= 90 || Vue.$cookies.get("sbc_defaut_main_menu") !='cock' ) ) {
		Vue.$cookies.set("sbc_channel_type", 1);
		Vue.$cookies.set("sbc_defaut_main_menu", 'cock');
		next();
	}
	else {
		next();
	}
	//return next();
});

export default router
