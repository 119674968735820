<template>
  <v-row class="ma-0 h-100" v-if="passRangeSmall">
      
      <v-col cols="6" class="pa-0 h-100" v-if="passBlueRed && passBlueRed[0] && passBlueRed[0]._id "
         style="padding:4px 2px 0px 0px !important;">
          <v-col cols="12" class="pa-0 each-button-height mb-0">
              <v-btn :color="passBlueRed[0].color" 
              @click="_selectBtn(passBlueRed[0]._id)"
              :class="getSelectBtn == passBlueRed[0]._id ? 'on-active-btn' : ''"
              class="w-100 rounded-0 h-100"
              height="100%!important">
                  <span>
                      <span class="betting-name-blue-red" style="font-family: 'Koh Santepheap', serif!important;">{{ passBlueRed[0].name }}
                      </span>
                      <br>
                      <span class="betting-payout">{{ passBlueRed[0].payout }}</span>
                  </span>
              </v-btn>
          </v-col>
          <v-col cols="12" class="pa-0 each-button-height mb-0">
              <v-row class="ma-0 h-100 pb-0" >
                  <v-col cols="6" class="pa-0 each-button-right each-button-height" v-if="passRangeSmall">
                      <v-btn :color="passRangeSmall[1].color" 
                          @click="_selectBtn(passRangeSmall[1]._id)"
                          :class="getSelectBtn == passRangeSmall[1]._id ? 'on-active-btn' : ''" class="w-100 rounded-0 mb-0 py-3" height="100%!important">
                          <span>
                              <span class="betting-name" >{{ passRangeSmall[1].name }}
                              </span>
                              <br>
                              <span class="betting-payout">{{ passRangeSmall[1].payout }}</span>
                          </span>
                      </v-btn>
                  </v-col>
                  <v-col cols="6" class="pa-0 each-button-right each-button-height">
                      <v-btn :color="passRangeBig[1].color"         
                      :class="getSelectBtn == passRangeBig[1]._id ? 'on-active-btn' : ''"
                      @click="_selectBtn(passRangeBig[1]._id)"  
                      class="w-100 rounded-0 mb-0 py-3" height="100%!important">
                          <span>
                              <span class="betting-name" >{{ passRangeBig[1].name }}
                              </span>
                              <br>
                              <span class="betting-payout" >{{ passRangeBig[1].payout }}</span>
                          </span>
                      </v-btn>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="12" class="pa-0 mb-1 pt-0" v-if="passSixBtn" >
              <v-row class="ma-0 h-100 ">
                  <v-col cols="4" class="pa-0 mt-0 mb-1 each-button-left animal-btn"  
                      v-for="firstSixBtn in passSixBtn"
                      :style="`background-color:${firstSixBtn.color}`" 
                      :key="firstSixBtn.name">
                      <button text class="w-100 rounded-0 ma-0 mb-0" style="height:100%; "
                      @click="_selectBtn(firstSixBtn._id)" 
                      :class="getSelectBtn == firstSixBtn._id ? 'on-active-btn' : ''"
                          :style="`border :5px solid ${firstSixBtn.color}`">
                          <div class="w-100" :style="` background-color:${firstSixBtn.color}`" style="height:20%;">
                              <v-row class="mx-1 my-0">
                                  <span class="animal-name">{{ firstSixBtn.year_number }}
                                  </span>
                                  <v-spacer></v-spacer>
                                  <span class="animal-name">{{ firstSixBtn.name }}
                                  </span>
                              </v-row>
                          </div>
                          <div class="w-100" style="height:76%;background-color:white;" >
                              <v-img style="overflow: initial" class="img-btn"
                                  :src="findImageOfEachAnimal2(firstSixBtn.year_number)" :lazy-src="findImageOfEachAnimal2(firstSixBtn.year_number)
                                      "></v-img>
                                        <span class="animal-payout" :style="'color: '+firstSixBtn.color+'!important'">1: {{ firstSixBtn.payout }}
                                  </span>
                          </div>
                      </button>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 each-button-height px-0">
              <v-row class="ma-0 h-100 ">
                  <v-col cols="6" v-for="i in passSmallBig" :key="i.name" class="py-0 px-0">
                      <v-btn :color="i.color"         class="w-100 rounded-0 mb-0 mt-0"  
                      height="90%!important"
                      @click="_selectBtn(i._id)"
                      :class="getSelectBtn == i._id ? 'on-active-btn' : ''"
                      >
                          <span style="margin-top:-5px;">
                              <span class="betting-name" style="font-family: 'Koh Santepheap', serif!important;">{{ i.name }}
                              </span>
                              <br>
                              <span class="betting-payout">{{ i.payout }}</span>
                          </span>
                      </v-btn>
                  </v-col>
              </v-row>
          </v-col>

      </v-col>
      <v-col cols="6" class="pa-0 h-100" style="padding:4px 0px 0px 2px !important;">
          <v-col cols="12" class="pa-0 each-button-height mb-0" 
          v-if="passBlueRed && passBlueRed[0] && passBlueRed[0]._id" >
              <v-btn :color="passBlueRed[1].color"
                      :class="getSelectBtn == passBlueRed[1]._id ? 'on-active-btn' : ''"
                      @click="_selectBtn(passBlueRed[1]._id)" 
                      class="w-100 rounded-0 mb-0 " style="height:100%!important">
                  <span>
                      <span class="betting-name-blue-red" style="font-family: 'Koh Santepheap', serif!important;">{{ passBlueRed[1].name }}
                      </span>
                      <br>
                      <span class="betting-payout">{{ passBlueRed[1].payout }}</span>
                  </span>
              </v-btn>
          </v-col>
          <v-col cols="12" class="pa-0 each-button-height mb-0">
            <v-row class="ma-0 h-100 pb-0" >
                <v-col cols="6" class="pa-0 each-button-right each-button-height" v-if="passRangeSmall">
                    <v-btn :color="passRangeSmall[0].color" 
                        @click="_selectBtn(passRangeSmall[0]._id)"
                        :class="getSelectBtn == passRangeSmall[1]._id ? 'on-active-btn' : ''" class="w-100 rounded-0 mb-0 py-3" height="100%!important">
                        <span>
                            <span class="betting-name" >{{ passRangeSmall[0].name }}
                            </span>
                            <br>
                            <span class="betting-payout">{{ passRangeSmall[0].payout }}</span>
                        </span>
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pa-0 each-button-right each-button-height">
                    <v-btn :color="passRangeBig[0].color"         
                    :class="getSelectBtn == passRangeBig[0]._id ? 'on-active-btn' : ''"
                    @click="_selectBtn(passRangeBig[0]._id)"  
                    class="w-100 rounded-0 mb-0 py-3" height="100%!important">
                        <span>
                            <span class="betting-name" >{{ passRangeBig[0].name }}
                            </span>
                            <br>
                            <span class="betting-payout" >{{ passRangeBig[0].payout }}</span>
                        </span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
          <v-col cols="12" class="pa-0 ma-0" >
              <v-row class="ma-0 h-100 ">
                  <v-col cols="4" class="pa-0 mt-0 mb-1 each-button-left animal-btn" :style="` background-color:${lastSixBtn.color}`" 
                      v-for="lastSixBtn in passSixToTwelve"
                      :key="lastSixBtn.name">
                      <button text class="w-100 rounded-0 ma-0 mb-0" style="height:100%;" 
                      @click="_selectBtn(lastSixBtn._id)" 
                      :class="getSelectBtn == lastSixBtn._id ? 'on-active-btn' : ''"
                          :style="`border :5px solid ${lastSixBtn.color}`">
                          <div class="w-100" :style="` background-color:${lastSixBtn.color}`" style="height:20%;">
                              <v-row class="mx-1 my-0">
                                  <span class="animal-name">{{ lastSixBtn.year_number }}
                                  </span>
                                  <v-spacer></v-spacer>
                                  <span class="animal-name">{{ lastSixBtn.name }}
                                  </span>
                              </v-row>
                          </div>
                          <div class="w-100" style="height:76%;background-color:white;">
                              <v-img style="overflow: initial" class="img-btn"
                                  :src="findImageOfEachAnimal2(lastSixBtn.year_number)" :lazy-src="findImageOfEachAnimal2(lastSixBtn.year_number)
                                      "></v-img>
                                        <span class="animal-payout" :style="'color: '+lastSixBtn.color+'!important'">1: {{ lastSixBtn.payout }}
                                  </span>
                          </div>
                      </button>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="12" class="pt-0 each-button-height px-0">
              <v-row class="ma-0 h-100 ">
                  <v-col cols="6" v-for="i in passOddPair" :key="i.name" class="py-0 px-0">
                      <v-btn :color="i.color"         class="w-100 rounded-0 mb-0 mt-1"  
                      height="90%!important"
                      @click="_selectBtn(i._id)"
                      :class="getSelectBtn == i._id ? 'on-active-btn' : ''"
                      >
                          <span style="margin-top:-5px;">
                              <span class="betting-name" style="font-family: 'Koh Santepheap', serif!important;">{{ i.name }}
                              </span>
                              <br>
                              <span class="betting-payout">{{ i.payout }}</span>
                          </span>
                      </v-btn>
                  </v-col>
              </v-row>
          </v-col>
      </v-col>
    

  </v-row>
</template>


<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Mixin from "~mixin/MX_FindImageFunctionCollection.js";
export default {
  mixins: [Mixin],
  components: {
      // Tabs,
  },
  props: [
      "passClientTime",
  ],
  data() {
      return {
          
      }
  },
  computed: {
      ...mapGetters("$_modules", {
          userInfor: "getUserInfo",
          getBettingAmount: "getBettingAmount",
      }),
      ...mapGetters("$_machine_yuki", {
          currentGame: "getCurrentGame",
          getLimit: "getLimit",
          passSixBtn: "getFirstSixBtn",
          passSixToTwelve: "getSixToTwelve",
          passRangeSmall: "getRangeSmall",
          passRangeBig: "getRangeBig",
          passSmallBig: "getSmallBig",
          passOddPair: "getOddPair",
          passBlueRed: "getBlueRed",
          getSelectBtn:"getSelectBtn",
      }),
  },
  methods: {
      _closeBetting(){
          if(this.loading){
              this.$toastr.e(this.$t('alert.processing_previous_betting'))
              return false
          }
          if(!this.passClientTime || !this.passClientTime.is_open_betting ){
              this.$toastr.e(this.$t('alert.game_close'))
              return false
          } 
          else if(!this.getBettingAmount || this.getBettingAmount == 0 ){
              this.$toastr.e(this.$t('alert.please_input_amount'))
              return false
          }
          else return true
          
      },  
      async _selectBtn(selectedBtn){
          if(this._closeBetting() == true){
              this.loading = true
              let data = [{
                  amount: parseInt(this.getBettingAmount),
                  yuki_id: selectedBtn,
              }];
              const response =  await this.createBet(data)
              if (response.data.code) {
                  this.CLEAR_BETTING_AMOUNT();
                  this.fetchUserInfo();
                  this.fetchCurrentBetting();
                  this.$toastr.s(this.$t(response.data.message.descriptions))
              }else {
                  // this.CLEAR_BETTING_AMOUNT()
                  this.$toastr.e(`${response.data.message.descriptions}`)
                  this.error = true;
              }
              this.loading = false;
          }else{
              this.$toastr.e(this.$t('Channel is not open'))
          }
      },
         
      ...mapMutations("$_machine_yuki", [
          "UPDATE_SELECT_BTN"
      ]),
      ...mapMutations("$_modules", ["CLEAR_BETTING_AMOUNT"
      ]),
      ...mapActions("$_modules", [
      "fetchUserInfo"
      ]),
      ...mapActions("$_machine_yuki", [
      "createBet", "fetchCurrentBetting"
      ]),
  }
};
</script>
<style scoped>
/* .each-button-height{
  padding:13px 16px!important;
} */
</style>