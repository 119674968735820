<template>
  <div class="mx-auto row w-100 justify-center mb-3">
    <div class="mr-3">
      <v-btn
        @click="_clearMoney"
        class="btn btn-secondary mx-0 row mt-0 btn-clear-money"
        :disabled="loading"
        style="height: 40px !important"
      >
        <v-icon style="margin-right: 4px" size="17px"
          >mdi-close-circle-outline</v-icon
        >
        <span >
          {{ $t("title.cancel") }}
        </span>
      </v-btn>
    </div>
    <div class="ml-3">
      <v-btn
        @click="_submitBetting"
        class="btn btn-secondary row mx-0 mt-0 btn-submit-money"
        :disabled=" loading"
        style="height: 40px !important"
      >
        <v-icon
          v-if="!loading"
          style="margin-right: 4px"
          size="17px"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon v-if="loading"> mdi-refresh mdi-spin </v-icon>
        <span >
          {{ $t("title.confirm") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["passCheckAllowSubmit"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      loading: "getLoading",
    }),
  },

  methods: {
    _submitBetting() {
      this.$emit("handleSubmitBtn");
    },
    _clearMoney() {
      this.$emit("handleClearBtn");
    },
  },
};
</script>
