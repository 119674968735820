import Cockfight from '../../../modules/FolderGames/Cockfight';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/cockfight',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true,
                title: "COCK FIGHT"
            },
            children: [
                {
                    path: '',
                    component: Cockfight,
                    name: 'Cockfight',
                    props: true,
                }
            ]
    }
];

export default routes;