<template>
  <div>
    <v-app>
      <div fluid fill-height class="pa-0 background-register position-relative">
        <div class="login-header">
          <v-btn text color="pt-2 ml-2 mt-0" style="height:40px!important" @click="_goToRouter('login')"><v-icon class="ml-2 pt-2" size="1.7rem" color="#ffc502" c>mdi-arrow-left-circle</v-icon></v-btn>
        </div>
        <v-layout class="form-register-container desktop-screen">
          <form-section style="color: #000000 !important;" :passValid="valid" :passUser="user" :passLoading="loading" :passLanguage="lang"
            v-on:register="register(user)" v-on:changeLanguage="handleChangeLanguage" :error="error" :errorMessage="errorMessage" />
        </v-layout>
        <v-layout class="mobile-screen mb-14">
          <form-section-mobile :passValid="valid" :passUser="user" :passLoading="loading" :passLanguage="lang"
            v-on:register="register(user)" v-on:changeLanguage="handleChangeLanguage" :error="error" :errorMessage="errorMessage"   />
        </v-layout>
        <div class="register-footer">
      
        </div>
      </div>
    </v-app>
  </div>
</template> 

<script>
import "@/assets/css/register/register_share_style.css";
import "@/assets/css/register/screen_max_480.css";
import "@/assets/css/register/screen_min_481.css";
import "@/assets/css/register/screen_min_768.css";
import "@/assets/css/register/screen_min_993.css";
import "@/assets/css/register/screen_min_1025.css";
import "@/assets/css/register/screen_min_1281.css";
import "@/assets/css/register/screen_min_1441.css";
import "@/assets/css/register/screen_min_1661.css";
import "@/assets/css/register/screen_min_1824.css";
import { mapActions, mapMutations } from "vuex";
import FormSection from './components/FormSection.vue';
import FormSectionMobile from './components/FormSectionMobile.vue';
export default {
  components: { FormSection, FormSectionMobile },
  metaInfo: {
      titleTemplate: `%s | REGISTER`
    },
  data() {
    return {
      dialogTerm:false,
      is_check:false,
      user: {
        user_name: '',
        fullname: '',
        password: '',
        confirm_password:'',
        app_id: '',
        currency_type:1
      },
      termPolicy:'1.Tuân thủ các quy định hội viên và các quy định pháp lệnh:Sau khi quý khách đăng ký tài khoản hội viên sbc2888 thành công thì sẽ được sử dụng tất cả các dịch vụ của công ty chúng tôi. Khi sử dụng các dịch vụ đó, đồng nghĩa quý khách đã đồng ý và tuân thủ theo các quy định mà công ty chúng tôi đề ra như (Quy tắc trò chơi Casino, quy tắc đặt cược thể thao, thông báo, các hạng mục chú ý) và một số pháp lệnh có liên quan. Tất cả các dịch vụ liên quan như sản phẩm, dịch vụ khách hàng, hệ thống phần mềm, kèo cược, trò chơi, casino, kết cấu mạng của công ty chúng tôi đều được giám sát bởi ủy ban cờ bạc và giải trí của nước Philippines. Vì vậy khi quý khách sử dụng những dịch vụ của chúng tôi thì phải tuân thủ theo các pháp lệnh hợp pháp của nước bản địa, nếu có vi phạm điều gì đều không thuộc phạm vi trách nhiệm dịch vụ của chúng tôi.',
      head_contact_no:'',
      tail_contact_no:'',
      select: ['English'],
      isShowConfirmPassword: true,
      isShowPassword:true,
      password: 'Password',
      loading: false,
      error: false,
      rules: {
        required: [(value) => !!value || 'Required.'],
      },
      checkConfirmPass: false,
      valid: false,
      url: "https://www.dropbox.com/s/wc1ixvksogfnq5n/SBC-Mobile_prodRelease.apk?dl=1",
      lang: '',
      showChat:true,
      passLoading:false,
      errorMessage:'',
      clearError:false
    }
  },
  computed: {
    
    logo (){
      return''
    }
  },
  created() {
    this.lang = this.$cookies.get("local");
    if (this.lang == null) {
        this.lang = "english";
        this.$cookies.set('local' , this.lang );
    }
    
  },
  methods: {
    onSelect(data) {
      this.head_contact_no = data.dialCode;
    },
    reset() {
      this.$refs.form.reset();
    },
    async register() {
      let _ts = this;
      try {
        
        _ts.passLoading = true;
        _ts.user.app_id = process.env.VUE_APP_ID;
        _ts.user.parent_id = await _ts.getUserParent(parseInt(_ts.user.currency_type));
        if(!_ts.user.parent_id) {
          this.$toastr.e(_ts.$t('toast.select_currency_problem'))
          return false;
        }
  
        if(_ts.user.password != _ts.user.confirm_password) {
          _ts.checkConfirmPass = true;
          this.$toastr.e(_ts.$t('toast.confirmpass'));
          return false;
        }
        else{
          _ts.user.user_name = _ts.user.user_name.toString().toLocaleLowerCase();
          _ts.user.password = _ts.user.password.toString().toLocaleLowerCase();
        }   
        const response = await this.fetchRegister(this.user);
        if (response.data.code) {
            this.passLoading = false;
            await this.$router.push({ name: 'login', params: { username: this.user.user_name } });
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
          this.passLoading = false;
        }
      } catch (error) {
        _ts.$toastr.e(`${error}`)
        _ts.passLoading = false;
        return error;
      } finally {
        _ts.passLoading = false;
      }
    },

    getUserParent(userCurrency){
      switch(userCurrency){
            case 1 : return process.env.VUE_APP_PARENT_ID_DOLLAR;
            case 2 : return process.env.VUE_APP_PARENT_ID_RIEL;
            case 4 : return process.env.VUE_APP_PARENT_ID_VIETNAM;
            case 5 : return process.env.VUE_APP_PARENT_ID_BAHT;
            default:
                return false;
        }
    },
    async myInfo() {
      try {
        this.passLoading = true;
        const response = await this.fetchMyInfo();
        if (response.data.code) {
          const { user_name, balance, currency_type } = response.data.data;
          this.$cookies.set("user_name", user_name);
          this.$cookies.set("balance", balance);
          this.$cookies.set("currency_type", currency_type);
        }
        else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        return error;
      } finally {
        this.loading = false;
      }
    },
    handleChangeLanguage(language) {
      this.lang = language;
      switch (language) {
        case 'english':
          this.changeLangEn();
          break;
        case 'Vietname':
          this.changeLangKh();
          break;
        case 'thai':
          this.changeLangTh();
          break;
        case 'khmer':
          this.changeLangKh();
          break;
      }
    },
    _goToRouter(data){
            this.$router.push(data);
        },
    changeLangEn() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "english" ? "english" : "english";
      this.$cookies.set("local", this.$i18n.locale);

    },
    changeLangKh() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "khmer" ? "khmer" : "khmer";
      this.$cookies.set("local", this.$i18n.locale);

    },
    changeLangTh() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "thai" ? "thai" : "thai";
      this.$cookies.set("local", this.$i18n.locale);

    },
    changeLangVN() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "vietnam" ? "vietnam" : "vietnam";
      this.$cookies.set("local", this.$i18n.locale);

    },
    ...mapActions("$_auth", ["fetchLogIn","fetchRegister"]),
    ...mapActions("$_myinfo", ["fetchMyInfo"]),
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_CHANNEL_LIST",
    ]),
  }
}
</script>