import MachineGames from '@/modules/FolderGames/MachineGames';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/machine-games/:page',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: MachineGames,
                    name: 'machine_games',
                    props: true,
                }
            ]
    }
];

export default routes;