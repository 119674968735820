<template>
  <div class="main-menu-tab-game-position ma-0 pa-0" style="overflow:hidden">
    <a v-if="userInfo && userInfo.menu && userInfo.menu.menu1 && userInfo.menu.menu1.is_enable"
      :class="defaultMainMenu == 'cock' ? 'main-menu-span-active' : ''" class="btn main-menu-btn-custom pa-0 ma-0"
      style="text-align: center" @click="getToMenu('/cockfight', userInfo.menu.menu1.channel_type)">
      <img src="../../public/images/a_page_access_global/game-logo/cockfight-logo.png" alt=""
        class="main-menu-tab-logo x-tab-log" />
      <p class="mainmenu-name">{{ userInfo.menu.menu1.desc }}</p>
    </a>
    <a v-if="userInfo && userInfo.menu && userInfo.menu.menu2 && userInfo.menu.menu2.is_enable"
      :class="defaultMainMenu == 'casino' ? 'main-menu-span-active' : ''" class="btn main-menu-btn-custom pa-0 ma-0"
      style="text-align: center" @click="getToMenu('/casino', userInfo.menu.menu2.channel_type)">

      <img src="../../public/images/a_page_access_global/game-logo/poker-logo.png" alt=""
        class="main-menu-tab-logo x-tab-log" />
      <p class="mainmenu-name">{{ userInfo.menu.menu2.desc }}</p>
    </a>
    <a v-if="userInfo && userInfo.menu && userInfo.menu.menu5 && userInfo.menu.menu5.is_enable"
      :class="defaultMainMenu == 'cock_casinolive' ? 'main-menu-span-active' : ''"
      class="btn main-menu-btn-custom pa-0 ma-0" style="text-align: center"
      @click="getToMenu('/CockAndCasinoLive/ALL', userInfo.menu.menu5.channel_type)">
      <img src="../../public/images/a_page_access_global/game-logo/Cock&Casino live icon.png" alt=""
        class="main-menu-tab-logo x-tab-log" />
      <p class="mainmenu-name">{{ userInfo.menu.menu5.desc }}</p>
    </a>

    <a v-if="userInfo && userInfo.menu && userInfo.menu.menu4 && userInfo.menu.menu4.is_enable"
      :class="defaultMainMenu == 'machine_game' ? 'main-menu-span-active' : ''"
      class="btn main-menu-btn-custom  pa-0 ma-0" style="text-align: center"
      @click="getToMenu('/machine-games/ALL', userInfo.menu.menu4.channel_type)">
      <img src="../../public/images/a_page_access_global/game-logo/machine-game-logo.png" alt=""
        class="main-menu-tab-logo x-tab-log" />
      <p class="mainmenu-name">{{ userInfo.menu.menu4.desc }}</p>
    </a>
    <a v-if="userInfo && userInfo.menu && userInfo.menu.menu3 && userInfo.menu.menu3.is_enable"
      :class="defaultMainMenu == 'lotto_game' ? 'main-menu-span-active' : ''" class="btn main-menu-btn-custom pa-0 ma-0"
      style="text-align: center" @click="getToMenu('/LOTTO', userInfo.menu.menu3.channel_type)">
      <img :src="require('~public_image/a_page_access_global/game-logo/lottery-logo.png')" alt=""
        class="main-menu-tab-logo x-tab-log" />
      <p class="mainmenu-name">{{ userInfo.menu.menu3.desc }}</p>
    </a>
    <a :class="defaultMainMenu == 'sport_game' ? 'main-menu-span-active' : ''"
      class="btn main-menu-btn-custom pa-0 ma-0" style="text-align: center" @click="getToMenu('/sport', 'sport_game')">
      <img :src="require('~public_image/a_page_access_global/game-logo/Sport icon.png')" alt=""
        class="main-menu-tab-logo x-tab-log" />
      <p class="mainmenu-name">Sport</p>
    </a>


  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import "@/assets/css/menu.css";
import publicFunction from "../mixin/index.js";
export default {
  data() {
    return {
      mixins: [publicFunction],
      allMenu: null,
      defaultMainMenu: "cock",

    };
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getMainMenu: "getMainMenu",
    }),
    ...mapState("$_modules", {
      mainMenuSelected: "mainMenuId",
    }),
  },
  watch: {
    mainMenuSelected: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.defaultMainMenu = this.$cookies.get("sbc_defaut_main_menu");
      }
    },
  },
  async created() {
    // this.allMenu = this.$cookies.get("sbc_user").menu;
    this.defaultMainMenu = this.$cookies.get("sbc_defaut_main_menu");


    if (this.defaultMainMenu == 'machine_game') {
      this.getToMenu('/machine-games/ALL', this.$cookies.get("sbc_user").menu.menu4.channel_type)
    }
  },
  methods: {

    getToMenu(path, main_menu_type) {
      // console.log(main_menu_type);
      if (this.defaultMainMenu !== main_menu_type) {
        try {
          this.defaultMainMenu == main_menu_type;
          this.UPDATE_MAIN_MENU(main_menu_type);
          this.$cookies.set("sbc_defaut_main_menu", main_menu_type);

          // console.log(`main_menu_type: ${main_menu_type}`);
          let resp = this.setCookieChannelType(main_menu_type)
          if (!resp) {
            this.$toastr.e('something when wrong, check getToMenu func');
          }
          else {
            this.UPDATE_EXPAND_MENU(true);
            this.$router.push({ path });
          }
          // console.log(`getToMenurouter.push: ${path}`);
        } catch (e) {
          // console.error("on catch", e);
        }
      }
    },
    setCookieChannelType(main_menu_type) {
      let mainMenu = this.userInfo.menu;

      switch (true) {
        case main_menu_type == 'cock':
          for (let i = 0; i <= mainMenu.menu1.channels.length; i++) {
            if (mainMenu.menu1.channels[i].is_enable) {
              this.$cookies.set("sbc_channel_type", mainMenu.menu1.channels[i].channel_type);
              this.UPDATE_CHANNEL_ID(mainMenu.menu1.channels[i].channel_type);
              break;
            }
          }
          // console.log(`getToMenucockfight: ${this.$cookies.get('sbc_channel_type')}`);
          return true;
        case main_menu_type == 'cock_casinolive':
          for (let i = 0; i <= mainMenu.menu5.channels.length; i++) {
            if (mainMenu.menu5.channels[i].is_enable) {
              this.$cookies.set("sbc_channel_type", mainMenu.menu5.channels[i].channel_type);
              this.UPDATE_CHANNEL_ID(mainMenu.menu5.channels[i].channel_type);
              break;
            }
          }
          // console.log(`getToMenucockfight: ${this.$cookies.get('sbc_channel_type')}`);
          return true
        case main_menu_type == 'casino':
          // this.$cookies.set("sbc_channel_type", mainMenu.menu2.channels[0].channel_type);
          // this.$cookies.set('sbc_casino_live',  mainMenu.menu2.channels[0].channel_type) 

          if (this.$cookies.get('sbc_live_channel')) {
            this.$cookies.set('sbc_live_channel', this.$cookies.get('sbc_live_channel'));
            this.$cookies.set("sbc_channel_type", this.$cookies.get('sbc_live_channel'));
            this.UPDATE_CHANNEL_ID(this.$cookies.get('sbc_live_channel'));
            return true
          }
          else {
            for (let i = 0; i <= mainMenu.menu2.channels.length; i++) {
              if (mainMenu.menu2.channels[i].is_enable) {
                this.$cookies.set('sbc_live_channel', mainMenu.menu2.channels[i].channel_type);
                this.$cookies.set("sbc_channel_type", mainMenu.menu2.channels[i].channel_type);
                this.UPDATE_CHANNEL_ID(mainMenu.menu2.channels[i].channel_type);
                break;

              }
            }
          }

          // console.log(`getToMenucasino: ${this.$cookies.get('sbc_channel_type')}`);
          return true
        case main_menu_type == 'lotto_game':
          for (let i = 0; i <= mainMenu.menu3.games.length; i++) {
            if (mainMenu.menu3.games[i].is_enable) {
              this.$cookies.set("sbc_channel_type", mainMenu.menu3.games[i].channels[0].channel_type);
              this.UPDATE_CHANNEL_ID(mainMenu.menu3.games[i].channels[0].channel_type);
              break;
            }
          }
          // this.$cookies.set("sbc_channel_type", this.userInfo.menu.menu3.games[0].channels[0].channel_type);
          // this.UPDATE_CHANNEL_ID(this.userInfo.menu.menu3.games[0].channels[0].channel_type);
          return true
        case main_menu_type == 'machine_game':
          for (let i = 0; i <= mainMenu.menu4.games.length; i++) {
            if (mainMenu.menu4.games[i].is_enable) {
              this.$cookies.set("sbc_channel_type", mainMenu.menu4.games[i].channels[0].channel_type);
              this.UPDATE_CHANNEL_ID(mainMenu.menu4.games[i].channels[0].channel_type);
              break;
            }
          }
          // console.log(`getToMachineGame: ${this.$cookies.get('sbc_channel_type')}`);
          return true
        case main_menu_type == 'sport_game':
          this.$cookies.set("sbc_channel_type", 'sport_game');
          this.UPDATE_CHANNEL_ID('9999');
          // console.log(`getToMachineGame: ${this.$cookies.get('sbc_channel_type')}`);
          return true
        default:
          return false
      }
    },
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "SELECT_GAME_ID",
      "UPDATE_MAIN_MENU",
      "UPDATE_EXPAND_MENU"
    ]),
  },
};
</script>
