export default {
	home: 'Trang chủ',
	invoice: 'វិក័យប័ត្រ',
	patients: 'អ្នកជំងឺ',
	doctors: 'គ្រូពេទ្យ',
	stock: 'ស្តុក',
	services: 'សេវាកម្ម',
	settings: 'ការកំណត់',
	contact: 'ទំនាក់ទំនង',
	new_invoice: 'បង្កើតវិក្កយបត្រថ្មី',
	detail_invoice: 'វិក្កយបត្រលំអិត',
	logout: 'đăng xuất',
	kk1: 'Tiger',
	kk2: 'Gourd',
	kk3: 'Rooster',
	kk4: 'Lobster',
	kk5: 'Crab',
	kk6: 'Fish',
	tsOdd: 'ODD',
	tsEven: 'Even',
	ts1: '1',
	ts2: '2',
	ts3: '3',
	ts4: '4',
	ts5: '5',
	ts6: '6',
	tsBig: 'O',
	tsSmall: 'U',
	betting_history:"lịch sử cá cược",
	withdraw:"rút",
	deposit:"tiền gửi",
	account_detail:"thông tin tài khoản",
};
