<template>
    <div>
      <div class="g-header pa-0">
          
        <div class="g-summary pl-4 pr-2 pt-1">
          <div
            class="g-num-status w-100 justify-content-between align-items-center pt-0"
          >
            <div
              class="g-num w-50 w-sm-25 text-uppercase text-left pt-0 mt-0"
              style="font-size: 1.7rem"
            >
              #
              <span style="color: rgb(240, 197, 89); font-weight: 800">{{
                passChannel.fight_no
              }}</span>
            </div>
            <div
              class="side-title w-50 w-sm-25 g-status font-weight-bold text-uppercase text-right pt-3"
            >
              <span :class="textColorBaseOnChannelStatus(passChannel.is_open)">
                {{ $t(`title.${checkChannelStatus(passChannel.is_open)}`) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
      <marquee
        v-if="passChannel.is_maintain"
        class="open-betting-countdown red--text mb-3"
      >
        <div >
          {{ passChannel.maintain_desc }}
        </div>
      </marquee>
      <div
        v-else-if="
          !passChannel.is_maintain && getCountdown && getCountdown.type == 'open'
        "
        class="mb-2 open-betting-countdown"
        :class="getCountdown.countDown > 10 ? 'open-betting' : 'close-betting'"
        width="100%"
        direction="left;"
      >
        {{ getCountdown.countDown - 5 }}
      </div>
      <div
        v-else-if="
          !passChannel.is_maintain &&
          (passChannel.is_open == null || passChannel.is_open == false) &&
          passTotalLotto &&
          passTotalLotto > 0
        "
        class="mb-2 pa-0 open-betting-countdown"
        style="margin-top: -6px"
        :class="
          getCountdown.type == 'open' ? 'open-betting' : 'result-betting-loto'
        "
        width="100%"
        direction="left;"
      >
        <span style="color: #013366">{{ $t("title._result") }} </span>
        <span class="red--text">
          {{ passTotalLotto ? passTotalLotto : "" }}
        </span>
      </div>
      <marquee
        v-else-if="
          !passTotalLotto &&
          !passChannel.is_maintain &&
          ( passChannel.is_open == false ||
            (getCountdown && getCountdown.type) == 'close')
        "
        scrollamount="15"
        class="mb-2 open-betting-countdown"
        :class="getCountdown.type == 'open' ? 'open-betting' : 'close-betting'"
        width="100%"
        direction="left;"
      >
        {{ $t("title.gameIsClose") }}
      </marquee>
      <marquee
      v-else-if="
        passChannel.is_open == null || passChannel.is_open == true
      "
      scrollamount="15"
      class="open-betting-countdown mt-1 mb-0"
      :class="passChannel.is_open == null ?'close-betting':  passChannel.is_open == true
      ? 'open-betting' : '' "
      width="100%"
      direction="left;"
    >
      {{   passChannel.is_open == null ? $t("title.gameIsLive"):  passChannel.is_open == true
      ? $t("title.gameIsOpen") : ''  }}
    </marquee>
    
  </div>
    </div>
  </template>
  
  <script>
  import "@/assets/css/homepage.css";
  import { mapGetters } from "vuex";
  export default {
    props: ["passTotalLotto"],
    data() {
      return {
        countDown: {},
      };
    },
    computed: {
      ...mapGetters("$_casino_live", {
        getCountdown: "getCountdown",
        passChannel: "getChannel",
      }),
    },
    methods: {
      checkChannelStatus() {
        if (this.passChannel.is_open == null) {
          return "Not_Open";
        } else {
          if (this.passChannel.is_open) {
            return "Open";
          }
          return "Close";
        }
      },
      textColorBaseOnChannelStatus() {
        if (this.passChannel.is_open == null) {
          return "text-warning";
        } else {
          if (this.passChannel.is_open) {
            return "open-betting";
          }
          return "text-danger";
        }
      },
    },
  };
  </script>
  <style scoped>
  .open-betting-countdown {
    background-color: #def3f6;
    font-size: 1.3rem;
    font-family: "khmer mef1";
    font-weight: 900;
    height: 45px;
    text-align: center;
    padding-top: 8px;
    text-shadow: 0.2px 2px black;
    width:94%;
    border-radius: 3px;
    text-transform: uppercase;
    
  }
  
  .open-betting {
    color: #62da7e;
  }
  
  .close-betting {
    color: #dc3545;
  }
  .side-title {
    margin-top: -13px;
    font-size: 1.7rem;
    font-weight: 900 !important;
    text-shadow: 3px 0px 7px rgba(236, 235, 229, 0.3),
      -3px 0px 7px rgba(228, 226, 220, 0.2), 0px 4px 7px rgba(221, 216, 199, 0.3);
  }
  </style>