<template>
  <div class="pcoded-main-container">
    <div class="main-body">
      <div class="page-wrapper" style="overflow:hidden">
        <div class="page-body"  style="overflow:hidden">
          <DesktopScreenContainer
            v-on:sentSubmitBetting="createBetting"
            :passChannelStatus="channelStatus"
            :passBettingTypeMaster="bettingTypeMaster">
          </DesktopScreenContainer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/machine_game/dragon_tiger/screen_max_480.css";
import "@/assets/css/machine_game/dragon_tiger/screen_min_481.css";
import "@/assets/css/machine_game/dragon_tiger/screen_min_768.css";
import "@/assets/css/machine_game/dragon_tiger/screen_min_993.css";
import "@/assets/css/machine_game/dragon_tiger/screen_min_1025.css";
import "@/assets/css/machine_game/dragon_tiger/screen_min_1281.css";
import "@/assets/css/machine_game/dragon_tiger/screen_min_1441.css";
import "@/assets/css/machine_game/dragon_tiger/screen_min_1661.css";
import "@/assets/css/machine_game/dragon_tiger/screen_min_1824.css";
import "@/assets/css/machine_game/dragon_tiger/machine_dragon_share_style.css";
import "@/assets/css/machine_game/machine_share_style.css";
import "@/assets/css/machine_game/sub_game_share/screen_max_480.css";
import "@/assets/css/machine_game/sub_game_share/screen_min_481.css";
import "@/assets/css/machine_game/sub_game_share/screen_min_768.css";
import "@/assets/css/machine_game/sub_game_share/screen_min_993.css";
import "@/assets/css/machine_game/sub_game_share/screen_min_1025.css";
import "@/assets/css/machine_game/sub_game_share/screen_min_1281.css";
import "@/assets/css/machine_game/sub_game_share/screen_min_1441.css";
import "@/assets/css/machine_game/sub_game_share/screen_min_1661.css";
import "@/assets/css/machine_game/sub_game_share/screen_min_1824.css";

import DesktopScreenContainer from "./components/DesktopScreenComponent/DesktopScreenContainer.vue";
import socket from "~mixin/MX_SocketMachineGame.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    DesktopScreenContainer,
  },
  mixins: [socket],
  data() {
    return {
      error: false,
      bettingObj: {
        number_id: null,
        amount: null,
      },
      isBetting: false,
      data: {
        rows_per_page: 6,
        page: 1,
      },
      selectedSlotGameId: null,
      requestApi: "",
      channelId : this.$cookies.get("sbc_channel_type")
    };
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      channel: "getChannel",
      channelStatus: "getChannelStatus",
      resultObj: "getResult",
      bettingTypeMaster: "getBettingTypeMaster",
      todayBetting: "getTodayBetting",
      currentBetting: "getCurrentBetting",
      weightObj: "getWeight",
      getButton: "getButton",
      getLoading: "getLoading",
      loadingTodayReport: "getLoadingTodayReport",
      getClientTime:"getClientTime",
      getTigerCard:"getTigerCard"
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
      isSound: "getIsSound",
      userNotList: "getNotList",
    }),
  },
  created() {
     if (!this.$cookies.get("sbc_selected_slot_game")) {
      this.selectedSlotGameId = 95;
      this.$cookies.set("sbc_selected_slot_game", this.selectedSlotGameId);
      this.SELECT_GAME_ID(this.selectedSlotGameId);
    } else {
      this.SELECT_GAME_ID(this.$cookies.get("sbc_selected_slot_game"));
    }
    this.connectedSocket();
    this._initialData();

  },
  methods: {
    connectedSocket() {
      this.$connectSocket();
      this.listenerSocket();
    },
    
    async _initialData() {
      this.requestApi = this._calculateRequestApiKeyword(this.channelId);
      await this.fetchChannel({ data: {}, base_url: this.requestApi });
      await this.fetchResult({ data: {}, base_url: this.requestApi });
      await this.fetchButton({ data: {}, base_url: this.requestApi });
      await this.fetchBettingTypeMaster({
        data: {},
        base_url: this.requestApi,
      });
      await this.fetchCurrentBetting({ data: {}, base_url: this.requestApi });
      await this.fetchTodayBetting({ data: {}, base_url: this.requestApi });
      this.calculateData();
    },
    validateBeforeBetting(submitBettingData) {
      // console.log(submitBettingData)
      const money = submitBettingData.amount;
      const userBalance = this.userInfor.balance;
      if (
        this.getBettingStatus() &&
        submitBettingData.amount > 0 &&
        userBalance >= money &&
        this.isBetting == false
      ) {
        return true;
      } else if (!this.getBettingStatus()) {
        this.bettingStatus = "Game is not open";
        this.UPDATE_ERROR_MESSAGE(this.$t("title.game_is_close"));
        this.$toastr.e(this.$t("title.game_is_close"));
        return false;
      } else if (
        submitBettingData.amount == 0 ||
        submitBettingData.amount == null
      ) {
        this.bettingStatus = this.$t("title.inputAmount");
        this.UPDATE_ERROR_MESSAGE(this.$t("title.inputAmount"));
        this.$toastr.e(this.$t("title.inputAmount"));
        return false;
      } else if (userBalance < money) {
        this.bettingStatus = "Not enought Balance";
        this.UPDATE_ERROR_MESSAGE(this.$t("title.not_enought_balance"));
        this.$toastr.e(this.$t(`title.not_enought_balance`));
        return false;
      } else if (this.isBetting == true) {
        this.bettingStatus = "We processing your previous betting, please wait";
        this.UPDATE_ERROR_MESSAGE(this.$t("title.loading"));
        this.$toastr.e(this.$t("title.loading"));
        return false;
      } else {
        this.UPDATE_ERROR_MESSAGE(this.$t(`something when wrong`));
        this.$toastr.e(`something when wrong`);
        return false;
      }
    },
    async createBetting(submitBettingData) {
      this.bettingObj = submitBettingData;
      if (this.validateBeforeBetting(submitBettingData)) {
        try {
          this.isBetting = true;
          this.LOADING(true);
          const response = await this.fetchCreateBetting({
            data: this.bettingObj,
            base_url: this.requestApi,
          });
          if (response.data.code) {
            this.$toastr.s(this.$t("title.SUCCESS"));
            this.INPUT_BETTING_AMOUNT(0);
            this.UPDATE_SELECT_DRAGON_BUTTON(null);
            this.UPDATE_ERROR_MESSAGE("");
            this.bettingStatus = "Betting is success";
            await this.updateBalance(response.data.data.balance);
            this.BETTING_DIALOG(false);
            this.LOADING(false);
            this.isBetting = false;
            this.UPDATE_BET_AMOUNT(response.data.data);
            this.UPDATE_CURRENT_BETTING(response.data.data);
            this.UPDATE_PAYOUT(response.data.data);
          } else {
            if (response.data.message.descriptions == "Channel is not open") {
              this.UPDATE_ERROR_MESSAGE(this.$t("title.game_is_close"));
              this.UPDATE_CH_STATUS(false);
            } else if (response.data.message.descriptions == "Tie is limit") {
              this.UPDATE_ERROR_MESSAGE(this.$t("title.limit"));
              this.$toastr.e(this.$t("title.limit"));
            } else if (
              response.data.message.descriptions ==
              "Betting count is over limit"
            ) {
              this.UPDATE_ERROR_MESSAGE(
                this.$t("title.betting_count_over_limit")
              );
              this.$toastr.e(this.$t("title.betting_count_over_limit"));
            } else {
              this.$toastr.e(`${response.data.message.descriptions}`);
              this.UPDATE_ERROR_MESSAGE(
                this.$t("title." + response.data.message.descriptions)
              );
            }
            this.isBetting = false;
            this.LOADING(false);
            this.bettingStatus = response.data.message.descriptions;
          }
        } catch (error) {
          this.bettingStatus = this.$t("title.fales");
          this.UPDATE_ERROR_MESSAGE(this.$t("title.fales"));
          this.LOADING(false);
          this.error = true;
          return error;
        } finally {
          this.bettingMoney = 0;
          this.loading = false;
          this.LOADING(false);
        }
      }
    },

    async callTodayReport() {
      this.requestApi = this._calculateRequestApiKeyword(this.channelId);
      await this.fetchTodayBetting({ data: {}, base_url: this.requestApi });
    },
    getBettingStatus() {
      if (
        this.channel.is_open &&
        this.userInfor.enable &&
        this.channel.enable
      ) {
        return true;
      }
      return false;
    },
    calculateData() {
      this.RESET_COUNTDOWN();
    },
    ...mapMutations("$_dashboard_live", [
      "SEND_CARD",
      "UPDATE_BACCARAT_CARD",
      "SEND_BACK_CARD",
      "CLEAR_PLAYER_CARD",
      "CLEAR_TO_DEFAULT",
      "APPEND_KLAKLOUK_RESULT",
      "APPEND_KLAKLOUK_CLEAR_START",
      "CLIENT_RANDOM",
      "UPDATE_CURRENT_GAME_RESULT",
      "UPDATE_CLIENT_TIME",
      "LATEST_RESULT",
      "UPDATE_CURRENT_BETTING",
      "UPDATE_CHANNEL",
      "UPDATE_RESULT",
      "UPDATE_AMOUNT",
      "UPDATE_PAYOUT",
      "UPDATE_TODAY_BETTING",
      "RESET_AMOUNT",
      "UPDATE_CHANNEL_STATUS",
      "RELOAD_PAGE",
      "CLEAR_CACH",
      "UPDATE_AMOUNT_MAX",
      "UPDATE_BET_AMOUNT",
      "UPDATE_CH_STATUS",
      "BETTING_DIALOG",
      "UPDATE_DRAGON_CARD",
      "UPDATE_TIGER_CARD",
      "UPDATE_FIST_LOAD_BACARAT_CARD",
      "RESET_CARD",
      "UPDATE_FIST_LOAD_TIGER_DRAGON_CARD",
      "UPDATE_IS_REPORT",
      "UPDATE_COUNTDOWN",
      "UPDATE_PLAYER_CARD_UPDATED",
      "UPDATE_BANKER_CARD_UPDATED",
      "UPDATE_SECOND_CARD",
      "UPDATE_FIRST_CARD",
      "RESET_COUNTDOWN",
      "UPDATE_KLAKLOUK_CARD",
      "UPDATE_LOTTO_CARD",
      "UPDATE_YUKI_CARD",
      "UPDATE_ABONG_CARD",
      "UPDATE_TAISIV",
      "UPDATE_FANTAN",
      "LOADING",
      "UPDATE_ERROR_MESSAGE",
      "UPDATE_SELECT_DRAGON_BUTTON",
      "UPDATE_CLIENT_CARD"
    ]),
    ...mapActions("$_modules", [
      "updateBalance",
      "updateUserInfo",
      "fetchUserInfo",
      "fetchNotList",
      "fetchNotCount",
      "refreshNotiList",

    ]),
    ...mapActions("$_dashboard_live", [
      "fetchResult",
      "fetchChannel",
      "fetchBettingTypeMaster",
      "fetchTodayBetting",
      "fetchCurrentBetting",
      "fetchCreateBetting",
      "updateCurrentBetting",
      "updateChannel",
      "updateResult",
      "fetchWeight",
      "fetchButton",
    ]),
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_USER_INFO",
      "UPDATE_NOTI_DATA",
      "SELECT_GAME_ID",
      "INPUT_BETTING_AMOUNT",
      'CLEAR_BETTING_AMOUNT'
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.$socket.removeAllListeners();
    this.$socket.disconnect();
    this.CLEAR_CACH();
    next();
  },
};
</script>