<template>
    <v-flex class="fluid machine-game" xs12 md12>
      <!-- <div class="card event-header mb-0" v-if="channel.is_maintain">
        <div >
          <h5 class="event-title">{{ channel.maintain_desc }}</h5>
        </div>
      </div> -->
      <div id="video-container" class="video-container">
        <div >
          <iframe :src="urlToken" frameborder="1" class="iframe-vid" width="100%" height="100%" allowfullscreen>
          </iframe>
          <div v-if="isShow" style="position:absolute; left:0; width:100%; height: 100% !important; z-index:2">
            <div style="position:relative; left:0; color:#000000; font-weight:bold; padding:25%; padding-bottom:0; width:100%; text-align:center">
                <!-- <v-btn v-if="isShow" icon x-large @click="playVideo"  variant="tonal" dark>
                  <v-icon size="90">mdi-play</v-icon>
                </v-btn> -->
              <img @click="playVideo" src="../../../../../../../public/images/btn_play.png" style="width: 40px; height: 40px;">
            </div>
          </div>
        </div>
      </div>
    </v-flex>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props:['passurl', 'channelType'],
  data() {
    return {
      urlToken: "",
      isShow: true
    };
  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    // this.getVideoToken(this.channelType);
  },
  methods: {
    playVideo(){
      this.getVideoToken(this.channelType);
    },
    // getUrl() {
    //   if (this.passurl && (this.passurl[0] || this.passurl[1])) {
    //     if (this.passurl[0]) {
    //       return this.paspassurlsUrl = this.passurl[0];
    //     } else {
    //       return this.passurl = this.passurl[1];
    //     }
    //   }
    // },
    async getVideoToken(type) {
      try {
        const response = await this.fetchVideoToken(type);
        if (response.data.code) {
          this.isShow = false;
          // if (response.data.data.token && response.data.data.playOrder) {
          //   this.urlToken = `${this.getUrl()}&playOrder=${response.data.data.playOrder}&token=${response.data.data.token}`;
          // } else if (response.data.data.token && !response.data.data.playOrder) {
            this.urlToken = `${this.getUrl()}&token=${response.data.data.token}`;
          // } else if (!response.data.data.token && response.data.data.playOrder) {
          //   this.urlToken = `${this.getUrl()}&playOrder=${response.data.data.playOrder}`;
          // } else {
          //   this.urlToken = this.getUrl();
          // }
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
        }
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    getUrl() {
      if (
        this.passurl &&
        (this.passurl[0] || this.passurl[1])
      ) {
        if (this.passurl[0]) {
          return this.passurl = this.passurl[0];
        } else {
          return this.passurl = this.passurl[1];
        }
      }
    },
    ...mapActions("$_cockfight_casino_live", [
      "fetchVideoToken",
    ]),
  },
};
</script>


<style scoped>
.black-screen {
  background-color: #212427;
}


.video-container {
  padding-bottom: 65%;
  margin-top: 0px;
  position: relative;
  background-color: #565656;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
  border: #566573 1px solid;
}

.iframe-vid {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
}
</style>