<template>
    <div class="justify-content-center row text-center mx-2 my-6 px-0">
      <div class="text-center w-100 display-money mb-5">
        {{
          userInfo && userInfo.currency_type == 2
            ? formatMoney(getBettingAmount)
            : formatMoney(getBettingAmount)
        }}
      </div>
  
      <div class="row ma-0 w-100 mx-auto text-center">
        <div class="row coin-container ma-0 px-0 gp-scroll">
          <div
            v-for="(button, index) in passGetButton"
            :key="button._id"
            class="ma-0 p-0 col-2"
          >
            <!-- <div @click="_updateAmount(parseInt(passPlaceCoin))" style="color: aliceblue;">{{ passPlaceCoin }}</div> -->
            <div @click="_inputAmount(button.amount)">
              <img :src="_detectCoin(index)" class="btn-coin text-center" />
              <div
                text
                class="text btn-bet-circle prevent-select"
              >
                {{ button.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapGetters, mapMutations } from "vuex";
  import soundFunction from "~mixin/MX_SoundFunctionCollection.js";
  
  
  export default {
    props: ["passGetButton", "passChannel", "passPlaceCoin"],
    mixins: [soundFunction],
    data() {
      return {
        selectedButton: {},
        coinClickCounter: 0,
      };
    },
  
    computed: {
      ...mapGetters("$_modules", {
        userInfo: "getUserInfo",
        getBettingAmount:"getBettingAmount"
      }),
    },
    methods: {
      ...mapMutations("$_modules", ["UPDATE_UPDATE_BETTING_AMOUNT"]),
  
      _detectCoin(index) {
        switch (index) {
          case 0:
            return "/images/coins/blue.png";
          case 1:
            return "/images/coins/pink.png";
          case 2:
            return "/images/coins/green.png";
          case 3:
            return "/images/coins/cyan.png";
          case 4:
            return "/images/coins/orange.png";
          case 5:
            return "/images/coins/red.png";
          case 6:
            return "/images/coins/blue.png";
          case 7:
            return "/images/coins/pink.png";
          case 33:
            return "/images/coins/grey.png";
          default:
            return "";
        }
      },
      _inputAmount(money) {
        if(this.getBettingAmount == 0) {
          this.coinClickCounter = 0;
        }
        this._playSoundInsertCoin()
        this.coinClickCounter++;
        this.UPDATE_SELECT_COIN(this.coinClickCounter);
        this.UPDATE_UPDATE_BETTING_AMOUNT(money);
      },
      formatCoinCurrency(amount) {
        switch (amount) {
          case 1000:
            return "1ព";
          case 5000:
            return "5ព";
          case 10000:
            return "1ម";
          case 20000:
            return "2ម";
          case 50000:
            return "5ម";
          case 100000:
            return "10ម";
          case 200000:
            return "20ម";
          case 500000:
            return "50ម";
          case 1000000:
            return "1ល";
          case 5000000:
            return "5ល";
          default:
            return amount;
        }
      },
      formatBtnCurrencyKh(amount) {
        switch (true) {
          case amount >= 1000 && amount <= 9999:
            return amount / 1000 + "ព";
          case amount >= 10000 && amount <= 999999:
            return amount / 10000 + "ម";
          case amount >= 1000000 && amount <= 99999999999:
            return amount / 1000000 + "ល";
          default:
            return amount;
        }
      },
      formatBtnCurrency(amount) {
        switch (true) {
          case amount >= 1000 && amount <= 9999:
            return amount / 1000 + "K";
          default:
            return amount;
        }
      },
      formatMoney(amount) {
        return amount.toLocaleString();
      },
      ...mapMutations("$_dashboard", ["BETTING_DIALOG"]),
      ...mapMutations("$_dashboard_live", [
        "UPDATE_SELECT_COIN",
      ]),
    },
  };
  </script>
  <style scoped>
  @media (max-width: 760px) {
    .btn-coin {
      width: 100%;
      height: auto;
      position: relative;
      padding: 0.2rem;
      margin: 0px !important;
    }
  
    .btn-coin-cover:hover {
      cursor: pointer;
    }
  
    .btn-coin-cover {
      width: 100%;
    }
  
    .btn-bet-circle {
      font-family: "khmer mef1";
      position: absolute;
      font-size: 1rem;
      font-weight: 900;
      text-shadow: 1px 0px 1px black;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-51%, -51%);
      padding: 0px;
      margin: 0px !important;
    }
  }
  
  @media (min-width: 760px) {
    .btn-coin {
      width: 100%;
      height: auto;
      position: relative;
      padding: 0.25rem;
      margin: 0px !important;
    }
  
    .btn-coin-cover:hover {
      cursor: pointer;
    }
  
    .btn-bet-circle {
      font-family: "khmer mef1";
      position: absolute;
      font-size: 1rem;
      font-weight: 900;
      text-shadow: 1px 0px 1px black;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-51%, -51%);
      padding: 0px;
      margin: 0px !important;
    }
  }
  
  @media (min-width: 960px) {
    .btn-coin {
      width: 100%;
      height: auto;
      position: relative;
      padding: 0.25rem;
      margin: 0px !important;
    }
  
    .btn-coin-cover:hover {
      cursor: pointer;
    }
  
    .btn-bet-circle {
      font-family: "khmer mef1";
      position: absolute;
      font-size: 1rem;
      font-weight: 900;
      text-shadow: 1px 0px 1px black;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-51%, -51%);
      padding: 0px;
      margin: 0px !important;
    }
  }
  
  @media (min-width: 1280px) {
    .btn-coin {
      width: 100%;
      height: auto;
      position: relative;
      padding: 0.3rem;
      margin: 0px !important;
    }
  
    .btn-coin-cover:hover {
      cursor: pointer;
    }
  
    .money-in-coin-position {
      margin-top: -52px;
      margin-bottom: 25px;
      z-index: 100;
      font-weight: 900;
      font-size: 16px;
    }
  
    .btn-bet-circle {
      font-family: "khmer mef1";
      position: absolute;
      font-size: 16px;
      font-weight: 900;
      text-shadow: 1px 1px 1px black;
      top: 50%;
      left: 50%;
      transform: translate(-51%, -51%);
      text-shadow: 1px -2px 10px black;
      padding: 0px;
      margin: 0px !important;
    }
  }
  
  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }
  .hold-coin {
    position: sticky;
    left: 0px;
    z-index: 100;
    background: #013366;
    font-size: bolder;
    border-right: 2px dashed white;
  }
  .coin-container {
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
  }
  .coin-container:hover {
    cursor: pointer;
  }
  .display-money {
    background-color: #7592af65;
    height: 40px;
    border-radius: 5px;
    margin: 5px 0px 7px 0px;
    color: #212427;
    font-weight: bolder;
    text-shadow: 1px 0.2px #406386;
    font-size: 1.3rem;
    padding-top: 5px;
    color:white;
  }
  </style>