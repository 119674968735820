/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'

let request = new Request;

const fetchDarkMode = async ({ commit }, payload) => {
	commit('UPDATE_DARK_MODE', payload);
};
const updateBalance = async({ commit }, data) => {
    commit('UPDATE_BALANCE', data);
};
const updateUserInfo = async({ commit }, data) => {
    commit('UPDATE_USER_INFO', data);

};
const fetchUserInfo = async ({ commit }) => {
	const response = await request.get({
        url: 'user'
    })
	if (response.data.code) {
        let res = response.data.data
        commit('UPDATE_USER_INFO', res);
        _defineSubmenu({ commit }, res);
    }
};
const fetchNotCount = async ({ commit }) => {
	const response = await request.get({
        url: 'notification/notif_count'
    })
	if (response.data.code) {
        commit('UPDATE_NOTI_COUNT', response.data.data);
    }
};

const removeDeposit = async ({ commit, dispatch }, { vm, data, }) => {
	const response = await request.delete({
        url: 'notification/deposit',
        data
    })
	if (response.data.code) {
        let requestData={
            rows_per_page : 6,
            page : 1
        }
        
        let res = await dispatch('fetchNotList', requestData );
        if(res){
            commit('UPDATE_NOTI_DATA', res.reports);
        }
        await dispatch('fetchNotCount');
        commit('DIALOG', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
        return true;
    }else{
        vm.$toastr.e(`${response.data.message.descriptions}`)
        return false;
    }
};
const removeWithdraw = async ({ commit, dispatch }, { vm, data, }) => {
	const response = await request.delete({
        url: 'notification/withdraw',
        data
    })
	if (response.data.code) {
        let requestData={
            rows_per_page : 6,
            page : 1
        }
        let res = await dispatch('fetchNotList', requestData );
        if(res){
            commit('UPDATE_NOTI_DATA', res.reports);
        }
        dispatch('fetchNotCount');
        commit('DIALOG', false);

        vm.$toastr.s(`${response.data.message.descriptions}`)
        return true;
    }else{
        vm.$toastr.e(`${response.data.message.descriptions}`)
        return false;
    }
};

const fetchNotList = async ({ commit }, data) => {
    commit('LOADING', true);
	const response = await request.post({
        url: 'notification',
        data
    })
	if (response.data.code) {
        commit('UPDATE_NOTI_LIST', response.data.data);
        commit('LOADING', false);
        return response.data.data
    }
};
const refreshNotiList = async ({ commit }) => {
    let data = {
        rows_per_page: 6,
        page: 1
    };
	const response = await request.post({
        url: 'notification',
        data
    })
	if (response.data.code) {
        commit('UPDATE_NOTI_DATA', response.data.data);
    }
};
const fetchNotiDetail = async ({ commit }, data) => {
    commit('LOADING', true);
    commit('DIALOG', true);
	const response = await request.post({
        url: 'notification/detail',
        data
    })
	if (response.data.code) {
        commit('UPDATE_NOTI_DETAIL', response.data.data);
        commit('LOADING', false);
    }
};
const fetchPromoText = async ({commit}, data) => {
    const response = await request.get({
        url: `user/promotion/?app_id=${process.env.VUE_APP_ID}`,
        data
    })
    if (response.data.code) {
        commit('UPDATE_PROMOTEXT', response.data.data);
    }
};

const fetchAgentAccountList = async ({commit}, data) => {
    const response = await request.get({
        url: 'notification/agent_acc',
        data
    })
    if (response.data.code) {
        commit('UPDATE_AGENT_ACCOUNT_LIST', response.data.data);
    }
};
const fetchUserBank = async ({commit}) => {
    const response = await request.get({
        url: 'notification/player_acc'
    })
    if (response.data.code) {
        commit('UPDATE_USER_BANK', response.data.data);
    }
};
const getUserBank = async () => {
	return await request.get({
		url: 'notification/player_acc'
	});
};
const fetchCreateWithdraw = async ({}, data) => {
    return await request.post({
        url: '/notification/withdraw',
        data
    })
};
const fetchCreateDeposit = async ({}, data) => {
    return await request.post({
        url: '/notification/deposit',
        data
    })
};
const updateDeposit = async ({}, data) => {
    return await request.put({
        url: '/notification/deposit',
        data
    })
};
const updateWithdraw = async ({}, data) => {
    return await request.put({
        url: '/notification/withdraw',
        data
    })
};
const sortByOrder = (data) => {
    data.sort((a, b) => a.order - b.order);
    return data
}
const _defineSubmenu = ({ commit },res) => {
    if(res.menu && res.menu.menu1 )  commit('SUB_MENU_COCK', sortByOrder(res.menu.menu1.channels));
    if(res.menu && res.menu.menu2 )  {
        let ch2Menu = res.menu.menu2.channels;
        let filterMenu = [];
        for(let i = 0; i<ch2Menu.length; i++){
            if(ch2Menu[i].is_enable){
                filterMenu.push(ch2Menu[i]);
            }
        }
        commit('SUB_MENU_CASINO', sortByOrder(filterMenu))
    }
    if(res.menu && res.menu.menu5 )  commit('SUB_MENU_COCK_X_CASINO', sortByOrder(res.menu.menu5.channels));
    if(res.menu && res.menu.menu3 ) {
        commit('SUB_MENU_LOTTERY', sortByOrder(res.menu.menu3.games));
        for(let i = 0 ; i < res.menu.menu3.games.length ; i++){
        if(res.menu.menu3.games[i].channel_type == "lotto") commit('SUB_MENU_LOTTERY_MENU_LOTTO', sortByOrder(res.menu.menu3.games[i].channels));
        else if(res.menu.menu3.games[i].channel_type == "yuki") commit('SUB_MENU_LOTTERY_MENU_YUKI', sortByOrder(res.menu.menu3.games[i].channels));
        else if(res.menu.menu3.games[i].channel_type == "keno") commit('SUB_MENU_LOTTERY_MENU_KENO', sortByOrder(res.menu.menu3.games[i].channels));
        else if(res.menu.menu3.games[i].channel_type == "lottery") commit('SUB_MENU_LOTTERY_MENU_LOTTERY2D', sortByOrder(res.menu.menu3.games[i].channels));
        else if(res.menu.menu3.games[i].channel_type == "lottery3") commit('SUB_MENU_LOTTERY_MENU_LOTTERY3D', sortByOrder(res.menu.menu3.games[i].channels));
        else if(res.menu.menu3.games[i].channel_type == "lotteryvn") commit('SUB_MENU_LOTTERY_MENU_LOTTERYVN', sortByOrder(res.menu.menu3.games[i].channels));
        }
    }
    if(res.menu && res.menu.menu4 ) {
        commit('SUB_MENU_MACHINE_GAME', sortByOrder(res.menu.menu4.games));
        for(let i = 0 ; i < res.menu.menu4.games.length ; i++){
        if(res.menu.menu4.games[i].desc == "ALL") commit('SUB_MENU_MACHINE_GAME_MENU_ALL', sortByOrder(res.menu.menu4.games[i].channels));
        else if(res.menu.menu4.games[i].desc == "BACCARAT") commit('SUB_MENU_MACHINE_GAME_MENU_BACCARAT', sortByOrder(res.menu.menu4.games[i].channels));
        else if(res.menu.menu4.games[i].desc == "Dragon Tiger") commit('SUB_MENU_MACHINE_GAME_MENU_DRAGONTIGER', sortByOrder(res.menu.menu4.games[i].channels));
        else if(res.menu.menu4.games[i].desc == "Tai Xiu") commit('SUB_MENU_MACHINE_GAME_MENU_TAIXIU', sortByOrder(res.menu.menu4.games[i].channels));
        else if(res.menu.menu4.games[i].desc == "Do Den") commit('SUB_MENU_MACHINE_GAME_MENU_DODEN', sortByOrder(res.menu.menu4.games[i].channels));
        else if(res.menu.menu4.games[i].desc == "Sic Bo") commit('SUB_MENU_MACHINE_GAME_MENU_SICBO', sortByOrder(res.menu.menu4.games[i].channels));
        else if(res.menu.menu4.games[i].desc == "Kla Klouk") commit('SUB_MENU_MACHINE_GAME_MENU_KLAKLOUK', sortByOrder(res.menu.menu4.games[i].channels));
        }
    }
}

export default {
    fetchPromoText,
	fetchDarkMode,
	updateBalance,
	updateUserInfo,
	fetchUserInfo,
	fetchNotCount,
	fetchNotList,
    fetchUserBank,
    fetchAgentAccountList,
    fetchCreateWithdraw,
    fetchCreateDeposit,
    getUserBank,
    fetchNotiDetail,
    removeDeposit,
    removeWithdraw,
    refreshNotiList,
    updateDeposit,
    updateWithdraw
};