<template>
  <div>
    <v-row class="ma-0 w-100">
      <div class="machine-dragon-three-section machine-blue-title  machine-play-blue-bg"
        :class="getSelectedDodenBtn && getSelectedDodenBtn.button && getSelectedDodenBtn.button.name == passBetType[0].name && getSelectedDodenBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[0])"> 
        <p class="machine-payout-name machine-button-font">{{passBetType[0].payout}}</p>
        <p class="machine-button-name machine-button-font white--text">{{$t('title.small_tx')}}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-dragon-three-section machine-green-title  machine-play-green-bg"
        :class="getSelectedDodenBtn && getSelectedDodenBtn.button && getSelectedDodenBtn.button.name == passBetType[4].name && getSelectedDodenBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[4])"> 
        <p class="machine-payout machine-button-font">{{'1 : '+ passBetType[4].payout}}</p>
        <p class="machine-button-name machine-button-font white--text">{{passBetType[4].name}}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-dragon-three-section machine-red-title  machine-play-red-bg"
      :class="getSelectedDodenBtn && getSelectedDodenBtn.button && getSelectedDodenBtn.button.name == passBetType[1].name && getSelectedDodenBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[1])"> 
        <p class="machine-payout-name machine-button-font">{{passBetType[1].payout}}</p>
        <p class="machine-button-name machine-button-font white--text">{{$t('title.large_tx')}}</p>
      </div>
    </v-row>
    <MachineSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitDoden'"/>
  </div>
</template>
<script>
import {mapGetters, mapMutations } from "vuex";
import MachineSubmitButton from "~global_components/component_machine_game/machine_game_submit_button.vue"

export default {
  components: {
    MachineSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  computed:{
    ...mapGetters("$_machine_games", {
      getSelectedDodenBtn: "getSelectedDodenBtn",
    }),
  },

  methods:{
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_DODEN_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_machine_games", [
      "UPDATE_SELECTED_DODEN_BUTTON_IN_ALL",
    ]),
  }
}
</script>