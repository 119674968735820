<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="grid-container">
        <div v-for="number in 80" :key="number" class="lotto-number number">
          <div v-for="(result, i ) in passClientRandom.lotto" :key="i" :class="{
            'blink':
              passClientRandom.lotto[
              passClientRandom.lotto.length - 1
              ] == number,
            'l-number-active': result == number,
          }"
         
          >
            {{ result == number ? number : "" }}
          </div>
          <span  style="color: #212427!important">{{ number ? number : 0 }}</span>
        </div>
      </v-col>

    </v-row>
    <TableResult 
    class="hide-lotto-on-mobile"
    :passLastTwentyResults="passLastTwentyResults"/>
    
  </div>
</template>

<script>
import TableResult from './TableResult.vue';
export default {
  components:{TableResult},
  props: [
    "passClientRandom", "passLastTwentyResults"
  ],
  data() {
    return {
      
    }
  },
  watch: {
    passLastTwentyResults() {
    },
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
    orderResultUnderOver() {
      return this.getListResultsRange();
    },
  },
  methods: {
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      for (var index = this.passLastTwentyResults.length - 1; index >= 0; index--) {
        const item = this.passLastTwentyResults[index];
        if (index == this.passLastTwentyResults.length - 1) {
          newResult.push({
            ...item,
            row,
            col,
          });
        } else {
          const pre = newResult[newResult.length - 1];
          if (pre.small_large.name != item.small_large.name) {
            newResult.push({
              ...item,
              row: 1,
              col: pre.col + 1,
            });
          } else {
            if (pre.row >= 6) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              newResult.push({
                ...item,
                row: pre.row + 1,
                col: pre.col,
              });
            }
          }
        }
      }
      return newResult;

    },
    getListResultsRange() {
      if (this.passLastTwentyResults) {
        let row = 1;
        let col = 1;
        let newResult = [];
        for (var index = this.passLastTwentyResults.length - 1; index >= 0; index--) {
          const item = this.passLastTwentyResults[index];
          if (index == this.passLastTwentyResults.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (pre.range.name != item.range.name) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              if (pre.row >= 6) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
          // });
        }
        return newResult;
      }
      return [];

    },


  },
};
</script>
<style>
.cvcir-blue{
  border: 6px solid blue
}
.cvcir-red{
  border: 6px solid red
}
</style>