<template>
  <div class="mx-auto row w-100 justify-center py-3">
    <div class="mr-3">
      <v-btn
        @click="_clearMoney"
        class="btn mx-0 row mt-0 btn-clear-money"
        :disabled="loading"
      >
        <span>
          {{ $t("title.cancel") }}
        </span>
      </v-btn>
    </div>
    <div class="ml-3">
      <v-btn
        @click="_submitBetting"
        class="btn row mx-0 mt-0 btn-submit-money"
        :disabled="getBettingAmount == 0 || !getBettingAmount || loading"
      >
        <v-icon v-if="loading"> mdi-refresh mdi-spin </v-icon>
        <span >
          {{ $t("title.confirm") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["passCheckAllowSubmit"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      loading: "getLoading",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },

  methods: {
    _submitBetting() {
      this.$emit("handleSelectButton");
    },
    _clearMoney() {
      this.CLEAR_BETTING_AMOUNT();
      this.UPDATE_ERROR_MESSAGE();
      this.CLEAR_SELECTED_BTN();
    },
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT",
      "UPDATE_ERROR_MESSAGE",
    ]),
    ...mapMutations("$_dashboard", ["UPDATE_ERROR_MESSAGE", "UPDATE_COCK_SELECTED_BTN", "CLEAR_SELECTED_BTN"]),
  },
};
</script>
