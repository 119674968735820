<template>
    <div class="lotto-nav-head mx-0 pt-0" style="height:110px; width:100%;">
        <v-row no-gutters class="mx-0 w-100" style="overflow:hidden">
          <v-col cols="12">
            <div class="head-right-right " style="width: 100%">
              <ul style="width: 100%" class="mb-1">
                <li style="font-weight:700; font-size:1rem" class="pt-0 pl-1 text-left">
                  {{ moment(currentGame.date).format("YYYY/MM/DD") }}
                  <span class="item-right pr-0">{{ getCurrentDate()}}</span>
                </li>
                <li class="pl-1 pb-1 text-left">
                  <span style="font-size:.8rem">{{ $t("title.game_no") }}</span>
                  <span class="item-right" style="color:orange; font-weight:800; font-size:.8rem">#{{ currentGame.no }}</span>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col cols="12" v-if="getClientTime && getClientTime.time">
            <div
              class="head-right-last"
              style="background-color: rgba(159, 203, 254, 0.3)"
            >
              <span class="span-number span-number-on-run" :style=" getClientTime.time <=10 ?'color:darkred!important; text-shadow:1px 1px gold':''">{{
                getClientTime.time
              }}</span>
            </div>
          </v-col>
          <v-col cols="12" v-else>
            <marquee
              class="head-right-last mt-0"
              style="background-color: rgba(254, 159, 183, 0.3)"
            >
              <span class="span-number " style="color:red">
                {{ $t('title.game_close') }}</span>
            </marquee>
          </v-col>
        </v-row>
      </div>
</template>

<script>
import "@/assets/css/homepage.css";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            // countDown: {},
        }
    },
    computed: {
        ...mapGetters("$_lottery3d", {
            getClientTime: "getClientTime",
            currentGame: "getCurrentGame",
        }),
    },
    methods: {
        moment,
        getCurrentDate() {
          return moment().format("hh:mm:ss");
        },
    }
}
</script>
<style scoped>
.open-betting-countdown {
    background-color: #def3f6;
    font-size: 29px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 43px;
    text-align: center;
    padding-top: 0px;
    text-shadow: 0.2px 2px black;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}
</style>