<template>
  <div>
    <div class="lotto-nav-head" style="background:#0437b4;">
      <Tabs/>
    </div>
    <v-row no-gutters>
      <v-col cols="12" class="plus-middle-top-box" style="overflow:hidden">
        <v-layout class=" d-sm-none" style="margin-top:-16px; margin-bottom:10px;">
          <lottery-info />
        </v-layout>
       <display-result :passRandomNumber="passRandomNumber"  :passClientRandom="passClientRandom" :result_a="result_a" :ass_a="ass_a"  />
       <lottery-post />
       <lottery-betting style="background-color:#0437b4" class="d-sm-none mt-2"/>
       <ninety-betting-button/>
      </v-col>
    </v-row>   
  </div>
</template>
<script>
import "@/assets/css/lottery_menu/lottery2d/lottery.css";
// import {  mapGetters, mapMutations, mapActions  } from "vuex";
import LotteryInfo from "@/modules/FolderGames/LotteryMenu/Lottery/components/SubComponent/LotteryInfo.vue";
import LotteryBetting from "@/modules/FolderGames/LotteryMenu/Lottery/components/SubComponent/LotteryBetting.vue";
import Tabs from "~global_components/component_machine_game/Tabs.vue";
import DisplayResult from "./SubComponent/DisplayResult.vue";
import LotteryPost from "./SubComponent/LotteryPost.vue";
import NinetyBettingButton from './SubComponent/NinetyBettingButton.vue';
export default {
  components: {
    Tabs,
    DisplayResult,
    LotteryPost,
    NinetyBettingButton,
    LotteryInfo,
    LotteryBetting
  },
  props: ["passRandomNumber","passClientRandom", "result_a" ,'ass_a',
  ],
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>
