<template>
    <v-row class="ma-0">
        <v-col cols="5" class="px-1 py-0">
            <div style="height:140px; ">
                <div class="w-100 game-no-style">
                    <div style="height:50%" class="text-center ">
                        {{ $t('title.game_no') }}
                    </div>
                    <div style="height:60%" class="text-center pt-1 font-weight-bold">
                        {{ passCurrentGame && passCurrentGame.date ? (passCurrentGame.date.split("-").join("").slice(0, 8) + "#" +
                            passCurrentGame.no) : " " }}
                    </div>
                </div>
                <div class="user-balance-style">
                    <div class="text-center pt-0 balance-value font-weight-bold">
                        {{ $t('header.balance') }}
                    </div>
                    <div style="height:50%;" class="text-center pt-1 ">
                        <span>
                            {{ userInfor && userInfor.balance ?
                                _formatCurrency(userInfor) : '' }}{{ userInfor.currencySymbol }}</span>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="7" class="pa-0 pr-1">
            <div class="mb-0 pb-0">
                <v-row class="ma-0">
                    <v-col class="current-game-limit pa-0 mr-1">
                        <div class="w-100 text-center limit-title">
                            {{ $t("title.betLimit") }}
                        </div>
                        <div class="w-100 pt-5 pl-1 pl-md-1 pl-xl-4">
                            <span class="label-span limit-value font-weight-bold">{{ $t("title.min") }} :</span>
                            <span class=" text-danger font-weight-bold limit-value">
                                {{ formatMoney(passGetLimit.amount_min) }}
                            </span>
                            <br>
                            <span class="label-span limit-value font-weight-bold">{{ $t("title.max") }} :</span>
                            <span class=" text-danger font-weight-bold limit-value" >
                                {{ formatMoney(passGetLimit.amount_max) }}
                            </span>
                        </div>
                    </v-col>

                    <v-col class="current-game-limit pa-0">
                        <div class="w-100 text-center limit-title">
                            {{ $t("title.pleaseBet") }}
                        </div>
                        <div class="w-100 pt-7 text-center">
                            <span
                                class="h1 font-weight-bold"
                                :style="passClientTime ? (passClientTime.time <= 10 ? 'color:darkred!important; text-shadow:1px 1px gold' : '') : ''">
                                {{ passClientTime && passClientTime.time ? passClientTime.time : 0 }}
                            </span>
                        </div>

                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>
  
  
<script>

import { mapGetters } from "vuex";
import Mixin from "~mixin/MX_ShareFunctionCollection.js";
export default {
    components: {
        // Tabs,
    },
    props: [
        "passClientTime", "passCurrentGame", "passGetLimit"
    ],
    data() {
        return {
            mixins: [Mixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
    }
};
</script>