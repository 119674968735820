<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passIndex}}.{{ passChDesc }}</h5>
    <div class="game-machine-component-result">
      <div class="trends limit-on-tab" style="position: relative;">
        <!-- <div v-if="showResult && showResult.card_dragon && showResult.card_tiger" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
              <tr style="height: 38px;">
                <th style="background-color: rgba(40, 90, 226, .93); width: 50%;"><b>Tiger</b></th>
                <th style="background-color: rgba(224, 80, 73, .93); width: 50%;"><b>Dragon</b></th>
              </tr>
              <tr>
                <td style="background-color: rgba(40, 90, 226, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{showResult.card_tiger.value}}</b></td>
                <td style="background-color: rgba(224, 80, 73, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{showResult.card_dragon.value}}</b></td>
              </tr>
            </table>
          </div>
        </div> -->
        <div v-if="passTime && !passTime.is_open_betting && passTime.time == 0" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
              <tr style="height: 38px;">
                <th style="background-color: rgba(224, 80, 73, .93); width: 50%;"><b>Dragon</b></th>
                <th style="background-color: rgba(40, 90, 226, .93); width: 50%;"><b>Tiger</b></th>

              </tr>
              <tr>
                <td style="background-color: rgba(224, 80, 73, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;">
                  <img id="block01D" v-if="block01D" :src="block01D" style="max-width: 70px !important; height: auto; margin-right: 10px" class="blockcards-size" />
                </td>
                <td style="background-color: rgba(40, 90, 226, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;">
                  <img id="block01T" v-if="block01T" :src="block01T" style="max-width: 70px !important; height: auto; margin-right: 10px" class="blockcards-size" />
                </td>

              </tr>
            </table>
          </div>
        </div>
        <div>
          <div class="trend-legend-custome  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-wala">{{ resultObj.summary ? resultObj.summary.TIGER : 0 }}</span>
              <p class="mb-0 count-label">Tiger</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-meron">{{ resultObj.summary ? resultObj.summary.DRAGON : 0 }}</span>
              <p class="mb-0 count-label">Dragon</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-draw">{{ resultObj.summary ? resultObj.summary.TIE : 0 }}</span>
              <p class="mb-0 count-label">Tie</p>
            </div>
          </div>
        </div>
      <div class="px-3 pb-0 gp-panel-bg rounded-bottom landscape-trend"
        style="position: relative; background-color: #ffffff !important">
          <v-col cols="12" sm="12" style="padding: 0">
            <v-row style="padding: 0; margin: 0">
              <v-col
                cols="6"
                md="6"
                sm="6"
                lg="6"
                style="padding: 0 5px 0 0; margin: 0">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td v-for="col in fixColBac" :key="col">
                          <div>
                            <div
                              v-for="(result, i) in orderBaccaratResult"
                              :key="i"
                            >
                              <span v-if="col == result.col && row == result.row">
                                <v-badge
                                  v-if="
                                    result.is_pair_player && result.is_pair_banker
                                  "
                                  bordered
                                  left
                                  color="red"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-badge
                                    bordered
                                    bottom
                                    color="blue"
                                    dot
                                    offset-x="10"
                                    offset-y="10"
                                  >
                                    <v-avatar
                                      :color="'a' + result.color"
                                      size="18"
                                      style="
                                        margin: 0px;
                                        color: #ffffff;
                                        font-weight: bold;
                                      "
                                    >
                                      {{
                                        result.name == "DRAGON"
                                          ? "D"
                                          : result.name == "TIGER"
                                          ? "G"
                                          : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                      }}
                                    </v-avatar>
                                  </v-badge>
                                </v-badge>
                                <v-badge
                                  v-else-if="
                                    result.is_pair_player &&
                                    !result.is_pair_banker
                                  "
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-avatar
                                    :color="'a' + result.color"
                                    size="18"
                                    style="
                                      margin: 0px;
                                      color: #ffffff;
                                      font-weight: bold;
                                    "
                                  >
                                    {{
                                    result.name == "DRAGON"
                                          ? "D"
                                          : result.name == "TIGER"
                                          ? "G"
                                          : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                    }}
                                  </v-avatar>
                                </v-badge>
                                <v-badge
                                  v-else-if="
                                    !result.is_pair_player &&
                                    result.is_pair_banker
                                  "
                                  bordered
                                  left
                                  color="red"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-avatar
                                    :color="'a' + result.color"
                                    size="23"
                                    style="
                                      margin: 0px;
                                      color: #ffffff;
                                      font-weight: bold;
                                    "
                                  >
                                    {{
                                    result.name == "DRAGON"
                                          ? "D"
                                          : result.name == "TIGER"
                                          ? "G"
                                          : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                    }}
                                  </v-avatar>
                                </v-badge>
                                <v-avatar
                                  v-else
                                  :color="'a' + result.color"
                                  size="18"
                                  style="
                                    margin: 0px;
                                    color: #ffffff;
                                    font-weight: bold;
                                  "
                                >
                                  {{
                                  result.name == "DRAGON"
                                          ? "D"
                                          : result.name == "TIGER"
                                          ? "G"
                                          : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                  }}
                                </v-avatar>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-col cols="6" md="6" sm="6" lg="6" style="padding: 0; margin: 0">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td v-for="col in fixCol" :key="col">
                          <div>
                            <div v-for="(result, i) in orderResult" :key="i">
                            
                              <span v-if="col == result.col && row == result.row">
                                <v-badge
                                  v-if="
                                    result.is_pair_player && result.is_pair_banker
                                  "
                                  bordered
                                  left
                                  color="red"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-badge
                                    bordered
                                    bottom
                                    color="blue"
                                    dot
                                    offset-x="10"
                                    offset-y="10"
                                  >
                                    <v-icon
                                      :class="'c' + result.color"
                                      style="padding: 0; margin: 0"
                                      >mdi-circle-outline</v-icon
                                    >
                                  </v-badge>
                                </v-badge>
                                
                                <v-badge
                                  v-else-if="
                                    result.is_pair_player &&
                                    !result.is_pair_banker
                                  "
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-icon
                                    :class="'c' + result.color"
                                    style="padding: 0; margin: 0"
                                    >mdi-circle-outline</v-icon
                                  >
                                </v-badge>
                                <v-badge
                                  v-else-if="
                                    !result.is_pair_player &&
                                    result.is_pair_banker
                                  "
                                  bordered
                                  left
                                  color="red"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-icon
                                    :class="'c' + result.color"
                                    style="padding: 0; margin: 0"
                                    >mdi-circle-outline</v-icon
                                  >
                                </v-badge>
                                <span v-else>
                                  <v-icon
                                    :class="'c' + result.color"
                                    style="padding: 0; margin: 0"
                                    >mdi-circle-outline</v-icon
                                  >
                                </span>
                                
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>
    <div class="game-machine-component-reserve-space"></div>
  </div>
</template>
<script>
import shareFunction from "~mixin/MX_ShareFunctionCollection.js";
import { mapActions } from "vuex";

export default {
  mixins: [shareFunction],
  props:['passGame', 'passTime', 'passIndex', 'channelType', 'passChDesc', 'resultObjS', 'showResult', 'tigerCard', 'dragonCard'],
  data() {
    return {
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {},
      block01T: require('~public_image/a_game_access_global/cards/back_card.png'),
      block01D: require('~public_image/a_game_access_global/cards/back_card.png'),
      TigerCard1: "0052",
      DragonCard1: "0052"
    }
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
    number(bet) {
      return this.getNumber(bet);
    },
    orderBaccaratResult() {
      return this.getBaccaratResults();
    },
  },
  async created() {
    this.getResult(this.channelType);
  },
  watch: {
    resultObjS: function() { 
      // console.log('dragontiger.....')
      this.getResult(this.channelType);
    },
    dragonCard: function(newVal) { 
      console.log(`dragon:::: ${newVal}`);
      if(newVal.length > 0){
        if(newVal[0].name){
          if (this.DragonCard1 == "0052"){
            new Promise((resolve) => setTimeout(resolve, 1500));
            this.DragonCard1 = "../../../../../../images/a_game_access_global/cards_animation/" + newVal[0].name + ".gif";
            this.block01D = this.DragonCard1
          }
        }
      }else{
        this.DragonCard1 = "0052";
        this.block01D = require('~public_image/a_game_access_global/cards/back_card.png')
      }
    },
    tigerCard: function(newVal) { 
      console.log(`tiget:::: ${newVal}`);
      if(newVal.length > 0){
        for (var i = 0; i <= newVal.length; i++) {
          if(newVal[i].scan_number == 2){
            if (this.TigerCard1 == "0052"){
              new Promise((resolve) => setTimeout(resolve, 1500));
              this.TigerCard1 = "../../../../../../images/a_game_access_global/cards_animation/" + newVal[i].name + ".gif";
              this.block01T = this.TigerCard1
            }
          }
        }
      }else{
        this.TigerCard1 = "0052";
        this.block01T = require('~public_image/a_game_access_global/cards/back_card.png')
      }
    }
  },
  methods: {
    async getResult(channelType) {
      try {
        const response = await this.fetchMaBaResult(channelType);
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    getNumber(bet){
      let number = [];
      number = bet.split(":");
      return number[1]
    },
    findImageOfEachAnimal(animalName) {
      switch (animalName) {
          case 1:
            return "/images/a_each_game/klaklok/0001.png";
          case 2:
            return "/images/a_each_game/klaklok/0002.png";
          case 3:
            return "/images/a_each_game/klaklok/0003.png";
      }
    },
    getBaccaratResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
      if (this.resultObj.results != null) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          // console.log(index)
          const item = resultList[index];
          // console.log(item)
          if (row <= this.rows) {
            // console.log('row <= this.rows')
            // console.log(row)
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            // console.log('else')
            row = 1;
            col += 1;
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
        if (col > this.cols) {
          this.fixColBac = col;
        } else {
          this.fixColBac = this.cols;
        }
      }
      return newResult;
    },
    getListResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.resultObj.results != null && this.resultObj.results.length > 0){
            let resultList = this.resultObj.results
            for (var index = resultList.length - 1; index >= 0; index--) {
                const item = resultList[index];
                if(index == resultList.length - 1){
                    newResult.push({
                        ...item,
                        row,
                        col,
                    });
                }else{
                    const pre = newResult[newResult.length - 1];
                    if(pre.name != item.name){
                        newResult.push({ 
                        ...item,
                        row: 1,
                        col: pre.col + 1,
                        });
                    }else{
                        if (pre.row >= 6) {
                            newResult.push({
                                ...item,
                                row: 1,
                                col: pre.col + 1,
                            });
                        } else {
                            newResult.push({
                                ...item,
                                row: pre.row + 1,
                                col: pre.col,
                            });
                        }
                    }
                }
            }
        }
      return newResult;
    },
    ...mapActions("$_machine_games", ["fetchMaBaResult"]),
  }
}
</script>

<style>

</style>