<template>
    <div class="row ma-0 mt-2" v-if="getMachineButton">
        <v-img v-for="(i,index) in getMachineButton" :key="i.name" :src="findCoin(index)" @click="_inputAmount(i.amount)" contain class="machine-coins">
          <br v-if="index%3==0 ">
            <p class="machine-coins-value">{{ i.label }}</p>
        </v-img>
    </div>
</template>
<script>
    
import { mapGetters , mapMutations} from "vuex";
import shareFunction from "../../../../../mixin/MX_ShareFunctionCollection.js"

export default {
    mixins: shareFunction,
  components: {
  },
  data() {
    return {
        listCoins:[
            {
            path : require('../../../../../../public/images/a_game_access_global/coins/1.png'),
            name:'5$',
            value:5
            },
            {
                path : require('../../../../../../public/images/a_game_access_global/coins/2.png'),
                name:'10$',
                value:10
            },
            {
                path : require('../../../../../../public/images/a_game_access_global/coins/3.png'),
                name:'15$',
                value:15
            },
            {
                path : require('../../../../../../public/images/a_game_access_global/coins/4.png'),
                name:'20$',
                value:20
            },
            {
                path : require('../../../../../../public/images/a_game_access_global/coins/5.png'),
                name:'50$',
                value:50
            },
            {
                path : require('../../../../../../public/images/a_game_access_global/coins/6.png'),
                name:'100$',
                value:100
            },
        ]
    }
  },
  
  computed: {

    ...mapGetters("$_cockfight_casino_live", {
      getMachineButton: "getMachineButton",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },
  methods:{
    ...mapMutations("$_modules", ["UPDATE_UPDATE_BETTING_AMOUNT"]),
    findCoin(index){
      switch(index){
        case 0 :  return require('../../../../../../public/images/a_game_access_global/coins/1.png');
        case 1 :  return  require('../../../../../../public/images/a_game_access_global/coins/2.png');
        case 2 :  return  require('../../../../../../public/images/a_game_access_global/coins/3.png');
        case 3 :   return require('../../../../../../public/images/a_game_access_global/coins/4.png');
        case 4 :   return require('../../../../../../public/images/a_game_access_global/coins/5.png');
        case 5 :   return require('../../../../../../public/images/a_game_access_global/coins/6.png');

      }
    },
    _inputAmount(money) {
      if(this.getBettingAmount == 0) {
        this.coinClickCounter = 0;
      }
    //   this._playSoundInsertCoin()
      this.coinClickCounter++;
    //   this.UPDATE_SELECT_COIN(this.coinClickCounter);
      this.UPDATE_UPDATE_BETTING_AMOUNT(money);
    },
  }
  }
</script>