<template>
    <div class="flexi-machine-right">
        <div class="game-machine-betting-component">
          <div class="game-machine-input">
            <ListCoins/>
            <CockXCasinoInputAmount/>
            <OutStanding/>
            <h5>Statement</h5>
          </div>
          <div class="game-machine-statement">
            
            <Statement/>
          </div>

        </div>
    </div>
</template>
<script>
import ListCoins from "../SubComponent/ListCoins.vue"
import CockXCasinoInputAmount from "../SubComponent/CockXCasinoInputAmount.vue"
import OutStanding from "../SubComponent/OutStanding.vue"
import Statement from "../SubComponent/Statement.vue"

export default {
  components: {
    ListCoins,
    CockXCasinoInputAmount,
    OutStanding,
    Statement
    },
  }
</script>