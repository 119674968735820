<template>
    <v-dialog transition="dialog-top-transition" max-width="898" v-model="loginDialog" class="login-dialog"
        @click:outside="UPDATE_LOGIN_DIALOG(false)"
            style="box-shadow: none!important;">
            <v-flex class="form-login-section ">
                <div id="wrapper" class="ma-0">
                    <div class="auth-page login-wrapper bg-white">
                        <div class="login-panel position-relative">
                            <v-form ref="form" v-model="validate" lazy-validation @submit.prevent="handleLogin"
                                class="form-container">
                                <div class="form-group fg-container pt-2">
                                    <div class="btn-login-title">
                                        <img src="../../../../../public/images/a_page_access_global/login-icons/login_label.png"
                                            style="width: 200px;">
                                    </div>
                                </div>
                                <div class="form-group section-input pb-0 mb-0">
                                    <div class="form-group">
                                        <label class="h6 pt-0 pb-0 prime-color font-weight-bold">ឈ្មោះអ្នកប្រើប្រាស់ |
                                            User
                                            Name</label>
                                        <div class="input-group prime-input" style="">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text prime-bg-color" style="margin-left:-1px;">
                                                    <v-icon color="white">mdi-account </v-icon>
                                                </div>
                                            </div>
                                            <input v-model="user.user_name" class="form-control" placeholder="Username"
                                                required type="text" @input="error = false">
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <label class="h6 pt-0 pb-0 prime-color font-weight-bold">លេខសម្ងាត់ |
                                            Password</label>
                                        <div class="input-group prime-input mb-0" style="">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text prime-bg-color" style="margin-left:-1px;">
                                                    <v-icon color="white">mdi-lock </v-icon>
                                                </div>
                                            </div>
                                            <input v-model="user.password" class="form-control mb-0"
                                                placeholder="Password" required type="password" @input="error = false">
                                        </div>
                                    </div>
                                    <div class="form-group mb-0">
                                        <v-checkbox class="v-remember mt-0" v-model="checker" color="#0537b4"
                                            hide-details>
                                            <template v-slot:label>
                                                <span class="v-remember-label">ចាំគណនី | REMEMBER ME</span>
                                            </template></v-checkbox>
                                    </div>
                                    
                                <div v-if="error" style="color:#9e000e; " class="mb-4">{{
                                    errorMessage
                                }}</div>
                                    <div @click="_goToRouter('/register')" class="btn register-btn pt-2"> ចុះឈ្មោះ |
                                        Register
                                    </div>
                                </div>
                                <div class="pt-10" style="width: 100%;text-align: center;">
                                    <button v-if="!loading" type="submit" class="btn px-5 pt-2 submit-btn">
                                        {{ $t('title.log') }}
                                    </button>
                                    <v-btn v-if="loading" :disabled="loading" type="submit" color="grey-lighten-3"
                                        class="px-5 btn btn-secondary " style="border-radius:5px;">
                                        <v-icon>
                                            mdi-refresh mdi-spin
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-form>
                        </div>

                        <div class="bgLogo">
                            <div class="logoImg">
                                <img src="../../../../../public/images/logo_background/logo-login.png" alt="">
                            </div>
                        </div>

                    </div>
                </div>
            </v-flex>
        </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    
    data() {
        return {
      
            user: {
                user_name: this.$route.params.username ? this.$route.params.username : null,
                password: null,
                app_id: null
            },
            showPassword: false,
            password: 'Password',
            errorMessage: '',
            loading: false,
            error: false,
            rules: {
                required: [(value) => !!value || this.$t('title.required')],
                min: v => (v && 0 != v.length >= 6) || this.$t('title.limit_six_chacracter'),
                emailMatch: () => (`The email and password you entered don't match`),
            },
            valid: false,
            checker : true,
            validate: false,
        }
    },
    computed: {
        ...mapGetters("$_auth", {
            loginDialog: "getLoginDialog",
        })
    },

    methods: {

        handleClearError() {
            this.error = false;
        },
        reset() {
            this.$refs.form.reset();
        },
        async handleLogin() {
            try {
                this.loading = true;
                if (this.user.user_name == '' || this.user.password == '') {
                    this.$toastr.e(`Bắt buộc`);
                    return
                }
                this.user.user_name = this.user.user_name.toString().toLocaleLowerCase();
                this.user.password = this.user.password.toString().toLocaleLowerCase();
                this.user.app_id = process.env.VUE_APP_ID
                const response = await this.fetchLogIn(this.user);
                // console.log(response)
                if (response.data.code) {
                    this.UPDATE_EXPAND_MENU(true);
                    const { token, user } = response.data.data;
                    if (user.is_suspend) {
                        this.$toastr.e(`Your account have been blooked!`)
                    } else {
                        if (user.currency_type == 2) {
                            this.$cookies.set("local", "khmer");
                            this.$i18n.locale = "khmer";
                        } else {
                            this.$cookies.set("local", "english");
                            this.$i18n.locale = "english"
                        }
                        this.$cookies.set("sbc_token", "Bearer " + token, 0);
                        // this.$toastr.e(`${JSON.stringify(user.menu)}`)
                        // this.$cookies.set("sbc_user", user);
                        // console.log('sbc_user', `${JSON.stringify(user)}`);
                        this.$cookies.set("sbc_user", JSON.stringify(user));
                        // this.UPDATE_CHANNEL_ID(user.home_channel[0])
                        // this.UPDATE_CHANNEL_LIST(user.home_channel)
                        let allMenu = user.menu;
                        let defaultChannel = allMenu.menu1.channels[0].channel_type;
                        let menuList1 = allMenu.menu1.channels;
                        // let menuList2 = allMenu.menu2.channels;
                        // let menuList3 = allMenu.menu3.channels;
                        // let menuList4 = allMenu.menu4.channels;
                        if (allMenu.menu1.is_enable) {
                            defaultChannel = allMenu.menu1.channel_type
                            for (let i = 0; i <= allMenu.menu1.channels.length; i++) {
                                if (allMenu.menu1.channels[i].is_enable) {
                                    this.$cookies.set("sbc_channel_type", allMenu.menu1.channels[i].channel_type);
                                    break;
                                }
                            }
                        } else if (allMenu.menu2.is_enable) {
                            defaultChannel = allMenu.menu2.channel_type
                            for (let i = 0; i <= allMenu.menu2.channels.length; i++) {
                                if (allMenu.menu2.channels[i].is_enable) {
                                    this.$cookies.set("sbc_channel_type", allMenu.menu2.channels[i].channel_type);
                                    break;
                                }
                            }
                        } else if (allMenu.menu3.is_enable) {
                            defaultChannel = allMenu.menu3.channel_type
                            for (let i = 0; i <= allMenu.menu3.channels.length; i++) {
                                if (allMenu.menu3.channels[i].is_enable) {
                                    this.$cookies.set("sbc_channel_type", allMenu.menu3.channels[i].channel_type);
                                    break;
                                }
                            }
                        } else if (allMenu.menu4.is_enable) {
                            defaultChannel = allMenu.menu4.channel_type
                            for (let i = 0; i <= allMenu.menu4.channels.length; i++) {
                                if (allMenu.menu4.channels[i].is_enable) {
                                    this.$cookies.set("sbc_channel_type", allMenu.menu4.channels[i].channel_type);
                                    break;
                                }
                            }
                        }
                        this.$cookies.set("sbc_defaut_main_menu", defaultChannel);
                        this.UPDATE_CHANNEL_ID(menuList1[0].channel_type);
                        this.UPDATE_CHANNEL_LIST(user.channel_list);
                        this.UPDATE_LOGIN_DIALOG(false)
                        await this.$router.push({ path: "/cockfight" });
                        // this.myInfo();
                    }
                } else {
                    this.$toastr.e(response.data.message.descriptions)
                    this.error = true;
                    this.errorMessage = response.data.message.descriptions;
                }
            } catch (error) {
                this.$toastr.e(`${error}`);
                this.errorMessage = error;
                this.error = true;
                return error;
            } finally {
                this.loading = false;
            }

        },
        _goToRouter(data){
            this.UPDATE_LOGIN_DIALOG(false)
            this.$router.push(data);
        },
        ...mapActions("$_auth", ["fetchLogIn", "updateSelectedLoginMenu"]),
        ...mapMutations("$_auth", [
            "UPDATE_LOGIN_DIALOG"
        ]),
        ...mapMutations("$_modules", [
            "UPDATE_CHANNEL_ID",
            "UPDATE_CHANNEL_LIST",
            "UPDATE_EXPAND_MENU"
        ]),
    }
}
</script>
<style>
.v-dialog {
    box-shadow: none !important;
}
</style>