<template>
  <div class="pcoded-main-container">
    <div class="page-body" style="overflow: hidden">
      <div class="lottery-menu-nav-head">
        <MainMenu />
      </div>
      <div>
        <DesktopScreenContainer :passEnabledChannel="countEnableGame()" :passChannel="channel"
          :passChannelList="channelList" :passSelectedChannel="channelSelected" :passNewChannelList="newChannelList"
          :passCurrentBetting="currentBetting" :passResult="resultObj" :passChannelStatus="channelStatus"
          :passGetButton="getButton" :passBettingTypeMaster="bettingTypeMaster" :passWeight="weightObj"
          :passTodayReport="todayBetting" :passLoadingTodayReport="loadingTodayReport" :passUserCurrency="userCurrency"
          v-on:sentSubmitBetting="createBetting" v-on:sentRefreshTodayReport="fetchTodayBetting"
          v-on:sentRefreshCurrentReport="fetchCurrentBetting" v-on:sentSetChannel="setChannelType">
        </DesktopScreenContainer>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "../../../components/MainMenu.vue";
import "@/assets/css/casino_live/main_casino_live/screen_max_480.css";
import "@/assets/css/casino_live/main_casino_live/screen_min_481.css";
import "@/assets/css/casino_live/main_casino_live/screen_min_768.css";
import "@/assets/css/casino_live/main_casino_live/screen_min_993.css";
import "@/assets/css/casino_live/main_casino_live/screen_min_1025.css";
import "@/assets/css/casino_live/main_casino_live/screen_min_1281.css";
import "@/assets/css/casino_live/main_casino_live/screen_min_1441.css";
import "@/assets/css/casino_live/main_casino_live/screen_min_1661.css";
import "@/assets/css/casino_live/main_casino_live/screen_min_1824.css";
import "@/assets/css/casino_live/main_casino_live/casino_share_style.css";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DesktopScreenContainer from "./components/DesktopScreenComponent/DesktopScreenContainer.vue";

import MX_Socket_CasinoLive from "@/mixin/SocketGame/MX_Socket_CasinoLive.js";

export default {
  mixins: [MX_Socket_CasinoLive],
  components: {
    DesktopScreenContainer,
    MainMenu
  },
  metaInfo: {
    titleTemplate: `%s |  CASINO LIVE`
  },
  data() {
    return {
      channelList: [],
      newChannelList: [],
      channelSelected: 1,
      loading: false,
      error: false,
      bettingObj: {
        number_id: null,
        amount: null
      },
      isBetsuccess: true,
      isBetting: false,
      userCurrency: ''
    }
  },
  watch: {
    channelId: async function (newVal, oldVal) {
      if (newVal != oldVal && oldVal != 1) {
        this.UPDATE_EXPAND_MENU(true)
        this.RELOAD_PAGE();
        this._connectSocketCasinoLive();
        this._initialData();
      }
    }
  },
  computed: {
    ...mapGetters("$_casino_live", {
      channel: "getChannel",
      channelStatus: "getChannelStatus",
      resultObj: "getResult",
      bettingTypeMaster: "getBettingTypeMaster",
      todayBetting: "getTodayBetting",
      currentBetting: "getCurrentBetting",
      weightObj: "getWeight",
      getButton: "getButton",
      loadingTodayReport: "getLoadingTodayReport",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      isSound: "getIsSound",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
    resultColor() {
      return this.showResultByColor(this.resultObj)
    },
    getStatus() {
      return this.checkSattus()
    },
    getStatusColor() {
      return this.checkSattusColor()
    },
    getResultStatus() {
      return this.resultStatue()
    },
    getChannelsColor(index) {
      return this.resultChannelColor(index)
    },
  },
  async created() {
    if (!this.$cookies.get('sbc_live_channel')) {
      const menu2Channel = this.userInfo.menu.menu2.channels;

      for (let i = 0; i < menu2Channel.length; i++) {

        if (menu2Channel[i].is_enable) {
          this.channelSelected = menu2Channel[i].channel_type;
          break;
        }
      }
    } else {
      this.channelSelected = this.$cookies.get('sbc_live_channel');

    }
    this.UPDATE_CHANNEL_ID(this.channelSelected);
    this._connectSocketCasinoLive();
    this.channelSelected ? await this._initialData() : "";
  },
  methods: {
    countEnableGame() {
      let menu2Channel = this.userInfo.menu.menu2.channels;
      let enabledGame = 0;
      for (let i = 0; i < menu2Channel.length; i++) {
        if (menu2Channel[i].is_enable) enabledGame++
      }
      return enabledGame;
    },
    async _initialData() {
      await this.fetchChannel();
      await this.fetchResult();
      await this.fetchButton();
      if (
        this.channelSelected == 15 ||
        this.channelSelected == 16
      )
        await this.fetchWeight();
      await this.fetchBettingTypeMaster();
      await this.fetchCurrentBetting();
      await this.fetchTodayBetting();
      this.calculateData();
    },
    validateBeforeBetting(submitBettingData) {
      const money = submitBettingData.amount;
      const userBalance = this.userInfo.balance;
      if (this.getBettingStatus() && submitBettingData.amount > 0 && userBalance >= money && this.isBetting == false) {
        return true
      }
      else if (!this.getBettingStatus()) {
        this.bettingStatus = "Game is not open"
        this.isBetsuccess = false
        this.$toastr.e(`Game is not open`)
        return false
      }
      else if (submitBettingData.amount <= 0) {
        this.bettingStatus = "Please enter amount!"
        this.isBetsuccess = false
        this.$toastr.e(`Please enter amount!`)
        return false
      }
      else if (userBalance < money) {
        this.bettingStatus = "Not enought Balance"
        this.isBetsuccess = false
        this.$toastr.e(`Not enought Balance`)
        return false
      }
      else if (this.isBetting == true) {
        this.bettingStatus = "We processing your previous betting, please wait"
        this.isBetsuccess = false
        this.$toastr.e(`Please wait`)
        return false
      }
      else {
        this.$toastr.e(`something when wrong`)
        return false
      }
    },
    async createBetting(submitBettingData) {
      this.bettingObj = submitBettingData;
      if (this.validateBeforeBetting(submitBettingData)) {
        try {
          this.isBetting = true;
          this.LOADING(true);
          const response = await this.fetchCreateBetting(this.bettingObj);
          if (response.data.code) {
            this.$toastr.s(`Betting is success`)
            this.bettingStatus = "Betting is success"
            await this.updateBalance(response.data.data.balance)
            this.BETTING_DIALOG(false);
            this.LOADING(false);
            this.isBetting = false;
            this.CLEAR_BETTING_AMOUNT();
            this.CLEAR_SELECTED_BTN();
            this.UPDATE_BET_AMOUNT(response.data.data);
            this.UPDATE_CURRENT_BETTING(response.data.data)
            this.UPDATE_PAYOUT(response.data.data)
          } else {
            if (response.data.message.descriptions == "Channel is not open") {
              this.UPDATE_CH_STATUS(false);
            }
            this.isBetting = false;
            this.LOADING(false);
            this.$toastr.e(`${response.data.message.descriptions}`)
            this.bettingStatus = response.data.message.descriptions
          }
        } catch (error) {
          this.bettingStatus = "Betting is not success"
          this.isBetsuccess = false;
          this.LOADING(false);
          this.error = true;
          return error;
        } finally {
          this.loading = false;
          this.LOADING(false);
        }
      }
    },
    async setChannelType(channelType) {
      if ((this.channelSelected) != channelType) {
        this.channelSelected = channelType;
        this.$cookies.set("sbc_live_channel", channelType);
        this.$cookies.set("sbc_channel_type", channelType);
        this.UPDATE_CHANNEL_ID(channelType);
        this.UPDATE_IS_REPORT(true)
      }
    },

    playSound(soundName) {
      let chType = this.$cookies.get('sbc_live_channel');
      if (chType > 4 && (chType != 15 || chType != 16)) {
        if (this.isSound) {
          let audio = new Audio(`/sound/${soundName}`);
          let promise = audio.play();
          if (promise) {
            promise.catch(function () {
            });
          }
        }
      }
    },
    playAudioTing() {
      let audio = new Audio("/sound/ting1s.mp3");
      let promise = audio.play();
      if (promise) {
        //Older browsers may not return a promise, according to the MDN website
        promise.catch(function () { });
      }
    },
    showResultByColor(result) {
      if (result.results[0]) {
        if (result.results[0].name === "1" || result.results[0].name === "4") {
          return "red"
        } else {
          return "black"
        }
      } else {
        return "primary"
      }
    },
    getBettingStatus() {
      if (this.channel.is_open && this.userInfo.enable && this.channel.enable) {
        return true
      }
      return false
    },
    checkSattus() {
      if (this.channel.is_open == null) {
        return "Not Open"
      } else {
        if (this.channel.is_open) {
          return "Open"
        }
        return "Close"
      }
    },
    resultStatue() {
      if (this.channel.is_open == null && this.resultObj.results[0]) {
        return true
      } else {
        return false
      }
    },
    resultChannelColor(index) {
      if (this.channelStatus) {
        for (var i = 0; i < this.channelStatus.length; i++) {
          if (this.channelStatus[i].channel_type == index) {
            if (this.channelStatus[i].status == null) {
              return "orange"
            } else {
              if (this.channelStatus[i].status) {
                return "green"
              }
              return "red"
            }
          }
        }
      }
    },
    checkSattusColor() {
      if (this.channel.is_open == null) {
        return "text-warning"
      } else {
        if (this.channel.is_open) {
          return "text-success"
        }
        return "text-danger"
      }
    },
    async calculateData() {
      // await this.fetchChannel();
      // console.log(`calculateData`);
      this.RESET_COUNTDOWN();
      if (this.channelSelected == 6 || this.channelSelected == 7) {
        let getData = this.channel.card_list;
        this.UPDATE_FIST_LOAD_BACARAT_CARD(getData);
      }
      if (this.channelSelected == 8 || this.channelSelected == 9) {
        let getData = this.channel.card_list;
        this.UPDATE_FIST_LOAD_TIGER_DRAGON_CARD(getData)
      }
    },
    ...mapMutations("$_casino_live", [
      "CLEAR_SELECTED_BTN",
      "UPDATE_CURRENT_BETTING",
      'UPDATE_CHANNEL',
      "UPDATE_AMOUNT",
      "UPDATE_PAYOUT",
      "RELOAD_PAGE",
      "CLEAR_CACH",
      "UPDATE_BET_AMOUNT",
      "UPDATE_CH_STATUS",
      "BETTING_DIALOG",
      "UPDATE_DRAGON_CARD",
      "UPDATE_FIST_LOAD_BACARAT_CARD",
      "UPDATE_FIST_LOAD_TIGER_DRAGON_CARD",
      "UPDATE_IS_REPORT",
      "RESET_COUNTDOWN",
      "LOADING"
    ]),
    ...mapActions("$_modules", ["updateBalance"]),
    ...mapActions("$_casino_live", [
      "fetchWeight",
      "fetchResult",
      "fetchChannel",
      "fetchBettingTypeMaster",
      "fetchTodayBetting",
      "fetchCurrentBetting",
      "fetchCreateBetting",
      "updateCurrentBetting",
      "updateChannel",
      "updateResult",
      "fetchButton"
    ]),
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_USER_INFO",
      "UPDATE_EXPAND_MENU",
      "CLEAR_BETTING_AMOUNT",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this._disconnectSocketCasinoLive();
    this.CLEAR_CACH();
    next();
  }
};

</script>
<style>
.lcir-yellow {
  background-color: #ffba41 !important;
}

.lcir-red {
  background-color: #f34141 !important;
}

.lcir-blue {
  background-color: #167df1 !important;
}

.lcir-green {
  background-color: #376b07 !important;
}

.lcir-cancel {
  background-color: #6c757d !important;
}
</style>