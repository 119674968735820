<template>
    <div class="lotto-nav-head w-100">
        <v-row no-gutters >
          <v-col cols="6">
            <div class="head-right-right" style="width: 100%; float:  left; padding-top:2.5px">
              <ul style="width: 100%">
                <li class="px-2 "  style="font-size:.95rem; text-align:left">
                  {{ moment(getLastOneResult.date).format("YYYY-MM-DD") }}
                  <span class="item-right">#{{ getLastOneResult.no }}</span>
                </li>
                <li class="px-2" style="font-size:.95rem;  text-align:left">
                  {{ $t("title.total") }}
                  <span class="item-right">{{
                    getLastOneResult.total_random
                  }}</span>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col cols="6" v-if="passClientTime && passClientTime.time" >
            <div class="head-right-last" style="background-color: rgba(159, 203, 254, 0.3)">
              <!-- <div class="box-number"></div> -->
              <span class="span-number span-number-on-run"
                :style="passClientTime.time <= 10 ? 'color:darkred!important; text-shadow:1px 1px gold' : ''">{{
                  passClientTime
                  && passClientTime.time ? passClientTime.time : 0 }}</span>
            </div>
          </v-col>
          <v-col cols="3" v-if="passClientRandom && passClientTime.time <= 0" class="l-logo-result-random-result">
            <div :class="passRandomNumber ? '' : 'focus-one-result'">
              {{
                passRandomNumber
                ? passRandomNumber
                : passClientRandom.lotto
                  ? passClientRandom.lotto.slice(-1)[0]
                  : 0
              }}
            </div>
          </v-col>
          <v-col cols="3" v-if="!passClientTime.time && passClientRandom && passClientRandom.total
                ">
            <div class="head-right-last">
              <div class="box-number">
                {{
                  passClientRandom.lotto && passClientRandom.lotto.length
                  ? passClientRandom.lotto.length
                  : 0
                }}
              </div>
              <span class="span-number">{{ passClientRandom.total }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  // DEFINE local VARIABLE
  props: ["passClientTime", "passClientRandom", 'passRandomNumber', 'passLastTwentyResults'],
  data() {
    return {

    };
  },

  computed: {
    ...mapGetters("$_machine", {
      getLastOneResult: "getLastOneResult",
    }),
  },
  methods: {
    moment,
  },
};
</script>
