<template>
  <div>
      <CockFightTableResultComponent
        v-if="_selectedGame(channelId) == 'cock'"
        :rows="6" :cols="25" />
      <KlakloukTableResult
        v-if="_selectedGame(channelId) == 'klaklouk'"
        :rows="6" :cols="25" />
      <Han2TableResult
        v-if="_selectedGame(channelId) == 'han2'"
        :rows="6" :cols="25" />
      <Han3TableResult
        v-if="_selectedGame(channelId) == 'han3'"
        :rows="6" :cols="25" />
      <TigerDragonResultComponent 
        v-if="_selectedGame(channelId) == 'tiger-dragon'"
        :rows="6" :cols="25"
      />
  </div>
</template>

<script>
import CockFightTableResultComponent from "./_TableResultComponent/CockFightTableResultComponent.vue";
import KlakloukTableResult from "./_TableResultComponent/KlakloukTableResult.vue";
import Han2TableResult from "./_TableResultComponent/Han2TableResult.vue";
import Han3TableResult from "./_TableResultComponent/Han3TableResult.vue";
import TigerDragonResultComponent from "./_TableResultComponent/TigerDragonResultComponent.vue";
import Mixin from "../../../../mixin/MX_ShareFunctionCollection.js";

import { mapGetters } from "vuex";
export default {
  components: {Han2TableResult, Han3TableResult, CockFightTableResultComponent, KlakloukTableResult, TigerDragonResultComponent },
  data() {
    return {
      mixins: [Mixin],
    };
  },
  computed: {
    ...mapGetters("$_modules", {
            channelId: "getChannelId",
        }),
  },
}
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height:6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(40, 53, 89, .8);
  border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(78, 106, 181, .2)
}

@keyframes rotate {

  from {
      transform: rotate(0deg)
  }

  to {
      transform: rotate(360deg)
  }

}


.refresh-start {

  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;

}

.text-meron {
  color: #f93c3c !important
}

.txt-white {
  color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
  color: #FFC008
}

.txt-grey {
  color: #6C757D
}
</style>