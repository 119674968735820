<template>
  <v-col cols="12" class="keno-content-eighty-result pa-0">
    <v-row no-gutters>
      <v-col cols="6" class="keno-logo-info">
        <p>New lotto</p>
      </v-col>
      <v-col cols="6" class="game-info">
        <p class="keno-info" v-if="getLastOneResult && getLastOneResult.no">#{{ getLastOneResult.no }} </p>
        <p class="keno-info" v-else>l<v-icon color="#e9de37" class="pl-1">mdi-refresh mdi-spin</v-icon>ading</p>
        <v-row class="mx-0 justify-content-between keno-info-padding">
          <p class="keno-info">{{ $t('title.total') }}</p>
          <p class="keno-info" v-if="getLastOneResult && getLastOneResult.total_random">{{
            getLastOneResult.total_random }}</p>
          <p class="keno-info" v-else>l<v-icon color="#e9de37" class="pl-1">mdi-refresh mdi-spin</v-icon>ading</p>
        </v-row>
      </v-col>
      <div class="w-100 ma-0 pa-0 keno-xs-game-info">
        <v-row class="ma-0">
          <div class="keno-time-info-xs">
            <p>{{ getCurrentGame.date }}</p>
            <p>#{{ getCurrentGame.no }}</p>
          </div>
          <div class="keno-loading-sync-xs bg-success">
            <img :src="require('~public_image/a_each_game/keno/spinner.gif')" alt="">
          </div>
          <div class="keno-countdown-time-container-xs">
            <div class="keno-countdown-total-ball-number">
              <p>{{ passClientRandom && passClientRandom.keno ? passClientRandom.keno.length : 0 }}</p>
            </div>
            <div class="keno-countdown-total-result">
              <div class="keno-countdown-total-ball-number">
                <p>{{ passClientRandom && passClientRandom.keno ? passClientRandom.keno.length : 0 }}</p>
              </div>
              <div class="keno-countdown-ball-number">
                <p v-if="getClientTime && getClientTime.time > 0" class="text-center"
                  :class="!getClientTime.is_open_betting ? 'keno-time-close' : 'keno-time-open'">{{
                    getClientTime.time }}</p>
                <div v-else>
                  <img :src="require('~public_image/a_each_game/keno/ball.png')" alt="">
                  <p>{{
                    passRandomNumber && passClientRandom != null && passClientRandom.keno
                    ? passRandomNumber
                    : passClientRandom && passClientRandom.keno && passClientRandom.keno.length > 0
                      ? passClientRandom.keno.slice(-1)[0]
                      : 0
                  }}</p>
                </div>
              </div>

            </div>
          </div>
          <div class="keno-total-all-ball-xs bg-danger ">
            <div class="keno-countdown-total-random-number">
              <p>{{ passClientRandom && passClientRandom.keno ? passClientRandom.total : 0 }}</p>
            </div>
          </div>
        </v-row>
        <v-row class="ma-0 pa-0 keno-xs-previous-game-info">
            <p>{{ $t('title.total') }} :</p>
            <p class="text-white">{{ getLastOneResult.no }}</p>
            <p>{{ $t('title.previous_game') }} :</p>
            <p class="text-white">{{ getLastOneResult.total_random }}</p>
        </v-row>
      </div>

      <v-col cols="12" class="keno-grid-container">
        <div v-for="number in 80" :key="number" class="keno-number ke-number">
          <div v-for="(result, i ) in passClientRandom.keno" :key="i" :class="{
            'keno-blink':
              passClientRandom.keno[
              passClientRandom.keno.length - 1
              ] == number,
            'keno-number-active': result == number,
          }">
            {{ result == number ? number : "" }}
          </div>
          <span style="color: #212427!important">{{ number ? number : 0 }}</span>
        </div>
      </v-col>

    </v-row>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default ({
  props: [
    "passClientRandom", "passLastTwentyResults", 'passRandomNumber'
  ],
  computed: {
    ...mapGetters("$_keno", {
      lastTwentyResults: "getLastTwentyResult",
      getCurrentGame: 'getCurrentGame',
      getLastOneResult: 'getLastOneResult',
      getClientTime:'getClientTime'
    }),
  },
  methods: {
    formatDateId(data) {
      return moment(data.date).format("YYYYMMDD") + "#" + data.no
    }
  }

})
</script>
  