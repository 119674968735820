export default {
	user_name: 'Tên Đăng Nhập',
	password: 'Mật Khẩu',
	login: 'ចូលទៅគណនី',
	contact_us: 'ទាក់ទង​មក​ពួក​យើង',
	//validation
	invalid: {
		username: 'ការបញ្ចូលឈ្មោះអ្នកប្រើមិនត្រឹមត្រូវទេ!'
	},
	//required
	required: {
		username: 'សូមបញ្ចូលឈ្មោះអ្នកប្រើរបស់អ្នក!',
		password: 'សូមបញ្ចូលពាក្យសំងាត់របស់អ្នក!'
	},
	//error
	error: {
		login_failed: 'ឈ្មោះអ្នកប្រើឬពាក្យសម្ងាត់របស់អ្នកមិនមាននៅក្នុងប្រព័ន្ធរបស់យើងទេ។'
	}
};
