export default {
    methods: {
      _disconnectSocketKeno(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
      },
      _connectSocketKeno(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
        this.$connectSocket();
        this.listenerSocketKeno()
      },
      listenerSocketKeno() {
        this.$socket.on("client-random", (data) => {
          if (data && data.total != null) {
            let interval;
            const _this = this;
            interval = setInterval(function () {
              let result = parseInt(Math.random() * (80 - 1) + 1);
              _this.randomNumber = result;
            }, 50);
            setTimeout(() => {
              clearInterval(interval);
              _this.randomNumber = null;
              _this.clientRandom = data;
              // _this._playSoundBang();
              // if(this.$confetti) this.$confetti.stop;
            }, 800);
          }
        });
        // socket get time,  is betting, is coldown
        this.$socket.on("client-time", (data) => {
          if (data) {
            // define client time to display count down time at play zone
            this.clientTime = data;
            this.UPDATE_CLIENT_TIME(data);
            // play audio sound
            if (data.time == 59) {
              this._playSoundBetDown();
            }
            // if (data.time < 11 && data.time != 0) this._playSoundTing();
            // if (data.time == 0) this._playSoundGoodLuck();
          }
        });
        // get new game
        this.$socket.on("client-new-game", (data) => {
          if (data) {
            this.clientRandom = [];
            this.bonus = [];
            // this._clearResult();
            this._initialData();
          }
        });
        // get game result and bonus
        this.$socket.on("client-result", (data) => {
          if (data) {
            this.clientResult = data;
          }
        });
        this.$socket.on("client-bonus", (data) => {
          if (data) {
            this.bonus = data;
            if (this.bonus.is_bonus) {
              // this.$confetti.start();
              this._playSoundCongratulation();
            }
          }
        });
      },
    }
}