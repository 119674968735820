const UPDATE_DARK_MODE = (state, data) => {
    state.darkMode = data;
};


const UPDATE_BALANCE = (state, balance) => {
    state.userInfo.balance = balance;
};
const UPDATE_USER_INFO = (state, data) => {
    state.userInfo = data;
    state.userInfo.currencySymbol = data.currency_type == 1 ? "$" : data.currency_type == 2 ? "៛" : data.currency_type == 3 ? "฿" : "₫";
};
const UPDATE_PROMOTEXT = (state, data) => {
    state.promotText = data;
};
const UPDATE_CHANNEL_ID = (state, data) => {
    state.channelId = data;
};
const LOADING = (state, payload) => {
    state.loading = payload;
};
const DIALOG = (state, payload) => {
    state.dialog = payload;
};
const EDIT_DIALOG = (state, payload) => {
    state.editDialog = payload;
};
const UPDATE_CHANNEL_LIST = (state, data) => {
    state.channelList = data;
};
const UPDATE_NOTI_DATA = (state, data) => {
    state.notiData = data;
};
const UPDATE_SOUND = (state, data) => {
    state.isSoundOn = data
};
const UPDATE_AUDIO = (state, data) => {
    state.isAudioPlay = data
};
const UPDATE_NOTI_COUNT = (state, data) => {
    state.notiCount = data
};
const UPDATE_NOTI_DETAIL = (state, data) => {
    state.notiDetail = data
};
const UPDATE_AMOUNT_NOTI_DETAIL = (state, data) => {
    state.notiDetail.amount = data
};
const UPDATE_AGENT_ACCOUNT_LIST = (state, data) => {
    state.agentAccountList = data
};
const UPDATE_NOTI_LIST = (state, data) => {
    state.notiList = data
};
const UPDATE_USER_BANK = (state, data) => {
    state.userBank = data
};
const UPDATE_DEPOSIT = (state, data) => {
    state.deposit = data
};
const UPDATE_WITHDRAW = (state, data) => {
    state.withdraw = data
};
const UPDATE_NOTI_STATUS = (state, data) => {
    state.withdraw = data
};
const UPDATE_BETTING_AMOUNT = (state, data) => {
    let money = parseInt(data)
    state.bettingAmount += parseInt(money)
    // state.bettingAmount = money
};
const UPDATE_LIVE_BETTING_AMOUNT = (state, data) => {
    let money = parseInt(data)
    // state.bettingAmount += parseInt(money)
    state.bettingAmount = money
};
const INPUT_BETTING_AMOUNT = (state, data) => {
    state.bettingAmount = parseInt(data)
};
const CLEAR_BETTING_AMOUNT = (state) => {
    state.bettingAmount = 0
};
const SELECT_GAME_ID = (state, data) => {
    state.slotGame = data
};
const UPDATE_MAIN_MENU = (state, data) => {
    state.mainMenuId = data;
};

const UPDATE_BETTING_KLAKLOUK_AMOUNT = (state, data) => {
    let x_key = data[0]
    let x_money = parseInt(data[1])
    let x_id = data[2]
    switch (x_key) {
        case "ឃ្លោក":
            state.bettingKlakloukAmount.gourd.amout = x_money
            state.bettingKlakloukAmount.gourd.game_id = x_id
            state.bettingKlakloukAmount.gourd.display = true
            state.bettingKlakloukAmount.status = { key: "gourd", name: "ឃ្លោក" }
            break
        case "មាន់":
            // state.bettingKlakloukAmount.chicken += x_money
            state.bettingKlakloukAmount.chicken.amout = x_money
            state.bettingKlakloukAmount.chicken.game_id = x_id
            state.bettingKlakloukAmount.chicken.display = true
            state.bettingKlakloukAmount.status = { key: "chicken", name: "មាន់" }
            break
        case "បង្កង":
            //state.bettingKlakloukAmount.lobster += x_money
            state.bettingKlakloukAmount.lobster.amout = x_money
            state.bettingKlakloukAmount.lobster.game_id = x_id
            state.bettingKlakloukAmount.lobster.display = true
            state.bettingKlakloukAmount.status = { key: "lobster", name: "បង្កង" }
            break
        case "ក្ដាម":
            //state.bettingKlakloukAmount.crab += x_money
            state.bettingKlakloukAmount.crab.amout = x_money
            state.bettingKlakloukAmount.crab.game_id = x_id
            state.bettingKlakloukAmount.crab.display = true
            state.bettingKlakloukAmount.status = { key: "crab", name: "ក្ដាម" }
            break
        case "ត្រី":
            //state.bettingKlakloukAmount.fish += x_money
            state.bettingKlakloukAmount.fish.amout = x_money
            state.bettingKlakloukAmount.fish.game_id = x_id
            state.bettingKlakloukAmount.fish.display = true
            state.bettingKlakloukAmount.status = { key: "fish", name: "ត្រី" }
            break
        case "ខ្លា":
            //state.bettingKlakloukAmount.tiger += x_money
            state.bettingKlakloukAmount.tiger.amout = x_money
            state.bettingKlakloukAmount.tiger.game_id = x_id
            state.bettingKlakloukAmount.tiger.display = true
            state.bettingKlakloukAmount.status = { key: "tiger", name: "ខ្លា" }
            break
        default:
            return 0
    }
};

const INPUT_BETTING_KLAKLOUK_AMOUNT = (state, data) => {
    state.bettingKlakloukAmount = data
};
const CLEAR_BETTING_KLAKLOUK_AMOUNT = (state) => {
    state.bettingKlakloukAmount = { gourd: { game_id: '', amout: 0, display: false }, chicken: { game_id: '', amout: 0, display: false }, lobster: { game_id: '', amout: 0, display: false }, crab: { game_id: '', amout: 0, display: false }, fish: { game_id: '', amout: 0, display: false }, tiger: { game_id: '', amout: 0, display: false }, status: { key: "", name: "" } }
};


const INPUT_BETTING_SICBO_AMOUNT = (state, data) => {
    state.bettingSicboAmount = data
};
const CLEAR_BETTING_SICBO_AMOUNT = (state) => {
    state.bettingSicboAmount = { bettingname: '', amount: 0, bettingid: '', status: false }
};

const UPDATE_BETTING_SICBO_AMOUNT = (state, data) => {
    let x_name = data[0]
    let x_money = parseInt(data[1])
    let x_id = data[2]
    state.bettingSicboAmount.bettingname = x_name
    state.bettingSicboAmount.amount = x_money
    state.bettingSicboAmount.bettingid = x_id
    if (x_id != "") {
        state.bettingSicboAmount.status = true
    } else {
        state.bettingSicboAmount.status = false
    }
};

const INPUT_BETTING_DD_AMOUNT = (state, data) => {
    state.bettingDdAmount = data
};
const CLEAR_BETTING_DD_AMOUNT = (state) => {
    state.bettingDdAmount = { bettingname: '', amount: 0, bettingid: '', status: false }
};

const UPDATE_BETTING_DD_AMOUNT = (state, data) => {
    let x_name = data[0]
    let x_money = parseInt(data[1])
    let x_id = data[2]
    state.bettingDdAmount.bettingname = x_name
    state.bettingDdAmount.amount = x_money
    state.bettingDdAmount.bettingid = x_id
    if (x_id != "") {
        state.bettingDdAmount.status = true
    } else {
        state.bettingDdAmount.status = false
    }
};
const UPDATE_UPDATE_BETTING_AMOUNT= (state, data) => {
    let money = parseInt(data)
    state.bettingAmount += parseInt(money)
};
const UPDATE_INPUT_BETTING_AMOUNT= (state, data) => {
    state.bettingAmount = parseInt(data)
};

const UPDATE_EXPAND_MENU= (state, data) => {
    state.expandMenu = !data
};
const UPDATE_EXPAND_MENU_SMALL= (state, data) => {
    state.expandMenuSmall = !data
};
// *********** MENU ************
const SUB_MENU_COCK = (state, data) => {
    state.subMenuCock = data;
};
const SUB_MENU_CASINO = (state, data) => {
    state.subMenuCasino = data;
};
const SUB_MENU_COCK_X_CASINO = (state, data) => {
    state.subMenuCockXCasino = data;
};
const SUB_MENU_LOTTERY = (state, data) => {
    state.subMenuLottery = data;
};
const SUB_MENU_LOTTERY_MENU_LOTTO = (state, data) => {
    state.subMenuLotteryMenuLotto = data;
};
const SUB_MENU_LOTTERY_MENU_YUKI = (state, data) => {
    state.subMenuLotteryMenuYuki = data;
};
const SUB_MENU_LOTTERY_MENU_KENO = (state, data) => {
    state.subMenuLotteryMenuKeno = data;
};
const SUB_MENU_LOTTERY_MENU_LOTTERY2D = (state, data) => {
    state.subMenuLotteryMenuLottery2d = data;
};
const SUB_MENU_LOTTERY_MENU_LOTTERY3D = (state, data) => {
    state.subMenuLotteryMenuLottery3d = data;
};
const SUB_MENU_LOTTERY_MENU_LOTTERYVN = (state, data) => {
    state.subMenuLotteryMenuLotteryVn= data;
};
const SUB_MENU_MACHINE_GAME = (state, data) => {
    state.subMenuMachineGame = data;
};
const SUB_MENU_MACHINE_GAME_MENU_ALL = (state, data) => {
    state.subMenuMachineGameMenuAll = data;
};

const SUB_MENU_MACHINE_GAME_MENU_BACCARAT = (state, data) => {
    state.subMenuMachineGameMenuBaccarat = data;
};
const SUB_MENU_MACHINE_GAME_MENU_DODEN = (state, data) => {
    state.subMenuMachineGameMenuDoden = data;
};

const SUB_MENU_MACHINE_GAME_MENU_TAIXIU = (state, data) => {
    state.subMenuMachineGameMenuTaiXiu = data;
};

const SUB_MENU_MACHINE_GAME_MENU_DRAGONTIGER = (state, data) => {
    state.subMenuMachineGameMenuDragonTiger = data;
};
const SUB_MENU_MACHINE_GAME_MENU_SICBO = (state, data) => {
    state.subMenuMachineGameMenuSicBo = data;
};

const SUB_MENU_MACHINE_GAME_MENU_KLAKLOUK = (state, data) => {
    state.subMenuMachineGameMenuKlaKlouk = data;
};




export default {
    SUB_MENU_LOTTERY_MENU_LOTTERYVN,
    SUB_MENU_MACHINE_GAME_MENU_DRAGONTIGER,
    SUB_MENU_MACHINE_GAME_MENU_TAIXIU,
    SUB_MENU_MACHINE_GAME_MENU_DODEN,
    SUB_MENU_MACHINE_GAME_MENU_KLAKLOUK,
    SUB_MENU_MACHINE_GAME_MENU_SICBO,
    SUB_MENU_MACHINE_GAME_MENU_BACCARAT,
    SUB_MENU_MACHINE_GAME_MENU_ALL,
    SUB_MENU_MACHINE_GAME,
    SUB_MENU_LOTTERY_MENU_KENO,
    SUB_MENU_LOTTERY_MENU_LOTTERY2D,
    SUB_MENU_LOTTERY_MENU_LOTTERY3D,
    SUB_MENU_LOTTERY_MENU_YUKI,
    SUB_MENU_LOTTERY_MENU_LOTTO,
    UPDATE_PROMOTEXT,
    UPDATE_EXPAND_MENU_SMALL,
    UPDATE_EXPAND_MENU,
    UPDATE_INPUT_BETTING_AMOUNT,
    UPDATE_UPDATE_BETTING_AMOUNT,
    UPDATE_LIVE_BETTING_AMOUNT,
    SELECT_GAME_ID,
    CLEAR_BETTING_AMOUNT,
    INPUT_BETTING_AMOUNT,
    UPDATE_BETTING_AMOUNT,
    UPDATE_BETTING_KLAKLOUK_AMOUNT,
    INPUT_BETTING_KLAKLOUK_AMOUNT,
    CLEAR_BETTING_KLAKLOUK_AMOUNT,
    UPDATE_BETTING_SICBO_AMOUNT,
    INPUT_BETTING_SICBO_AMOUNT,
    CLEAR_BETTING_SICBO_AMOUNT,
    UPDATE_BETTING_DD_AMOUNT,
    INPUT_BETTING_DD_AMOUNT,
    CLEAR_BETTING_DD_AMOUNT,
    UPDATE_DARK_MODE,
    UPDATE_BALANCE,
    UPDATE_USER_INFO,
    UPDATE_CHANNEL_ID,
    UPDATE_CHANNEL_LIST,
    UPDATE_SOUND,
    UPDATE_AUDIO,
    UPDATE_NOTI_LIST,
    UPDATE_NOTI_COUNT,
    UPDATE_USER_BANK,
    UPDATE_AGENT_ACCOUNT_LIST,
    UPDATE_DEPOSIT,
    UPDATE_WITHDRAW,
    UPDATE_NOTI_STATUS,
    LOADING,
    UPDATE_NOTI_DETAIL,
    DIALOG,
    UPDATE_NOTI_DATA,
    EDIT_DIALOG,
    UPDATE_AMOUNT_NOTI_DETAIL,
    UPDATE_MAIN_MENU,
    SUB_MENU_COCK,
    SUB_MENU_CASINO,
    SUB_MENU_COCK_X_CASINO,
    SUB_MENU_LOTTERY
};