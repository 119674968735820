<template>
  <v-flex class="fluid" xs12 md12>
    <div class="mb-0 event-bg px-1 pt-2" v-if="channel.is_maintain">
        <marquee class="event-title m-0"><span>{{ channel.maintain_desc }}</span></marquee>
    </div>
    <div id="video-container" class="video-container" :class="channel.is_stop_video ? 'black-screen' : ''">
      <div v-if="channel.is_stop_video" style="background-color:#000000"></div>
      <div v-else>
       <iframe :src="urlToken" frameborder="0" class="iframe-vid" width="100%" height="100%" allowfullscreen >
       </iframe>
      </div>
    </div>
  </v-flex>
</template>

<script>

import { mapActions, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      urlToken: ""
    };
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
    }),
    ...mapState("$_dashboard", {
      vdolinks: "vdolinks",
    })
  },
  watch: {
    vdolinks: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.getVideoToken()
      }
    },
  },
  methods: {
    async getVideoToken() {
      try {
        const response = await this.fetchVideoToken();
        if (response.data.code) {
          // if (response.data.data.token && response.data.data.playOrder) {
          //   this.urlToken = `${this.getUrl()}&playOrder=${response.data.data.playOrder}&token=${response.data.data.token}`;
          // } else if (response.data.data.token && !response.data.data.playOrder) {
            this.urlToken = `${this.getUrl()}&token=${response.data.data.token}`;
          // } else if (!response.data.data.token && response.data.data.playOrder) {
          //   this.urlToken = `${this.getUrl()}&playOrder=${response.data.data.playOrder}`;
          // } else {
          //   this.urlToken = this.getUrl();
          // }
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
        }
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    getUrl() {
      if (
        this.vdolinks &&
        (this.vdolinks[0] || this.vdolinks[1])
      ) {
        if (this.vdolinks[0]) {
          return this.urlToken = this.vdolinks[0];
        } else {
          return this.urlToken = this.vdolinks[1];
        }
      }
    },
    handler: function (e) {
      e.preventDefault();
    },
    ...mapActions("$_dashboard", [
      "fetchVideoToken",
    ]),
  },
};
</script>


<style scoped>
.black-screen {
  background-color: #212427;
}


.video-container {
  padding-bottom: 56%;
  margin-top: 0px;
  position: relative;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
  border: #566573 1px solid;
}

.iframe-vid {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}
.event-bg{
  border-top: 1px solid gray;
  background: #012c98;
  padding:0;
  width:100%;

}
.event-title{
  color:#ec4a58!important;
}
</style>
