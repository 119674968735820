<template>
  <div class="game-machine-component-right">
      <CockFightButton :passBetType="passBetType " :passChannelType="channelType" v-if="passBetType && passBetType.length > 0 && channelType == 1 || channelType == 2 || channelType == 3
        || channelType == 4 || channelType == 15 || channelType == 16" />
    

    <KlakloukButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 5" />
    <BaccaratButton :passBetType="passBetType" :passChannelType="channelType"
      v-if="channelType == 6 || channelType == 7" />
    <BaccaratThreeButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 18 && passBetType && passBetType.length > 0 " />
    <DragonTigerButton :passBetType="passBetType" :passChannelType="channelType"
      v-if="channelType == 8 || channelType == 9 || channelType == 19" />
    <LottoButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 10" />
    <TaisevButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 14" />
    <YukiButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 11" />
    <ApongButton :passBetType="passBetType" :passChannelType="channelType"
      v-if="channelType == 12 || channelType == 17" />
    <FantanButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 13" />
    <HanButton :passBetType="passBetType" :passChannelType="channelType"
      v-if="channelType == 20" />
      <Han3Button :passBetType="passBetType" :passChannelType="channelType"
      v-if=" channelType == 21" />
    <div class="announce-result" v-if="_checkIfAnnounceResult(passChannelInfo)">
      <marquee behavior="" direction="" class="announce-result-run-text">{{ $t('title.waitingResult') }}</marquee>
    </div>
  </div>
</template>
<script>
import CockFightButton from "./CockFightButton.vue";
import LottoButton from "./LottoButton.vue";
import KlakloukButton from "./KlakloukButton.vue";
import BaccaratButton from "./BaccaratButton.vue";
import DragonTigerButton from "./DragonTigerButton.vue";
import TaisevButton from "./TaisevButton.vue";
import YukiButton from "./YukiButton.vue";
import ApongButton from "./ApongButton.vue";
import FantanButton from "./FantanButton.vue";
import HanButton from "./HanButton.vue";
import Han3Button from "./Han3Button.vue";
import BaccaratThreeButton from "./BaccaratThreeButton.vue";


export default {
  components: {
    Han3Button,
    CockFightButton,
    KlakloukButton,
    BaccaratButton,
    DragonTigerButton,
    LottoButton,
    TaisevButton,
    YukiButton,
    ApongButton,
    FantanButton,
    HanButton,
    BaccaratThreeButton
  },
  props: ['passGame', 'passBetType', 'channelType', 'passTime', 'passIndex', 'passChannelInfo'],
  methods: {
    _checkIfAnnounceResult(passChannelInfo) {
      if (!passChannelInfo || (passChannelInfo && (passChannelInfo.is_open == false || passChannelInfo.is_open == null))) return true
      else return false
    }
  }
}
</script>
