<template>
  <div>
    <v-col col-12 class="w-100 pt-0 pr-0">
      <div class="g-summary">
        <div class="sides-wrapper gp-panel-bg rounded">
            <div class="d-flex sides px-2">
                <div v-if="walaButton" class="side-section meron-section pb-1 side-1"
                    @click="_selectButton(walaButton._id)">
                    <div class=" pt-7 mb-0 "><span style="font-weight:600">{{
                        formatMoney(currentBetting.dragon_amount)
                    }}</span>
                        <div class="odds">{{ walaButton?' '+ walaButton.payout_display: 'x.xx'}}</div>
                        <div class="side-title pt-1">
                            <div class="truncate-meron font-weight-bold">{{walaButton? $t('title.'+walaButton.name) :"" }}</div>
                        </div>
                    </div>
                </div>
                <div class="side-section-tie tie-section  mx-2 side-1"
                    :class="selectedBtnClassTie ? 'on-active-btn' : ''" 
                    @click="_selectButton(tieButton._id)"
                    v-if="meronButton">
                    <div class="pt-7 mb-0 "><span style="font-weight:600">{{ 
                      formatMoney(currentBetting.tie_amount)
                    }}</span>

                        <div class="odds font-weight-bold">{{ tieButton?`${tieButton.payout_display}`: 'x.xx' }}</div>
                        <div class="side-title pt-1">
                            <div class="truncate-tie font-weight-bold">{{$t('title.tie')}}</div>
                        </div>
                    </div>
                </div>
                <div class="side-section wala-section pb-1 side-1"
                    :class="selectedBtnClassMeron ? 'on-active-btn' : ''" 
                    v-if="meronButton"
                    @click="_selectButton(meronButton._id)">
                    <div class="pt-7 mb-0"><span style="font-weight:600">{{
                      formatMoney(currentBetting.tiger_amount)
                    }}</span>
                        <div class="odds">{{ meronButton? ' '+ meronButton.payout_display: 'x.xx'}}</div>
                        <div class="side-title pt-1">
                            <div class="truncate-wala font-weight-bold">{{meronButton? $t('title.'+meronButton.name) :""}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Mixin from "../../../../../../../mixin/MX_ShareFunctionCollection.js";

export default {
  data() {
    return {
      mixins: [Mixin],
      bettingMoney: 0,
      selectedButton: {},
      activeItem: null,
      selectedBtnClassMeron: false,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedCoin: false,
    };
  },
  
  computed: {
      meronButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[2]
            else return {}
        },
        
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
      channelStatus: "getChannelStatus",
      bettingTypeMaster: "getBettingTypeMaster",
      currentBetting: "getCurrentBetting",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },
  methods: {
    _selectButton(selectedButtonId) {
      let submitBettingData = {
        type_id: selectedButtonId,
        amount: this.getBettingAmount,
      };
      this.$emit("sentSubmitBetting", submitBettingData);
    },
    ...mapActions("$_dashboard", ["fetchCreateBetting"]),
    ...mapMutations("$_modules", ["CLEAR_BETTING_AMOUNT"]),
  },
};
</script>

<style scoped>
    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 200px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .betting-coin-meron {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-wala {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .betting-coin-meron {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-wala {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -40px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 7px;
        left: -8px;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #17202a;
        min-height: 200px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #17202a;
    }

.open-betting-countdown {
    background-color: #def3f6;
    font-size: 22px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}
.g-summary {
  position: relative;
  background-color: #0b1625
}
.g-summary {
  padding: .3rem .6rem;
  border-radius: .25rem 0 0 0
}
.truncate {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}
.truncate-meron{
  color:rgb(245, 177, 177);
  font-size:1.7rem;
  font-weight: 700;
  text-shadow: 3px 0px 7px rgba(245, 243, 230, 0.1), -3px 0px 7px rgba(252, 246, 229, 0.1), 0px 4px 7px rgba(221, 216, 199, 0.1);
}
.truncate-tie{
  color:white;
  font-size:1.7rem;
  font-weight: 700;
  text-shadow: 3px 0px 7px rgba(245, 243, 230, 0.1), -3px 0px 7px rgba(252, 246, 229, 0.1), 0px 4px 7px rgba(221, 216, 199, 0.1);
}
.truncate-wala{
  color:rgb(55, 145, 219);
  font-size:1.7rem;
  font-weight: 700;
  text-shadow: 3px 0px 7px rgba(245, 243, 230, 0.1), -3px 0px 7px rgba(252, 246, 229, 0.1), 0px 4px 7px rgba(221, 216, 199, 0.1);
}
.odds {
  font-size: 2rem;
  color:white;
  font-weight:900;
}
.meron-section{
  color: #fff !important;
  background-color: #c41b1b80 !important;
  font-size:1.7rem;
  border-color: transparent !important;
  border-radius: 10px;
  text-transform: uppercase;
}
.wala-section{
  color: #fff !important;
  background-color: #1b1fee90 !important;
  font-size:1.7rem;
  border-color: transparent !important;
  border-radius: 10px;
  text-transform: uppercase;
}
.tie-section{
  color: #fff !important;
  background-color: #205c09 !important;
  font-size:1.7rem;
  border-color: transparent !important;
  border-radius: 10px;
  text-transform: uppercase;
}
.side-section-tie{
  cursor: pointer;
}
</style>