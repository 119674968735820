import vietnam from './vietnam';
import english from './english';
import khmer from './khmer';


export default {
	vietnam,
	english,
	khmer

};

