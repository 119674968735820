<template>
    <div class="flexi-left pa-0">
        <TabChannelGame :passSelectedChannel="passSelectedChannel" :passChannelStatus="passChannelStatus"
            :passStartIndex="0" :passEndIndex="5" v-on:sentSetChannel="_switchChannel" />
        <VideoLiveForDesktop />
        <div class="cock-hide-on-mobile">
            <TableResult :rows="6" :cols="35" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import VideoLiveForDesktop from "../videoLiveForDesktop.vue";
import TableResult from "../TableResults.vue";
import TabChannelGame from "./TabChannelGame.vue";
// import BettingComponent from "./BettingComponent.vue";
// import ReportBetting from "../ReportBetting.vue";
// import ResultVideoApong from '../_ResultOnVideo/ResultVideoApong.vue';

export default {
    components: { TabChannelGame, VideoLiveForDesktop, TableResult },
    data() {
        return {
            logoWebsite: '../../../../../public/images/asiancockfighting-word.jpg',
        }
    },
    props: [
        "passChannelStatus",
        "passSelectedChannel"
    ],
    computed: {
        ...mapGetters("$_dashboard", {
            channelObj: "getChannel",
        }),
    },
    methods: {
        _switchChannel(selectedChannel) {
            this.$emit("sentSetChannel", selectedChannel);
        }
    },
}
</script>
