<template>
    <div class="w-100">
      <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }} </h5>
      <div class="game-machine-component-result">
        <div class="trends limit-on-tab"  style="position: relative;">
          <div v-if="scanResult && scanResult.total" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center; background-color:rgba(40, 90, 226, .83)">
            <div class="h-100">
              <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
                <tr style="height: 38px;">
                  <th v-if="scanResult.total<=810" style="color: #ffffff; background-color:rgba(40, 90, 226, .83)">
                    <b>EVEN</b>
                  </th>
                  <th v-else style="color: #ffffff; background-color:rgba(224, 80, 73, .93)">
                    <b>ODD</b>
                  </th>
                </tr>
                <tr>
                  <td v-if="scanResult.total<=810" style="background-color: rgba(40, 90, 226, .83); font-size: 42px;">
                    <b>{{scanResult.total}}</b>
                  </td>
                  <td v-else style="background-color: rgba(224, 80, 73, .93); font-size: 42px;">
                    <b>{{scanResult.total}}</b>
                  </td>
                </tr>
              </table>
            </div>
          </div>
            <div>
            <!-- <div class="trend-legend-custome  gp-panel-bg  d-flex  justify-content-between align-items-center"
                style="background-color: #ffffff !important;">
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-wala">{{ resultObj.summary.SMALL }}</span>
                <p class="mb-0 count-label">U</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-meron">{{ resultObj.summary.LARGE }}</span>
                <p class="mb-0 count-label">O</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-one">{{ resultObj.summary.RANGE1 }}</span>
                <p class="mb-0 count-label">1</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-two">{{ resultObj.summary.RANGE2 }}</span>
                <p class="mb-0 count-label">2</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-three">{{ resultObj.summary.RANGE3 }}</span>
                <p class="mb-0 count-label">3</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center "><span
                  class="count rounded-circle trend-four">{{ resultObj.summary.RANGE4 }}</span>
                <p class="mb-0 count-label">4</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center "><span
                  class="count rounded-circle trend-five">{{ resultObj.summary.RANGE5 }}</span>
                <p class="mb-0 count-label">5</p>
              </div>
            </div> -->
            <div class="px-3 pb-4 gp-panel-bg  landscape-trend"
                style="position: relative; background-color: #ffffff !important; padding: 0 !important;">
                <v-col cols="12" sm="12" style="padding: 0">
                    <v-row style="padding: 0; margin: 0">
                        <v-col
                        cols="6"
                        md="6"
                        sm="6"
                        lg="6"
                        style="padding: 0; margin: 0">
                            <VideoLive :passurl="passUrl" :channelType="channelType"/>
                        </v-col>
                        <v-col cols="6" md="6" sm="6" lg="6" style="padding:  10px 0px 0 0; margin: 0">
                            <div class="table-responsive gp-scroll">
                              <table class="machine-table">
                                <tbody>
                                  <tr v-for="row in rows" :key="row">
                                    <td v-for="col in (channel.fix_column+8)" :key="col">
                                      <div v-if="channel.column - channel.fix_column <= 0">
                                        <div v-for="(result, i) in resultObj.results" :key="i">
                                          <span v-if="col == result.cid && row == result.rid" style="padding:0;margin:0">
                                            <v-avatar v-if="result && result.small_large && result.small_large.color" :color="calColor(result.small_large.color)"   class="machine-list-lotto-result-ball"
                                            >
                                              <span>{{  $t('button.' + result.small_large.name) }}</span>
                                            </v-avatar>
                                          </span>
                                        </div>
                                      </div>
                                      <div v-else>
                                        <div v-for="(result, i) in resultObj.results" :key="i">
                                          <span v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid" style="padding:0;margin:0">
                                          <v-avatar v-if="result && result.small_large && result.small_large.color" :color="calColor(result.small_large.color)"    class="machine-list-lotto-result-ball"
                                              >
                                              <span>  {{  $t('button.' + result.small_large.name) }}</span>
                                            </v-avatar>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
            </div>
        </div>
    </div>
    <!-- <div class="game-machine-component-reserve-space"></div> -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VideoLive from "../GameResult/VideoLive.vue";
import Mixin from "../../../../../../mixin";
export default {
  components: {
    VideoLive
  },
  props:['passUrl', 'passGame', 'passIndex', 'channelType', 'resultObjS', 'passChDesc', 'showResult', 'totalP','totalB', 'scanResult'],
  data() {
    return {
      mixins: [Mixin],
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {
        results:[]
      },
      
    }
  },
  watch: {
    resultObjS: function (newVal) {
      if(newVal.objCoResult){
        this.resultObj.results = newVal.objCoResult
      }
    }
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
    }),
    getColor(resultName) {
      if (resultName == "BANKER") {
        return "error";
      } else if (resultName == "PLAYER") {
        return "primary";
      } else if (resultName == "TIE") {
        return "success";
      } else {
        return "default";
      }
    },

  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  methods: {
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        // console.log(`getResult2 >>>> ${response}`)
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult"]),
  }
}
</script>
<style>
  /* .v-icon.v-icon{
    font-size: 18px !important
  } */
  div.v-avatar{
    font-size: 8px !important;
  }
  .v-application .pb-3{
    padding-bottom: 2px !important;
  }
</style>