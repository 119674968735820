/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'
let request = new Request;
const fetchLastResults = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotteryvn/result',
        data
    })
    if (response.data.code) {
        let getLastFiveResults = response.data.data.slice(0, 5);
        commit('LATEST_FIVE_RESULTS', getLastFiveResults);
        commit('LATEST_TWENTY_RESULTS', response.data.data);
        if(response.data.data.length>0){
            commit('LATEST_ONE_RESULTS',  response.data.data[0]);
            commit('UPDATE_LRY3_RESULT_A', response.data.data[0].result_a.name);
            commit('UPDATE_LRY3_RESULT_B', response.data.data[0].result_b.name);
            commit('UPDATE_LRY3_RESULT_C', response.data.data[0].result_c.name);
            commit('UPDATE_LRY3_RESULT_D', response.data.data[0].result_d.name);
            commit('UPDATE_LRY3_RESULT_E', response.data.data[0].result_e.name);
        }
        
    }
};

const fetchButton = async ({commit}) => {
    const response = await request.getSlot({
        url: 'm_lotteryvn/button'
    })
    if (response.data.code) {
        commit('GET_BUTTON', response.data.data);
    }
};


const fetchCurrentBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotteryvn/betting/current',
        data
    })
    if (response.data.code) {
        commit('CURRENT_BETTING', response.data.data);
    }
};

const fetchPreviousBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotteryvn/betting/previous',
        data
    })
    if (response.data.code) {
        commit('PREVIOUS_BETTING', response.data.data);
    }
};

const fetchCurrentGame = async ({commit}, data) => {
    
    const response = await request.getSlot({
        url: 'm_lotteryvn/game',
        data
    })
    if (response.data.code) {
        commit('CURRENT_GAME', response.data.data);
    }
};

const fetchBettingType = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotteryvn/type',
        data
    })
    if (response.data.code) {
        commit('CHANNEL_POST', response.data.data);
    }
};
const passResultRandom = async ({commit}, data) => {
      if (data.forUse == 'a') {
        commit('UPDATE_LRY3_RESULT_A', data.data);
      }
      if (data.forUse == 'b') {
        commit('UPDATE_LRY3_RESULT_B', data.data);
      }
      if (data.forUse == 'c') {
        commit('UPDATE_LRY3_RESULT_C', data.data);
      }
      if (data.forUse == 'd') {
        commit('UPDATE_LRY3_RESULT_D', data.data);
      }
      if (data.forUse == 'e') {
        commit('UPDATE_LRY3_RESULT_E', data.data);
      }
};

const createBet = async ({commit}, { data}) => {
    commit('IS_BETTING', true);
    const response = await request.postSlot({
        url: 'm_lotteryvn/betting',
        data
    })
    if (response.data.code) {
        commit('IS_BETTING', false);
        commit('APPEND_CURRENT_BETTING', response.data.data);
        return response
    }else{
        commit('IS_BETTING', false);
        return response
    }
};
const fetchLimit = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotteryvn/limit',
        data
    })
    if (response.data.code) {
        commit('UPDATE_LOTTERY3D_LIMIT', response.data.data);
    }
};
const fetchDetailInvoice = async ({commit}, data) => {
    commit('UPDATE_LOADING_DIALOG_INVOICE', true);
    const filter = data.data;
    const id = data.id
    const response = await request.getSlot({
        url: `m_lotteryvn/report/detail?module_bet_id=${id}`,
        filter
    })
    if (response.data.code) {
        commit('LIST_DETAIL_INVOICE', response.data.data);
        commit('UPDATE_DIALOG_INVOICE', true);
    }
    commit('UPDATE_LOADING_DIALOG_INVOICE', false);
};


export default {
    passResultRandom,
    fetchDetailInvoice,
	fetchLastResults,
    fetchCurrentBetting,
    fetchPreviousBetting,
    fetchCurrentGame,
    fetchBettingType,
    createBet,
    fetchButton,
    fetchLimit
};
