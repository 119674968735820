<template>
  <div class="trends limit-on-tab">
    <div>
      <div class="col col-12 py-sm-7 py-md-7">
        <div class="row" style="text-align:center">
        <div class="col-4 col-sm-3 col-md-2 pa-md-0">
          <div class="bad-cont d-inline-flex align-items-center mr-1">
            <span class="count rounded-circle circle-color-kk">{{
              resultObj.summary["NUMBER-1"]
            }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk1") }}</p>
          </div>
        </div>
        <div class="col-4 col-sm-3 col-md-2 pa-md-0">
          <div class="bad-cont d-inline-flex align-items-center mr-1">
            <span class="count rounded-circle circle-color-kk">{{
              resultObj.summary["NUMBER-2"]
            }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk2") }}</p>
          </div>
        </div>
        <div class="col-4 col-sm-3 col-md-2 pa-md-0">
          <div class="bad-cont d-inline-flex align-items-center mr-1">
            <span class="count rounded-circle circle-color-kk">{{
              resultObj.summary["NUMBER-3"]
            }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk3") }}</p>
          </div>
        </div>
        <div class="col-4 col-sm-3 col-md-2 pa-md-0">
          <div class="bad-cont d-inline-flex align-items-center mr-1">
            <span class="count rounded-circle circle-color-kk">{{
              resultObj.summary["NUMBER-4"]
            }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk4") }}</p>
          </div>
        </div>
        <div class="col-4 col-sm-3 col-md-2 pa-md-0">
          <div class="bad-cont d-inline-flex align-items-center mr-1">
            <span class="count rounded-circle circle-color-kk">{{
              resultObj.summary["NUMBER-5"]
            }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk5") }}</p>
          </div>
        </div>
        <div class="col-4 col-sm-3 col-md-2 pa-md-0">
          <div class="bad-cont d-inline-flex align-items-center mr-1">
            <span class="count rounded-circle circle-color-kk">{{
              resultObj.summary["NUMBER-6"]
            }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk6") }}</p>
          </div>
        </div>
      </div>
      </div>
      <!-- <div class="trend-legend gp-panel-bg d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle circle-color-kk">{{ resultObj.summary["NUMBER-1"] }}</span>
          <p class="mb-0 count-label">{{ $t("menu.kk1") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle circle-color-kk">{{ resultObj.summary["NUMBER-2"] }}</span>
          <p class="mb-0 count-label">{{ $t("menu.kk2") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle circle-color-kk">{{ resultObj.summary["NUMBER-3"] }}</span>
          <p class="mb-0 count-label">{{ $t("menu.kk3") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span
            class="count rounded-circle circle-color-kk">{{ resultObj.summary["NUMBER-4"] }}</span>
          <p class="mb-0 count-label">{{ $t("menu.kk4") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span
            class="count rounded-circle circle-color-kk">{{ resultObj.summary["NUMBER-5"] }}</span>
          <p class="mb-0 count-label">{{ $t("menu.kk5") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span
            class="count rounded-circle circle-color-kk">{{ resultObj.summary["NUMBER-6"] }}</span>
          <p class="mb-0 count-label">{{ $t("menu.kk6") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span
            class="count rounded-circle circle-color-kk">{{ resultObj.summary.CANCEL }}</span>
          <p class="mb-0 count-label">Cancelled</p>
        </div>
      </div> -->
      <div
        class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
        style="position: relative; "
      >
        <div class="table-responsive pb-3 gp-scroll">
          <table class="kk-table" style="height: 167px">
            <tbody>
              <tr>
                <td v-for="col in cols" :key="col">
                  <div>
                    <div
                      v-for="(result, i) in resultObj.results
                        ? resultObj.results.slice().reverse()
                        : resultObj.results"
                      :key="i"
                      class="text-center"
                      style="
                        width: 42px;
                        max-width: 42px;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                      "
                    >
                      <div v-if="col - 1 == i">
                        <div
                          width="42"
                          style="
                            font-size:1.2rem!important,
                            text-align: center;
                            margin: 4px 0px 4px 0px;
                            color: white;
                            background-color: #2a3a4d;
                          "
                        >
                          <b>#{{ result.fight_no }}</b>
                        </div>
                        <v-img
                          v-if="result.result1_name"
                          :src="findImageOfEachAnimal(result.result1_name)"
                          width="42"
                          style="
                            text-align: center;
                            margin-bottom: 4px;
                            height: 42px;
                          "
                        ></v-img>
                        <v-img
                          v-if="result.result2_name"
                          :src="findImageOfEachAnimal(result.result2_name)"
                          width="50"
                          style="
                            text-align: center;
                            margin-bottom: 4px;
                            height: 50px;
                          "
                        ></v-img>
                        <v-img
                          v-if="result.result3_name"
                          :src="findImageOfEachAnimal(result.result3_name)"
                          width="50"
                          style="
                            text-align: center;
                            margin-bottom: 4px;
                            height: 50px;
                          "
                        ></v-img>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
              <v-row style="padding:0; margin:0">
                  <v-col cols="12" md="12" sm="12" lg="12" style="padding:0 5px 0 0; margin:0">
                          <div class="table-responsive pb-3 gp-scroll">
                            <table class="trend-table">
                              <tbody>
                                <tr>
                                  <td v-for="col in cols" :key="col">
                                    <div>
                                      <div v-for="(result, i) in resultObj.results" :key="i" class="text-center" style="width:50px;max-width:50px;margin-left: auto; margin-right: auto; text-align: center;">
                                        <div v-if="(col-1) == i">
                                            <v-img v-if="result.result1_name" :src="findImageOfEachAnimal(result.result1_name)" width="50" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 50px;"></v-img>
                                            <v-img v-if="result.result2_name" :src="findImageOfEachAnimal(result.result2_name)" width="50" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 50px;"></v-img>
                                            <v-img v-if="result.result3_name" :src="findImageOfEachAnimal(result.result3_name)" width="50" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 50px;"></v-img>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" lg="12" style="padding:0; margin:0">
                          <div class="table-responsive pb-3 gp-scroll">
                              <table class="trend-table">
                                  <tbody>
                                      <tr v-for="row in rows" :key="row">
                                          <td v-for="col in cols" :key="col">
                                            <div style="width: 25px;">
                                              <div v-for="(result, i) in resultObj.results" :key="i">
                                                <span v-if="col == result.cid && row == result.rid">
                                                  <v-avatar 
                                                    :color="result.color"
                                                    size="30"                  
                                                    style="box-shadow: rgba(0, 0, 0, 0.2) 0px -3px 0px inset;"
                                                    >                  
                                                    <span class="black--text" style="font-weight:bold;">
                                                      {{result.fight_no}}
                                                    </span>
                                                  </v-avatar>
                                                </span>
                                              </div>
                                            </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                  </v-col>
              </v-row>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["rows", "cols"],

  computed: {
    ...mapGetters("$_dashboard", {
      resultObj: "getResult",
      channel: "getChannel",
    }),
    orderResult() {
      return this.getListResults();
    },
    number(bet) {
      return this.getNumber(bet);
    },
  },
  methods: {
    findImageOfEachAnimal(animalName) {
      switch (animalName) {
        case "ក្តាន់":
          return "/images/a_each_game/klaklok/0001.png";
        case "ឃ្លោក":
          return "/images/a_each_game/klaklok/0002.png";
        case "មាន់":
          return "/images/a_each_game/klaklok/0003.png";
        case "បង្កង":
          return "/images/a_each_game/klaklok/0004.png";
        case "ក្ដាម":
          return "/images/a_each_game/klaklok/0005.png";
        case "ត្រី":
          return "/images/a_each_game/klaklok/0006.png";
        case "ខ្លា":
          return "/images/a_each_game/klaklok/0007.png";
        case "CANCEL":
          return "/images/a_each_game/klaklok/0010.png";
        default:
          return "";
        // code block
      }
    },
    _resultColor(color) {
      switch (color) {
        case "cir-blue":
          return "circle-color-kk";
        case "cir-red":
          return "trend-meron";
        case "cir-green":
          return "trend-draw";
        case "cir-cancel":
          return "trend-cancel";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.kk-table td {
  width: 50px;
  height: 163px;
  line-height: 30px;
  font-size: 10px;
  text-align: center;
  border: .5px solid #fdd1d173;
  border-radius: 55px;
  padding: 0px;
}

.kk-table td > div {
  width: 50px;
}
table tr td {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  
}
table {
  width: 100% !important;
  max-width: 100% !important;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}
.bad-cont {
  font-size: 11px;
  background: #7c8ad31e;
  color: #fff;
  margin-left: 5px;
  padding: 2px 10px;
  position: relative;
  border-radius: 5px;
}
.bad-cont {
  margin-left: 2px;
}
.bad-cont .count {
  padding: 4px;
  position: absolute;
  width: 25px;
  min-width: 25px;
  height: 25px;
  display: inline;
  margin: auto;
  left: -6px;
  display: flex;
  justify-content: center;
}
.bad-cont .count {
  padding: 0.4rem;
  width: 35px;
  height: 35px;
  font-size:.95rem;
  font-weight: 700;
}
.bad-cont .count-label {
  padding-left: 1.3rem;
}
.circle-color-kk{
  background-color:#66340180
}
</style>