<template>
    <div class="pt-1">
        <v-tabs style="color: white !important; margin-top: 12px; border: 1px solid #34495E;" fixed-tabs>
            <v-tabs-slider style="height:4px !important; color: #FFC008 !important;"></v-tabs-slider>
            <v-tab @click="callCurrentBetting" class="g-header">
                <!-- <v-icon left style="color:white"> mdi mdi-cast </v-icon> -->
                <i class="bi-coin" style="font-size: 1.5rem; color: white; padding: 5px;"></i>
                <span style="font-family: 'khmer mef1'; color:white">
                    {{ $t("title.current_betting") }}
                </span>
            </v-tab>
            <v-tab @click="callTodayReport" class="g-header">
                <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
                <i class="bi-clipboard-data" style="font-size: 1.5rem; color: white; padding: 5px;"></i>
                <span style="font-family: 'khmer mef1'; color:white">
                    {{ $t("title.today_report") }}
                </span>
            </v-tab>
            <v-tab-item>
                <v-simple-table style="font-family: 'hanuman'; background-color: rgba(0, 0, 0, 0.6);"
                    class="elevation-1 theme--dark" fixed-header height="279.1" min-height="279.1">
                    <template v-slot:default>
                        <thead style="background-color: rgba(0, 0, 0, 0.6);">
                            <tr>
                                <th class="text-left" style="width:25%">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-left" style="width:25%">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-left" style="width:25%">
                                    {{ $t("title.return") }}({{ userInfor.currencySymbol }})
                                </th>
                                <th class="text-left" style="width:25%">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="current in currentBetting.list" :key="current._id">
                                <td>
                                    #{{ current.fight_no }}
                                </td>
                                <td>
                                    <v-img :src="findImageOfEachKlaklouk(current.betting)" width="35"
                                        style="text-align: center;margin-bottom: 4px; height: 35px;"></v-img>
                                </td>
                                <td :class="'c' + current.bet_color">
                                    {{ formatMoney(current.bet_amount) + ' x ' + current.payout + '= ???' }}
                                </td>
                                <td>
                                    <print-betting :current="current" />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-tab-item>
            <v-tab-item>
                <v-simple-table style="font-family: 'khmer mef1'; background-color: rgba(0, 0, 0, 0.6);"
                    class="elevation-1 theme--dark" fixed-header height="279.1" min-height="279.1">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left" style="width:5%">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-left" style="width:10%">
                                    {{ $t("title.time") }}
                                </th>
                                <th class="text-left" style="width:10%">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-left" style="width:60%!important">
                                    {{ $t("title.return") }} ({{ userInfor.currencySymbol }})
                                </th>
                                <th class="text-left" style="width:10%">
                                    {{ $t("title.result") }}
                                </th>
                                <th class="text-left" style="width:5%">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="today in todayBetting" :key="today._id">
                                <td class="text-left">
                                    #{{ today.fight_no }}
                                </td>
                                <td class="text-left">
                                    {{ grtDate(today.time) }}
                                </td>
                                <td class="text-left" :class="'c' + today.bet_color">
                                    <v-img :src="findImageOfEachKlaklouk(today.betting)" width="35"
                                        style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 35px;"></v-img>
                                </td>
                                <td :class="today.is_win == true
                                        ? 'ccir-blue'
                                        : today.is_win == false
                                            ? 'ccir-red'
                                            : ''
                                    " class="text-left">
                                    {{ today.is_win ? formatMoney(today.bet_amount) + ' x ' + today.payout + ' : ' +
                                        formatMoney(today.case) :  today.amount }}
                                </td>
                                <td :class="'c' + today.result_color" class="text-left">
                                    {{ today.result1_name }},{{ today.result2_name }},{{ today.result3_name }}
                                </td>
                                <td class="text-left">
                                    <print-today-report :todayReport="today" />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>

//import "@/assets/css/homepage.css";
import { mapActions, mapGetters } from "vuex";
import PrintBetting from "../PrintBetting.vue";
import PrintTodayReport from "../PrintTodayReport.vue";
import clickMixin from "~mixin/MX_ShareFunctionCollection.js"
export default {
    props: ['passLoadingTodayReport'],
    components: {
        PrintBetting,
        PrintTodayReport
    },
    data() {
        return {
            mixins: [clickMixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_dashboard_live", {
            todayBetting: "getTodayBetting",
            currentBetting: "getCurrentBetting"
        }),
    },
    methods: {
        grtDate(date) {
            let time = date.split(" ")
            if (time[1]) {
                return time[1];
            }
            return date
        },
       
        async callCurrentBetting() {
            await this.fetchCurrentBetting();
        },
        async callTodayReport() {
            await this.fetchTodayBetting();
        },
        _sentRefreshTodayReport() {
            this.$emit("sentRefreshTodayReport");
        },
        _sentRefreshCurrentReport() {
            this.$emit("sentRefreshCurrentReport");
        },
        ...mapActions("$_dashboard_live", [
            "fetchTodayBetting",
            "fetchCurrentBetting"
        ]),
    }
}
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(40, 53, 89, .8);
    border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(78, 106, 181, .2)
}

@keyframes rotate {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.refresh-start {
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
}

.text-meron {
    color: #f93c3c !important
}

.txt-white {
    color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
    color: #FFC008
}

.txt-grey {
    color: #6C757D
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    height: 32px !important;
}
</style>