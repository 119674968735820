const UPDATE_SELECTED_LOGIN_MENU = (state, data) => {
	state.selectedLoginMenu = data;
};

const UPDATE_LIST_LOGIN_GAME = (state, data) => {
	state.list_login_game = data;
};
const UPDATE_LOGIN_DIALOG = (state, data) => {
	state.loginDialog = data;
};
export default {
    UPDATE_LOGIN_DIALOG,
    UPDATE_LIST_LOGIN_GAME,
    UPDATE_SELECTED_LOGIN_MENU
};
