<template>
    <div>
      <table v-if="data && data[0] && data[0].length>0" class="mcg-statment-table" :style="getMachineStatement.total_rows > 5 ? '': 'height:auto!important'">
        <tr class="w-100">
          <th v-for="j in header" :key="j.name" :style="j.width" >{{ j.name }}</th>
        </tr>
        <tr v-for="(i, index) in data[0]" :key="i.module_bet_id" @click="dialogMethod(i)" class="has-detail" >
          <td > {{ index+1+'.' }}</td>
          <td > {{ moment(i.time).format(
            "hh:mm:ss"
          ) }}</td>
          <td class="text-uppercase">{{ getChannelTypeName(i.channel_type)}}</td>
          <td> {{ i.no }}</td>
          <td > {{checkbetType(i.channel_type, i.bet_name)}}</td>
          <td> {{ i.total_amount }}</td>
          <td >{{ i.amount_win != null ? i.amount_win : '---' }}</td>
        </tr>
        
    </table>
    <div v-else class=" mx-auto justify-center no-data-statement">
      <img src="../../../../../../public/images/a_page_access_global/no-data-icon copy.png" style="width:70px!important;" height="80px" alt="" />
      <p class="pr-3 pt-2">{{ $t('title.no_data') }}</p>
   </div>
    <div v-if=" data && data[0] && this.getMachineStatement.total_rows > data[0].length && !busy " class="load-more" @click="loadMore" >
       <span>Load More</span>
    </div>
    <div v-else-if="data && data[0] && this.getMachineStatement.total_rows > data[0].length && busy" class="load-more" >
      <span>    <img src="../../../../../../public/images/a_page_access_global/loading.svg" alt="" /></span>
   </div>

   <DetailReport 
   :passDialog="detailDialog"
   :passGetInvoiceData="getInvoiceData"
   :passVariableModuleName="variableModuleName"
   :passDetailLoading="detailLoading"
   :passIsShow="isShow"
   :passGetInvoiceDetailData="getInvoiceDetailData"
   v-on:closeDetailDialog="closeDialogDetail"
   v-on:showDeail="showDeail"
 />
  </div>
</template>

<script>    
import { mapGetters, mapActions, mapMutations } from "vuex";
import dashboardFunc from "../../../../../mixin/MX_ShareFunctionCollection.js";
import axios from "axios";
import moment from "moment";
export default {
  mixins: [dashboardFunc],
  components: {
    DetailReport: () => import("../../../../FolderStatement/statement/components/DetailReportInMachineGame.vue"),
  },
  data() {
    return {
        amount:0,
        count : 0,
        busy: false,
        header:[
          {
            name:'#',
            width:'min-width:40px;'
          },
          {
            name:this.$t("title.time"),
            width:'min-width:90px;'
          },
          {
            name:this.$t("title.gameType"),
            width:'min-width:120px;'
          },
          {
            name:this.$t("title.no"),
            width:'min-width:50px;'
          },
          {
            name:this.$t("title.bet_type"),
            width:'min-width:120px;'
          },
          {
            name:this.$t("title.totalAmount"),
            width:'min-width:170px;'
          },
          {
            name:this.$t("title.amountWin"),
            width:'min-width:170px;'
          },
        ],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      channelType: 1,
      loading: false,
      detailLoading: false,
      dialog: false,
      isShow: false,
      tempVal: {},
      dataRB: {
        page: 1,
        rowsPerPage: 10,
      },
      dateFilterRB: {},
      headers: [
        { text: "header.no", value: "no" },
        { text: "title.date_time", value: "date" },
        { text: "header.bettingType", value: "module_name" },
        { text: "header.post", value: "channel_type" },
        { text: "header.game_no", value: "no" },
        { text: "header.group", value: "group" },
        { text: "header.balance", value: "total_amount" },
        { text: "header.total_win_lose", value: "amount_win" },
        { text: "header.cash", value: "cast" },
        { text: "header.result", value: "status" },
      ],
 
      dataRequest: {
        descendingFormat: [true],
        sortByFormat: ["_id"],
        descending: true,
        sortBy: null,
        page: 1,
        rows_per_page: 10,
        getLoadingDetail: false,
        invoice_id: null,
      },
      detailDialog: false,
      variableModulePath: "",
      variableModulePathDetail: "",
      variableModuleName: "",
      getLoadingDetail: false,
      detailData: [],
      getInvoiceData: [],
      getInvoiceDetailData: [],
      startDate: null,
      endDate: null,
      v_startDate: null,
      v_endDate: null,
      selectedOption: null,
      moduleBetId: null,
      token: this.$cookies.get("sbc_token"),
    
    }
  },
  
  computed: {
    data (){
      return [this.getMachineStatement.reports];
    },
    ...mapGetters("$_cockfight_casino_live", {
      getMachineStatement: "getMachineStatement",
      getMachineStatementConfiguredData : 'getMachineStatementConfiguredData'
    }),
    ...mapGetters("$_modules", {
      userInfo:"getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },
  async created(){
    await this.fetchStatement(this.getMachineStatementConfiguredData);
  },
  methods:{
    moment,
    showDeail() {
      if (!this.isShow) {
        this.detailLoading = true;
        this.invoiceDataMethod(this.tempVal);
      }
    },
    async invoiceDataMethod(val) {
      let slotModulePath = "";
      let requestUrl = process.env.VUE_APP_SLOT_URL;
      if (val.channel_type == "91") {
        slotModulePath =
          "m_yuki/report/detail?module_bet_id=" + val.module_bet_id;
      } else if (val.channel_type == "92") {
        slotModulePath =
          "m_lottery/report/detail?module_bet_id=" + val.module_bet_id;
      }
      //request API
      await axios
        .get(requestUrl + slotModulePath, {
          headers: {
            Authorization: this.token,
            "channel-type": this.channelType,
          },
        })
        .then((response) => {
          if (response.data.code) {
            this.detailLoading = false;
            this.isShow = true;
            this.getInvoiceDetailData = response.data.data;
          }
        })
        .catch((error) => console.log(error));
    },
    loadMore: function() {
      this.busy = true;
      let data = {
        rows_per_page :  this.getMachineStatementConfiguredData.rows_per_page,
        page : this.getMachineStatementConfiguredData.page + 1
      };
      this.UPDATE_STATEMENT_CONFIGRUED_DATA(data)
      this.fetchLoadMoreMachineStatement(this.getMachineStatementConfiguredData);
     
        this.busy = false;
    },
    async dialogMethod(val) {
      this.getInvoiceData = [];
      this.detailLoading = true;
      this.tempVal = val;
      this.moduleBetId = val.module_bet_id;
      this.channelType = this.calculateSwitchChannel(val.channel_type);
      this.detailDialog = true;
      this.variableModulePath = this.calculateRequestReportKeyword(
        val.channel_type
      );
      this.variableModulePathDetail = this.calculateRequestReportKeyword(
        val.channel_type
      );
      this.variableModuleName = this.calculateChannelTypeKeyword(
        val.channel_type
      );
      let requestUrl = this.checkIsMachineGame(val.channel_type)
        ? process.env.VUE_APP_SLOT_URL
        : process.env.VUE_APP_API_URL;
      //request API
      await axios
        .get(requestUrl + this.variableModulePath + this.moduleBetId, {
          headers: {
            Authorization: this.token,
            "channel-type": this.channelType,
          },
        })
        .then((response) => {
          if (response.data.code) {
            this.getInvoiceData = response.data.data;
            this.detailLoading = false;
          }
        })
        .catch((error) => console.log(error));
    },
    closeDialogDetail() {
      this.isShow = false;
      this.detailDialog = false;
    },
    ...mapActions("$_cockfight_casino_live", [
      "fetchStatement",
      "fetchLoadMoreMachineStatement"
    ]),
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_STATEMENT_CONFIGRUED_DATA"
    ]),
  }
  }
</script>
<style>
.has-detail:hover{
  cursor: pointer;
}
</style>