<template>
        <v-expand-transition>
            <v-card v-show="expandMenu" height="auto" width="240" 
            class="dropdown-card-container">
              <ul class="show-notification profile-notification pl-0" style="display: block">
                <a v-for="(i, index) in dropdownList" :key="index" @click="_goToRoute(i.menuUrl)">
                  <li class="waves-effect waves-light menu-item  my-0 pl-4 prime-color font-weight-bold " >
                    <v-icon style="margin-top: -5px" class="pr-2 prime-color ">{{ i.menuIcon }}</v-icon>
                    {{ $t(i.menuName) }}
                  </li>
                </a>
                <li class="waves-effect waves-light menu-item pl-3" @click="
                  changeLang(lang == 'flag-kh.png' ? 'english' : 'khmer')
                  ">
         
                    <img class="img-fluid pt-0"
                      style="margin: -1px 5px 0px 5px; width:20px!important; height:16px!important"
                      :src="_detectLanguage(lang)" />
                    <span style=" font-size:1rem;" class="prime-color font-weight-bold ">  {{ lang == 'flag-kh.png' ? 'ខ្មែរ' :
                      'English' }}</span>
                     
                </li>
                <li style="border-top:2px solid #0437b6; " class="mt-1"></li>
                <li class="waves-effect waves-light menu-item justify-center text-center px-0 logout-tab"  @click="onLogout">
              
                    <v-icon style="margin-top: -5px" class="pr-2  font-weight-bold" >mdi-logout</v-icon>
                    <span class=" text-uppercase">{{ $t("title.logout") }}</span>
                
                </li>
              </ul>
            </v-card>
          </v-expand-transition>
    
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import dashboardFunc from "~mixin/MX_ShareFunctionCollection.js"


export default {
  dashboardFunc: [dashboardFunc],
  data: () => ({
    data: {
      rows_per_page: 6,
      page: 1,
    },
    dropdownList:[
      {
      menuUrl:'/cockfight',
      menuName: "menu.home",
      menuIcon: "mdi-home"
      },
      {
      menuUrl:'/allstatement',
      menuName: "menu.betting_history",
      menuIcon: "mdi-calculator"
      },
      {
      menuUrl:'/withdraw',
      menuName: "menu.withdraw",
      menuIcon: "mdi-arrow-bottom-left"
      },
      {
      menuUrl:'/deposit',
      menuName: "menu.deposit",
      menuIcon: "mdi-arrow-top-right"
      },
      {
      menuUrl:'/my-info',
      menuName: "menu.account_detail",
      menuIcon: "mdi-account-edit"
      },

    ],
    posts: {},
    bottom: false,
    beers: [],
    editAmount: 0,
    scrollTop: 0,
    offsetTop: {},
    height: 600,
    lading: false,
    lang:'',
    numberRule: (amount) => {
      if (amount < 0) return "Please enter a positive number";
      return true;
    },
  }),
  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      expandMenu: "getExpandMenu",
    }),
  },
  mounted(){
    if (
      !this.$cookies.get("local") ||
      this.$cookies.get("local") == "english"
    ) {
      this.$cookies.set("local", "english");
      this.lang = "flag-en.webp";
    } else this.lang = "flag-kh.png";
  },
  methods: {
    changeLang(lang) {
      if (lang == "khmer") this.lang = "flag-kh.png";
      else this.lang = "flag-en.webp";
      this.$i18n.locale = lang;
      this.$cookies.set("local", lang);
    },
    _goToRoute(path) {
      var selectedChannel = 1;
      if (path == '/cockfight') {
        selectedChannel = this.$cookies.get('sbc_home_channel') ? this.$cookies.get('sbc_home_channel') : 
        this.userInfo.home_channel[0];
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == '/LOTTO') {
        selectedChannel = this.$cookies.get('sbc_slot_channel') ? this.$cookies.get('sbc_slot_channel') : 90
        this.$cookies.set("sbc_channel_type", selectedChannel);
        
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == '/live') {
        selectedChannel = this.$cookies.get('sbc_live_channel') ? this.$cookies.get('sbc_live_channel') : this.userInfo.live_channel[0]
        this.$cookies.set("sbc_channel_type", selectedChannel);
     
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      this.UPDATE_EXPAND_MENU(true);
      this.$router.push({ path }).catch(() => { /* ignore */ });
    },
    _detectLanguage(lang) {
      try{
        return require(`../../assets/images/${lang}`)
      }catch(e){
        // console.log(e)
        return {}
      }
   
    },
    onLogout() {
      if (this.$socket) {
        this.$socket.removeAllListeners();
        this.$socket.disconnect();
      }
      this.$cookies.remove("sbc_channel_type");
      this.$cookies.remove("sbc_token");
      this.$router.push("/login");
    },
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_EXPAND_MENU"
    ]),
  },
};
</script>
