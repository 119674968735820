<template>
    <div class="machine-outstanding row ma-0 mt-5">
        <span>{{$t('title.outstanding')}} :</span>
        <v-spacer></v-spacer>
        <span>{{ getMachineStatement.turn_over }}</span>
    </div>
</template>
<script>
    
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
  },
  data() {
    return {
        amount:0
    }
  },
  
  computed: {
    ...mapGetters("$_machine_games", {
      getMachineStatement: "getMachineStatement",
    }),
    ...mapGetters("$_modules", {
      userInfo:"getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
    
  },
  methods:{
    inputAmount(amount) {
      this.INPUT_BETTING_AMOUNT(amount);
    },
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT",
    ]),
  }
  }
</script>