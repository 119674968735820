const LATEST_FIVE_RESULTS = (state, data) => {
    state.lastFiveResults = data;
};
const CURRENT_BETTING = (state, data) => {
    state.currentBetting = data;
};
const UPDATE_SELECT_BTN_TYPE = (state, data) => {
    state.selectedBtnType = data;
};

const GET_BUTTON = (state, data) => {
    state.buttonMoney = data;
};
const BUTTON_TYPE = (state, data) => {
    state.buttonType = data;
};
const INPUT_BETTING_AMOUNT = (state, data) => {
    state.bettingAmount = data;
};
const UPDATE_BETTING_AMOUNT = (state, data) => {
    state.bettingAmount =  parseFloat(state.bettingAmount) + parseFloat(data);
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATE_KENO_LIMIT = (state, data) => {
    state.limitKenoBetting = data;
};

const UPDATE_DATA_BETTING = (state, data) => {
    state.bettingData =  data
};
const APPEND_CURRENT_BETTING = (state, data) => {
    state.currentBetting.total_amount = state.currentBetting.total_amount + data.total_amount;
    state.currentBetting.list.unshift(data);
};
const PREVIOUS_BETTING = (state, data) => {
    state.previousBetting = data;
};
const CURRENT_GAME = (state, data) => {
    state.currentGame = data;
};
const CHANNEL_POST = (state, data) => {
    state.lotteryChannel = data;
};
const LATEST_ONE_RESULTS = (state, data) => {
    if(data!=null){
        state.lastOneResult = data;
    }
    
};
const LATEST_TWENTY_RESULTS = (state, data) => {
    state.lastTwentyResult = data;
};

const IS_BETTING = (state, data) => {
    state.is_Betting = data;
};

const UPDATE_CLIENT_TIME = (state, data) => {
    state.clientTime = data;
};

// New define
const UPDATE_BETTING_TYPE = (state, data) => {
    state.bettingType = data;
    state.selectedListBettingNumber = [];
    state.numbers.forEach((item, index) => state.selectedNumber[index] = false)
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATED_CHANNEL_POST = (state, data) => {
    if(!state.selectedPost.includes(data)) {
        state.selectedPost.push(data)
    }
    else{
        state.selectedPost.splice(state.selectedPost.indexOf(data), 1);
    }
    if(state.selectedPost.length <5 )  state.selectedAllPost = false;
    if(state.selectedPost.length == 5 )  state.selectedAllPost = true;
    state.totalInvoice = state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATED_SELECT_ALL_CHANNEL_POST = (state, data) => {
    state.selectedAllPost = !data;
    if(state.selectedPost.length <5 ) state.selectedPost=["A","B","C","D","E"];
    else state.selectedPost = [];
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATED_SELECT_LIST_BETTING = (state, data) => {
    state.selectedListBettingNumber.push(data);
    // console.log(state.selectedPost.length+"--"+ state.selectedListBettingNumber+"--"+state.bettingAmount)
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UNSELECT_LIST_BETTING = (state, data) => {
    state.selectedListBettingNumber =  state.selectedListBettingNumber.filter((n) => {
        return n != data
      });
      state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UNSELECT_SPECIFIC_BETTING = (state, data) => {
    state.selectedNumber[data] = false;
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};

const FILTER_UNSELECTED = (state, data) => {
    state.selectedListBettingNumber = state.selectedListBettingNumber.filter((number) => {
        return !data.xcludeArr.includes(number);
      }
    );
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const LIST_DETAIL_INVOICE = (state, data) => {
    state.ListDetailInvoice = data;
};
const UPDATE_DIALOG_INVOICE = (state, data) => {
    state.dialogDetailInovice = data;
};

const UPDATE_LOADING_DIALOG_INVOICE = (state, data) => {
    state.loadingDetailDialog = data;
};


const UPDATE_KENO_LOADING = (state, data) => {
    state.loading = data;
};
const CLEAR_TO_DEFAULT = (state) => {
    state.bettingAmount = 0;
    state.totalInvoice = 0;
    state.clientTime = {
      is_countdown: null,
      is_open_betting: null,
      time: null,
    }
};
const UPDATE_LRY3_RESULT_A = (state,data) => {
    state.result_a = data;
};
const UPDATE_LRY3_RESULT_B = (state,data) => {
    state.result_b = data;
};
const UPDATE_LRY3_RESULT_C = (state,data) => {
    state.result_c = data;
};
const UPDATE_LRY3_RESULT_D = (state, data) => {
    state.result_d = data;
};
const UPDATE_LRY3_RESULT_E = (state, data) => {
    state.result_e = data;
};
const APPEND_RESULT_STATEMENT = (state, data) => {
    state.lastTwentyResult.unshift(data);
};
const RELOAD_PAGE_LOTTO = (state) => {
    state.lastResults=[],
    state.currentBetting=[],
    state.previousBetting=[],
    state.currentGame=[],
    state.rangeType=[],
    state.headTailType=[],
    state.lastOneResult=[],
    state.lastTwentyResult=[],
    state.is_Betting = false,
    state.buttonMoney=[],
    state.lastFiveResults=[]
};
export default {
    UPDATE_KENO_LOADING,
    UPDATE_SELECT_BTN_TYPE,
    BUTTON_TYPE,
    RELOAD_PAGE_LOTTO,
    APPEND_RESULT_STATEMENT,
    UPDATE_LRY3_RESULT_A,
    UPDATE_LRY3_RESULT_B,
    UPDATE_LRY3_RESULT_C,
    UPDATE_LRY3_RESULT_D,
    UPDATE_LRY3_RESULT_E,

    UPDATE_LOADING_DIALOG_INVOICE,
    LIST_DETAIL_INVOICE,
    UPDATE_DIALOG_INVOICE,
    //define
    UPDATE_KENO_LIMIT,
    UPDATE_CLIENT_TIME,
    CLEAR_TO_DEFAULT,
    UPDATE_BETTING_TYPE,
    UPDATED_CHANNEL_POST,
    UPDATED_SELECT_ALL_CHANNEL_POST,
    UPDATED_SELECT_LIST_BETTING,
    UNSELECT_LIST_BETTING,
    UNSELECT_SPECIFIC_BETTING,
    FILTER_UNSELECTED,
    // --
    UPDATE_DATA_BETTING,
    UPDATE_BETTING_AMOUNT,
    LATEST_FIVE_RESULTS,
    LATEST_TWENTY_RESULTS,
    CURRENT_BETTING,
    PREVIOUS_BETTING,
    CURRENT_GAME,
    CHANNEL_POST,
    LATEST_ONE_RESULTS,
    APPEND_CURRENT_BETTING,
    IS_BETTING,
    GET_BUTTON,
    INPUT_BETTING_AMOUNT,
};
