<template>
    <div class="login-header-container">
        <div class="login-img-logo">
            <img src="../../../../../public/images/logo_background/Logo-header.png" alt="">
        </div>
        <div class="login-welcome-text">

            <p class="login-welcome-name d-none d-sm-block text-uppercase">
                {{ $t('title.welcomeAccount') }}
            </p>
            <marquee class="login-welcome-marquee d-none d-sm-block">
                {{ welcomeText() }}
            </marquee>

        </div>
        <div class="login-button-action">
            <div class="login-action-btn-center">
                <button class="mr-1" @click="_openLoginDialog(true)">{{ $t('title.login') }}</button>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <button class="text--color" v-bind="attrs" v-on="on" @click="_updateExpand()" v-click-outside="{
                    handler: onClickOutside,
                    include
                }">
                            <v-icon color="white">mdi-web</v-icon> {{ lang }}

                            <v-icon color="white" v-if="!expandMenu">mdi-menu-down</v-icon>
                            <v-icon color="white" v-if="expandMenu">mdi-menu-up</v-icon>
                        </button>
                    </template>
                    <v-list>
                        <ul class="show-notification profile-notification pl-0" style="display: block">
                            <li class="waves-effect waves-light menu-item pl-3"
                                @click="handleChangeLanguage('English')">
                                <span class="prime-color font-weight-bold ">
                                    English
                                </span>
                            </li>
                            <li class="waves-effect waves-light menu-item pl-3" @click="handleChangeLanguage('ខ្មែរ')">
                                <span class="prime-color font-weight-bold ">
                                    ខ្មែរ
                                </span>
                            </li>
                            <li class="waves-effect waves-light menu-item pl-3"
                                @click="handleChangeLanguage('ภาษาไทย')">
                                <span class="prime-color font-weight-bold ">
                                    ภาษาไทย
                                </span>
                            </li>
                            <li class="waves-effect waves-light menu-item pl-3"
                                @click="handleChangeLanguage('TiếngViệt')">
                                <span class="prime-color font-weight-bold ">
                                    TiếngViệt
                                </span>
                            </li>

                        </ul>
                    </v-list>
                </v-menu>
            </div>
        </div>

        

    </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
    data() {
        return {
            loginDialog: false,
            lang: 'English',
            expandMenu: false,
            getPromoText: {
                title_en: "Welcome To sbc2888 Website",
                title_kh: "សូមស្វាគមន៍មកកាន់ Sbc2888",
                title_vn: "Welcome To sbc2888",
                title_thai: "Welcome To sbc2888",
            },
            user: {
                user_name: this.$route.params.username ? this.$route.params.username : null,
                password: null,
                app_id: null
            },
            showPassword: false,
            password: 'Password',
            errorMessage: '',
            loading: false,
            error: false,
            rules: {
                required: [(value) => !!value || this.$t('title.required')],
                min: v => (v && 0 != v.length >= 6) || this.$t('title.limit_six_chacracter'),
                emailMatch: () => (`The email and password you entered don't match`),
            },
            valid: false,
            checker : true,
            validate: false,
        }
    },
    computed: {

    },
    created() {
        const selectLang = this.$cookies.get("local");
        console.log("selectLang", selectLang)
        this.welcomeText(selectLang);
        if (selectLang == null) {
            this.$i18n.locale = this.lang;
            this.$cookies.set("local", this.lang);
        }
        else this.lang = this.wordDetector(selectLang);
    },
    methods: {

        handleClearError() {
            this.error = false;
        },
        reset() {
            this.$refs.form.reset();
        },
        async handleLogin() {
            try {
                this.loading = true;
                if (this.user.user_name == '' || this.user.password == '') {
                    this.$toastr.e(`Bắt buộc`);
                    return
                }
                this.user.user_name = this.user.user_name.toString().toLocaleLowerCase();
                this.user.password = this.user.password.toString().toLocaleLowerCase();
                this.user.app_id = process.env.VUE_APP_ID
                const response = await this.fetchLogIn(this.user);
                // console.log(response)
                if (response.data.code) {
                    this.UPDATE_EXPAND_MENU(true);
                    const { token, user } = response.data.data;
                    if (user.is_suspend) {
                        this.$toastr.e(`Your account have been blooked!`)
                    } else {
                        if (user.currency_type == 2) {
                            this.$cookies.set("local", "khmer");
                            this.$i18n.locale = "khmer";
                        } else {
                            this.$cookies.set("local", "english");
                            this.$i18n.locale = "english"
                        }
                        this.$cookies.set("sbc_token", "Bearer " + token, 0);
                        // this.$toastr.e(`${JSON.stringify(user.menu)}`)
                        // this.$cookies.set("sbc_user", user);
                        // console.log('sbc_user', `${JSON.stringify(user)}`);
                        this.$cookies.set("sbc_user", JSON.stringify(user));
                        // this.UPDATE_CHANNEL_ID(user.home_channel[0])
                        // this.UPDATE_CHANNEL_LIST(user.home_channel)
                        let allMenu = user.menu;
                        let defaultChannel = allMenu.menu1.channels[0].channel_type;
                        let menuList1 = allMenu.menu1.channels;
                        // let menuList2 = allMenu.menu2.channels;
                        // let menuList3 = allMenu.menu3.channels;
                        // let menuList4 = allMenu.menu4.channels;
                        if (allMenu.menu1.is_enable) {
                            defaultChannel = allMenu.menu1.channel_type
                            for (let i = 0; i <= allMenu.menu1.channels.length; i++) {
                                if (allMenu.menu1.channels[i].is_enable) {
                                    this.$cookies.set("sbc_channel_type", allMenu.menu1.channels[i].channel_type);
                                    break;
                                }
                            }
                        } else if (allMenu.menu2.is_enable) {
                            defaultChannel = allMenu.menu2.channel_type
                            for (let i = 0; i <= allMenu.menu2.channels.length; i++) {
                                if (allMenu.menu2.channels[i].is_enable) {
                                    this.$cookies.set("sbc_channel_type", allMenu.menu2.channels[i].channel_type);
                                    break;
                                }
                            }
                        } else if (allMenu.menu3.is_enable) {
                            defaultChannel = allMenu.menu3.channel_type
                            for (let i = 0; i <= allMenu.menu3.channels.length; i++) {
                                if (allMenu.menu3.channels[i].is_enable) {
                                    this.$cookies.set("sbc_channel_type", allMenu.menu3.channels[i].channel_type);
                                    break;
                                }
                            }
                        } else if (allMenu.menu4.is_enable) {
                            defaultChannel = allMenu.menu4.channel_type
                            for (let i = 0; i <= allMenu.menu4.channels.length; i++) {
                                if (allMenu.menu4.channels[i].is_enable) {
                                    this.$cookies.set("sbc_channel_type", allMenu.menu4.channels[i].channel_type);
                                    break;
                                }
                            }
                        }
                        this.$cookies.set("sbc_defaut_main_menu", defaultChannel);
                     
                        // this.$cookies.set(
                        //     "sbc_slot_channel",
                        //     allMenu.menu3 ? allMenu.menu3[0].channel_type : ""
                        // );
                        // this.$cookies.set("sbc_user", user);

                        // ADD STATIC
                        // console.log('if it here')
                        this.UPDATE_CHANNEL_ID(menuList1[0].channel_type);
                        this.UPDATE_CHANNEL_LIST(user.channel_list);
                        await this.$router.push({ path: "/cockfight" });
                        // this.myInfo();
                    }
                } else {
                    this.$toastr.e(response.data.message.descriptions)
                    this.error = true;
                }
            } catch (error) {
                this.$toastr.e(`${error}`)
                this.error = true;
                return error;
            } finally {
                this.loading = false;
            }

        },

        welcomeText() {
            switch (this.lang) {
                case 'English': return this.getPromoText.title_en;
                case 'ខ្មែរ': return this.getPromoText.title_kh;
                case 'TiếngViệt': return this.getPromoText.title_vn;
                case "ภาษาไทย": return this.getPromoText.title_thai;
                default: return 'Welcome ....'
            }
        },
        handleChangeLanguage(language) {
            this.lang = language;
            switch (language) {
                case 'English':
                    this.changeLangEn();
                    break;
                case 'TiếngViệt':
                    this.changeLangVN();
                    break;
                case 'ภาษาไทย':
                    this.changeLangTh();
                    break;
                case 'ខ្មែរ':
                    this.changeLangKh();
                    break;
            }
        },
        wordDetector(language) {
            switch (language) {
                case 'english':
                    return 'English';
                case 'vietnam':
                    return "TiếngViệt";
                case 'thai':
                    return "ภาษาไทย";
                case 'khmer':
                    return 'ខ្មែរ';
            }
        },
        _openLoginDialog(data){
            this.UPDATE_LOGIN_DIALOG(data);
        },  
        changeLangEn() {
            let lang = this.$cookies.get("local");
            this.$i18n.locale = lang && lang == "english" ? "english" : "english";
            this.$cookies.set("local", this.$i18n.locale);
            this.welcomeText(lang);
        },
        changeLangKh() {
            let lang = this.$cookies.get("local");
            this.$i18n.locale = lang && lang == "khmer" ? "khmer" : "khmer";
            this.$cookies.set("local", this.$i18n.locale);
            this.welcomeText(lang);
        },
        changeLangTh() {
            let lang = this.$cookies.get("local");
            this.$i18n.locale = lang && lang == "thai" ? "thai" : "thai";
            this.$cookies.set("local", this.$i18n.locale);
            this.welcomeText(lang);
        },
        changeLangVN() {
            let lang = this.$cookies.get("local");
            this.$i18n.locale = lang && lang == "vietnam" ? "vietnam" : "vietnam";
            this.$cookies.set("local", this.$i18n.locale);
            this.welcomeText(lang);
        },
        _updateExpand() {
            this.expandMenu = !this.expandMenu;
        },
        onClickOutside() {
            this.expandMenu = false;
        },
        _goToRouter(data){
            this.$router.push(data);
        },
        ...mapActions("$_auth", ["fetchLogIn", "updateSelectedLoginMenu"]),
        ...mapMutations("$_modules", [
            "UPDATE_CHANNEL_ID",
            "UPDATE_CHANNEL_LIST",
            "UPDATE_EXPAND_MENU",
            "UPDATE_LOGIN_DIALOG"
        ]),
        ...mapMutations("$_auth", [
            "UPDATE_LOGIN_DIALOG"
        ]),
    }
}
</script>
<style>
.v-dialog {
    box-shadow: none !important;
}
</style>