<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passIndex}}.{{ passChDesc }}</h5>
    <div class="game-machine-component-result">
      <div class="trends limit-on-tab" style="position: relative;">
        <div v-if="showResult && showResult.result_small_big_draw && showResult.card" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;"
              :style="showResult.result_small_big_draw.name == 'small' ? 'background-color:rgba(40, 90, 226, .83)' : showResult.result_small_big_draw.name == 'big' ? 'background-color:rgba(224, 80, 73, .83)' : 'background-color:rgba(68, 106, 30, .83)'">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
              <tr style="height: 38px;">
                <th style="color: #ffffff;"
                  :style="showResult.result_small_big_draw.name == 'small' ? 'background-color:rgba(40, 90, 226, .83)' : showResult.result_small_big_draw.name == 'big' ? 'background-color:rgba(224, 80, 73, .83)' : 'background-color:rgba(68, 106, 30, .83)'">
                  <b>{{showResult.result_small_big_draw.name}}</b>
                </th>
              </tr>
              <tr>
                <td style="font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{showResult.card.value}}</b></td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div
            class="px-3 pb-0 gp-panel-bg rounded-bottom landscape-trend"
            style="position: relative; background-color: #ffffff !important">
            <v-col cols="12" sm="12" style="padding: 0">
              <v-row style="padding: 0; margin: 0">
                <v-col
                  cols="6"
                  md="6"
                  sm="6"
                  lg="6"
                  style="padding: 0 5px 0 0; margin: 0">
                  <div class="table-responsive pb-3 gp-scroll">
                    <table class="machine-table">
                      <tbody>
                        <tr v-for="row in rows" :key="row">
                          <td v-for="col in fixColBac" :key="col">
                            <div>
                              <div
                                v-for="(result, i) in orderBaccaratResult"
                                :key="i"
                              >
                                <span v-if="col == result.col && row == result.row">
                                  <v-avatar
                                    :color="result.color"
                                    size="18"
                                    style="
                                      color: white;
                                      font-weight: bolder;
                                      font-size:1.3rem;
                                      padding-bottom:1px
                                    "
                                  >
                                    {{
                                      result.name
                                    }}
                                  </v-avatar>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
                <v-col cols="6" md="6" sm="6" lg="6" style="padding: 0; margin: 0">
                  <div class="table-responsive pb-3 gp-scroll">
                    <table class="machine-table">
                      <tbody>
                        <tr v-for="row in rows" :key="row">
                          <td v-for="col in fixColBac" :key="col">
                            <div>
                              <div
                                v-for="(result, i) in orderResult"
                                :key="i"
                              >
                                <span v-if="col == result.col && row == result.row">
                                  <v-avatar
                                    :color="result.color"
                                    size="18"
                                    style="
                                      color: white;
                                      font-weight: bolder;
                                      font-size:1.5rem;
                                      padding-bottom:1px
                                    "
                                  >
                                    {{
                                      $t('title.taixiu'+result.name)
                                    }}
                                  </v-avatar>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </div>
      </div>
    </div>
    <div class="game-machine-component-reserve-space"></div>
  </div>
</template>
<script>

import shareFunction from "~mixin/MX_ShareFunctionCollection.js";
import { mapActions } from "vuex";

export default {
  mixins: [shareFunction],
  props:['passGame', 'passIndex', 'channelType', 'passChDesc', 'showResult'],
  data() {
    return {
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {}
    }
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
    orderBaccaratResult() {
      return this.getBaccaratResults();
    }
  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  watch: {
    resultObjS: function() { 
      // console.log('taixiu.....')
      this.getResult(this.channelType);
    }
  },
  methods: {
    async getResult(channelType) {
      //console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        //console.log(`getResult2 >>>> ${response}`);
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    getBaccaratResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj.results != null) {
        // //console.log( this.resultObj.results)
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          // //console.log(index)
          const item = resultList[index].result_range;
          //console.log(item)
          if (row <= this.rows) {
            // //console.log('row <= this.rows')
            // //console.log(row)
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            // //console.log('else')
            row = 1;
            col += 1;
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
        if (col > this.cols) {
          this.fixColBac = col;
        } else {
          this.fixColBac = this.cols;
        }
      }
      return newResult;
    },
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (
        this.resultObj.results != null &&
        this.resultObj.results.length > 0
      ) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index].result_small_big_draw;
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (pre.name != item.name) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              if (pre.row >= this.rows) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col;
        } else {
          this.fixCol = this.cols;
        }
      }
      return newResult;
    },
    ...mapActions("$_machine_games", ["fetchMaBaResult"]),
  }
}
</script>
<style>

</style>