<template>
    <v-col cols="12 px-1 pt-2 pb-0" >
        <v-row class="ma-0">
            <v-col cols="12" class="pa-0">
                <v-row class="ma-0"> 
                <input placeholder="0" type="number" v-model="getBettingAmount" pattern="[0-9]*" min="1"
                    @input="(event) => inputAmount(event.target.value)"
                    onlynumber="true" class="yuki-input-betting" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 8  " />
                    <div class="yuki-clear-betting py-0 py-md-3 " @click="_clearMoney()" >
                        <v-icon style="font-size:3rem;"  @click="_clearMoney()" >mdi-history</v-icon></div>
                </v-row>
            </v-col>
        </v-row>
    </v-col>
</template>
  
  
<script>

import { mapGetters, mapMutations, mapActions } from "vuex";
import Mixin from "~mixin/MX_ShareFunctionCollection.js";
export default {
    components: {
        // Tabs,
    },
    data() {
        return {
            mixins: [Mixin],
            loading : false
        }
    },
    computed: {

            ...mapGetters("$_modules", {
                userInfo: "getUserInfo",
                getBettingAmount: "getBettingAmount",
            }),
        ...mapGetters("$_machine_yuki", {
            currentGame: "getCurrentGame",
            getLimit: "getLimit",
            getSelectBtn:"getSelectBtn"
        }),
    },
    methods:{
        inputAmount(amount) {
            this.UPDATE_INPUT_BETTING_AMOUNT(amount);
            },
        _clearMoney() {
            this.CLEAR_BETTING_AMOUNT();
        },
       
        
        
        ...mapMutations("$_modules", [
            "UPDATE_INPUT_BETTING_AMOUNT", "CLEAR_BETTING_AMOUNT"
        ]),

            ...mapActions("$_modules", [
        "fetchUserInfo"
        ]),
        } 
};
</script>