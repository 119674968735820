/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'
let request = new Request;

const fetchLastResults = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_yuki/result',
        data
    })
    if (response.data.code) {
        let getLastFiveResults = response.data.data.slice(0, 5);
        commit('LATEST_FIVE_RESULTS', getLastFiveResults);
        commit('LATEST_TWENTY_RESULTS', response.data.data);
        commit('LATEST_ONE_RESULTS', response.data.data[0]);
    }
};

const fetchTypeMaster = async ({commit}) => {
    const response = await request.getSlot({
        url: 'm_yuki/type'
    })
    if (response.data.code) {
        commit('UPDATE_TYPE_MASTER', response.data.data);
        commit("FIRST_SIX_BTN", response.data.data.slice(0, 6));
        commit("SIX_TO_TWELVE", response.data.data.slice(6, 12));
        commit("SMALL_BIG", response.data.data.slice(12, 14));
        commit("RANGE_SMALL", response.data.data.slice(14, 16));
        commit("RANGE_BIG", response.data.data.slice(16, 18));
        commit("BLUE_RED", response.data.data.slice(18, 20));
        commit("ODD_PAIR", response.data.data.slice(20, 22));
    }
};
const fetchButton = async ({commit}) => {
    const response = await request.getSlot({
        url: 'm_yuki/button'
    })
    if (response.data.code) {
        commit('GET_BUTTON', response.data.data);
    //    console.log(response.data.data)
    }
};


const fetchCurrentBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_yuki/betting/current',
        data
    })
    if (response.data.code) {
        commit('CURRENT_BETTING', response.data.data);
    }
};

const fetchPreviousBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_yuki/betting/previous',
        data
    })
    if (response.data.code) {
        commit('PREVIOUS_BETTING', response.data.data);
    }
};

const fetchCurrentGame = async ({commit}, data) => {
    
    const response = await request.getSlot({
        url: 'm_yuki/game',
        data
    })
    if (response.data.code) {
        commit('CURRENT_GAME', response.data.data);
    }
};

const fetchBettingType = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_yuki/type',
        data
    })
    if (response.data.code) {
        commit('HEAD_TAIL_TYPE', response.data.data.slice(0, 2));
        commit('RANGE_TYPE', response.data.data.slice(2, 7));
    }
};

// const createBet = async ({commit}, {vm, data}) => {
//     commit('IS_BETTING', true);
//     const response = await request.postSlot({
//         url: 'm_yuki/betting',
//         data
//     })
//     if (response.data.code) {
//         commit('IS_BETTING', false);
//         const response1 = await request.getSlot({
//             url: 'm_yuki/betting/current',
//             data
//         })
//         if (response1.data.code) {
//             commit('CURRENT_BETTING', response1.data.data);
//         }
//         vm.$toastr.s(`${response.data.message.descriptions}`)
//     }else{
//         vm.$toastr.e(`${response.data.message.descriptions}`)
//         commit('IS_BETTING', false);
//     }
// };

const createBet = async ({}, data) => {
    return await request.postSlot({
        url: 'm_yuki/betting',
        data
    })
};

const fetchLimit = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_yuki/limit',
        data
    })
    if (response.data.code) {
        commit('UPDATE_YUKI_LIMIT', response.data.data);
    }
};

export default {
    fetchLimit,
	fetchLastResults,
    fetchCurrentBetting,
    fetchPreviousBetting,
    fetchCurrentGame,
    fetchBettingType,
    createBet,
    fetchButton,
    fetchTypeMaster
};
