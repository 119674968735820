<template>
    <div class="history limit-on-tab">
        <v-tabs style="color: white !important; margin-top: 16px; border: 1px solid #34495E;" fixed-tabs>
            <v-tabs-slider style="height:4px !important; color: #FFC008 !important;"></v-tabs-slider>
            <v-tab @click="callCurrentBetting" class="g-header">
                <!-- <v-icon left style="color:white"> mdi mdi-cast </v-icon> -->
                <i class="bi-coin" style="font-size: 1.5rem; color: white; padding: 5px;"></i>
                <span style="font-family: 'khmer mef1'; color:white">
                    {{ $t("title.current_betting") }}
                </span>
            </v-tab>
            <v-tab @click="callTodayReport" class="g-header">
                <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
                <i class="bi-clipboard-data" style="font-size: 1.5rem; color: white; padding: 5px;"></i>
                <span style="font-family: 'khmer mef1'; color:white">
                    {{ $t("title.today_report") }}
                </span>
            </v-tab>
            <v-tab-item>
                <v-simple-table style="font-family: 'khmer mef1'; background-color: rgba(0, 0, 0, 0.6);"
                    class="elevation-1 theme--dark" fixed-header height="279.1" min-height="279.1">
                    <template v-slot:default>
                        <thead style="background-color: rgba(0, 0, 0, 0.6);">
                            <tr>
                                <th class="text-left">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.return") }}({{ userInfor.currencySymbol }})
                                </th>
                                <th class="text-left">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="current in currentBetting.list" :key="current._id">
                                <td>
                                    #{{ current.fight_no }}
                                </td>
                                <td>
                                    <v-img :src="findImageOfEachAnimal(current.betting)" width="35"
                                        style="text-align: center;margin-bottom: 4px; height: 35px;"></v-img>
                                </td>
                                <td :class="'c' + current.bet_color">
                                    {{ formatMoney(current.bet_amount) + ' x ' + current.payout + '= ???' }}
                                </td>
                                <td>
                                    <print-betting :current="current" />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-tab-item>
            <v-tab-item>
                <v-simple-table style="font-family: 'khmer mef1'; background-color: rgba(0, 0, 0, 0.6);"
                    class="elevation-1 theme--dark" fixed-header height="279.1" min-height="279.1">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.time") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.return") }} ({{ userInfor.currencySymbol }})
                                </th>
                                <th class="text-left">
                                    {{ $t("title.result") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="today in todayBetting" :key="today._id">
                                <td class="text-left">
                                    #{{ today.fight_no }}
                                </td>
                                <td class="text-left">
                                    {{ grtDate(today.time) }}
                                </td>
                                <td class="text-left" :class="'c' + today.bet_color">
                                    <v-img :src="findImageOfEachAnimal(today.betting)" width="35"
                                        style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 35px;"></v-img>
                                </td>
                                <td :class="today.is_win == null
                                        ? 'ccir-green'
                                        : today.is_win
                                            ? ''
                                            : 'ccir-red'
                                    " class="text-left">
                                    {{ today.is_win == true ? formatMoney(today.bet_amount) +' x ' + today.payout + ' = ' +  formatMoney(today.case) : today.is_win == false ? formatMoney(today.amount_win) : formatMoney(today.bet_amount) +' x ' + today.payout + ' = ' +  formatMoney(today.bet_amount)}}
                                </td>
                                <td :class="'c' + today.result_color" class="text-left">
                                    {{ today.result1_name }},{{ today.result2_name }},{{ today.result3_name }}
                                </td>
                                <td class="text-left">
                                    <print-today-report :todayReport="today" />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>

//import "@/assets/css/homepage.css";
import { mapActions, mapGetters } from "vuex";
import PrintBetting from "../PrintBetting.vue";
import PrintTodayReport from "../PrintTodayReport.vue";
import clickMixin from "../../../../../mixin/MX_ShareFunctionCollection.js"
export default {
    props: ['passLoadingTodayReport'],
    components: {
        PrintBetting,
        PrintTodayReport
    },
    data() {
        return {
            mixins: [clickMixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_dashboard", {
            todayBetting: "getTodayBetting",
            currentBetting: "getCurrentBetting"
        }),
    },
    methods: {
        grtDate(date) {
            let time = date.split(" ")
            if (time[1]) {
                return time[1];
            }
            return date
        },
        findImageOfEachAnimal(animalName) {
            switch (animalName) {
                case "ក្តាន់":
                  return "/images/a_each_game/klaklok/0001.png";
                case "ឃ្លោក":
                    return "/images/a_each_game/klaklok/0002.png";
                case "មាន់":
                    return "/images/a_each_game/klaklok/0003.png";
                case "បង្កង":
                    return "/images/a_each_game/klaklok/0004.png";
                case "ក្ដាម":
                    return "/images/a_each_game/klaklok/0005.png";
                case "ត្រី":
                    return "/images/a_each_game/klaklok/0006.png";
                case "ខ្លា":
                  return "/images/a_each_game/klaklok/0001.png";
                default:
                    return ''
            // code block
            }
        },
        async callCurrentBetting() {
            await this.fetchCurrentBetting();
        },
        async callTodayReport() {
            await this.fetchTodayBetting();
        },
        _sentRefreshTodayReport() {
            this.$emit("sentRefreshTodayReport");
        },
        _sentRefreshCurrentReport() {
            this.$emit("sentRefreshCurrentReport");
        },
        ...mapActions("$_dashboard", [
            "fetchTodayBetting",
            "fetchCurrentBetting"
        ]),
    }
}
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgba(40, 53, 89, .8);
    border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(78, 106, 181, .2)
}

@keyframes rotate {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.refresh-start {
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
}

.text-meron {
    color: #f93c3c !important
}

.txt-white {
    color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
    color: #FFC008
}

.txt-grey {
    color: #6C757D
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    height: 32px !important;
}
</style>