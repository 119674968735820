<template>
  <div class=" pt-0 px-0 pb-2 px-md-0" v-if="passBettingTypeMaster && passBettingTypeMaster[0]">
    <SubGameChannelInfo/>
    <div class="g-summary">
      <div class="col-12 px-0">
        <div class="row px-0 mx-0">
          <v-spacer></v-spacer>
          <div
            style="width: 100% !important"
            class="dd-x-title-dd-1 ri-taixiu-betting-col-dd-1 ri-x-green-dd-1 col-5 text-center pr-2"
            :class="
              getDdBetting.bettingid == passBettingTypeMaster[0]._id
                ? 'ri-sicbo-betting-status'
                : ''
            "
            @click="
              __xbetting(
                passBettingTypeMaster[0]._id,
                passBettingTypeMaster[0].name
              )
            "
          >
            {{ $t("title.tai" + passBettingTypeMaster[0].name) }}
          </div>
          <v-spacer></v-spacer>
          <div
            style="width: 95% !important"
            class="dd-x-title-dd-1 ri-taixiu-betting-col-dd-1 ri-x-red-dd-1 col-5 text-center pl-2"
            :class="
              getDdBetting.bettingid == passBettingTypeMaster[1]._id
                ? 'ri-sicbo-betting-status'
                : ''
            "
            @click="
              __xbetting(
                passBettingTypeMaster[1]._id,
                passBettingTypeMaster[1].name
              )
            "
          >
            {{ $t("title.tai" + passBettingTypeMaster[1].name) }}
          </div>
          <v-spacer></v-spacer>
        </div>
      </div>
      <!-- <v-container class="ma-0 pa-0">
        <v-row class="ma-0">
          <v-col v-for="i in 7" :key="i" class="taixiu-button-number">
            {{ i }}
          </v-col>
        </v-row>  
      </v-container> -->
      <div class="ri-taixiu-betting-row-dd-2 mt-3 mx-0 ">
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r1-c1-dd-2 w-100"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[2]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[2]._id,
              passBettingTypeMaster[2].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{
                passBettingTypeMaster[2].name
              }}</span>
            </span>
          </div>
        </div>
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r1-c2-dd-2"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[3]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[3]._id,
              passBettingTypeMaster[3].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{
                passBettingTypeMaster[3].name
              }}</span>
            </span>
          </div>
        </div>
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r1-c3-dd-2"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[4]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[4]._id,
              passBettingTypeMaster[4].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{
                passBettingTypeMaster[4].name
              }}</span>
            </span>
          </div>
        </div>
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r1-c4-dd-2"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[8]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[8]._id,
              passBettingTypeMaster[8].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-text-dd-1" style="color: greenyellow">{{
                passBettingTypeMaster[8].name
              }}</span>
            </span>
          </div>
        </div>
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r1-c5-dd-2"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[9]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[9]._id,
              passBettingTypeMaster[9].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{
                passBettingTypeMaster[9].name
              }}</span>
            </span>
          </div>
        </div>
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r1-c6-dd-2"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[10]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[10]._id,
              passBettingTypeMaster[10].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{
                passBettingTypeMaster[10].name
              }}</span>
            </span>
          </div>
        </div>
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r1-c7-dd-2"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[11]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[11]._id,
              passBettingTypeMaster[11].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{
                passBettingTypeMaster[11].name
              }}</span>
            </span>
          </div>
        </div>
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r3-c2-dd-2"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[5]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[5]._id,
              passBettingTypeMaster[5].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{
                passBettingTypeMaster[5].name
              }}</span>
            </span>
          </div>
        </div>
        <div
          class="ri-taixiu-betting-col-dd-2 ri-xcol-r3-c3-dd-2"
          :class="
            getDdBetting.bettingid == passBettingTypeMaster[6]._id
              ? 'ri-sicbo-betting-status'
              : ''
          "
          @click="
            __xbetting(
              passBettingTypeMaster[6]._id,
              passBettingTypeMaster[6].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">
                {{ passBettingTypeMaster[6].name }}
              </span>
            </span>
          </div>
        </div>
        <div class="ri-taixiu-betting-col-dd-2 ri-xcol-r3-c4-dd-2"
        :class="
        getDdBetting.bettingid == passBettingTypeMaster[7]._id
          ? 'ri-sicbo-betting-status'
          : ''
        "
        @click="
            __xbetting(
              passBettingTypeMaster[7]._id,
              passBettingTypeMaster[7].name
            )
          "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{  passBettingTypeMaster[7].name }}</span>
            </span>
          </div>
        </div>
        <div class="ri-taixiu-betting-col-dd-2 ri-xcol-r3-c5-dd-2"
          :class="
          getDdBetting.bettingid == passBettingTypeMaster[12]._id
            ? 'ri-sicbo-betting-status'
            : ''
          "
          @click="
              __xbetting(
                passBettingTypeMaster[12]._id,
                passBettingTypeMaster[12].name
              )
            "
        >
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{  passBettingTypeMaster[12].name  }}</span>
            </span>
          </div>
        </div>
        <div class="ri-taixiu-betting-col-dd-2 ri-xcol-r3-c6-dd-2"
        :class="
        getDdBetting.bettingid == passBettingTypeMaster[13]._id
          ? 'ri-sicbo-betting-status'
          : ''
        "
        @click="
            __xbetting(
              passBettingTypeMaster[13]._id,
              passBettingTypeMaster[13].name
            )
          "
      >
        <div>
          <span>
            <span class="dd-x-title-dd-3">{{  passBettingTypeMaster[13].name  }}</span>
          </span>
        </div>
      </div>
      <div class="ri-taixiu-betting-col-dd-2 ri-xcol-r3-c7-dd-2"
      :class="
      getDdBetting.bettingid == passBettingTypeMaster[14]._id
        ? 'ri-sicbo-betting-status'
        : ''
      "
      @click="
          __xbetting(
            passBettingTypeMaster[14]._id,
            passBettingTypeMaster[14].name
          )
        "
    >
      <div>
        <span>
          <span class="dd-x-title-dd-3">{{  passBettingTypeMaster[14].name  }}</span>
        </span>
      </div>
    </div>
      </div>
    </div>
    <CoinButton
      :passGetButton="getButton"
      :passChannel="passChannel"
      :passPlaceCoin="getBettingAmount"
      class="mt-2"
    />
    <SubmitBtn
      :passCheckAllowSubmit="_checkAllowSubmit"
      v-on:handleSubmitBtn="_submitBetting"
      v-on:handleClearBtn="_clearMoney"
      class="mb-2"
    />
    <TaiXiuMachineReportBettingComponent
      :passLoadingTodayReport="passLoadingTodayReport"
    />
    <v-row class="mx-0 justify-center w-100 mt-2">
      <v-col col-12 style="padding: 0">
        <div class="show-on-mobile pt-5">
          <TableResult :rows="6" :cols="35" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import "@/assets/css/homepage.css";
import CoinButton from "~global_components/component_machine_game/CoinBettingButton.vue";
import SubmitBtn from "~global_components/component_casino/SubmitBtn.vue";
import TableResult from "../../TableResults.vue";
import SubGameChannelInfo from "../SubGameChannelInfoComponent.vue";
import TaiXiuMachineReportBettingComponent from "../../_ReportBettingComponent/TaiXiuMachineReportBettingComponent.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    TaiXiuMachineReportBettingComponent,
    CoinButton,
    SubmitBtn,
    TableResult,
    SubGameChannelInfo
  },
  props: [
    "passChannelStatus",
    "passBettingTypeMaster",
    "passWeight",
    "passSelectedChannel",
    "passUserCurrency",
    "passLoadingTodayReport",
  ],
  data() {
    return {
      selectedButton: {},
      selectedBtnClassMeron: false,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedBtnClassPairBanker: false,
      selectedBtnClassPairPlayer: false,
      csBanker1: "",
      csBanker2: "",
      csBanker3: "",
      csPlayer1: "",
      csPlayer2: "",
      csPlayer3: "",
      totalP: 0,
      totalB: 0,
      selectedCoin: false,
      
    };
  },
  watch: {},
  computed: {
    spinningNotAllow() {
      return require("/public/images/spinning-not-allow.svg");
    },
    _checkAllowSubmit() {
      if (
        this.getBettingAmount > 0 && this.getDdBetting.bettingid
      )
        return true;
      else return false;
    },
    ...mapGetters("$_dashboard_live", {
      dialogBetting: "getDialogBetting",
      getButton: "getButton",
      amountMin: "getAmountMin",
      amountMx: "getAmountMax",
      getClientTime: "getClientTime",
      passChannel: "getChannel",
      passCurrentBetting: "getCurrentBetting",
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
      getBettingAmount: "getBettingAmount",
      getDdBetting: "getBettingDdAmount",
    }),
  },

  methods: {
    __xbetting(id, name) {
      this.UPDATE_BETTING_DD_AMOUNT([name, this.getBettingAmount, id]);
    },
    _clearMoney() {
      this.CLEAR_BETTING_DD_AMOUNT();
      this.INPUT_BETTING_AMOUNT(0);
    },
    _submitBetting() {
      let submitBettingData = {
        type_id: this.getDdBetting.bettingid,
        amount: this.getBettingAmount,
      };
      this.$emit("sentSubmitBetting", submitBettingData);
      this._clearMoney();
    },
    ...mapMutations("$_dashboard_live", [
    ]),
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
      "UPDATE_BETTING_DD_AMOUNT",
      "CLEAR_BETTING_DD_AMOUNT"
    ]),
  },
};
</script>
<style scoped>
@media screen and (max-width: 568px) {

  .ri-sicbo-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-xcol-r1-c1-dd-1 {
  grid-area: r1c1dd1;
}

.ri-xcol-r1-c6-dd-1 {
  grid-area: r1c6dd1;
}

.ri-taixiu-betting-row-dd-1 {
  display: grid;
  grid-template-areas: "r1c1dd1 r1c6dd1";
  gap: 6px;
  width: 100%;
  height: 100%;
}

.ri-taixiu-betting-col-dd-1 {
  font-size: 1vw;
  font-weight: 900;
  color: #008ea0;
  border: 1px solid white;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 3px;
  text-transform: uppercase;
  width: 95% !important;
}
.ri-taixiu-betting-col-dd-1:hover {
  cursor: pointer;
}
.ri-taixiu-betting-col-dd-1 div {
  width: 99%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ri-taixiu-betting-col-dd-1 div span {
  width: 100%;
  text-align: center;
}

.ri-taixiu-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-x-green-dd-1 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-x-red-dd-1 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}

.dd-x-title-dd-1 {
  display: block;
  font-size: 8vw;
}
.dd-x-text-dd-1 {
  display: block;
}

.dd-x-title-dd-2 {
  display: block;
  font-size: 2vw;
}
.dd-x-text-dd-2 {
  display: block;
}

.dd-x-title-dd-3 {
  display: block;
  font-size: 4vw;
}
.dd-x-text-dd-1 {
  display: block;
  font-size: 12vw;
}
.dd-x-text-dd-2 {
  display: block;
  font-size: 2vw;
}
/*------------------- */
.ri-xcol-r1-c1-dd-2 {
  grid-area: r1c1dd2;
}
.ri-xcol-r1-c2-dd-2 {
  grid-area: r1c2dd2;
}
.ri-xcol-r1-c3-dd-2 {
  grid-area: r1c3dd2;
}
.ri-xcol-r1-c4-dd-2 {
  grid-area: r1c4dd2;
}
.ri-xcol-r1-c5-dd-2 {
  grid-area: r1c5dd2;
}
.ri-xcol-r1-c6-dd-2 {
  grid-area: r1c6dd2;
}
.ri-xcol-r1-c7-dd-2 {
  grid-area: r1c7dd2;
}

.ri-xcol-r3-c2-dd-2 {
  grid-area: r3c2dd2;
}
.ri-xcol-r3-c3-dd-2 {
  grid-area: r3c3dd2;
}
.ri-xcol-r3-c4-dd-2 {
  grid-area: r3c4dd2;
}
.ri-xcol-r3-c5-dd-2 {
  grid-area: r3c5dd2;
}
.ri-xcol-r3-c6-dd-2 {
  grid-area: r3c6dd2;
}
.ri-xcol-r3-c7-dd-2 {
  grid-area: r3c7dd2;
}

.ri-taixiu-betting-row-dd-2 {
  margin-top: 6px;
  display: grid;
  grid-template-areas:
    "r1c1dd2 r1c2dd2 r1c3dd2 r1c4dd2 r1c5dd2 r1c6dd2 r1c7dd2"
    "r3c2dd2 r3c3dd2 r3c4dd2 r1c4dd2 r3c5dd2 r3c6dd2 r3c7dd2";

  gap: 7px;
  width: 100%;
  height: 100%;
}

.ri-taixiu-betting-col-dd-2 {
  font-size: 1vw;
  font-weight: 900;
  color: white;
  border: 1px solid white;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 3px;
  text-transform: uppercase;
}
.ri-taixiu-betting-col-dd-2:hover {
  cursor: pointer;
}
.ri-taixiu-betting-col-dd-2 div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ri-taixiu-betting-col-dd-2 div span {
  width: 100%;
  text-align: center;
}

.ri-taixiu-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-x-green-dd-2 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-x-red-dd-2 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-sicbo-count-down{
  width: 100%;
  height: auto;
  background-color: #386faf8f;
  margin-bottom: 6px;
  margin-top: 0px;
  text-align: center;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  
  
}
.ri-sicbo-count-down span{
  color: #11f5c4;
  font-size: 8vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
.ri-sicbo-count-down marquee{
  color: #dc3545;
  font-size: 8vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
}
</style>