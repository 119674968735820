<template>
    <div>
        <div class="col-sm-12 px-0 " style="width:94%; margin:auto">
            <div class="form-group no-error mb-0">
                <input
                  :value="getBettingAmount"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  id="betAmount"
                  placeholder="ENTER BET AMOUNT"
                  class="form-control betAmount numbers betAmountValid mb-0 pb-0"                  
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  @input="(event) => inputAmount(event.target.value)"
                />
            </div>
            <p
              class="mb-0 mss-error"
              v-if="errorMessage"
              
            >
              {{ errorMessage }}
            </p>
          </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
  },
  props: [
    "passChannel",
    "passResult",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passTodayReport",
    "passLoadingTodayReport",
    "passChannelList",
    "passSelectedChannel",
    "passCurrentBetting",
    "passNewChannelList",
    "passChannelType",
    "passUserCurrency",
  ],
  data() {
    return {
      bettingMoney: 0,
    };
  },
  computed: {
    ...mapGetters("$_dashboard", {
      currentBetting: "getCurrentBetting",
      errorMessage: "getErrorMessage",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },
  methods: {
    inputAmount(amount) {
      this.INPUT_BETTING_AMOUNT(amount);
    },
    _clearMoney() {
      this.CLEAR_BETTING_AMOUNT();
      this.UPDATE_ERROR_MESSAGE();
      this.selectedBtnClassMeron = false;
      this.selectedBtnClassTie = false;
      this.selectedBtnClassWala = false;
    },
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT",
      "UPDATE_ERROR_MESSAGE"
    ]),
    ...mapMutations("$_dashboard", [
      "UPDATE_ERROR_MESSAGE"
    ]),
  },
};
</script>

<style>
.btn-switch-channel:has(.to-hidden) {
  display: none !important;
}

.video-section {
  position: relative;
  overflow: hidden;
  background-color: #012d98
}
.show-on-mobile {
  display: none;
}
@media (max-width: 768px) {
  .show-on-mobile {
    display: block;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::placeholder
 {
    color: white!important;
  }
.mss-error{
  color: #ef2643; 
  font-weight: 600; 
  font-size: 1.3rem; 
  text-transform: uppercase;
}
</style>