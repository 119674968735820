import LOTTERYVN1 from '@/modules/FolderGames/LotteryMenu/LotteryVN/LotteryVN_1';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/LOTTERYVN1',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: LOTTERYVN1,
                    name: 'LOTTERYVN1'
                }
            ]
    }
];

export default routes;