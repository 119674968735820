export default {
    permission_list: 'បញ្ជីការអនុញ្ញាត',
    role_list: 'បញ្ជីតួនាទី',
    user_list: 'បញ្ជីអ្នកប្រើប្រាស់',
    menu_list: 'បញ្ជី Menu',
    create_permission: 'បង្កើតការអនុញ្ញាត',
    update_permission: 'កែប្រែការអនុញ្ញាត',
    create_role: 'បង្កើតតួនាទី',
    update_role: 'កែប្រែកែប្រែ',
    create_user: 'បង្កើតអ្ន​កប្រើប្រាស់',
    update_user: 'កែប្រែអ្ន​កប្រើប្រាស់',
    create_menu: 'បង្កើតមីនុយ',
    update_menu: 'កែប្រែមីនុយ',
    change_password_user: 'ផ្លាស់ប្តូរពាក្យសម្ងាត់',
    welcome: 'សូមស្វាគមន៍:',
    account_balance: 'គណនីសមតុល្យ',
    user_role: 'តួនាទីអ្នកប្រើប្រាស់',
    No: 'លេខ',
    no: 'លេខ',
    your_balance: 'សមតុល្យរបស់អ្នក',
    home: 'ទំព័រដើម',
    sbc: 'SBC',
    status: 'ស្ថានភាព',
    time: 'ពេលវេលា',
    result: 'លទ្ធផល',
    five_k: '1.5 $',
    ten_k: '2.5 $',
    twenty_k: '5 $',
    five_k1: '12.5 $',
    ten_100k: '25 $',
    twenty_200k: '50 $',
    five_500k: '125 $',
    one_1m: '1M $',
    payout: 'PAYOUT',
    current_betting: 'ភ្នាល់',
    today_report: 'របាយការណ៍ថ្ងៃនេះ',
    type: 'ប្រភេទ',
    amount: 'ចំនួនទឹកប្រាក់',
    invoice: "សំបុត្រ",
    action: 'សកម្មភាព',
    my_profile: 'ប្រវត្តិរូបរបស់ខ្ញុំ',
    persional_info: 'ព័ត៍មានផ្ទាល់ខ្លួន',
    change_password: 'ផ្លាស់ប្តូរពាក្យសម្ងាត់',
    result_betting: 'លទ្ធការភ្នាល់',
    result_win: 'លទ្ធផលឈ្នះ',
    report: 'របាយការណ៍',
    MERON: 'ក្រហម',
    WALA: 'ខៀវ',
    login: 'ចូលប្រព័ន្ធ',
    deposit: 'ដាក់ប្រាក់',
    withdraw: 'ដក់ប្រាក់',
    betting: 'Statement',
    all_betting: 'របាយការណ៍ទាំងអស់',
    betting1: 'ភ្នាល់',
    channel1: 'ប៉ុស្តិ៍ 1',
    channel2: 'ប៉ុស្តិ៍ 2',
    channel3: 'ប៉ុស្តិ៍ 3',
    channel4: 'ប៉ុស្តិ៍ 4',
    min: 'តូចបំផុត',
    max: 'ធំបំផុត',
    log: "ចូល​ | LOGIN",
    logging: 'កំពុងចូល',
    changeLanguage: 'ផ្លាស់ប្តូរភាសា',
    password: 'សូមបញ្ចូលពាក្យសម្ងាត់របស់អ្នក',
    SignIn: "ចូលប្រព័ន្ធ",
    bet_between: "ចន្លោះ",
    bet_small_large: 'តូច / ធំ',
    all_statement: 'របាយការណ៍ទាំងអស់',
    total_win_lose: 'សរុបលទ្ធផលឈ្នះចាញ់',
    start_date: 'ថ្ងៃចាប់ផ្តើម',
    end_date: 'ថ្ងៃបញ្ចប់',
    fight_no: 'លេខភ្នាល់',
    channel: 'បុស្តិ៍',
    group_no: 'ក្រុមលេខ',
    date_time: 'ពេលវេលា',
    cast: 'សរុប',
    amount_win: 'ទឹកប្រាក់ឈ្នះ',
    total_amount: 'សរុបចំនួនទឹកប្រាក់',
    // start from here
    TIGER: "ខ្លា",
    DRAGON: "នាគ",
    gameIsClose: "ការភ្នាល់ត្រូវបានបិទ",
    tie: "ស្មើរ",
    draw: 'ស្មើរ',
    fight_sign: "#",
    fight: "ជុំ",
    cancel: "បោះបង់",
    confirm: "សូមបញ្ជាក់",
    Close: "បិទ",
    Open: "បើក",
    Not_Open: "ឡាយ",
    //sidebar
    user_center: "មជ្ឈមណ្ឌលអ្នកប្រើប្រាស់",
    all_report: "របាយការណ៍រួម",
    cash_ins: "ការបញ្ចូលសាច់ប្រាក់",
    withdrawals: "ដក់ប្រាក់",
    languages: "ភាសា",
    other: "ផ្សេងៗ",
    logout: "ចាក់ចេញ",
    small: "តូច",
    large: "ធំ",
    thin: "ស្តើង",
    thick: "ក្រាស់",
    return: "ប្រាក់ត្រឡប់",
    Old_Balance: "ទឹកប្រាក់ចាស់",
    New_Balance: "ទឹកប្រាក់ ថ្មី",
    old_balance: 'ទឹកប្រាក់ ចាស់',
    game: 'លេខភ្នាល់',
    bettingType: 'ប្រភេទភ្នាល់',
    new_balance: 'ទឹកប្រាក់ថ្មី',
    Amount: 'ចំនួនទឹកប្រាក់',
    dateTime: 'កាលបរិច្ឆេទ',
    cash: 'លុយត្រឡប់',
    Rdeposit: 'របាយការណ៍ដាក់ប្រាក់',
    Rwithdraw: 'របាយការណ៍ដក់ប្រាក់',
    red: 'ក្រហម',
    blue: 'ខៀវ',
    even: 'គូរ',
    odd: 'សេស',
    selectCoin: "សូមជ្រើសរើសកាក់",
    _result: "លទ្ធផល= ",
    betting_status: 'ភ្នាល់',
    meron: "ក្រហម",
    wala: "ខៀវ",
    bet_wala: "ចាក់ខៀវ",
    bet_meron: "ចាក់ក្រហម",
    bet_draw: "ចាក់ស្មើរ",
    bet_tie: "ចាក់ស្មើរ",
    TIE: "ស្មើរ",
    credit: "ទឹកប្រាក់",
    Rememberpassword: 'ចងចាំពាក្យសម្ងាត់',
    date: "កាលបរិច្ឆេទ",
    sender: "អ្នកផ្ងើ",
    reciever: "អ្នកទទួល",
    bank_reciever: "ធនាគារអ្នកទទួល",
    account_name: "ឈ្មោះគណនី",
    send_date: "កាលបរិច្ឆេទផ្ងើ",
    receive_date: "កាលបរិច្ឆេទទទួល",
    register: 'ចុះឈ្មោះ',
    promote_login_header: "ប្រូម៉ូសិនដាក់ប្រាក់លើកដំបូងនិងទទួលបាន 100%",
    chat_with_one: 'ជជែកជាមួយភ្នាក់ងារ sbc2888',
    need_help: "សួស្តី! តើពួកយើងអាចជួយអ្វីអ្នកបានទេ?",
    chat_now: 'ជជែកឥឡូវនេះ',
    register_member: "ចុះឈ្មោះជាសមាជិក",
    user_name: "ឈ្មោះអ្នកប្រើប្រាស់",
    full_name: "នាមត្រកូល និងនាមខ្លួន",
    confirm_password: "បញ្ជាក់ពាក្យសម្ងាត់",
    password_register: "ពាក្យសម្ងាត់",
    contact_no: "លេខទំនាក់ទំនង",
    confirm_age: " ខ្ញុំមានអាយុយ៉ាងតិច១៨ឆ្នាំ ហើយខ្ញុំបានអាន, ",
    confirm_age_red: "ទទួលយក និងយល់ព្រមតាមលក្ខខណ្ឌ ច្បាប់ គោលការណ៍ឯកជនភាព គោលការណ៍ខូគី និងគោលការណ៍ទាក់ទងនឹងការផ្ទៀងផ្ទាត់អាយុ",
    check_confirm_password: 'មិនបានភ្ជាប់',
    success_register: "ការចុះឈ្មោះបានជោគជ័យ",
    account_number: 'លេខគណនី',
    note: 'កំណត់សំគាល់',
    current_password: 'ពាក្យសម្ងាត់បច្ចុប្បន្ន',
    new_password: 'ពាក្យសម្ងាត់ថ្មី',
    field_required: "ចំណុចនេះតម្រូវអោយវាយបញ្ចូល",
    hotline: "ទាក់ទង់មកយើងបានភ្លាមៗ",
    required: 'សូមផ្គូផ្គងទម្រង់សំណើ 1',
    DEPOSIT: 'ដាក់ប្រាក់',
    WITHDRAW: 'ដក់ប្រាក់',
    PENDING: 'កំពុងរង់ចាំ',
    REJECT: "បដិសេធ",
    SUCCESS: "ជោគជ័យ",
    choseBank: "សូមជ្រើសរើសធនាគា",
    game_is_close: "ហ្គាមបានបិទ",
    not_enought_balance: "មិនមានទឹកប្រាក់គ្រប់គ្រាន់",
    inputAmount: "សូមបញ្ចូលចំនួនទឹកប្រាក់",
    limit: "ដែនកំណត់ស្មើ",
    betting_count_over_limit: "ចំនួនភ្នាល់លើសកំណត់",
    copy: 'ចម្លង',
    loading:"សូមរង់ចាំ",

    game_no: "លេខហ្គាម:",
    betLimit: 'ដែនកំណត់ភ្នាល់',
    total_betting_an_invoice: 'សរុបនៅក្នុងវិក្កយបត្ររួម',
    yuki_wipe: "ជូត",
    yuki_street: "ឆ្លូវ",
    yuki_karl: "ខាល",
    yuki_thao: "ថោះ",
    yuki_factory: "រោង",
    yuki_net: "ម្សាញ់",
    yuki_thin: "ស្តើង",
    yuki_thick: "ក្រាស់",
    yuki_mimi: "មមី",
    yuki_mom: "មមែ",
    yuki_vk: "វក",
    yuki_roka: "រកា",
    yuki_kor: "កុរ",
    yuki_odd: "សេស",
    yuki_pair: "គូ",
    yuki_red: "ក្រហម",
    yuki_blue: "ខៀវ",
    yuki_dog: "ច",
    yuki_null: "null",
    outstanding: "ទឺកប្រាក់បានភ្នាល់",
    lo_current_betting: "ការភ្នាល់បច្ចុប្បន្ន",
    lo_previous_betting: "ការភ្នាល់ពីមុន",
    ticket: 'សបុត្រ',
    invoice_detail: "វិក័យប័ត្រលម្អិត",
    game_id: "ហ្គាម ID",
    result_year: "លទ្ធផលតាមឆ្នាំ",
    result_small_big: "លទ្ធផល តូច/ធំ",
    result_range: "លទ្ធផល Range",
    odd_even: 'សេស / គូ',
    blue_red: 'ខៀវ/ក្រហម',
    no_data: "គ្មានទិន្នន័យ ...",
    total_betting_a_game: 'សរុបនៅក្នុងហ្គេម',
    pleaseBet: 'សូមធ្វើការភ្នាល់',
    submit_betting: "Sumit",
    bet_type: "ប្រភេទការភ្នាល់",
    combination:"បន្សំ",
    yuki_bet_amount: "សូមបញ្ចូលចំនួនទឹកប្រាក់ចាក់របស់អ្នក!",
    yuki_enough_balance: "ទឹកប្រាក់មិនមានគ្រប់គ្រាន់",
    yuki_bet_limit: "ចំនួនភ្នាល់ត្រូវតែនៅចន្លោះដែនកំណត់ភ្នាល់",
    yuki_select_your_bet: "សូមជ្រើសរើសការភ្នាល់របស់អ្នក!",

    D:"D",
    normal_betting: "ចាក់ធម្មតា",
    go_with_tail: "ចាក់អូសកន្ទុយ",
    go_with_head: "ចាក់អូសក្បាល",
    all_post: "ទាំងអស់",
    post_channel: "ប៉ុស្ត៍",
    lottery_result: "លទ្ធផលឆ្នោត",
    game_close: "ការភ្នាល់ត្រូវបានបិទ",

    // lotto
    total_betting: "សរុបការភ្នាល់",
    total: "សរុប",


    total_amount_win: "ការទូទាត់សងសរុប",
    yuki_war: 'War',
    bet_total: "សរុបចំនួនទឹកប្រាក់",
    total_cast: "សរុប Cast",

    result_number: "លទ្ធផលលេខ",
    x_win: "ឈ្នះ",
    x_lose: "ចាញ់",
    select_bank: 'សូមជ្រើសរើសធនាគារ',
    clear: "លុប",
    ក្តាម: "ក្តាម",
    បង្គង: "បង្គង",
    ក្ដាម: "ក្ដាម",
    បង្កង: 'បង្កង',
    pleaes_detail: "សូមមើលក្នុង លំអិត",
    game_processing: "កំពុងដំណើរការ",
    showDetailInvoice: "លំអិតការចាក់",

    //sicbo
    sicbo_small: "តូច",
    sicbo_large: "ធំ",
    taismall:"តូច",
    taibig:"ធំ",
    sicbo_even: 'គូរ',
    sicbo_odd: 'សេស',
    taixiusmall: "តូច",
    taixiubig: "ធំ",
    win:"ឈ្នះ",
    lose:"ចាញ់",
    undefined:"---",
    17:"17",
    16:"16",
    15:"15",
    14:"14",
    13:"13",
    12:"12",
    11:"11",
    // Taixiu
    taixiu7: "7",
    A:"A",
    7:"7",
    8:"8",
    9:"9",
    10:"10",
    J:"J",
    Q:"Q",
    K:"K",
    diamond:"♦",
    heart:"♥",
    club:"♣",
    spade:"♠",
    "4-5-6":"4-5-6",
    "A-2-3":"A-2-3",
    "8-9-10":"8-9-10",
    "J-Q-K":"J-Q-K",
    "1":"1",
    "2":"2",
    "3":"3",
    "4":"4",
    "5":"5",
    "6":"6",
    big:"ធំ",
    "Please contact your upline":"សូមទំនាក់ទំនងទៅ Agent របស់លោកអ្នក",

    //DD
    card_type:"ប្រភេទបៀ",
    dd_small: "តូច",
    dd_big: "ធំ",
    c_MERON:'MERON',
    c_WALA:'WALA',
    c_TIE:"TIE",
    c_draw:"TIE",
    c_CANCEL:"CANCEL",
    gameIsLive:"ហ្គេមនឹងចាប់ផ្តើមបន្តិចទៀតនេះ",
    gameIsOpen:"សូមភ្នាល់, សូមសំណាងល្អ",
    enter:"ចូលលេង",
    gameType:"ប្រភេទហ្គេម",
    totalAmount:"សរុបចំនួនភ្នាល់",
    amountWin:'ចំនួនឈ្នះ',
    waitingResult:"កំពុងរង់ចាំលទ្ធផល...",
    print:"Print"   ,
    resultStatement:"លទ្ធផល",
    statement:"របាយការណ៌",
    small_odd:"ក្រោមសេស",
  small_even:"ក្រោមគូ",
  large_odd:"លើសេស",
  large_even:"លើគូ",
  not_enought_amount : "សូមបញ្ចូលទឹកប្រាក់",
  'This Game is close': 'ការភ្នាល់បានបិទ',
  previous_game: "លេខយោង",
  welcomeText:"សូមស្វាគមន៍មកកាន់វេបសាយកម្សាន្ត SBC2888 ជូនពរលោកអ្នកអោយទទួលបានសំណាងល្អគ្រប់ៗគ្នា",
  welcomeAccount:"សូមស្វាគមន៌",
  LO:"LO",
  small_tx:"តូច",
  large_tx:"ធំ",
  CANCEL:"បោះបង់",
  '2-3':"2-3",
  'Ox':"គោ",
  'Pig':"ជ្រូក",
  "Goat":"ពពែ",
  'Tiger':"ខ្លា",
  'tiger':"ខ្លា",
  'Rat':"កណ្តុរ",
  'ខ្លា':'ខ្លា',
  មាន់: "មាន់",
  ត្រី: "ត្រី",
  "Monkey":"ស្វា",
  Horse:"សេះ",
  ឃ្លោក: "ឃ្លោក",
  "Rabbit":"ទស្សាយ",
  Thin:"ស្ដើង",
  Thick:"ក្រាស់",
  enter_game:"ចូលលេង",
  '2digit':"2 លេខ",
  '3digit':"3 លេខ",
  bac4_MERON:'BANKER',
  bac4_WALA:'PLAYER',
  bac4_draw:'ស្មើ',
  bac4_TIE:'ស្មើ',
  roulette_TIE:"0",
};