export default {
  data() {
    return {
      clearAll: 0,
    }},
    methods: {
      _disconnectSocketLotteryVn(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
      },
      _connectSocketLotteryVn(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
        this.CLEAR_TO_DEFAULT();
        this.$connectSocket();
        this.listenerSocketLotteryVn()
      },
      listenerSocketLotteryVn() {
        this.$socket.on("client-random", async (data) => {
          if (this.clearAll == 0) { this.CLEAR_RESULT_LOTTERY3(); }
          this.clearAll = 1;
          if (data != null) {
  
            if (this.result_a.length != data.result_a.name.length) {
              this.fromFinalToResult(data.result_a.name[data.result_a.name.length - 1], "a");
              this.result_a = data.result_a.name;
            }
            if (this.result_b.length != data.result_b.name.length) {
              this.fromFinalToResult(data.result_b.name[data.result_b.name.length - 1], "b");
              this.result_b = data.result_b.name;
  
            }
            if (this.result_c.length != data.result_c.name.length) {
              this.fromFinalToResult(data.result_c.name[data.result_c.name.length - 1], 'c');
  
              this.result_c = data.result_c.name;
  
            }
            if (this.result_d.length != data.result_d.name.length) {
              this.fromFinalToResult(data.result_d.name[data.result_d.name.length - 1], 'd');
              this.result_d = data.result_d.name;
  
            }
            if (this.result_e.length != data.result_e.name.length) {
              this.fromFinalToResult(data.result_e.name[data.result_e.name.length - 1], 'e');
              this.result_e = data.result_e.name;
            }
            this.LATEST_ONE_RESULTS(data);
          }
        });
        // socket get time,  is betting, is coldown
        this.$socket.on("client-time", (data) => {
          if (data) {
            // define client time to display count down time at play zone
            this.UPDATE_CLIENT_TIME(data);
            this.CLEAR_RESULT_LOTTERY3();
  
            // play audio sound
            if (data.time == 118) {
              this._playSoundBetDown();
            }
            if (data.time < 11 && data.time != 0) {
              this._playSoundTing();
            }
            if (data.time == 0) {
              this._playSoundGoodLuck();
            }
          }
        });
        // get new game
        this.$socket.on("client-new-game", (data) => {
          if (data) {
            this.bonus = [];
            this.fetchUserInfo();
          }
        });
        // get game result and bonus
        this.$socket.on("client-result", async (data) =>  {
          if (data) {
            this.clientResult = data;
            this.APPEND_RESULT_STATEMENT(data);
            await this.fetchCurrentBetting();
            await this.fetchCurrentGame();
            await this.fetchPreviousBetting();
          }
        });
        this.$socket.on("client-reverse-result", async (data) =>  {
          if (data) {
            await this.fetchLastResults();
            await this.fetchCurrentBetting();
            await this.fetchCurrentGame();
            await this.fetchPreviousBetting();
          }
        });
        this.$socket.on("client-bonus",  (data) => {
          if (data) {
            this.bonus = data;
            if (this.bonus.is_bonus) {
              // this.$confetti.start();
              this._playSoundCongratulation();
            }
          }
        });
        this.$socket.on("client-reset", (data) => {
          if (data) {
            // this.$confetti.start();
            this.CLEAR_TO_DEFAULT();
            this.ass_a = null;
            this.ass_b = null;
            this.ass_c = null;
            this.ass_d = null;
            this.ass_e = null;
          }
        });
        this.$socket.on("client-update-channel-info", (data) => {
          if (data) {
            const game = {
              date : data.channel.date,
              is_enable : data.channel.enable,
              no : data.channel.no,
              is_start : data.is_start
          }
          this.CURRENT_GAME(game)
        }
         
        });
      
      },
    }
}