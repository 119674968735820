<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passIndex }}.{{ passChDesc }}</h5>
    <div class="game-machine-component-result">
      <v-row no-gutters style="position: relative;">
        <!-- v-if="showResult && showResult.result1_name && showResult.result2_name && showResult.result3_name"  -->
        <div v-if="showResult && showResult.result_small_big && showResult.random"
          style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;"
          :style="showResult.result_small_big.name == 'small' ? 'background-color:rgba(40, 90, 226, .83)' : showResult.result_small_big.name == 'big' ? 'background-color:rgba(224, 80, 73, .83)' : 'background-color:rgba(68, 106, 30, .83)'">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%;">
              <tr style="height: 38px;">
                <th colspan="3" style="color: #ffffff;"
                  :style="showResult.result_small_big.name == 'small' ? 'background-color:rgba(40, 90, 226, .83)' : showResult.result_small_big.name == 'big' ? 'background-color:rgba(224, 80, 73, .83)' : 'background-color:rgba(68, 106, 30, .83)'">
                  <b>{{ showResult.result_small_big.name }}</b>
                </th>
              </tr>
              <tr>
                <td>
                  <v-img :src="findImageOfEachAnimal(showResult.random[0])" width="48"
                    style=" text-align: center; margin-right: auto; margin-left: auto;height: 48px;"></v-img>
                </td>
                <td>
                  <v-img :src="findImageOfEachAnimal(showResult.random[1])" width="48"
                    style=" text-align: center; margin-right: auto; margin-left: auto;height: 48px;"></v-img>
                </td>
                <td>
                  <v-img :src="findImageOfEachAnimal(showResult.random[2])" width="48"
                    style=" text-align: center; margin-right: auto; margin-left: auto;height: 48px;"></v-img>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <v-col cols="12">
          <!--<span style="color: red;">{{ resultObj.results }}</span>-->
          <v-tabs class="sciboresult" style="color: rgb(255, 0, 0) !important;" fixed-tabs>
            <v-tabs-slider color="#174e87" style="height:3px !important"></v-tabs-slider>

            <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
                {{ $t("title.result_small_big") }}
              </span>
            </v-tab>
            <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
                {{ $t("title.result_range") }}
              </span>
            </v-tab>
            <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
                {{ $t("title.odd_even") }}
              </span>
            </v-tab>
            <v-tab-item>
              <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend bottom-space"
                style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderBigSmall" :key="i" class="div-result">
                              <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn :color="result.result_small_big.color" fab dark>
                                  <!-- <v-icon>mdi-checkbox-blank-circle</v-icon> -->
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend bottom-space"
                style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderRange" :key="i" class="div-result">
                              <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn :color="result.result_range.color" fab dark class="btn-range">
                                  <span class="range-name" style="font-size: 10px;">
                                    {{ result.result_range.name }}
                                  </span>
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>

            <v-tab-item>
              <div class="px-3 pb-0 gp-panel-bg rounded-bottom landscape-trend bottom-space"
                style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderOddEven" :key="i" class="div-result">
                              <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn :color="result.result_odd_even.color" fab dark class="btn-range">
                                  <span class="range-name" style="font-size: 10px;">
                                    {{ result.result_odd_even.name == 'odd' ? 'o' : 'e' }}
                                  </span>
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </div>
    <div class="game-machine-component-reserve-space"></div>
  </div>
</template>
<script>

import shareFunction from "~mixin/MX_ShareFunctionCollection.js";
import { mapActions } from "vuex";

export default {
  mixins: [shareFunction],
  props: ['passGame', 'passIndex', 'channelType', 'passChDesc', 'showResult'],
  data() {
    return {
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {}
    }
  },
  computed: {
    orderBigSmall() {
      return this.getListResults(1);
    },
    orderRange() {
      return this.getListResults(2);
    },
    orderOddEven() {
      return this.getListResults(3);
    },
  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  watch: {
    resultObjS: function () {
      // console.log('sicbo.....')
      this.getResult(this.channelType);
    }
  },
  methods: {
    findImageOfEachAnimal(animalName) {
      switch (animalName) {
        case 1:
          return "/images/a_each_game/sicbo/sicbo_1.png";
        case 2:
          return "/images/a_each_game/sicbo/sicbo_2.png";
        case 3:
          return "/images/a_each_game/sicbo/sicbo_3.png";
        case 4:
          return "/images/a_each_game/sicbo/sicbo_4.png";
        case 5:
          return "/images/a_each_game/sicbo/sicbo_5.png";
        case 6:
          return "/images/a_each_game/sicbo/sicbo_6.png";
        default:
          return "";
      }
    },
    async getResult(channelType) {
      //console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        //console.log(`getResult2 >>>> ${response}`)
        if (response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    getListResults(type) {
      let row = 1;
      let col = 1;
      let newResult = [];

      if (this.resultObj.results != null && this.resultObj.results.length > 0) {
        let resultList = this.resultObj.results;

        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          //console.log()
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (type == 1) {
              if (!(pre.result_small_big.name == item.result_small_big.name)) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 2) {
              if (pre.result_range.name != item.result_range.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 3) {
              if (pre.result_odd_even.name != item.result_odd_even.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 4) {
              if (pre.blue_red.name != item.blue_red.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col
        } else {
          this.fixCol = this.cols
        }
      }
      return newResult;
    },
    ...mapActions("$_machine_games", ["fetchMaBaResult"]),
  }
}
</script>
<style>
.v-btn--fab.v-size--default {
  width: 18px !important;
  height: 18px !important;
}

.sciboresult>.v-tabs-bar {
  height: 28px !important;
}

div.px-3.pb-4.bottom-space {
  padding-bottom: 0 !important;
}
</style>