var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center",attrs:{"id":"menu-container"}},[_c('div',{staticClass:"menu-space"},[_c('v-row',{staticClass:"ma-0 pl-17 w-100"},[_c('div',{staticClass:"w-100 d-flex align-center"},[_c('div',{staticClass:"row ma-0"},_vm._l((_vm.getSubMenuLottery),function(i){return _c('div',{key:i.name,staticClass:"text-center d-flex align-items-center menu-slot-item"},[(_vm.userInfo &&
              _vm.getSubMenuLottery &&
              _vm.getSubMenuLottery.length > 0 && i.is_enable
              )?_c('div',{staticClass:"nav-lottery-main-menu nav-icon-wr row ma-0 pt-0",class:_vm._findRouteParent(_vm.$route.path) == i.name
              ? 'main-menu-span-active'
              : '',on:{"click":function($event){return _vm._goToRoute('/' + i.name)}}},[_c('img',{staticClass:"lottery-main-game-image dancing-image",attrs:{"src":_vm._urlToImg(i.name)}}),_c('p',[_vm._v(_vm._s(i.name)+" ")])]):_vm._e()])}),0)])])],1)]),_c('div',{staticClass:"gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center w-100",style:(!_vm._checkHeightSubmenu() ? 'max-height:0!important' : ''),attrs:{"id":"submenu-container"}},[_c('div',{staticClass:"submenu-space"},[_c('v-row',{staticClass:"ma-0 pl-17"},[_c('div',{staticClass:"w-100 d-flex align-center"},[(_vm.userInfo && _vm.getSubMenuLottery)?_c('div',{staticClass:"row my-0 center-screen-mobile",staticStyle:{"max-width":"100%","width":"100%"}},_vm._l((_vm.calculateSubMenu()),function(i){return _c('div',{key:i.name,staticClass:"text-center d-flex align-items-center menu-slot-item"},[(_vm.userInfo &&
              _vm.getSubMenuLottery &&
              _vm.getSubMenuLottery.length > 0 &&
              i && i.name && i.is_enable
              )?_c('div',{staticClass:"ma-0 lottery-submenu-item w-100",class:_vm.channelId == i.channel_type
              ? 'menu-lottery-active'
              : '',on:{"click":function($event){i.name ?
                _vm._goToRoute(
                  '/' +
                  i.name
                    .replace('(', '')
                    .replace(')', '')
                    .toUpperCase()
                ) : ''}}},[_c('p',[_vm._v(_vm._s(_vm._submenuDefineName(_vm.channelId, i.name))+" ")])]):_vm._e()])}),0):_vm._e()])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }