/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'
let request = new Request;
const fetchLastResults = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lottery/result',
        data
    })
    if (response.data.code) {
        let getLastFiveResults = response.data.data.slice(0, 5);
        commit('LATEST_FIVE_RESULTS', getLastFiveResults);
        commit('LATEST_TWENTY_RESULTS', response.data.data);
        commit('LATEST_ONE_RESULTS', response.data.data[0]);
    }
};

const fetchButton = async ({commit}) => {
    const response = await request.getSlot({
        url: 'm_lottery/button'
    })
    if (response.data.code) {
        commit('GET_BUTTON', response.data.data);
       
    }
};


const fetchCurrentBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lottery/betting/current',
        data
    })
    if (response.data.code) {
        commit('CURRENT_BETTING', response.data.data);
    }
};

const fetchPreviousBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lottery/betting/previous',
        data
    })
    if (response.data.code) {
        commit('PREVIOUS_BETTING', response.data.data);
    }
};

const fetchCurrentGame = async ({commit}, data) => {
    
    const response = await request.getSlot({
        url: 'm_lottery/game',
        data
    })
    if (response.data.code) {
        commit('CURRENT_GAME', response.data.data);
    }
};

const fetchBettingType = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lottery/type',
        data
    })
    if (response.data.code) {
        
        commit('CHANNEL_POST', response.data.data);
    }
};

const createBet = async ({commit}, { data}) => {
    commit('IS_BETTING', true);
    const response = await request.postSlot({
        url: 'm_lottery/betting',
        data
    })
    if (response.data.code) {
        commit('IS_BETTING', false);
        commit('APPEND_CURRENT_BETTING', response.data.data);
        return response
    }else{
        commit('IS_BETTING', false);
        return response
    }
};
const passResultRandom = async ({commit}, data) => {
    if (data) {
        commit('UPDATE_LRY_RESULT', data.data);
      }
};


export default {
    passResultRandom,
	fetchLastResults,
    fetchCurrentBetting,
    fetchPreviousBetting,
    fetchCurrentGame,
    fetchBettingType,
    createBet,
    fetchButton
};
