<template>
    <div>
        <div
        class="w-100"
        :style="
          passChannel.is_open != true &&
          passSelectedChannel != 1 &&
          passSelectedChannel != 2 &&
          passSelectedChannel != 3 &&
          passSelectedChannel != 4 &&
          passSelectedChannel != 15 &&
          passSelectedChannel != 16 &&
          passSelectedChannel != 20 &&
          passSelectedChannel != 21 &&
          passSelectedChannel != 18 &&
          passSelectedChannel != 19 &&
          passSelectedChannel != 10 
            ? 'height:50px;'
            : passSelectedChannel == 10
            ? 'height:88px!important;'
            : ''
        "
      >
        <ResultVideoKlaKlok v-if="(passSelectedChannel == 5) &&
        passChannel.is_open != true" :passListKlaklouk="listKlaklouk"/>
        <ResultVideoBaccarat
          v-if="
            (passSelectedChannel == 6 || passSelectedChannel == 7) &&
            passChannel.is_open != true
          "
          :passPlayerCard1="playerCard1"
          :passPlayerCard2="playerCard2"
          :passPlayerCard3="playerCard3"
          :passBankerCard1="bankerCard1"
          :passBankerCard2="bankerCard2"
          :passBankerCard3="bankerCard3"
          :passPlayerPoint="playerPoint"
          :passBankerPoint="bankerPoint"
        />
        <ResultVideoDragon
          v-if="
            (passSelectedChannel == 8 || passSelectedChannel == 9) &&
            passChannel.is_open != true
          "
          :passDragonCard="dragonCard"
          :passTigerCard="tigerCard"
        />
        <ResultVideoLotto
          v-if="passSelectedChannel == 10 && passChannel.is_open != true"
          :passListLotto="listLotto"
        />
        <ResultVideoYuki
          v-if="passSelectedChannel == 11 && passChannel.is_open != true"
          :passListYuki="listYuki"
        />
        <ResultVideoApong
          v-if="
            (passSelectedChannel == 12 || passSelectedChannel == 17) &&
            passChannel.is_open != true
          "
          :passListAbong="listAbong"
        />
        <ResultVideoFantan
          v-if="passSelectedChannel == 13 && passChannel.is_open != true"
          :passlistFantan="listFantan"
        />
        <ResultVideoTaisiv
          v-if="passSelectedChannel == 14 && passChannel.is_open != true"
          :passListTaisiv="listTaiSiv"
        />
      </div>
    </div>
</template>

<script>

import "@/assets/css/homepage.css";
import { mapGetters } from "vuex";
import ResultVideoDragon from "../_ResultOnVideo/ResultVideoTigerDragon.vue";
import ResultVideoBaccarat from "../_ResultOnVideo/ResultVideoBaccarat.vue";
import ResultVideoKlaKlok from "../_ResultOnVideo/ResultVideoKlaKlok.vue";
import ResultVideoLotto from "../_ResultOnVideo/ResultVideoLotto.vue";
import ResultVideoYuki from "../_ResultOnVideo/ResultVideoYuki.vue";
import ResultVideoApong from "../_ResultOnVideo/ResultVideoApong.vue";
import ResultVideoTaisiv from "../_ResultOnVideo/ResultVideoTaisiv.vue";
import ResultVideoFantan from "../_ResultOnVideo/ResultVideoFantan.vue";
export default {
  components: {
    ResultVideoTaisiv,
    ResultVideoDragon,
    ResultVideoBaccarat,
    ResultVideoKlaKlok,
    ResultVideoLotto,
    ResultVideoYuki,
    ResultVideoApong,
    ResultVideoFantan,
  },
  props: [
    "passChannel",
    "passChannelStatus",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passLoadingTodayReport",
    "passSelectedChannel",
    "passCurrentBetting",
    "passUserCurrency",
  ],
  data() {
    return {
        // DEFINE VARIABLE HERE
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
    }),
    ...mapGetters("$_dashboard", {
      // Tiger Dragon
      dragonCard: "getDragonCard",
      tigerCard: "getTigerCard",
      //Baccarat
      playerCard1: "getPlayerCard1",
      playerCard2: "getPlayerCard2",
      playerCard3: "getPlayerCard3",
      bankerCard1: "getBankerCard1",
      bankerCard2: "getBankerCard2",
      bankerCard3: "getBankerCard3",
      bankerPoint: "getBankerPoint",
      playerPoint: "getPlayerPoint",
      //Lotto
      listLotto: "getListLotto",
      // klaklouk
      listKlaklouk: "getListKlaklouk",
      // abong
      listAbong: "getListAbong",
      // yuki
      listYuki: "getListYuki",
      // taisiv
      listTaiSiv: "getListTaiSiv",
      // abong
      listFantan: "getListFantan",
    }),
  },
  methods: {
    receiveSubmitBetting(recieveBettingData) {
      this.$emit("sentSubmitBetting", recieveBettingData);
    },
    _switchChannel(selectedChannel) {
      this.$emit("sentSetChannel", selectedChannel);
    },
  },
};
</script>

<style>
.btn-switch-channel:has(.to-hidden) {
  display: none !important;
}

.video-section {
  position: relative;
  overflow: hidden;
  background-color: #012d98;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 50px !important;
}

.video-spacer {
  width: 100%;
  height: 0px;
  padding: 0px 0px 56%;
  position: relative;
}
</style>