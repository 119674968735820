<template>
    <v-col cols="12" class="keno-content-betting-type pa-0">
        <v-row class="m-0">
            <div class="keno-countdown">
                <div class="keno-countdown-loading">
                    <img :src="require('~public_image/a_each_game/keno/spinner.gif')" alt="">
                </div>
                <div class="keno-countdown-total-result">
                    <div class="keno-countdown-total-ball-number">
                        <p>{{ passClientRandom && passClientRandom.keno ? passClientRandom.keno.length : 0 }}</p>
                    </div>
                    <div class="keno-countdown-ball-number">
                        <p v-if="getClientTime && getClientTime.time > 0" class="text-center"
                            :class="!getClientTime.is_open_betting ? 'keno-time-close' : 'keno-time-open'">{{
                                getClientTime.time }}</p>
                        <div v-else>
                            <img :src="require('~public_image/a_each_game/keno/ball.png')" alt="">
                            <p>{{
                                passRandomNumber && passClientRandom != null && passClientRandom.keno
                                ? passRandomNumber
                                : passClientRandom && passClientRandom.keno && passClientRandom.keno.length > 0
                                    ? passClientRandom.keno.slice(-1)[0]
                                    : 0
                            }}</p>
                        </div>
                    </div>
                    <div class="keno-countdown-total-random-number">
                        <p>{{ passClientRandom && passClientRandom.keno ? passClientRandom.total : 0 }}</p>
                    </div>
                </div>
            </div>
            <div class="keno-betting-button">
                <div class="keno-current-game-and-time">
                    <v-row class="ma-0">
                        <p class="keno-current-game-no">{{ getCurrentGame && getCurrentGame.no ? '#' + getCurrentGame.no : $t('title.loading') }}</p>
                        <p class="keno-current-game-time">{{ getCurrentGame.date ?  getCurrentGame.date : $t('title.loading')}}</p>
                    </v-row>
                </div>
                <div class="betting-range-title">
                    <v-row class="ma-0">
                        <p class="w-50 keno-title-split-first">{{ $t('title.bet_small_large') }}</p>
                        <p class="w-50 keno-title-split-second">{{ $t('title.odd_even') }}</p>
                    </v-row>
                </div>
                <div class="range-button-type-container">
                    <v-row class="ma-0">
                        <div v-for="btn in buttonSmallBig" :key="btn._id" class="small-big-button-type-element"
                            @click="onSelectedButtonType(btn)">
                            <p class="button-type-title text-uppercase">{{ $t('title.' + btn.name) }}</p>
                            <p class="button-type-start-end-number">{{ `${btn.start_number} - ${btn.end_number}` }}</p>
                            <p class="button-type-payout">{{ `${btn.payout}` }}</p>
                        </div>
                        <div v-for="btn in buttonOddEven" :key="btn._id" class="small-big-button-type-element"
                            @click="onSelectedButtonType(btn)">
                            <p class="button-type-title text-uppercase">{{ $t('title.' + btn.name) }}</p>
                            <p class="button-type-start-end-number">- - -</p>
                            <p class="button-type-payout">{{ `${btn.payout}` }}</p>
                        </div>
                    </v-row>
                </div>
                <div class="betting-range-title">
                    <p>{{ $t('title.combination') }}</p>
                </div>
                <div class="range-button-type-container ">
                    <v-row class="ma-0">
                        <div v-for="btn in buttonCombination" :key="btn._id" class="small-big-button-type-element"
                            @click="onSelectedButtonType(btn)">
                            <p class="button-type-title">{{ $t('title.' + btn.name) }}</p>
                            <p class="button-type-start-end-number ">- - -</p>
                            <p class="button-type-payout">{{ `${btn.payout}` }}</p>

                        </div>

                    </v-row>
                </div>
            </div>
        </v-row>
        <div class="button-betting-range" v-if="this.getButtonType">
            <div class="betting-range-title">
                <p>{{ $t('title.bet_between') }}</p>
            </div>
            <div class="range-button-type-container">
                <v-row class="ma-0">
                    <div v-for="range in buttonRange" :key="range._id" class="range-button-type-element"
                        @click="onSelectedButtonType(range)">
                        <p class="button-type-title">{{ appendToNumber(range.name) }}</p>
                        <p class="button-type-start-end-number">{{ `${range.start_number} - ${range.end_number}` }}</p>
                        <p class="button-type-payout">{{ `${range.payout}` }}</p>
                    </div>
                </v-row>
            </div>
        </div>
    </v-col>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
export default ({
    props: [
        "passClientRandom", 'passRandomNumber'
    ],
    computed: {
        buttonSmallBig() {
            if (this.getButtonType) return this.getButtonType.slice(0, 2);
            else return []
        },
        buttonRange() {
            if (this.getButtonType) return this.getButtonType.slice(2, 7);
            else return []
        },
        buttonOddEven() {
            if (this.getButtonType) return this.getButtonType.slice(7, 9);
            else return []
        },
        buttonCombination() {
            if (this.getButtonType) return this.getButtonType.slice(9, 13);
            else return []
        },
        ...mapGetters("$_keno", {
            getClientTime: "getClientTime",
            getButtonType: "getButtonType",
            getCurrentGame: 'getCurrentGame',
            amount: "getBettingAmount",
            getLoading: 'getLoading',
            getLimit: 'getLimit'
        }),
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
        }),
    },
    methods: {
        onSelectedButtonType(btn) {
            this.UPDATE_SELECT_BTN_TYPE(btn);
            this.createBetting(btn._id, this.amount)
        },
        formatDateId(data) {
            return moment(data.date).format("YYYYMMDD") + "#" + data.no
        },
        appendToNumber(data) {
            switch (data) {
                case '1': return "1st";
                case '2': return "2nd";
                case '3': return "3rd";
                case '4': return "4th";
                case '5': return "5th";
                default: return this.$t('title.loading');
            }
        },

        async createBetting(btnId, amount) {

            if (this.getLoading) {
                this.$toastr.e(this.$t('title.processing_previous_betting'));
                return
            }
            if (this.getClientTime && !this.getClientTime.is_open_betting) {
                this.$toastr.e(this.$t('title.game_close'));
                return
            }
            if (!amount || amount == 0 || parseInt(this.userInfo.amount) <= amount) {
                this.$toastr.e(this.$t('title.not_enought_amount'));
                return
            }
            if (amount < this.getLimit.amount_min || amount > this.getLimit.amount_max) {
                this.$toastr.e(this.$t(`title.bet_over_limit`));
                return;
            }
            if (!btnId) {
                this.$toastr.e(this.$y('title.no_select_btn'));
                return
            }
            this.UPDATE_KENO_LOADING(true);
            try {
                let data = {
                    amount: amount,
                    type_id: btnId
                };
         
                let res = await this.createBet({ data: data });
                if (res.data.code) {
                    this.handleClear();
                    this.UPDATE_BALANCE(res.data.data.balance);
                    this.$toastr.s(this.$t(`title.${res.data.message.descriptions}`));
                    // this.playSoundBetSuccess()
                } else {
                    this.$toastr.e(this.$t(`title.${res.data.message.descriptions}`));
                }
                this.UPDATE_KENO_LOADING(false);
            } catch (error) {
                this.$toastr.e(error);
                this.UPDATE_KENO_LOADING(false);
                return error;
            }
        },
        handleClear() {
            this.INPUT_BETTING_AMOUNT(parseInt(0));
        },
        ...mapMutations("$_keno", [
            "UPDATE_SELECT_BTN_TYPE", "INPUT_BETTING_AMOUNT", "UPDATE_KENO_LOADING"
        ]),
        ...mapActions("$_keno", [
            "createBet",
        ]),
        ...mapMutations("$_modules", ["UPDATE_BALANCE"]),
    }

})
</script>
  