<template>
    <div>
        <Header :passDrawer="drawer" :passUserInfo="passUserInfo" v-on:invokeDrawer="handleDrawer"></Header>
        <v-navigation-drawer style="z-index: 999999999" v-model="drawer" right fixed temporary>
            <SideBar :passDrawer="drawer" :passUserInfo="passUserInfo" v-on:invokeLogout="handleLogout"></SideBar>
        </v-navigation-drawer>
    </div>
</template>
<script>
// import "@/assets/css/header.css";
import Header from './Header/Header.vue';
import SideBar from './SideBar.vue';
export default {
    components: { Header, SideBar },
    props: ['passUserInfo'],
    data: () => ({
        drawer: false,
        value: 'recent'
        
    }),
    methods:{
        handleDrawer(getDrawer){
            this.drawer = getDrawer;
        },
        handleLogout(){
            this.$emit("invokeLogout");
        }
    }
}
</script>