<template>
  <div  style="overflow:hidden">
    <lottery-info class="d-none d-sm-block"/>
    <v-row no-gutters class="mx-0 w-100">
      <lottery-betting class="d-none d-sm-block w-100"/>
      <list-lottery-result />
    </v-row>
  </div>
</template>

<script>
import ListLotteryResult from "./SubComponent/ListLotteryResult.vue";
import LotteryInfo from "@/modules/FolderGames/LotteryMenu/Lottery/components/SubComponent/LotteryInfo.vue";
import LotteryBetting from "@/modules/FolderGames/LotteryMenu/Lottery/components/SubComponent/LotteryBetting.vue";

export default {
  components: {
    ListLotteryResult,
    LotteryInfo,
    LotteryBetting,
  },
  data() {
    return {
    };
  },
};
</script>
