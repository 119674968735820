<template>
  <v-container id="main">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-img lazy-src="images/a_each_game/baccarat/bg.jpg" src="images/a_each_game/baccarat/bg.jpg">
          <div class="rx-kl-min-max1" style="margin-bottom: 20px" :style="{
            backgroundImage:
              'url(\'' + 'images/a_each_game/klaklok/img/Asset2.png' + '\')'
          }">
            <div class="rx-kl-min-max-box">
              <div class="rx-kl-game" v-if="channelObj.fight_no">
                <p>#{{ channelObj.fight_no }}</p>
              </div>
              <div class="rx-kl-min-dt" v-if="amountMin">
                <p>{{ formatMoney(amountMin) }}</p>
              </div>
              <div class="rx-kl-max-dt" v-if="amountMax">
                <p>{{ formatMoney(amountMax) }}</p>
              </div>
            </div>
          </div>
         
          <div id="wraper">
            <div id="tableDiv">
              <div style="
                  position: relative;
                  left: 65%;
                  top: 10%;
                  transform: translateX(-50%);
                ">
                <div id="blockCards">
                  <img id="block01P" v-if="block01P " :src="block01P"
                    style="max-width: 90px; height: auto; margin-right: 10px" class="blockcards-size" />
                  
                  <img id="block02P" v-if="block01P" :src="block02P" style="max-width: 90px; margin-right: 10px"
                    class="blockcards-size" />
                  <img id="block03P" v-if="getPlayerCard3 &&
                    getPlayerCard3 != '0052'
                    " style="max-width: 90px; height: auto" :src="block03P" class="card-animation blockcards-size" />
                </div>
              </div>
    
              <div style="
                  position: relative;
                  left: 106.5%;
                  top: 10%;
                  transform: translateX(-50%);
                ">
            
                <div id="blockCards">
                  <img id="block01B" v-if="getBankerCard1" :src="block01B"
                    style="max-width: 90px; height: auto; margin-right: 10px" class="blockcards-size" />
                  <img id="block02B" v-if="getBankerCard2" :src="block02B"
                    style="margin-right: 10px; max-width: 90px; height: auto" class="blockcards-size" />
                  <img id="block03B" v-if="getBankerCard3 &&
                    getBankerCard3 != '0052'
                    " :src="block03B" class="card-animation blockcards-size" style="max-width: 90px; height: auto" />
                </div>
              </div>

              <!-- <h3 id="winnerText">  {{ this.showWinner() }} <span style="font-weight:bold;color:white;text-transform: uppercase;">WIN</span></h3> -->
            </div>
          </div>

          <!--Client Time-->
          <div class="rx-close-open-box p-0" v-if="getClientTime" :class="getClientTime.is_open_betting == true
            ? 'rx-close-open-box-green'
            : 'rx-close-open-box-red'
            ">
            <img :src="getClientTime.is_open_betting ? spinningAllow : spinningNotAllow
              " v-if="getClientTime.time" style="width: 100%; height: auto; padding: 0px" alt="" />
            <div v-if="getClientTime.time" style="
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                display: flex;
                align-items: center;
              ">
              <span :style="!getClientTime.is_open_betting ? 'color:#e15b64' : ''">{{ getClientTime.time }}</span>
            </div>
            <div v-else style="
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                display: flex;
                align-items: center;
              ">
              <span><img :src="spinningClose" style="width: 100%; height: auto; padding: 0px" alt="" />
              </span>
            </div>
          </div>
   
          <!--coins-->
          <div class="rb-kl-box rb-kl-box-ppair" v-if="getSelectedBtn == 'selectedPairPlayer'">
            <div class="rb-kl-cion rb-kl-cion-1" v-if="getSelectedCoin > 0"></div>
            <div class="rb-kl-cion rb-kl-cion-2" v-if="getSelectedCoin > 1"></div>
            <div class="rb-kl-cion rb-kl-cion-3" v-if="getSelectedCoin > 2"></div>
          </div>

          <div class="rb-kl-box rb-kl-box-player" v-if="getSelectedBtn == 'selectedPlayer'">
            <div class="rb-kl-cion rb-kl-cion-1" v-if="getSelectedCoin > 0"></div>
            <div class="rb-kl-cion rb-kl-cion-2" v-if="getSelectedCoin > 1"></div>
            <div class="rb-kl-cion rb-kl-cion-3" v-if="getSelectedCoin > 2"></div>
          </div>

          <div class="rb-kl-box rb-kl-box-tie" v-if="getSelectedBtn == 'selectedTie'">
            <div class="rb-kl-cion rb-kl-cion-1" v-if="getSelectedCoin > 0"></div>
            <div class="rb-kl-cion rb-kl-cion-2" v-if="getSelectedCoin > 1"></div>
            <div class="rb-kl-cion rb-kl-cion-3" v-if="getSelectedCoin > 2"></div>
          </div>

          <div class="rb-kl-box rb-kl-box-banker" v-if="getSelectedBtn == 'selectedBanker'">
            <div class="rb-kl-cion rb-kl-cion-1" v-if="getSelectedCoin > 0"></div>
            <div class="rb-kl-cion rb-kl-cion-2" v-if="getSelectedCoin > 1"></div>
            <div class="rb-kl-cion rb-kl-cion-3" v-if="getSelectedCoin > 2"></div>
          </div>

          <div class="rb-kl-box rb-kl-box-pbanker" v-if="getSelectedBtn == 'selectedPairBanker'">
            <div class="rb-kl-cion rb-kl-cion-1" v-if="getSelectedCoin > 0"></div>
            <div class="rb-kl-cion rb-kl-cion-2" v-if="getSelectedCoin > 1"></div>
            <div class="rb-kl-cion rb-kl-cion-3" v-if="getSelectedCoin > 2"></div>
          </div>
          <div id="main">
            <div class="td-result-l" style="text-align: center" :style="{
              backgroundImage:
                'url(\'' + 'images/a_each_game/dragon/round-result.png' + '\')'
            }">
              <img v-if="getPlayerPoint!=null && getCurrentGameResult" :src="require('/public/images/a_each_game/tiger_dragon/Tiger/' + getPlayerPoint.toString() + '.png')
                " style="width: 50%; height: auto; padding-top: 15%" />
            </div>

            <div class="td-result-r" style="text-align: center" :style="{
              backgroundImage:
                'url(\'' + 'images/a_each_game/dragon/round-result.png' + '\')'
            }">
              <img v-if="getBankerPoint!=null && getCurrentGameResult" :src="require('/public/images/a_each_game/tiger_dragon/Dragon/' + getBankerPoint.toString() + '.png')
                " style="width: 50%; height: auto; padding-top: 15%" />
            </div>

            <div class="td-card-result-l x-x-hidden"></div>

            <div class="td-card-result-r td-x-hidden"></div>

            <div class="td-card-sand-l td-x-hidden" :style="{
              backgroundImage: 'url(\'' + 'images/a_game_access_global/cards/back_card.png' + '\')'
            }"></div>

            <div class="td-card-sand-r td-x-hidden" :style="{
              backgroundImage: 'url(\'' + 'images/a_game_access_global/cards/back_card.png' + '\')'
            }"></div>

            <div class="td-win-lose-result-x td-x-hidden"></div>

            <div class="card-container-top" id="first-card" v-if="getFirstCard">
            </div>

            <div class="card-container" id="second-card" v-if="getSecondCard">
            </div>
          </div>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import clickMixin from "~mixin/MX_ShareFunctionCollection.js";

export default {
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s |  BACCARAT`
    },
  data() {
    return {
      defaultImage : require('~public_image/a_game_access_global/cards/back_card.png'),
      block01P: require('~public_image/a_game_access_global/cards/back_card.png'),
      block02P: require('~public_image/a_game_access_global/cards/back_card.png'),
      block03P: require('~public_image/a_game_access_global/cards/back_card.png'),
      block01B: require('~public_image/a_game_access_global/cards/back_card.png'),
      block02B: require('~public_image/a_game_access_global/cards/back_card.png'),
      block03B: require('~public_image/a_game_access_global/cards/back_card.png'),
      showBlock1: false,
      showBlock2: false,
      showBlock3: false,
      winner: "",
      card1: "",
      card2: "",
      card3: "",
      banker1: "",
      banker2: "",
      banker3: "",
      showFirstCard: false,
      mixins: [clickMixin],
    };
  },

  computed: {
    spinningAllow() {
      return require("/public/images/spinning-allow.svg");
    },
    spinningNotAllow() {
      return require("/public/images/spinning-not-allow.svg");
    },
    spinningClose() {
      return require("/public/images/spinning-close.svg");
    },
    ...mapGetters("$_modules", {
      getBettingAmount: "getBettingAmount",
    }),
    ...mapGetters("$_dashboard_live", {
      getSelectedBtn: "getSelectedBtn",
      getSelectedCoin: "getSelectedCoin",
      getCurrentGameResult: "getCurrentGameResult",
      getClientTime: "getClientTime",
      getClientRandom: "getClientRandom",
      amountMin: "getAmountMin",
      amountMax: "getAmountMax",
      channelObj: "getChannel",
      getPlayerCard1: "getPlayerCard1",
      getPlayerCard2: "getPlayerCard2",
      getPlayerCard3: "getPlayerCard3",
      getBankerCard1: "getBankerCard1",
      getBankerCard2: "getBankerCard2",
      getBankerCard3: "getBankerCard3",
      getBankerPoint: "getBankerPoint",
      getPlayerPoint: "getPlayerPoint",
      getFirstCard: "getFirstCard",
      getSecondCard: "getSecondCard",
      getBackCard: "getBackCard",
      isSound: "getIsSound",
      getCard:"getCard"
    }),
  },

  methods: {
    generateFirstCard(num, imagePath) {
      if(this.showFirstCard == true) {
        $("#first-card").empty();
        for (let i = 1; i <= num; i++) {
          let bowlingCard = $("<div>", {
            class: "bowling-card",
          });
          let bowlingCardImage = $("<div>", {
            class: "bowling-card-image card-animation",
            style: `background-image: url(${'../../../../../public/images/a_game_access_global/cards_big/' + imagePath + ".png"
              })`,
          });
          bowlingCard.append(bowlingCardImage);
          $("#first-card").append(bowlingCard);
          this._playSoundTakeCard();
          this.showFirstCard = false;
        }
      }
    },
    async generateSecondCards(num) {
      // console.log("num", num)
      $("#second-card").empty();
      for (let i = 1; i <= num; i++) {
        let bowlingCard = $("<div>", {
          class: "bowling-card",
        });
        let bowlingCardImage = $("<div>", {
          class: "bowling-card-image card-animation",
        });
        bowlingCard.append(bowlingCardImage);
        $("#second-card").append(bowlingCard);
        this._playSoundTakeCard();
        await new Promise((resolve) => setTimeout(resolve, 300));
      }
    },

    //Test

    generateBowlingBallsFirstTest(num, imagePath) {
      $("#first-card").empty();
      for (let i = 1; i <= num; i++) {
        let bowlingCard = $("<div>", {
          class: "bowling-card",
        });
        let bowlingCardImage = $("<div>", {
          class: "bowling-card-image",
          style: `background-image: url(${'../../../../../public/images/a_game_access_global/cards_big/' + imagePath + ".png"
            })`,
        });
        bowlingCard.append(bowlingCardImage);
        $("#first-card").append(bowlingCard);
      }
    },

    async generateBowlingBallsTest(num) {
      $("#second-card").empty();
      for (let i = 0; i <= num; i++) {
        let bowlingCard = $("<div>", {
          class: "bowling-card",
        });
        let bowlingCardImage = $("<div>", {
          class: "bowling-card-image",
        });
        bowlingCard.append(bowlingCardImage);
        $("#second-card").append(bowlingCard);
      }
    },

    _xsetResultX(x = null) {
      $(".td-win-lose-result-x").css("background-image", "url(" + x + ")");
    },

    dtStopResult() {
      $(".td-card-sand-l").removeClass("td-card-sand-l-start");
      $(".td-card-sand-r").removeClass("td-card-sand-r-start");
      $(".td-card-sand-l").addClass("td-x-hidden");
      $(".td-card-sand-r").addClass("td-x-hidden");
      $(".td-resul-open-box").addClass("td-x-hidden");
      $(".td-card-result-l").addClass("td-x-hidden");
      $(".td-card-result-r").addClass("td-x-hidden");
      $(".td-win-lose-result-x").addClass("td-x-hidden");
    },

    dtStartDragonCard() {
      setTimeout(function () {
        $(".td-card-result-l").removeClass("td-x-hidden");
        setTimeout(function () {
          $(".td-result-l")
            .find(".td-resul-open-box")
            .removeClass("td-x-hidden");
        }, 1200);
      }, 3084);
    },

    dtStartTigerCard() {
      setTimeout(function () {
        $(".td-card-result-r").removeClass("td-x-hidden");
        setTimeout(function () {
          $(".td-result-r")
            .find(".td-resul-open-box")
            .removeClass("td-x-hidden");
        }, 1200);
      }, 3084);
    },

    dtStartCurrentGameResult() {
      $(".td-win-lose-result-x").removeClass("td-x-hidden");
    },

    setPlayerCard(blockId) {
      // Remove the "card-animation" class from the element
      document.getElementById(blockId).classList.remove("card-animation");
      this[blockId] = this.defaultImage;
      // await new Promise((resolve) => setTimeout(resolve, 100));
      document.getElementById(blockId).classList.add("card-animation");
      this._playSoundTakeCard();
      return this[blockId];
    },

    showWinner() {
      try {
        const name = this.getCurrentGameResult.co_type_of_betting.name;

        // Check if the name is null or empty
        if (!name || name.trim() == "") {
          return null; // or you can return any other default value as per your requirement
        }

        this.winner = name;
        return this.winner;
      } catch (error) {
        //console.error("An error occurred:", error);
        return ""; // or you can return any other default value in case of an exception
      }
    },

    setBettingCoins(bettype) {
      if (bettype == this.getSelectedBtn) {
        return this.getBettingAmount;
      } else {
        return 0;
      }
    },
  },

  watch: {
    getCurrentGameResult: function () {
      // console.log('hihigetCurrentGameResult')
      try {
        if (this.getCurrentGameResult.co_type_of_betting.name == "PLAYER") {
          this._xsetResultX(
            require("~public_image/a_each_game/baccarat/player_win.png")
          );
          this._playSoundPlayerWin();
        } else if (this.getCurrentGameResult.co_type_of_betting.name == "BANKER") {
          this._xsetResultX(
            require("~public_image/a_each_game/baccarat/banker_win.png")
          );
          this._playSoundBankerWin();
        } else if (this.getCurrentGameResult.co_type_of_betting.name == "TIE") {
          this._xsetResultX(require("~public_image/a_each_game/tiger_dragon/Tie.png"));
          this._playSoundTieGame();
        }
      } catch (exception) {
        return "";
      }

      let myintervalstop = setInterval(() => {
        this.dtStartCurrentGameResult();
        clearInterval(myintervalstop);
      }, 1000);
    },

    async getPlayerCard1() {
      if (this.getPlayerCard1 != "0052") {
        var element1P = document.getElementById("block01P");
        element1P.style.display = "block";
        this.card1 = this.getPlayerCard1;
        await new Promise((resolve) => setTimeout(resolve, 1500));
        let p1 = "images/a_game_access_global/cards_animation/" + this.card1 + ".gif";
        this.block01P = p1
        this._playSoundOpenCard();
      }
        
    },

    async getPlayerCard2() {
      if (this.getPlayerCard2 != "0052") {
        var element2P = document.getElementById("block02P");
        element2P.style.display = "block";
        this.block02P = this.defaultImage;
        await new Promise((resolve) => setTimeout(resolve, 1500));
        let p2 = "images/a_game_access_global/cards_animation/" +  this.getPlayerCard2 + ".gif";
        this.block02P = p2;
        this._playSoundOpenCard();
        //   }
        // }
      } else {
        return;
      }

      ///
      try {
        if (this.getPlayerPoint != -1) {
          await new Promise((resolve) => setTimeout(resolve, 1500));
          this._xgetDragonCard(
            require("~public_image/a_each_game/dragon/" +
              this.getPlayerPoint +
              ".png")
          );
          this.dtStartTigerCard();
        }
      } catch (exception) {
        //Ignore
      }
    },

    async getPlayerCard3() {
      if (this.getPlayerCard3 != "0052") {
         this.card3 = this.getPlayerCard3;
         await new Promise((resolve) => setTimeout(resolve, 1500));
          const gifUrl = "images/a_game_access_global/cards_animation/" + this.card3 + ".gif";
          this.$set(this, "block03P", gifUrl);
          this._playSoundOpenCard();
        
      }

      try {
        if (this.getPlayerPoint != -1) {
          await new Promise((resolve) => setTimeout(resolve, 3500));
          this._xgetDragonCard(
            require("~public_image/a_each_game/dragon/" +
              this.getPlayerPoint +
              ".png")
          );
          this.dtStartTigerCard();
        }
      } catch (exception) {
        //Ignore
      }
    },

    async getBankerCard1() {
      if (this.getBankerCard1 != "0052") {
        
        var element1B = document.getElementById("block01B");
     
          element1B.style.display = "none";
     
        element1B.style.display = "block";
        let b1 = "images/a_game_access_global/cards_animation/" + this.getBankerCard1 + ".gif";
        await new Promise((resolve) => setTimeout(resolve, 1500));
        this.block01B = b1
        this._playSoundOpenCard();
      }
    },

    async getBankerCard2() {
      if (this.getBankerCard2 != "0052") {
        var element2B = document.getElementById("block02B");
        element2B.style.display = "block";
        this.banker2 = this.getBankerCard2;
        let b2 = "images/a_game_access_global/cards_animation/" + this.banker2 + ".gif";
        await new Promise((resolve) => setTimeout(resolve, 1500));
        this.$set(this, "block02B", b2);
        this._playSoundOpenCard();
      } else {
        return;
      }

      try {
        if (this.getBankerPoint != -1) {
          await new Promise((resolve) => setTimeout(resolve, 1500));
          this._xgetTigerCard(
            require("~public_image/a_each_game/tiger/" +
              this.getBankerPoint +
              ".png")
          );
          this.dtStartDragonCard();
        }
      } catch (exception) {
        //Ignore
      }
    },

    async getBankerCard3() {
      if (this.getBankerCard3 != "0052") {
        this.banker3 = this.getBankerCard3;
        if (this.banker3 && this.banker3 != "0052") {
          await new Promise((resolve) => setTimeout(resolve, 1500));
          const gifUrl1 = "images/a_game_access_global/cards_animation/" + this.banker3 + ".gif";
          this.$set(this, "block03B", gifUrl1);
          this._playSoundOpenCard();
        }
      }
      try {
        if (this.getBankerPoint != -1) {
          await new Promise((resolve) => setTimeout(resolve, 3500));
          this._xgetTigerCard(
            require("~public_image/a_each_game/tiger/" +
              this.getBankerPoint +
              ".png")
          );
          this.dtStartDragonCard();
        }
      } catch (exception) {
        //Ignore
      }
    },

    getFirstCard (newValue) {
      if (newValue != null && this.getFirstCard) {
        this.showFirstCard = true;
        this.dtStopResult();
        this.block01P = this.defaultImage;
        this.block02P = this.defaultImage;
        this.block03P = "";
        this.block01B = this.defaultImage;
        this.block02B = this.defaultImage;
        this.block03B = "";
        var element1P = document.getElementById("block01P");
        var element1B = document.getElementById("block01B");
        var element2P = document.getElementById("block02P");
        var element2B = document.getElementById("block02B");
        element1P.style.display = "none";
        element1B.style.display = "none";
        element2P.style.display = "none";
        element2B.style.display = "none";
        
        setTimeout(() => {
          this.generateFirstCard(1, newValue.barcode);
        }, 1000);
        
      }
    },

    getSecondCard: function (newValue) {

      if (newValue != null && this.getFirstCard) {
        this.generateSecondCards(newValue.count);
      }
    },
    getCard (newValue) {
      if (newValue == true) {
        var element1P = document.getElementById("block01P");
        var element1B = document.getElementById("block01B");
        var element2P = document.getElementById("block02P");
        var element2B = document.getElementById("block02B");
        this.dtStopResult();
        this.block01P = this.defaultImage;
        this.block02P = this.defaultImage;
        this.block03P = "";
        this.block01B = this.defaultImage;
        this.block02B = this.defaultImage;
        this.block03B = "";
        element1P.style.display = "block";
        // this.setPlayerCard("block01P");
        element2P.style.display = "block";
        // this.setPlayerCard("block02P");
        element1B.style.display = "block";
        // this.setPlayerCard("block01B");
        element2B.style.display = "block";
        // this.setPlayerCard("block02B");
      }},
    getBackCard (newValue) {
      if (newValue == true && this.getBackCard) {
        
        var element1P = document.getElementById("block01P");
        var element1B = document.getElementById("block01B");
        var element2P = document.getElementById("block02P");
        var element2B = document.getElementById("block02B");

        setTimeout(() => {
          this.dtStopResult();
          this.block01P = this.defaultImage;
          this.block02P = this.defaultImage;
          this.block03P = "";
          this.block01B = this.defaultImage;
          this.block02B = this.defaultImage;
          this.block03B = "";
          element1P.style.display = "none";
          element1B.style.display = "none";
          element2P.style.display = "none";
          element2B.style.display = "none";

          setTimeout(() => {
            
            element1P.style.display = "block";
            this.setPlayerCard("block01P");
            setTimeout(() => {
              element2P.style.display = "block";
              this.setPlayerCard("block02P");
              setTimeout(() => {
                element1B.style.display = "block";
                this.setPlayerCard("block01B");
                setTimeout(() => {
                  element2B.style.display = "block";
                  this.setPlayerCard("block02B");
                }, 1000);
              }, 2000); // Delay for showing element1B and element2B
            }, 1000);
          }, 1000);  // Delay for showing element1P and element2P
        }, 2000);
      }
    },

    async getClientTime() {
      this.dtStopResult();
    },
  },
};
</script>

<style>
.card-container-top,
.card-container {
  position: absolute;
  /* Center the container horizontally */
  transform: translate(-50%, -50%);
  /* Center the container perfectly */
  display: flex;
  flex-wrap: wrap-reverse;
  /* Reverse the layout */
  justify-content: center;
  gap: 10px;
  max-width: 400px;
  /* Adjust the container width as needed */
}

.card-container-top {
  top: 28%;
  left: 50%;
}

.card-container {
  top: 68%;
  left: 50%;
}

.bowling-card {
  width: 70px;
  height: 100px;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.bowling-card-image {
  width: 100%;
  height: 100%;
  border-radius: 1px;
  /* Maintain the same border radius for image */
  background-image: url("/images/a_game_access_global/cards_animation/b1fv.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  
}

@media screen and (max-width: 1425px) {
  .bowling-card {
    width: 65px;
    height: 90px;
  }

  .card-container-top {
    top: 28%;
  }
}

@media screen and (max-width: 1295px) {
  .bowling-card {
    width: 55px;
    height: 80px;
  }

  .card-container-top {
    top: 28%;
  }
}

@media screen and (max-width: 1295px) {
  .bowling-card {
    width: 45px;
    height: 65px;
  }

  .card-container-top {
    top: 28%;
  }
}

@media screen and (max-width: 959px) {
  .bowling-card {
    width: 25px;
    height: 40px;
  }

  .card-container-top {
    top: 28%;
  }
}

/* Rescale the coins on small screens */
@media screen and (max-width: 868px) {
  .bowling-card {
    width: 25px;
    height: 40px;
  }

  .card-container-top {
    top: 28%;
  }
}

@media screen and (max-width: 868px) {
  .bowling-card {
    width: 20px;
    height: 35px;
  }

  .card-container-top {
    top: 28%;
  }
}

#winnerText {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
  /* Ensures that the text is on top of the cards */
  font-size: 20px;
  /* Adjust the font size as needed */
  color: yellow;
  /* Change the text color to your preference */
  background-color: rgba(0, 0, 0, 0.7);
  /* Add a semi-transparent background for readability */
  padding: 10px 20px;
  /* Add some padding around the text */
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}

:root {
  --translate-x-start: -16%;
  --translate-x-step: 35%;
  --translate-y: 220%;
  --translate-y-mobile: 220%;
  --translate-y-mobile-1880: 220%;
  --translate-y-mobile-866: 220%;
  --translate-y-mobile-848: 220%;
  --translate-y-mobile-750: 220%;
  --translate-y-mobile-660: 220%;
  --translate-y-mobile-600: 220%;
  --translate-y-mobile-575: 220%;
  --translate-y-mobile-450: 220%;
  --translate-y-mobile-436: 220%;
  --translate-y-mobile-390: 220%;
  --translate-y-mobile-345: 220%;
  --translate-y-mobile-310: 220%;
  --translate-y-mobile-285: 220%;
  --translate-y-mobile-260: 220%;
}

.rb-kl-box {
  position: absolute;
  width: 30%;
  height: 35%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0px;
}

.rb-kl-box-ppair {
  transform: translate(var(--translate-x-start), var(--translate-y));
}

.rb-kl-box-player {
  transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
      var(--translate-y));
}

.rb-kl-box-tie {
  transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
      var(--translate-y));
}

.rb-kl-box-banker {
  transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
      var(--translate-y));
}

.rb-kl-box-pbanker {
  transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
      var(--translate-y));
}

.coin-stack {
  position: static;
}

.rb-kl-cion {
  position: absolute;
  left: 40%;
  width: 10%;
  height: 15%;
  background-size: 100% 100%;
  text-align: center;
  z-index: 1;
  /* Default stacking order */
}

.rb-kl-cion-1 {
  top: 40%;
  background-image: url("/images/a_each_game/klaklok/img/Coin/x-coin-1.png");
}

.rb-kl-cion-2 {
  top: 36%;
  background-image: url("/images/a_each_game/klaklok/img/Coin/x-coin-10.png");
}

.rb-kl-cion-3 {
  top: 32%;
  background-image: url("/images/a_each_game/klaklok/img/Coin/x-coin-100.png");
}

@media (max-width: 999px) {
  .blockcards-size {
    max-width: 60px !important;
  }
}

@media (max-width: 959px) {
  .blockcards-size {
    max-width: 40px !important;
  }
}

@media (max-width: 871px) {
  .blockcards-size {
    max-width: 30px !important;
  }
}

/** coins */
@media (max-width: 1880px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-1880));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-1880));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-1880));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-1880));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-1880));
  }
}

@media (max-width: 767px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start), var(--translate-y-mobile));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile));
  }
}

@media (max-width: 866px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-866));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-866));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-866));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-866));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-866));
  }
}

@media (max-width: 848px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-848));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-848));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-848));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-848));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-848));
  }
}

@media (max-width: 750px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-750));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-750));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-750));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-750));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-750));
  }
}

@media (max-width: 660px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-660));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-660));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-660));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-660));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-660));
  }
}

@media (max-width: 600px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-600));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-600));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-600));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-600));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-600));
  }
}

@media (max-width: 575px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-575));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-575));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-575));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-575));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-575));
  }
}

@media (max-width: 450px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-450));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-450));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-450));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-450));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-450));
  }
}

@media (max-width: 436px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-436));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-436));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-436));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-436));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-436));
  }
}

@media (max-width: 390px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-390));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-390));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-390));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-390));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-390));
  }
}

@media (max-width: 345px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-345));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-345));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-345));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-345));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-345));
  }
}

@media (max-width: 310px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-310));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-310));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-310));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-310));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-310));
  }
}

@media (max-width: 285px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-285));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-285));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-285));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-285));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-285));
  }
}

@media (max-width: 260px) {
  .rb-kl-box-ppair {
    transform: translate(var(--translate-x-start),
        var(--translate-y-mobile-260));
  }

  .rb-kl-box-player {
    transform: translate(calc(var(--translate-x-start) + (1.9 * var(--translate-x-step))),
        var(--translate-y-mobile-260));
  }

  .rb-kl-box-tie {
    transform: translate(calc(var(--translate-x-start) + (3.9 * var(--translate-x-step))),
        var(--translate-y-mobile-260));
  }

  .rb-kl-box-banker {
    transform: translate(calc(var(--translate-x-start) + (5.9 * var(--translate-x-step))),
        var(--translate-y-mobile-260));
  }

  .rb-kl-box-pbanker {
    transform: translate(calc(var(--translate-x-start) + (7.8 * var(--translate-x-step))),
        var(--translate-y-mobile-260));
  }
}

#wraper {
  position: relative;
  top: 30%;
}

/* the Div container for the table img */
#tableDiv {
  position: relative;
}

/* the div contain the 5 poker cards on the table */

img {
  width: 85px;
}

/* the class for the 5 cards */
.cards {
  display: inline-block;
}

/* computer block cards from the user */
#blockCards {
  display: flex;
}

#blockCards #block01P #block02P #block03P {
  margin-right: 02px;
  /* Add some spacing between the images (adjust as needed) */
}

@keyframes dealCardAnimation {
  0% {
    transform: translate(100%, -100%) rotate(45deg) scale(0.5);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0) rotate(0) scale(1);
    opacity: 1;
  }
}

.card-animation {
  animation: dealCardAnimation 1s ease forwards;
}
.rx-kl-min-max1 {
  position: absolute;
  top: 1%;
  left: 0.80%;
  width: 16%;
  height: 16.9%;
  background-size: 100% 100%;
}


.rx-kl-min-max1 .rx-kl-min-max-box {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rx-kl-min-max1 .rx-kl-game {
  font-weight: 900;
  color: white;
  text-shadow: 0px .7px rgb(22, 5, 5);
  position: absolute;
  left: 70%;
  top: 38%;
}

.rx-kl-min-max1 .rx-kl-game p {
  font-size: 0.8vw;
}

.rx-kl-min-max1 .rx-kl-min {
  position: absolute;
  top: 38%;
  left: 35%;
  font-weight: 900;
  color: goldenrod;
  text-shadow: 0px .5px rgb(22, 5, 5);
}

.rx-kl-min-max1 .rx-kl-max {
  position: absolute;
  top: 67.5%;
  left: 35%;
  font-weight: 900;
  color: goldenrod;
  text-shadow: 0px .5px rgb(22, 5, 5);

}

.rx-kl-min-max1 .rx-kl-min p {
  font-size: 0.8vw;
}

.rx-kl-min-max1 .rx-kl-max p {
  font-size: 0.8vw;
}

.rx-close-open-box {
  position: absolute;
  right: 2%;
  top: 1%;
  width: 11%;
  height: 19%;
  text-align: center;
  border-radius: 100%;

}

.rx-close-open-box span {
  font-weight: 900;
  font-size: 2.5vw;
  color: white;
  width: 100%;
  text-align: center;
}
</style>