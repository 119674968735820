<template>
    <div class="display-main-result">
        <div class="lottery3d-result-title">
            <p>LO</p>
        </div>

        <v-row class="w-100 mx-0">
            <!-- //Result A    -->
            <column-result :ColumnName="'A'" :result="getLottery3Result_A" :ass="ass_a" :paddingSize="'pr-1'"
                :colorResult="'#f4c905'" :ballImgResult="ballImg(1)"></column-result>
            <!-- //Result B    -->
            <column-result :ColumnName="'B'" :result="getLottery3Result_B" :ass="ass_b" :paddingSize="'px-1'"
                :colorResult="'#1dcb26'" :ballImgResult="ballImg(2)"></column-result>
            <!-- //Result C    -->
            <column-result :ColumnName="'C'" :result="getLottery3Result_C" :ass="ass_c" :paddingSize="'px-1'"
                :colorResult="'#c705d2'" :ballImgResult="ballImg(3)"></column-result>
            <!-- //Result D    -->
            <column-result :ColumnName="'D'" :result="getLottery3Result_D" :ass="ass_d" :paddingSize="'px-1'"
                :colorResult="'#d50201'" :ballImgResult="ballImg(4)"></column-result>
            <!-- //Result E    -->
            <column-result :ColumnName="'E'" :result="getLottery3Result_E" :ass="ass_e" :paddingSize="'pl-1'"
                :colorResult="'#02c3b7'" :ballImgResult="ballImg(5)"></column-result>
        </v-row>

    </div>
</template>

<script>


import { mapGetters } from "vuex";

import Mixin from "~mixin";
import ColumnResult from './ColumnResult.vue';
export default {
    components: { ColumnResult },
    mixins: [Mixin],
    // DEFINE local VARIABLE

    props: ['ass_a', 'ass_b', 'ass_c', "ass_d", 'ass_e'],
    data() {
        return {


        }
    },
    computed: {

        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_lottery3d", {
            getLastOneResult: 'getLastOneResult',
            getLottery3Output: 'getLottery3Output',
            getLottery3Result_A: "getLottery3Result_A",
            getLottery3Result_B: "getLottery3Result_B",
            getLottery3Result_C: "getLottery3Result_C",
            getLottery3Result_D: "getLottery3Result_D",
            getLottery3Result_E: "getLottery3Result_E",
        }),

    },
    watch: {
        getLastOneResult() { },
    },
    methods: {
        _passResultData(data, id) {
            switch (true) {
                case (id == 1 && data != null && data.result_a != null): return data.result_a;
                case (id == 2 && data != null && data.result_b != null): return data.result_b;
                case (id == 3 && data != null && data.result_c != null): return data.result_c;
                case (id == 4 && data != null && data.result_d != null): return data.result_d;
                case (id == 5 && data != null && data.result_e != null): return data.result_e;
                default: return this.defaultLottery3dResults;
            }
        },

        ballImg(id) {
            switch (id) {
                case 1: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_yellow.png');
                case 2: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_green.png');
                case 3: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_pink.png');
                case 4: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_red.png');
                case 5: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_sea.png');
                case 6: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_white.png');
            }
        },

        _goToDetail(data) {
            this.selectedItem = data;
            this.invoice_id = data.invoice_no;
            this.loadDetailData(data.invoice_no);
            this.dialog = true;
        },
        updatePage() {
            this.getLoadingDetail = true;
            this.loadDetailData(this.invoice_id);
            this.getLoadingDetail = false;
        },
        async loadDetailData(_id) {
            this.detailData = [];
            this.getLoadingDetail = true;
            this.dataRequest.sortByFormat.length < 1
                ? (this.dataRequest.sortBy = "_id._id")
                : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
            this.dataRequest.descendingFormat.length > 0
                ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
                : (this.dataRequest.descending = true);

            const response = await this.$request.getSlot({
                url: 'm_lottery/report/detail?module_bet_id=' + _id
            });
            if (response.data.code) {
                this.detailData = response.data.data;
                this.getLoadingDetail = false;
            }
        },
        printTicket(item, ticket_id) {
            this.items = item;
            this.ticket_id = ticket_id;
            this.dialogPrintTicket = true;
        },
        printTicket2(item) {
            // console.log('@item:',item);
            this.items = item;
            this.betALength = item.bet_a.number.length
            this.betBLength = item.bet_b.number.length
            this.betCLength = item.bet_c.number.length
            this.betDLength = item.bet_d.number.length
            this.betELength = item.bet_e.number.length
            this.labelA = item.bet_a.label
            this.labelB = item.bet_b.label
            this.labelC = item.bet_c.label
            this.labelD = item.bet_d.label
            this.labelE = item.bet_e.label
            this.dialogPrintTicket2 = true;
        },
        print() {
            this.$htmlToPaper("printMe");
        },
        closePrintDialog() {
            this.dialogPrintTicket = false;
        },
        openPrintMultiple() {
            if (this.selectedAddOns != "") {
                this.dialogPrintTicketMulti = true;
            }
        },
        printMulti() {
            this.$htmlToPaper("printMeMultiTicket");
        },
        closePrintMultiDialog() {
            var inputs = document.getElementsByClassName("myCheck");
            for (var i = 0, l = inputs.length; i < l; ++i) {
                if (inputs[i].checked) {
                    inputs[i].checked = false;
                }
            }
            this.selectedAddOns = [];
            this.dialogPrintTicketMulti = false;
        },

    },
};
</script>