<template>
  <div>
    <div class="lottery-menu-nav-head">
      <MainMenu/>
    </div>
  <v-row class="ma-0">
    <LeftBlock
      :passChannelStatus="passChannelStatus"
      :passSelectedChannel="passSelectedChannel"
      v-on:sentSetChannel="_switchChannel"/>
    <div class="flexi-right px-0 px-sm-0 px-md-0 pt-0"  style="background-color: white;border-left: 3px solid black;">
      <div class="card mx-0 right-block-container pt-0 ma-0" style="border:0; border-radius: 0!important">
        <!-- <ChannelList /> -->
        <Cockfight
          v-if="_selectedGame(channelId) == 'cock'"
          :passBettingTypeMaster="passBettingTypeMaster"
          v-on:sentSubmitBetting="receiveSubmitBetting"
        />
        <Klaklouk
          v-if="_selectedGame(channelId) == 'klaklouk'"
          :passBettingTypeMaster="passBettingTypeMaster"
          v-on:sentSubmitBetting="receiveSubmitBetting"
        />
        <Han2BettingComponent
        v-if="_selectedGame(channelId) == 'han2'"
        :passBettingTypeMaster="passBettingTypeMaster"
        v-on:sentSubmitBetting="receiveSubmitBetting"
      />
      <Han3BettingComponent
        v-if="_selectedGame(channelId) == 'han3'"
        :passBettingTypeMaster="passBettingTypeMaster"
        v-on:sentSubmitBetting="receiveSubmitBetting"
      />
      <TigerDragonBettingComponent
      v-if="_selectedGame(channelId) == 'tiger-dragon'"
      :passBettingTypeMaster="passBettingTypeMaster"
      v-on:sentSubmitBetting="receiveSubmitBetting"
    />
      </div>
    </div>
  </v-row>
</div>
</template>
<script>
import MainMenu from "../../../../../components/MainMenu.vue";


import { mapGetters } from "vuex";
import LeftBlock from "./LeftBlock.vue";
import Cockfight from "./BettingComponents/Cockfight.vue";
import Klaklouk from "./BettingComponents/Klaklouk.vue";
import Han2BettingComponent from "./BettingComponents/Han2BettingComponent.vue";
import TigerDragonBettingComponent from "./BettingComponents/TigerDragonBettingComponent.vue";
import Han3BettingComponent from "./BettingComponents/Han3BettingComponent.vue";
// import ChannelList from "../../../../components/ChannelList.vue";
import globalFunction from "../../../../../mixin/MX_ShareFunctionCollection.js";

export default {
  // components: { VideoLiveForDesktop, TableResult, BettingComponent, ReportBetting, ResultVideoApong },
  components: {TigerDragonBettingComponent, LeftBlock, Cockfight, Klaklouk, Han2BettingComponent, Han3BettingComponent, MainMenu },
  props: [
    "passChannel",
    "passChannelStatus",
    "passResult",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passTodayReport",
    "passLoadingTodayReport",
    "passChannelList",
    "passSelectedChannel",
    "passCurrentBetting",
    "passNewChannelList",
    "passChannelType",
    "passUserCurrency",
  ],
  mixins: [globalFunction],
  data() {
    return {
      logoWebsite: "../../../../../public/images/asiancockfighting-word.jpg",
  
    };
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channelObj: "getChannel",
    }),
    ...mapGetters("$_modules", {
            channelId: "getChannelId",
        }),
  },
  methods: {
    _clearMoney() {
      this.$emit("handleClearBtn");
    },
    receiveSubmitBetting(recieveBettingData) {
      this.$emit("sentSubmitBetting", recieveBettingData);
    },
    _switchChannel(selectedChannel) {
      this.$emit("sentSetChannel", selectedChannel);
    },
  },
};
</script>

<style>
.btn-switch-channel:has(.to-hidden) {
  display: none !important;
}

.video-section {
  position: relative;
  overflow: hidden;
  background-color: #012d98
}
</style>