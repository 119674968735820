<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0">
        <ChannelInfo
            :passChannel="passChannel"
        />
        <div>
            <div class="g-summary pb-3">
                <div class="sides-wrapper gp-panel-bg rounded" style="padding: 2px 6%;"
                    :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                    <div class="d-flex sides px-2 justify-center w-100">
                    </div>
                    <v-row style="padding:14px 12px; position:relative;top:0px; margin-bottom: -40px;">
                        <v-col md="3" lg="3" sm="3" style="background-color:#013366;border:1px solid #ffffff;padding:0">
                            <div :class="selectedBtnClassTwoThree ? 'fantan-range-betting-container-active' : ''"
                                @click="_allowBtnBet('selectedTwoThree')">
                                <svg viewBox='5 5 140 141'>
                                    <a href='#'>
                                        <polygon style="fill:#00aa5c;" points='5,5 50,50 95,5' />
                                        <text x="48" y="30" text-anchor="middle" fill="black" font-size="24"
                                            font-weight="bolder">2</text>
                                    </a>
                                    <a href='#'>
                                        <polygon style="fill:#ffe030;" points='5,5 50,50 5,95' />
                                        <text x="22" y="53" text-anchor="middle" fill="black" font-size="24"
                                            font-weight="bolder">3</text>
                                    </a>
                                    <!-- <text x="72" y="95" text-anchor="middle" fill="white" font-size="20">{{twoThreeButton.desc}}</text> -->
                                    <text x="72" y="95" text-anchor="middle" fill="white" font-size="24"
                                        font-weight="bolder">{{ twoThreeButton.payout_display }}</text>
                                </svg>
                            </div>
                        </v-col>
                        <v-col md="3" lg="3" sm="3" style="background-color:#013366;border:1px solid #ffffff;padding:0">
                            <div :class="selectedBtnClassOneTwo ? 'fantan-range-betting-container-active' : ''"
                                @click="_allowBtnBet('selectedOneTwo')">
                               
                                <svg viewBox='-42 5 140 141'>
                                    <a href='#'>
                                        <polygon style="fill:#00aa5c;" points='5,5 50,50 95,5' />
                                        <text x="48" y="30" text-anchor="middle" fill="black" font-size="24"
                                            font-weight="bolder">2</text>
                                    </a>
                                    <a href='#'>
                                        <polygon style="fill:#ffffff;" points='98, 0 50,50 100,100' />
                                        <text x="78" y="55" text-anchor="middle" fill="black" font-size="24"
                                            font-weight="bolder">1</text>
                                    </a>
                                    <!-- <text x="22" y="95" text-anchor="middle" fill="white" font-size="20">{{oneTwoButton.desc}}</text> -->
                                    <text x="22" y="95" text-anchor="middle" fill="white"
                                        font-size="24">{{ oneTwoButton.payout_display }}</text>
                                </svg>
                            </div>
                        </v-col>
                        <!--  -->
                        <v-col md="3" lg="3" sm="3" style="background-color:#013366; border:1px solid #ffffff;padding:0">
                            <div :class="selectedBtnClassOneFour ? 'fantan-range-betting-container-active' : ''"
                                @click="_allowBtnBet('selectedOneFour')">
                                <svg viewBox='5 5 140 141'>
                                    <a href='#'>
                                        <polygon style="fill:#d43839;" points='0,5 50,50 100,5' />
                                        <text x="48" y="30" text-anchor="middle" fill="black" font-size="24"
                                            font-weight="bolder">4</text>
                                    </a>
                                    <a href='#'>
                                        <polygon style="fill:#ffffff;" points='5,5 50,50 5,100' />
                                        <text x="22" y="55" text-anchor="middle" fill="black" font-size="24"
                                            font-weight="bolder">1</text>
                                    </a>
                                    <!-- <text x="72" y="95" text-anchor="middle" fill="white" font-size="20"> {{oneFourButton.desc}}</text> -->
                                    <text x="72" y="95" text-anchor="middle" fill="white"
                                        font-size="24">{{ oneFourButton.payout_display }}</text>
                                </svg>
                            </div>
                        </v-col>
                        <!--  -->
                        <v-col md="3" lg="3" sm="3" style="background-color:#013366;border:1px solid #ffffff;padding:0">
                            <div :class="selectedBtnClassThreeFour ? 'fantan-range-betting-container-active' : ''"
                                @click="_allowBtnBet('selectedThreeFour')">
                                <svg viewBox='-42 5 140 141'>
                                    <a href='#'>
                                        <polygon style="fill:#ffe030;" points='5,5 50,50 100,5' />
                                        <text x="48" y="30" text-anchor="middle" fill="black" font-size="24"
                                            font-weight="bolder">3</text>
                                    </a>
                                    <a href='#'>
                                        <polygon style="fill:#d43839;" points='100,5 50,50 100,100' />
                                        <text x="78" y="55" text-anchor="middle" fill="black" font-size="24"
                                            font-weight="bolder">4</text>
                                    </a>
                                    <!-- <text x="22" y="95" text-anchor="middle" fill="white" font-size="20">{{threeFourButton.desc}}</text> -->
                                    <text x="22" y="95" text-anchor="middle" fill="white"
                                        font-size="24">{{ threeFourButton.payout_display }}</text>
                                </svg>
                            </div>
                        </v-col>
                    </v-row>
                    <!-- bet2 -->
                    <v-row style="padding:14px 12px; position:relative; top:0px; margin-bottom: -50px;">
                        <v-col md="2" lg="2" sm="2"
                            :class="selectedBtnClassOdd ? 'fantan-range-betting-container-active' : 'fantan-range-betting-odd'"
                            @click="_allowBtnBet('selectedOdd')">
                            <div style="position:relative; top:30%;text-align: center;">
                                <h6>{{ oddButton.desc }}</h6>
                                {{ oddButton.payout_display }}
                            </div>
                        </v-col>
                        <!-- v-if="bettingMoney > 0 && selectedBtnClassTwo" -->
                        <v-col md="8" lg="8" sm="8" style="padding:0">
                            <div style="padding: 2px; margin-bottom: 10px;">
                                <svg viewBox='5 5 90 64'>
                                    <a @click="_allowBtnBet('selectedTwo')">
                                        <polygon points='5,5 50,40 95,5'
                                            :class="selectedBtnClassTwo ? 'svg-background-active' : 'svg-background'" />
                                        <text x="48" y="15" text-anchor="middle"
                                            :class="selectedBtnClassTwo ? 'text-background-active' : 'text-background'">Fan
                                            2</text>
                                        <text x="48" y="27" text-anchor="middle"
                                            :class="selectedBtnClassTwo ? 'text-background-active' : 'text-background'">1:{{ twoButton.payout_display }}</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedThree')">
                                        <polygon style="fill:#ffe030;" points='5,5 50,40 5,75'
                                            :class="selectedBtnClassThree ? 'svg-background-active' : 'svg-background'" />
                                        <text x="22" y="35" text-anchor="middle"
                                            :class="selectedBtnClassThree ? 'text-background-active' : 'text-background'">Fan
                                            3</text>
                                        <text x="22" y="45" text-anchor="middle"
                                            :class="selectedBtnClassThree ? 'text-background-active' : 'text-background'">1:{{ threeButton.payout_display }}</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedFour')">
                                        <polygon style="fill:#d43839;" points='3,70 50,40 140,95'
                                            :class="selectedBtnClassFour ? 'svg-background-active' : 'svg-background'" />
                                        <text x="50" y="55" text-anchor="middle"
                                            :class="selectedBtnClassFour ? 'text-background-active' : 'text-background'">Fan
                                            4</text>
                                        <text x="50" y="65" text-anchor="middle"
                                            :class="selectedBtnClassFour ? 'text-background-active' : 'text-background'">1:{{ fourButton.payout_display }}</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedOne')">
                                        <polygon style="fill:#ffffff;" points='95,5 50,40 95,68'
                                            :class="selectedBtnClassOne ? 'svg-background-active' : 'svg-background'" />
                                        <text x="77" y="35" text-anchor="middle"
                                            :class="selectedBtnClassOne ? 'text-background-active' : 'text-background'">Fan
                                            1</text>
                                        <text x="77" y="45" text-anchor="middle"
                                            :class="selectedBtnClassOne ? 'text-background-active' : 'text-background'">1:{{ oneButton.payout_display }}</text>
                                    </a>
                                </svg>
                            </div>
                        </v-col>
                        <v-col md="2" lg="2" sm="2"
                            :class="selectedBtnClassEven ? 'fantan-range-betting-container-active' : 'fantan-range-betting-even'"
                            @click="_allowBtnBet('selectedEven')">
                            <div style="position:relative; top:30%;text-align: center;">
                                <h6>{{ evenButton.desc }}</h6>
                                {{ evenButton.payout_display }}
                            </div>
                        </v-col>
                    </v-row>
                    <!-- bet3 -->
                    <v-row style="padding:14px 12px; position:relative;top:0px;margin-bottom: -27px;">
                        <v-col md="3" lg="3" sm="3" style="background-color:#013366;border:1px solid #ffffff;padding:0">
                            <div :class="selectedBtnClassOneTwoThree ? 'fantan-range-betting-container-active' : ''"
                                @click="_allowBtnBet('selectedOneTwoThree')">
                                <svg viewBox='-50 -55 200 201'>
                                    <text x="52" y="-15" text-anchor="middle" fill="white"
                                        font-size="30">{{ oneTwoThreeButton.desc }}</text>
                                    <a>
                                        <polygon style="fill:#ffffff;" points='95,5 50,50 95,95' />
                                        <text x="78" y="55" text-anchor="middle" fill="black" font-size="18">1</text>
                                    </a>
                                    <a>
                                        <polygon style="fill:#00aa5c;" points='5,5 50,50 95,5' />
                                        <text x="48" y="30" text-anchor="middle" fill="black" font-size="18">2</text>
                                    </a>
                                    <a>
                                        <polygon style="fill:#ffe030;" points='5,5 50,50 5,95' />
                                        <text x="22" y="53" text-anchor="middle" fill="black" font-size="18">3</text>
                                    </a>
                                    <text x="52" y="135" text-anchor="middle" fill="white"
                                        font-size="30">{{ oneTwoThreeButton.payout_display }}</text>
                                </svg>
                            </div>
                        </v-col>

                        <v-col md="3" lg="3" sm="3" style="background-color:#013366;border:1px solid #ffffff;padding:0">
                            <div :class="selectedBtnClassTwoThreeFour ? 'fantan-range-betting-container-active' : ''"
                                @click="_allowBtnBet('selectedTwoThreeFour')">
                                <svg viewBox='-50 -55 200 201'>
                                    <text x="52" y="-15" text-anchor="middle" fill="white"
                                        font-size="30">{{ twoThreeFourButton.desc }}</text>
                                    <a @click="_allowBtnBet('selectedTwo')">
                                        <polygon style="fill:#00aa5c;" points='5,5 50,50 95,5' />
                                        <text x="48" y="30" text-anchor="middle" fill="black" font-size="18">2</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedThree')">
                                        <polygon style="fill:#ffe030;" points='5,5 50,50 5,95' />
                                        <text x="22" y="53" text-anchor="middle" fill="black" font-size="18">3</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedFour')">
                                        <polygon style="fill:#d43839;" points='5,95 50,50 95,95' />
                                        <text x="50" y="85" text-anchor="middle" fill="black" font-size="18">4</text>
                                    </a>
                                    <text x="52" y="135" text-anchor="middle" fill="white"
                                        font-size="30">{{ twoThreeFourButton.payout_display }}</text>
                                </svg>
                            </div>
                        </v-col>
                        <!--  -->
                        <v-col md="3" lg="3" sm="3" style="background-color:#013366;border:1px solid #ffffff;padding:0">
                            <div :class="selectedBtnClassOneTwoFour ? 'fantan-range-betting-container-active' : ''"
                                @click="_allowBtnBet('selectedOneTwoFour')">
                                <svg viewBox='-50 -55 200 201'>
                                    <text x="52" y="-15" text-anchor="middle" fill="white"
                                        font-size="30">{{ oneTwoFourButton.desc }}</text>
                                    <a @click="_allowBtnBet('selectedTwo')">
                                        <polygon style="fill:#00aa5c;" points='5,5 50,50 95,5' />
                                        <text x="48" y="30" text-anchor="middle" fill="black" font-size="18">2</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedOne')">
                                        <polygon style="fill:#ffffff;" points='95,5 50,50 95,95' />
                                        <text x="78" y="55" text-anchor="middle" fill="black" font-size="18">1</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedFour')">
                                        <polygon style="fill:#d43839;" points='5,95 50,50 95,95' />
                                        <text x="50" y="85" text-anchor="middle" fill="black" font-size="18">4</text>
                                    </a>
                                    <text x="52" y="135" text-anchor="middle" fill="white"
                                        font-size="30">{{ oneTwoFourButton.payout_display }}</text>
                                </svg>
                            </div>
                        </v-col>

                        <!--  -->
                        <v-col md="3" lg="3" sm="3" style="background-color:#013366;border:1px solid #ffffff;padding:0">
                            <div :class="selectedBtnClassOneThreeFour ? 'fantan-range-betting-container-active' : ''"
                                @click="_allowBtnBet('selectedOneThreeFour')">
                                <svg viewBox='-50 -55 200 201'>
                                    <text x="52" y="-15" text-anchor="middle" fill="white"
                                        font-size="30">{{ oneThreeFourButton.desc }}</text>
                                    <a @click="_allowBtnBet('selectedOne')">
                                        <polygon style="fill:#ffffff;" points='95,5 50,50 95,95' />
                                        <text x="78" y="55" text-anchor="middle" fill="black" font-size="18">1</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedThree')">
                                        <polygon style="fill:#ffe030;" points='5,5 50,50 5,95' />
                                        <text x="22" y="53" text-anchor="middle" fill="black" font-size="18">3</text>
                                    </a>
                                    <a @click="_allowBtnBet('selectedFour')">
                                        <polygon style="fill:#d43839;" points='5,95 50,50 95,95' />
                                        <text x="50" y="85" text-anchor="middle" fill="black" font-size="18">4</text>
                                    </a>
                                    <text x="52" y="135" text-anchor="middle" fill="white"
                                        font-size="30">{{ oneThreeFourButton.payout_display }}</text>
                                </svg>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <CoinButton 
            :passPlaceCoin="bettingMoney"
            :passGetButton="passGetButton" :passChannel="passChannel" v-on:handleInputMonay="_inputAmount" />
            <SubmitBtn  
            :passCheckAllowSubmit="_checkAllowSubmit"
            v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney"/>
            <LimitBetting/>
        </div>
    </div>
</template>

<script>

import "@/assets/css/homepage.css";
import CoinButton from '../../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../../components/ChannelInfoLive.vue';
import LimitBetting from '../../../../../components/LimitBettingLive.vue';
import SubmitBtn from '../../../../../components/SubmitBtn.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn},
    props: ["passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel'
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            valueTiger: "",
            resultTiger: '',
            valueDragon: '',
            resultDragon: '',
            countDown: {},
            selectedBtnClassSmall: false,
            selectedBtnClassTie: false,
            selectedBtnClasslarge: false,
            selectedBtnClassOne: false,
            selectedBtnClassTwo: false,
            selectedBtnClassThree: false,
            selectedBtnClassFour: false,
            selectedBtnClassOdd: false,
            selectedBtnClassEven: false,
            selectedBtnClassTwoThree: false,
            selectedBtnClassOneTwo: false,
            selectedBtnClassOneFour: false,
            selectedBtnClassThreeFour: false,
            selectedBtnClassOneTwoThree: false,
            selectedBtnClassTwoThreeFour: false,
            selectedBtnClassOneTwoFour: false,
            selectedBtnClassOneThreeFour: false,
            selectedCoin: false

        }

    },
    computed: {
        _checkAllowSubmit() {
            if (  this.bettingMoney> 0 && 
                (this.selectedBtnClassFive || this.selectedBtnClassFour 
                || this.selectedBtnClassThree || this.selectedBtnClassTwo || this.selectedBtnClassOne || this.selectedBtnClassSmall 
                || this.selectedBtnClasslarge || this.selectedBtnClassEven || this.selectedBtnClassOdd
                || this.selectedBtnClassTwoThree || this.selectedBtnClassOneTwo || this.selectedBtnClassOneFour || this.selectedBtnClassThreeFour
                || this.selectedBtnClassOneTwoThree || this.selectedBtnClassTwoThreeFour || this.selectedBtnClassOneTwoFour || this.selectedBtnClassOneThreeFour)
                ) return true;
            else  return false;
        },
        oneButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        twoButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        threeButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        fourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[3]
            else return {}
        },
        oneTwoButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[4]
            else return {}
        },
        oddButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[5]
            else return {}
        },
        evenButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[6]
            else return {}
        },
        oneFourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[7]
            else return {}
        },
        twoThreeButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[8]
            else return {}
        },
        threeFourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[9]
            else return {}
        },
        oneTwoThreeButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[10]
            else return {}
        },
        oneThreeFourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[11]
            else return {}
        },
        twoThreeFourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[12]
            else return {}
        },
        oneTwoFourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[13]
            else return {}
        },
        ...mapGetters("$_casino_live", {
            dialogBetting: "getDialogBetting",
            amountMin: "getAmountMin",
            amountMax: "getAmountMax",
            getCountdown: "getCountdown"
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },

    methods: {
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <= 999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <= 99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
        formatCoinCurrency(amount) {
            switch (amount) {
                case 5000:
                    return '5ព';
                case 10000:
                    return '1ម';
                case 20000:
                    return '2ម';
                case 50000:
                    return '5ម';
                case 100000:
                    return '10ម';
                case 200000:
                    return '20ម';
                default:
                    return amount;
            }

        },
        checkSattus() {
            if (this.passChannel.is_open == null) {
                return "Not_Open"
            } else {
                if (this.passChannel.is_open) {
                    return "Open"
                }
                return "Close"
            }
        },
        checkStatus() {
            if (this.passChannel.is_open == null) {
                return "text-warning"
            } else {
                if (this.passChannel.is_open) {
                    return "text-success"
                }
                return "text-danger"
            }
        },
        _detectCoin(index) {
            switch (index) {
                case 0:
                    return '/images/coins/blue.png';
                case 1:
                    return '/images/coins/pink.png';
                case 2:
                    return '/images/coins/cyan.png';
                case 3:
                    return '/images/coins/red.png';
                case 4:
                    return '/images/coins/ofantan-range.png';
                case 5:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinlarge(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/ofantan-range.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorlarge(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectColor(index) {
            switch (index) {
                case 0:
                    return 'black';
                case 1:
                    return 'black';
                case 2:
                    return 'black';
                case 3:
                    return 'black';
                case 4:
                    return 'black';
                case 5:
                    return 'black';
                default:
                    return ''
            }
        },
        _inputAmount(money) {
                this.selectedCoin = true
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)
        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassSmall = false;
            this.selectedBtnClassOne = false;
            this.selectedBtnClasslarge = false;
            this.selectedBtnClassTwo = false;
            this.selectedBtnClassThree = false;
            this.selectedBtnClassFour = false;
            this.selectedBtnClassFive = false;
            this.selectedBtnClassSix = false;
            this.selectedBtnClassOdd = false;
            this.selectedBtnClassEven = false;
            this.selectedBtnClassTwoThree = false;
            this.selectedBtnClassOneTwo = false;
            this.selectedBtnClassOneFour = false;
            this.selectedBtnClassThreeFour = false;
            this.selectedBtnClassOneTwoThree = false;
            this.selectedBtnClassTwoThreeFour = false;
            this.selectedBtnClassOneTwoFour = false;
            this.selectedBtnClassOneThreeFour = false;
            this.selectedCoin = false;
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.selectedCoin) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedOne':
                    this.selectedButton = this.oneButton;
                    this.selectedBtnClassOne = true;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedTwo':
                    this.selectedButton = this.twoButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = true;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedThree':
                    this.selectedButton = this.threeButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = true;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedFour':
                    this.selectedButton = this.fourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = true;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedOdd':
                    this.selectedButton = this.oddButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = true;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedEven':
                    this.selectedButton = this.evenButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = true;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedTwoThree':
                    this.selectedButton = this.twoThreeButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = true;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedOneTwo':
                    this.selectedButton = this.oneTwoButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = true;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedOneFour':
                    this.selectedButton = this.oneFourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = true;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedThreeFour':
                    this.selectedButton = this.threeFourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = true;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedOneTwoThree':
                    this.selectedButton = this.oneTwoThreeButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = true;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedTwoThreeFour':
                    this.selectedButton = this.twoThreeFourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = true;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedOneTwoFour':
                    this.selectedButton = this.oneTwoFourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = true;
                    this.selectedBtnClassOneThreeFour = false;
                    break;
                case 'selectedOneThreeFour':
                    this.selectedButton = this.oneThreeFourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassOneTwo = false;
                    this.selectedBtnClassOneFour = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassOneTwoThree = false;
                    this.selectedBtnClassTwoThreeFour = false;
                    this.selectedBtnClassOneTwoFour = false;
                    this.selectedBtnClassOneThreeFour = true;
                    break;
            }
        },
        ...mapMutations("$_casino_live", [
            "BETTING_DIALOG",
            "UPDATE_ABONG_CARD",
            "RESET_ABONG_CARD"
        ]),
    }
}
</script>
<style scoped>
@media(min-width:960px) {
    .money-in-coin-fantan-range-position {
        margin-top: -34px;
        margin-bottom: 14px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .betting-coin-fantan-range {
        width: 50px;
        height: auto;
        margin: auto;
        /* margin-top: -50px; */
        z-index: 1000 important
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .betting-coin-small {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-large {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -29.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 70px;
    }
}

@media(min-width:1280px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .betting-coin-small {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-fantan-range {
        width: 50px;
        height: auto;
        margin: auto;
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
    }

    .money-in-coin-fantan-range-position {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 7px;
        left: -8px;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }
}

.open-betting-countdown {
    background-color: #def3f6;
    font-size: 22px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}

.fantan-range-betting-container {
    width: 18%;
    height: 90px;
    border-radius: 5px;
    background-color: rgb(0, 102, 153, .5);
    border: 1px solid rgb(218, 165, 32);
    padding: 0px;
    position: relative;
}

.fantan-range-betting-container-active {
    
    transform: scale(1);
    padding: 0px;
    position: relative;
    width:100%;
    height: auto;
    border:0px;
}

.fantan-range-betting-container:hover {
    cursor: pointer;
}

.fantan-range-betting-header {
    width: 100%;
    height: 66%;
    padding-top: 10px;
    background-color: rgba(32, 218, 88, 0.2);
    border-bottom: 3px solid rgb(218, 165, 32);
    color: lightgoldenrodyellow;
    text-shadow: 1px 0px black;
    padding-top: 0px;
    font-weight: 700;
    font-size: 27px;
}

.fantan-range-betting-payout {
    text-align: center;
    padding-top: 10px
}

.img-coin-fantan-range {
    width: 100%;
    display: grid;
    margin-top: -70px
}

.fantan-range-betting-odd {
    background-color: #bf1a00;
    border-left: 1px solid white;
}

.fantan-range-betting-even {
    background-color: #0d599c;
    border-right: 1px solid white;
}

.svg-background {
    fill: #28a745;
}

.svg-background-active {
    fill: goldenrod !important;
}

.text-background-active {
    fill: black !important;
    text-shadow: 1px 1px white;
    font-size: 8px;
    font-weight: bolder;
}

.text-background {
    fill: black !important;
    font-size: 6px;
}

.img-coin-fantan-range {
    position: absolute;
    top: 105px;
}

.betting-coin-odd {
    position: absolute;
    top: 50%;
    left: 11%;
    transform: translate(0%, -50%);
    width: 80%;
    display: grid;
}

.money-in-coin-odd-position {
    color: #212427;
    text-align: center;
    z-index: 100;
    font-weight: 900;
    text-align: center;
    font-size: 12px;
    text-shadow: 1px 0px black;
    padding: 70px 0;
}

.betting-coin-n1 {
    position: absolute;
    top: 50%;
    right: 7%;
    transform: translate(0%, 95%);
    width: 20%;
    display: grid;

}

.betting-coin-mn-n1 {
    position: absolute;
    color: #212427;
    top: 0%;
    right: -33%;
    transform: translate(0%, 431%);
    z-index: 100;
    font-weight: 900;
    width: 100%;
    text-align: center;
    font-size: 12px;
    text-shadow: 1px 0px black;

}

.betting-coin-n2 {
    position: absolute;
    top: 50%;
    left: 39%;
    transform: translate(0%, 5%);
    width: 20%;
    display: grid;

}

.betting-coin-mn-n2 {
    position: absolute;
    color: #212427;
    top: 0%;
    left: -1%;
    transform: translate(0%, 140%);
    z-index: 100;
    font-weight: 900;
    width: 100%;
    text-align: center;
    font-size: 12px;
    text-shadow: 1px 0px black;
}


.betting-coin-n3 {
    position: absolute;
    top: 100%;
    left: 39%;
    transform: translate(0%, 205%);
    width: 20%;
    display: grid;

}

.betting-coin-mn-n3 {
    position: absolute;
    color: #212427;
    top: 0%;
    left: -1.2%;
    transform: translate(0%, 820%);
    z-index: 100;
    font-weight: 900;
    width: 100%;
    text-align: center;
    font-size: 12px;
    text-shadow: 1px 0px black;
}


.betting-coin-n4 {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translate(0%, 95%);
    width: 20%;
    display: grid;

}

.betting-coin-mn-n4 {
    position: absolute;
    color: #212427;
    top: 0%;
    left: -33%;
    transform: translate(0%, 445%);
    z-index: 100;
    font-weight: 900;
    width: 100%;
    text-align: center;
    font-size: 12px;
    text-shadow: 1px 0px black;

}

svg:hover {
    cursor: pointer;
}

.fantan-range-betting-odd:hover {
    cursor: pointer;
}

</style>