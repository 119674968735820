<template>

  <div class="sides-wrapper gp-panel-bg rounded">
    <div class="d-flex sides casino-live-bet-btn-container">
      <div v-if="meronButton" class="side-section meron-section pb-1 side-1 position-relative" :class="[
        getCasinoLiveSeletedBtn.selectedBtnClassMeron ? 'on-active-btn' : '',
        _findButtonSection(passSelectedChannel, 'left'),
      ]" @click="_allowBtnBet('selectedMeron')">
        <div class="pad-cock-btn" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
          <span style="font-weight: 600">
            {{
        _displayMoney(
          userInfo.currency_type,
          passSelectedChannel,
          passCurrentBetting,
          "left"
        )
      }}
          </span>

          <div class="odds" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
            {{ _displayPayout(passSelectedChannel, "left") }}
          </div>
          <div class="side-title" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
            <div class="tiger-dragon-betting-label">
              {{
        meronButton
          ? $t(
            sameGameFindName(passSelectedChannel) +
            meronButton.name
          )
          : ""
      }}
            </div>
          </div>
        </div>
        <img v-if="getSwitchChannelLoading" :src="require('~public_image/loading_rolling.gif')"
          class="rolling-loading-img" />
      </div>
      <div v-if="tieButton" class="side-section-tie pb-1 mx-2 side-1 position-relative" :class="[
        getCasinoLiveSeletedBtn.selectedBtnClassTie ? 'on-active-btn' : '',
        _findButtonSection(passSelectedChannel, 'middle'),
      ]" @click="_allowBtnBet('selectedTie')">
        <div class="pad-cock-btn" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
          <span style="font-weight: 600">{{
        _displayMoney(
          userInfo.currency_type,
          passSelectedChannel,
          passCurrentBetting,
          "middle"
        )
      }}</span>

          <div class="odds" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
            {{
        passWeight
          ? _displayPayout(passSelectedChannel, "middle")
          : "x.xx"
      }}
          </div>
          <div class="side-title" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
            <div class="tiger-dragon-betting-label">
              {{ $t(sameGameFindName(passSelectedChannel) + "draw") }}
            </div>
          </div>

        </div>
        <img v-if="getSwitchChannelLoading" :src="require('~public_image/loading_rolling.gif')"
          class="rolling-loading-img" />
      </div>
      <div v-if="walaButton" class="side-section pb-1 side-1 position-relative" :class="[
        getCasinoLiveSeletedBtn.selectedBtnClassWala ? 'on-active-btn' : '',
        _findButtonSection(passSelectedChannel, 'right'),
      ]" @click="_allowBtnBet('selectedWala')">
        <div class="pad-cock-btn" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
          <span style="font-weight: 600">{{
        _displayMoney(
          userInfo.currency_type,
          passSelectedChannel,
          passCurrentBetting,
          "right"
        )
      }}</span>
          <div class="odds" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
            {{ _displayPayout(passSelectedChannel, "right") }}
          </div>
          <div class="side-title" :style="getSwitchChannelLoading ? 'opacity:.1;' : ''">
            <div class="tiger-dragon-betting-label">
              {{
        walaButton
          ? $t(
            sameGameFindName(passSelectedChannel) +
            walaButton.name
          )
          : ""
      }}
            </div>
          </div>
        </div>
        <img v-if="getSwitchChannelLoading" :src="require('~public_image/loading_rolling.gif')"
          class="rolling-loading-img" />
      </div>
    </div>
  </div>

</template>

<script>
import "~css/casino_live/main_casino_live/tiger_dragon_live/channel_live_share.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/max_480.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/min_481.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/min_768.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/min_993.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/min_1025.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/min_1281.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/min_1441.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/min_1661.css";
import "~css/casino_live/main_casino_live/tiger_dragon_live/min_1824.css";


import { mapGetters, mapMutations } from "vuex";
export default {

  props: [
    "passChannel",
    "passCurrentBetting",
    "passChannelStatus",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passSelectedChannel",
  ],
  data() {
    return {
      selectedButton: {},
      bettingMoney: 0,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedCoin: false,
    };
  },
  watch: {
    dialogBetting(newV) {
      !newV ? (this.bettingMoney = 0) : "";
    },
  },
  computed: {
    meronButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[1];
      else return {};
    },
    walaButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[0];
      else return {};
    },
    tieButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[2];
      else return {};
    },
    _checkAllowSubmit() {
      if (
        this.bettingMoney > 0 &&
        (this.selectedBtnClassMeron ||
          this.selectedBtnClassTie ||
          this.selectedBtnClassWala)
      )
        return true;
      else return false;
    },

    ...mapGetters("$_casino_live", {
      getSwitchChannelLoading: "getSwitchChannelLoading",
      dialogBetting: "getDialogBetting",
      getCasinoLiveSeletedBtn: "getCasinoLiveSeletedBtn"
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },
  methods: {
    _displayPayout(channel, position) {
      if (position == "left") {
        if (channel == 19) return this.passBettingTypeMaster[1].payout_display;
        if (channel == 18) return this.passBettingTypeMaster[1].payout_display;
        if (channel == 15) return '2';
        if (channel == 16) return '1.95';
      }
      if (position == "middle") {
        if (channel == 19) return "1 : 8";
        if (channel == 15 || channel == 18) return "1 : 8";
        if (channel == 16) return "31";
      }
      if (position == "right") {
        if (channel == 19) return this.passBettingTypeMaster[0].payout_display;
        if (channel == 18) return this.passBettingTypeMaster[0].payout_display;
        if (channel == 15) return '1.95';
        if (channel == 16) return '1.95';
      }
    },
    _findButtonSection(channel, position) {
      if (position == "left") {
        if (channel == 19) return "meron-section";
        if (channel == 15 || channel == 18) return "wala-section";
        if (channel == 16) return "black-section";
      }
      if (position == "middle") {
        if (channel == 19) return "tie-section";
        if (channel == 15 || channel == 18) return "tie-section";
        if (channel == 16) return "tie-section";
      }
      if (position == "right") {
        if (channel == 19) return "wala-section";
        if (channel == 15 || channel == 18) return "meron-section";
        if (channel == 16) return "meron-section";
      }
    },
    _allowBtnBet(selectedBtn) {
      if (this.getBettingAmount <= 0) {
        this.$toastr.e(this.$t("title.selectCoin"));
        return false;
      }
      switch (selectedBtn) {
        case 'selectedMeron':
          this.assignSelectedValue(this.meronButton, true, false, false)
          break;
        case 'selectedTie':
          this.assignSelectedValue(this.tieButton, false, true, false)
          break;
        case 'selectedWala':
          this.assignSelectedValue(this.walaButton, false, false, true)
          break;
        default:
          break;
      }
    },
    assignSelectedValue(selectedButton, selectedBtnClassMeron, selectedBtnClassTie, selectedBtnClassWala) {
      let data = {
        selectedButton: selectedButton,
        selectedBtnClassMeron: selectedBtnClassMeron,
        selectedBtnClassTie: selectedBtnClassTie,
        selectedBtnClassWala: selectedBtnClassWala
      }
      this.UPDATE_CASINO_SELECTED_BTN(data)
    },

    formatBtnCurrencyKh(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "ពាន់";
        case amount >= 10000 && amount <= 999999:
          return amount / 10000 + "មុឺន";
        case amount >= 1000000 && amount <= 99999999999:
          return amount / 1000000 + "លាន";
        default:
          return amount;
      }
    },
    formatBtnCurrency(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "K";
        default:
          return amount;
      }
    },
    _displayMoney(currencyType, channel, dataAmount, position) {
      if (position == "left") {
        if (currencyType == 2) {
          if (channel == 15 || channel == 16) {
            return this.formatBtnCurrencyKh(dataAmount.wala);
          }
          if (channel == 18) {
            return this.formatBtnCurrencyKh(dataAmount.player_amount);
          }
          if (channel == 19) {
            return this.formatBtnCurrencyKh(dataAmount.dragon_amount);
          }
        } else {
          if (channel == 15 || channel == 16) {
            return this.formatBtnCurrency(dataAmount.wala);
          }
          if (channel == 18) {
            return this.formatBtnCurrency(dataAmount.player_amount);
          }
          if (channel == 19) {
            return this.formatBtnCurrency(dataAmount.dragon_amount);
          }
        }
      }
      if (position == "middle") {
        if (currencyType == 2) {
          if (channel == 15 || channel == 16) {
            return this.formatBtnCurrencyKh(dataAmount.tie);
          }
          if (channel == 18) {
            return this.formatBtnCurrencyKh(dataAmount.tie);
          }
          if (channel == 19) {
            return this.formatBtnCurrencyKh(dataAmount.tie);
          }
        } else {
          if (channel == 15 || channel == 16) {
            return this.formatBtnCurrency(dataAmount.tie);
          }
          if (channel == 18) {
            return this.formatBtnCurrency(dataAmount.tie_amount);
          }
          if (channel == 19) {
            return this.formatBtnCurrency(dataAmount.tie_amount);
          }
        }
      } else if (position == "right") {
        if (currencyType == 2) {
          if (channel == 15 || channel == 16) {
            return this.formatBtnCurrencyKh(dataAmount.meron);
          }
          if (channel == 18) {
            return this.formatBtnCurrencyKh(dataAmount.banker_amount);
          }
          if (channel == 19) {
            return this.formatBtnCurrencyKh(dataAmount.tiger_amount);
          }
        } else {
          if (channel == 15 || channel == 16) {
            return this.formatBtnCurrency(dataAmount.meron);
          }
          if (channel == 18) {
            return this.formatBtnCurrency(dataAmount.banker_amount);
          }
          if (channel == 19) {
            return this.formatBtnCurrency(dataAmount.tiger_amount);
          }
        }
      }
    },
    ...mapMutations("$_casino_live", ["BETTING_DIALOG", "UPDATE_CASINO_SELECTED_BTN"]),
  },
};
</script>
<style scoped>
.rolling-loading-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: auto;
}

@media (min-width: 960px) {
  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
    min-height: 185px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
  }

  .betting-coin-meron {
    width: 80px;
    height: auto;
    margin: auto;
    margin-top: 20px;
  }

  .betting-coin-wala {
    width: 80px;
    height: auto;
    margin: auto;
    margin-top: 20px;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    justify-content: center;
    width: 150px;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
    border: 4px solid gold;
    /* transform: scale(1.005); */
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    justify-content: center;
    width: 150px;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
  }

  .side-section:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -50.5px;
    margin-bottom: 25px;
    margin-left: -2px;
    z-index: 100;
    font-weight: 600;
    text-shadow: 1px 0px 1px black;
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
    min-height: 200px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
  }

  .betting-coin-meron {
    width: 80px;
    height: auto;
    margin: auto;
    margin-top: 20px;
  }

  .betting-coin-wala {
    width: 80px;
    height: auto;
    margin: auto;
    margin-top: 20px;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    justify-content: center;
    width: 150px;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    justify-content: center;
    width: 150px;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
  }

  .side-section:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
    min-height: 200px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
  }
}

.bet-btn-container {
  padding-right: 13px !important;
  padding-left: 10px !important;
}
</style>