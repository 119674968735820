<template>
  <div
    class="flexi-machine-left">
    <div v-if="passPage == 'ALL'">

      <div v-for="(item, index) in getSubMenuCockXCasino" :key="index">
        <GameElement
          v-if="item.is_enable"
          :passChDesc="item.desc"
          :passChannelInfo="item"
          :passPage="passPage"
          :passItem="item.namespace"
          :passUrl="item.live_urls"
          :passBetType="item.bet_type"
          :channelType="item.channel_type"
          :passGame="index + 1"
          :passIndex="index + 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GameElement from "../GamePlayComponent/GameElement.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    GameElement,
  },
  props: ["passPage"],
  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      channelId: "channelId",
      getMainMenu: "getMainMenu",
      getSubMenuCockXCasino : "getSubMenuCockXCasino"
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  async created(){
    await this.prepareToExit()
  },
  methods: {
    fintPrototypeSocket(i) {
      switch (i) {
        case 1:
          if(this.$socket1) return this.$socket1;
          else return false
          
        case 2:
          if(this.$socket2) return this.$socket2;
          else return false
        case 3:
          if(this.$socket3) return this.$socket3;
          else return false
        case 4:
          if(this.$socket4) return this.$socket4;
          else return false
        case 5:
          if(this.$socket5) return this.$socket5;
          else return false
        case 6:
          if(this.$socket6) return this.$socket6;
          else return false
        case 7:
          if(this.$socket7) return this.$socket7;
          else return false
        case 8:
        if(this.$socket8) return this.$socket8;
          else return false
        case 9:
        if(this.$socket9) return this.$socket9;
          else return false
        case 10:
          if(this.$socket10) return this.$socket10;
            else return false
        case 11:
          if(this.$socket11) return this.$socket11;
            else return false
        case 12:
          if(this.$socket12) return this.$socket12;
            else return false
        case 13:
        if(this.$socket13) return this.$socket13;
            else return false
        case 14:
        if(this.$socket14) return this.$socket14;
            else return false
        case 15:
        if(this.$socket15) return this.$socket15;
            else return false
        case 16:
        if(this.$socket16) return this.$socket16;
            else return false
        case 17:
        if(this.$socket17) return this.$socket17;
            else return false
        case 18:
        if(this.$socket18) return this.$socket18;
            else return false
        case 19:
        if(this.$socket19) return this.$socket19;
            else return false
        case 20:
        if(this.$socket20) return this.$socket20;
            else return false
        case 21:
        if(this.$socket21) return this.$socket21;
            else return false
      }
    },
    prepareToExit() {
      if(this.userInfo && this.userInfo.menu && this.userInfo.menu.menu5){
        switch (this.passPage) {
        case "ALL":
          for (let i = 1; i <= this.userInfo.menu.menu5.channels.length; i++) {
            if(this.fintPrototypeSocket(i) != false){
              this.fintPrototypeSocket(i).disconnect();
            this.fintPrototypeSocket(i).removeAllListeners();
            }
            
          }
          break;
      }
      }
     
    },
  },
};
</script>