<template>
    <div class=" max-label text-right px-3 pt-7 pb-3">
        <span v-if="_selectedGame(channelId) == 'cock'"  >
             {{ $t('title.min') }} : <span class="focus-text pl-2">{{formatMoney(weightObj.amount_min)  }} </span> 
             <span class="px-3">|</span>{{  $t('title.max')}}: <span class="focus-text pl-2">{{formatMoney(weightObj.amount_max) }}</span>
        </span> 
        <span v-else >
           {{ $t('title.min') }} <span class="focus-text">{{formatMoney(amountMin)  }} </span> 
            | {{  $t('title.max')}} <span class="focus-text">{{formatMoney(amountMax) }}</span>
        </span>
    </div>
</template>

<script>
import clickMixin from "../../mixin"
import { mapGetters } from "vuex";
export default {
    props: ['passWeight'],
    data() {
        return {
            mixins: [clickMixin],
        }
    },
    computed: {
        ...mapGetters("$_dashboard", {
            amountMin: "getAmountMin",
            amountMax: "getAmountMax",
            weightObj: "getWeight",
        }),
        ...mapGetters("$_modules", {
            channelId: "getChannelId",
        }),
    },
    methods: {
    }
}
</script>
<style >
.max-label{
    font-size:1.1em!important;
    color:white;
}
.focus-text{
    color:yellow;
}
</style>