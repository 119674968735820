<template>
  <div>
    <v-row class="ma-0 w-100">
      <!-- {{ passBetType }} -->
      
      <div style="width: 100% !important; max-width: 100% !important;" class="machine-baccarat-three-section flex-grow-1">
        <div  class="machine-baccrat-button machine-play-blue-bg" :class="getSelectedTaisevBtn && getSelectedTaisevBtn.button 
        && getSelectedTaisevBtn.button.name == passBetType[7].name && getSelectedTaisevBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[7])"> 
          <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button" >{{passBetType[7] ? passBetType[7].payout : '11'}}
          <br/>
          <span class="machine-button-name machine-button-font fantan-blue white--text">{{passBetType[7] ? passBetType[7].name.toUpperCase() : 'EVEN'}}</span>
          </p>
          
        </div>
        <div class="machine-baccrat-button reserver-margin machine-play-red-bg" :class="getSelectedTaisevBtn && getSelectedTaisevBtn.button 
        && getSelectedTaisevBtn.button.name == passBetType[8].name && getSelectedTaisevBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[8])"> 
          <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">{{passBetType[8] ? passBetType[8].payout : '0.95'}}
          <br/>
          <span class="taisev-yellow-title machine-button-name machine-button-font white--text">
            {{ passBetType[8] && passBetType[8].name.toUpperCase() === 'SMALL' ? 'UNDER' : 'NA'  }}
          </span>
          </p>
        </div>
      </div>

      <!-- Custom Spacer -->
      <div style="width: 10px;"> <!-- Adjust width as needed --></div>

      <div style="width: 100% !important; max-width: 100% !important;" class="machine-baccarat-three-section flex-grow-1">
        <div class="machine-baccrat-button machine-play-blue-bg"  :class="getSelectedTaisevBtn && getSelectedTaisevBtn.button 
        && getSelectedTaisevBtn.button.name == passBetType[6].name && getSelectedTaisevBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[6])"> 
          <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">{{passBetType[6] ? passBetType[6].payout : '11'}}
          <br/>
          <span class="machine-button-name machine-button-font fantan-red white--text">
            {{ passBetType[6] && passBetType[6].name.toUpperCase() }}
          </span>
          </p>

        </div>
        <div  class=" machine-baccrat-button reserver-margin machine-play-red-bg" :class="getSelectedTaisevBtn && getSelectedTaisevBtn.button 
        && getSelectedTaisevBtn.button.name == passBetType[9].name && getSelectedTaisevBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[9])"> 
          <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">{{passBetType[9] ? passBetType[9].payout : '0.95'}}
          <br/>
          <span class="machine-button-name machine-button-font taisev-dark-green-title white--text">
            {{ passBetType[9] && passBetType[9].name.toUpperCase() === 'BIG' ? 'OVER' : 'NA' }}
          </span>
          </p>
          

        </div>
      </div>
    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitTaisev'"/>
  </div>
</template>
<script>
import {mapGetters, mapMutations } from "vuex";
import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"

export default {
  components: {
    CockXCasinoSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  computed:{
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedTaisevBtn: "getSelectedTaisevBtn",
    }),
  },

  methods:{
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_TAISEV_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_TAISEV_BUTTON_IN_ALL",
    ]),
  }
}
</script>

<style>
.margin-fantan-button-name {
  margin: 0 !important;
}
</style>