const LATEST_FIVE_RESULTS = (state, data) => {
    state.lastFiveResults = data;
};
const CURRENT_BETTING = (state, data) => {
    state.currentBetting = data;
};
const GET_BUTTON = (state, data) => {
    state.buttonMoney = data;
};
const APPEND_CURRENT_BETTING = (state, data) => {
    state.currentBetting.list.unshift(data);
};
const PREVIOUS_BETTING = (state, data) => {
    state.previousBetting = data;
};
const CURRENT_GAME = (state, data) => {
    state.currentGame = data;
};
const HEAD_TAIL_TYPE = (state, data) => {
    state.headTailType = data;
};
const RANGE_TYPE = (state, data) => {
    state.rangeType = data;
};
const LATEST_ONE_RESULTS = (state, data) => {
    state.lastOneResult = data;
};
const LATEST_TWENTY_RESULTS = (state, data) => {
    state.lastTwentyResult = data;
};
const IS_BETTING = (state, data) => {
    state.is_Betting = data;
};
const RELOAD_PAGE_LOTTO = (state) => {
    state.lastResults=[],
    state.currentBetting=[],
    state.previousBetting=[],
    state.currentGame=[],
    state.rangeType=[],
    state.headTailType=[],
    state.lastOneResult=[],
    state.lastTwentyResult=[],
    state.is_Betting = false,
    state.buttonMoney=[],
    state.lastFiveResults=[]
};

export default {
    RELOAD_PAGE_LOTTO,
    LATEST_FIVE_RESULTS,
    LATEST_TWENTY_RESULTS,
    CURRENT_BETTING,
    PREVIOUS_BETTING,
    CURRENT_GAME,
    HEAD_TAIL_TYPE,
    RANGE_TYPE,
    LATEST_ONE_RESULTS,
    APPEND_CURRENT_BETTING,
    IS_BETTING,
    GET_BUTTON
};
