<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passIndex }}.{{ passChDesc }}</h5>
    <div class="game-machine-component-result">
      <v-row no-gutters style="position: relative;">
        <!-- v-if="showResult && showResult.result_small_big && showResult.random"  -->
        <div v-if="showResult && showResult.result_small_big_draw && showResult.card"
          style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;"
          :style="showResult.result_small_big_draw.name == 'small' ? 'background-color:rgba(40, 90, 226, .83)' : showResult.result_small_big_draw.name == 'big' ? 'background-color:rgba(224, 80, 73, .83)' : 'background-color:rgba(68, 106, 30, .83)'">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%;">
              <tr style="height: 38px;">
                <th style="color: #ffffff;"
                  :style="showResult.result_small_big_draw.name == 'small' ? 'background-color:rgba(40, 90, 226, .83)' : showResult.result_small_big_draw.name == 'big' ? 'background-color:rgba(224, 80, 73, .83)' : 'background-color:rgba(68, 106, 30, .83)'">
                  <b>{{ showResult.result_small_big_draw.name }}</b>
                </th>
              </tr>
              <tr>
                <td style="color: #ffffff; font-size: 42px; text-shadow: 1px 1px 2px #000;">
                  <b>{{ showResult.card.value }}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <v-col cols="12">
          <v-tabs class="dodenresult" style="color: rgb(255, 0, 0) !important;" fixed-tabs>
            <v-tabs-slider color="#174e87" style="height:3px !important"></v-tabs-slider>
            <v-tab class="g-header">
              <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
                {{ $t("title.result_small_big") }}
              </span>
            </v-tab>
            <v-tab class="g-header">
              <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
                {{ $t("title.result_range") }}
              </span>
            </v-tab>
            <v-tab class="g-header">
              <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
                {{ $t("title.card_type") }}
              </span>
            </v-tab>
            <v-tab-item>
              <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend bottom-space"
                style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderBigSmall" :key="i" class="div-result">
                              <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn :color="result.result_small_big_draw.color" fab dark>

                                  <!-- <v-icon>mdi-checkbox-blank-circle</v-icon> -->
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="px-3 pb-0 gp-panel-bg rounded-bottom landscape-trend bottom-space"
                style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderRange" :key="i" class="div-result">
                              <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn :color="result.result_range.color" fab dark class="btn-range">
                                  <span class="range-name range-size" style="font-size: 5px;">
                                    {{ result.result_range.name }}
                                  </span>
                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend bottom-space"
                style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderOddEven" :key="i" class="div-result">
                              <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn :color="result.result_card_type.color" fab dark class="btn-range">
                                  <span class="">
                                    <span v-if="result.result_card_type.name == 'heart'"
                                      class="card-type-size">&#9829;</span>
                                    <span v-if="result.result_card_type.name == 'diamond'"
                                      class="card-type-size">&#9830;</span>
                                    <span v-if="result.result_card_type.name == 'club'"
                                      class="card-type-size">&#9827;</span>
                                    <span v-if="result.result_card_type.name == 'spade'"
                                      class="card-type-size">&#9824;</span>
                                    <span v-if="result.result_card_type.name == '7'">7</span>
                                  </span>

                                  <!--{{ resultStatement.result_odd_even.name == 'odd' ? 'o' : 'e' }}
                                        resultStatement.result_card_type.name
                                          -->


                                </v-btn>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </div>
    <div class="game-machine-component-reserve-space"></div>
  </div>
</template>
<script>

import shareFunction from "~mixin/MX_ShareFunctionCollection.js";
import { mapActions } from "vuex";

export default {
  mixins: [shareFunction],
  props: ['passGame', 'passIndex', 'channelType', 'passChDesc', 'showResult'],
  data() {
    return {
      rows: 6,
      cols: 35,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {}
    }
  },
  computed: {
    orderBigSmall() {
      return this.getListResults(1);
    },
    orderRange() {
      return this.getListResults(2);
    },
    orderOddEven() {
      return this.getListResults(3);
    },
  },
  async created() {
    this.getResult(this.channelType);
  },
  watch: {
    resultObjS: function () {
      // console.log('doden.....')
      this.getResult(this.channelType);
    }
  },
  methods: {
    async getResult(channelType) {
      try {
        const response = await this.fetchMaBaResult(channelType);
        if (response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    getListResults(type) {
      let row = 1;
      let col = 1;
      let newResult = [];

      if (this.resultObj.results != null && this.resultObj.results.length > 0) {
        let resultList = this.resultObj.results;

        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (type == 1) {
              if (pre.result_small_big_draw.name != item.result_small_big_draw.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 2) {
              if (pre.result_range.name != item.result_range.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 3) {
              if (pre.result_card_type.name != item.result_card_type.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 4) {
              if (pre.blue_red.name != item.blue_red.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col
        } else {
          this.fixCol = this.cols
        }
      }
      return newResult;
    },
    ...mapActions("$_machine_games", ["fetchMaBaResult"]),
  }
}
</script>
<style>
.v-btn--fab.v-size--default {
  width: 18px !important;
  height: 18px !important;
}

div.g-header>span {
  font-size: 8px !important;
}

.dodenresult>.v-tabs-bar {
  height: 28px !important;
}

div.px-3.pb-4.bottom-space {
  padding-bottom: 0 !important;
}
</style>