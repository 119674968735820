import MoneyRequest from '../../../modules/FolderTransaction/moneyrequest';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/money-request',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: MoneyRequest,
                    name: 'money-request'
                }
            ]
    }
];

export default routes;