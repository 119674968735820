import menu from './_menu';
import button from './_button';
import invoice from './_invoice';
import toast from './_toast';
import login from './_login';
import print from './_print'
import header from './_header'
import lang from './_lang'
import title from './_title'
import input from './_input'
import alert from './_alert';

export default {
	input,
	title,
	lang,
	header,
	alert,
	menu,
	invoice,
	label: {},
	button,
	toast,
	login,
	print,
	// field
	field: {},
	validation: {
		required: 'This field is required.',
		min: 'Must be at least {min} characters.',
		max: 'Must be no more than {max} characters.',
		//image
		invalid_type_img: 'The file must be a file of type: {type}.',
		invalid_size_img: 'The file is too big, it must be smaller than {size}',
		failed_upload: 'The file failed to upload.'
	},
	error: {
		not_found: 'Request Not Found!'
	},
	sale_detail: {
		image_file_title: 'FILE IMAGE URL'
	},
	filter: {
		date: 'Filter By Date'
	},
	message: {
		confirm_cancel: 'Do you really want to cancel?',
		confirm_delete: 'Are you sure delete this field?'
	}
};
