<template>

<!-- <div  style="overflow:hidden">
    <lottery-info class="d-none d-sm-block"/>
    <v-row no-gutters class="mx-0 w-100">
      <lottery-betting class="d-none d-sm-block w-100"/>
      <list-lottery-result />
    </v-row>
  </div> -->
  <div class="lottery3d-right">
    <lottery-info class=""/>
    <lottery-betting class="w-100"/>
    <ResultsStatement/>
  </div>
</template>

<script>

import ResultsStatement from "./SubComponent/RightComponents/ResultsStatement.vue";
import LotteryInfo from "./SubComponent/RightComponents/LotteryInfo.vue";
import LotteryBetting from "./SubComponent/RightComponents/LotteryBetting.vue";
export default {
  components: {
    ResultsStatement,
    LotteryInfo,
    LotteryBetting
  },
  data() {
    return {
    };
  },
};
</script>
