<template>
  <v-container id="main">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-img :lazy-src="require('~public_image/a_each_game/taixiu/taixiu.jpg')"
          :src="require('~public_image/a_each_game/taixiu/taixiu.jpg')">
          <machineChannelInfo :passGameName="gameName" />
          
         <div class="container-game">          
          <table class="tb-dd-game tb-tx-game" style=" width: 95% !important;">
            <tr>
              <td></td>
              <td class="table-tr-td1">
                <h4>SMALL</h4>
                <h5>1 : {{passBettingTypeMaster[1].payout_display}}</h5>
              </td>

              <td style="padding: 10px 10px; color:#ffffff;text-align: center;border-spacing: 30px;font-weight: bold; width: 25%;">
                <div  class="game-dd-box game-dd-box-center-img">
                  <v-img
                  id="tx-card-animation"
                  :src="card"
                  style="width: 40%"
                ></v-img>
                </div>
              </td>

              <td class="table-tr-td1">
                <h4>BIG</h4>
                <h5>1 : {{passBettingTypeMaster[0].payout_display}}</h5>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="table-tr-td1">
                <h4>A-2-3</h4>
                <h5>1 : {{passBettingTypeMaster[2].payout_display}}</h5>
              </td>
              <td class="table-tr-td1">
                <h4>4-5-6</h4>
                <h5>1 : {{passBettingTypeMaster[3].payout_display}}</h5>
              </td>
              <td rowspan="2" class="table-tr-td1">
                <h1 style="color: greenyellow;">7</h1>
                <h6>1 : {{passBettingTypeMaster[4].payout_display}}</h6>
              </td>
              <td class="table-tr-td1">
                <h4>8-9-10</h4>
                <h5>1 : {{passBettingTypeMaster[5].payout_display}}</h5>
              </td><td class="table-tr-td1">
                <h4>J-Q-K</h4>
                <h5>1 : {{passBettingTypeMaster[6].payout_display}}</h5>
              </td>
            </tr>
            <tr>
              <td class="table-tr-td1">
                <v-img class="img-respone" style="width:30px;margin: 0 auto;margin-bottom: 5px;" src="images/a_each_game/doden/1.png"></v-img>
                <h6>1 : {{passBettingTypeMaster[7].payout_display}}</h6>
              </td>
              <td class="table-tr-td1">
                <v-img class="img-respone" style="width:30px;margin: 0 auto;margin-bottom: 5px;" src="images/a_each_game/doden/2.png"></v-img>
                <h6>1 : {{passBettingTypeMaster[8].payout_display}}</h6>
              </td>
              
              <td class="table-tr-td1">
                <v-img class="img-respone" style="width:30px;margin: 0 auto;margin-bottom: 5px;" src="images/a_each_game/doden/3.png"></v-img>
                <h6>1 : {{passBettingTypeMaster[9].payout_display}}</h6>
              </td>
              <td class="table-tr-td1">
                <v-img class="img-respone" style="width:30px;margin: 0 auto;margin-bottom: 5px;" src="images/a_each_game/doden/4.png"></v-img>
                <h6>1 : {{passBettingTypeMaster[10].payout_display}}</h6>
              </td>
            </tr>
          </table>
        </div>
        </v-img>
        <div class="td-case-x">
          <div class="td-case-taixiu td-x-hidden"
            :style="{ backgroundImage: 'url(\'' + 'images/a_game_access_global/cards/back_card.png' + '\')' }">
            <img id="f-result-x">
          </div>
          <div class="td-case">
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "~css/machine_game/doden/doden.css";
import machineChannelInfo from "~global_components/component_machine_game/machineChannelInfo.vue";
import { mapGetters } from "vuex";
import $ from "jquery";
export default {
  components: {
    machineChannelInfo,
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s |  DODEN`
    },
  data() {
    return {
      card: "images/a_game_access_global/cards/back_card.png",
      gameName: "doden",
    };
  },

  computed: {
    spinningAllow() {
      return require("/public/images/spinning-allow.svg");
    },
    spinningNotAllow() {
      return require("/public/images/spinning-not-allow.svg");
    },
    spinningClose() {
      return require("/public/images/spinning-close.svg");
    },
    ...mapGetters("$_modules", {
      getBettingAmount: "getBettingAmount",
     
    }),
    ...mapGetters("$_dashboard_live", {
      getSelectedBtn: "getSelectedBtn",
      getSelectedCoin: "getSelectedCoin",
      getCurrentGameResult: "getCurrentGameResult",
      getClientTime: "getClientTime",
      getClientRandom: "getClientRandom",
      amountMin: "getAmountMin",
      amountMax: "getAmountMax",
      channelObj: "getChannel",
      getPlayerCard1: "getPlayerCard1",
      getPlayerCard2: "getPlayerCard2",
      getPlayerCard3: "getPlayerCard3",
      getBankerCard1: "getBankerCard1",
      getBankerCard2: "getBankerCard2",
      getBankerCard3: "getBankerCard3",
      getBankerPoint: "getBankerPoint",
      getPlayerPoint: "getPlayerPoint",
      getFirstCard: "getFirstCard",
      getSecondCard: "getSecondCard",
      getClientCard: "getClientCard",
      passBettingTypeMaster: "getBettingTypeMaster", 
      getClearStart: "getKlakloukClearStart",
     
    }),
  },

  methods: {    
    dtStartCurrentGameResult() {
      $('.td-win-lose-result-x').removeClass("td-x-hidden");
    },   
    async myCard() {
      this.stopBurnCard();
      await document
        .getElementById("tx-card-animation")
        .classList.remove("animate__fadeOutTopLeft");
         this.card = "images/a_game_access_global/cards/back_card.png";
      document
        .getElementById("tx-card-animation")
        .classList.add("card-animation");
    },
    async removeCard() {
      this.stopBurnCard();
      document
        .getElementById("tx-card-animation")
        .classList.remove("card-animation");
      // document.getElementById("tx-card-animation").classList.remove("animate__animated  animate__fadeOutTopLeft");
      document
        .getElementById("tx-card-animation")
        .classList.add("animate__animated");
      document
        .getElementById("tx-card-animation")
        .classList.add("animate__fadeOutTopLeft");
        this.card = "images/a_game_access_global/cards/back_card.png";
      setTimeout(function () {
        document
          .getElementById("tx-card-animation")
          .classList.remove("animate__fadeOutTopLeft");
        document
          .getElementById("tx-card-animation")
          .classList.add("card-animation");
      }, 1000);
    },
    tryFirstCard(){
      this.dtStopResult();
        this._playSoundTakeCard();
        $('.td-case-taixiu').removeClass('animate__animated');
        $('.td-case-taixiu').removeClass('animate__fadeOutTopLeft');
        document
        .getElementById("tx-card-animation")
        .classList.add("animate__animated");
      document
        .getElementById("tx-card-animation")
        .classList.add("animate__fadeOutTopLeft");
        this.card = "images/a_game_access_global/cards/back_card.png";
        this.__getFirstCard(require("~public_image/a_game_access_global/cards_big/" + this.getFirstCard.barcode + '.png'));
    },
    async trySecondCard(){
      await this.__xCasetSecondCard(this.getSecondCard.count, require("~public_image/a_game_access_global/cards/back_card.png"))
  
    },
    dtStopResult(){
        this.card = '';
        document
        .getElementById("tx-card-animation")
        .classList.remove("card-animation");
      },
      stopBurnCard(){
        this.__clearCase()
        $('.td-case-taixiu').addClass('animate__animated');
        $('.td-case-taixiu').addClass('animate__fadeOutTopLeft');
        $('.tb-tx-game').removeClass('hide-game');
      },
    __getFirstCard(x = null) {
      $('#f-result-x').hide();
      $('.td-case-taixiu').removeClass('td-x-hidden');
      $('.td-case-taixiu').addClass('td-case-taixiu-a');
      var clearCard = function () {
        $('.td-case-taixiu').removeClass('td-case-taixiu-a');
        $('#f-result-x').attr('src', x);
        $('#f-result-x').show(10);
      }
      setTimeout(clearCard, 2000);
    },
  
    __clearCase() {
      $('.td-case-taixiu').addClass('td-x-hidden');
      $('.td-case').html('');
    },
    __xCasetSecondCard(x = null, y = null) {
      $('.tb-tx-game').addClass('hide-game');
      this.dtStopResult();
      if(this.getBackCard) this.__clearCase();
      $('.td-case').html('');
      $('.td-case-x').css('display', 'block');
      var i = 1,
        action = function () {
          var rxc = 'xrxkf' + (x.toString()) + ((i - 1).toString());
          var rxca = 'xrxkf' + (x.toString()) + ((i - 1).toString()) + '-a';
          var xc = 'xrxkf' + (x.toString()) + (i.toString());
          var xca = 'xrxkf' + (x.toString()) + (i.toString()) + '-a';
          $('.td-case').find('.' + rxc).removeClass(rxca);
          $('.td-case').append('<div class="' + xc + '"></div>');
          $('.td-case').find('.' + xc).css("background-image", "url(" + y + ")");
          $('.td-case').find('.' + xc).addClass(xca);
          let audio = new Audio("/sound/take_card.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
          i++;
          if (i <= (x + 1)) {
            setTimeout(action, 500);
          }
   
        };
      action();
      setTimeout(this.stopBurnCard, parseInt(this.getSecondCard.count * 800));
    },
  },

  watch: {
    getClientCard: function () {
      if(this.getClientCard && this.getClientCard.name ){
        this.card = require("~public_image/a_game_access_global/cards_animation/" +
        this.getClientCard.name +
        ".gif");
      }
    },
    getFirstCard: function () {
      if (this.getFirstCard && this.getFirstCard.barcode) {
        this.tryFirstCard()
      }
    },
    getSecondCard: function () {
      if (this.getSecondCard && this.getFirstCard.barcode) {
        this.trySecondCard()
      }
    },
    getBackCard: function (newVal) {
      if (newVal) {
        this._playSoundTakeCard();
        this.myCard();
      }
    },
    getClearStart: function () {
      this.removeCard();
    },
  },
};
</script>
<style>
.hide-game{
  display: none;
}
</style>

