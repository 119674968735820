<template>
    <div class="w-100">
      <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }} </h5>
      <div class="game-machine-component-result">
        <div class="trends limit-on-tab"  style="position: relative;">
          <div v-if="updateChInfo && updateChInfo.is_open == null">
            <div v-if="resultObj.results && resultObj.results.length > 0 && resultObj.results[0].result1_name && resultObj.results[0].result2_name && resultObj.results[0].result3_name" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; background-color: rgba(40, 90, 226, .83); align-items: center;">
              <div class="h-100">
                <table style="width: 100%; border-spacing: 30px; height: 100%;">
                  <tr style="height: 38px;">
                    <th style="background-color: rgba(40, 90, 226, .83); color: #ffffff;" colspan="3"><b>Result</b></th>
                  </tr>
                  <tr>
                    <td>
                      <p style="color:#ffffff; font-weight:700; font-size:0.8rem; padding-top:0px"> P1</p>
                    </td>
                    <td>
                      <p style="color:#ffffff; font-weight:700; font-size:0.8rem; padding-top:0px"> P2</p>
                    </td>
                    <td>
                      <p style="color:#ffffff; font-weight:700; font-size:0.8rem; padding-top:0px"> P3</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <span style="padding: 15px; border-radius: 50px;" :class="_getResultClass(resultObj.results[0].result1_name).c">{{_getResultClass(resultObj.results[0].result1_name).f}}</span>
                      </p>
                    </td>
                    <td>
                      <p>
                        <span style="padding: 15px; border-radius: 50px;" :class="_getResultClass(resultObj.results[0].result2_name).c">{{_getResultClass(resultObj.results[0].result2_name).f}}</span>
                      </p>
                    </td>
                    <td>
                      <p>
                        <span style="padding: 15px; border-radius: 50px;" :class="_getResultClass(resultObj.results[0].result3_name).c">{{_getResultClass(resultObj.results[0].result3_name).f}}</span>
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
            <div>
              <!-- <div class="trend-legend-custome  gp-panel-bg  d-flex  justify-content-between align-items-center"
                style="background-color: #ffffff !important;">
              </div> -->
            <div class="px-3 pb-4 gp-panel-bg  landscape-trend"
                style="position: relative; background-color: #ffffff !important; padding: 0px !important;">
                <v-col cols="12" sm="12" style="padding: 0">
                    <v-row style="padding: 0; margin: 0">
                        <v-col
                        cols="6"
                        md="6"
                        sm="6"
                        lg="6"
                        style="padding: 0; margin: 0">
                        
                          <VideoLive :passurl="passUrl" :channelType="channelType" />

                        </v-col>
                        <v-col cols="6" md="6" sm="6" lg="6" style="padding:  10px 0px 0 0; margin: 0">
                            <div class="table-responsive gp-scroll">
                              <table class="han-table-machine-game-list">
                                <tbody>
                                  <tr class="han2-tr-result-machine-game-list">
                                    <td>
                                      <div>
                                        <div class="han-blank-reserve">
                                          <b style="font-weight:700; font-size:.9rem;"></b>
                                        </div>
                                        <div>
                                          <p style="color:#000000; font-weight:700; font-size:0.8rem; padding-top:0px"> P1</p>
                                          <p style="color:#000000; font-weight:700; font-size:0.8rem; padding-top:0px"> P2</p>
                                          <p style="color:#000000; font-weight:700; font-size:0.8rem; padding-top:0px"> P3</p>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-for="col in cols" :key="col">
                                      <div>
                                        <div v-for="(result, i) in (resultObj.results ? resultObj.results.slice().reverse() : resultObj.results)"
                                          :key="i" class="text-center"
                                         >
                                          <div v-if="(col - 1) == i">
                                            <div class="machine-list-han">
                                              <b>#{{ result.fight_no }}</b>
                                            </div>
                                            <p> <span :class="_getResultClass(result.result1_name).c">{{ i == 0
                                              ? _getResultClass(result.result1_name).f : _getResultClass(result.result1_name).n }}</span></p>
                                            <p> <span :class="_getResultClass(result.result2_name).c">{{ i == 0
                                              ? _getResultClass(result.result2_name).f : _getResultClass(result.result2_name).n }}</span></p>
                                            <p><span :class="_getResultClass(result.result3_name).c">{{ i == 0
                                              ? _getResultClass(result.result3_name).f : _getResultClass(result.result3_name).n }}</span></p>
                                            </div>
                                        </div>
                                        <div v-if="col>resultObj.results.length">
                                          <div class="machine-list-han">
                                            <b>--</b>
                                          </div>
                                          <div>
                                            <p style="color: #212427; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                                            <p style="color: #212427; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                                            <p style="color: #212427; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
            </div>
        </div>
    </div>
    <div class="game-machine-component-reserve-space"></div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VideoLive from "../GameResult/VideoLive.vue";
import Mixin from "../../../../../../mixin";
export default {
  components: {
    VideoLive
  },
  props:['updateChInfo', 'passUrl', 'passGame', 'passIndex', 'channelType', 'resultObjS', 'passChDesc', 'showResult', 'totalP','totalB'],
  data() {
    return {
      mixins: [Mixin],
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {
        results:[]
      },
      
    }
  },
  watch: {
    resultObjS: function (newVal) {
      if(newVal.objCoResult){
        this.resultObj.results = newVal.objCoResult
      }
    }
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
    }),
    getColor(resultName) {
      if (resultName == "BANKER") {
        return "error";
      } else if (resultName == "PLAYER") {
        return "primary";
      } else if (resultName == "TIE") {
        return "success";
      } else {
        return "default";
      }
    },

  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  methods: {
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        // console.log(`getResult2 >>>> ${response}`)
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    _getResultClass(x) {
      switch (x) {
        case 'Banker 1':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Banker 2':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Banker 3':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Player 1':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'Player 2':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'Player 3':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'CANCEL':
          return { c: 'han2-r-td-cancel', n: 'C', f: 'C', cf: 'han2-r-td-cancel-f' }
        case 'Tie':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 1':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 2':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 3':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
        default:
          return ''
      }
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult"]),
  }
}
</script>
<style scoped>

div.v-avatar{
    font-size: 8px !important;
  }
  .v-application .pb-3{
    padding-bottom: 2px !important;
  }

table tr td {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  /*border: 1px solid white;*/
}

table {
  border-collapse: collapse;
  width: 100% !important;
  max-width: 100% !important;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}



.han2-r-td-tie {
  background-color: #4caf50;

}

.han2-r-td-cancel {
  background-color: #9e9e9e;

}

.han2-r-td-player {
  background-color: #0b4592;

}

.han2-r-td-banker {
  background-color: #b31013;

}

.han2-r-td-banker-f {
  color: #b31013;
}

.han2-r-td-player-f {
  color: #0b4592;
}

.han2-r-td-cancel-f {
  color: #9e9e9e;
}</style>