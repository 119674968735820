<template>
    <div class="login-banner-container">
        <v-carousel cycle hide-delimiter-background show-arrows-on-hover class="login-carousel "
            delimiter-icon="mdi-minus">
            <v-carousel-item v-for="(slide, i) in slides" :key="i" eager>
                <img :src="_loginIndexToClass(i)" class="login-banner" />
                <img :src="_loginIndexToClassMobile( i)" class="login-banner-mobile" />
                <img :src="_loginIndexToBannerText(lang,i)" class="login-banner-text" />
            </v-carousel-item>
        </v-carousel>
    </div>
</template>
<script>
export default {
    data() {
        return {
            slides: [
                '1',
                '2',
                '3',
                '4',
            ],
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
    },
    methods: {
        _loginIndexToBannerText(lang, i) {
            
            if (lang == "khmer") {
                switch (i) {
                    case 0: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/banner_casino_online.png");
                    case 1: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/banner_cockfight.png");
                    case 2: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/banner_lottery.png");
                    case 3: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/banner_machine_game.png");
                    default: return '';
                }
            } else {
                switch (i) {
                    case 0: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/banner_casino_online.png");
                    case 1: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/banner_cockfight.png");
                    case 2: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/banner_lottery.png");
                    case 3: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/banner_machine_game.png");
                    default: return "";
                }
            }
        },
        _loginIndexToClass(i) {
            switch (i) {
                case 0: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/PC/Casino.jpg");
                case 1: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/PC/cockfight.jpg");
                case 2: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/PC/lottery.jpg");
                case 3: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/PC/machine-game.jpg");
                // case 4: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/PC/banner-sport.jpg");
                default: return "banner-cockfight";
            }
        },
        _loginIndexToClassMobile(i) {
            switch (i) {
                case 0: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/Moblie/cockfight.jpg");
                case 1: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/Moblie/Casino.jpg");
                case 2: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/Moblie/lottery.jpg");
                case 3: return require("../../../../../public/images/a_game_access_global/login-page/Image_Background/Banner/Moblie/Machine-game.jpg");
                default: return "banner-cockfight";
            }
        }
    }
}
</script>