<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }}</h5>
    <div class="game-machine-component-result">
      <div class="trends limit-on-tab" style="position: relative;">
        <!-- <div v-if="isShow">
          <div v-if="resultObj.results && resultObj.results.length > 0 && resultObj.results[0]" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;"
              :style="resultObj.results[0].name == 'TIGER' ? 'background-color:rgba(40, 90, 226, .83)' : resultObj.results[0].name == 'DRAGON' ? 'background-color:rgba(224, 80, 73, .83)' : 'background-color:rgba(68, 106, 30, .83)'">
            <div class="h-100">
              <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
                <tr style="height: 38px;">
                  <th style="color: #ffffff;"
                    :style="resultObj.results[0].name == 'TIGER' ? 'background-color:rgba(40, 90, 226, .83)' : resultObj.results[0].name == 'DRAGON' ? 'background-color:rgba(224, 80, 73, .83)' : 'background-color:rgba(68, 106, 30, .83)'">
                    <b>Result</b>
                  </th>
                </tr>
                <tr>
                  <td style="font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{resultObj.results[0].name}}</b></td>
                </tr>
              </table>
            </div>
          </div>
        </div> -->
        <div v-if="(scanPlayer && scanPlayer.length > 0) || (scanBanker &&scanBanker.length > 0)" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
                <tr style="height: 38px;">
                  <th style="background-color: rgba(40, 90, 226, .93); width: 50%;"><b>Dragon</b></th>
                  <th style="background-color: rgba(224, 80, 73, .93); width: 50%;"><b>Tiger</b></th>
                </tr>
                <tr>
                  <td style="background-color: rgba(40, 90, 226, .83); font-size: 42px;">
                    <b>{{calTotal(scanPlayer)}}</b>
                  </td>
                  <td style="background-color: rgba(224, 80, 73, .83); font-size: 42px;">
                    <b>{{calTotal(scanBanker)}}</b>
                  </td>
                </tr>
            </table>
          </div>
        </div>
        <div>
          <!-- <div class="trend-legend-custome  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-wala">{{ resultObj.summary.TIGER }}</span>
              <p class="mb-0 count-label">Tiger</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-meron">{{ resultObj.summary.DRAGON }}</span>
              <p class="mb-0 count-label">Dragon</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-draw">{{ resultObj.summary.TIE }}</span>
              <p class="mb-0 count-label">Tie</p>
            </div>
          </div> -->
        </div>
      <div class="px-3 pb-0 gp-panel-bg landscape-trend"
        style="position: relative; background-color: #ffffff !important; padding: 0 !important;">
          <v-col cols="12" sm="12" style="padding: 0 ">
            <v-row style="padding: 0; margin: 0">
              <v-col
                cols="6"
                md="6"
                sm="6"
                lg="6"
                style="padding: 0 !important; margin: 0">
                <VideoLive  :passurl="passUrl" :channelType="channelType" />
              </v-col>
              <v-col cols="6" md="6" sm="6" lg="6" style="padding:  10px 0px 0 0; margin: 0">
                <div class="table-responsive gp-scroll">
                  <table class="machine-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td v-for="col in fixColBac" :key="col">
                          <div>
                            <div
                              v-for="(result, i) in orderBaccaratResult"
                              :key="i"
                            >
                              <span v-if="col == result.col && row == result.row">
                                <v-badge
                                  v-if="
                                    result.is_pair_player && result.is_pair_banker
                                  "
                                  bordered
                                  left
                                  color="red"
                                  dot
                                  offset-x="5"
                                  offset-y="5"
                                >
                                  <v-badge
                                    bordered
                                    bottom
                                    color="blue"
                                    dot
                                    offset-x="5"
                                    offset-y="5"
                                  >
                                    <v-avatar
                                      :color="calColor(result.color)"
                                      class="machine-list-dragon-result-ball"
                                    >
                                    <span>  {{
                                        result.name == "DRAGON"
                                          ? "D"
                                          : result.name == "TIGER"
                                          ? "G"
                                          : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                      }}</span>
                                    </v-avatar>
                                  </v-badge>
                                </v-badge>
                                <v-badge
                                  v-else-if="
                                    result.is_pair_player &&
                                    !result.is_pair_banker
                                  "
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-avatar
                                    :color="calColor(result.color)"
                                    class="machine-list-dragon-result-ball"
                                  >
                                  <span>  {{
                                    result.name == "DRAGON"
                                          ? "D"
                                          : result.name == "TIGER"
                                          ? "G"
                                          : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                    }}</span>
                                  </v-avatar>
                                </v-badge>
                                <v-badge
                                  v-else-if="
                                    !result.is_pair_player &&
                                    result.is_pair_banker
                                  "
                                  bordered
                                  left
                                  color="red"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-avatar
                                    :color="calColor(result.color)"
                                    class="machine-list-dragon-result-ball"
                                  >
                                  <span> {{
                                    result.name == "DRAGON"
                                          ? "D"
                                          : result.name == "TIGER"
                                          ? "G"
                                          : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                    }}</span> 
                                  </v-avatar>
                                </v-badge>
                                <v-avatar
                                  v-else
                                  :color="calColor(result.color)"
                                  class="machine-list-dragon-result-ball"
                                >
                                 <span>{{
                                  result.name == "DRAGON"
                                          ? "D"
                                          : result.name == "TIGER"
                                          ? "G"
                                          : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                  }}</span> 
                                </v-avatar>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>
    <div class="game-machine-component-reserve-space"></div>
  </div>
</template>
<script>
import shareFunction from "../../../../../../mixin/MX_ShareFunctionCollection.js";
import { mapActions } from "vuex";
import VideoLive from "../GameResult/VideoLive.vue";

export default {
  mixins: [shareFunction],
  props:['passUrl', 'passGame', 'passIndex', 'channelType', 'passChDesc', 'resultObjS', 'showResult', 'scanPlayer', 'scanBanker'],
  components: {
    VideoLive
  },
  data() {
    return {
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      isShow: false,
      resultObj: {
        results:[]
      }
    }
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
    number(bet) {
      return this.getNumber(bet);
    },
    orderBaccaratResult() {
      return this.getBaccaratResults();
    },
  },
  async created() {
    this.getResult(this.channelType);
  },
  watch: {
    resultObjS: function (newVal) {
      if(newVal.objCoResult){
        // this.countDownTimer()
        this.resultObj.results = newVal.objCoResult
      }
    }
  },
  methods: {
    calColor(data){
    switch(data){
      case 'cir-blue' : return 'blue';
      case 'cir-red' : return 'red';
      case 'cir-green' : return 'green';
      case 'cir-cancel' : return 'grey';
    }
  },
    calTotal(list){
      var total = 0;
      for (var index = 0; index < list.length; index++) {
        total = total + list[index].value;
      }
      return (total % 10);
    },
    countDownTimer() {
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, 10000)
    },
    async getResult(channelType) {
      try {
        const response = await this.fetchMaBaResult(channelType);
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    getNumber(bet){
      let number = [];
      number = bet.split(":");
      return number[1]
    },
    getBaccaratResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
      if (this.resultObj.results != null) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          // console.log(index)
          const item = resultList[index];
          // console.log(item)
          if (row <= this.rows) {
            // console.log('row <= this.rows')
            // console.log(row)
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            // console.log('else')
            row = 1;
            col += 1;
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
        if (col > this.cols) {
          this.fixColBac = col;
        } else {
          this.fixColBac = this.cols;
        }
      }
      return newResult;
    },
    getListResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.resultObj.results != null && this.resultObj.results.length > 0){
            let resultList = this.resultObj.results
            for (var index = resultList.length - 1; index >= 0; index--) {
                const item = resultList[index];
                if(index == resultList.length - 1){
                    newResult.push({
                        ...item,
                        row,
                        col,
                    });
                }else{
                    const pre = newResult[newResult.length - 1];
                    if(pre.name != item.name){
                        newResult.push({ 
                        ...item,
                        row: 1,
                        col: pre.col + 1,
                        });
                    }else{
                        if (pre.row >= 6) {
                            newResult.push({
                                ...item,
                                row: 1,
                                col: pre.col + 1,
                            });
                        } else {
                            newResult.push({
                                ...item,
                                row: pre.row + 1,
                                col: pre.col,
                            });
                        }
                    }
                }
            }
        }
      return newResult;
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult"]),
  }
}
</script>