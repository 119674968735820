<template>
  <v-app>
    <div class="background">
      <LoginHeader :lang="lang" />
      <LoginBanner :lang="lang" />
      <LoginMenu :loginMenu="loginMenu" :lang="lang" />
      <LoginListGame :loginMenu="loginMenu" :lang="lang" />
      <DialogLogin />
      <LoginFooter :lang="lang" />
    </div>

  </v-app>

</template>

<script>
import { mapActions } from "vuex";
import "@/assets/css/login/login_share_style.css";
import "@/assets/css/login/screen_max_480.css";
import "@/assets/css/login/screen_min_481.css";
import "@/assets/css/login/screen_min_768.css";
import "@/assets/css/login/screen_min_993.css";
import "@/assets/css/login/screen_min_1025.css";
import "@/assets/css/login/screen_min_1281.css";
import "@/assets/css/login/screen_min_1441.css";
import "@/assets/css/login/screen_min_1661.css";
import "@/assets/css/login/screen_min_1824.css";

import LoginFooter from './components/LoginFooter.vue';
import LoginHeader from './components/LoginHeader.vue';
import LoginBanner from './components/LoginBanner.vue';
import LoginMenu from './components/LoginMenu.vue';
import LoginListGame from './components/LoginListGame.vue';
import loginMenu from '../../../../login_menu.json';
import DialogLogin from './components/DialogLogin.vue';
// import FormSection from './components/FormSection.vue';
// import FormSectionMobile from './components/FormSectionMobile.vue';
export default {
  components: { LoginHeader, LoginBanner, LoginMenu, LoginListGame, DialogLogin, LoginFooter },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    titleTemplate: `%s | LOGIN`
  },
  data() {
    return {
      lang: '',
      loginMenu: loginMenu,
      select: ['English'],

    }
  },

  created() {
    if (this.$route.params.username) this.$toastr.s(this.$t('title.success_register'));
    for (let i = 0; i < this.loginMenu.menu.length; i++) {
      if (this.loginMenu.menu[i].display_status_enable == true) {
        this.$cookies.set("selected_login_menu", this.loginMenu.menu[i].id)
        this.updateSelectedLoginMenu(this.loginMenu.menu[i]);
        break
      }
    }
  },
  methods: {
    ...mapActions("$_auth", ["fetchLogIn", "updateSelectedLoginMenu"]),
  },

}
</script>
