<template>
  <div>
    <v-row class="ma-0 w-100">
      
      <div class="machine-baccarat-three-section machine-blue-title">
        <div class="machine-baccrat-button machine-play-blue-bg" :class="getSelectedBaccaratBtn && getSelectedBaccaratBtn.button && getSelectedBaccaratBtn.button.name == passBetType[2].name && getSelectedBaccaratBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[2])"> 
          <p class="machine-payout-name machine-button-font" >{{passBetType[2] ? passBetType[2].payout : '11'}}</p>
          <p class="machine-button-name machine-button-font">{{passBetType[2] ? passBetType[2].name == 'PAIR PLAYER' ? 'P.PLAYER':'---': 'P.PLAYER'}}</p>
        </div>
        <div class="machine-baccrat-button reserver-margin machine-play-blue-bg" :class="getSelectedBaccaratBtn && getSelectedBaccaratBtn.button && getSelectedBaccaratBtn.button.name == passBetType[4].name && getSelectedBaccaratBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[4])"> 
          <p class="machine-payout-name machine-button-font">{{passBetType[4] ? passBetType[4].payout : '0.95'}}</p>
          <p class="machine-button-name machine-button-font">{{passBetType[4] ? passBetType[4].name : 'PLAYER'}}</p>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-baccarat-mid-section machine-green-title machine-play-green-bg" :class="getSelectedBaccaratBtn && getSelectedBaccaratBtn.button && getSelectedBaccaratBtn.button.name == passBetType[3].name && getSelectedBaccaratBtn.channelType == passChannelType?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[3])">
        <p class="machine-payout machine-button-font">{{passBetType[3] ? passBetType[3].payout : '7'}}</p>
        <p class="machine-button-name machine-button-font">{{passBetType[3] ? passBetType[3].name : 'DRAW'}}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-baccarat-three-section machine-red-title ">
        <div class="machine-baccrat-button machine-play-red-bg" :class="getSelectedBaccaratBtn && getSelectedBaccaratBtn.button && getSelectedBaccaratBtn.button.name == passBetType[0].name && getSelectedBaccaratBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[0])"> 
          <p class="machine-payout-name machine-button-font">{{passBetType[0] ? passBetType[0].payout : '11'}}</p>
          <p class="machine-button-name machine-button-font">{{passBetType[0] ? passBetType[0].name== 'PAIR BANKER' ? 'P.BANKER': '---' : 'P.BANKER'}}</p>
        </div>
        <div class=" machine-baccrat-button reserver-margin machine-play-red-bg" :class="getSelectedBaccaratBtn && getSelectedBaccaratBtn.button && getSelectedBaccaratBtn.button.name == passBetType[1].name && getSelectedBaccaratBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(passBetType[1])"> 
          <p class="machine-payout-name machine-button-font">{{passBetType[1] ? passBetType[1].payout : '0.95'}}</p>
          <p class="machine-button-name machine-button-font">{{passBetType[1] ? passBetType[1].name : 'BANKER'}}</p>
        </div>
      </div>
    </v-row>
    <MachineSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitBaccarat'"/>
  </div>
</template>
<script>
import {mapGetters, mapMutations } from "vuex";
import MachineSubmitButton from "~global_components/component_machine_game/machine_game_submit_button.vue"

export default {
  components: {
    MachineSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  computed:{
    ...mapGetters("$_machine_games", {
      getSelectedBaccaratBtn: "getSelectedBaccaratBtn",
    }),
  },

  methods:{
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_BACCARAT_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_machine_games", [
      "UPDATE_SELECTED_BACCARAT_BUTTON_IN_ALL",
    ]),
  }
}
</script>