<template>
    <div class="login-list-game-container">
        <div class="row ma-0 justify-content-between">
            <div class="login-list-game-ele mb-5 shine" :class="_loginGameIndexToClass(i.id)"
                v-for="(i, index) in getListLoginGame" :key="index" @click="_openLoginDialog(true)" 
                @mouseenter="fireHover=index" @mouseleave="fireHover=null">
                <div :class="{ 'fireplace': fireHover == index }">
                    <main class="fireplace__spark"></main>
                    <main class="fireplace__spark"></main>
                    <main class="fireplace__spark"></main>
                    <main class="fireplace__spark"></main>
                    <main class="fireplace__spark"></main>
                    <div class="blur fix">
                        <div class="fireplace__light"></div>
                    </div>
                    <img v-if="lang != 'khmer'" :src="_loginGameIndexToNameEngImage(i.id)"
                        :class="_loginGameIndexToNameEngImageClass(i.id)" class="login-name-game" alt="" />
                    <img v-else :src="_loginGameIndexToNameKmImage(i.id)"
                        :class="_loginGameIndexToNameKmImageClass(i.id)" class="login-name-game" alt="" />
                    <img v-if="_loginGameIndexToEnterImage(i.id) != ''" :src="_loginGameIndexToEnterImage(i.id)"
                        class="login-enter-game" alt="" @click="_openLoginDialog(true)" />
                    <span v-if="_loginGameIndexToEnterImage(i.id)" class="login-enter-game-text"
                        @click="_openLoginDialog(true)">
                        {{ $t('title.enter_game') }}
                    </span>
                </div>


            </div>
            <div v-if="getListLoginGame.length % 4 == 3" class="login-list-game-ele">
            </div>
            <div v-if="getListLoginGame.length % 4 == 2 && getListLoginGame.length % 4 != 0"
                class="login-list-game-ele">
            </div>
            <div v-if="getListLoginGame.length % 4 == 2 && getListLoginGame.length % 4 != 0"
                class="login-list-game-ele">
            </div>
            <div v-if="getListLoginGame.length % 4 == 1 && getListLoginGame.length % 4 != 0"
                class="login-list-game-ele">
            </div>
            <div v-if="getListLoginGame.length % 4 == 1 && getListLoginGame.length % 4 != 0"
                class="login-list-game-ele">
            </div>
            <div v-if="getListLoginGame.length % 4 == 1 && getListLoginGame.length % 4 != 0"
                class="login-list-game-ele">
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    props: ['loginMenu'],
    data() {
        return {
        fireHover: null,

        }
  },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        ...mapGetters("$_auth", {
            getListLoginGame: "getListLoginGame",
            getSelectedLoginMenu: "getSelectedLoginMenu",
        })
    },
    methods: {
        _openLoginDialog(data) {
            this.UPDATE_LOGIN_DIALOG(data);
        },
        _loginGameIndexToEnterImage(i) {
            switch (i) {
                case 11: return "";
                case 12: return "";
                case 13: return "";
                case 14: return "";
                case 15: return "";
                case 21: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 22: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 23: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 31: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 32: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 33: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 34: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 35: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 36: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 37: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 38: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 39: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 41: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 42: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 43: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 44: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 45: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 46: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 51: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 52: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 53: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 54: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 55: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 56: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 61: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                case 62: return require("../../../../../public/images/a_game_access_global/login-page/enter_game_button.png");
                default: return "";
            }
        },
        _loginGameIndexToNameEngImageClass(i) {
            switch (i) {
                case 11: return "login-top-game";
                case 12: return "login-top-game";
                case 13: return "login-top-game";
                case 14: return "login-top-game";
                case 15: return "login-top-game";
                case 21: return "login-cock-game";
                case 22: return "login-cock-game";
                case 23: return "login-cock-game";
                case 31: return "login-cock-game";
                case 32: return "login-cock-game";
                case 33: return "login-cock-game";
                case 34: return "login-cock-game";
                case 35: return "login-cock-game";
                case 36: return "login-cock-game";
                case 37: return "login-cock-game";
                case 38: return "login-cock-game";
                case 39: return "login-cock-game";
                case 41: return "login-cock-game";
                case 42: return "login-cock-game";
                case 43: return "login-cock-game";
                case 44: return "login-cock-game";
                case 45: return "login-cock-game";
                case 46: return "login-cock-game";
                case 51: return "login-cock-game";
                case 52: return "login-cock-game";
                case 53: return "login-cock-game";
                case 54: return "login-cock-game";
                case 55: return "login-cock-game";
                case 56: return "login-cock-game";
                case 61: return "login-cock-game";
                case 62: return "login-cock-game";
                default: return "";
            }
        },

        _loginGameIndexToNameKmImageClass(i) {
            switch (i) {
                case 11: return "login-top-game-km";
                case 12: return "login-top-game-km";
                case 13: return "login-top-game-km";
                case 14: return "login-top-game-km";
                case 15: return "login-top-game-km";
                case 21: return "login-cock-game-km";
                case 22: return "login-cock-game-km";
                case 23: return "login-cock-game-km";
                case 31: return "login-cock-game-km";
                case 32: return "login-cock-game-km";
                case 33: return "login-cock-game-km";
                case 34: return "login-cock-game-km";
                case 35: return "login-cock-game-km";
                case 36: return "login-cock-game-km";
                case 37: return "login-cock-game-km";
                case 38: return "login-cock-game-km";
                case 39: return "login-cock-game-km";
                case 41: return "login-cock-game-km";
                case 42: return "login-cock-game-km";
                case 43: return "login-cock-game-km";
                case 44: return "login-cock-game-km";
                case 45: return "login-cock-game-km";
                case 46: return "login-cock-game-km";
                case 51: return "login-cock-game-km";
                case 52: return "login-cock-game-km";
                case 53: return "login-cock-game-km";
                case 54: return "login-cock-game-km";
                case 55: return "login-cock-game-km";
                case 56: return "login-cock-game-km";
                case 61: return "login-cock-game-km";
                case 62: return "login-cock-game-km";
                default: return "";
            }
        },

        _loginGameIndexToNameEngImage(i) {
            switch (i) {
                case 11: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/COCKFIGHT.png");
                case 12: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/casino.png");
                case 13: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/Machine-game.png");
                case 14: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/lottery.png");
                case 15: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/Sport.png");
                case 21: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/CAMBODIA-CockFight.png");
                case 22: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/pilipin-CockFight.png");
                case 23: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/vietnam-CockFight.png");
                case 31: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/apong.png");
                case 32: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/baccarat.png");
                case 33: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/dragon-tiger.png");
                case 34: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/fatan.png");
                case 35: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/han.png");
                case 36: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/kla---klouk.png");
                case 37: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/lotto.png");
                case 38: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/taisiev.png");
                case 39: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/yuki.png");
                case 41: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/baccarat.png");
                case 42: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/dragon-tiger.png");
                case 43: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/kla---klouk.png");
                case 44: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/do---den.png");
                case 45: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/taixiu.png");
                case 46: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/sicbo.png");
                case 51: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/lotto.png");
                case 52: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/yuki.png");
                case 53: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/keno.png");
                case 54: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/lottery-2D.png");
                case 55: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/lottery-3D.png");
                case 61: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/soccer.png");
                case 62: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/boxing.png");
                default: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/CAMBODIA-CockFight.png");
            }
        },
        _loginGameIndexToNameKmImage(i) {
            switch (i) {
                case 11: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/មាន់ជល់.png");
                case 12: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/កាសុីណូ.png");
                case 13: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ម៉ាសុីនហ្គេម.png");
                case 14: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ឡូតូ.png");
                case 15: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/កីឡា.png");
                case 21: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/មាន់ជល់ខ្មែរ.png");
                case 22: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/មាន់ជល់ហ្វីលីពីន.png");
                case 23: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/មាន់ជល់វៀតណាម.png");
                case 31: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/អាប៉ោង.png");
                case 32: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/បាការ៉ាត់.png");
                case 33: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ខ្លានាគ.png");
                case 34: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ហ្វាតាន់.png");
                case 35: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ហាន.png");
                case 36: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ខ្លាឃ្លោក.png");
                case 37: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/តៃសៀវ.png");
                case 38: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/តៃសូ.png");
                case 39: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/យូគី.png");
                case 41: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/បាការ៉ាត់.png");
                case 42: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ខ្លានាគ.png");
                case 43: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ខ្លាឃ្លោក.png");
                case 44: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ដូ-ដិន.png");
                case 45: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/តៃសូ.png");
                case 46: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ឡុកឡាក់.png");
                case 51: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ឡូតូ.png");
                case 52: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/យូគី.png");
                case 53: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/គីណូ.png");
                case 54: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ឆ្នោត-2D.png");
                case 55: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ឆ្នោត-3D.png");
                case 61: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/បាល់ទាត់.png");
                case 62: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Khmer/ប្រដាល់.png");
                default: return require("../../../../../public/images/a_game_access_global/login-page/Text File/Eng/CAMBODIA-CockFight.png");
            }
        },
        _loginGameIndexToClass(i) {
            switch (i) {
                case 11: return "login-game-top-cockfight";
                case 12: return "login-game-top-casino";
                case 13: return "login-game-top-slot";
                case 14: return "login-game-top-lottery";
                case 15: return "login-game-top-sport";
                case 21: return "login-game-cock-cockfight-1";
                case 22: return "login-game-cock-cockfight-2";
                case 23: return "login-game-cock-cockfight-3";
                case 31: return "login-game-casino-apong";
                case 32: return "login-game-casino-baccarat";
                case 33: return "login-game-casino-dragon";
                case 34: return "login-game-casino-fatan";
                case 35: return "login-game-casino-han";
                case 36: return "login-game-casino-kla-klouk";
                case 37: return "login-game-casino-lotto";
                case 38: return "login-game-casino-taisiev";
                case 39: return "login-game-casino-yuki";
                case 41: return "login-game-machine-bacc";
                case 42: return "login-game-machine-dragon";
                case 43: return "login-game-machine-klaklouk";
                case 44: return "login-game-machine-doden";
                case 45: return "login-game-machine-taixiu";
                case 46: return "login-game-machine-sicbo";
                case 51: return "login-game-lottery-lotto";
                case 52: return "login-game-lottery-yuki";
                case 53: return "login-game-lottery-keno";
                case 54: return "login-game-lottery-lottery2d";
                case 55: return "login-game-lottery-lottery3d";
                case 61: return "login-game-sport-soccer";
                case 62: return "login-game-sport-boxing";
                // case 56: return "login-game-lottery-sicbo";
                default: return "login-game-cockfight";
            }
        },
        ...mapMutations("$_auth", [
            "UPDATE_LOGIN_DIALOG"
        ]),

    }
}
</script>
<style>
.login-enter-game:hover {
    color: rgb(172, 209, 70);
    cursor: pointer;
    text-shadow: -1px 0 2px black;
}

.login-enter-game-text:hover {
    color: rgb(172, 209, 70);
    cursor: pointer;
}

.login-list-game-ele:hover {
    cursor: pointer;
    filter: drop-shadow(0 0 20px #025ddb) invert(2%);
}

.login-list-game-ele:hover>.login-enter-game-text {
    color: yellow;
}
</style>
<style lang="scss">
.shine {
    position: relative;
    overflow: hidden;

    &::before {
        background: linear-gradient(to right,
                fade_out(#fff, 1) 0%,
                fade_out(#fff, 0.7) 100%);
        content: "";
        display: block;
        height: 100%;
        left: -75%;
        position: absolute;
        top: 0;
        transform: skewX(-25deg);
        width: 50%;
        z-index: 2;
    }

    &:hover,
    &:focus {
        &::before {
            animation: shine 0.85s;
        }
    }

    @keyframes shine {
        100% {
            left: 125%;
        }
    }
}

// Options
$black: #1b1e23;
$white: #f4eed7;
$feature : #4b9aaa;
$log: linear-gradient(#e66465, #5d5e55);
$fire: linear-gradient(0deg, rgba(236, 221, 66, 1) 10%, rgba(237, 174, 52, 1) 15%, rgba(237, 100, 52, 1) 50%, rgba(250, 71, 8, 1) 59%);
// Pen specific



.fireplace{
    
    &__flame,
    &__flame_big {

        background: $fire;
        position: relative;
        margin-bottom: -15px;

        z-index: 3;
        opacity: 0.7;
    }

    &__flame {
        margin-left: 10px;
        animation: burn 1.5s linear forwards infinite;
    }



    section:nth-of-type(1) {
        left: 70px;
        transform: rotateZ(45deg);
    }

    section:nth-of-type(2) {
        left: 100px;
        transform: rotateZ(25deg);
    }

    section:nth-of-type(3) {
        left: 140px;
        transform: rotateZ(5deg);
    }

    section:nth-of-type(4) {
        left: 140px;
        transform: rotateZ(-5deg);
    }

    section:nth-of-type(5) {
        left: 170px;
        transform: rotateZ(-15deg);
    }

    section:nth-of-type(6) {
        left: 10px;
        transform: rotateZ(-35deg);
    }

    section:nth-of-type(7) {
        left: 40px;
        transform: rotateZ(-45deg);
    }

    &__spark {
        position: absolute;
        height: 6px;
        width: 6px;
        border-radius: 5px;
        top: 0;
        left: 0;
        background: rgb(255, 174, 0);
        z-index: 10;
        opacity: 0;
    }

    main:nth-of-type(1) {
        animation: spark_1 1s forwards infinite;
    }

    main:nth-of-type(2) {
        animation: spark_2 1s 0.75s forwards infinite;
    }

    main:nth-of-type(3) {
        animation: spark_3 1s 0.25s forwards infinite;
    }

    main:nth-of-type(4) {
        animation: spark_4 1s 0.5s forwards infinite;
    }
    main:nth-of-type(5) {
        animation: spark_5 1s 0.5s forwards infinite;
    }
    main:nth-of-type(6) {
        animation: spark_6 1s 0.2s forwards infinite;
    }

    &__light {
        height: 100%;
        width: 100%;
        border-radius: 50% 50% 30% 30%;
        transform: scale(1.1, 1.2);
        filter: blur(50px);
        background: orange;
        position: absolute;
        top: 40px;
        left: 0;
        z-index: -1;
        opacity: 0.4;
    }

    .blur {
        filter: blur(1px);
        &.fix {
            position: absolute;
        }
    }

}


@keyframes spark_1 {
    0% {
        opacity: 1;
        left: 100px;
        top: 210px;
    }

    100% {
        top: 60px;
        left: 110px;
        opacity: 0;
    }
}

@keyframes spark_2 {
    0% {
        opacity: 1;
        left: 180px;
        top: 210px;
    }

    100% {
        top: 20px;
        left: 150px;
        opacity: 0;
    }
}

@keyframes spark_3 {
    0% {
        opacity: 1;
        left: 220px;
        top: 210px;
    }

    100% {
        top: 30px;
        left: 170px;
        opacity: 0;
    }
}

@keyframes spark_4 {
    0% {
        opacity: 1;
        left: 120px;
        top: 210px;
    }

    100% {
        top: 30px;
        left: 140px;
        opacity: 0;
    }
}
@keyframes spark_5 {
    0% {
        opacity: 1;
        left: 140px;
        top: 210px;
    }

    100% {
        top: 10px;
        left: 260px;
        opacity: 0;
    }
}
@keyframes spark_6 {
    0% {
        opacity: 1;
        left: 50px;
        top: 100px;
    }

    100% {
        top: 10px;
        left: 60px;
        opacity: 0;
    }
}
</style>