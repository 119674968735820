import { render, staticRenderFns } from "./ResultVideoDistribution.vue?vue&type=template&id=2b502d64"
import script from "./ResultVideoDistribution.vue?vue&type=script&lang=js"
export * from "./ResultVideoDistribution.vue?vue&type=script&lang=js"
import style0 from "./ResultVideoDistribution.vue?vue&type=style&index=0&id=2b502d64&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports