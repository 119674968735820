<template>
    <div class="w-100">
      <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }} </h5>
      <div class="game-machine-component-result">
        <div class="trends limit-on-tab"  style="position: relative;">
          <div v-if="sResultKk && sResultKk.length > 0" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center; background-color:rgba(40, 90, 226, .83)">
            <div class="h-100">
              <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
                <tr style="height: 38px;">
                  <th style="color: #ffffff; background-color:rgba(40, 90, 226, .83)">
                    <b>Result</b>
                  </th>
                </tr>
                <tr>
                  <td>
                    <div v-for="i in sResultKk" :key="i">
                      <v-img :src="findImageOfEachAnimal(i.name)" width="34" style=" text-align: center; margin-right: auto; margin-left: auto;height: 34px;"></v-img>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <div class="px-3 pb-4 gp-panel-bg landscape-trend"
                style="position: relative; background-color: #ffffff !important; padding: 0 !important;">
                <v-col cols="12" sm="12" style="padding: 0">
                    <v-row style="padding: 0; margin: 0">
                        <v-col
                        cols="6"
                        md="6"
                        sm="6"
                        lg="6"
                        style="padding: 0; margin: 0">
                            <VideoLive :passurl="passUrl" :channelType="channelType" />
                        </v-col>
                        <v-col cols="6" md="6" sm="6" lg="6" style="padding:  10px 0px 0 0; margin: 0">
                            <div class="table-responsive gp-scroll">
                              <table class="kk-table">
                                <tbody>
                                  <tr>
                                    <td v-for="col in cols" :key="col">
                                      <div>
                                        <div v-for="(result, i) in (resultObj.results ? resultObj.results.slice().reverse() : resultObj.results)" :key="i" class="text-center taixiu-list-result-machine-game-container">
                                          <div v-if="(col-1) == i">
                                              <v-img v-if="result.result1_name" :src="findImageOfEachAnimal(result.result1_name)" class="text-center taixiu-list-result-machine-game"></v-img>
                                              <v-img v-if="result.result2_name" :src="findImageOfEachAnimal(result.result2_name)" class="text-center taixiu-list-result-machine-game"></v-img>
                                              <v-img v-if="result.result3_name" :src="findImageOfEachAnimal(result.result3_name)" class="text-center taixiu-list-result-machine-game"></v-img>
                                              <v-img v-if="result.result_odd_even" :src="findImageOfEachAnimal(result.result_odd_even)" class="text-center taixiu-list-result-machine-game">{{ result.fight_no }}</v-img>
                                              <v-img v-if="result.result_small_big" :src="findImageOfEachAnimal(result.result_small_big)" class="text-center taixiu-list-result-machine-game">{{ result.fight_no }}</v-img>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
            </div>
        </div>
    </div>
    <div class="game-machine-component-reserve-space"></div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VideoLive from "../GameResult/VideoLive.vue";
import Mixin from "../../../../../../mixin";
export default {
  components: {
    VideoLive
  },
  props:['passUrl', 'passGame', 'passIndex', 'channelType', 'resultObjS', 'passChDesc', 'sResultKk', 'totalP','totalB'],
  data() {
    return {
      mixins: [Mixin],
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {
        results:[]
      },
    }
  },
  watch: {
    resultObjS: function (newVal) {
      if(newVal.objCoResult){
        this.resultObj.results = newVal.objCoResult
      }
    }
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
    })
  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  methods: {
    findImageOfEachAnimal(animalName) {
          switch (animalName) {
            case "1":
                return "/images/a_each_game/taisiev/1.png";
            case "2":
                return "/images/a_each_game/taisiev/2.png";
            case "3":
                return "/images/a_each_game/taisiev/3.png";
            case "4":
                return "/images/a_each_game/taisiev/4.png";
            case "5":
                return "/images/a_each_game/taisiev/5.png";
            case "6":
                return "/images/a_each_game/taisiev/6.png";
            case "odd":
                return "/images/a_each_game/taisiev/odd.png";
            case "small":
                return "/images/a_each_game/taisiev/small.png";
            case "even":
                return "/images/a_each_game/taisiev/even.png";
            case "big":
                return "/images/a_each_game/taisiev/big.png";
            case "CANCEL":
                return "/images/a_each_game/taisiev/cancel.png";
            default:
              return '';
          }
    },
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        // console.log(`getResult2 >>>> ${response}`)
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult"]),
  }
}
</script>
<style>
  /* .v-icon.v-icon{
    font-size: 18px !important
  } */
  div.v-avatar{
    font-size: 8px !important;
  }
  .v-application .pb-3{
    padding-bottom: 2px !important;
  }
</style>