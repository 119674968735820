<template>
  <v-container fluid class="pa-0 report-page-container" style="margin:auto">
    <v-toolbar flat dark class="toolbar-style"> </v-toolbar>
    <v-card flat class="" style="background-color: transparent!important;">
      <v-col cols="12" class="pa-0">
        <HeaderResultBetting @child-checkbox="dateWinLoseFilterValue" />
        <v-data-table
          hide-default-header
          class="elevation-1 report-table"
          item-key="_id"
          mobile-breakpoint="0"
          :headers="headersReportBetting ? headersReportBetting : []"
          :footer-props="footerProps"
          :page.sync="dataRB.page"
          :items="
            dataReportBetting && dataReportBetting.reports
              ? dataReportBetting.reports
              : []
          "
          :items-per-page.sync="dataRB.rowsPerPage"
          :server-items-length="
            dataReportBetting && dataReportBetting.total_rows
              ? dataReportBetting.total_rows
              : 0
          "
          :loading="getLoading"
          @update:page="reportBetting"
          @update:items-per-page="reportBetting"
          @update:sort-desc="reportBetting"
          @click:row="(item) => dialogMethod(item)"
        >
          <template v-slot:header="{ props }">
            <th
              v-for="(header, index) in props.headers"
              :key="index"
              class="text-center header-text-style"
              :style="header.width"
            >
              {{ $t(`title.${header.text}`) }}
            </th>
          </template>

          <template v-slot:[`header.amount`]="{ header }">
            {{ header.text }} ({{ getUserCurrency() }})
          </template>

          <template v-slot:[`header.amount_win`]="{ header }">
            {{ header.text }} ({{ getUserCurrency() }})
          </template>

          <!--No-->
          <template v-slot:[`item.no`]="{ item }">
            <h6 class="pt-3">
              {{ "#" + item.no }}
            </h6>
          </template>

          <!--channnel type-->
          <template v-slot:[`item.channel_type`]="{ item }">
            <v-btn small  style="border-radius: 10px!important;  background: linear-gradient(40deg, rgba(13, 32, 121, 0.97) 1%, rgba(13, 36, 136, 0.836) 48%, rgba(13, 32, 121, 0.97) 97%) !important;" >
              <h6 class="v-btn__content" style="color:gold; padding-top:7px!important; min-width: 130px; ">
                {{ getChannelTypeName(item.channel_type) }}
              </h6>
            </v-btn>
          </template>
          <template v-slot:[`item.bet_name`]="{ item }">
              <h6 class="text-center pt-3"> 
                {{ checkbetType(item.channel_type, item.bet_name) }}
              </h6> 
            
          </template>
          <!--date-->
          <template v-slot:[`item.time`]="{ item }">
            <h6 class="pt-3">{{ item.time }}</h6>
          </template>

          <!--total amount-->
          <template v-slot:[`item.total_amount`]="{ item }">
            <h6 class="pt-3">   {{ formatMoney(item.total_amount) }}</h6>
          </template>

          <!--cast-->
          <template v-slot:[`item.cast`]="{ item }">
            <h6 v-if="item.cast == null" class="c-white pt-3"> 0 </h6>
            <h6 v-else class="c-white pt-3">
              {{ formatMoney(item.cast) }}
            </h6>
          </template>

          <!--amount win-->
          <template v-slot:[`item.amount_win`]="{ item }">
            <h6
              v-if="item.amount_win == null || item.amount_win == 0"
              class="c-white pt-3"
            >
              0
          </h6>
            <h6 v-if="item.amount_win > 0" class="c-white">
              <v-chip
                label
                :class="ma - 2"
                color="primary"
                class="mt-3"
                style="
                  color: white;
                  font-size: 1rem;
                  height: 30px;
                  min-width:150px;
                  justify-content: center;
                  border-radius: 10px!important;
                "
              >
                {{ formatMoney(item.amount_win) }}
              </v-chip>
            </h6>
            <span v-if="item.amount_win < 0" class="c-red">
              <v-chip
                label
                :class="ma - 2"
                color="red"
                style="
                color: white;
                font-size: 1rem;
                height: 30px;
                min-width:150px;
                justify-content: center;
                border-radius: 10px!important;
                "
              >
                {{ formatMoney(item.amount_win) }}
              </v-chip>
            </span>
          </template>
        </v-data-table>
      </v-col>
      <DetailReport 
        :passDialog="detailDialog"
        :passGetInvoiceData="getInvoiceData"
        :passVariableModuleName="variableModuleName"
        :passDetailLoading="detailLoading"
        :passIsShow="isShow"
        :passGetInvoiceDetailData="getInvoiceDetailData"
        v-on:closeDetailDialog="closeDialogDetail"
        v-on:showDeail="showDeail"
      />
    </v-card>
  </v-container>
</template>


<script>
import "@/assets/css/statement/report_statement.css";
import "@/assets/css/statement/screen_max_480.css";
import "@/assets/css/statement/screen_min_481.css";
import "@/assets/css/statement/screen_min_768.css";
import "@/assets/css/statement/screen_min_993.css";
import "@/assets/css/statement/screen_min_1025.css";
import "@/assets/css/statement/screen_min_1281.css";
import "@/assets/css/statement/screen_min_1441.css";
import "@/assets/css/statement/screen_min_1661.css";
import "@/assets/css/statement/screen_min_1824.css";
import headersReportBetting from "./_api/columnsReportBetting";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import clickMixin from "~mixin/MX_ShareFunctionCollection.js";

import axios from "axios";

export default {
  components: {
    HeaderResultBetting: () => import("./components/HeaderResultBetting.vue"),
    DetailReport: () => import("./components/DetailReport.vue"),
  },
  mixins: [clickMixin],
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s | BETTING STATEMENT`
    },
  data() {
    return {
      headersReportBetting: headersReportBetting,
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      channelType: 1,
      loading: false,
      detailLoading: false,
      dialog: false,
      isShow: false,
      tempVal: {},
      dataRB: {
        page: 1,
        rowsPerPage: 10,
      },

      dateFilterRB: {},

      data: {
        rows_per_page: 10,
        page: 1,
        end_date: "",
        start_date: "",
      },
      headers: [
        { text: "header.no", value: "no" },
        { text: "title.date_time", value: "date" },
        { text: "header.bettingType", value: "module_name" },
        { text: "header.post", value: "channel_type" },
        { text: "header.game_no", value: "no" },
        { text: "header.group", value: "group" },
        { text: "header.balance", value: "total_amount" },
        { text: "header.total_win_lose", value: "amount_win" },
        { text: "header.cash", value: "cast" },
        { text: "header.result", value: "status" },
      ],
 
      dataRequest: {
        descendingFormat: [true],
        sortByFormat: ["_id"],
        descending: true,
        sortBy: null,
        page: 1,
        rows_per_page: 10,
        getLoadingDetail: false,
        invoice_id: null,
      },
      detailDialog: false,
      variableModulePath: "",
      variableModulePathDetail: "",
      variableModuleName: "",
      getLoadingDetail: false,
      detailData: [],
      getInvoiceData: [],
      getInvoiceDetailData: [],
      startDate: null,
      endDate: null,
      v_startDate: null,
      v_endDate: null,
      selectedOption: null,
      moduleBetId: null,
      token: this.$cookies.get("sbc_token"),
    };
  },

  created() {
    this.reportBetting();
  },

  item: {
    type: Object,
    default: null,
  },

  computed: {
    ...mapGetters("$_allstatement", {
      dataReportBetting: "getReportBetting",
    }),
    ...mapGetters("$_allstatement", ["getLoading"]),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  mounted() {
    this.channelType = this.$cookies.get("sbc_channel_type")
      ? this.$cookies.get("sbc_channel_type")
      : 1;
  },
  methods: {
    showDeail() {
      if (!this.isShow) {
        this.detailLoading = true;
        this.invoiceDataMethod(this.tempVal);
      }
    },
    closeDialogDetail() {
      this.isShow = false;
      this.detailDialog = false;
    },
    async dialogMethod(val) {
      this.getInvoiceData = [];
      this.detailLoading = true;
      this.tempVal = val;
      this.moduleBetId = val.module_bet_id;
      this.channelType = this.calculateSwitchChannel(val.channel_type);
      this.detailDialog = true;
      this.variableModulePath = this.calculateRequestReportKeyword(
        val.channel_type
      );
      this.variableModulePathDetail = this.calculateRequestReportKeyword(
        val.channel_type
      );
      this.variableModuleName = this.calculateChannelTypeKeyword(
        val.channel_type
      );

      let requestUrl = this.checkIsMachineGame(val.channel_type)
        ? process.env.VUE_APP_SLOT_URL
        : process.env.VUE_APP_API_URL;
      //request API
      await axios
        .get(requestUrl + this.variableModulePath + this.moduleBetId, {
          headers: {
            Authorization: this.token,
            "channel-type": this.channelType,
          },
        })
        .then((response) => {
          if (response.data.code) {
            this.getInvoiceData = response.data.data;
            this.getInvoiceData.channel_type = val.channel_type;
            this.detailLoading = false;
            // console.log("variableModuleName", this.variableModuleName)
            if(this.variableModuleName=='m_yuki' || this.variableModuleName == 'm_lottery' || this.variableModuleName == 'm_lottery3' || this.variableModuleName == 'm_lotteryvn'){
                this.showDeail();
            }
            
          }
        })
        .catch((error) => console.log(error));
    },
    async invoiceDataMethod(val) {
      let slotModulePath = "";
      // console.log('val',val);
      let requestUrl = process.env.VUE_APP_SLOT_URL;
      if (val.channel_type == "79" || val.channel_type == "791" || val.channel_type == '792') {
        slotModulePath ="m_lotteryvn/report/detail?module_bet_id=" + val.module_bet_id;
      }
      if (val.channel_type == "90" || val.channel_type == "901" || val.channel_type == '902') {
        slotModulePath ="m_lotto/report/detail?module_bet_id=" + val.module_bet_id;
      }
      if (val.channel_type == "91" || val.channel_type == "911" || val.channel_type == "912") {
        slotModulePath ="m_yuki/report/detail?module_bet_id=" + val.module_bet_id;
      } else if (val.channel_type == "92" || val.channel_type == "921" || val.channel_type == "922") {
        slotModulePath ="m_lottery/report/detail?module_bet_id=" + val.module_bet_id;
      } else if(val.channel_type == "99" || val.channel_type == "991"|| val.channel_type == "992"){
        slotModulePath ="m_keno/report/detail?module_bet_id=" + val.module_bet_id;
      } else if(val.channel_type == "89" || val.channel_type == "891" || val.channel_type == "892"){
        slotModulePath ="m_lottery3/report/detail?module_bet_id=" + val.module_bet_id;
      }
      //request API
      await axios
        .get(requestUrl + slotModulePath, {
          headers: {
            Authorization: this.token,
            "channel-type": this.channelType,
          },
        })
        .then((response) => {
          if (response.data.code) {
            this.detailLoading = false;
            this.isShow = true;
            this.getInvoiceDetailData = response.data.data;
          }
        })
        .catch((error) => console.log(error));
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.invoice_id);
      this.getLoadingDetail = false;
    },
    async loadDetailData(_id) {
      this.detailData = [];
      this.getLoadingDetail = true;

      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
      // let request = new Request;
        await axios
          .get(
            process.env.VUE_APP_API_URL + this.variableModulePathDetail + _id,
            {
              headers: {
                Authorization: this.token,
              },
            }
          )
          .then((response) => {
            if (response.data.code) {
              this.detailData = response.data.data;
              this.getLoadingDetail = false;
            }
          })
          .catch((error) => console.log(error));
    },

    dateWinLoseFilterValue: function (params) {
      this.dateFilterRB = params;
      this.dataRB = {
        page: 1,
        rowsPerPage: 10,
      };
      this.reportBetting();
    },
    reset() {
      this.$refs.form.reset();
    },

    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getUserCurrency() {
      if (this.userInfor.currency_type == 1) {
        return "$";
      } else if (this.userInfor.currency_type == 2) {
        return "៛";
      } else if (this.userInfor.currency_type == 3) {
        return "฿";
      }
    },

    async reportBetting() {
      let body = {};
      if (
        this.dateFilterRB.startDate != null &&
        this.dateFilterRB.endDate != null
      ) {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
          start_date: this.dateFilterRB.startDate,
          end_date: this.dateFilterRB.endDate,
        };
      } else {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
        };
      }
      await this.fetchReportBetting(body);
    },
    ...mapActions("$_myinfo", ["fetchMyInfo"]),
    ...mapMutations("$_allstatement", ["REPORT_BETTING"]),
    ...mapActions("$_allstatement", ["fetchReportBetting"]),
  },
};
</script>

<style>
.report-table .v-data-table__wrapper{
  overflow-y: scroll!important;
}
</style>
<style>
.header {
  font-size: 16px;
}

.cir-red{
  border:0!important;
  padding-bottom:2px;
  border-radius: 25px;
  font-size:1rem!important
}
.cir-blue{
  border:0!important;
  padding-bottom:2px;
  border-radius: 25px;
  font-size:1rem!important
}
.cir-green{
  border:0!important;
  padding-bottom:2px;
  border-radius: 25px;
  font-size:1rem!important
}
.cir-cancel{
  border:0!important;
  padding-bottom:2px;
  border-radius: 25px;
  font-size:1rem!important
}
.cbcir-black{
  background: white;
  border:0!important;
  padding:5px 15px!important;
  border-radius: 25px;
  font-size:1.2rem!important;
  color: #212427!important;
}

.cbcir-red{
  background: #f44336;
  border:0!important;
  padding:5px 15px!important;
  border-radius: 25px;
  font-size:1.2rem!important;
  color:white!important;
}
.cbcir-blue{
  background: #1976d2;
  border:0!important;
  padding:5px 15px!important;
  border-radius: 25px;
  font-size:1.2rem!important;
  color:white!important;
}
.cbcir-green{
  background: #999933;
  border:0!important;
  padding:5px 15px!important;
  border-radius: 25px;
  font-size:1.2rem!important;
  color:white!important;
}
.cbcir-cancel{
  background: grey;
  color:white!important;
  border:0!important;
  padding:5px 15px!important;
  border-radius: 25px;
  font-size:1.2rem!important
}
.report-amount-lose {
  color: red;
  font-weight: 700;
  font-size: 18px !important;
}

.report-amount-win {
  color: yellow;
  font-weight: 700;
  font-size: 18px !important;
}

.report-amount {
  color: #1976d2;
  
  font-weight: 700;
  font-size: 18px !important;
}

.container {
  height: auto !important;
}
.v-btn-toggle{
  border-radius: 4px;
  display: inline-flex;
  max-width: 100%;
}
</style>