<template>
  <div>
    <v-row class="ma-0 w-100">
      <!-- {{ passBetType }} -->

      <div style="width: 100% !important; max-width: 100% !important;"
        class="machine-baccarat-three-section flex-grow-1">
        <div class="machine-baccrat-button machine-red-title machine-play-red-bg" 
          :class="getSelectedFantanBtn && getSelectedFantanBtn.button && getSelectedFantanBtn.button.name == passBetType[2].name 
          && getSelectedFantanBtn.channelType == passChannelType ? 'machine-game-active' : ''"
          @click="_updatedSelectedBtn(passBetType[2])">
          <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">
            {{ passBetType[2] ? passBetType[2].payout : '1.95' }}
            <br/>
            <span class="machine-button-name machine-button-font fantan-red">{{ passBetType[2] ? passBetType[2].name.toUpperCase() : 'NA' }}</span>
          </p>
        </div>
        <!-- Down Left -->
        <div class="row">
          <div class="col-6" style="margin-right: 0; padding-right: 2px;">
            <div class="machine-baccrat-button reserver-margin machine-play-green-bg"
              :class="getSelectedFantanBtn && getSelectedFantanBtn.button && getSelectedFantanBtn.button.name == passBetType[12].name 
              && getSelectedFantanBtn.channelType == passChannelType ? 'machine-game-active' : ''"
              @click="_updatedSelectedBtn(passBetType[12])">
              <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">
                {{ passBetType[12] && passBetType[12].name.toUpperCase() === '1' ? 'FAN 1' : 'NA' }}
                <br/>
                <span class="machine-button-name machine-button-font">
                  {{ passBetType[12] && passBetType[12].payout === '4' ? '1:4' : '1:4' }}
                </span>
              </p>
            </div>
          </div>
          <div class="col-6" style="margin-left: 0; padding-left: 2px;">
            <div class="machine-baccrat-button reserver-margin  machine-play-green-bg"
              :class="getSelectedFantanBtn && getSelectedFantanBtn.button && getSelectedFantanBtn.button.name == passBetType[13].name 
              && getSelectedFantanBtn.channelType == passChannelType ? 'machine-game-active' : ''"
              @click="_updatedSelectedBtn(passBetType[13])">
              <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">
                {{ passBetType[13] && passBetType[13].name.toUpperCase() === '2' ? 'FAN 2' : 'NA' }}
                <br/>
                <span class="machine-button-name machine-button-font">
                  {{ passBetType[13] && passBetType[13].payout === '4' ? '1:4' : '1:4' }}
                </span>
              </p>
             
            </div>
          </div>
        </div>
      </div>

      <!-- Custom Spacer -->
      <div style="width: 10px;"> <!-- Adjust width as needed --></div>

      <div style="width: 100% !important; max-width: 100% !important;"
        class="machine-baccarat-three-section machine-blue-title flex-grow-1 ">
        <div class="machine-baccrat-button machine-play-blue-bg"
          :class="getSelectedFantanBtn && getSelectedFantanBtn.button && getSelectedFantanBtn.button.name == passBetType[3].name 
          && getSelectedFantanBtn.channelType == passChannelType ? 'machine-game-active' : ''"
          @click="_updatedSelectedBtn(passBetType[3])">
          <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">
            {{ passBetType[3] ? passBetType[3].payout : '1.95' }}
            <br/>
            <span class="machine-button-name machine-button-font fantan-blue">{{ passBetType[3] ? passBetType[3].name.toUpperCase() : 'NA' }}</span>
          </p>
        </div>

        <!--Down Right-->
        <div class="row">
          <div class="col-6" style="margin-right: 0; padding-right: 2px;">
            <div class="machine-baccrat-button reserver-margin machine-play-green-bg"
              :class="getSelectedFantanBtn && getSelectedFantanBtn.button && getSelectedFantanBtn.button.name == passBetType[14].name 
              && getSelectedFantanBtn.channelType == passChannelType ? 'machine-game-active' : ''"
              @click="_updatedSelectedBtn(passBetType[14])">
              <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">
                {{ passBetType[14] && passBetType[14].name.toUpperCase() === '3' ? 'FAN 3' : 'NA' }}
                <br/>
                <span class="machine-button-name machine-button-font">
                  {{ passBetType[14] && passBetType[14].payout === '4' ? '1:4' : '1:4' }}
                </span>
              </p>
            </div>
          </div>
          <div class="col-6" style="margin-left: 0; padding-left: 2px;">
            <div class="machine-baccrat-button reserver-margin machine-play-green-bg"
              :class="getSelectedFantanBtn && getSelectedFantanBtn.button && getSelectedFantanBtn.button.name == passBetType[15].name 
              && getSelectedFantanBtn.channelType == passChannelType ? 'machine-game-active' : ''"
              @click="_updatedSelectedBtn(passBetType[15])">
              <p class="machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button">
                {{ passBetType[15] && passBetType[15].name.toUpperCase() === '4' ? 'FAN 4' : 'NA' }}
              </p>
              <p class="machine-button-name machine-button-font">
                {{ passBetType[15] && passBetType[15].payout === '4' ? '1:4' : '1:4' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitFantan'" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"

export default {
  components: {
    CockXCasinoSubmitButton,
  },
  props: ['passBetType', 'passChannelType'],
  computed: {
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedFantanBtn: "getSelectedFantanBtn",
    }),
  },

  methods: {
    _updatedSelectedBtn(button) {
      let data = {
        button: button,
        channelType: this.passChannelType
      }
      this.UPDATE_SELECTED_FANTAN_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_FANTAN_BUTTON_IN_ALL",
    ]),
  }
}
</script>

<style>
 .fan-button {
  font-size: large !important;
 }
 .machine-button-font .fan-button {
  margin-top: 0px !important;
 }
.margin-fantan-button-name {
  margin: 0 !important;
}
</style>