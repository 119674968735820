<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="list-result-head">
          <ul class="mb-0">
            <li>
              <span class="mt-5"> {{ $t("title.total_betting") }}  </span>
              <span class="float-right pr-5 " style="font-weight:bold">
                {{ currentBetting && currentBetting.total_amount ?  formatMoney(currentBetting.total_amount): '0.00' }}
              </span>
            </li>
          </ul>
        </div>
        <div>
          <div>
            <div class="title-current">{{ $t("title.result") }}</div>
          </div>
          <div class="list-result-body">
            <table class="tb-bet">
              <tr
                v-for="(item, i) in getLastFiveResults"
                :key="i"
                class="ng-star-inserted current-betting-tr"
              >
                <td>
                    {{ item.date.split("-").join("").slice(0, 8) + "#" + item.no }}
                </td>
                <td>
                  {{item.total_random }}
                </td>
                <td class="print">
                 {{item.small_large.name[0] == "s" ? "U" : "O" }}
                </td>
                <td class="print">
                  {{item.range.name }}
                 </td>
              </tr>
            </table>
           
          </div>
        </div>
        <div>
          <div>
            <div class="title-current">
              {{ $t("title.lo_current_betting") }}
            </div>
            <h6
              class="sub-title pr-1 pl-2 mb-1"
              style="color: #212427"
              v-if="
                currentBetting &&
                currentBetting.count &&
                currentBetting.count > 0
              "
            >
              {{
                currentBetting && currentBetting.list
                  ? moment(currentBetting.date).format("YYYYMMDD") +
                    "#" +
                    currentBetting.list[0].no
                  : "loading..."
              }}
              <span class="float-right">
                <button
                  class="btn-link link print-all"
                  @click="openPrintMultiple()"
                >
                  {{ $t("title.ticket") }}
                </button>
              </span>
            </h6>
          </div>
          <div class="list-result-body current-betting-list">
            <table class="tb-bet">
              <tr
                v-for="(item, i) in currentBetting.list"
                :key="i"
                class="ng-star-inserted current-betting-tr"
              >
                <td v-if="item.betting && item.betting.length > 1">
                  <span v-if="$i18n.locale == 'khmer'" style="font-weight:700">
                    {{ item.betting && item.betting[0] == "s" ?  $t('title.small') :  $t('title.large') }}
                  </span>
                  <span v-else style="font-weight:700">
                    {{ item.betting && item.betting[0] == "s" ?  $t('title.small') : $t('title.large') }}
                  </span>
                </td>
                <td v-else style="font-weight:700">
                  {{ item.betting && item.betting[0] ? item.betting[0] : "" }}
                </td>
                <td>
                  {{ formatMoney(item.bet_amount) + ' x ' + item.payout + ' = '+ '???' }}
                </td>
                <td class="print">
                  <button
                    class="btn-link link"
                    @click="printTicket(item, item._id)"
                  >
                    <span class="fontKhmer">{{ $t("title.ticket") }}</span>
                  </button>
                  <!-- <input
                    type="checkbox"
                    :value="[{ item }]"
                    v-model="selectedAddOns"
                    class="myCheck"
                  /> -->
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div>
            <div class="title-current">
              {{ $t("title.lo_previous_betting") }}
            </div>
            <h6
            class="sub-title pr-1 pl-2"
            style="color: #212427"
            v-if="
            previousBetting &&
            previousBetting.length &&
            previousBetting.length > 0
            "
          >
            {{
              previousBetting
                ? moment(previousBetting.time).format("YYYYMMDD") +
                  "#" +
                  previousBetting[0].no
                : "loading..."
            }}
            <span class="float-right">
              <button
                class="btn-link link print-all"
                @click="openPrintMultiple()"
              >
                {{ $t("title.ticket") }}
              </button>
            </span>
          </h6>
          </div>
          <div class="list-result-body previous-betting-list">
            <table class="tb-bet">
              <tr
                v-for="(item, i) in previousBetting"
                :key="i"
                class="ng-star-inserted current-betting-tr"
              >
                <td v-if="item.betting && item.betting.length > 1">
                  <span v-if="$i18n.locale == 'khmer'" style="font-weight:700">
                    {{ item.betting && item.betting[0] == "s" ? $t('title.small') : $t('title.large') }}
                  </span>
                  <span v-else style="font-weight:700">
                    {{ item.betting && item.betting[0] == "s" ? $t('title.small') : $t('title.large') }}
                  </span>
                </td>
                <td v-else style="font-weight:700">
                  {{ item.betting && item.betting[0] ? item.betting[0] : "" }}
                </td>
                <td>
                  {{ formatMoney(item.bet_amount) + ' x ' + item.payout + ' = ' }} <span :style="item.amount_win >= 0 ? 'color:#003466' :'color:#cd4848' ">{{  formatMoney(item.case)  }}</span>
                </td>
                <td class="print">
                  <button
                    class="btn-link link"
                    @click="printTicket(item, item._id)"
                  >
                    <span class="fontKhmer">{{ $t("title.ticket") }}</span>
                  </button>
                  <!-- <input
                    type="checkbox"
                    :value="[{ item }]"
                    v-model="selectedAddOns"
                    class="myCheck"
                  /> -->
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-col>
    </v-row>
        <!-- Print One Ticket On Select -->
        <v-dialog v-model="dialogPrintTicket" persistent max-width="500px">
          <div id="printMe" hidden>
            <div style="max-width: 260px; margin-left: 0px" v-if="items">
              <div class="kh-mef-14" style="text-align: center">
                <span style="font-size: 18px" class="fontKhmer">
                  {{ userInfor.user_name }}</span
                >
                <div>
                  <span style="font-size: 14px" class="fontKhmer">{{
                    drawTicketLineOnPrint
                  }}</span>
                </div>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.ticket_id") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items._id }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.game_id") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{
                    items.time
                      ? items.time.split("-").join("").slice(0, 8) + "#" + items.no
                      : "loading..."
                  }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.date") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items.time }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.type_betting") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  <span v-if="$i18n.locale == 'khmer'">
                    {{
                      items.betting && items.betting[0] == "s"
                        ? $t('title.small')
                        : items.betting && items.betting[0] == "l"
                        ? $t('title.large')
                        : items.betting
                        ? items.betting[0]
                        : "loading..."
                    }}
                  </span>
                  <span v-else-if="$i18n.locale != 'khmer'">
                    {{
                      items.betting && items.betting[0] == "s"
                        ? $t('title.small')
                        : items.betting && items.betting[0] == "l"
                        ? $t('title.large')
                        : items.betting
                        ? items.betting[0]
                        : "loading..."
                    }}
                  </span>
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.bet_point") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ formatMoney(items.bet_amount) + 'x' + items.payout + '='+ '???' }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.win_loss") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items.is_win == null ? "???" : items.is_win }}
                </span>
              </div>
              <div style="margin-left: 10px">
                <span style="font-size: 14px" class="fontKhmer">{{
                  drawTicketLineOnPrint
                }}</span>
              </div>
              <div style="text-align: center; margin-top: -30px">
                <p
                  class="fontKhmer"
                  style="font-size: 16px; text-align: center; margin-left: -10px"
                >
                  {{ WebsiteName }}
                </p>
              </div>
            </div>
          </div>
          <v-card class="justify-center">
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" style="width:15%"  @click="closePrintDialog()">   <span></span> {{
             $t("invoice.cancel")
              }}</v-btn>
              <v-btn color="red darken-1"  style="width:15%"   @click="print()">{{
                $t("invoice.print") 
              }}</v-btn>
            </v-card-actions>
            <v-card-title class="justify-center username-title">
              {{ userInfor.user_name }}
            </v-card-title>
            <v-card-title class="justify-center draw-ticket-line">
              <span>{{ drawTicketLine }}</span>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.ticket_id") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items._id }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.game_id") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{
                  items.time
                    ? items.time.split("-").join("").slice(0, 8) + "#" + items.no
                    : ""
                }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.date") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items.time }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.type_betting") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1 pl-5">
                <span v-if="$i18n.locale == 'khmer'">
                  {{
                    items.betting && items.betting[0] == "s"
                      ? $t('title.small')
                      : items.betting && items.betting[0] == "l"
                      ?  $t('title.large')
                      : items.betting
                      ? items.betting[0]
                      : "loading..."
                  }}
                </span>
                <span v-else-if="$i18n.locale != 'khmer'">
                  {{
                    items.betting && items.betting[0] == "s"
                      ? $t('title.small')
                      : items.betting && items.betting[0] == "l"
                      ? $t('title.large')
                      : items.betting
                      ? items.betting[0]
                      : "loading..."
                  }}
                </span>
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.bet_point") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items.amount }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.win_loss") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items.is_win == null ? "???" : items.is_win }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center draw-ticket-line">
              <span>{{ drawTicketLine }}</span>
            </v-card-title>
            <v-card-title style="margin-top: -25px" class="justify-center">
              <span style="font-size: 14px; opacity: 0.5">{{ WebsiteName }}</span>
            </v-card-title>
          </v-card>
        </v-dialog>
        <!-- Print selected tickets -->
        <v-dialog
          v-model="dialogPrintTicketMulti"
          persistent
          max-width="500px"
          style="margin-top: 170px !important"
        >
          <div id="printMeMultiTicket" hidden>
            <div
              style="max-width: 260px; margin-left: 0px"
              v-for="(item, i) in this.selectedAddOns"
              :key="i"
            >
              <div class="kh-mef-14">
                <div class="kh-mef-14" style="text-align: center">
                  <span
                    class="fontKhmer"
                    style="font-size: 28px; margin-left: -25px"
                  >
                    {{ userInfor.user_name }}</span
                  >
                  <div>
                    <span class="fontKhmer" style="font-size: 18px">{{
                      drawTicketLine
                    }}</span>
                  </div>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.ticket_id") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item._id }}
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.game_id") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{
                      item[0].item.time
                        ? item[0].item.time.split("-").join("").slice(0, 8) +
                          "#" +
                          item[0].item.no
                        : "loading..."
                    }}
                  </span>
                </div>
                <div class="row">
                  <div
                    class="col-5 fontKhmer"
                    style="font-weight: bold; font-size: 12.5px"
                  >
                    {{ $t("invoice.date") }}:
                  </div>
                  <div
                    class="col-5 fontKhmer"
                    style="
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: -25px;
                    "
                  >
                    {{ item[0].item.time }}
                  </div>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.type_betting") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    <span v-if="$i18n.locale == 'khmer'">
                      {{
                        item[0].item.betting && item[0].item.betting[0] == "s"
                          ?  $t('title.small')
                          : item[0].item.betting && item[0].item.betting[0] == "l"
                          ?  $t('title.large')
                          : item[0].item.betting
                          ? item[0].item.betting[0]
                          : "loading..."
                      }}
                    </span>
                    <span v-else-if="$i18n.locale != 'khmer'">
                      {{
                        item[0].item.betting && item[0].item.betting[0] == "s"
                          ? "Under"
                          : item[0].item.betting && item[0].item.betting[0] == "l"
                          ? "Over"
                          : item[0].item.betting
                          ? item[0].item.betting[0]
                          : "loading..."
                      }}
                    </span>
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.bet_point") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item.amount }}
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.win_loss") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item.is_win == null ? "???" : item[0].item.is_win }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 18px; opacity: 0.9">{{
                    drawTicketLine
                  }}</span>
                </div>
                <div style="text-align: center">
                  <p
                    class="fontKhmer"
                    style="font-size: 16px; text-align: center; margin-left: -10px"
                  >
                    {{ WebsiteName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <v-card class="justify-center">
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1"  style="width:15%"  @click="closePrintMultiDialog()">{{
                $t("invoice.cancel")
              }}</v-btn>
              <v-btn color="red darken-1"  style="width:15%"  @click="printMulti()">{{
                $t("invoice.print")
              }}</v-btn>
            </v-card-actions>
            <div v-for="(item, i) in this.selectedAddOns" :key="i">
              <v-card-title class="justify-center username-title">
                {{ userInfor.user_name }}
              </v-card-title>
              <v-card-title class="justify-center draw-ticket-line">
                <span>{{ drawTicketLine }}</span>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.ticket_id") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item._id }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.game_id") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{
                    item[0].item.time
                      ? item[0].item.time.split("-").join("").slice(0, 8) +
                        "#" +
                        item[0].item.no
                      : ""
                  }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.date") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.time }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.type_betting") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1 pl-5">
                  <span v-if="$i18n.locale == 'khmer'">
                    {{
                      item[0].item.betting && item[0].item.betting[0] == "s"
                        ?  $t('title.small')
                        : item[0].item.betting && item[0].item.betting[0] == "l"
                        ?  $t('title.large')
                        : item[0].item.betting
                        ? item[0].item.betting[0]
                        : "loading..."
                    }}
                  </span>
                  <span v-else-if="$i18n.locale != 'khmer'">
                    {{
                      item[0].item.betting && item[0].item.betting[0] == "s"
                        ? "Under"
                        : item[0].item.betting && item[0].item.betting[0] == "l"
                        ? "Over"
                        : item[0].item.betting
                        ? item[0].item.betting[0]
                        : "loading..."
                    }}
                  </span>
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.bet_point") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.amount }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.win_loss") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.is_win == null ? "???" : item[0].item.is_win }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center draw-ticket-line">
                <span>{{ drawTicketLine }}</span>
              </v-card-title>
              <v-card-title style="margin-top: -25px" class="justify-center">
                <span style="font-size: 14px; opacity: 0.5">{{ WebsiteName }}</span>
              </v-card-title>
            </div>
          </v-card>
        </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import clickMixin from "~mixin/MX_ShareFunctionCollection.js"
export default {
  // DEFINE local VARIABLE
  data() {
    return {
      message: "Hello world",
      previousGame: {},
      current: {},
      dialogPrintTicket: false,
      dialogPrintTicketMulti: false,
      format: "YYYY-MM-DD",
      selectedAddOns: [],
      items: [],
    ticket_id: "",
    drawTicketLine: "--------------------------------------------------------",
    drawTicketLineOnPrint: "------------------------------------------------",
    WebsiteName: "lotto99",
        mixins: [clickMixin],
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_machine", {
      latestResults: "getLastResults",
      getLastFiveResults: "getLastFiveResults",
      currentBetting: "getCurrentBetting",
      previousBetting: "getPreviousBetting",
      currentGame: "getCurrentGame",
      countTime: "getCountTime",
    }),
  },
  methods: {
    moment,
    printTicket(item, ticket_id) {
      this.items = item;
      this.ticket_id = ticket_id;
      this.dialogPrintTicket = true;
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    closePrintDialog() {
      this.dialogPrintTicket = false;
    },
    openPrintMultiple() {
      if (this.selectedAddOns != "") {
        this.dialogPrintTicketMulti = true;
      }
    },
    printMulti() {
      this.$htmlToPaper("printMeMultiTicket");
    },
    closePrintMultiDialog() {
      var inputs = document.getElementsByClassName("myCheck");
      for (var i = 0, l = inputs.length; i < l; ++i) {
        if (inputs[i].checked) {
          inputs[i].checked = false;
        }
      }
      this.selectedAddOns = [];
      this.dialogPrintTicketMulti = false;
    },
    ...mapMutations("$_machine", [
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
    ]),
  },
};
</script>
<style>
.current-betting-tr > td {
  font-size: 0.8rem !important;
  color: #212427;
  
}

.tb-bet {
  table-layout: auto;
  padding-right:5px;
  width: 100%
}

table {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
  text-indent: initial;
  border-color: grey;
}
table > tr {
  vertical-align: middle;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.tb-bet td:first-child {
  width: 40px;
}
.tb-bet td,
.left-side > section .recent-bet .tb-bet th {
  outline: none;
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left:10px;
  padding-right:5px;
}
td {
  display: table-cell;
  vertical-align: inherit;
}
.result-loss {
  color: #8b0000;
}
.result-win {
  color: #00f;
}
.sub-title {
  color: #0437b4 !important;
  font-size: 0.85rem !important;
  padding-top: 7px;
  padding-bottom: 3px;
  height: 22px;
  font-weight: 700;
}
.current-betting-list {
  height: 150px;
  overflow-y: scroll;
}
.previous-betting-list {
  height: 187px;
  overflow-y: scroll;
}
.current-betting-tr:nth-child(odd) {
  background: #e7e9eb;
}
.print-all {
  color: red !important;
  font-size: 12px !important;
}
.myCheck{
  padding-left:5px;
  margin-left:5px
}
.tb-bet td, .left-side > section .recent-bet .tb-bet th{
  text-align: right;
}
</style>
