export default {
  methods: {
    findImageOfEachAnimal2(yearId) {
      switch (yearId) {
        case 1:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 1.png");
        case 2:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 2.png");
        case 3:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 3.png");
        case 4:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 4.png");
        case 5:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 5.png");
        case 6:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 6.png");
        case 7:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 7.png");
        case 8:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 8.png");
        case 9:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 9.png");
        case 10:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 10.png");
        case 11:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 11.png");
        case 12:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 12.png");
        default:
        // code block
      }
    },
    findImageOfEachAnimal(yearId) {
      switch (yearId) {
        case 1:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 1.png");
        case 2:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 2.png");
        case 3:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 3.png");
        case 4:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 4.png");
        case 5:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 5.png");
        case 6:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 6.png");
        case 7:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 7.png");
        case 8:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 8.png");
        case 9:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 9.png");
        case 10:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 10.png");
        case 11:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 11.png");
        case 12:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 12.png");
        default:
        // code block
      }
    },
    findResultImageOfEachAnimal(yearId) {
      switch (yearId) {
        case 1:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 1.png");
        case 2:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 2.png");
        case 3:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 3.png");
        case 4:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 4.png");
        case 5:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 5.png");
        case 6:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 6.png");
        case 7:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 7.png");
        case 8:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 8.png");
        case 9:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 9.png");
        case 10:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 10.png");
        case 11:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 11.png");
        case 12:
          return require("../../public/images/a_each_game/newYuki/Animal bet/Asset 12.png");
        default:
        // code block
      }
    },
    findMachineGameLogo(channelId) {
      switch (true) {
        case (channelId == '1' || channelId == '2' || channelId == '3' ||
          channelId == '4' || channelId == '15' ||
          channelId == '16'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/cockfight-image-box.jpg");
        case (channelId == '5'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/kla-kloukt-image-box.jpg");
        case (channelId == '6' || channelId == '7' || channelId == '8' || channelId == '9'|| channelId == '18' || channelId == '19'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/casinot-image-box.jpg");
        case (channelId == '94' || channelId == '941' || channelId == '942' ||
          channelId == '943' || channelId == '944' || channelId == '945' ||
          channelId == '946' || channelId == '947' || channelId == '948' ||
          channelId == '949'):
          return require("/public/images/a_game_access_global/machine_game_logo/Dragon tiger.png");
        case (channelId == '95' || channelId == '951' || channelId == '952' ||
          channelId == '953' || channelId == '954' || channelId == '955' ||
          channelId == '956' || channelId == '957' || channelId == '958' ||
          channelId == '959'):
          return require("/public/images/a_game_access_global/machine_game_logo/Baccarat01.png");
        case (channelId == '98' || channelId == '981' || channelId == '982' ||
          channelId == '983' || channelId == '984' || channelId == '985' ||
          channelId == '986' || channelId == '987' || channelId == '988' ||
          channelId == '989'):
          return require("/public/images/a_game_access_global/machine_game_logo/Lottery.png");
        case (channelId == '96' || channelId == '961' || channelId == '962' ||
          channelId == '963' || channelId == '964' || channelId == '965' ||
          channelId == '966' || channelId == '967' || channelId == '968' ||
          channelId == '969'):
          return require("/public/images/a_game_access_global/machine_game_logo/doDen.png");
        case (channelId == '97' || channelId == '971' || channelId == '972' ||
          channelId == '973' || channelId == '974' || channelId == '975' ||
          channelId == '976' || channelId == '977' || channelId == '978' ||
          channelId == '979'):
          return require("/public/images/a_game_access_global/machine_game_logo/Sic Bo.png");
        case (channelId == '93' || channelId == '931' || channelId == '932' ||
          channelId == '933' || channelId == '934' || channelId == '935' ||
          channelId == '936' || channelId == '937' || channelId == '938' ||
          channelId == '939'):
          return require("/public/images/a_game_access_global/machine_game_logo/Kla-klok.png");

        case (channelId == '10'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/lottery-image-box.jpg");
        case (channelId == '11'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/yuki-image-box.jpg");
        case (channelId == '12' || channelId == '17'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/apongt-image-box.jpg");
        case (channelId == '13'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/fantan-image-box.jpg");
        case (channelId == '14'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/taisiev-image-box.jpg");
        case (channelId == '20'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/han-image-box.jpg");
        case (channelId == '21'):
          return require("/public/images/a_game_access_global/cock_and_casino_live/han-image-box.jpg");
        default:
        // code block
      }
    },
    findImgKlakloukButton(channelId) {
      switch (channelId) {
        case 1:
          return require("/public/images/a_each_game/klaklok/new/0001.png");
        case 2:
          return require("/public/images/a_each_game/klaklok/new/0002.png");
        case 3:
          return require("/public/images/a_each_game/klaklok/new/0003.png");
        case 4:
          return require("/public/images/a_each_game/klaklok/new/0004.png");
        case 5:
          return require("/public/images/a_each_game/klaklok/new/0005.png");
        case 6:
          return require("/public/images/a_each_game/klaklok/new/0006.png");
        case 7:
          return require("/public/images/a_each_game/klaklok/new/Frame.png");
        default:
        // code block
      }
    },

    findImgApongButton(channelId) {
      switch (channelId) {
        case 1:
          return require("/public/images/a_each_game/6number/1.png");
        case 2:
          return require("/public/images/a_each_game/6number/2.png");
        case 3:
          return require("/public/images/a_each_game/6number/3.png");
        case 4:
          return require("/public/images/a_each_game/6number/4.png");
        case 5:
          return require("/public/images/a_each_game/6number/5.png");
        case 6:
          return require("/public/images/a_each_game/6number/6.png");
        default:
        // code block
      }
    },
  },
};
