<template>
  <div>
    <div class="lottery-menu-nav-head">
      <MainMenu />
    </div>
    <MachineGameSubMenuTab :passIsSubGame="true"/>
    <v-container class="sub-machine-game-body">
      <v-row class="ma-0">
        <LeftBlock :passChannelStatus="passChannelStatus"
          :passSelectedChannel="passSelectedChannel"  class="sub-game-flexi-left"/>
        
          <div class="card mx-0 right-block-container pt-0 mt-0 border-0 sub-game-flexi-right" style="justify-content:center">
            <!-- <MachineGameTab class="tab-style pb-2 pt-0 mb-0 px-1 d-none d-sm-flex "/> -->
            <KlakloukMachineBettingComponent
              v-if="checkerGame(_selectedGame(channelId)) == 'klaklouk'"
              :passBettingTypeMaster="passBettingTypeMaster"
              v-on:sentSubmitBetting="receiveSubmitBetting"
            />
            <TigerDragonMachineBettingComponent
              v-if="checkerGame(_selectedGame(channelId)) == 'dragon'"
              :passBettingTypeMaster="passBettingTypeMaster"
              v-on:sentSubmitBetting="receiveSubmitBetting"
            />
            <BaccaratMachineBettingComponent
              v-if="checkerGame(_selectedGame(channelId)) == 'baccarat' "
              :passBettingTypeMaster="passBettingTypeMaster"
              v-on:sentSubmitBetting="receiveSubmitBetting"
            />
            <DodenMachineBettingComponent
            v-if="checkerGame(_selectedGame(channelId)) == 'doden'"
            :passBettingTypeMaster="passBettingTypeMaster"
            v-on:sentSubmitBetting="receiveSubmitBetting"
          />
          <SicBoMachineBettingComponent
          v-if="checkerGame(_selectedGame(channelId)) == 'sicbo'"
          :passBettingTypeMaster="passBettingTypeMaster"
          v-on:sentSubmitBetting="receiveSubmitBetting"
        />
        <TaiXiuMachineBettingComponent
        v-if="checkerGame(_selectedGame(channelId)) == 'taixiu'"
        :passBettingTypeMaster="passBettingTypeMaster"
        v-on:sentSubmitBetting="receiveSubmitBetting"
      />
      <HamMachineBettingComponent
        v-if="_selectedGame(channelId) == 'slot-ham'"
        :passBettingTypeMaster="passBettingTypeMaster"
        v-on:sentSubmitBetting="receiveSubmitBetting"
      />
      <ThreeFaceMachineBettingComponent
        v-if="_selectedGame(channelId) == 'slot-three_face'"
        :passBettingTypeMaster="passBettingTypeMaster"
        v-on:sentSubmitBetting="receiveSubmitBetting"
      />
        </div>
      </v-row>
    </v-container>
  
  </div>
</template>
<script>
import MainMenu from "~global_components/MainMenu.vue";
import MachineGameSubMenuTab from "~global_components/component_machine_game/machine_games_sub_menu_tab.vue";

import { mapGetters } from "vuex";
import LeftBlock from "./LeftBlock.vue";
import KlakloukMachineBettingComponent from "./BettingComponents/KlakloukMachineBettingComponent.vue";
import TigerDragonMachineBettingComponent from "./BettingComponents/TigerDragonMachineBettingComponent.vue";
import BaccaratMachineBettingComponent from "./BettingComponents/BaccaratMachineBettingComponent.vue";
// import MachineGameTab from "../../../../components/component_machine_game/TabsKlaklouk.vue";
import Mixin from "~mixin/MX_ShareFunctionCollection.js";
import DodenMachineBettingComponent from "./BettingComponents/DodenMachineBettingComponent.vue";
import SicBoMachineBettingComponent from "./BettingComponents/SicboMachineBettingComponent.vue";
import TaiXiuMachineBettingComponent from "./BettingComponents/TaiXiuMachineBettingComponent.vue";
import HamMachineBettingComponent from "./BettingComponents/TaiXiuMachineBettingComponent.vue";
import ThreeFaceMachineBettingComponent from "./BettingComponents/TaiXiuMachineBettingComponent.vue";
// MachineGameTab
export default {
  // components: { VideoLiveForDesktop, TableResult, BettingComponent, ReportBetting, ResultVideoApong },
  components: { MachineGameSubMenuTab, ThreeFaceMachineBettingComponent, HamMachineBettingComponent, TaiXiuMachineBettingComponent, SicBoMachineBettingComponent, LeftBlock, KlakloukMachineBettingComponent, TigerDragonMachineBettingComponent, BaccaratMachineBettingComponent, DodenMachineBettingComponent, MainMenu },
  props: [
    "passChannel",
    "passChannelStatus",
    "passResult",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passTodayReport",
    "passLoadingTodayReport",
    "passChannelList",
    "passSelectedChannel",
    "passCurrentBetting",
    "passNewChannelList",
    "passChannelType",
    "passUserCurrency",
  ],
  data() {
    return {
      mixins: [Mixin],
    };
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      channelObj: "getChannel",
    }),
    ...mapGetters("$_modules", {
            channelId: "getChannelId",
        }),
  },
  methods: {
    checkerGame(page){
      switch(true){
        case (page =="slot-baccarat" || page =="slot-baccarat10" ||page =="slot-baccarat2" ||page =="slot-baccarat3" ||page =="slot-baccarat4" ||page =="slot-baccarat5" ||page =="slot-baccarat6" ||page =="slot-baccarat7" ||page =="slot-baccarat8" ||page =="slot-baccarat9" )
        : return 'baccarat';
        case (page =="slot-dragon" || page =="slot-dragon10" ||page =="slot-dragon2" ||page =="slot-dragon3" ||page =="slot-dragon4" ||page =="slot-dragon5" ||page =="slot-dragon6" ||page =="slot-dragon7" ||page =="slot-dragon8" ||page =="slot-dragon9" )
        : return 'dragon';
        case (page =="slot-klaklouk" || page =="slot-klaklouk10" ||page =="slot-klaklouk2" ||page =="slot-klaklouk3" ||page =="slot-klaklouk4" ||page =="slot-klaklouk5" ||page =="slot-klaklouk6" ||page =="slot-klaklouk7" ||page =="slot-klaklouk8" ||page =="slot-klaklouk9" )
        : return 'klaklouk'
        case (page =="slot-doden" || page =="slot-doden10" ||page =="slot-doden2" ||page =="slot-doden3" ||page =="slot-doden4" ||page =="slot-doden5" ||page =="slot-doden6" ||page =="slot-doden7" ||page =="slot-doden8" ||page =="slot-doden9" )
        : return 'doden'
        case (page =="slot-taixiu" || page =="slot-taixiu10" ||page =="slot-taixiu2" ||page =="slot-taixiu3" ||page =="slot-taixiu4" ||page =="slot-taixiu5" ||page =="slot-taixiu6" ||page =="slot-taixiu7" ||page =="slot-taixiu8" ||page =="slot-taixiu9" )
        : return 'taixiu'
        case (page =="slot-sicbo" || page =="slot-sicbo10" ||page =="slot-sicbo2" ||page =="slot-sicbo3" ||page =="slot-sicbo4" ||page =="slot-sicbo5" ||page =="slot-sicbo6" ||page =="slot-sicbo7" ||page =="slot-sicbo8" ||page =="slot-sicbo9" )
        : return 'sicbo'
      }
    },
    _clearMoney() {
      this.$emit("handleClearBtn");
    },
    receiveSubmitBetting(recieveBettingData) {
      this.$emit("sentSubmitBetting", recieveBettingData);
    },
  },
};
</script>

<style>
.btn-switch-channel:has(.to-hidden) {
  display: none !important;
}

.video-section {
  position: relative;
  overflow: hidden;
  background-color: #012d98
}

.tab-style{
  width:100%;
  display: inline-flex;
}
</style>