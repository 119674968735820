<template>
  <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0 pt-0" v-if="passBettingTypeMaster">
    <div class="g-summary">
      <div class="ri-dd-count-down">
        <span 
          v-if="getClientTime && getClientTime.is_open_betting">{{ getClientTime.time }}
        </span>
        <marquee
          v-else-if="!passChannel.is_maintain && (passChannel.is_open == false || (getClientTime && !getClientTime.is_open_betting))"
          scrollamount="15" class="open-betting-countdown"
          width="100%" direction="left;">
          {{ $t('title.gameIsClose') }}
        </marquee>
        <div v-else class="open-betting-countdown">
          <img :src="spinningNotAllow"
              style="width: 50px; height: auto; padding: 0px" alt="" />
        </div>
      </div>
    
      <div class="col-12 px-2">
        <div class="row px-0 mx-0">
          <div
            style="width: 95% !important"
            class="dd-x-title-dd-1 ri-taixiu-betting-col-dd-1 ri-x-green-dd-1 col-6 text-center pr-2"
            :class="
              getDdBetting.bettingid == passBettingTypeMaster[0]._id
                ? 'ri-sicbo-betting-status'
                : ''
            "
            @click="
              __xbetting(
                passBettingTypeMaster[0]._id,
                passBettingTypeMaster[0].name
              )
            "
          >
            {{ $t("title.tai" + passBettingTypeMaster[0].name) }}
          </div>
          <div
            style="width: 95% !important"
            class="dd-x-title-dd-1 ri-taixiu-betting-col-dd-1 ri-x-red-dd-1 col-6 text-center pl-2"
            :class="
              getDdBetting.bettingid == passBettingTypeMaster[1]._id
                ? 'ri-sicbo-betting-status'
                : ''
            "
            @click="
              __xbetting(
                passBettingTypeMaster[1]._id,
                passBettingTypeMaster[1].name
              )
            "
          >
            {{ $t("title.tai" + passBettingTypeMaster[1].name) }}
          </div>
        </div>
      </div>

      <div class="ri-sicbo-betting-row-dd-2 px-2">
        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r1-c1-dd-2"
          :class="getDdBetting.bettingid == passBettingTypeMaster[2]._id ? 'ri-sicbo-betting-status' : ''"
          @click="__xbetting(passBettingTypeMaster[2]._id, passBettingTypeMaster[2].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{ passBettingTypeMaster[2].name }}</span>
              <span class="dd-x-text-dd-3">1: {{passBettingTypeMaster[2].payout_display}}</span>
            </span>
          </div>
        </div>
        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r1-c2-dd-2"
           :class="getDdBetting.bettingid == passBettingTypeMaster[3]._id ? 'ri-sicbo-betting-status' : ''"
           @click="__xbetting(passBettingTypeMaster[3]._id, passBettingTypeMaster[2].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{ passBettingTypeMaster[3].name }}</span>
              <span class="dd-x-text-dd-3">1:  {{passBettingTypeMaster[3].payout_display}}</span>
            </span>
          </div>
        </div>
        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r1-c3-dd-2"
          :class="getDdBetting.bettingid == passBettingTypeMaster[4]._id ? 'ri-sicbo-betting-status' : ''"
           @click="__xbetting(passBettingTypeMaster[4]._id, passBettingTypeMaster[2].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-1-MD">{{ passBettingTypeMaster[4].name }}</span>
              <span class="dd-x-text-dd-1-MD">1:  {{passBettingTypeMaster[4].payout_display}}</span>
            </span>
          </div>
        </div>
        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r1-c4-dd-2"
          :class="getDdBetting.bettingid == passBettingTypeMaster[5]._id ? 'ri-sicbo-betting-status' : ''"
           @click="__xbetting(passBettingTypeMaster[5]._id, passBettingTypeMaster[5].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{ passBettingTypeMaster[5].name }}</span>
              <span class="dd-x-text-dd-3">1:  {{passBettingTypeMaster[5].payout_display}}</span>
            </span>
          </div>
        </div>
        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r1-c5-dd-2"
          :class="getDdBetting.bettingid == passBettingTypeMaster[6]._id ? 'ri-sicbo-betting-status' : ''"
           @click="__xbetting(passBettingTypeMaster[6]._id, passBettingTypeMaster[6].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-3">{{ passBettingTypeMaster[6].name }}</span>
              <span class="dd-x-text-dd-3">1:  {{passBettingTypeMaster[6].payout_display}}</span>
            </span>
          </div>
        </div>

        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r3-c2-dd-2"
          :class="getDdBetting.bettingid == passBettingTypeMaster[7]._id ? 'ri-sicbo-betting-status' : ''"
           @click="__xbetting(passBettingTypeMaster[7]._id, passBettingTypeMaster[7].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-3-Pic Pic-black">&#9824;</span>
              <span class="dd-x-text-dd-3">1:  {{passBettingTypeMaster[7].payout_display}}</span>
            </span>
          </div>
        </div>
        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r3-c3-dd-2"
        :class="getDdBetting.bettingid == passBettingTypeMaster[8]._id ? 'ri-sicbo-betting-status' : ''"
           @click="__xbetting(passBettingTypeMaster[8]._id, passBettingTypeMaster[8].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-3-Pic Pic-black">&#9827;</span>
              <span class="dd-x-text-dd-3">1:  {{passBettingTypeMaster[8].payout_display}}</span>
            </span>
          </div>
        </div>
        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r3-c4-dd-2"
        :class="getDdBetting.bettingid == passBettingTypeMaster[9]._id ? 'ri-sicbo-betting-status' : ''"
           @click="__xbetting(passBettingTypeMaster[9]._id, passBettingTypeMaster[8].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-3-Pic Pic-red">&#9829;</span>
              <span class="dd-x-text-dd-3">1:  {{passBettingTypeMaster[9].payout_display}}</span>
            </span>
          </div>
        </div>
        <div class="ri-sicbo-betting-col-dd-2 ri-xcol-r3-c5-dd-2"
        :class="getDdBetting.bettingid == passBettingTypeMaster[10]._id ? 'ri-sicbo-betting-status' : ''"
           @click="__xbetting(passBettingTypeMaster[10]._id, passBettingTypeMaster[10].name)">
          <div>
            <span>
              <span class="dd-x-title-dd-3-Pic Pic-red">&#9830;</span>
              <span class="dd-x-text-dd-3">1:  {{passBettingTypeMaster[10].payout_display}}</span>
            </span>
          </div>
        </div>

      </div>

    </div>

    <CoinButton :passGetButton="getButton" :passChannel="passChannel" :passPlaceCoin="getBettingAmount"/>
    <SubmitBtn :passCheckAllowSubmit="_checkAllowSubmit" v-on:handleSelectButton="_submitBetting()"
      v-on:handleClearBtn="_clearMoney" />
    <DodenMachineReportBettingComponent :passLoadingTodayReport="passLoadingTodayReport" />
    <v-row class="mx-0 justify-center w-100 mt-2">
      <v-col col-12 style="padding: 0">
        <div class="show-on-mobile pt-5">
          <TableResult :rows="6" :cols="35" />
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import "@/assets/css/homepage.css";
import CoinButton from "~global_components/component_machine_game/CoinBettingButton.vue";
import SubmitBtn from "~global_components/component_machine_game/SubmitBtnMachine.vue";
import TableResult from "../../TableResults.vue";
import DodenMachineReportBettingComponent from "../../_ReportBettingComponent/DodenMachineReportBettingComponent.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    DodenMachineReportBettingComponent,
    CoinButton,
    SubmitBtn,
    TableResult,
  },
  props: [
    "passChannelStatus",
    "passBettingTypeMaster",
    "passWeight",
    "passSelectedChannel",
    "passUserCurrency",
    "passLoadingTodayReport",
  ],
  data() {
    return {
      selectedButton: {},
      selectedBtnClassMeron: false,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedBtnClassPairBanker: false,
      selectedBtnClassPairPlayer: false,
      csBanker1: "",
      csBanker2: "",
      csBanker3: "",
      csPlayer1: "",
      csPlayer2: "",
      csPlayer3: "",
      totalP: 0,
      totalB: 0,
      selectedCoin: false,
    };
  },
  watch: {},
  computed: {
    meronButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[0];
      else return {};
    },
    walaButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[1];
      else return {};
    },
    tieButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[2];
      else return {};
    },
    pairBankerButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[3];
      else return {};
    },
    pairPlayerButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[4];
      else return {};
    },
    _checkAllowSubmit() {
      if (
        this.getBettingAmount > 0 &&
        (this.selectedBtnClassMeron ||
          this.selectedBtnClassTie ||
          this.selectedBtnClassWala ||
          this.selectedBtnClassPairBanker ||
          this.selectedBtnClassPairPlayer)
      )
        return true;
      else return false;
    },
    ...mapGetters("$_dashboard_live", {
      dialogBetting: "getDialogBetting",
      getButton: "getButton",
      amountMin: "getAmountMin",
      amountMx: "getAmountMax",
      getCountdown: "getCountdown",
      passChannel: "getChannel",
      passCurrentBetting: "getCurrentBetting",
      getClientTime: "getClientTime",
      loading: "getLoading",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
      getDdBetting: 'getBettingDdAmount',
    }),
  },

  methods: {
    ...mapMutations("$_modules", [
      "UPDATE_BETTING_DD_AMOUNT",
      "UPDATE_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT",
      "CLEAR_BETTING_DD_AMOUNT",
    ]),
    __xbetting(id, name) {
      this.UPDATE_BETTING_DD_AMOUNT([name, this.getBettingAmount, id]);
    },
    __xcheckallowsubmit() {
      if (this.getDdBetting.bettingid != '' && this.getDdBetting.amount > 0) {
        return false;
      } else {
        return true;
      }
    },
    _inputAmount(money) {
      this.UPDATE_BETTING_AMOUNT(money);
      //this._playSoundInsertCoin()
      this.UPDATE_BETTING_DD_AMOUNT([this.getDdBetting.bettingname, this.getBettingAmount, this.getDdBetting.bettingid])
      this.__xcheckallowsubmit();
    },
    formatBtnCurrencyKh(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "ព";
        case amount >= 10000 && amount <= 999999:
          return amount / 10000 + "ម";
        case amount >= 1000000 && amount <= 99999999999:
          return amount / 1000000 + "ល";
        default:
          return amount;
      }
    },
    formatBtnCurrency(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "K";
        default:
          return amount;
      }
    },

    _detectCoin(index) {
      switch (index) {
        case 0:
          return '/images/coins/blue.png';
        case 1:
          return '/images/coins/pink.png';
        case 2:
          return '/images/coins/green.png';
        case 3:
          return '/images/coins/cyan.png';
        case 4:
          return '/images/coins/orange.png';
        case 5:
          return '/images/coins/red.png';
        case 6:
          return '/images/coins/blue.png';
        case 7:
          return '/images/coins/pink.png';
        case 33:
          return '/images/coins/grey.png';
        default:
          return ''
      }
    },
    _detectColor(index) {
      switch (index) {
        case 0:
          return 'black';
        case 1:
          return 'black';
        case 2:
          return 'black';
        case 3:
          return 'black';
        case 4:
          return 'black';
        case 5:
          return 'black';
        case 6:
          return 'black';
        case 7:
          return 'black';
        case 99:
          return 'white';
        default:
          return ''
      }
    },
    _detectCurrentMoneyCoinMeron(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorMeron(money) {
      switch (true) {
        case money < 5:
          return "black";
        case money < 10:
          return "black";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "grey";
        default:
          return "";
      }
    },
    _detectCurrentMoneyCoinWala(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorWala(money) {
      switch (true) {
        case money < 5:
          return "blue";
        case money < 10:
          return "#F92A53";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "grey";
        default:
          return "";
      }
    },
    _detectCurrentMoneyCoinTie(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorTie(money) {
      switch (true) {
        case money < 5:
          return "blue";
        case money < 10:
          return "#F92A53";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "white";
        default:
          return "";
      }
    },

    _clearMoney() {
      /*this.selectedBtnClassMeron = false;
      this.selectedBtnClassTie = false;
      this.selectedBtnClassWala = false;
      this.selectedBtnClassPairBanker = false;
      this.selectedBtnClassPairPlayer = false;
      this.selectedCoin = false;
      this.INPUT_BETTING_AMOUNT(0);
      this.UDPDATE_SELECTE_BTN("");
      this.UPDATE_SELECT_COIN("");
      */

      this.CLEAR_BETTING_AMOUNT()
      this.CLEAR_BETTING_DD_AMOUNT()
      this.__xcheckallowsubmit();
    },
    _submitBetting() {
      /*let submitBettingData = {
        type_id: this.selectedButton._id,
        amount: this.getBettingAmount,
      };
      this.$emit("sentSubmitBetting", submitBettingData);
      this._clearMoney();*/

      let submitBettingData = {
        type_id: this.getDdBetting.bettingid,
        amount: this.getDdBetting.amount
      };
      this.$emit("sentSubmitBetting", submitBettingData);
      this._clearMoney();
    },
    _allowBtnBet(selectedBtn) {
      // if (!this.selectedCoin) {
      //   this.$toastr.e(this.$t("title.selectCoin"));
      //   return false;
      // }
      switch (selectedBtn) {
        case "selectedMeron":
          // console.log("selectedMeron");
          this.UDPDATE_SELECTE_BTN("selectedBanker");
          this.selectedButton = this.meronButton;
          this.selectedBtnClassMeron = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedTie":
          this.UDPDATE_SELECTE_BTN("selectedTie");
          this.selectedButton = this.tieButton;
          this.selectedBtnClassTie = true;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedWala":
          this.UDPDATE_SELECTE_BTN("selectedPlayer");
          this.selectedButton = this.walaButton;
          this.selectedBtnClassWala = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedPairBanker":
          this.UDPDATE_SELECTE_BTN("selectedPairBanker");
          this.selectedButton = this.pairBankerButton;
          this.selectedBtnClassPairBanker = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedPairPlayer":
          this.UDPDATE_SELECTE_BTN("selectedPairPlayer");
          this.selectedButton = this.pairPlayerButton;
          this.selectedBtnClassPairPlayer = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          break;
      }
    },
    ...mapMutations("$_dashboard_live", [
      "BETTING_DIALOG",
      "UPDATE_PLAYER_CARD",
      "UPDATE_BANKER_CARD",
      "RESET_CARD",
      "UDPDATE_SELECTE_BTN",
      "UPDATE_SELECT_COIN"
    ]),
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
    ]),
  },
};
</script>
<style scoped>

.ri-dd-count-down{
  width: 100%;
  height: auto;
  background-color: #386faf8f;
  margin-bottom: 6px;
  margin-top: 0px;
  text-align: center;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  
  
}
.ri-dd-count-down span{
  color: #11f5c4;
  font-size: 2rem;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
.ri-dd-count-down marquee{
  color: #dc3545;
  font-size: 30px;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}









.ri-xcol-r1-c1-dd-1 {
  grid-area: r1c1dd1;
}

.ri-xcol-r1-c6-dd-1 {
  grid-area: r1c6dd1;
}


.ri-sicbo-betting-row-dd-1 {
  display: grid;
  grid-template-areas:
    'r1c1dd1 r1c6dd1';
  gap: 6px;
  width: 100%;
  height: 100%;


}


.ri-sicbo-betting-col-dd-1 {
  font-size: 1vw;
  font-weight: 900;
  color: white;
  border: 1px solid white;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 3px;


}

.ri-sicbo-betting-col-dd-1:hover {
  cursor: pointer;
}

.ri-sicbo-betting-col-dd-1 div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

}

.ri-sicbo-betting-col-dd-1 div span {
  width: 100%;
  text-align: center;
}


.ri-sicbo-betting-status {
  background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%) !important;
}

.ri-x-green-dd-1 {
  color: #4466fd;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
}

.ri-x-red-dd-1 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
}


.dd-x-title-dd-1 {
  display: block;
  font-size: 7vw;
  text-transform: uppercase;
}

.dd-x-text-dd-1 {
  display: block;
  color: white !important;
  font-size:15px;
  
}

.dd-x-title-dd-2 {
  display: block;
  font-size: 7vw;
  text-transform: uppercase;
}

.dd-x-text-dd-2 {
  display: block;
  font-size:15px;
}

.dd-x-title-dd-3 {
  display: block;
  font-size: 4vw;
}

.dd-x-text-dd-3 {
  display: block;
  font-size: 14px;
}

.dd-x-title-dd-1-MD {
  display: block;
  font-size: 45px !important;
  color: #88da05;
}

.dd-x-text-dd-1-MD {
  display: block;
  font-size: 20px !important;
  color: #f7f7f7;
}

.dd-x-title-dd-3-Pic {
  font-size: 45px;
  line-height: 30px;
}

.Pic-black {
  color: #212427;
}

.Pic-red {
  color: #f34141;
}

/*------------------- */
.ri-xcol-r1-c1-dd-2 {
  grid-area: r1c1dd2;
}

.ri-xcol-r1-c2-dd-2 {
  grid-area: r1c2dd2;
}

.ri-xcol-r1-c3-dd-2 {
  grid-area: r1c3dd2;
}

.ri-xcol-r1-c4-dd-2 {
  grid-area: r1c4dd2;
}

.ri-xcol-r1-c5-dd-2 {
  grid-area: r1c5dd2;
}





.ri-xcol-r3-c2-dd-2 {
  grid-area: r3c2dd2;
}

.ri-xcol-r3-c3-dd-2 {
  grid-area: r3c3dd2;
}

.ri-xcol-r3-c4-dd-2 {
  grid-area: r3c4dd2;
}

.ri-xcol-r3-c5-dd-2 {
  grid-area: r3c5dd2;
}



.ri-sicbo-betting-row-dd-2 {
  margin-top: 6px;
  display: grid;
  grid-template-areas:
    'r1c1dd2 r1c2dd2 r1c3dd2 r1c4dd2 r1c5dd2'
    'r3c2dd2 r3c3dd2 r1c3dd2 r3c4dd2 r3c5dd2';

  gap: 7px;
  width: 100%;
  height: 100%;


}


.ri-sicbo-betting-col-dd-2 {
  font-size: 1vw;
  font-weight: 900;
  color: white;
  border: 1px solid white;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 3px;


}

.ri-sicbo-betting-col-dd-2:hover {
  cursor: pointer;
}

.ri-sicbo-betting-col-dd-2 div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

}

.ri-sicbo-betting-col-dd-2 div span {
  width: 100%;
  text-align: center;
}


.ri-sicbo-betting-status {
  background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%) !important;
}

.ri-x-green-dd-2 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
}

.ri-x-red-dd-2 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
}

.g-summary {
  padding: 0px 5px 0px 0px;
}


/*--------------------2 */
@media (min-width: 960px) {
  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid blue;
    min-height: 185px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(113, 253, 113, 0.288);
  }

  .betting-coin-meron {
    width: 40px;
    height: auto;
    margin: auto;
  }

  .betting-coin-wala {
    width: 40px;
    height: auto;
    margin: auto;
  }

  .btn-coin {
    width: 45px;
    height: auto;
    position: relative;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .money-in-coin-position {
    margin-top: -29.5px;
    margin-bottom: 25px;
    margin-left: -2px;
    z-index: 100;
    font-weight: 600;
    text-shadow: 1px 0px 1px black;
    font-size: 13px;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    top: 1px;
    left: -16px;
    font-size: 12.5px;
    font-weight: 600;
    text-shadow: 1px 0px 1px black;
    width: 68px;
  }



  .side-section-betting-btn {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 2px solid #437adb;
    border-radius: 5px;
    z-index: 1000;
  }
}

@media (min-width: 1280px) {
  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    min-height: 200px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(113, 253, 113, 0.288);
  }

  .betting-coin-meron {
    width: 50px;
    height: auto;
    margin: auto;
  }

  .betting-coin-wala {
    width: 60px;
    height: auto;
    margin: auto;
  }

  .btn-coin {
    width: 60px;
    height: auto;
    position: relative;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .betting-coin-meron {
    width: auto;
    height: 58px;
    margin: auto;
  }

  .betting-coin-wala {
    width: auto;
    height: 58px;
    margin: auto;
  }

  .btn-coin {
    width: 60px;
    height: auto;
    position: relative;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .money-in-coin-position {
    margin-top: -38px;
    margin-bottom: 31px;
    z-index: 100;
    font-weight: 900;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    top: 7px;
    left: -8px;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
  }



  .side-section-betting-btn {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
    background-image: url("/images/tiger-dragon/dragon.png");
    z-index: 1000;
  }
}

.container {
  display: flex;
}

.column {
  flex: 2;
  text-align: center !important;
}

.left-column {
  flex: 2;
}

.right-column {
  flex: 2;
}

.block {
  margin-bottom: 10px;
  padding: 10px 0x;
}

.long-block {
  height: 200px;
  flex-basis: calc(33.33% - 20px);
}

.meron-section-tiger,
.modal-meron .modal-content {
  color: #fff;
}

.meron-section-tiger {
  color: #fff;
  border: 2px#f34141 solid;
  border-radius: 5px;
}

.meron-section-tiger .side-title,
.modal-meron .modal-bet-title {
  text-transform: uppercase;
  color: #f34141;
}

.wala-section-dragon,
.modal-meron .modal-content {
  color: #fff;
}

.wala-section-dragon {
  color: #fff;
  border: 2px solid #437adb;
  border-radius: 5px;
}

.wala-section-dragon .side-title,
.modal-meron-tiger .modal-bet-title {
  text-transform: uppercase;
  color: #187df1;
}

.pair-betting {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(32, 218, 88, 0.2);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}

.pair-betting:hover {
  cursor: pointer;
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.pair-banker-text {
  font-size: 18px;
  font-weight: 600;
  color: lightgoldenrodyellow;
  text-shadow: 1px 0px black;
}

.selected-p-banker {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}

.banker-button {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.tie-button {
  width: 100%;
  height: 238px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.banker-button :hover {
  cursor: pointer;
}

.selected-p-player {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}



.open-betting {
  color: #28a745;
}

.close-betting {
  color: #dc3545;
}

.truncate {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: center !important;
}

.odds {
  font-size: 1.5rem;
  /* margin-top: 10px; */
  color: whitesmoke;
  font-weight: 900;
}

.odds-subtle {
  font-size: 1rempx;
  color: hsla(0, 0%, 100%, 0.8);
  margin-top: 3px;
}

.tie-section {
  border: 2px greenyellow solid;
  border-radius: 5px;
}

.tie-section .side-title {
  color: greenyellow;
}

@media screen and (max-width: 1904px) {
  .text-display-payout {
    font-size: 2rem !important;
  }

  .range-betting-payout {
    font-size: 2rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 1280px) {
  .text-display-payout {
    font-size: 2rem !important;
  }

  .range-betting-payout {
    font-size: 1.3rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 982px) {
  .tie-button {
    height: 249px;
  }

  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.1rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 960px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .block {
    margin-bottom: 10px;
    padding: 5px;
  }
}

@media screen and (max-width: 959px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.2rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 768px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.1rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 700px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 0.8rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 600px) {
  .range-betting-payout {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 529px) {
  .range-betting-payout {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 390px) {
  .text-display-payout {
    font-size: 0.75rem;
  }

  .range-betting-payout {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 375px) {
  .range-betting-payout {
    font-size: 0.9rem;
  }
}

.han2-min-max-limit .max-label {
  float: right !important;
  width: auto !important;
  margin-top: 8px !important;
}

@media screen and (min-width: 1904px) {}

@media screen and (min-width: 1280px) {}

@media screen and (min-width: 982px) {}

@media screen and (min-width: 960px) {}

@media screen and (min-width: 959px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 700px) {}

@media screen and (min-width: 600px) {}

@media screen and (min-width: 529px) {
  .kalakouk-row {
    padding-left: 46px !important;
  }
}

@media screen and (min-width: 450px) {
  .kalakouk-row {
    padding-left: 36px !important;
  }
}

@media screen and (min-width: 428px) {
  .kalakouk-row {
    padding-left: 38px !important;
  }
}

@media screen and (min-width: 414px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 390px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 384px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 375px) {
  .kalakouk-row {
    padding-left: 30px !important;
  }
}

.range-betting-container {
  width: 23%;
  height: 90px;
  border-radius: 5px;
  background-color: rgb(0, 102, 153, 0.1);
  border: 1px solid rgb(218, 165, 32);
  position: relative;
}

.range-betting-container-active {
  width: 23%;
  height: 90px;
  border-radius: 5px;
  background-color: #012681;
  border: 3px solid grey;
  transform: scale(1.04);
  position: relative;
}

.payout {
  font-size: .9rem !important;
  font-weight: bold
}
/*============== */

@media(max-width:760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.2rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-coin-cover {
    width: 100%
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:960px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:1280px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.3rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.hold-coin {
  position: sticky;
  left: 0px;
  z-index: 100;
  background: #013366;
  font-size: bolder;
  border-right: 2px dashed white;
}

.coin-container {
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.coin-container:hover {
  cursor: pointer;
}

.display-money {
  background-color: #7592af65;
  height: 40px;
  border-radius: 5px;
  margin: 5px 0px 7px 0px;
  color: #212427;
  font-weight: bolder;
  text-shadow: 1px 0.2px #406386;
  font-size: 1.3rem;
  padding-top: 5px;
  color: white;
}

.btn-clear-money {
  background: rgb(201, 201, 214);
  background: linear-gradient(180deg,
      rgba(201, 201, 214, 1) 0%,
      rgba(103, 103, 106, 1) 100%);
  border-width: 0.5 !important;
  border-color: #133375 !important;
  border-radius: 6px;
  padding: 6px 10px;
  color: white;
  width: 150px;
  text-align: center;
  justify-content: center;
}

.btn-submit-money {
  background: rgb(110, 179, 241);
  background: linear-gradient(180deg,
      rgba(110, 179, 241, 0.9108018207282913) 0%,
      rgba(25, 72, 166, 1) 100%);
  border-width: 0.5 !important;
  border-color: #133375 !important;
  border-radius: 6px;
  padding: 6px 10px;
  color: white;
  width: 150px;
  height: 30px !important;
  justify-content: center;
}

.btn-clear-money:hover {
  cursor: pointer;
}

.btn-submit-money:hover {
  cursor: pointer;
}


@media screen and (max-width: 568px) {

  .ri-sicbo-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-xcol-r1-c1-dd-1 {
  grid-area: r1c1dd1;
}

.ri-xcol-r1-c6-dd-1 {
  grid-area: r1c6dd1;
}

.ri-taixiu-betting-row-dd-1 {
  display: grid;
  grid-template-areas: "r1c1dd1 r1c6dd1";
  gap: 6px;
  width: 100%;
  height: 100%;
}

.ri-taixiu-betting-col-dd-1 {
  font-size: 1vw;
  font-weight: 900;
  color: #008ea0;
  border: 1px solid white;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 3px;
  text-transform: uppercase;
  width: 95% !important;
}
.ri-taixiu-betting-col-dd-1:hover {
  cursor: pointer;
}
.ri-taixiu-betting-col-dd-1 div {
  width: 99%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ri-taixiu-betting-col-dd-1 div span {
  width: 100%;
  text-align: center;
}

.ri-taixiu-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-x-green-dd-1 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-x-red-dd-1 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}

.dd-x-title-dd-1 {
  display: block;
  font-size: 8vw;
}
.dd-x-text-dd-1 {
  display: block;
}

.dd-x-title-dd-2 {
  display: block;
  font-size: 2vw;
}
.dd-x-text-dd-2 {
  display: block;
}

.dd-x-title-dd-3 {
  display: block;
  font-size: 4vw;
}
.dd-x-text-dd-1 {
  display: block;
  font-size: 12vw;
}
.dd-x-text-dd-2 {
  display: block;
  font-size: 2vw;
}
/*------------------- */
.ri-xcol-r1-c1-dd-2 {
  grid-area: r1c1dd2;
}
.ri-xcol-r1-c2-dd-2 {
  grid-area: r1c2dd2;
}
.ri-xcol-r1-c3-dd-2 {
  grid-area: r1c3dd2;
}
.ri-xcol-r1-c4-dd-2 {
  grid-area: r1c4dd2;
}
.ri-xcol-r1-c5-dd-2 {
  grid-area: r1c5dd2;
}
.ri-xcol-r1-c6-dd-2 {
  grid-area: r1c6dd2;
}
.ri-xcol-r1-c7-dd-2 {
  grid-area: r1c7dd2;
}

.ri-xcol-r3-c2-dd-2 {
  grid-area: r3c2dd2;
}
.ri-xcol-r3-c3-dd-2 {
  grid-area: r3c3dd2;
}
.ri-xcol-r3-c4-dd-2 {
  grid-area: r3c4dd2;
}
.ri-xcol-r3-c5-dd-2 {
  grid-area: r3c5dd2;
}
.ri-xcol-r3-c6-dd-2 {
  grid-area: r3c6dd2;
}
.ri-xcol-r3-c7-dd-2 {
  grid-area: r3c7dd2;
}

.ri-taixiu-betting-row-dd-2 {
  margin-top: 6px;
  display: grid;
  grid-template-areas:
    "r1c1dd2 r1c2dd2 r1c3dd2 r1c4dd2 r1c5dd2 r1c6dd2 r1c7dd2"
    "r3c2dd2 r3c3dd2 r3c4dd2 r1c4dd2 r3c5dd2 r3c6dd2 r3c7dd2";

  gap: 7px;
  width: 100%;
  height: 100%;
}

.ri-taixiu-betting-col-dd-2 {
  font-size: 1vw;
  font-weight: 900;
  color: white;
  border: 1px solid white;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 3px;
  text-transform: uppercase;
}
.ri-taixiu-betting-col-dd-2:hover {
  cursor: pointer;
}
.ri-taixiu-betting-col-dd-2 div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ri-taixiu-betting-col-dd-2 div span {
  width: 100%;
  text-align: center;
}

.ri-taixiu-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-x-green-dd-2 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-x-red-dd-2 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-sicbo-count-down{
  width: 100%;
  height: auto;
  background-color: #386faf8f;
  margin-bottom: 6px;
  margin-top: 0px;
  text-align: center;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  
  
}
.ri-sicbo-count-down span{
  color: #11f5c4;
  font-size: 8vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
.ri-sicbo-count-down marquee{
  color: #dc3545;
  font-size: 8vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
}
@media screen and (min-width: 569px) {

  .ri-sicbo-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-xcol-r1-c1-dd-1 {
  grid-area: r1c1dd1;
}

.ri-xcol-r1-c6-dd-1 {
  grid-area: r1c6dd1;
}

.ri-taixiu-betting-row-dd-1 {
  display: grid;
  grid-template-areas: "r1c1dd1 r1c6dd1";
  gap: 6px;
  width: 100%;
  height: 100%;
}

.ri-taixiu-betting-col-dd-1 {
  font-size: 1vw;
  font-weight: 900;
  color: #008ea0;
  border: 1px solid white;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 3px;
  text-transform: uppercase;
  width: 95% !important;
}
.ri-taixiu-betting-col-dd-1:hover {
  cursor: pointer;
}
.ri-taixiu-betting-col-dd-1 div {
  width: 99%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ri-taixiu-betting-col-dd-1 div span {
  width: 100%;
  text-align: center;
}

.ri-taixiu-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-x-green-dd-1 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-x-red-dd-1 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}

.dd-x-title-dd-1 {
  display: block;
  font-size: 2vw;
}
.dd-x-text-dd-1 {
  display: block;
}

.dd-x-title-dd-2 {
  display: block;
  font-size: 2vw;
}
.dd-x-text-dd-2 {
  display: block;
}

.dd-x-title-dd-3 {
  display: block;
  font-size: 1vw;
}
.dd-x-text-dd-1 {
  display: block;
  font-size: 4vw;
}
.dd-x-text-dd-2 {
  display: block;
  font-size: 2vw;
}
/*------------------- */
.ri-xcol-r1-c1-dd-2 {
  grid-area: r1c1dd2;
}
.ri-xcol-r1-c2-dd-2 {
  grid-area: r1c2dd2;
}
.ri-xcol-r1-c3-dd-2 {
  grid-area: r1c3dd2;
}
.ri-xcol-r1-c4-dd-2 {
  grid-area: r1c4dd2;
}
.ri-xcol-r1-c5-dd-2 {
  grid-area: r1c5dd2;
}
.ri-xcol-r1-c6-dd-2 {
  grid-area: r1c6dd2;
}
.ri-xcol-r1-c7-dd-2 {
  grid-area: r1c7dd2;
}

.ri-xcol-r3-c2-dd-2 {
  grid-area: r3c2dd2;
}
.ri-xcol-r3-c3-dd-2 {
  grid-area: r3c3dd2;
}
.ri-xcol-r3-c4-dd-2 {
  grid-area: r3c4dd2;
}
.ri-xcol-r3-c5-dd-2 {
  grid-area: r3c5dd2;
}
.ri-xcol-r3-c6-dd-2 {
  grid-area: r3c6dd2;
}
.ri-xcol-r3-c7-dd-2 {
  grid-area: r3c7dd2;
}

.ri-taixiu-betting-row-dd-2 {
  margin-top: 6px;
  display: grid;
  grid-template-areas:
    "r1c1dd2 r1c2dd2 r1c3dd2 r1c4dd2 r1c5dd2 r1c6dd2 r1c7dd2"
    "r3c2dd2 r3c3dd2 r3c4dd2 r1c4dd2 r3c5dd2 r3c6dd2 r3c7dd2";

  gap: 7px;
  width: 100%;
  height: 100%;
}

.ri-taixiu-betting-col-dd-2 {
  font-size: 1vw;
  font-weight: 900;
  color: white;
  border: 1px solid white;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 3px;
  text-transform: uppercase;
}
.ri-taixiu-betting-col-dd-2:hover {
  cursor: pointer;
}
.ri-taixiu-betting-col-dd-2 div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ri-taixiu-betting-col-dd-2 div span {
  width: 100%;
  text-align: center;
}

.ri-taixiu-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-x-green-dd-2 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-x-red-dd-2 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-sicbo-count-down{
  width: 100%;
  height: auto;
  background-color: #386faf8f;
  margin-bottom: 6px;
  margin-top: 0px;
  text-align: center;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  
  
}
.ri-sicbo-count-down span{
  color: #11f5c4;
  font-size: 2vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
.ri-sicbo-count-down marquee{
  color: #dc3545;
  font-size: 2vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
}

</style>