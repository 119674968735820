export default {
  methods: {
    _findRouteParent(url) {
      switch (true) {
        case url == "/LOTTO":
          return "LOTTO";
        case url == "/YUKI":
          return "YUKI";
        case url == "/LOTTERYVN1" ||
          url == "/LOTTERYVN2" ||
          url == "/LOTTERYVN3":
          return "LOTTERY VN";
        case url == "/LOTTERY2D":
          return "LOTTERY 2D";
        case url == "/LOTTERY3D":
          return "LOTTERY 3D";
          case url == "/KENO":
            return "KENO";
        default:
          return "";
      }
    },
    _urlToImg(path) {
      switch (true) {
        case path == "YUKI":
          return require("../../public/images/a_page_access_global/game-logo/w99_yuki_icon.png");
        case path == "LOTTERY 2D":
          return require("../../public/images/a_page_access_global/game-logo/lottery_icon_2D.png");
        case path == "LOTTO":
          return require("../../public/images/a_page_access_global/game-logo/w99_lotto_icon.png");
        case path == "KENO":
          return require("../../public/images/a_page_access_global/game-logo/keno.png");
        case path == "LOTTERY 3D":
          return require("../../public/images/a_page_access_global/game-logo/lottery_icon_3D.png");
        case path == "LOTTERY VN":
            return require("../../public/images/a_page_access_global/game-logo/VN Lottery icon.png");
            case path == "Soccer":
              return require("../../public/images/a_page_access_global/game-logo/Soccer icon.png");
              case path == "Boxing":
                return require("../../public/images/a_page_access_global/game-logo/boxing icon.png");
      }
    },
    _calculateMenuArray(selectedGame) {
      switch (true) {
        case selectedGame == "/LOTTO" ||
          selectedGame == "/LOTTO160S" ||
          selectedGame == "/LOTTO100S" ||
          selectedGame == "/LOTTO30S":
          return 0;
        case selectedGame == "/YUKI" ||
          selectedGame == "/YUKI160S" ||
          selectedGame == "/YUKI100S" ||
          selectedGame == "/YUKI30S":
          return 2;
        case selectedGame == "/LOTTERY2D":
          return 3;
        case selectedGame == "/KENO" ||
          selectedGame == "/KENO160S" ||
          selectedGame == "/KENO100S" ||
          selectedGame == "/KENO30S":
          return 1;
        case selectedGame == "/LOTTERY3D" ||
          selectedGame == "/LOTTERY3160S" ||
          selectedGame == "/LOTTERY3100S" ||
          selectedGame == "/":
          return 4;
        default:
          return 0;
      }
    },
  },
};
