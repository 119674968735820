export default {
	username: 'Username',
	user_name:"User Name",
	password: 'Password',
	login: 'Login',
	contact_us: 'Contact us',
	//validation
	invalid: {
		username: 'The input is not valid username!'
	},
	//required
	required: {
		username: 'Please input your username!',
		password: 'Please input your Password!'
	},
	//error
	error: {
		login_failed: "Your username or password doesn't exist."
	},
	bottom_label_welcome:"WELCOME TO WEBSITE ",
	website_name :"SBC2888",
	bottom_label_website_qoute: "Play and Win Anytime, Anywhere with Our Easy-to-Use Online Casino!",
	high_security : "1. High Security",
	fast_deposit : "2. Deposit money in just a minute",
	fast_withdraw : "3. Fast Withdraw money ",
	about_us : "Information About Us",
	support24_7:"Customer support 24/7",
	come_to_win:"COME TO PLAY, COME TO WIN!",
	transaction : "Transaction with:",
	social_media:"Follow us:",
	about_us_content : "SBC2888 offers a wide range of high-quality games produced by our dedicated team. We provide 24/7 customer support, ensuring your needs are met at any time. Rest assured, all your information is kept strictly confidential and private."


};
