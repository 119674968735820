<template>
    <div>
        <VideoLiveForDesktop/>
        <div class="submachine-hide-on-mobile">
            <TableResult :rows="6" :cols="35" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import VideoLiveForDesktop from "../videoLiveForDesktop.vue";
import TableResult from "../TableResults.vue";
export default {
    components: { VideoLiveForDesktop, TableResult },
    data() {
        return {
            logoWebsite: '../../../../../public/images/asiancockfighting-word.jpg',
        }
    },
    props: [
        "passChannelStatus",
        "passSelectedChannel"
    ],
    computed: {
        ...mapGetters("$_dashboard", {
            channelObj: "getChannel",
        }),
    },
    methods: {
        _switchChannel(selectedChannel) {
        this.$emit("sentSetChannel", selectedChannel);
        }
    }
}
</script>

<style>
.event-header {
    background: transparent !important;
    border: #566573 1px solid !important;
    border-radius: 0px !important;
    padding-left: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
}

</style>