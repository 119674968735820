export default {
  methods: {
    _playSoundInsertCoin() {
      let audio = new Audio("/sound/insert_coin.mp3");
      let promise = audio.play();
      if (promise) {
        promise.catch(function () {});
      }
    },
  },
};
