<template>
  <div class="" style="overflow:hidden">
  
      <div class="row ma-0">
        <div v-for="(k, index3) in result_a" :key="index3" style="width:20%">
          <div class="lottery2d-result-element ">
            <div class="lottery2d-result-title rounded-b-0">
              <p>{{ columnIndex(index3) }}</p>
            </div>
            <div class="lottery-result-ball-result" style="width: 95%;" :style="corlorIndex(index3)">
              <v-img v-for="(n, index4) in k.trim()" :key="index4" :src="ballImg(index3)" loading="eager"
                class=" each-lottery2d-ball heartBeat" contain>
                <p class="lottery2d-ball-number">{{ n }} </p>
              </v-img>
              <v-img v-if="k.length < 2" :src="ballImg(index3)" class="each-lottery2d-ball px-0" contain loading="eager">
                <p class="lottery2d-ball-number">?</p>
              </v-img>
            </div>
          </div>
        </div>
        <div style="width:20%" v-if="result_a.length < 1">
          <div class="lottery2d-result-element ">
            <div class="lottery2d-result-title rounded-b-0">
              <p>{{ columnIndex(0) }}</p>
            </div>
            <div class="lottery-result-ball-result" style="width: 95%;" :style="corlorIndex(0)">

              <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(0)" class="each-lottery2d-ball" loading="eager"
                contain>
                <p class="lottery2d-ball-number">?</p>
              </v-img>
            </div>
          </div>
        </div>
        <div style="width:20%" v-if="result_a.length < 2">
          <div class="lottery2d-result-element ">
            <div class="lottery2d-result-title rounded-b-0">
              <p>{{ columnIndex(1) }}</p>
            </div>
            <div class="lottery-result-ball-result" style="width: 95%;" :style="corlorIndex(1)">
              <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(1)" class="each-lottery2d-ball" loading="eager"
                contain>
                <p class="lottery2d-ball-number">?</p>
              </v-img>
            </div>
          </div>
        </div>
        <div style="width:20%" v-if="result_a.length < 3">
          <div class="lottery2d-result-element ">
            <div class="lottery2d-result-title rounded-b-0">
              <p>{{ columnIndex(2) }}</p>
            </div>
            <div class="lottery-result-ball-result" style="width: 95%;" :style="corlorIndex(2)">
              <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(2)" class="each-lottery2d-ball" loading="eager"
                contain>
                <p class="lottery2d-ball-number">?</p>
              </v-img>
            </div>
          </div>
        </div>
        <div style="width:20%" v-if="result_a.length < 4">
          <div class="lottery2d-result-element ">
            <div class="lottery2d-result-title rounded-b-0">
              <p>{{ columnIndex(3) }}</p>
            </div>
            <div class="lottery-result-ball-result" style="width: 95%;" :style="corlorIndex(3)">
              <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(3)" class="each-lottery2d-ball" loading="eager"
                contain>
                <p class="lottery2d-ball-number">?</p>
              </v-img>
            </div>
          </div>
        </div>
        <div style="width:20%" v-if="result_a.length < 5">
          <div class="lottery2d-result-element ">
            <div class="lottery2d-result-title rounded-b-0">
              <p>{{ columnIndex(4) }}</p>
            </div>
            <div class="lottery-result-ball-result" style="width: 95%;" :style="corlorIndex(4)">
              <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(4)" class="each-lottery2d-ball" loading="eager"
                contain>
                <p class="lottery2d-ball-number">?</p>
              </v-img>
            </div>
          </div>
        </div>
      </div>
    
    <div class="row ma-0" v-if="!result_a">
      <div v-for="(k, index3) in 5" :key="index3" style="width:20%">
        <div class="lottery2d-result-element">
          <div class="lottery2d-result-title rounded-b-0">
            <p>{{ columnIndex(index3) }}</p>
          </div>
          <div class="lottery-result-ball-result" style="width: 95%;" :style="corlorIndex(index3)">
            <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(index3)" loading="eager"
              class=" each-lottery2d-ball heartBeat" contain>
              <p class="lottery2d-ball-number">{{ n }} </p>
            </v-img>
            <v-img v-if="k.length < 2" :src="ballImg(index3)" class="each-lottery2d-ball px-0" contain loading="eager">
              <p class="lottery2d-ball-number">?</p>
            </v-img>
          </div>
        </div>
      </div>

      <!-- <div v-if="result_a.length < 4" class="lottery-result-ball-result" :style="corlorIndex(1)">
                    <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(6)" class="each-lottery3d-ball"
                        loading="eager" contain>
                        <p class="lottery3d-ball-number">?</p>
                    </v-img>
                </div> -->
      <!-- <div v-if="result_a.length < 3" class="lottery-result-ball-result"  :style="corlorIndex(2)">
                    <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(6)" class="each-lottery3d-ball"
                        loading="eager " contain>
                        <p class="lottery3d-ball-number">?</p>
                    </v-img>
                </div>
                <div v-if="result_a.length < 2" class="lottery-result-ball-result"  :style="corlorIndex(3)">
                    <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(6)" class="each-lottery3d-ball"
                        loading="eager " contain>
                        <p class="lottery3d-ball-number">?</p>
                    </v-img>
                </div>
                <div v-if="result_a.length < 1" class="lottery-result-ball-result "  :style="corlorIndex(4)">
                    <v-img v-for="(n, index4) in 2" :key="index4" :src="ballImg(6)" class="each-lottery3d-ball"
                        loading="eager " contain>
                        <p class="lottery3d-ball-number">?</p>
                    </v-img>
                </div> -->
    </div>


    <div style="display: none">{{ ass_a }}</div>

    <!-- <div class="plus-m-box-col-head" >
            <span>A</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-yellow " :class="passClientRandom && passClientRandom[0] ?'animated heartBeat' : ''">
              <span class="">{{passClientRandom && passClientRandom[0] ? passClientRandom[0] : passRandomNumber? passRandomNumber: '??'}}</span>
            </div>
          </div>
        </div>
    
        <div class="plus-m-box-col">
          <div class="plus-m-box-col-head">
            <span>B</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-blue" :class="passClientRandom && passClientRandom[1] ?'animated heartBeat' : ''">
              <span>{{passClientRandom && passClientRandom[1] ? passClientRandom[1] : passClientRandom[0] && passRandomNumber ? passRandomNumber : '??'}}</span>
            </div>
          </div>
        </div>
        <div class="plus-m-box-col">
          <div class="plus-m-box-col-head">
            <span>C</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-green" :class="passClientRandom && passClientRandom[2] ?'animated heartBeat' : ''">
              <span>{{passClientRandom && passClientRandom[2] ? passClientRandom[2] : passClientRandom[1] && passRandomNumber ? passRandomNumber : '??'}}</span>
            </div>
          </div>
        </div>
    
        <div class="plus-m-box-col">
          <div class="plus-m-box-col-head">
            <span>D</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-maroon" :class="passClientRandom && passClientRandom[3] ?'animated heartBeat' : ''">
              <span>{{passClientRandom && passClientRandom[3] ? passClientRandom[3] :  passClientRandom[2] && passRandomNumber ? passRandomNumber : '??'}}</span>
            </div>
          </div>
        </div>
    
        <div class="plus-m-box-col">
          <div class="plus-m-box-col-head">
            <span>E</span>
          </div>
          <div class="plus-m-box-body">
            <div class="plus-m-box-body-content plus-m-color-red" :class="passClientRandom && passClientRandom[4] ?'animated heartBeat' : ''">
              <span>{{passClientRandom && passClientRandom[4] ? passClientRandom[4] :  passClientRandom[3] && passRandomNumber ? passRandomNumber : '??'}}</span>
            </div>
          </div>
          -->

  </div>
</template>
<script>
import "@/assets/css/lottery_menu/lottery2d/lottery.css";
export default ({
  props: ["passRandomNumber", "passClientRandom", "result_a", 'ass_a'],
  methods: {
    ballImg(id) {
      switch (id) {
        case 0: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_yellow.png');
        case 1: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_green.png');
        case 2: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_pink.png');
        case 3: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_red.png');
        case 4: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_sea.png');
        case 6: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_white.png');
      }
    },
    columnIndex(id) {
      switch (id) {
        case 0: return 'A';
        case 1: return 'B';
        case 2: return 'C';
        case 3: return 'D';
        case 4: return 'E';
      }
    },
    corlorIndex(id) {
      switch (id) {
        case 0: return 'background: rgb(244, 201, 5)';
        case 1: return 'background: rgb(29, 203, 38)';
        case 2: return 'background: rgb(199, 5, 210)';
        case 3: return 'background: rgb(213, 2, 1)';
        case 4: return 'background:rgb(2, 195, 183)';
      }
    },
  }
})
</script>
<style></style>