<template>
  <div class="row ma-0">
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <button
    v-if="!getLoading"
      @click="_submitBetting()"
      class="machine-submit-button text-uppercase"
      :class="!_checkAllowBetting() ? 'is_disabled' :''"
    >
      {{$t('title.confirm')}}
    </button>
    <button
    v-if="getLoading"
      class="machine-submit-button text-uppercase is_disabled"
    >
    <img src="../../../public/images/a_page_access_global/loading.svg"  />
    </button>
    <v-spacer></v-spacer>
    <div @click="_cancelBetting()" class="machine-cancel-button text-uppercase">
      {{$t('title.cancel')}}
    </div>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
  </div>
</template>
 
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import shareFunction from "~mixin/MX_ShareFunctionCollection.js"
export default {
  mixins: [shareFunction],
  props: ["passBetType", "passChannelType", "selectedSubmit"],
  data() {
    return {
      machine_statement_configured_data:{   
        rows_per_page: 10,
        page: 1,
    },
    }
  },
  computed: {
    ...mapGetters("$_modules", {
      getBettingAmount: "getBettingAmount",
    }),
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedCockBtn: "getSelectedCockBtn",
      getSelectedBaccaratBtn: "getSelectedBaccaratBtn",
      getSelectedTigerBtn: "getSelectedTigerBtn",
      getSelectedTaixiuBtn: "getSelectedTaixiuBtn",
      getSelectedDodenBtn: "getSelectedDodenBtn",
      getSelectedSicboBtn: "getSelectedSicboBtn",
      getSelectedKlakloukBtn:"getSelectedKlakloukBtn",
      getSelectedLottoBtn:"getSelectedLottoBtn",
      getSelectedHanBtn:"getSelectedHanBtn",
      getSelectedTaisevBtn:"getSelectedTaisevBtn",
      getSelectedYukiBtn:"getSelectedYukiBtn",
      getSelectedApongBtn:"getSelectedApongBtn",
      getSelectedFantanBtn:"getSelectedFantanBtn",
      getLoading:"getLoading",
    }),
  },

  methods: {
    _checkAllowBetting() {
      if (this.getBettingAmount > 0 && this._checkWhatGameBySelectBtn() == this.selectedSubmit)
        return true;
      else return false;
    },
    _checkWhatGameBySelectBtn() {
      
      switch (true) {
        case 
          ( this.getSelectedBaccaratBtn && this.getSelectedBaccaratBtn.button && 
          (this.getSelectedBaccaratBtn.button.name == "PAIR PLAYER" ||
            this.getSelectedBaccaratBtn.button.name == "PAIR BANKER" ||
            this.getSelectedBaccaratBtn.button.name == "TIE" ||
            this.getSelectedBaccaratBtn.button.name == "PLAYER" ||
            this.getSelectedBaccaratBtn.button.name == "BANKER") &&
            this.getSelectedBaccaratBtn.channelType == this.passChannelType):
            return 'selectedSubmitBaccarat';
        case (this.getSelectedTigerBtn && this.getSelectedTigerBtn.button && (
          this.getSelectedTigerBtn.button.name == "TIGER" ||
          this.getSelectedTigerBtn.button.name == "DRAGON" ||
          this.getSelectedTigerBtn.button.name == "TIE") &&
          this.getSelectedTigerBtn.channelType == this.passChannelType):
            return 'selectedSubmitTiger';
        case (this.getSelectedTaixiuBtn && this.getSelectedTaixiuBtn.button && (
          this.getSelectedTaixiuBtn.button.name == "small" ||
          this.getSelectedTaixiuBtn.button.name == "7" ||
          this.getSelectedTaixiuBtn.button.name == "big") &&
          this.getSelectedTaixiuBtn.channelType == this.passChannelType):
            return 'selectedSubmitTaixiu';
        case (this.getSelectedDodenBtn && this.getSelectedDodenBtn.button && (
          this.getSelectedDodenBtn.button.name == "small" ||
          this.getSelectedDodenBtn.button.name == "7" ||
          this.getSelectedDodenBtn.button.name == "big") &&
          this.getSelectedDodenBtn.channelType == this.passChannelType):
            return 'selectedSubmitDoden';
        case (this.getSelectedSicboBtn && this.getSelectedSicboBtn.button && (
          this.getSelectedSicboBtn.button.name == "small" ||
          this.getSelectedSicboBtn.button.name == "odd" ||
          this.getSelectedSicboBtn.button.name == "evn" ||
          this.getSelectedSicboBtn.button.name == "big") &&
          this.getSelectedSicboBtn.channelType == this.passChannelType):
            return 'selectedSubmitSicbo';
        case (this.getSelectedKlakloukBtn && this.getSelectedKlakloukBtn.button && (
          this.getSelectedKlakloukBtn.button.name == "ខ្លា" ||
          this.getSelectedKlakloukBtn.button.name == "ត្រី" ||
          this.getSelectedKlakloukBtn.button.name == "មាន់" ||
          this.getSelectedKlakloukBtn.button.name == "ឃ្លោក" ||
          this.getSelectedKlakloukBtn.button.name == "ក្ដាម" ||
          this.getSelectedKlakloukBtn.button.name == "បង្កង") &&
          this.getSelectedKlakloukBtn.channelType == this.passChannelType):
            return 'selectedSubmitKlaklouk';
        case (this.getSelectedCockBtn && this.getSelectedCockBtn.button && (
          this.getSelectedCockBtn.button.name == "TIE" ||
          this.getSelectedCockBtn.button.name == "MERON" ||
          this.getSelectedCockBtn.button.name == "TIE" ||
          this.getSelectedCockBtn.button.name == "BANKER" ||
          this.getSelectedCockBtn.button.name == "PLAYER" ||
          this.getSelectedCockBtn.button.name == "WALA") &&
          this.getSelectedCockBtn.channelType == this.passChannelType):
            return 'selectedSubmitCockFight';
        case (this.getSelectedLottoBtn && this.getSelectedLottoBtn.button && (
          this.getSelectedLottoBtn.button.name.toUpperCase() == "SMALL" ||
          this.getSelectedLottoBtn.button.name.toUpperCase() == "LARGE") &&
          this.getSelectedLottoBtn.channelType == this.passChannelType):
            return 'selectedSubmitLotto';
        case (this.getSelectedHanBtn && this.getSelectedHanBtn.button && (
          this.getSelectedHanBtn.button.name.toUpperCase() == "BANKER 1" ||
          this.getSelectedHanBtn.button.name.toUpperCase() == "BANKER 2" ||
          this.getSelectedHanBtn.button.name.toUpperCase() == "BANKER 3" ||
          this.getSelectedHanBtn.button.name.toUpperCase() == "TIE 1" ||
          this.getSelectedHanBtn.button.name.toUpperCase() == "TIE 2" ||
          this.getSelectedHanBtn.button.name.toUpperCase() == "TIE 3" ||
          this.getSelectedHanBtn.button.name.toUpperCase() == "PLAYER 1" ||
          this.getSelectedHanBtn.button.name.toUpperCase() == "PLAYER 2" ||
          this.getSelectedHanBtn.button.name.toUpperCase() == "PLAYER 3") &&
          this.getSelectedHanBtn.channelType == this.passChannelType):
            return 'selectedSubmitHan';
        case (this.getSelectedTaisevBtn && this.getSelectedTaisevBtn.button && (
          this.getSelectedTaisevBtn.button.name.toUpperCase() == "EVEN" ||
          this.getSelectedTaisevBtn.button.name.toUpperCase() == "ODD" ||
          this.getSelectedTaisevBtn.button.name.toUpperCase() == "SMALL" ||
          this.getSelectedTaisevBtn.button.name.toUpperCase() == "BIG") &&
          this.getSelectedTaisevBtn.channelType == this.passChannelType):
            return 'selectedSubmitTaisev';
        case (this.getSelectedYukiBtn && this.getSelectedYukiBtn.button && (
          this.getSelectedYukiBtn.button.name.toUpperCase() == "BLUE" ||
          this.getSelectedYukiBtn.button.name.toUpperCase() == "RED" ||
          this.getSelectedYukiBtn.button.name.toUpperCase() == "UNDER" ||
          this.getSelectedYukiBtn.button.name.toUpperCase() == "OVER") &&
          this.getSelectedYukiBtn.channelType == this.passChannelType):
            return 'selectedSubmitYuki';
        case (this.getSelectedApongBtn && this.getSelectedApongBtn.button && (
          this.getSelectedApongBtn.button.name.toUpperCase() == "1" ||
          this.getSelectedApongBtn.button.name.toUpperCase() == "2" ||
          this.getSelectedApongBtn.button.name.toUpperCase() == "3" ||
          this.getSelectedApongBtn.button.name.toUpperCase() == "4" ||
          this.getSelectedApongBtn.button.name.toUpperCase() == "5" ||
          this.getSelectedApongBtn.button.name.toUpperCase() == "6") &&
          this.getSelectedApongBtn.channelType == this.passChannelType):
            return 'selectedSubmitApong';
        case (this.getSelectedFantanBtn && this.getSelectedFantanBtn.button && (
          this.getSelectedFantanBtn.button.name.toUpperCase() == "EVEN" ||
          this.getSelectedFantanBtn.button.name.toUpperCase() == "ODD" ||
          this.getSelectedFantanBtn.button.name.toUpperCase() == "1" ||
          this.getSelectedFantanBtn.button.name.toUpperCase() == "2" ||
          this.getSelectedFantanBtn.button.name.toUpperCase() == "3" ||
          this.getSelectedFantanBtn.button.name.toUpperCase() == "4") &&
          this.getSelectedFantanBtn.channelType == this.passChannelType):
            return 'selectedSubmitFantan';
        default:
          return true;
      }
    },
    async _submitBetting() {
      const data = {
        amount: this.getBettingAmount,
        type_id : this._defineTypeId(),
        channelType : this.passChannelType
      }
     const res = await this.createBetting({ vm: this, data: data });
     if(res.data.code) {
      await this.CLEAR_BETTING_AMOUNT();
      this.UPDATE_BALANCE(res.data.data.balance);
      const data = {
        total_amount : res.data.data.bet_amount,
        amountWin : null,
        channel_type : this.passChannelType,
        no : res.data.data.fight_no,
        time:res.data.data.time
      }
      // this.UPDATE_RECENT_REPORT(data);
      await this.fetchStatement(this.machine_statement_configured_data);
      this.UPDATE_OUTSTANDING_AMOUNT(data.total_amount);
      this._cancelBetting();
     }
      
    },
    _defineTypeId(){
      switch(this._findGameName(this.passChannelType)){
        case '1': return  this.getSelectedCockBtn.button.type_id;
        case '2': return  this.getSelectedCockBtn.button.type_id;
        case '3': return  this.getSelectedCockBtn.button.type_id;
        case '4': return  this.getSelectedCockBtn.button.type_id;
        case '15': return  this.getSelectedCockBtn.button.type_id;
        case '16': return  this.getSelectedCockBtn.button.type_id;
        case 'klaklouk': return  this.getSelectedKlakloukBtn.button.type_id;
        case '6': return  this.getSelectedBaccaratBtn.button.type_id;
        case '7': return  this.getSelectedBaccaratBtn.button.type_id;
        case '8': return  this.getSelectedTigerBtn.button.type_id;
        case '9': return  this.getSelectedTigerBtn.button.type_id;
        case '19': return  this.getSelectedCockBtn.button.type_id;
        case '18': return  this.getSelectedCockBtn.button.type_id;
        case 'doden': return  this.getSelectedDodenBtn.button.type_id;
        case 'sicbo': return  this.getSelectedSicboBtn.button.type_id;
        case 'taixiu': return  this.getSelectedTaixiuBtn.button.type_id;
        case '10': return this.getSelectedLottoBtn.button.type_id;
        case '20':
        case '21':
          return this.getSelectedHanBtn.button.type_id; 
        case '14':
          return this.getSelectedTaisevBtn.button.type_id;
        case '11':
          return this.getSelectedYukiBtn.button.type_id;
        case '12':
          return this.getSelectedApongBtn.button.type_id;
        default : return;
      }
    },
    _cancelBetting() {
        if(this.selectedSubmit == 'selectedSubmitCockFight'){  this.UPDATE_SELECTED_COCK_BUTTON_IN_ALL({}); }
        if(this.selectedSubmit == 'selectedSubmitBaccarat'){  this.UPDATE_SELECTED_BACCARAT_BUTTON_IN_ALL({}); }
        if(this.selectedSubmit == 'selectedSubmitTiger'){ this.UPDATE_SELECTED_TIGER_BUTTON_IN_ALL({}); }
        if(this.selectedSubmit == 'selectedSubmitTaixiu'){ this.UPDATE_SELECTED_TAIXIU_BUTTON_IN_ALL({}); }
        if(this.selectedSubmit == 'selectedSubmitDoden'){ this.UPDATE_SELECTED_DODEN_BUTTON_IN_ALL({}); }
        if(this.selectedSubmit == 'selectedSubmitSicbo'){ this.UPDATE_SELECTED_SICBO_BUTTON_IN_ALL({}); }
        if(this.selectedSubmit == 'selectedSubmitKlaklouk'){ this.UPDATE_SELECTED_KLAKLOUK_BUTTON_IN_ALL({}); }
        //-----
        if(this.selectedSubmit == 'selectedSubmitLotto'){this.UPDATE_SELECTED_LOTTO_BUTTON_IN_ALL({});}
        if(this.selectedSubmit == 'selectedSubmitHan'){this.UPDATE_SELECTED_HAN_BUTTON_IN_ALL({});}
        if(this.selectedSubmit == 'selectedSubmitTaisev'){this.UPDATE_SELECTED_TAISEV_BUTTON_IN_ALL({});}
        if(this.selectedSubmit == 'selectedSubmitYuki'){this.UPDATE_SELECTED_YUKI_BUTTON_IN_ALL({});}
        if(this.selectedSubmit == 'selectedSubmitApong'){this.UPDATE_SELECTED_APONG_BUTTON_IN_ALL({});}
        if(this.selectedSubmit == 'selectedSubmitFantan'){this.UPDATE_SELECTED_FANTAN_BUTTON_IN_ALL({});}

        this._checkWhatGameBySelectBtn();
    },
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT",
      'UPDATE_BALANCE'
    ]),
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_BACCARAT_BUTTON_IN_ALL",
      "UPDATE_SELECTED_TIGER_BUTTON_IN_ALL",
      "UPDATE_SELECTED_TAIXIU_BUTTON_IN_ALL",
      "UPDATE_SELECTED_DODEN_BUTTON_IN_ALL",
      "UPDATE_SELECTED_SICBO_BUTTON_IN_ALL",
      "UPDATE_SELECTED_KLAKLOUK_BUTTON_IN_ALL",
      //------
      "UPDATE_SELECTED_LOTTO_BUTTON_IN_ALL",
      "UPDATE_SELECTED_HAN_BUTTON_IN_ALL",
      "UPDATE_SELECTED_TAISEV_BUTTON_IN_ALL",
      "UPDATE_SELECTED_YUKI_BUTTON_IN_ALL",
      "UPDATE_SELECTED_APONG_BUTTON_IN_ALL",
      "UPDATE_SELECTED_FANTAN_BUTTON_IN_ALL",

      // "UPDATE_RECENT_REPORT",
      "UPDATE_OUTSTANDING_AMOUNT",
      "UPDATE_SELECTED_COCK_BUTTON_IN_ALL"
    ]),
    ...mapActions("$_cockfight_casino_live", ["createBetting", "fetchStatement"]),
  },
};
</script>