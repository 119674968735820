<template>
       <v-row no-gutters>
      <v-col cols="12" class="yuki-padding-6">
        <div class="justify-content-center row text-center mt-0 mb-2 px-0 " >
          <div class="row ma-0 w-100 mx-auto text-center">
            <div class="row coin-container ma-0 px-0  justify-center">
              <div v-for="(button, index) in getButtonMoney" :key="button._id" class="col-3 yuki-coins px-0 px-sm-0 px-md-0 px-xl-3 py-0">
                <div @click="_inputAmount(button.amount)" >
                  <img :src="_detectCoin(index)" class="btn-coin text-center">
                  <div text :style="`color:${_detectColor(index)};`" class="text yuki-btn-bet-circle prevent-select">
                    {{ button.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
</template>
  
  
<script>

import { mapGetters, mapMutations } from "vuex";
import Mixin from "~mixin/MX_ShareFunctionCollection.js";
export default {
    // props: [
    //     "passPlaceCoin",
    // ],
    components: {
        // Tabs,
    },
    data() {
        return {
            mixins: [Mixin],
        }
    },
    computed: {

            ...mapGetters("$_modules", {
                userInfo: "getUserInfo",
                getBettingAmount: "getBettingAmount",
            }),
        ...mapGetters("$_machine_yuki", {
            currentGame: "getCurrentGame",
            getLimit: "getLimit",
            getButtonMoney:'getButtonMoney'
        }),
    },
    methods:{
        _detectColor(index) {
      switch (index) {
        case 0:
          return 'black';
        case 1:
          return 'black';
        case 2:
          return 'black';
        case 3:
          return 'black';
        case 4:
          return 'black';
        case 5:
          return 'black';
        case 6:
          return 'black';
        case 7:
          return 'black';
        case 99:
          return 'white';
        default:
          return ''
      }
    },
    _detectCoin(index) {
      switch (index) {
        case 0:
          return require('../../../../public/images/a_game_access_global/coins/1.png');
        case 1:
          return require('../../../../public/images/a_game_access_global/coins/2.png');
        case 2:
          return require('../../../../public/images/a_game_access_global/coins/3.png');
        case 3:
          return require('../../../../public/images/a_game_access_global/coins/4.png');
        case 4:
          return require('../../../../public/images/a_game_access_global/coins/5.png');
        case 5:
        return require('../../../../public/images/a_game_access_global/coins/6.png');
        case 6:
        return require('../../../../public/images/a_game_access_global/coins/7.png');
        case 7:
        return require('../../../../public/images/a_game_access_global/coins/8.png');
        case 33:
          return '/images/coins/grey.png';
        default:
          return ''
      }
    },
        _inputAmount(amount) {
            this.UPDATE_UPDATE_BETTING_AMOUNT(amount);
            },
        _clearMoney() {
            this.CLEAR_BETTING_AMOUNT();
        },
        ...mapMutations("$_modules", [
            "UPDATE_UPDATE_BETTING_AMOUNT", "CLEAR_BETTING_AMOUNT"
        ]),
    }
};
</script>