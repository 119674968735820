
<template>
    <!-- <div class="tab-game-position mb-5 px-0" v-if="userInfo && userInfo.slot_channel && userInfo.slot_channel.length > 0"> -->
      <div class="tab-game-position mb-0 px-0" v-if="userInfo && userInfo.slot_channel && userInfo.slot_channel.length > 0">
      <a class="btn btn-custom pt-1 pb-0 px-0 mb-1" :class=" $route.name == i ? 'span-active' : ''"  v-for="(i, index) in userInfo.slot_channel" :key="index" @click="getToRoute(i.name, _findChannelType(i.name))" style="text-align:center" :style="'width:'+ 100/(userInfo.slot_channel.length+2) +'%!important'"  >
        <span :class=" $route.name == i.name ? 'span-active' : ''"  class="span-btn" style="" :style="index == 0 ?'border-left:1px solid white;':''">
          <img :src="_calculateLogo(i.name)" alt="" class="tab-logo x-tab-log">
        </span>
      </a>
    </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import publicFunction from "~mixin/MX_ShareFunctionCollection.js"
export default {
  data(){
  return {
      mixins: [publicFunction],
    }
  },
  computed: {
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
        }),
    },
  methods: {
    _calculateLogo(url){
      switch(url){
        case 'LOTTO'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Lottery.png');
        case 'YUKI'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Yuki icon.png');
        case 'LOTTERY'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Lotto icon.png');
        case 'DRAGON'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Dragon tiger.png');
        case 'KLAKLOUK'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Kla-klok.png');
        case 'BACCARAT'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Baccarat01.png');
        case 'DODEN'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/doDen.png');
        case 'SICBO'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Sic Bo.png');
        case 'TAIXIU'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Baccarat01.png');
        case 'Ham'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Lottery.png');
        case 'ThreeFace'
          : return require('../../../public/images/a_game_access_global/machine_game_logo/Lottery.png');
      }
    },
    getToRoute(path, channel_type) {
      try{
        let selectedSlotGameId = this._findSelectedSlotGame(path);
        this.SELECT_GAME_ID(selectedSlotGameId);
        this.$cookies.set("sbc_selected_slot_game", selectedSlotGameId);
        this.$cookies.set("sbc_channel_type", channel_type);
        this.UPDATE_CHANNEL_ID(channel_type);
        this.$router.push({ path });
      }catch(e) {
        console.error("on catch", e);
      }
    },
    ...mapMutations("$_modules", [
            "UPDATE_CHANNEL_ID","SELECT_GAME_ID"

    ]),
  },
};
</script>
<style>
  .tab-logo{
    height:40px;
    width:38px;
    margin-top:-3px
  }
  .lotto-nav-head {
    text-align: center;
    padding-top:0px!important;
    padding-bottom:5px!important;
  }
  .lotto-nav-head .span-btn{
    border: 0 !important;
  }
  .right-block-container .span-btn{
    display: inline-block;
    text-align: center;
    border-left: 0 !important;
    padding: 0;
  }
  .right-block-container a{
    
    text-align: center;
    
  }
  .right-block-container .x-tab-log{ margin-left: -12px;}
  .right-block-container .r-a-active{background-color: #3f4c7441;}
  .tab-game-position{
    border-style: solid;
    border-width: 2px;
    -o-border-image: linear-gradient(45deg,#013366,#fff,#013366) 1;
    border-image: linear-gradient(45deg,#013366,#fff,#013366) 1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    overflow-x: scroll;
  }
  .btn-custom{
    
    margin: 0px 3px;
    border-radius: 5px;
  }
  .span-active{
    background:grey
  }
</style>
