<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }} </h5>
    <div class="game-machine-component-result">
      <div class="trends limit-on-tab" style="position: relative;">
        <!-- <div v-if="isShow">
            <div v-if="resultObj.results && resultObj.results.length > 0" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;">
                <div class="h-100">
                    <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
                        <tr style="height: 38px;">
                            <th style="background-color: rgba(40, 90, 226, .93); width: 50%;"><b>Player</b></th>
                            <th style="background-color: rgba(224, 80, 73, .93); width: 50%;"><b>Banker</b></th>
                        </tr>
                        <tr>
                            <td style="background-color: rgba(40, 90, 226, .83); font-size: 42px;"><b>{{totalP}}</b></td>
                            <td style="background-color: rgba(224, 80, 73, .83); font-size: 42px;"><b>{{totalB}}</b></td>
                        </tr>
                    </table>
                </div>
            </div>
          </div> -->
        <div v-if="(scanPlayer && scanPlayer.length > 0) || (scanBanker && scanBanker.length > 0)"
          style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
              <tr style="height: 38px;">
                <th style="background-color: rgba(40, 90, 226, .93); width: 50%;"><b>Player</b></th>
                <th style="background-color: rgba(224, 80, 73, .93); width: 50%;"><b>Banker</b></th>
              </tr>
              <tr>
                <td style="background-color: rgba(40, 90, 226, .83); font-size: 42px;">
                  <b>{{ calTotal(scanPlayer) }}</b>
                </td>
                <td style="background-color: rgba(224, 80, 73, .83); font-size: 42px;">
                  <b>{{ calTotal(scanBanker) }}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <!-- <div class="trend-legend-custome gp-panel-bg d-flex justify-content-between align-items-center" style="background-color: #ffffff !important" >      
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-wala">{{resultObj.summary.P}}</span>
          <p class="mb-0 count-label">Player</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-wala">{{resultObj.summary.PP}}</span>
          <p class="mb-0 count-label">Pair Player</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-meron">{{resultObj.summary.B}}</span>
          <p class="mb-0 count-label">Banker</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-meron">{{resultObj.summary.PB}}</span>
          <p class="mb-0 count-label">Pair Banker</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-draw">{{resultObj.summary.T}}</span>
          <p class="mb-0 count-label">Tie</p>
        </div>
      </div> -->
          <div class="px-3 pb-0 gp-panel-bg landscape-trend"
            style="position: relative; background-color: #ffffff !important; padding: 0 !important;">
            <v-col cols="12" sm="12" style="padding: 0">
              <v-row style="padding: 0; margin: 0">
                <v-col cols="6" md="6" sm="6" lg="6" style="padding: 0; margin: 0">
                  <VideoLive :passurl="passUrl" :channelType="channelType" />
                </v-col>
                <v-col cols="6" md="6" sm="6" lg="6" style="padding: 10px 0 0 0; margin: 0">
                  <div class="table-responsive gp-scroll">
                    <table class="machine-table" border="1px">
                      <tbody>
                        <tr v-for="row in rows" :key="row">
                          <td v-for="col in fixColBac" :key="col">
                            <div>
                              <div v-for="(result, i) in orderBaccaratResult" :key="i">
                                <span v-if="col == result.col && row == result.row">
                                  <v-badge v-if="result.is_pair_player && result.is_pair_banker" bordered left
                                    color="red" dot offset-x="7" offset-y="7">
                                    <v-badge bordered bottom color="blue" dot offset-x="7" offset-y="7">
                                      <v-avatar class="bar-result machine-list-baccarat-result-ball" :color="calColor(result.color)" >
                                        <span>  {{
                                          result.name == "BANKER"
                                          ? "B"
                                          : result.name == "PLAYER"
                                            ? "P"
                                            : result.name == "TIE"
                                              ? "T"
                                              : "C"
                                        }}</span>
                                      </v-avatar>
                                    </v-badge>
                                  </v-badge>
                                  <v-badge v-else-if="result.is_pair_player &&
                                          !result.is_pair_banker
                                          " bordered bottom color="blue" dot offset-x="7" offset-y="7">
                                    <v-avatar :color="calColor(result.color)" class="bar-result machine-list-baccarat-result-ball" >
                                      <span>{{
                                        result.name == "BANKER"
                                        ? "B"
                                        : result.name == "PLAYER"
                                          ? "P"
                                          : result.name == "TIE"
                                            ? "T"
                                            : "C"
                                      }}</span>
                                    </v-avatar>
                                  </v-badge>
                                  <v-badge v-else-if="!result.is_pair_player &&
                                          result.is_pair_banker
                                          " bordered left color="red" dot offset-x="7" offset-y="7">
                                    <v-avatar :color="calColor(result.color)" class="bar-result machine-list-baccarat-result-ball" >
                                      <span>{{
                                        result.name == "BANKER"
                                        ? "B"
                                        : result.name == "PLAYER"
                                          ? "P"
                                          : result.name == "TIE"
                                            ? "T"
                                            : "C"
                                      }}</span>
                                    </v-avatar>
                                  </v-badge>
                                  <v-avatar v-else :color="calColor(result.color)" class="bar-result machine-list-baccarat-result-ball"  >
                                    <span>{{
                                      result.name == "BANKER"
                                      ? "B"
                                      : result.name == "PLAYER"
                                        ? "P"
                                        : result.name == "TIE"
                                          ? "T"
                                          : "C"
                                    }}
                                  </span>
                                  </v-avatar>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="game-machine-component-reserve-space"></div> -->
  </div>
</template>
<script>

import shareFunction from "../../../../../../mixin/MX_ShareFunctionCollection.js";
import { mapActions } from "vuex";
import VideoLive from "../GameResult/VideoLive.vue";
export default {
  components: {
    VideoLive
  },
  mixins: [shareFunction],
  props: ['passGame', 'passIndex', 'passUrl', 'channelType', 'resultObjS', 'passChDesc', 'showResult', 'scanPlayer', 'scanBanker'],
  data() {
    return {
      rows: 6,
      cols: 20,
      isShow: false,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {},

    }
  },
  watch: {
    resultObjS: function (newVal) {
      if (newVal.objCoResult) {
        // this.countDownTimer();
        this.resultObj.results = newVal.objCoResult
      }
    }
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
    totalPlayer(list) {
      return this.calTotal(list);
    },
    orderBaccaratResult() {
      return this.getBaccaratResults();
    },
    getColor(resultName) {
      if (resultName == "BANKER") {
        return "error";
      } else if (resultName == "PLAYER") {
        return "primary";
      } else if (resultName == "TIE") {
        return "success";
      } else {
        return "default";
      }
    },
  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  methods: {
  calColor(data){
    switch(data){
      case 'cir-blue': return 'blue';
      case 'cir-red': return 'red';
      case 'cir-cancel': return 'cancel';
      case 'cir-green': return 'green';
    }
  },
    calTotal(list) {
      var total = 0;
      for (var index = 0; index < list.length; index++) {
        total = total + list[index].value;
      }
      return (total % 10);
    },
    countDownTimer() {
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, 1000)
    },
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        //console.log(`getResult2 >>>> ${response}`)
        if (response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    getBaccaratResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj.results != null) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          // //console.log(item)
          if (row <= this.rows) {
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            row = 1;
            col += 1;
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
        if (col > this.cols) {
          this.fixColBac = col;
        } else {
          this.fixColBac = this.cols;
        }
      }
      return newResult;
    },
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (
        this.resultObj.results != null &&
        this.resultObj.results.length > 0
      ) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (pre.name != item.name) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              if (pre.row >= this.rows) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col;
        } else {
          this.fixCol = this.cols;
        }
      }
      //console.log(newResult)
      return newResult;
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult"]),
  }
}
</script>
<style>
/* .v-icon.v-icon{
    font-size: 18px !important
  } */
  
.v-application .pb-3 {
  padding-bottom: 2px !important;
}
</style>