<template>
    <div>
        <!-- <ChannelLiveInfo /> -->
        <ButtonBetting class="pt-0" v-on:sentSubmitBetting="receiveSubmitBetting" />
        <CoinButton :passGetButton="getButton" :passChannel="passChannel" :passPlaceCoin="getBettingAmount"/>
        <SubmitBtn :passCheckAllowSubmit="_checkAllowSubmit" v-on:handleSelectButton="_submitBetting()"
          v-on:handleClearBtn="_clearMoney" />


        <KlakloukReportMachineBettingComponent :passLoadingTodayReport="passLoadingTodayReport" />
        <v-row class="mx-0 justify-center w-100 mt-2">
            <v-col col-12 style="padding: 0;">
                <div class="show-on-mobile pt-5">
                    <TableResult :rows="6" :cols="35" />
                </div>
            </v-col>
        </v-row>


    </div>
</template>
<script>

import { mapGetters, mapMutations } from "vuex";
import CoinButton from "~global_components/component_machine_game/CoinBettingButton.vue";
import SubmitBtn from "~global_components/component_machine_game/SubmitBtnMachine.vue";
import TableResult from "../../TableResults.vue";
// import ChannelLiveInfo from '~global_components/ChannelLiveInfo.vue';
//import ListLiveCoins from '~global_components/ListLiveCoins.vue';
// import BettingSection from '~global_components/BettingSection.vue';
import KlakloukReportMachineBettingComponent from "../../_ReportBettingComponent/KlakloukReportMachineBettingComponent.vue";
//import SubmitBtn from "~global_components/SubmitBtn.vue";
import Mixin from "~mixin/MX_ShareFunctionCollection.js"
// import LimitBetting from '~global_components/LimitLiveBetting.vue';
import soundFunction from "~mixin/MX_SoundFunctionCollection.js";
import ButtonBetting from './ButtonBetting/ButtonBettingKlaklouk.vue'
//import CoinButton from "~global_components/CoinBettingButton.vue";


export default {
    components: { ButtonBetting, KlakloukReportMachineBettingComponent, TableResult, CoinButton, SubmitBtn},
    props: ["passChannel",
        'passResult',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passTodayReport',
        'passLoadingTodayReport',
        "passChannelList",
        "passSelectedChannel",
        "passCurrentBetting",
        "passNewChannelList",
        "passChannelType",
        "passUserCurrency"
    ],
    mixins: [soundFunction],
    data() {
        return {
            mixins: [Mixin],
            bettingMoney: 0,
            selectedButton: {},
            activeItem: null,
            selectedBtnClassMeron: false,
            selectedBtnClassTie: false,
            selectedBtnClassWala: false,
            selectedCoin: false,
        }
    },
    computed: {
        meronButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        ...mapGetters("$_dashboard_live", {
            channelObj: "getChannel",
            getButton: "getButton",
            currentBetting: "getCurrentBetting",
            loading: "getLoading",
            errorMessage: "getErrorMessage"
        }),
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
            getBettingAmount: "getBettingAmount",
            getBettingKlakloukAmount: "getBettingKlakloukAmount",

        }),
    },
    methods: {
        ...mapMutations("$_modules", [
            "UPDATE_BETTING_AMOUNT",
            "UPDATE_BETTING_KLAKLOUK_AMOUNT",
            "CLEAR_BETTING_KLAKLOUK_AMOUNT",
        ]),
        receiveSubmitBetting(recieveBettingData) {
            this.$emit("sentSubmitBetting", recieveBettingData);
        },
        _switchChannel(selectedChannel) {
            this.$emit("sentSetChannel", selectedChannel);
        },
         __xcheckallowsubmit(){
            let key = this.getBettingKlakloukAmount.status.key
            if(key !=""){
                if(this.getBettingKlakloukAmount[key].amout > 0 && this.getBettingKlakloukAmount[key].game_id !=""){
                    return false;
                }else{
                    return true;
                }
            }else{
                return true;
            }
           
        },

        _submitBetting() {
            // console.log('hey')
            let key = this.getBettingKlakloukAmount.status.key
            let _id = this.getBettingKlakloukAmount[key].game_id
            let amount = this.getBettingKlakloukAmount[key].amout

            let submitBettingData = {
                type_id: _id,
                amount: amount
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();

        },
        _inputAmount(money) {
            this.UPDATE_BETTING_AMOUNT(money);
            this._playSoundInsertCoin()
            let name = this.getBettingKlakloukAmount.status.name
            if (name != "") {
                let key = this.getBettingKlakloukAmount.status.key
                let _id = this.getBettingKlakloukAmount[key].game_id
                this.CLEAR_BETTING_KLAKLOUK_AMOUNT()
                this.UPDATE_BETTING_KLAKLOUK_AMOUNT([name, this.getBettingAmount, _id]);
            }
            this.__xcheckallowsubmit();

        },
        _clearMoney() {
            this.CLEAR_BETTING_AMOUNT()
            this.CLEAR_BETTING_KLAKLOUK_AMOUNT()
            this.__xcheckallowsubmit();
            /*this.CLEAR_BETTING_KLAKLOUK_AMOUNT()*/
        },
        _allowBtnBet(selectedBtn) {
            switch (selectedBtn) {
                case 'b1':
                    this.selectedButton = this.meronButton;
                    this._submitBetting()
                    break;
                case 'b2':
                    this.selectedButton = this.walaButton;
                    this._submitBetting()
                    break;
                case 'b3':
                    this.selectedButton = this.tieButton;
                    this._submitBetting()
                    break;
            }
        },
        _checkAllowSubmit() {
            if (this.getBettingAmount > 0
            ) return true;
            else return false;
        },

        findImageOfEachAnimal(animalName) {
            switch (animalName) {
                case "ឃ្លោក":
                    return "/images/a_each_game/klaklok/02.png";
                case "មាន់":
                    return "/images/a_each_game/klaklok/03.png";
                case "បង្កង":
                    return "/images/a_each_game/klaklok/04.png";
                case "ក្ដាម":
                    return "/images/a_each_game/klaklok/05.png";
                case "ត្រី":
                    return "/images/a_each_game/klaklok/06.png";
                case "ខ្លា":
                    return "/images/a_each_game/klaklok/01.png";
                case "CANCEL":
                    return "/images/a_each_game/klaklok/cancel.png";
                default:
                    return ''
                // code block
            }
        },
        _detectColor(index) {
            switch (index) {
                case 0:
                    return 'black';
                case 1:
                    return 'black';
                case 2:
                    return 'black';
                case 3:
                    return 'black';
                case 4:
                    return 'black';
                case 5:
                    return 'black';
                case 6:
                    return 'black';
                case 7:
                    return 'black';
                case 99:
                    return 'white';
                default:
                    return ''
            }
        },
        _detectCoin(index) {
            switch (index) {
                case 0:
                    return '/images/coins/blue.png';
                case 1:
                    return '/images/coins/pink.png';
                case 2:
                    return '/images/coins/green.png';
                case 3:
                    return '/images/coins/cyan.png';
                case 4:
                    return '/images/coins/orange.png';
                case 5:
                    return '/images/coins/red.png';
                case 6:
                    return '/images/coins/blue.png';
                case 7:
                    return '/images/coins/pink.png';
                case 33:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },

        ...mapMutations("$_modules", [
            "CLEAR_BETTING_AMOUNT", "CLEAR_BETTING_KLAKLOUK_AMOUNT",])
    }

}
</script>

<style scoped>
.btn-switch-channel:has(.to-hidden) {
    display: none !important
}

.video-section {
    position: relative;
    overflow: hidden;
    background-color: rgba(2, 90, 179, 0.6)
}

.show-on-mobile {
    display: none;
}

@media(max-width:768px) {
    .show-on-mobile {
        display: block;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.han2-btn-bet-row {
    text-align: center;
    display: grid !important;
    grid-template-columns: auto auto auto;
    gap: 0px;
    padding-bottom: 0px !important;
}

.han2-btn-bet-item {

    border: 1px solid #ffffff;
    /*
    padding: 6px 26px 20px 26px;
    margin-top: 16px;
    margin-right: 16px;
    margin-bottom: 6px;
    */
    border-radius: 6px;
    width: 78%;
    margin: 0px 0px 16px 0px;
    padding-bottom: 3px;
}

.han2-btn-bet-item:hover {
    cursor: pointer;
}

.banker-color {
    color: #ff0e1a;
}

.player-color {
    color: blue;
}

.range-betting-payout img {
    height: auto;
    width: 100%;
}

.x-mg-pd-0 {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.range-betting-payout {
    text-align: center;
    margin-top: -2px;
    font-size: 1.5rem;
    padding-top: 2px;
    text-shadow: 0 1px 0 #ffffff,
        0 1px 0 #ffffff,
        0 1px 0 #ffffff,
        0 1px 0 #ffffff,
        0 1px 0 #ffffff,
        0 3px 1px rgba(77, 74, 74, 0.1),
        0 0 3px rgba(133, 122, 122, 0.1),
        0 1px 3px rgba(157, 148, 148, 0.3),
        0 3px 1px rgba(177, 172, 172, 0.2),
        0 1px 3px rgba(160, 156, 156, 0.25),
        0 3px 3px rgba(177, 176, 176, 0.2),
        0 3px 1px rgba(165, 162, 162, 0.15);
}

.text-display-payout {
    text-align: center;
    font-size: 2rem;
    color: white;
    padding-top: 3px;
    font-weight: 600;
}


@media screen and (max-width: 1904px) {
    .text-display-payout {
        font-size: 2rem !important;
    }

    .range-betting-payout {
        font-size: 2rem;
    }
}

@media screen and (max-width:1280px) {
    .text-display-payout {
        font-size: 2rem !important;
    }

    .range-betting-payout {
        font-size: 1.3rem;
    }
}

@media screen and (max-width:982px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: 1.1rem;
    }
}

@media screen and (max-width:960px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: 1rem
    }
}

@media screen and (max-width:959px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: 1.2rem
    }
}

@media screen and (max-width:768px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: 1.1rem
    }
}

@media screen and (max-width:700px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: .80rem
    }
}

@media screen and (max-width:600px) {
    .range-betting-payout {
        font-size: .80rem
    }
}

@media screen and (max-width:529px) {
    .range-betting-payout {
        font-size: 1.2rem !important
    }

}

@media screen and (max-width: 390px) {
    .text-display-payout {
        font-size: .75rem;
    }

    .range-betting-payout {
        font-size: .70rem
    }
}

@media screen and (max-width: 375px) {
    .range-betting-payout {
        font-size: .90rem;
    }
}

.han2-min-max-limit .max-label {
    float: right !important;
    width: auto !important;
    margin-top: 8px !important;
}

@media screen and (min-width: 1904px) {}

@media screen and (min-width:1280px) {}

@media screen and (min-width:982px) {}

@media screen and (min-width:960px) {}

@media screen and (min-width:959px) {}

@media screen and (min-width:768px) {}

@media screen and (min-width:700px) {}

@media screen and (min-width:600px) {}

@media screen and (min-width:529px) {
    .kalakouk-row {
        padding-left: 46px !important;
    }

}

@media screen and (min-width:450px) {
    .kalakouk-row {
        padding-left: 36px !important;
    }

}

@media screen and (min-width:428px) {
    .kalakouk-row {
        padding-left: 38px !important;
    }

}

@media screen and (min-width:414px) {
    .kalakouk-row {
        padding-left: 32px !important;
    }

}

@media screen and (min-width: 390px) {
    .kalakouk-row {
        padding-left: 32px !important;
    }
}

@media screen and (min-width: 384px) {
    .kalakouk-row {
        padding-left: 32px !important;
    }
}

@media screen and (min-width: 375px) {
    .kalakouk-row {
        padding-left: 30px !important;
    }
}


/* coin betting button*/

@media(max-width:760px) {
    .btn-coin {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0.2rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-coin-cover {
        width: 100%
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 1px 0px 1px black;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -51%);
        padding: 0px;
        margin: 0px !important;
    }
}

@media(min-width:760px) {
    .btn-coin {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0.25rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 1px 0px 1px black;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -51%);
        padding: 0px;
        margin: 0px !important;
    }
}

@media(min-width:960px) {
    .btn-coin {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0.25rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 1px 0px 1px black;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -51%);
        padding: 0px;
        margin: 0px !important;
    }
}

@media(min-width:1280px) {
    .btn-coin {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0.3rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .money-in-coin-position {
        margin-top: -52px;
        margin-bottom: 25px;
        z-index: 100;
        font-weight: 900;
        font-size: 16px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -51%);
        padding: 0px;
        margin: 0px !important;
    }
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.hold-coin {
    position: sticky;
    left: 0px;
    z-index: 100;
    background: #013366;
    font-size: bolder;
    border-right: 2px dashed white;
}

.coin-container {
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
}

.coin-container:hover {
    cursor: pointer;
}

.display-money {
    background-color: #7592af65;
    height: 40px;
    border-radius: 5px;
    margin: 5px 0px 7px 0px;
    color: #212427;
    font-weight: bolder;
    text-shadow: 1px 0.2px #406386;
    font-size: 1.3rem;
    padding-top: 5px;
    color:white;
}

.btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
            rgba(201, 201, 214, 1) 0%,
            rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
}

.btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
            rgba(110, 179, 241, 0.9108018207282913) 0%,
            rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    height: 30px !important;
    justify-content: center;
}

.btn-clear-money:hover {
    cursor: pointer;
}

.btn-submit-money:hover {
    cursor: pointer;
}
</style>