<template>
  <div>
    
    <v-row class="ma-0 w-100">
      <div class="machine-baccarat-three-section machine-blue-title text-center">
        <div class="machine-klaklouk-button pt-0 bg-white"
        :class="getSelectedKlakloukBtn && getSelectedKlakloukBtn.button && getSelectedKlakloukBtn.button.name == defineOrder()[0].name && getSelectedKlakloukBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[0])"> 
          <img :src="findImgKlakloukButton(7)" alt="" class="machine-klaklouk-img-frame">
          <img :src="findImgKlakloukButton(1)" alt="" class="machine-klaklouk-img-btn">
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0  bg-white" :class=" getSelectedKlakloukBtn && getSelectedKlakloukBtn.button && getSelectedKlakloukBtn.button.name ==defineOrder()[3].name && getSelectedKlakloukBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[3])"> 
          <img :src="findImgKlakloukButton(7)" alt="" class="machine-klaklouk-img-frame">
          <img :src="findImgKlakloukButton(4)" alt="" class="machine-klaklouk-img-btn">
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-baccarat-three-section machine-green-title text-center">
        <div class="machine-klaklouk-button pt-0  bg-white" :class=" getSelectedKlakloukBtn && getSelectedKlakloukBtn.button && getSelectedKlakloukBtn.button.name ==defineOrder()[1].name && getSelectedKlakloukBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[1])">
          <img :src="findImgKlakloukButton(7)" alt="" class="machine-klaklouk-img-frame">
          <img :src="findImgKlakloukButton(2)" alt="" class="machine-klaklouk-img-btn" > 
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0  bg-white" :class=" getSelectedKlakloukBtn && getSelectedKlakloukBtn.button && getSelectedKlakloukBtn.button.name ==defineOrder()[4].name && getSelectedKlakloukBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[4])">
          <img :src="findImgKlakloukButton(7)" alt="" class="machine-klaklouk-img-frame">
          <img :src="findImgKlakloukButton(5)" alt="" class="machine-klaklouk-img-btn">
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-baccarat-three-section machine-red-title text-center">
        <div class="machine-klaklouk-button pt-0  bg-white" :class="getSelectedKlakloukBtn && getSelectedKlakloukBtn.button && getSelectedKlakloukBtn.button.name ==defineOrder()[2].name && getSelectedKlakloukBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[2])"> 
          <img :src="findImgKlakloukButton(7)" alt="" class="machine-klaklouk-img-frame">
          <img :src="findImgKlakloukButton(3)" alt="" class="machine-klaklouk-img-btn" >
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0  bg-white" :class=" getSelectedKlakloukBtn && getSelectedKlakloukBtn.button && getSelectedKlakloukBtn.button.name ==defineOrder()[5].name && getSelectedKlakloukBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[5])"> 
          <img :src="findImgKlakloukButton(7)" alt="" class="machine-klaklouk-img-frame">
          <img :src="findImgKlakloukButton(6)" alt="" class="machine-klaklouk-img-btn">
        </div>
      </div>
    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitKlaklouk'"/>
  </div>
</template>
<script>

import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"
import findImg from "../../../../../../mixin/MX_FindImageFunctionCollection.js"

import {mapGetters, mapMutations } from "vuex";

export default {
  mixins: [findImg],
  components: {
    CockXCasinoSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  computed:{
    
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedKlakloukBtn: "getSelectedKlakloukBtn",
    }),
  },

  methods:{
    defineOrder(){
      return this.passBetType.slice().sort(function(a, b){
        return (a.order > b.order) ? 1 : -1;
      });
    },
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_KLAKLOUK_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_KLAKLOUK_BUTTON_IN_ALL",
    ]),
  }
}
</script>