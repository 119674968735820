<template>
  <v-container fluid class="pa-0 report-page-container" style="background:#bfc0c4; margin:auto">
    <v-toolbar flat dark class="toolbar-style"></v-toolbar>
    <v-card flat class="linea-container">
        <v-col class="pa-0">
          <HeaderWithdraw @child-checkbox="dateWinLoseFilterValue" />
            <v-data-table 
              class="elevation-1 report-table"
              item-key="_id" 
              mobile-breakpoint="NaN"
              hide-default-header
              :headers="headersReportWithdraw" :footer-props="footerProps" :page.sync="dataRB.page"
              :items="dataReportWithdraw.reports" :items-per-page.sync="dataRB.rowsPerPage"
              :server-items-length="dataReportWithdraw.total_rows" :loading="getLoading"
                      @update:options="reportWithdraw" @update:page="reportWithdraw"
                      @update:items-per-page="reportWithdraw" @update:sort-desc="reportWithdraw">

                      <template v-slot:header="{ props }">
                        <th
                          v-for="(header, index) in props.headers"
                          :key="index"
                          class="text-center pt-4 header-text-style"
                        >
                        <h5 class="ma-0 text-white">{{ $t(`title.${header.text}`) }}</h5>
                        </th>
                      </template>
                      <template v-slot:[`item.date`]="{ item }">
                       <h5> {{ item.date }}</h5>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <span v-if="item.amount >= 0" class="c-white">
                          <h5>{{ formatMoney(item.amount) }}  </h5>
                        </span>
                      </template>
                      <template v-slot:[`item.old_balance`]="{ item }">
                        <h5>{{ formatMoney(item.old_balance) }}</h5>
                      </template>
                      <template v-slot:[`item.new_balance`]="{ item }">
                       <h5> {{ formatMoney(item.new_balance) }}</h5>
                      </template>
            </v-data-table>
        </v-col>
    </v-card>
  </v-container>
</template>

<script>
import "@/assets/css/statement/report_statement.css";

import headersReportWithdraw from "./_api/columnsReportWithdraw";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import clickMixin from "~mixin/MX_ShareFunctionCollection.js";
import moment from "moment";
export default {
  components: {
    HeaderWithdraw: () => import("./components/HeaderWithdraw.vue"),
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s | WITHDRAW STATEMENT`
    },
  data() {
    return {
      headersReportWithdraw: headersReportWithdraw,
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      loading: false,
      channelType: 1,
      dialog: false,
      account_info: null,
      mixins: [clickMixin],
      dataRB: {
        page: 1,
        rowsPerPage: 10,
      },
      dateFilterRB: {},
    };
  },
  item: {
    type: Object,
    default: null,
  },
  mounted() {
    this.channelType = this.$cookies.get("sbc_channel_type")
      ? this.$cookies.get("sbc_channel_type")
      : 1;
  },
  watch: {
    channelId: function (newVal, oldVal) {
      // console.log(`channelId new: ${newVal} old: ${oldVal}`)
      if (newVal != oldVal) {
        this.channelType = newVal;
        this.reportWithdraw();
      }
    },
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_withdraw", {
      dataReportWithdraw: "getReportWithdraw",
    }),
    ...mapGetters("$_withdraw", ["getLoading"]),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },

  methods: {
    moment,
    dateWinLoseFilterValue: function (params) {
      this.dateFilterRB = params;
      this.dataRB = {
        page: 1,
        rowsPerPage: 10,
      };
      this.reportWithdraw();
    },
    reset() {
      this.$refs.form.reset();
    },
    convertDateTime(date, format = 'DD-MM-YYYY HH:mm:ss') {
            if (!date) return '';
            return moment(new Date(date)).format(format);
        },
    getUserCurrency() {
      if (this.userInfor.currency_type == 1) {
        return "$";
      } else if (this.userInfor.currency_type == 2) {
        return "៛";
      } else if (this.userInfor.currency_type == 3) {
        return "฿";
      }
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    async getUserInfo() {
      await this.fetchUserInfo();
    },
    async reportWithdraw() {
      let body = {};
      if (
        this.dateFilterRB.startDate != null &&
        this.dateFilterRB.endDate != null
      ) {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
          start_date: this.dateFilterRB.startDate,
          end_date: this.dateFilterRB.endDate,
        };
      } else {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
        };
      }
      await this.fetchReportWithdraw(body);
    },
    ...mapActions("$_myinfo", ["fetchMyInfo"]),
    ...mapMutations("$_withdraw", ["REPORT_WITHDRAW"]),
    ...mapActions("$_withdraw", ["fetchReportWithdraw"]),
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

.container {
  height: auto !important;
}

.header-text-style{
  font-weight:600; 
  font-size:14px; 
  color: rgba(255, 255, 255, 0.698);
  padding-bottom:15px;
  border-bottom:1px solid rgb(128, 128, 128,.5);
}
</style>