<template>
  <div>
    <v-row class="ma-0 w-100">
      <div class="cock-three-section machine-blue-title machine-play-blue-bg" 
        :class="getSelectedCockBtn && getSelectedCockBtn.button 
        && getSelectedCockBtn.button.name == passBetType[2].name 
        && getSelectedCockBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[2])"> 
        <p class="machine-payout-name cock-casino-button-font">{{ passBetType ? passBetType[2].payout : 'x.xx'}}</p>
        <p class="machine-button-name cock-casino-button-font white--text">{{passBetType[2] ? passBetType[2].name : 'Meron'}}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="cock-three-section machine-green-title machine-play-green-bg"
        :class="getSelectedCockBtn && getSelectedCockBtn.button 
        && getSelectedCockBtn.button.name == passBetType[1].name 
        && getSelectedCockBtn.channelType == passChannelType?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[1])"> 
        <p class="machine-payout cock-casino-button-font">{{passBetType ? passBetType[1].payout : 'x.xx'}}</p>
        <p class="machine-button-name cock-casino-button-font white--text">{{passBetType[1] ? passBetType[1].name : 'DRAW'}}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="cock-three-section machine-red-title machine-play-red-bg"
      :class="getSelectedCockBtn && getSelectedCockBtn.button 
        && getSelectedCockBtn.button.name == passBetType[0].name
        && getSelectedCockBtn.channelType == passChannelType?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[0])"> 
        <p class="machine-payout-name cock-casino-button-font">{{passBetType ? passBetType[0].payout : 'x.xx'}}</p>
        <p class="machine-button-name cock-casino-button-font white--text">{{passBetType[0] ? passBetType[0].name : 'DRAGON'}}</p>
      </div>
    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitCockFight'"/>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"

export default {
  components: {
    CockXCasinoSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  data() {
    return {
      payout: {},
      
    }
  },
  computed:{
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedCockBtn: "getSelectedCockBtn",
    }),
  },
  watch: {
    // resultObjS: function() { 
    //   this.getResult(this.channelType);
    // }
  },

  methods:{
 
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_COCK_BUTTON_IN_ALL(data);
    },

    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_COCK_BUTTON_IN_ALL",
    ]),
  }
}
</script>