<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0">
        <div>
            <div class="g-summary">
                <div class="sides-wrapper gp-panel-bg rounded">
                    <div class="d-flex sides bet-btn-container">
                        <div v-if="meronButton" class="side-section meron-section pb-1 side-1"
                            :class="getSelectedCockBtn.selectedBtnClassMeron ? 'on-active-btn' : ''"
                            
                            @click="_allowBtnBet('selectedMeron', meronButton)">

                            <div class="current-bet-amount"><span class="bet-amount">
                                {{
                                    _formatAmountCurrency(userInfo, currentBetting.meron )
                                }}
                                </span>
                                <div class="odds">{{ payout ? _sameGameCheckPayout(passSelectedChannel, 'meron', payout.meron.payout) : 'X.xx' }}</div>
                                <div class="side-title pt-1">
                                    <div class="truncate ">{{ meronButton ? $t( sameGameFindName(passSelectedChannel) + meronButton.name)
                                        : ''
                                    }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="tieButton" class="side-section-tie tie-section  mx-2 side-1"
                            :class="[getSelectedCockBtn.selectedBtnClassTie ? 'on-active-btn' : '', _sameGameCheckTieColorStyle(passSelectedChannel) ]" @click="_allowBtnBet('selectedTie')" >
                
                            <div class="current-bet-amount mb-0 "><span class="bet-amount">
                                {{
                                _formatAmountCurrency(userInfo, currentBetting.tie )
                            }}</span>

                                <div class="odds font-weight-bold">{{ payout ?  _sameGameCheckPayout(passSelectedChannel, 'tie', '1 : 8') : 'X.xx' }}</div>
                                <div class="side-title pt-1">
                                    <span class="truncate ">{{ $t(sameGameFindName(passSelectedChannel)+'draw') }}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="walaButton" 
                            class="side-section wala-section pb-1 side-1"
                            :class="[getSelectedCockBtn.selectedBtnClassWala ? 'on-active-btn' : '', _sameGameCheckBlueColorStyle(passSelectedChannel)]"
                            @click="_allowBtnBet('selectedWala')">
                            
                            <div class="current-bet-amount mb-0 "><span class="bet-amount">
                                {{
                                _formatAmountCurrency(userInfo, currentBetting.wala )
                            }}</span>
                                <div class="odds">{{ payout ?  _sameGameCheckPayout(passSelectedChannel, 'wala',payout.wala.payout) : 'X.xx' }}</div>
                                <div class="side-title pt-1">
                                    <div class="truncate ">{{ walaButton ? $t(sameGameFindName(passSelectedChannel) + walaButton.name) :
                                        ''
                                    }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Group Coin -->

        </div>
    </div> 
</template>

<script>
// import "@/assets/css/homepage.css";

import { mapGetters, mapMutations } from "vuex";
import dashboardFunc from "~mixin/MX_ShareFunctionCollection.js"
export default {   
    dashboardFunc: [dashboardFunc], 
    data() {
        return {
            
        }
    },
    computed: {
        meronButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[2]
            else return {}
        },

        ...mapGetters("$_dashboard", {
            passChannel: "getChannel",
            payout: "getWeight",
            currentBetting: "getCurrentBetting",
            loading: "getLoading",
            bettingTypeMaster: "getBettingTypeMaster",
            getSelectedCockBtn :"getSelectedCockBtn"
            
        }),
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
            passSelectedChannel: "getChannelId"
        }),
    },
    methods: {
        _allowBtnBet(selectedBtn) {
            switch (selectedBtn) {
                case 'selectedMeron':
                    this.assignSelectedValue(this.meronButton, true, false, false)                  
                    break;
                case 'selectedTie':
                    this.assignSelectedValue(this.tieButton, false, true, false)
                    break;
                case 'selectedWala':
                    this.assignSelectedValue(this.walaButton, false, false, true)                  
                    break;
                default :
                    break;
            }
        },
        assignSelectedValue(selectedButton, selectedBtnClassMeron, selectedBtnClassTie, selectedBtnClassWala ){
            let data = {
                    selectedButton : selectedButton,
                    selectedBtnClassMeron : selectedBtnClassMeron,
                    selectedBtnClassTie : selectedBtnClassTie,
                    selectedBtnClassWala: selectedBtnClassWala
                }
            this.UPDATE_COCK_SELECTED_BTN(data)     
        },
        checkChannelStatus() {
            if (this.passChannel.is_open == null) {
                return "Not_Open"
            } else {
                if (this.passChannel.is_open) {
                    return "Open"
                }
                return "Close"
            }
        },
        textColorBaseOnChannelStatus() {
            if (this.passChannel.is_open == null) {
                return "text-warning"
            } else {
                if (this.passChannel.is_open) {
                    return "text-success"
                }
                return "text-danger"
            }
        },
    
        ...mapMutations("$_dashboard", [
            "UPDATE_COCK_SELECTED_BTN",
        ]),
    }
}
</script>
<style scoped>
.channel-list-bg {

    background-color: #17202a;
    border: 1px solid #34495E;
    margin: 0px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: 0px;

}
.bet-btn-icon{
    font-size:.8rem!important; margin-top:0px!important; color:white!important;
}
.bet-amount{
    font-weight:600; 
    font-size:1.5rem;
    color:white;
}
</style>