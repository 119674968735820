<template>
  <div id="pcoded" class="pcoded iscollapsed" nav-type="st2" theme-layout="vertical" vertical-placement="left"
    vertical-layout="wide" pcoded-device-type="desktop" vertical-nav-type="expanded" vertical-effect="shrink"
    vnavigation-view="view1" fream-type="theme1" sidebar-img="false" sidebar-img-type="img1" layout-type="light">
    <div class="one-container navbar-wrapper">
      <GlobalComponentDesktop />
      <v-main class="p-0 v-main-style" style="min-height:100vh">
        <div class="m-0 p-0">
          <router-view></router-view>
        </div>
      </v-main>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import GlobalComponentDesktop from '../components/GlobalComponentDesktop.vue';
import MX_Socket_Global from "@/mixin/SocketGame/MX_Socket_Global.js";
export default {
  components: { GlobalComponentDesktop },
  mixins: [MX_Socket_Global],
  data: () => ({

    channelList: null,
    inset: true,
    drawer: null,
    error: false,
    user_name: null,
    dialog: false,
    balance: null,
    value: 'recent',
    data: {
      account_info: null,
      descending: true,
      sortBy: '_id',
      fields: ['fight_no', 'date']
    },
    dataNotification: {
      rows_per_page: 6,
      page: 1,
    }
  }),
  created() {
    this._globalSocketListener();
    this.initialData();
  },
  computed: {

    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      userNotList: "getNotList",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },

  methods: {
    initialData() {
      this.fetchPromoText()
      this.getUserInfo();
    },
    isSuspend() {
      if (this.userInfo) {
        if (this.userInfo.is_suspend) {
          this.onLogout()
        }
      }
    },
    onLogout() {
      this._disconnectGlobalSocket();
      this.$socket.removeAllListeners()
      this.$socket.disconnect()
      this.$cookies.remove('sbc_channel_type')
      this.$cookies.remove('sbc_token')
      this.$router.push("/login")
    },
    currencyFormat(num) {
      if (num) {
        num = parseFloat(num)
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
      return num
    },
    getUserCurrency() {
      if (this.userInfo.currency_type == 1) {
        return "$"
      } else if (this.userInfo.currency_type == 2) {
        return "៛"
      } else if (this.userInfo.currency_type == 3) {
        return "฿"
      }
    },
    async getNotification() {
      this.dataNotification = {
        rows_per_page: 6,
        page: 1,
      };
      this.UPDATE_NOTI_DATA([]);
      await this.fetchNotList(this.data);
      await this.fetchNotCount();
      this.UPDATE_NOTI_DATA(this.userNotList.reports);
    },
    async getUserInfo() {
      await this.fetchUserInfo();
    },
    ...mapActions("$_myinfo", [
      "fetchMyInfo",
    ]),
    ...mapActions("$_modules", ["fetchPromoText", "updateBalance", "updateUserInfo", "fetchUserInfo", 'fetchNotList', 'fetchNotCount']),
    ...mapMutations("$_modules", [
      "UPDATE_USER_INFO",
      "UPDATE_AUDIO", 'UPDATE_NOTI_DATA']),
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
}   
</script>
<style scoped>
@media (min-width: 599) {
  .desktop-screen-mobile {
    display: none !important;
    background: red;
    width: 600px;
    height: 300px;
  }
}

@media (max-width: 600) {
  .mobile-screen-home {
    display: none !important;
    background: red;
    width: 600px;
    height: 300px;
  }
}

.navi-bottom-style {
  position: fixed;
  height: 43px !important;
  color: white !important;
  background: #1f3147 !important;
  z-index: 999 !important;
}

.v-main-style{
  overflow:hidden; 
  background-image: url('../../public/images/a_page_access_global/SBC2888\ Background.jpg')!important;
}
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
</style>