<template>
    <div class="history limit-on-tab" >
        <v-tabs fixed-tabs>
            <v-tabs-slider class="cock-tab-slider"></v-tabs-slider>
            <v-tab @click="callCurrentBetting" >
                <i class="bi-coin report-betting-icon" ></i>
                <span class="report-betting-table-style">
                    {{ $t("title.current_betting") }}
                </span>
            </v-tab>
            <v-tab @click="callTodayReport" >
                <i class="bi-clipboard-data report-betting-icon" ></i>
                <span class="report-betting-table-style">
                    {{ $t("title.today_report")}}
                </span>
            </v-tab>
            <v-tab-item>
                <v-simple-table  class="elevation-1 cock-report-betting-table" fixed-header height="279.1" min-height="279.1">
                    <template v-slot:default>
                        <thead >
                            <tr>
                                <th class="w-25 text-header" style="min-width:70px">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="w-25 text-header" style="min-width:120px">
                                    {{ $t("title.type") }}
                                </th>
                                <th class=" w-25 text-header" style="min-width:150px">
                                    {{ $t("title.return") }}({{userInfor.currencySymbol}})
                                </th>
                                <th class="w-25 text-header" style="min-width:120px">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="current in currentBetting.list" :key="current._id" style="height:42px">
                                <td class="font-weight-bold text-center" style="font-size:1.3rem">
                                    #{{current.fight_no}}
                                </td>
                                <td>
                                    <v-chip label :class="current.bet_color" class="show-chip">
                                        <span class="white--text text-center" style="min-width:55px!important; font-size:1.3rem">
                                            {{ $t('title.'+current.betting) }}
                                        </span>
                                    </v-chip>
                                </td>
                                <td  class="font-weight-bold " style="font-size:1.3rem">
                                    {{ formatMoney(current.bet_amount)+' x ' + current.payout + '= ???'}}
                                </td>
                                <td class="font-weight-bold text-center">
                                    <print-betting :current="current" />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-tab-item>
            <v-tab-item>
                <v-simple-table  class="elevation-1 theme--light" fixed-header height="279.1" min-height="279.1">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-header" style="min-width:70px">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-header" style="min-width:120px">
                                    {{ $t("title.time") }}
                                </th>
                                <th class="text-header" style="min-width:120px">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-header" style="min-width:150px">
                                    {{ $t("title.return") }} ({{userInfor.currencySymbol}})
                                </th>
                                <th class="text-header" style="min-width:140px">
                                    {{ $t("title.result") }}
                                </th>
                                <th class="text-header" style="min-width:120px">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="today in todayBetting" :key="today._id">
                                <td class="text-left">
                                    #{{ today.fight_no }}
                                </td>
                                <td class="text-left">
                                    {{ grtDate(today.time) }}
                                </td>
                                <td class="text-left" :class="'c' + today.bet_color">
                                    <v-chip label :class="today.bet_color" style="padding:4px;color:#ffffff; font-size:12px;height:22px">
                                        <span class="white--text">
                                            {{ $t('title.'+today.betting) }}
                                        </span>
                                    </v-chip>
                                </td>
                                <td 
                                    
                                    :class="
                                          today.is_win == null
                                            ? 'c' + today.result_color
                                            : today.is_win
                                            ? ''
                                            : 'ccir-red'
                                        " class="text-left">
                                    
                                    {{ today.is_win == true ? formatMoney(today.bet_amount) +' x ' + today.payout + ' = ' +  formatMoney(today.case) : today.is_win == false ? formatMoney(today.amount_win) : formatMoney(today.bet_amount) +' x ' + today.payout + ' = ' +  formatMoney(today.bet_amount)}}

                                </td>
                                <td :class="'c' + today.result_color" class="text-left">
                                    <v-chip label :class="today.result_color" style="padding:4px;color:#ffffff; font-size:12px;height:22px">
                                        <span class="white--text">
                                            {{ $t('title.'+today.result_name) }}
                                        </span>
                                    </v-chip>
                                </td>
                                <td class="text-left">
                                    
                                    <print-today-report :todayReport="today" />
                                </td>
                            </tr>
                        </tbody>
                        <!-- <div class="py-3 text-center gp-panel-bg mx-2">
                            <div class="bet d-flex my-2 align-items-center " v-for="today in passTodayReport"
                                :key="today._id">
                                <div><span class="fight-circle font-weight-bold " :class="'report-' + today.bet_color">
                                        # {{ today.fight_no }}
                                    </span></div>
                                <div class="flex-grow-1 pl-0 pr-2 px-sm-2 w-100">
                                    <div class="d-flex justify-content-between align-items-center text-white">
                                        <div class="pl-1" :class="today.is_win ? 'text-wala' : today.is_win == false ? 'text-meron' :'txt-white'">{{ today.amount }}</div>
                                        <div class="d-flex flex-column align-items-end">
                                            <div class="d-inline " :class="today.is_win ? 'text-wala' : today.is_win == false ? 'text-meron' : today.result_name =='TIE' ? 'txt-gold' : 'txt-grey'">
                                                {{ today.is_win ? 'Win' : today.is_win == false ? 'Lose' : today.result_name =='TIE' ? 'Draw' : 'Cancelled' }}
                                            </div>
                                            <small class="font-italic text-muted">
                                                {{ today.time }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </template>
                </v-simple-table>
            </v-tab-item>
        </v-tabs> 
    </div>
</template>
<script>

//import "@/assets/css/homepage.css";
import { mapActions, mapGetters} from "vuex";
import PrintBetting from "../PrintBetting.vue";
import PrintTodayReport from "../PrintTodayReport.vue";
import clickMixin from "../../../../../mixin/MX_ShareFunctionCollection.js"
export default {
    props: ['passLoadingTodayReport'],
    components: {
        PrintBetting,
        PrintTodayReport
    },
    data() {
        return {
            mixins: [clickMixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_dashboard", {
            todayBetting: "getTodayBetting",
            currentBetting: "getCurrentBetting"
        }),
    },
    methods: {
        _resultMoney(today){
            if(today.amount_win <=0) return this.formatMoney(today.amount_win)
            else return this.formatMoney(today.case)
        },
        grtDate(date){
            let time = date.split(" ")
            if(time[1]){
                return time[1];
            }
            return date
        },
        async callCurrentBetting() {
            await this.fetchCurrentBetting();
        },
        async callTodayReport() {
            await this.fetchTodayBetting();
        },
        _sentRefreshTodayReport() {
            this.$emit("sentRefreshTodayReport");
        },
        _sentRefreshCurrentReport() {
            this.$emit("sentRefreshCurrentReport");
        },
        ...mapActions("$_dashboard", [
            "fetchTodayBetting",
            "fetchCurrentBetting"
        ]),
    }
}
</script>
<style>
    /* width */
    ::-webkit-scrollbar {
        width: 5px
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(9, 17, 40, .7)
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: rgba(40, 53, 89, .8);
        border-radius: 10px
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(78, 106, 181, .2)
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg)
        }
        to {
            transform: rotate(360deg)
        }
    }
    .refresh-start {
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-play-state: running;
    }
    .text-meron {
        color: #f93c3c!important
    }
    .txt-white{
        color:rgb(255, 255, 255,0.5)
    }
    .txt-gold{
        color:#FFC008
    }
    .txt-grey{
        color:#6C757D
    }
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
        height: 32px !important;
    }
    .history {
        border-top:3px solid black!important;
    }
    .text-header{
        color: #212427!important;
        font-weight:600;
        font-size:1rem!important;
        text-align: center!important;
    }
    .v-data-table__wrapper{
        height: 350px!important;
    }
    .show-chip{
        padding: 15px 30px;
        color:#ffffff; font-size:12px; height:22px;
    }
</style>