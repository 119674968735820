<template>

  <!-- 
  <div class="trends limit-on-tab">
    <div>
      <div
        class="trend-legend gp-panel-bg d-flex justify-content-between align-items-center"
        style="background-color: #ffffff !important"
      >
         
       <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-wala">{{
            resultObj.summary.P
          }}</span>
          <p class="mb-0 count-label">Player</p>
        </div>

         <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-wala">{{
            resultObj.summary.PP
          }}</span>
          <p class="mb-0 count-label">Pair Player</p>
        </div>

        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-meron">{{
            resultObj.summary.B
          }}</span>
          <p class="mb-0 count-label">Banker</p>
        </div>
        
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-meron">{{
            resultObj.summary.PB
          }}</span>
          <p class="mb-0 count-label">Pair Banker</p>
        </div>
       
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-draw">{{
            resultObj.summary.T
          }}</span>
          <p class="mb-0 count-label">Tie</p>
        </div>
      </div>
      <div
        class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
        style="position: relative; background-color: #ffffff !important"
      >
        <v-col cols="12" sm="12" style="padding: 5px 0">
          <v-row style="padding: 0; margin: 0">
            <v-col
              cols="6"
              md="6"
              sm="6"
              lg="6"
              style="padding: 0 5px 0 0; margin: 0"
            >
              <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in fixColBac" :key="col">
                        <div>
                          <div
                            v-for="(result, i) in orderBaccaratResult"
                            :key="i"
                          >
                            <span v-if="col == result.col && row == result.row">
                              <v-badge
                                v-if="
                                  result.is_pair_player && result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-badge
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-avatar
                                    :color="result.color"
                                    size="23"
                                    style="
                                      margin: 0px;
                                      color: #ffffff;
                                      font-weight: bold;
                                    "
                                  >
                                    {{
                                      result.name == "BANKER"
                                        ? "B"
                                        : result.name == "PLAYER"
                                        ? "P"
                                        : result.name == "TIE"
                                        ? "T"
                                        : "C"
                                    }}
                                  </v-avatar>
                                </v-badge>
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  result.is_pair_player &&
                                  !result.is_pair_banker
                                "
                                bordered
                                bottom
                                color="blue"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-avatar
                                  :color="result.color"
                                  size="23"
                                  style="
                                    margin: 0px;
                                    color: #ffffff;
                                    font-weight: bold;
                                  "
                                >
                                  {{
                                    result.name == "BANKER"
                                      ? "B"
                                      : result.name == "PLAYER"
                                      ? "P"
                                      : result.name == "TIE"
                                      ? "T"
                                      : "C"
                                  }}
                                </v-avatar>
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  !result.is_pair_player &&
                                  result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-avatar
                                  :color="result.color"
                                  size="23"
                                  style="
                                    margin: 0px;
                                    color: #ffffff;
                                    font-weight: bold;
                                  "
                                >
                                  {{
                                    result.name == "BANKER"
                                      ? "B"
                                      : result.name == "PLAYER"
                                      ? "P"
                                      : result.name == "TIE"
                                      ? "T"
                                      : "C"
                                  }}
                                </v-avatar>
                              </v-badge>
                              <v-avatar
                                v-else
                                :color="result.color"
                                size="23"
                                style="
                                  margin: 0px;
                                  color: #ffffff;
                                  font-weight: bold;
                                "
                              >
                                {{
                                  result.name == "BANKER"
                                    ? "B"
                                    : result.name == "PLAYER"
                                    ? "P"
                                    : result.name == "TIE"
                                    ? "T"
                                    : "C"
                                }}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="6" lg="6" style="padding: 0; margin: 0">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in fixCol" :key="col">
                        <div>
                          <div v-for="(result, i) in orderResult" :key="i">
                            <span v-if="col == result.col && row == result.row">
                              <v-badge
                                v-if="
                                  result.is_pair_player && result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-badge
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-icon
                                    :class="'c' + result.color"
                                    style="padding: 0; margin: 0"
                                    >mdi-circle-outline</v-icon
                                  >
                                </v-badge>
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  result.is_pair_player &&
                                  !result.is_pair_banker
                                "
                                bordered
                                bottom
                                color="blue"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-icon
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  !result.is_pair_player &&
                                  result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-icon
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </v-badge>
                              <span v-else>
                                <v-icon
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </span>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div> 
      </div>
    </div>
  </div>-->


  <v-row no-gutters>
    <v-col cols="12">
      <!--<span style="color: red;">{{ resultObj.results }}</span>-->
      <v-tabs style="color: rgb(255, 0, 0) !important;" fixed-tabs>
        <v-tabs-slider color="#174e87" style="height:3px !important"></v-tabs-slider>
        
        <v-tab class="g-header">
          <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
          <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
          <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
            {{ $t("title.result_small_big") }}
          </span>
        </v-tab>
        <v-tab class="g-header">
          <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
          <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
          <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
            {{ $t("title.result_range") }}
          </span>
        </v-tab>
        <v-tab class="g-header">
          <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
          <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
          <span style="font-family: 'khmer mef1'; color: #212427; font-weight:bold">
            {{ $t("title.odd_even") }}
          </span>
        </v-tab>
        <v-tab-item>
          <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
            style="position: relative; background-color: #ffffff !important;">
            <div class="table-responsive pb-3 gp-scroll">
              <table class="trend-table">
                <tbody>
                  <tr v-for="row in rows" :key="row">
                    <td class="result" v-for="col in cols" :key="col">
                      <div>
                        <div v-for="(result, i) in orderBigSmall" :key="i" class="div-result">
                          <span v-if="col == result.col && row == result.row" class="l-result-icon">
                            <v-btn :color="result.result_small_big.color"  fab dark>
                              <v-icon>mdi-checkbox-blank-circle</v-icon>
                            </v-btn>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-tab-item>
        
        <v-tab-item>
          <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
            style="position: relative; background-color: #ffffff !important;">
            <div class="table-responsive pb-3 gp-scroll">
              <table class="trend-table">
                <tbody>
                  <tr v-for="row in rows" :key="row">
                    <td class="result" v-for="col in cols" :key="col">
                      <div>
                        <div v-for="(result, i) in orderRange" :key="i" class="div-result">
                          <span v-if="col == result.col && row == result.row" class="l-result-icon">
                            <v-btn :color="result.result_range.color"  fab dark class="btn-range">
                              <span class="range-name">
                                {{ result.result_range.name }}
                              </span>
                            </v-btn>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-tab-item>
        
        <v-tab-item>
          <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
            style="position: relative; background-color: #ffffff !important;">
            <div class="table-responsive pb-3 gp-scroll">
              <table class="trend-table">
                <tbody>
                  <tr v-for="row in rows" :key="row">
                    <td class="result" v-for="col in cols" :key="col">
                      <div>
                        <div v-for="(result, i) in orderOddEven" :key="i" class="div-result">
                          <span v-if="col == result.col && row == result.row" class="l-result-icon">
                            <v-btn :color="result.result_odd_even.color"  fab dark class="btn-range">
                              <span class="range-name">
                                {{ result.result_odd_even.name == 'odd' ? 'o' : 'e' }}
                              </span>
                            </v-btn>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-tab-item>

      </v-tabs>
    </v-col>
    

  </v-row>
</template>

<script>

import "@/assets/css/homepage.css";
import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      /*
      fixCol: 30,
      fixColBac: 20,*/

      fixCol: 30,
      fixColBac: 20,
      fix_column: 20,
      rows: 6,
      cols: 30

    };
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      resultObj: "getResult",
      channel: "getChannel",
    }),
    orderBigSmall() {
      return this.getListResults(1);
    },
    orderRange() {
      return this.getListResults(2);
    },
    orderOddEven() {
      return this.getListResults(3);
    },
    /*
    orderResult() {
      return this.getListResults();
    },
    orderBaccaratResult() {
      return this.getBaccaratResults();
    },
    getColor(resultName) {
      if (resultName == "BANKER") {
        return "error";
      } else if (resultName == "PLAYER") {
        return "primary";
      } else if (resultName == "TIE") {
        return "success";
      } else {
        return "default";
      }
    },*/
  },
  methods: {
    getListResults(type) {
      let row = 1;
      let col = 1;
      let newResult = [];
      
      if (this.resultObj.results != null && this.resultObj.results.length > 0) {
        let resultList = this.resultObj.results;
        
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          // console.log()
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (type == 1) {
              if (!(pre.result_small_big.name == item.result_small_big.name)) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 2) {
              if (pre.result_range.name != item.result_range.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 3) {
              if (pre.result_odd_even.name != item.result_odd_even.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 4) {
              if (pre.blue_red.name != item.blue_red.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col
        } else {
          this.fixCol = this.cols
        }
      }
      return newResult;
    },
    /*
    getBaccaratResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj.results !== null) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          // console.log(index)
          const item = resultList[index];
          // console.log(item)
          if (row <= this.rows) {
            // console.log('row <= this.rows')
            // console.log(row)
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            // console.log('else')
            row = 1;
            col += 1;
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
        if (col > this.cols) {
          this.fixColBac = col;
        } else {
          this.fixColBac = this.cols;
        }
      }
      return newResult;
    },*/
    /*
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (
        this.resultObj.results !== null &&
        this.resultObj.results.length > 0
      ) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (pre.name !== item.name) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              if (pre.row >= this.rows) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col;
        } else {
          this.fixCol = this.cols;
        }
      }
      return newResult;
    },*/
  },
};
</script>

<style scoped>

.history-result td {
  text-align: center;
  height: 45px;
  width: 45px;
  padding: 2px;
  background: #f2f6f9;
  border: 1px solid #96afde;
  vertical-align: middle;
  box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
  -moz-box-shadow: 0 0 2px 1px #fff;
  -webkit-box-shadow: 0 0 2px 1px #fff;
  -ms-box-shadow: 0 0 2px 1px #fff;
  -o-box-shadow: 0 0 2px 1px #fff;
}
.v-btn--fab.v-size--default{
  width:36px!important;
  height:36px!important;
}
.center-padding-0 {
  text-align: center;
  padding: 0px !important;
}

.result-table {
  width: 100%;
  margin: 0;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}

.trend-table {
  border-spacing: 0px !important;
}

.trend-table td {
  width: 40px;
  height: 40px;
  font-size: 10px;
  text-align: center;
  padding: 0px;
}

.trend-table tr,
td {
  border: 1px solid #c5c5c5;
}

/* table tbody{
    height: 156px;
} */

td.result>div {
  width: 60px;
}

/* v-tabs{
  background: white !important;
} */
.theme--light.v-tabs>.v-tabs-bar {
  display: none !important;
}

.v-tabs-bar__content {
  display: none !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  background-color: #174e87;
}

.v-tabs:not(.v-tabs--vertical) .v-tab span {
  color: white !important;
}

.v-tab--active {
  background-color: #0a295a !important;
}

.trend-table tbody tr td {
  border: 0;
}

.trend-table tbody tr {
  border: 0;
}

.trend-table tbody tr:nth-child(odd) {
  background-color: #e6f1fd;
}

.trend-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

</style>
