<template>
  <div>
    <nav class="navbar header-navbar pcoded-header px-0 w-100 iscollapsed" header-theme="theme1" pcoded-header-position="fixed">
      <div class="navbar-wrapper w-100 row ma-0">
        <div class="navbar-logo navbar-left pt-1 px-0">
          <v-row class="py-4 w-100" >
            <a  @click="_goToRoute('/cockfight')" class="logo-container">
              <img class="header-logo-img" :src="logoHeader" alt=""/>
            </a>
            <div class="welcome-container">
              <p class="welcome-name pl-3 d-none d-sm-block text-uppercase">
                {{$t('title.welcomeAccount') }} : {{ `${userInfo.user_name ? userInfo.user_name :'loading...'}` }}
               </p>
              <marquee  class="welcome-text d-none d-sm-block">
                {{welcomeText}} 
               </marquee>  
            </div>
          </v-row>
          <a class="mobile-options waves-effect waves-light">
            <v-row class="mr-3">
              <v-menu offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn text   v-bind="attrs" v-on="on">
                    <v-badge v-if="getNotiCount && getNotiCount.count >=0" :content="getNotiCount.count>0 ? getNotiCount.count : '0' ">
                      <v-icon :style="`color:${calculateRingbellStype}`"  style="font-size: 22px">mdi-bell</v-icon>
                    </v-badge>
                  </v-btn>
                </template>
                <template>
                  <div id="scroll-target" class="parent-height">
                    <v-list v-scroll:#scroll-target="onScroll" class="vivo" :style="`height:${height}px;  display:table!important;`">
                      <v-list-item link class="py-0 w-100" style="
                          background: #1f3147;
                          position: fixed;
                          z-index: 200;
                          top: -2px;
                        ">
                        <v-list-item-title class="100%">
                          <span class="pl-3 notify-header noti-row">
                            {{ $t("title.amount") }}
                          </span>
                          <span class="pl-3 notify-header noti-row">
                            {{ $t("title.type") }}
                          </span>
                          <span class="pl-3 notify-header noti-row">
                            {{ $t("title.date") }}
                          </span>
                          <span class="pl-3 notify-header noti-row">
                            {{ $t("title.status") }}
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <div style="margin-top: 40px" />
                      <div v-for="(notification, i) in getNotiData" :key="i" class="pipo">
                        <v-list-item link @click="getDetailNotification(notification._id)">
                          <v-list-item-title class="w-100" style="display: inline">
                            <span class="pl-2 notify-text noti-row">
                              {{ formatMoney(notification.amount) }} <br /> <span class="mt-1" style="line-height:18px">
                                {{ notification && notification.promotion_percentage > 0 ?
                                  '(+' + notification.promotion_percentage + '%)' : '' }}</span>
                            </span>
                            <span class="pl-2 notify-text noti-row" :style="`color:${calculateColorType(
                              notification.type
                            )}`">
                              {{ notification.type }}
                            </span>
                            <span class="pl-2 notify-text noti-row">
                              {{
                                moment(notification.send_date).format(
                                  "YYYY-MM-DD"
                                )
                              }}
                            </span>
                            <span class="pl-2 notify-text noti-row">
                              <v-icon :style="`color:${calculateColorStatus(
                                    notification.status
                                  )}`">
                                {{
                                  calculateIconStatus(notification.status)
                                }}</v-icon>
                              <v-badge v-if="!notification.is_read" style="padding-top: 0px; font-size: 5px"
                                class="read-unread"></v-badge>
                            </span>
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                      <div style="text-align: center; padding: 7px 0px" v-if="loading">
                        <v-progress-circular model-value="20" v-model="loading" color="amber"></v-progress-circular>
                      </div>
                    </v-list>
                  </div>
                </template>
              </v-menu>
              <a href="/money-request" class="ml-2 mr-5">
                <v-icon class="m-icon" color="#0537b4">mdi-cash-multiple</v-icon>
              </a>
              <span class="included" style="margin-top:-5px;">
              <v-icon v-if="!expandMenu" @click="_updateExpand()" style="font-size: 1.5rem; color: #0537b4">mdi-menu</v-icon>
              <v-icon v-if="expandMenu" @click="_updateExpand()" style="font-size: 1.5rem; color: #0537b4">mdi-menu-open</v-icon>
            </span>
            </v-row>
          </a>
        </div>
       <DropdownMenu>
       </DropdownMenu>
       <NavRightMenu></NavRightMenu>
      </div>
      <div class="mobile-user-info">
        <v-row class="m-0">
          <div class="w-100 text-right">{{ $t('title.welcome') + ' ' + userInfo.user_name}} ( {{ _displayCurrencySymbol(userInfo.account_currency) + formatMoney(userInfo.balance) }} )</div>
        </v-row>
        
      </div>
    </nav>

    <v-dialog v-model="dialog" persistent width="450" style="background: #f3f3f3">
      <v-card v-if="getNotiDetail" style="padding: 0px 10px; overflow-x: hidden">
        <v-card-actions style="float: right; margin-right: -20px">
          <v-btn text color="error" block @click="closeDialog"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
        </v-card-actions>
        <v-card-text style="height: auto; width: 100%; background: white">
          <table class="w-100" style="">
            <tbody class="w-100 receipt-table">
              <tr style="margin: auto; display: table">
                <td :style="`color:${calculateColorType(
                  getNotiDetail.type
                )}!important`">
                  <div class="icon-background" :style="`background-color:${calculateColorStatus(
                    getNotiDetail.status
                  )}`">
                    <v-icon style="font-size: 40px!important; color: white">{{
                      calculateIconStatus(getNotiDetail.status)
                    }}</v-icon>
                  </div>
                </td>
              </tr>
              <tr style="text-align: center">
                <td :style="`color:${calculateColorStatus(getNotiDetail.status)}`" class="pt-4">
                  <h6 style="font-weight: 400">
                    <span style="font-weight: 700" :style="`color:${calculateColorType(
                      getNotiDetail.type
                    )}!important`">{{ $t('title.' + getNotiDetail.type) }} :</span>
                    {{ $t('title.' + getNotiDetail.status) }}
                  </h6>
                </td>
              </tr>
              <tr style="text-align: center">
                <td style="color: #212427" class="pt-0">
                  <h4 style="font-weight: 900">
                    {{ $t("title.amount") }}: {{ formatMoney(getNotiDetail.amount) }} {{ getNotiDetail &&
                      getNotiDetail.promotion_percentage > 0 ? '(+' + getNotiDetail.promotion_percentage + '%)' : '' }}
                  </h4>
                </td>
              </tr>
              <tr style="text-align: center" class="w-100">
                <td style="float: left">{{ $t("title.sender") }}</td>
                <td style="float: right">
                  {{ getNotiDetail.sender_username }}
                </td>
              </tr>
              <tr style="text-align: center" class="w-100">
                <td style="float: left">{{ $t("title.reciever") }}</td>
                <td style="float: right">
                  {{ getNotiDetail.reciever_username }}
                </td>
              </tr>
              <tr class="w-100">
                <td style="float: left">{{ $t("title.bank_reciever") }}</td>
                <td style="float: right">
                  {{
                    getNotiDetail && getNotiDetail.bank_reciever
                    ? `${getNotiDetail.bank_reciever.bank.name}(${getNotiDetail.bank_reciever.account_number})`
                    : ""
                  }}
                </td>
              </tr>
              <tr class="w-100">
                <td style="float: left">{{ $t("title.account_name") }}</td>
                <td style="float: right">
                  {{
                    getNotiDetail && getNotiDetail.bank_reciever
                    ? `${getNotiDetail.bank_reciever.account_name}`
                    : ""
                  }}
                </td>
              </tr>
              <tr class="w-100">
                <td style="float: left">{{ $t("title.send_date") }}</td>
                <td style="float: right">
                  {{
                    getNotiDetail && getNotiDetail.send_date
                    ? `${moment(getNotiDetail.send_date).format(
                      "YYYY-MM-DD-hh:mm:ss"
                    )}`
                    : ""
                  }}
                </td>
              </tr>
              <tr class="w-100">
                <td style="float: left">{{ $t("title.receive_date") }}</td>
                <td style="float: right">
                  {{
                    getNotiDetail && getNotiDetail.response_date
                    ? `${moment(getNotiDetail.response_date).format(
                      "YYYY-MM-DD-hh:mm:ss"
                    )}`
                    : "..."
                  }}
                </td>
              </tr>
              <h6 class="pt-3 pb-1 mb-0" style="color: #212427">{{ $t('title.note') }}:</h6>
              <tr class="w-100" style="overflow: hidden">
                <td style="
                    background: #dad5d5;
                    width: 100%;
                    overflow-y: scroll;
                    overflow-x: none;
                    border: 1px solid black;
                  ">
                  <div style="height: 80px; padding: 2px 10px">
                    {{
                      getNotiDetail && getNotiDetail.sender_note
                      ? getNotiDetail.sender_note
                      : ""
                    }}
                  </div>
                </td>
              </tr>
              <!-- <tr v-if="getNotiDetail.status == 'PENDING'" class="mt-4 px-5">  
                <td style="float:left">  
                    <v-btn class="mt-4" color="error" @click="removeRequest(getNotiDetail)">
                        <v-icon class="pt-1 pr-2">mdi-close-circle-outline</v-icon> {{ $t("button.delete") }}
                     </v-btn>
               </td>
                <td style="float:right">
                    <v-btn class="mt-4 pr-8" color="primary" @click="editRequest(getNotiDetail)">
                        <v-icon class="pt-1 pr-2" >mdi-pencil</v-icon> {{ $t("button.edit") }}
               </v-btn>
               </td>
             
            </tr> -->
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" persistent width="450" style="background: #f3f3f3 !important">
      <v-card v-if="getNotiDetail" style="padding: 0px 10px; overflow-x: hidden; background: #1f3147">
        <v-card-actions style="float: right; margin-right: -20px">
          <v-btn text color="error" block @click="closeEditDialog"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
        </v-card-actions>
        <v-card-text style="height: auto; width: 100%; background: none !important">
          <table class="w-100" style="">
            <tbody class="w-100 receipt-table">
              <tr style="margin: auto; display: table">
                <td :style="`color:${calculateColorStatus(getNotiDetail.status)}`">
                  <div class="icon-background" :style="`background-color:${calculateColorStatus(
                    getNotiDetail.status
                  )}`">
                    <v-icon style="font-size: 40px!important; color: white">{{
                      calculateIconStatus(getNotiDetail.status)
                    }}</v-icon>
                  </div>
                </td>
              </tr>
              <tr style="text-align: center">
                <td :style="`color:${calculateColorStatus(getNotiDetail.status)}`" class="pt-4">
                  <h6 style="font-weight: 400">
                    <span style="font-weight: 800; color: darkred">{{
                      getNotiDetail.type
                    }}</span>
                  </h6>
                </td>
              </tr>
              <tr style="text-align: center">
                <input type="text" id="name" name="name" required minlength="4" maxlength="8" size="10" />
                <v-text-field v-model.number="editAmount" :label="`Amount (${userInfo.currencySymbol})`" outlined
                  type="number" required :rules="[numberRule]" class="input-field-style">
                </v-text-field>
              </tr>
              <tr class="w-100">
                <td style="margin: auto; display: table">
                  <v-btn class="mt-4 pr-8" color="green" style="color: white" @click="updateRequest(getNotiDetail)">
                    {{ $t("button.update") }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import dashboardFunc from "~mixin/MX_ShareFunctionCollection.js"
// import TabGames from "./TabGames.vue"
import DropdownMenu from "./DropdownMenu.vue"
import NavRightMenu from "./NavRightMenu.vue"
import "@/assets/css/layout/layout_share_style.css";
import "@/assets/css/layout/screen_max_480.css";
import "@/assets/css/layout/screen_min_481.css";
import "@/assets/css/layout/screen_min_768.css";
import "@/assets/css/layout/screen_min_993.css";
import "@/assets/css/layout/screen_min_1025.css";
import "@/assets/css/layout/screen_min_1281.css";
import "@/assets/css/layout/screen_min_1441.css";
import "@/assets/css/layout/screen_min_1661.css";
import "@/assets/css/layout/screen_min_1824.css";



export default {
  dashboardFunc: [dashboardFunc],
  components: {
    // TabGames,
    DropdownMenu,
    NavRightMenu
  },
  data: () => ({
    data: {
      rows_per_page: 6,
      page: 1,
    },
    posts: {},
    bottom: false,
    beers: [],
    editAmount: 0,
    scrollTop: 0,
    offsetTop: {},
    height: 600,
    lading: false,
    numberRule: (amount) => {
      if (amount < 0) return "Please enter a positive number";
      return true;
    },
  }),
  computed: {
    welcomeText() {
            switch(this.userInfo.currency_type){
              case 1: return this.getPromoText.title_en;
              case 2: return this.getPromoText.title_kh;
              case 4: return this.getPromoText.title_vn;
              case 5: return this.getPromoText.title_thai;
              default: return 'Welcome ....'
            }
        },
    logoHeader(){
      return require('../../../public/images/logo_background/Logo-header.png')
    },
    calculateRingbellStype() {
      if (this.getNotiCount.count > 0) return "red";
      else return "grey";
    },

    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      userNotList: "getNotList",
      loading: "getLoading",
      getNotiCount: "getNotiCount",
      dialog: "getDialog",
      editDialog: "getEditDialog",
      getNotiDetail: "getNotiDetail",
      getNotiData: "getNotiData",
      expandMenu: "getExpandMenu",
      getPromoText:"getPromoText"
    }),
  },

  watch: {
    lang() { },
    height() { },
    bottom(bottom) {
      if (bottom) {
        this.addBeer();
      }
    },
  },

  methods: {

    _updateExpand(){
      this.UPDATE_EXPAND_MENU(this.expandMenu);
    },
    _goToRoute(path) {
      var selectedChannel = 1;
      if (path == '/cockfight') {
        selectedChannel = 1;
        this.$cookies.set("sbc_defaut_main_menu", 'cock');
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } 
      else{
        return ''
      }
      this.UPDATE_EXPAND_MENU(true);
        this.$router.push({ path }).catch(() => {
        /* ignore */
      });
    },
    changeLang(lang) {
      if (lang == "khmer") this.lang = "flag-kh.png";
      else this.lang = "flag-en.webp";
      this.$i18n.locale = lang;
      this.$cookies.set("local", lang);
    },
    calculateColorType(type) {
      if (type == "DEPOSIT") return "#f34141";
      if (type == "WITHDRAW") return "#167df1";
    },
    moment,
    changeHeight(a) {
      if (a) this.height = 600;
      else this.height = this.getNotiData.length * 52;
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
      this.bottom = this.bottomVisible(e);
    },
    bottomVisible(e) {
      const scrollY = e.target.scrollTop;
      const pageHeight = 150;
      const bottomOfPage = scrollY >= pageHeight;
      return bottomOfPage || pageHeight < scrollY;
    },
    async addBeer() {
      this.data = {
        rows_per_page: 6,
        page: this.data.page + 1,
      };
      await this.fetchNotList(this.data).then(() => {
        if (
          this.userNotList &&
          this.userNotList.reports &&
          this.userNotList.reports.length != 0
        ) {
          this.getNotiData.push(...this.userNotList.reports);
          this.changeHeight();
          if (this.bottomVisible()) {
            this.addBeer();
          }
        } else {
          this.data = {
            rows_per_page: 6,
            page: this.data.page - 1,
          };
          this.height = 600;
        }
      });
    },
    _detectLanguage(lang) {
      try{
        return require(`../../assets/images/${lang}`)
      }catch(e){
        // console.log(e)
        return {}
      }
   
    },
    onLogout() {
      if (this.$socket) {
        this.$socket.removeAllListeners();
        this.$socket.disconnect();
      }
      this.$cookies.remove("sbc_channel_type");
      this.$cookies.remove("sbc_token");
      this.$router.push("/login");
    },
      
    calculateIconStatus(status) {
      switch (status) {
        case "SUCCESS":
          return "mdi-check-circle-outline";
        case "PENDING":
          return "mdi-alert-circle-outline";
        case "REJECT":
          return "mdi-cancel";
        default:
          return "";
      }
    },
    calculateColorStatus(status) {
      switch (status) {
        case "SUCCESS":
          return "green";
        case "PENDING":
          return "#633e2c";
        case "REJECT":
          return "red";
        default:
          return "";
      }
    },
    async getDetailNotification(postId) {
      let data = {
        req_id: postId,
      };
      await this.fetchNotiDetail(data);
    },
    async removeRequest(item) {
      if (item.type == "DEPOSIT") {
        let data = {
          req_id: item._id,
        };
        let res = await this.removeDeposit({},{ vm: this, data: data });
        if (res == true)
          this.data = {
            rows_per_page: 6,
            page: 1,
          };
      }
      if (item.type == "WITHDRAW") {
        let data = {
          req_id: item._id,
        };
        let res = await this.removeWithdraw({},{ vm: this, data: data });
        if (res == true)
          this.data = {
            rows_per_page: 6,
            page: 1,
          };
      }
    },
    closeDialog() {
      this.DIALOG(false);
    },
    async updateRequest(item) {
      if (this.editAmount <= 0) {
        this.$toastr.e("Please enter deposit amount!");
      } else {
        if (item.type == "DEPOSIT") {
          try {
            let data = {
              req_id: item._id,
              agent_account_id: item.bank_reciever._id,
              amount: this.editAmount,
              note: "",
            };
            // this.loading = true;
            const response = await this.updateDeposit({ vm: this, data: data });
            if (response.data.code) {
              this.$toastr.s("Your deposit request have been update!");
              // this.loading = false;
              this.UPDATE_AMOUNT_NOTI_DETAIL(this.editAmount);
              this.closeEditDialog();
            } else {
              this.$toastr.e(response.data.message.descriptions);
              this.error = true;
            }
          } catch (error) {
            this.error = true;
            return error;
          } finally {
            // this.loading = false;
          }
        }
        if (item.type == "WITHDRAW") {
          try {
            let data = {
              req_id: item._id,
              amount: this.editAmount,
              note: "",
            };
            // this.loading = true;
            const response = await this.updateWithdraw({ vm: this, data: data });
            if (response.data.code) {
              this.$toastr.s("Your deposit withdraw have been update!");
              // this.loading = false;
              this.UPDATE_AMOUNT_NOTI_DETAIL(this.editAmount);
              this.closeEditDialog();
              this.closeEditDialog();
            } else {
              this.$toastr.e(response.data.message.descriptions);
              this.error = true;
            }
          } catch (error) {
            this.error = true;
            return error;
          } finally {
            // this.loading = false;
          }
        }
      }
    },
    async editRequest(notification) {
      this.EDIT_DIALOG(true);
      this.editAmount = notification.amount;
    },

    closeEditDialog() {
      this.EDIT_DIALOG(false);
    },
    ...mapActions("$_modules", [

      "fetchNotList",
      "fetchNotCount",
      "fetchNotiDetail",
      "removeDeposit",
      "removeWithdraw",
      "refreshNotiList",
      "updateDeposit",
      "updateWithdraw",
    ]),
    ...mapMutations("$_modules", [
      "DIALOG",
      "EDIT_DIALOG",
      "UPDATE_NOTI_DATA",
      "UPDATE_AMOUNT_NOTI_DETAIL",
      "UPDATE_CHANNEL_ID",
      "UPDATE_EXPAND_MENU"
    ]),
  },
};
</script>
