<template>
    <div class="login-menu-container">
        <div class="row ma-0">
            <div v-for="i in loginMenu.menu" :key="i.id" class="login-menu-ele-container " :style="`width: calc(100%/${loginMenu.number_active_menu})`"
                @click="_goToLoginMenu(i)">
                <img :class="i.id == getSelectedLoginMenu ? 'active-img' : ''" :src="_loginIndexToMenu(i.id)" alt="" class="login-menu-game">
                <div :class="i.id == getSelectedLoginMenu ? 'active' : ''" v-if="i.display_status_enable" class="menu-name">{{ i.menu_name }}</div>
                <div v-else class="login-hide-menu"></div>
            </div>
    </div>
    </div>
</template> 
  
<script>
import {  mapActions, mapGetters } from "vuex";
export default {
    
    props: ['loginMenu'],

    computed: {
        ...mapGetters("$_auth", {
            getSelectedLoginMenu: "getSelectedLoginMenu",
        })
    },
    methods:{
        _goToLoginMenu(menu){
            this.$cookies.set("selected_login_menu", menu.id)
            this.updateSelectedLoginMenu(menu);
        },
        _loginIndexToMenu(i) {
            switch (i) {
                case 1: return require("../../../../../public/images/a_page_access_global/game-logo/top_icon.png");
                case 2: return require("../../../../../public/images/a_page_access_global/game-logo/cockfight-logo.png");
                case 3: return require("../../../../../public/images/a_page_access_global/game-logo/poker-logo.png");
                case 4: return require("../../../../../public/images/a_page_access_global/game-logo/machine-game-logo.png");
                case 5: return require("../../../../../public/images/a_page_access_global/game-logo/w99_lottery_icon.png");
                case 6: return require("../../../../../public/images/a_page_access_global/game-logo/Sport icon.png");
                default: return require("../../../../../public/images/a_page_access_global/game-logo/w99_lottery_icon.png");
            }
        },
        ...mapActions("$_auth", ["updateSelectedLoginMenu"]),
    }
}
</script>
