export default {
  methods: {
    listenerSocket() {
        this.$socket.on("client-update-channel-info", (data) => {
          if (data) {
            if (data.is_open) {
              // this.playSound('place_your_bet_please_3.m4a')
            }
            this.updateChannel(data);
          }
        });
        this.$socket.on("client-status-channel-type", (data) => {
          if (data) {
            // console.log(`socket on client-status-channel-type: ${JSON.stringify(data)}`);
            this.UPDATE_CHANNEL_STATUS(data);
          }
        });
        this.$socket.on("client-defind-result-and-new-channel", async (data) => {
          // console.log(`socket on client-defind-result-and-new-channel: ${JSON.stringify(data)}`);
          if (data) {
            this.requestApi = this._calculateRequestApiKeyword(this.channelId);
            await this.fetchTodayBetting({ data: {}, base_url: this.requestApi });
            await this.updateResult(data);
          }
        });
        this.$socket.on("client-update-user-info", (data) => {
          if (data) {
            if (data.is_suspend) {
              this.$toastr.e(`Your account have been blooked!`);
              this.onLogout();
            }
            this.updateBalance(data.balance);
          }
        });
        this.$socket.on("client-update-betting-amount", (data) => {
          // console.log(`socket on client-update-betting-amount: ${JSON.stringify(data)}`);
          if (data) {
            if (this.channel.is_open) {
              this.fetchCurrentBetting();
              let currentcy = this.userInfor.currency_type - 1;
              this.UPDATE_AMOUNT(data[currentcy]);
            }
          }
        });
        this.$socket.on("client-update-payout", (data) => {
          // console.log(`socket on client-update-payout: ${JSON.stringify(data)}`);
          if (data) {
            // console.log(`socket on client-update-payout type: ${this.userInfor.currency_type} :type:${data.currency_type}`);
            if (this.channel.is_open || this.channel.is_open == null) {
              if (this.userInfor.currency_type == data.currency_type) {
                this.UPDATE_PAYOUT(data);
              }
            }
          }
        });
        this.$socket.on("client-reserves-result", (data) => {
          // console.log(`socket on client-reserves-result: ${JSON.stringify(data)}`);
          if (data) {
            this.UPDATE_TODAY_BETTING(data);
          }
        });
        this.$socket.on("client-reset-amount", (data) => {
          // console.log(`socket on client-reset-amount: ${JSON.stringify(data)}`);
          if (data) {
            this.RESET_AMOUNT();
            if (this.userInfor.currency_type == 1) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_usd);
            } else if (this.userInfor.currency_type == 2) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_kh);
            } else if (this.userInfor.currency_type == 3) {
              this.UPDATE_AMOUNT_MAX(data.amount_max_tb);
            }
          }
        });
        // this.$socket.on("client-notification", (data) => {
        //   if (data) {
        //     this.getNotification();
        //   }
        // });
        this.$socket.on("client-time", (data) => {
          if (data) {
            // define client time to display count down time at play zone
            this.UPDATE_CLIENT_TIME(data);
            this.UPDATE_FIRST_CARD(null);
            this.UPDATE_SECOND_CARD(null);
            this.CLEAR_PLAYER_CARD()
            // play audio sound
            if (data.time == 118) {
              this._playSoundBetDown();
            }
            if (data.time < 11 && data.time != 0) this._playSoundTing();
            if (data.time == 0) this._playSoundGoodLuck();
          }
        });
        this.$socket.on("client-new-game", async (data) => {
          if (data) {
            this.CLEAR_PLAYER_CARD();
            this.clientRandom = [];
            this.bonus = [];
            await this.fetchCurrentBetting({
              data: {},
              base_url: this.requestApi,
            });
            await this._initialData();
            this.fetchUserInfo();
          }
        });
        this.$socket.on("clear-start", async (data) => {
          if (data) {
            this.APPEND_KLAKLOUK_CLEAR_START(data);
            this.SEND_BACK_CARD(true);
            this.UPDATE_CLIENT_TIME(null);
            this.CLEAR_PLAYER_CARD();
          }
        });
        // get game result and bonus
        this.$socket.on("client-result", (data) => {
          if (data) {
            this.SEND_BACK_CARD(false)
            // console.log(data);
            this.UPDATE_CURRENT_GAME_RESULT(data);
            this.clientResult = data;
            if(this.channelId > 92) this.APPEND_KLAKLOUK_RESULT(data);
          }
        });
        this.$socket.on("client-bonus", (data) => {
          if (data) {
            this.bonus = data;
            if (this.bonus.is_bonus) {
              this._playSoundCongratulation();
            }
          }
        });
        this.$socket.on("client-random", (data) => {
          if (data) {
            this.CLIENT_RANDOM(data);
          }
        });
        // Get tiger card
        this.$socket.on("tiger-card", (data) => {
          if (data) {
            this.UPDATE_TIGER_CARD(data);
          }
        });
        this.$socket.on("dragon-card", (data) => {
          if (data) {
            
            this.UPDATE_DRAGON_CARD(data);
          }
        });
  
        this.$socket.on("client-scan-card", (data) => {
          if (data) {
            this.UPDATE_BACCARAT_CARD(data);
          }
        });
  
        this.$socket.on("first-card", (data) => {
          if (data) {
            
            this.UPDATE_FIRST_CARD(data);
          }
        });
  
        this.$socket.on("second-card", (data) => {
          if (data) {
            this.UPDATE_SECOND_CARD(data);
          }
        });
        this.$socket.on("clear-burn-card", (data) => {
          if (data) {
            this.CLEAR_PLAYER_CARD()
          }
        });
        this.$socket.on("client-card", (data) => {
          // console.log(`socket on client-update-betting-amount: ${JSON.stringify(data)}`);
          if (data) {
              this.UPDATE_CLIENT_CARD(data);
          }
        });
      },
    }
}