<template>
    <v-col cols="12 px-1" class="pb-0">
        <div class="curtain">
            <div class="curtain__wrapper">
                <input type="checkbox"
                disabled="disabled" 
                    :checked="passClientRandom.randmon_time == null">
                <div class="curtain__panel curtain__panel--left">
                </div>
                <div class="curtain__prize">
                    <div class="d-result-style">
                        <img src="../../../../public/images/a_each_game/newYuki/Rectangle.png" class="rotate backdrop-result" />
                        <img v-if="passClientResult" class="result-img-blink d-result-animal d-result-animal-shadow " :src="findImageOfEachAnimal(passClientResult.between_year.year_number)
                            " :lazy-src="findImageOfEachAnimal(passClientResult.between_year.year_number)" />
                        <img v-if="passRandomNumber && !passClientResult" class=" d-result-animal"
                            :src="findImageOfEachAnimal(passRandomNumber)"
                            :lazy-src="findImageOfEachAnimal(passRandomNumber)" />
                        <img src="../../../../public/images/a_each_game/newYuki/curtain_open.png" class="d-result-curtain-open" />
                    </div>
                </div>
                <div class="curtain__panel curtain__panel--right">
                </div>
            </div>
        </div>
    </v-col>
</template>
  
  
<script>

import { mapGetters } from "vuex";
import Mixin from "~mixin/MX_FindImageFunctionCollection.js";

export default {
    mixins: [Mixin],
    components: {
        // Tabs,
    },
    props: [
        "passClientTime", "passClientResult", "passClientRandom", "passRandomNumber"
    ],
    data() {
        return {
        
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_machine_yuki", {
            currentGame: "getCurrentGame",
            getLimit: "getLimit"
        }),
    },
    methods: {
    }
};
</script>
<style>
.d-result-animal-shadow {
    filter: drop-shadow(-1px 2px 20px #f9881e);
}</style>