<template>
    <div class="w-100">
      <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }} </h5>
      <div class="game-machine-component-result">
        <div class="trends limit-on-tab"  style="position: relative;">
          <div v-if="scanResult && scanResult.name" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center; background-color:rgba(40, 90, 226, .83)">
            <div class="h-100">
              <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
                <tr style="height: 38px;">
                  <th style="color: #ffffff; background-color:rgba(40, 90, 226, .83)">
                    <b>Result</b>
                  </th>
                </tr>
                <tr>
                  <td>
                    <v-img :src='findImgApongButton(Number(scanResult.name))' width="48" style=" text-align: center; margin-right: auto; margin-left: auto;height: 48px;"></v-img>
                  </td>
                </tr>
              </table>
            </div>
          </div>
            <div>
              <!-- <div class="trend-legend-custome  gp-panel-bg  d-flex  justify-content-between align-items-center"
                  style="background-color: #ffffff !important;">
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-meron">{{ resultObj.summary.one }}</span>
                  <p class="mb-0 count-label">1</p>
                </div>
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                    class="count rounded-circle trend-black">{{ resultObj.summary.two }}</span>
                  <p class="mb-0 count-label">2</p>
                </div>
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                    class="count rounded-circle trend-black">{{ resultObj.summary.three }}</span>
                  <p class="mb-0 count-label">3</p>
                </div>
                <div class="bad-cont d-inline-flex align-items-center "><span
                    class="count rounded-circle trend-meron">{{ resultObj.summary.four }}</span>
                  <p class="mb-0 count-label">4</p>
                </div>
                <div class="bad-cont d-inline-flex align-items-center "><span
                    class="count rounded-circle trend-black">{{ resultObj.summary.five }}</span>
                  <p class="mb-0 count-label">5</p>
                </div>
                <div class="bad-cont d-inline-flex align-items-center "><span
                    class="count rounded-circle trend-black">{{ resultObj.summary.six }}</span>
                  <p class="mb-0 count-label">6</p>
                </div>
              </div> -->
            <div class="px-3 pb-4 gp-panel-bg landscape-trend"
                style="position: relative; background-color: #ffffff !important; padding: 0 !important;">
                <v-col cols="12" sm="12" style="padding: 0">
                    <v-row style="padding: 0; margin: 0">
                        <v-col
                        cols="6"
                        md="6"
                        sm="6"
                        lg="6"
                        style="padding: 0; margin: 0">
                            <VideoLive :passurl="passUrl" :channelType="channelType" />
                        </v-col>
                        <v-col cols="6" md="6" sm="6" lg="6" style="padding:  10px 0 0 0; margin: 0">
                            <div class="table-responsive gp-scroll">
                                <table class="machine-table" >
                                    <tbody>
                                      <tr v-for="row in rows" :key="row">
                                          <td v-for="col in (channel.fix_column + 9)" :key="col">
                                            <div>
                                              <div v-for="(result, i) in orderResult" :key="i">
                                                  <span v-if="col == result.col && row == result.row">
                                                      <v-avatar  :color="calColor(result.color)" class="machine-list-apong-result-ball">
                                                          <span >
                                                              {{result.name}} 
                                                          </span>
                                                      </v-avatar>
                                                  </span>
                                              </div>
                                            </div>
                                          </td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
            </div>
        </div>
    </div>
    <!-- <div class="game-machine-component-reserve-space"></div> -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import findImg from "../../../../../../mixin/MX_FindImageFunctionCollection.js"

import VideoLive from "../GameResult/VideoLive.vue";
export default {
  mixins: [findImg],
  components: {
    VideoLive
  },
  props:['passUrl', 'passIndex', 'channelType', 'resultObjS', 'passChDesc', 'scanResult'],
  data() {
    return {
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      isShow: false,
      resultObj: {
        results:[]
      },
      
    }
  },
  watch: {
    resultObjS: function (newVal) {
      if(newVal.objCoResult){
        this.isShow = true;
        this.resultObj.results = newVal.objCoResult
      }
    }
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
    }),
    orderResult() {
            return this.getListResults();
        },
    getColor(resultName) {
      if (resultName == "BANKER") {
        return "error";
      } else if (resultName == "PLAYER") {
        return "primary";
      } else if (resultName == "TIE") {
        return "success";
      } else {
        return "default";
      }
    },

  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  methods: {
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        // console.log(`getResult2 >>>> ${response}`)
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    getListResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.resultObj.results !== null && this.resultObj.results.length > 0){
            let resultList = this.resultObj.results
            for (var index = resultList.length - 1; index >= 0; index--) {
                const item = resultList[index];
                if(index == resultList.length - 1){
                    newResult.push({
                        ...item,
                        row,
                        col,
                    });
                }else{
                    const pre = newResult[newResult.length - 1];
                    if(pre.name !== item.name){
                        newResult.push({ 
                        ...item,
                        row: 1,
                        col: pre.col + 1,
                        });
                    }else{
                        if (pre.row >= 6) {
                            newResult.push({
                                ...item,
                                row: 1,
                                col: pre.col + 1,
                            });
                        } else {
                            newResult.push({
                                ...item,
                                row: pre.row + 1,
                                col: pre.col,
                            });
                        }
                    }
                }
            }
        }
      // console.log(newResult)
      return newResult;
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult"]),
  }
}
</script>
<style>
  /* .v-icon.v-icon{
    font-size: 18px !important
  } */
  div.v-avatar{
    font-size: 8px !important;
  }
  .v-application .pb-3{
    padding-bottom: 2px !important;
  }
</style>