<template>
  <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0 pt-0">

    <div class="g-summary">

      <div class="ri-sicbo-betting-pd">

        <div class="ri-sicbo-count-down" v-if="getClientTime">
          <span v-if="getClientTime.is_open_betting==true">{{ getClientTime.time }}</span>
          <marquee v-else-if="getClientTime.is_open_betting==false">
            <span class="sicbo-span-close">{{ $t('title.gameIsClose') }}</span>
          </marquee>
          <marquee v-else>
            <span class="sicbo-span-close">{{ $t('title.gameIsClose') }}</span>
          </marquee>
        </div>
        <div class="ri-sicbo-betting-row">
          <!--  ri-sicbo-betting-status  -->
          <div class="ri-sicbo-betting-col ri-xcol-r1-c1 ri-x-green " v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[0]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[0]._id, passBettingTypeMaster[0].name)">
            <!--<div><span>{{ passBettingTypeMaster[0].name }}</span></div>-->
            <div><span>{{ $t('title.sicbo_small') }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r1-c2" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[4]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[4]._id, passBettingTypeMaster[4].name)">
            <div><span>{{ passBettingTypeMaster[4].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r1-c3" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[5]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[5]._id, passBettingTypeMaster[5].name)">
            <div><span>{{ passBettingTypeMaster[5].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r1-c4" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[12]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[12]._id, passBettingTypeMaster[12].name)">
            <div><span>{{ passBettingTypeMaster[12].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r1-c5" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[13]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[13]._id, passBettingTypeMaster[13].name)">
            <div><span>{{ passBettingTypeMaster[13].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r1-c6 ri-x-red" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[1]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[1]._id, passBettingTypeMaster[1].name)">
            <!--<div> <span>{{ passBettingTypeMaster[1].name }}</span></div>-->
            <div> <span>{{ $t('title.sicbo_large') }}</span></div>
            
          </div>

          <div class="ri-sicbo-betting-col ri-xcol-r2-c1" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[6]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[6]._id, passBettingTypeMaster[6].name)">
            <div><span>{{ passBettingTypeMaster[6].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r2-c2" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[7]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[7]._id, passBettingTypeMaster[7].name)">
            <div><span>{{ passBettingTypeMaster[7].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r2-c3" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[14]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[14]._id, passBettingTypeMaster[14].name)">
            <div><span>{{ passBettingTypeMaster[14].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r2-c4" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[15]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[15]._id, passBettingTypeMaster[15].name)">
            <div><span>{{ passBettingTypeMaster[15].name }}</span></div>
          </div>

          <div class="ri-sicbo-betting-col ri-xcol-r3-c1 ri-x-green" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[2]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[2]._id, passBettingTypeMaster[2].name)">
            <!--div><span>{{ passBettingTypeMaster[2].name }}</span></div>-->
            <div><span>{{ $t('title.sicbo_odd') }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r3-c2" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[8]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[8]._id, passBettingTypeMaster[8].name)">
            <div><span>{{ passBettingTypeMaster[8].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r3-c3" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[9]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[9]._id, passBettingTypeMaster[9].name)">
            <div><span>{{ passBettingTypeMaster[9].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r3-c4" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[16]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[16]._id, passBettingTypeMaster[16].name)">
            <div><span>{{ passBettingTypeMaster[16].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r3-c5" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[17]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[17]._id, passBettingTypeMaster[17].name)">
            <div><span>{{ passBettingTypeMaster[17].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r3-c6 ri-x-red" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[3]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[3]._id, passBettingTypeMaster[3].name)">
            <div><span>{{ $t('title.sicbo_even') }}</span></div>
          </div>

          <div class="ri-sicbo-betting-col ri-xcol-r4-c1" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[10]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[10]._id, passBettingTypeMaster[10].name)">
            <div><span>{{ passBettingTypeMaster[10].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r4-c2" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[11]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[11]._id, passBettingTypeMaster[11].name)">
            <div><span>{{ passBettingTypeMaster[11].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r4-c3" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[18]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[18]._id, passBettingTypeMaster[18].name)">
            <div><span>{{ passBettingTypeMaster[18].name }}</span></div>
          </div>
          <div class="ri-sicbo-betting-col ri-xcol-r4-c4" v-if="passBettingTypeMaster && passBettingTypeMaster[0]"
            :class="getSicboBetting.bettingid == passBettingTypeMaster[19]._id ? 'ri-sicbo-betting-status' : ''"
            @click="__xbetting(passBettingTypeMaster[19]._id, passBettingTypeMaster[19].name)">
            <div><span>{{ passBettingTypeMaster[19].name }}</span></div>
          </div>


        </div>

      </div>

    </div>
    <!--
    <CoinButton :passGetButton="getButton" :passChannel="passChannel" :passPlaceCoin="getBettingAmount"
     class="mt-2" />

    <SubmitBtn :passCheckAllowSubmit="_checkAllowSubmit" v-on:handleSubmitBtn="_submitBetting"
      v-on:handleClearBtn="_clearMoney" class="mb-2" />
    -->


    <div class="text-center w-100 display-money mb-5">
      {{
        userInfo && userInfo.currency_type == 2
        ? formatMoney(getBettingAmount)
        : formatMoney(getBettingAmount)
      }}
    </div>
    <div class="justify-content-center row text-center mx-2 my-6 px-0">
      <div class="row ma-0 w-100 mx-auto text-center">
        <div class="row coin-container ma-0 px-0 gp-scroll" v-if="getButton">
          <div v-for="(button, index) in getButton" :key="button._id" class="ma-0 p-0 col-2">
            <div @click="_inputAmount(button.amount)">
              <img :src="_detectCoin(index)" class="btn-coin text-center" />
              <div text :style="`color:${_detectColor(index)};`" class="text btn-bet-circle prevent-select">
                {{ button.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="mx-auto row w-100 justify-center pb-3 mb-4">
      <div class="mr-3">
        <v-btn @click="_clearMoney" class="btn btn-secondary mx-0 row mt-0 btn-clear-money" :disabled="loading"
          style="height: 40px !important">
          <v-icon style="margin-right: 4px" color="white" size="17px">mdi-close-circle-outline</v-icon>
          <span style="margin-top: -2px">
            {{ $t("title.cancel") }}
          </span>
        </v-btn>
      </div>
      <div class="ml-3">
        <v-btn @click="_submitBetting" class="btn btn-secondary row mx-0 mt-0 btn-submit-money"
          :disabled="__xcheckallowsubmit()" style="height: 40px !important">
          <v-icon v-if="!loading" style="margin-right: 4px" color="white" size="17px">mdi-check-circle-outline</v-icon>
          <v-icon v-if="loading"> mdi-refresh mdi-spin </v-icon>
          <span style="margin-top: -2px">
            {{ $t("title.confirm") }}
          </span>
        </v-btn>
      </div>
    </div>



    <BaccaratMachineReportBettingComponent :passLoadingTodayReport="passLoadingTodayReport" />
    <v-row class="mx-0 justify-center w-100 mt-2">
      <v-col col-12 style="padding: 0">
        <div class="show-on-mobile pt-5">
          <TableResult :rows="6" :cols="35" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import "@/assets/css/homepage.css";
/*
import CoinButton from "~global_components/CoinBettingButton.vue";
import SubmitBtn from "~global_components/SubmitBtn.vue";
*/
import TableResult from "../../TableResults.vue";
import BaccaratMachineReportBettingComponent from "../../_ReportBettingComponent/SicBoMachineReportBettingComponent.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    BaccaratMachineReportBettingComponent,
    /* CoinButton,
     SubmitBtn,*/
    TableResult,
  },
  props: [
    "passChannelStatus",
    "passBettingTypeMaster",
    "passWeight",
    "passSelectedChannel",
    "passUserCurrency",
    "passLoadingTodayReport",
  ],
  data() {
    return {
      selectedButton: {},
      selectedBtnClassMeron: false,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedBtnClassPairBanker: false,
      selectedBtnClassPairPlayer: false,
      csBanker1: "",
      csBanker2: "",
      csBanker3: "",
      csPlayer1: "",
      csPlayer2: "",
      csPlayer3: "",
      totalP: 0,
      totalB: 0,
      selectedCoin: false,
    };
  },
  watch: {},
  computed: {
    meronButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[0];
      else return {};
    },
    walaButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[1];
      else return {};
    },
    tieButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[2];
      else return {};
    },
    pairBankerButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[3];
      else return {};
    },
    pairPlayerButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[4];
      else return {};
    },
    _checkAllowSubmit() {
      if (
        this.getBettingAmount > 0 &&
        (this.selectedBtnClassMeron ||
          this.selectedBtnClassTie ||
          this.selectedBtnClassWala ||
          this.selectedBtnClassPairBanker ||
          this.selectedBtnClassPairPlayer)
      )
        return true;
      else return false;
    },
    ...mapGetters("$_dashboard_live", {
      dialogBetting: "getDialogBetting",
      getButton: "getButton",
      amountMin: "getAmountMin",
      amountMx: "getAmountMax",
      getCountdown: "getCountdown",
      passChannel: "getChannel",
      passCurrentBetting: "getCurrentBetting",
      getClientTime: "getClientTime",
      loading: "getLoading",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
      getSicboBetting: 'getBettingSicboAmount',

    }),
  },

  methods: {
    ...mapMutations("$_modules", [
      "UPDATE_BETTING_SICBO_AMOUNT",
      "UPDATE_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT",
      "CLEAR_BETTING_SICBO_AMOUNT",
    ]),
    __xbetting(id, name) {
      this.UPDATE_BETTING_SICBO_AMOUNT([name, this.getBettingAmount, id]);
    },
    __xcheckallowsubmit() {
      if (this.getSicboBetting.bettingid != '' && this.getSicboBetting.amount > 0) {
        return false;
      } else {
        return true;
      }
    },

    _inputAmount(money) {
      this.UPDATE_BETTING_AMOUNT(money);
      //this._playSoundInsertCoin()
      this.UPDATE_BETTING_SICBO_AMOUNT([this.getSicboBetting.bettingname, this.getBettingAmount, this.getSicboBetting.bettingid])
      this.__xcheckallowsubmit();
    },

    formatBtnCurrencyKh(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "ព";
        case amount >= 10000 && amount <= 999999:
          return amount / 10000 + "ម";
        case amount >= 1000000 && amount <= 99999999999:
          return amount / 1000000 + "ល";
        default:
          return amount;
      }
    },
    formatBtnCurrency(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "K";
        default:
          return amount;
      }
    },

    _detectCoin(index) {
      switch (index) {
        case 0:
          return require('~public_image/a_game_access_global/coins/1.png');
        case 1:
          return require('~public_image/a_game_access_global/coins/2.png');
        case 2:
          return require('~public_image/a_game_access_global/coins/3.png');
        case 3:
          return require('~public_image/a_game_access_global/coins/4.png');
        case 4:
          return require('~public_image/a_game_access_global/coins/5.png');
        case 5:
          return require('~public_image/a_game_access_global/coins/6.png');
        case 6:
          return require('~public_image/a_game_access_global/coins/7.png');
        case 7:
          return require('~public_image/a_game_access_global/coins/8.png');
        case 33:
          return require('~public_image/a_game_access_global/coins/1.png');
        default:
          return ''
      }
    },
    _detectColor(index) {
      switch (index) {
        case 0:
          return 'black';
        case 1:
          return 'black';
        case 2:
          return 'black';
        case 3:
          return 'black';
        case 4:
          return 'black';
        case 5:
          return 'black';
        case 6:
          return 'black';
        case 7:
          return 'black';
        case 99:
          return 'white';
        default:
          return ''
      }
    },
    _detectCurrentMoneyCoinMeron(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorMeron(money) {
      switch (true) {
        case money < 5:
          return "black";
        case money < 10:
          return "black";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "grey";
        default:
          return "";
      }
    },
    _detectCurrentMoneyCoinWala(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorWala(money) {
      switch (true) {
        case money < 5:
          return "blue";
        case money < 10:
          return "#F92A53";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "grey";
        default:
          return "";
      }
    },
    _detectCurrentMoneyCoinTie(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorTie(money) {
      switch (true) {
        case money < 5:
          return "blue";
        case money < 10:
          return "#F92A53";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "white";
        default:
          return "";
      }
    },

    _clearMoney() {
      this.CLEAR_BETTING_AMOUNT()
      this.CLEAR_BETTING_SICBO_AMOUNT()
      this.__xcheckallowsubmit();
    },
    _submitBetting() {

      let submitBettingData = {
        type_id: this.getSicboBetting.bettingid,
        amount: this.getSicboBetting.amount
      };
      this.$emit("sentSubmitBetting", submitBettingData);
      this._clearMoney();

    },
    _allowBtnBet(selectedBtn) {
      // if (!this.selectedCoin) {
      //   this.$toastr.e(this.$t("title.selectCoin"));
      //   return false;
      // }
      switch (selectedBtn) {
        case "selectedMeron":
          // console.log("selectedMeron");
          this.UDPDATE_SELECTE_BTN("selectedBanker");
          this.selectedButton = this.meronButton;
          this.selectedBtnClassMeron = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedTie":
          this.UDPDATE_SELECTE_BTN("selectedTie");
          this.selectedButton = this.tieButton;
          this.selectedBtnClassTie = true;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedWala":
          this.UDPDATE_SELECTE_BTN("selectedPlayer");
          this.selectedButton = this.walaButton;
          this.selectedBtnClassWala = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedPairBanker":
          this.UDPDATE_SELECTE_BTN("selectedPairBanker");
          this.selectedButton = this.pairBankerButton;
          this.selectedBtnClassPairBanker = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedPairPlayer":
          this.UDPDATE_SELECTE_BTN("selectedPairPlayer");
          this.selectedButton = this.pairPlayerButton;
          this.selectedBtnClassPairPlayer = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          break;
      }
    },
    ...mapMutations("$_dashboard_live", [
      "BETTING_DIALOG",
      "UPDATE_PLAYER_CARD",
      "UPDATE_BANKER_CARD",
      "RESET_CARD",
      "UDPDATE_SELECTE_BTN",
      "UPDATE_SELECT_COIN"
    ]),
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
    ]),
  },
};
</script>
<style scoped>

.ri-sicbo-count-down{
  width: 100%;
  height: auto;
  background-color: #386faf;
  margin-bottom: 6px;
  margin-top: 6px;
  text-align: center;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  border-radius: 6px;
}
.ri-sicbo-count-down span{
  color: #11f5c4;
  font-size: 2vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}

.ri-sicbo-count-down .sicbo-span-close{
  color: red;
}
.ri-xcol-r1-c1 {
  grid-area: r1c1;
}

.ri-xcol-r1-c2 {
  grid-area: r1c2;
}

.ri-xcol-r1-c3 {
  grid-area: r1c3;
}

.ri-xcol-r1-c4 {
  grid-area: r1c4;
}

.ri-xcol-r1-c5 {
  grid-area: r1c5;
}

.ri-xcol-r1-c6 {
  grid-area: r1c6;
}


.ri-xcol-r2-c1 {
  grid-area: r2c1;
}

.ri-xcol-r2-c2 {
  grid-area: r2c2;
}

.ri-xcol-r2-c3 {
  grid-area: r2c3;
}

.ri-xcol-r2-c4 {
  grid-area: r2c4;
}

.ri-xcol-r3-c1 {
  grid-area: r3c1;
}

.ri-xcol-r3-c2 {
  grid-area: r3c2;
}

.ri-xcol-r3-c3 {
  grid-area: r3c3;
}

.ri-xcol-r3-c4 {
  grid-area: r3c4;
}

.ri-xcol-r3-c5 {
  grid-area: r3c5;
}

.ri-xcol-r3-c6 {
  grid-area: r3c6;
}


.ri-xcol-r4-c1 {
  grid-area: r4c1;
}

.ri-xcol-r4-c2 {
  grid-area: r4c2;
}

.ri-xcol-r4-c3 {
  grid-area: r4c3;
}

.ri-xcol-r4-c4 {
  grid-area: r4c4;
}

.ri-sicbo-betting-row {
  display: grid;
  grid-template-areas:
    'r1c1 r1c2 r1c3 r1c4 r1c5 r1c6'
    'r1c1 r2c1 r2c2 r2c3 r2c4 r1c6'
    'r3c1 r3c2 r3c3 r3c4 r3c5 r3c6'
    'r3c1 r4c1 r4c2 r4c3 r4c4 r3c6';
  gap: 6px;
  width: 100%;
  height: 100%;

  margin-bottom: 12px;
}


.ri-sicbo-betting-col {
  font-size: 1vw;
  font-weight: 900;
  color: white;
  border: 1px solid white;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 3px;
  text-transform: uppercase;

}

.ri-sicbo-betting-col:hover {
  cursor: pointer;
}

.ri-sicbo-betting-col div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

}

.ri-sicbo-betting-col div span {
  width: 100%;
  text-align: center;
}

.ri-sicbo-betting-pd {
  padding-left: 3px;
  padding-right: 3px;
}

.ri-sicbo-betting-status {
  background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%) !important;
}

.ri-x-green {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
}

.ri-x-red {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1904px) {}

@media screen and (max-width:1280px) {}

@media screen and (max-width:982px) {}

@media screen and (max-width:960px) {}

@media screen and (max-width:959px) {}

@media screen and (max-width:768px) {

  .ri-sicbo-betting-col {
    font-size: 2vw;
  }
  .ri-sicbo-count-down span{
    font-size: 3vw;
  }

}

@media screen and (max-width:700px) {
  .ri-sicbo-count-down span{
    font-size: 3vw;
  }
  .v-application .ml-3{
    margin-left: 6px !important;
  }
}

@media screen and (max-width:600px) {

  .ri-sicbo-count-down span{
    font-size: 3vw;
  }
  
}

@media screen and (max-width:529px) {
  .ri-sicbo-betting-col {
    font-size: 3.1vw;
  }
  .ri-sicbo-count-down span{
    font-size: 5vw;
  }
}

@media screen and (max-width:450px) {
  .ri-sicbo-betting-col {
    font-size: 3.1vw;
  }
  .ri-sicbo-count-down span{
    font-size: 5.5vw;
  }
}

@media screen and (max-width:428px) {
  .ri-sicbo-betting-col {
    font-size: 3.3vw;
  }
  .ri-sicbo-count-down span{
    font-size: 6vw;
  }
}

@media screen and (max-width:414px) {
  .ri-sicbo-betting-col {
    font-size: 3.6vw;
  }
  .ri-sicbo-count-down span{
    font-size: 6vw;
  }
}

@media screen and (max-width: 390px) {
  .ri-sicbo-betting-col {
    font-size: 3.6vw;
  }
  .ri-sicbo-count-down span{
    font-size: 6vw;
  }
}

@media screen and (max-width: 384px) {
  .ri-sicbo-betting-col {
    font-size: 3.7vw;
  }
  .ri-sicbo-count-down span{
    font-size: 6vw;
  }
}

@media screen and (max-width: 375px) {
  .ri-sicbo-betting-col {
    font-size: 3.8vw;
  }
  .ri-sicbo-count-down span{
    font-size: 6vw;
  }
}



/*=========*/


/*==== coin betting button========*/

@media(max-width:760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.2rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-coin-cover {
    width: 100%
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:960px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:1280px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.3rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    padding: 0px;
    margin: 0px !important;
  }
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.hold-coin {
  position: sticky;
  left: 0px;
  z-index: 100;
  background: #013366;
  font-size: bolder;
  border-right: 2px dashed white;
}

.coin-container {
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.coin-container:hover {
  cursor: pointer;
}

.display-money {
  background-color: #7592af65;
  height: 40px;
  border-radius: 5px;
  margin: 5px 0px 7px 0px;
  color: #212427;
  font-weight: bolder;
  text-shadow: 1px 0.2px #406386;
  font-size: 1.3rem;
  padding-top: 5px;
  color: white;
}

.btn-clear-money {
  background: rgb(201, 201, 214);
  background: linear-gradient(180deg,
      rgba(201, 201, 214, 1) 0%,
      rgba(103, 103, 106, 1) 100%);
  border-width: 0.5 !important;
  border-color: #133375 !important;
  border-radius: 6px;
  padding: 6px 10px;
  color: white;
  width: 150px;
  text-align: center;
  justify-content: center;
}

.btn-submit-money {
  background: rgb(110, 179, 241);
  background: linear-gradient(180deg,
      rgba(110, 179, 241, 0.9108018207282913) 0%,
      rgba(25, 72, 166, 1) 100%);
  border-width: 0.5 !important;
  border-color: #133375 !important;
  border-radius: 6px;
  padding: 6px 10px;
  color: white;
  width: 150px;
  height: 30px !important;
  justify-content: center;
}

.btn-clear-money:hover {
  cursor: pointer;
}

.btn-submit-money:hover {
  cursor: pointer;
}


/*===== end coin betting button======*/
/*
@media (min-width: 960px) {
  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid blue;
    min-height: 185px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(113, 253, 113, 0.288);
  }

  .betting-coin-meron {
    width: 40px;
    height: auto;
    margin: auto;
  }

  .betting-coin-wala {
    width: 40px;
    height: auto;
    margin: auto;
  }

  .btn-coin {
    width: 45px;
    height: auto;
    position: relative;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
  
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;

    -ms-user-select: none;

    user-select: none;

  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;

    -ms-user-select: none;

    user-select: none;

  }

  .money-in-coin-position {
    margin-top: -29.5px;
    margin-bottom: 25px;
    margin-left: -2px;
    z-index: 100;
    font-weight: 600;
    text-shadow: 1px 0px 1px black;
    font-size: 13px;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    top: 1px;
    left: -16px;
    font-size: 12.5px;
    font-weight: 600;
    text-shadow: 1px 0px 1px black;
    width: 68px;
  }



  .side-section-betting-btn {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 2px solid #437adb;
    border-radius: 5px;
    z-index: 1000;
  }
}

@media (min-width: 1280px) {
  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    min-height: 200px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(113, 253, 113, 0.288);
  }

  .betting-coin-meron {
    width: 50px;
    height: auto;
    margin: auto;
  }

  .betting-coin-wala {
    width: 60px;
    height: auto;
    margin: auto;
  }

  .btn-coin {
    width: 100%;
height: auto;
position: relative;
padding: 0.3rem;
margin: 0px !important;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;

    -ms-user-select: none;

    user-select: none;

  }

  .betting-coin-meron {
    width: auto;
    height: 58px;
    margin: auto;
  }

  .betting-coin-wala {
    width: auto;
    height: 58px;
    margin: auto;
  }

  .btn-coin {
    width: 60px;
    height: auto;
    position: relative;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;

    -ms-user-select: none;

    user-select: none;

  }

  .money-in-coin-position {
    margin-top: -38px;
    margin-bottom: 31px;
    z-index: 100;
    font-weight: 900;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    top: 7px;
    left: -8px;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;

    position: absolute;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    padding: 0px;
    margin: 0px !important;
  }



  .side-section-betting-btn {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
    background-image: url("/images/tiger-dragon/dragon.png");
    z-index: 1000;
  }
}

.container {
  display: flex;
}

.column {
  flex: 2;
  text-align: center !important;
}

.left-column {
  flex: 2;
}

.right-column {
  flex: 2;
}

.block {
  margin-bottom: 10px;
  padding: 10px 0x;
}

.long-block {
  height: 200px;
  flex-basis: calc(33.33% - 20px);
}

.meron-section-tiger,
.modal-meron .modal-content {
  color: #fff;
}

.meron-section-tiger {
  color: #fff;
  border: 2px#f34141 solid;
  border-radius: 5px;
}

.meron-section-tiger .side-title,
.modal-meron .modal-bet-title {
  text-transform: uppercase;
  color: #f34141;
}

.wala-section-dragon,
.modal-meron .modal-content {
  color: #fff;
}

.wala-section-dragon {
  color: #fff;
  border: 2px solid #437adb;
  border-radius: 5px;
}

.wala-section-dragon .side-title,
.modal-meron-tiger .modal-bet-title {
  text-transform: uppercase;
  color: #187df1;
}

.pair-betting {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(32, 218, 88, 0.2);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}

.pair-betting:hover {
  cursor: pointer;
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.pair-banker-text {
  font-size: 18px;
  font-weight: 600;
  color: lightgoldenrodyellow;
  text-shadow: 1px 0px black;
}

.selected-p-banker {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}

.banker-button {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.tie-button {
  width: 100%;
  height: 238px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.banker-button :hover {
  cursor: pointer;
}

.selected-p-player {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}

.open-betting-countdown {
  background-color: #def3f6;
  font-size: 28px;
  font-family: "khmer mef1";
  font-weight: 900;
  height: 42px;
  text-align: center;
  padding-top: 0px;
  text-shadow: 0.2px 0.2px red;
}

.open-betting {
  color: #28a745;
}

.close-betting {
  color: #dc3545;
}

.truncate {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: center !important;
}

.odds {
  font-size: 1.5rem;

  color: whitesmoke;
  font-weight: 900;
}

.odds-subtle {
  font-size: 1rempx;
  color: hsla(0, 0%, 100%, 0.8);
  margin-top: 3px;
}

.tie-section {
  border: 2px greenyellow solid;
  border-radius: 5px;
}

.tie-section .side-title {
  color: greenyellow;
}

@media screen and (max-width: 1904px) {
  .text-display-payout {
    font-size: 2rem !important;
  }

  .range-betting-payout {
    font-size: 2rem;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 1280px) {
  .text-display-payout {
    font-size: 2rem !important;
  }

  .range-betting-payout {
    font-size: 1.3rem;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 982px) {
  .tie-button {
    height: 249px;
  }

  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.1rem;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 960px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1rem;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .block {
    margin-bottom: 10px;
    padding: 5px;
  }
}

@media screen and (max-width: 959px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.2rem;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 768px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.1rem;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 700px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 0.8rem;
  }

  .on-active-btn {
    border: 4px solid gold;

    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 600px) {
  .range-betting-payout {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 529px) {
  .range-betting-payout {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 390px) {
  .text-display-payout {
    font-size: 0.75rem;
  }

  .range-betting-payout {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 375px) {
  .range-betting-payout {
    font-size: 0.9rem;
  }
}

.han2-min-max-limit .max-label {
  float: right !important;
  width: auto !important;
  margin-top: 8px !important;
}

@media screen and (min-width: 1904px) {}

@media screen and (min-width: 1280px) {}

@media screen and (min-width: 982px) {}

@media screen and (min-width: 960px) {}

@media screen and (min-width: 959px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 700px) {}

@media screen and (min-width: 600px) {}

@media screen and (min-width: 529px) {
  .kalakouk-row {
    padding-left: 46px !important;
  }
}

@media screen and (min-width: 450px) {
  .kalakouk-row {
    padding-left: 36px !important;
  }
}

@media screen and (min-width: 428px) {
  .kalakouk-row {
    padding-left: 38px !important;
  }
}

@media screen and (min-width: 414px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 390px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 384px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 375px) {
  .kalakouk-row {
    padding-left: 30px !important;
  }
}

.range-betting-container {
  width: 23%;
  height: 90px;
  border-radius: 5px;
  background-color: rgb(0, 102, 153, 0.1);
  border: 1px solid rgb(218, 165, 32);
  position: relative;
}

.range-betting-container-active {
  width: 23%;
  height: 90px;
  border-radius: 5px;
  background-color: #012681;
  border: 3px solid grey;
  transform: scale(1.04);
  position: relative;
}

.payout {
  font-size: .9rem !important;
  font-weight: bold
}
*/
</style>