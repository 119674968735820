export default {
	username: 'អ្នកប្រើប្រាស់',
	user_name:"អ្នកប្រើប្រាស់",
	password: 'ពាក្យសម្ងាត់',
	login: 'ចូលប្រព័ន្ធ',
	contact_us: 'ទំនាក់ទំនងមកយើង',
	//validation
	invalid: {
		username: 'ការវាយបញ្ចូលមិនមែនជាឈ្មោះអ្នកប្រើប្រាស់ត្រឹមត្រូវទេ!'
	},
	//required
	required: {
		username: 'សូមវាយបញ្ចូលឈ្មោះអ្នកប្រើប្រាស់!',
		password: 'សូមវាយបញ្ចូលពាក្យសម្ងាត់!'
	},
	//error
	error: {
		login_failed: "ឈ្មោះអ្នកប្រើ ឬពាក្យសម្ងាត់របស់អ្នកមិនមានទេ."
	},
	bottom_label_welcome:"សូមស្វាគមន៍ចូលមកកាន់គេហទំព័រកម្សាន្ត",
	website_name :"SBC2888",
	bottom_label_website_qoute: "វេបសាយកាសុីណូអនឡាញដែលងាយស្រួលលេងនិងគួរអោយទុកចិត្តបំផុត",
	high_security : "១. ប្រព័ន្ធសុវត្ថិភាពខ្ពស់",
	fast_deposit : "២. ដាក់ប្រាក់ត្រឹមតែ ១នាទី",
	fast_withdraw : "៣. ដកប្រាក់រហ័សទាន់ចិត្ត",
	about_us : "ព័ត៌មានអំពីពួកយើង",
	support24_7:"សេវាកម្មអតិថិជន 24/7",
	come_to_win:"ចូលរួមលេង ចូលរួមឈ្នះ!",
	transaction : "វិធីសាស្ត្រទូទាត់:",
	social_media:"តាមដានពួកយើងតាមរយៈ",
	about_us_content : "SBC2888 ផ្តល់ជូននូវហ្គេមដែលមានគុណភាពខ្ពស់ដែលបានផលិតដោយក្រុមការងារ SBC2888 ដោយផ្ទាល់រួមមានហ្គេមជាច្រើនប្រភេទទៅកាន់អ្នកលេងរបស់យើង។ ផ្នែកបម្រើសេវាជូនអតិថិជនរបស់យើងអាចជួយអ្នក 24 ម៉ោង។ រាល់ព័ត៌មានផ្ទាល់ខ្លនទាំងអស់នឹងត្រូវបានរក្សាទុកដោយសុវុត្ថិភាព និងសម្ងាត់បំផុតជូនអតិថិជន។"
};
