<template>
  <div>
    <!-- {{ passBetType }} -->
    <v-row class="ma-0 w-100">
      <div class="machine-baccarat-three-section machine-blue-title text-center ">
        <div class="machine-klaklouk-button pt-0 machine-play-green-bg" :class=" getSelectedApongBtn && getSelectedApongBtn.button && getSelectedApongBtn.button.name ==defineOrder()[1].name 
        && getSelectedApongBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[1])"> 
          <p class="machine-button-name">
            <img :src="findImgApongButton(1)" alt="" class="image-klaklouk">
            <br/>
            <span class="font-apong-ccl apong-mobile">
              {{ passBetType[0] && passBetType[0].payout === '4' ? '1:4' : '1:4' }}
            </span>
          </p>
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0 machine-play-green-bg" :class=" getSelectedApongBtn && getSelectedApongBtn.button && getSelectedApongBtn.button.name ==defineOrder()[4].name 
        && getSelectedApongBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[4])"> 
          <p class="machine-button-name">
            <img :src="findImgApongButton(4)" alt="" class="image-klaklouk">
            <br/>
            <span class="font-apong-ccl apong-mobile">{{ passBetType[1] && passBetType[1].payout === '4' ? '1:4' : '1:4' }}</span>
          </p>
        </div>
      </div>
      <v-spacer></v-spacer>
    <div class="machine-baccarat-three-section machine-blue-title text-center ">
      <div class="machine-klaklouk-button pt-0 machine-play-green-bg" :class=" getSelectedApongBtn && getSelectedApongBtn.button && getSelectedApongBtn.button.name ==defineOrder()[2].name 
        && getSelectedApongBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[2])"> 
          <p class="machine-button-name ">
            <img :src="findImgApongButton(2)" alt="" class="image-klaklouk">
            <br/>
            <span class="font-apong-ccl apong-mobile">{{ passBetType[6] && passBetType[6].payout === '4' ? '1:4' : '1:4' }}</span>
          </p>
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0 machine-play-green-bg" :class=" getSelectedApongBtn && getSelectedApongBtn.button && getSelectedApongBtn.button.name ==defineOrder()[5].name 
        && getSelectedApongBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[5])"> 
          <p class="machine-button-name">
            <img :src="findImgApongButton(5)" alt="" class="image-klaklouk">
            <br/>
            <span class="font-apong-ccl apong-mobile">{{ passBetType[4] && passBetType[4].payout === '4' ? '1:4' : '1:4' }}</span>
          </p>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-baccarat-three-section machine-blue-title text-center">
        <div class="machine-klaklouk-button  pt-0 machine-play-green-bg" :class=" getSelectedApongBtn && getSelectedApongBtn.button && getSelectedApongBtn.button.name ==defineOrder()[3].name 
        && getSelectedApongBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[3])"> 
          <p class="machine-button-name">
            <img :src="findImgApongButton(3)" alt="" class="image-klaklouk">
            <br/>
            <span class="font-apong-ccl apong-mobile">{{ passBetType[7] && passBetType[7].payout === '4' ? '1:4' : '1:4' }}</span>
          </p>
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0 machine-play-green-bg" :class=" getSelectedApongBtn && getSelectedApongBtn.button && getSelectedApongBtn.button.name ==defineOrder()[6].name 
        && getSelectedApongBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[6])"> 
          <p class="machine-button-name">
            <img :src="findImgApongButton(6)" alt="" class="image-klaklouk">
            <br/>
            <span class="font-apong-ccl apong-mobile">{{ passBetType[2] && passBetType[2].payout === '4' ? '1:4' : '1:4' }}</span>
          </p>
        </div>
      </div>
    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitApong'"/>
  </div>
</template>
<script>

import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"
import findImg from "../../../../../../mixin/MX_FindImageFunctionCollection.js"

import {mapGetters, mapMutations } from "vuex";

export default {
  mixins: [findImg],
  components: {
    CockXCasinoSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  computed:{
    
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedApongBtn: "getSelectedApongBtn",
    }),
  },

  methods:{
    defineOrder(){
      return this.passBetType.slice().sort(function(a, b){
        return (a.order > b.order) ? 1 : -1;
      });
    },
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_APONG_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_APONG_BUTTON_IN_ALL",
    ]),
  }
}
</script>

<style>


</style>