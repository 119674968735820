<template>
    <div>
      <div v-if="!passIsSubGame"
          id="machine-menu-container"
          class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center w-100">
        <div class="menu-space w-100 ma-0"   >
          <v-row class="row w-100 ma-0">
                <div
                  class="text-center d-flex align-items-center menu-machine-game-slot-item mt-0"
                  :style="`width:${100/(getSubMenuMachineGame.length)}%`"
                  :class="$route.path == ('/machine-games/' + i.name) ? 'main-menu-span-active ' : ''"
                  v-for="i in getSubMenuMachineGame"
                  :key="i.name">
                  <div class="nav-machine-game-main-menu justify-center w-100 mt-0"
                    @click="_goToRoute('/machine-games/' + i.name)"
                   
                    v-if="getSubMenuMachineGame.length > 0 && i.is_enable">
                    <p class="text-uppercase text-center text-red">{{ i.name }}</p> 
                  </div>
                
            </div>
          </v-row>
        </div>
    </div>
    <div
      v-if="passIsSubGame"
      id="submachine-menu-container"
      class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center w-100"
    >
    <v-icon  class="website-base-icon ml-5" @click="_goToRoute('back')">mdi-arrow-left-circle</v-icon>
    </div>
  </div>
</template>

<script>
import "@/assets/css/machine-tabs.css";
import lotteryMenuFunction from "../../mixin/MX_LotteryMenuFunctionCollection.js";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  mixins: [lotteryMenuFunction],
  props:['passIsSubGame'],
  data: () => ({
    audioLogItems: [],
    chosenName: "",
  }),
  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      channelId: "channelId",
      getMainMenu: "getMainMenu",
      getSubMenuMachineGame:'getSubMenuMachineGame'
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
 mounted(){
  this.UPDATE_CHANNEL_ID(this.$cookies.get('sbc_channel_type'));
 },
  methods: {
    _calculateActive(channelId, menuId){
      if(channelId == menuId) return true;
      else return false
    }, 
    _checkHeightSubmenu(){
      let submenu = this.userInfo.menu.menu3.games[
      this._calculateMenuArray(this.$route.path)
      ].channels;
      for(let i in submenu){
        if(submenu[i].is_enable == true)  return true;
      }
      return false;
    },
    _checkHeightMenu(){
      let submenu = this.userInfo.menu.menu3.games[
      this._calculateMenuArray(this.$route.path)
      ].channels;
      for(let i in submenu){
        if(submenu[i].is_enable == true)  return true;
      }
      return false;
    },
    _goToRoute(path) {
      var selectedChannel = 90;
      this.UPDATE_EXPAND_MENU(true);
      if(path !='back'){
        if (path == '/LOTTO'  || path == "/LOTTO160S") {
        path = '/LOTTO';
        selectedChannel = 90
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == "/LOTTO100S") {
        path = '/LOTTO';
        selectedChannel = 901
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == "/LOTTO30S") {
        selectedChannel = 902;
        path = '/LOTTO';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == "/YUKI" || path == "/YUKI160S" ) {
        path = '/YUKI';
        selectedChannel = 91
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == "/YUKI100S" ) {
        selectedChannel = 911;
        path = '/YUKI';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if ( path == "/YUKI30S" ) {
        selectedChannel = 912;
        path = '/YUKI';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == "/LOTTERY") {
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      this.$router.push({ path }).catch(() => {
        /* ignore */
      });
      }
      else{
        this.$socket.removeAllListeners()
        this.$socket.disconnect()
        this.$router.push({ path: '/machine-games/ALL' }).catch(() => {
        /* ignore */
      });
    }

    },
  
    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_EXPAND_MENU"
    ]),
  },
};
</script>
<style>

</style>
