<template>
  <div class="trends limit-on-tab">
    

    <div>

      <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-wala">{{ resultObj.summary.TIGER }}</span>
          <p class="mb-0 count-label">Tiger</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-meron">{{ resultObj.summary.DRAGON }}</span>
          <p class="mb-0 count-label">Dragon</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-draw">{{ resultObj.summary.TIE }}</span>
          <p class="mb-0 count-label">Tie</p>
        </div>
      </div>
    
      <div
        class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
        style="position: relative; background-color: #ffffff !important"
      >
        <v-col cols="12" sm="12" style="padding: 5px 0">
          <v-row style="padding: 0; margin: 0">
            <v-col
              cols="6"
              md="6"
              sm="6"
              lg="6"
              style="padding: 0 5px 0 0; margin: 0"
            >
              <div class="table-responsive pb-3 gp-scroll">
                <table class="sub-machine-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in fixColBac" :key="col">
                        <div>
                          <div
                            v-for="(result, i) in orderBaccaratResult"
                            :key="i"
                          >
                            <span v-if="col == result.col && row == result.row">
                              <v-badge
                                v-if="
                                  result.is_pair_player && result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-badge
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-avatar
                                    :color="'a' + result.color"
                                    size="23"
                                    style="
                                      margin: 0px;
                                      color: #ffffff;
                                      font-weight: bold;
                                    "
                                  >
                                    {{
                                      result.name == "DRAGON"
                                        ? "D"
                                        : result.name == "TIGER"
                                        ? "G"
                                        : result.name == "TIE"
                                        ? "T"
                                        : "C"
                                    }}
                                  </v-avatar>
                                </v-badge>
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  result.is_pair_player &&
                                  !result.is_pair_banker
                                "
                                bordered
                                bottom
                                color="blue"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-avatar
                                  :color="'a' + result.color"
                                  size="23"
                                  style="
                                    margin: 0px;
                                    color: #ffffff;
                                    font-weight: bold;
                                  "
                                >
                                  {{
                                   result.name == "DRAGON"
                                        ? "D"
                                        : result.name == "TIGER"
                                        ? "G"
                                        : result.name == "TIE"
                                        ? "T"
                                        : "C"
                                  }}
                                </v-avatar>
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  !result.is_pair_player &&
                                  result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-avatar
                                  :color="'a' + result.color"
                                  size="23"
                                  style="
                                    margin: 0px;
                                    color: #ffffff;
                                    font-weight: bold;
                                  "
                                >
                                  {{
                                   result.name == "DRAGON"
                                        ? "D"
                                        : result.name == "TIGER"
                                        ? "G"
                                        : result.name == "TIE"
                                        ? "T"
                                        : "C"
                                  }}
                                </v-avatar>
                              </v-badge>
                              <v-avatar
                                v-else
                                :color="'a' + result.color"
                                size="23"
                                style="
                                  margin: 0px;
                                  color: #ffffff;
                                  font-weight: bold;
                                "
                              >
                                {{
                                 result.name == "DRAGON"
                                        ? "D"
                                        : result.name == "TIGER"
                                        ? "G"
                                        : result.name == "TIE"
                                        ? "T"
                                        : "C"
                                }}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="6" lg="6" style="padding: 0; margin: 0">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="sub-machine-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in fixCol" :key="col">
                        <div>
                          <div v-for="(result, i) in orderResult" :key="i">
                           
                            <span v-if="col == result.col && row == result.row">
                              <v-badge
                                v-if="
                                  result.is_pair_player && result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-badge
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-icon
                                    :class="'c' + result.color"
                                    style="padding: 0; margin: 0"
                                    >mdi-circle-outline</v-icon
                                  >
                                </v-badge>
                              </v-badge>
                              
                              <v-badge
                                v-else-if="
                                  result.is_pair_player &&
                                  !result.is_pair_banker
                                "
                                bordered
                                bottom
                                color="blue"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-icon
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  !result.is_pair_player &&
                                  result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-icon
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </v-badge>
                              <span v-else>
                                <v-icon
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </span>
                              
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>



    </div>
  </div>
   
    


</template>
  
  <script>
  
  import "@/assets/css/homepage.css";
  import { mapGetters } from "vuex";
  export default {
    props: ["rows", "cols"],
    data() {
    return {
      fixCol: 30,
      fixColBac: 20,
    };
  },
  
    computed: {
        ...mapGetters("$_dashboard_live", {
            resultObj: "getResult",
            channel: "getChannel",
        }),
        orderResult() {
            return this.getListResults();
        },
        number(bet) {
        return this.getNumber(bet);
        },
        orderBaccaratResult() {
        return this.getBaccaratResults();
        },
        getColor(resultName) {
      if (resultName == "BANKER") {
        return "error";
      } else if (resultName == "PLAYER") {
        return "primary";
      } else if (resultName == "TIE") {
        return "success";
      } else {
        return "default";
      }
    },

    },
    methods: {




        getNumber(bet){
        let number = [];
        number = bet.split(":");
        return number[1]
        },
        
        getBaccaratResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
      if (this.resultObj.results != null) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          // console.log(index)
          const item = resultList[index];
          // console.log(item)
          if (row <= this.rows) {
            // console.log('row <= this.rows')
            // console.log(row)
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            // console.log('else')
            row = 1;
            col += 1;
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
        if (col > this.cols) {
          this.fixColBac = col;
        } else {
          this.fixColBac = this.cols;
        }
      }
      return newResult;
    },
      getListResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.resultObj.results != null && this.resultObj.results.length > 0){
            let resultList = this.resultObj.results
            for (var index = resultList.length - 1; index >= 0; index--) {
                const item = resultList[index];
                if(index == resultList.length - 1){
                    newResult.push({
                        ...item,
                        row,
                        col,
                    });
                }else{
                    const pre = newResult[newResult.length - 1];
                    if(pre.name != item.name){
                        newResult.push({ 
                        ...item,
                        row: 1,
                        col: pre.col + 1,
                        });
                    }else{
                        if (pre.row >= 6) {
                            newResult.push({
                                ...item,
                                row: 1,
                                col: pre.col + 1,
                            });
                        } else {
                            newResult.push({
                                ...item,
                                row: pre.row + 1,
                                col: pre.col,
                            });
                        }
                    }
                }
            }
        }
      // console.log(newResult)
      return newResult;
    },
      _resultColor(color) {
        switch (color) {
          case 'cir-blue':
            return 'trend-wala'
          case 'cir-red':
            return 'trend-meron'
          case 'cir-green':
            return 'trend-draw'
          case 'cir-cancel':
            return 'trend-cancel'
          default:
            return ''
        }
      },
     
    
    },
  };
  </script>
  
  <style scoped>
  .history-result td {
    text-align: center;
    height: 45px;
    width: 45px;
    padding: 2px;
    background: #f2f6f9;
    border: 1px solid #96afde;
    vertical-align: middle;
    box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
    -moz-box-shadow: 0 0 2px 1px #fff;
    -webkit-box-shadow: 0 0 2px 1px #fff;
    -ms-box-shadow: 0 0 2px 1px #fff;
    -o-box-shadow: 0 0 2px 1px #fff;
  }
  .round-blue {
    background: url('~@/assets/images/round-blue.png') no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .round-red {
    background: url('~@/assets/images/round-red.png') no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .cycle-blue {
    background: url('~@/assets/images/cycle-blue.png') no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .cycle-red {
    background: url('~@/assets/images/cycle-red.png') no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .center-padding-0 {
    text-align: center;
    padding: 0px !important;
  }
  
  .result-table {
    width: 100%;
    margin: 0;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
  }
  .sub-machine-table {
    border-spacing: 0px !important;
  }
  .sub-machine-table td {
    width: 25px;
    height: 25px;
    font-size: 10px;
    text-align: center;
    padding: 0px;
  }
  .sub-machine-table tr,
  td {
    border: 1px solid #c5c5c5;
  }
  
  .bad-cont {
    font-size: 11px;
    background: #000000;
    color: #fff;
    margin-left: 5px;
    padding: 2px 10px;
    position: relative;
    border-radius: 5px;
  }
  .bad-cont {
    margin-left: 2px;
  }
  .bad-cont .count {
    padding: 4px;
    position: absolute;
    width: 25px;
    min-width: 25px;
    height: 25px;
    display: inline;
    margin: auto;
    left: -6px;
    display: flex;
    justify-content: center;
  }
  .bad-cont .count {
    padding: 0.4rem;
    width: 35px;
    height: 35px;
    font-size: 0.95rem;
    font-weight: 700;
  }
  .bad-cont .count-label {
    padding-left: 1.3rem;
  }
  
  td > div {
    width: 25px;
  }
  </style>