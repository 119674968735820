export default {
    methods: {
        getBulletColor(channelType, displayFor) {
            switch (true) {
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 16 || channelType == 18 || channelType == 19) && displayFor == 'meron'):
                    return 'trend-meron'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && displayFor == 'wala'):
                    return 'trend-wala'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && displayFor == 'tie'):
                    return 'trend-draw'
                case (channelType == 16 && displayFor == 'wala'):
                    return 'trend-black'
                case (channelType == 16 && displayFor == 'tie'):
                    return 'trend-draw'
            }
        },
      

    }
}