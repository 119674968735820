export default {
	home: 'HOME',
	invoice: 'invoice',
	patients: 'Patients',
	doctors: 'Doctors',
	stock: 'Stock',
	services: 'Services',
	settings: 'Settings',
	contact: 'Contact',
	new_invoice: 'New Invoice',
	detail_invoice: 'Detail Invoice',
	logout: 'LOGOUT',
	kk1: 'Tiger',
	kk2: 'Gourd',
	kk3: 'Rooster',
	kk4: 'Lobster',
	kk5: 'Crab',
	kk6: 'Fish',
	tsOdd: 'ODD',
	tsEven: 'Even',
	ts1: '1',
	ts2: '2',
	ts3: '3',
	ts4: '4',
	ts5: '5',
	ts6: '6',
	tsBig: 'O',
	tsSmall: 'U',
	betting_history:"BETTING HISTORY",
	withdraw:"WITHDRAW",
	deposit:"DEPOSIT",
	account_detail:"ACCOUNT DETAIL",
};
