<template>
  <v-dialog
    :value="getDialogInvoice"
    max-width="1200"
    persistent
    dark
    transition="dialog-bottom-transition"
  >
  <v-card class="pa-0 ma-0">
     <v-toolbar style="float: right; border-radius:100px" class="mt-5 mr-5" height="35" width="35">
      <v-btn icon color="error" class="pr-5" @click="UPDATE_DIALOG_INVOICE(false)">
        <v-icon style="color:#fb8c00 !important" >mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row class="pl-0">
        <v-col cols="12" sm="12" md="12" class="text-left mt-4">
          <v-toolbar-title class="dialog-title">{{ $t("title.invoice_detail") }}</v-toolbar-title>
        </v-col>
      </v-row>
      
     <v-data-table
          style="font-family: 'khmer mef1'"
          class="elevation-1 detail-resport"
          item-key="date"
          :headers="headersDetail"
          :footer-props="footerProps"
          :items="getListDetailInvoice ? getListDetailInvoice : []"
          :server-items-length="getListDetailInvoice ? parseInt(getListDetailInvoice.count): null"
          
          mobile-breakpoint="0"
      >
      <!-- :sort-desc.sync="dataRequest.descendingFormat"
      :sort-by.sync="dataRequest.sortByFormat"
      :page.sync="dataRequest.page"
      :items-per-page.sync="dataRequest.rowsPerPage"
      @update:page="updatePage"
      @update:items-per-page="updatePage"
      @update:sort-desc="updatePage"
      :loading="getLoadingDetail" -->
        <template v-slot:[`item`]="{ item }">
          <tr>
            <td>{{ item.time }}</td>
            <td>{{ item.no }}</td>
            <td>
              <span :style="{color: item.bet_color}">{{item.bet_type}}:{{item.betting}}  </span>
            </td>
            <td>{{item.amount ? (item.amount).toLocaleString():"..." }}</td>

            <td>{{ item.return_amount }}</td>
            <td :style="item.is_release_result && item.bet_result.is_win ? 'color:blue' : item.is_release_result && !item.bet_result.is_win ? 'color:red' : '---'">{{ item.is_release_result && item.bet_result.is_win ? $t("title.x_win") : item.is_release_result && !item.bet_result.is_win ? $t("title.x_lose") : '---' }}</td>
            <td>
              <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='A'">{{item.bet_type}}:{{ item.bet_result.a.number[0] }}</span></span>
              <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='B'">{{item.bet_type}}:{{ item.bet_result.b.number[0] }}</span></span>
              <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='C'">{{item.bet_type}}:{{ item.bet_result.c.number[0] }}</span></span>
              <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='D'">{{item.bet_type}}:{{ item.bet_result.d.number[0] }}</span></span>
              <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='E'">{{item.bet_type}}:{{ item.bet_result.e.number[0] }}</span></span>

            </td>
          </tr>
        </template>
        <template slot="body.append">
          <td :colspan="3" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td :class=" selectedItem.total_amount < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">

            {{ selectedItem ?  _formatAmountCurrency(userInfor, (selectedItem.total_amount)) : "loading ..."}}
          </td>
          <td :class=" selectedItem.total_amount_win < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">

            {{ selectedItem && ( selectedItem.total_cast || selectedItem.total_cast == 0)  ? _formatAmountCurrency(userInfor, selectedItem.total_cast) : "???"}}
          </td>
          <td></td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Mixin from "~mixin";
export default {
    mixins: [Mixin],
    props:['selectedItem'],
    data() {
        return {
          headersDetail: [
      { text: this.$t("title.date_time"), value: 'time' },
      { text:  this.$t("title.game"), value: 'no' },
      { text: this.$t("title.bet_type"), value: 'bet_type' },
      { text: this.$t("title.amount"), value: 'amount' },
      { text:this.$t("title.total_cast"),value:'total_cast'},
      { text: this.$t("title.result"),value:'result'},
      { text: this.$t("title.result_number"), value: 'result_number'}

    ],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_lottery3d", {
          getDialogInvoice: "getDialogInvoice",
          getListDetailInvoice:"getListDetailInvoice"
        }),
    },
    methods: {
      ...mapMutations("$_lottery3d", ["UPDATE_DIALOG_INVOICE"]),
    },
};
</script>