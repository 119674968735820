import { render, staticRenderFns } from "./LoginMenu.vue?vue&type=template&id=23d20ea2"
import script from "./LoginMenu.vue?vue&type=script&lang=js"
export * from "./LoginMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports