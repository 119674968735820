
const getChannel = (state) => state.channel;
const getMachineButton = (state) => state.machineButton;
const getAmountMin = (state) => state.amount_min
const getAmountMax = (state) => state.amount_max
const getChannelStatus = (state) => state.channelStatus;
const getNumberMaster = (state) => state.numberMaster;
const getBettingTypeMaster = (state) => state.bettingTypeMaster;
const getResult = (state) => state.resultData;
const getTodayBetting = (state) => state.todayBetting;
const getCurrentBetting = (state) => state.currentBetting;
const getCreateBetting = (state) => state.createBetting;
const getWeight = (state) => state.weight;
const getButton = (state) => state.button;
const getVdoLinks = (state) => state.vdolinks;
const getDialogBetting = (state) => state.dialog_betting;
const getLoadingTodayReport = (state) => state.loadingReport;
const getCountdown = (state) => state.countDown;
const getIsReport = (state) => state.isReport;
const getLoading = (state) => state.loading_betting;
const getErrorMessage = (state) => state.errorMessage;

// Tiger Dragon
const getDragonCard = (state) => state.dragonCard;
const getTigerCard = (state) => state.tigerCard;
// Baccarat
const getPlayerCard1 = (state) => state.playerCard1;
const getPlayerCard2 = (state) => state.playerCard2;
const getPlayerCard3 = (state) => state.playerCard3;
const getBankerCard1 = (state) => state.bankerCard1;
const getBankerCard2 = (state) => state.bankerCard2;
const getBankerCard3 = (state) => state.bankerCard3;
const getBankerPoint = (state) => state.totalB;
const getPlayerPoint = (state) => state.totalP;
//lotto
const getListLotto = (state) => state.listLotto;
const getTotalLotto = (state) => state.totalLotto;
// klaklouk
const getListKlaklouk = (state) => state.listKlaklouk;
// abong
const getListAbong = (state) => state.listAbong;
//yuki
const getListYuki = (state) => state.listYuki;
// Taisiv
const getListTaiSiv = (state) => state.listTaiSiv;
// fantan
const getListFantan = (state) => state.listFantan;
// betting coin
const getIsSelectedCoin = (state) => state.isSeletedCoin;

const getSelectedCockBtn = (state) => state.cockSeletedBtn;
   //FOR BUTTON BETTING
const getSelectedBaccaratBtn = (state) => state.selectedBaccaratButton_InAll;
const getSelectedTigerBtn = (state) => state.selectedTigerButton_InAll;
const getSelectedTaixiuBtn = (state) => state.selectedTaixiuButton_InAll;
const getSelectedDodenBtn = (state) => state.selectedDodenButton_InAll;
const getSelectedSicboBtn = (state) => state.selectedSicboButton_InAll;
const getSelectedKlakloukBtn = (state) => state.selectedKlakloukButton_InAll;
//---------
const getSelectedLottoBtn = (state) => state.selectedLottoButton_InAll;
const getSelectedHanBtn = (state) => state.selectedHanButton_InAll;
const getSelectedTaisevBtn = (state) => state.selectedTaisevButton_InAll;
const getSelectedYukiBtn = (state) => state.selectedYukiButton_InAll;
// FOR MACHIE STATEMENT
const getMachineStatement = (state) => state.machine_statement;
const getMachineStatementConfiguredData = (state) => state.machine_statement_configured_data;

export default {
    getMachineButton,
    getMachineStatementConfiguredData,
    getMachineStatement,
    getSelectedKlakloukBtn,
    getSelectedSicboBtn,
    getSelectedDodenBtn,
    getSelectedTaixiuBtn,
    getSelectedBaccaratBtn,
    getSelectedTigerBtn,
    getSelectedCockBtn,
    getErrorMessage,
    getLoading,
    getAmountMin,
    getAmountMax,
    getTotalLotto,
    getListLotto,
    getChannel,
    getChannelStatus,
    getNumberMaster,
    getResult,
    getTodayBetting,
    getCurrentBetting,
    getCreateBetting,
    getBettingTypeMaster,
    getWeight,
    getButton,
    getVdoLinks,
    getDialogBetting,
    getLoadingTodayReport,
    getCountdown,
    //Tiger Dragon
    getDragonCard,
    getTigerCard,
    // Baccarat
    getPlayerCard1,
    getPlayerCard2,
    getPlayerCard3,
    getBankerCard1,
    getBankerCard2,
    getBankerCard3,
    getBankerPoint,
    getPlayerPoint,
    // klaklouk
    getListKlaklouk,
    // abong
    getListAbong,
    getListYuki,
    getIsReport,
    getListTaiSiv,
    getListFantan,
    // betting coin
    getIsSelectedCoin,
    //------
    getSelectedLottoBtn,
    getSelectedHanBtn,
    getSelectedTaisevBtn,
    getSelectedYukiBtn
};
