<template>
  <div>
    <C_16_RouletteLiveTableResultComponent
      v-if="passSelectedChannel == 16"
         :rows="6" :cols="35" />
    <C_15_BaccaratLiveTableResultComponent
      v-if=" passSelectedChannel == 15 "
         :rows="6" :cols="35" />
      <C_18_BaccaratLiveTableResultComponent
      v-if="passSelectedChannel == 18 "
         :rows="6" :cols="35" />
         <C_19_TigerDragonLiveTableResultComponent
      v-if="passSelectedChannel == 19 "
         :rows="6" :cols="35" />

    <KlaklokTableResultComponent v-if="passSelectedChannel == 5" :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="35" />

    <BaccaratTableResultComponent v-if="passSelectedChannel == 6 || passSelectedChannel == 7" :rows =" 6" :cols=" 35 " />

    <TigerDragonTableResultComponent v-if="passSelectedChannel == 8 || passSelectedChannel == 9"
      :rows =" 6" :cols=" 35 " />

    <LottoTableResultComponent v-if=" passSelectedChannel == 10 " :passCurrentTableResult=" passCurrentTableResult "
      :rows=" 6 " :cols=" 35 " />

    <YukiTableResultComponent v-if=" passSelectedChannel == 11 " :passCurrentTableResult=" passCurrentTableResult "
      :rows=" 6 " :cols=" 35 " />

    <AbongTableResultComponent v-if=" passSelectedChannel == 12 || passSelectedChannel == 17 "
      :passCurrentTableResult=" passCurrentTableResult " :rows=" 6 " :cols=" 35 " />

    <FanTanResultComponent v-if=" passSelectedChannel == 13 " :passCurrentTableResult=" passCurrentTableResult " :rows=" 6 "
      :cols=" 35 " />

    <TaiSievTableResultComponent v-if=" passSelectedChannel == 14 " :passCurrentTableResult=" passCurrentTableResult "
      :rows=" 6 " :cols=" 35 " />

    <Han2GameTableResultComponent v-if=" passSelectedChannel == 20 " :passCurrentTableResult=" passCurrentTableResult "
      :rows=" 6 " :cols=" 20 " />

    <Han3GameTableResultComponent v-if=" passSelectedChannel == 21 " :passCurrentTableResult=" passCurrentTableResult "
      :rows=" 6 " :cols=" 20 " />

  </div>
</template>

<script>

import "~css/in_game/table_results_in_game/share_screen_style.css"
import "~css/in_game/table_results_in_game/screen_max_480.css"
import "~css/in_game/table_results_in_game/screen_min_481.css"
import "~css/in_game/table_results_in_game/screen_min_768.css"
import "~css/in_game/table_results_in_game/screen_min_993.css"
import "~css/in_game/table_results_in_game/screen_min_1025.css"
import "~css/in_game/table_results_in_game/screen_min_1281.css"
import "~css/in_game/table_results_in_game/screen_min_1441.css"
import "~css/in_game/table_results_in_game/screen_min_1661.css"
import "~css/in_game/table_results_in_game/screen_min_1824.css"

import C_16_RouletteLiveTableResultComponent from "./_TableResultComponent/C_16_RouletteLiveTableResultComponent.vue"
import C_15_BaccaratLiveTableResultComponent from "./_TableResultComponent/C_15_BaccaratLiveTableResultComponent.vue"
import C_18_BaccaratLiveTableResultComponent from "./_TableResultComponent/C_18_BaccaratLiveTableResultComponent.vue"
import C_19_TigerDragonLiveTableResultComponent from "./_TableResultComponent/C_19_TigerDragonLiveTableResultComponent.vue"
import BaccaratTableResultComponent from "./_TableResultComponent/BaccaratTableResultComponent.vue";
import TigerDragonTableResultComponent from "./_TableResultComponent/TigerDragonTableResultComponent.vue";
import KlaklokTableResultComponent from "./_TableResultComponent/KlaklokTableResultComponent.vue";
import LottoTableResultComponent from "./_TableResultComponent/LottoTableResultComponent.vue";
import YukiTableResultComponent from "./_TableResultComponent/YukiTableResultComponent.vue";
import AbongTableResultComponent from "./_TableResultComponent/AbongTableResultComponent.vue";
import FanTanResultComponent from "./_TableResultComponent/FanTanResultComponent.vue";
import TaiSievTableResultComponent from "./_TableResultComponent/TaiSievTableResultComponent.vue";
import Han2GameTableResultComponent from "./_TableResultComponent/Han2GameTableResultComponent.vue";
import Han3GameTableResultComponent from "./_TableResultComponent/Han3GameTableResultComponent.vue";


export default {
  components: { C_15_BaccaratLiveTableResultComponent, C_18_BaccaratLiveTableResultComponent,C_19_TigerDragonLiveTableResultComponent, C_16_RouletteLiveTableResultComponent, Han2GameTableResultComponent, Han3GameTableResultComponent, AbongTableResultComponent, YukiTableResultComponent, LottoTableResultComponent, KlaklokTableResultComponent, BaccaratTableResultComponent, TigerDragonTableResultComponent, FanTanResultComponent, TaiSievTableResultComponent },
  props: ["passCurrentTableResult", "passTodayTableResult", 'passLoadingTodayTableResult', 'passSelectedChannel'],
  methods: {
    _sentRefreshTodayTableResult() {
      this.$emit("sentRefreshTodayTableResult");
    },
    _sentRefreshCurrentTableResult() {
      this.$emit("sentRefreshCurrentTableResult");
    }
  },
}
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 5px
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(40, 53, 89, .8);
  border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(78, 106, 181, .2)
}

@keyframes rotate {

  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }

}


.refresh-start {

  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;

}

.text-meron {
  color: #f93c3c !important
}

.txt-white {
  color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
  color: #FFC008
}

.txt-grey {
  color: #6C757D
}
</style>