<template>
  <div>
    <v-row no-gutters class="ma-0">
      <v-col cols="12" class="text-center">
        <Tabs class="pt-1" style="background:#0437b4"></Tabs>
      </v-col>
      <v-col cols="12" sm="12" md="5" style="height: 100%">
        <Info
          :passClientTime="passClientTime"
          :passGetLimit="passGetLimit"
          :passCurrentGame="passCurrentGame"
        ></Info>
        <GameResult :passClientTime="passClientTime" :passClientResult="passClientResult" :passClientRandom="passClientRandom" :passRandomNumber="passRandomNumber"></GameResult>
       <SubmitButton :passClientTime="passClientTime" class="yuki-hide-small-screen"></SubmitButton>
      </v-col>
      <v-col cols="12" sm="12" md="7" style="height: 100%">
        <GameBettingButton
          :passClientTime="passClientTime"
          :passSixBtn="passSixBtn"
          :passSixToTwelve="passSixToTwelve"
          :passRangeSmall="passRangeSmall"
          :passRangeBig="passRangeBig"
          :passSmallBig="passSmallBig"
          :passOddPair="passOddPair"
          :passBlueRed="passBlueRed"
          :passGetSelectBtn="passGetSelectBtn"
          :passClientResult="passClientResult"
        >
        </GameBettingButton>
        <SubmitButton
          :passClientTime="passClientTime"
          class="yuki-hide-large-screen"
        ></SubmitButton>
      </v-col>
    </v-row>
    <CoinButton ></CoinButton>
  </div>
</template>


<script>
import Info from "./Info.vue";
import GameResult from "./Game_Display_Result.vue";
import GameBettingButton from "./GameBettingButton.vue";
import SubmitButton from "./Submit_Button.vue";
import CoinButton from "./Coin_Button.vue";
import Tabs from "../../../components/component_machine_game/Tabs.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    Info,
    GameBettingButton,
    GameResult,
    SubmitButton,
    CoinButton,
    Tabs
  },
  props: [
    "passGetButton",
    "passChannel",
    "passRandomNumber",
    "passClientRandom",
    "passClientResult",
    "passClientTime",
    "passCurrentGame",
    "passGetLimit",
    "passSixBtn",
    "passSixToTwelve",
    "passRangeSmall",
    "passRangeBig",
    "passSmallBig",
    "passOddPair",
    "passBlueRed",
    "passGetSelectBtn",
  ],
  data() {
    return {
      selectedButton: {},
      betType: 0,
      selectBetType: [],
      checkedOneSix: [],
      checkedSevenUp: [],
      checkedRangeSmall: [],
      checkedRangeBig: [],
      checkedSmallBig: [],
      checkedOddPair: [],
      checkedBlueRed: [],
      totalMoney: 0,
      rgetLimit: [],
      amount: 0,
      errorMessage: null,
      isBetting: false,
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
  },
  methods: {
    _g_translate(x) {
      switch (x) {
        case "ជូត":
          return "title.yuki_wipe";
        case "ឆ្លូវ":
          return "title.yuki_street";
        case "ខាល":
          return "title.yuki_karl";
        case "ថោះ":
          return "title.yuki_thao";
        case "រោង":
          return "title.yuki_factory";
        case "ម្សាញ់":
          return "title.yuki_net";
        case "ស្តើង":
          return "title.yuki_thin";
        case "ក្រាស់":
          return "title.yuki_thick";
        case "មមី":
          return "title.yuki_mimi";
        case "មមែ":
          return "title.yuki_mom";
        case "វក":
          return "title.yuki_vk";
        case "រកា":
          return "title.yuki_roka";
        case "កុរ":
          return "title.yuki_kor";
        case "សេស":
          return "title.yuki_odd";
        case "គូ":
          return "title.yuki_pair";
        case "ក្រហម":
          return "title.yuki_red";
        case "ខៀវ":
          return "title.yuki_blue";
        case "ច":
          return "title.yuki_dog";
        default:
          return "yuki_null";
      }
    },
    _selected(id, type) {
      if (type == 1) {
        if (this.checkedOneSix.includes(id)) {
          let i = this.checkedOneSix.map((item) => item).indexOf(id); // find index of your object
          this.checkedOneSix.splice(i, 1);
        } else {
          this.checkedOneSix.push(id);
        }
      } else if (type == 2) {
        if (this.checkedSevenUp.includes(id)) {
          let i = this.checkedSevenUp.map((item) => item).indexOf(id); // find index of your object
          this.checkedSevenUp.splice(i, 1);
        } else {
          this.checkedSevenUp.push(id);
        }
      } else if (type == 3) {
        if (this.checkedRangeSmall.includes(id)) {
          let i = this.checkedRangeSmall.map((item) => item).indexOf(id); // find index of your object
          this.checkedRangeSmall.splice(i, 1);
        } else {
          this.checkedRangeSmall.push(id);
        }
      } else if (type == 4) {
        if (this.checkedRangeBig.includes(id)) {
          let i = this.checkedRangeBig.map((item) => item).indexOf(id); // find index of your object
          this.checkedRangeBig.splice(i, 1);
        } else {
          this.checkedRangeBig.push(id);
        }
      } else if (type == 5) {
        if (this.checkedSmallBig.includes(id)) {
          let i = this.checkedSmallBig.map((item) => item).indexOf(id); // find index of your object
          this.checkedSmallBig.splice(i, 1);
        } else {
          this.checkedSmallBig.push(id);
        }
      } else if (type == 6) {
        if (this.checkedOddPair.includes(id)) {
          let i = this.checkedOddPair.map((item) => item).indexOf(id); // find index of your object
          this.checkedOddPair.splice(i, 1);
        } else {
          this.checkedOddPair.push(id);
        }
      } else if (type == 7) {
        if (this.checkedBlueRed.includes(id)) {
          let i = this.checkedBlueRed.map((item) => item).indexOf(id); // find index of your object
          this.checkedBlueRed.splice(i, 1);
        } else {
          this.checkedBlueRed.push(id);
        }
      }
      this.calculateMoney();
    },
    getAmount(money) {
      if (this.amount) this.amount = parseInt(this.amount) + parseInt(money);
      else this.amount = parseInt(money);
      if (
        this.checkedOneSix.length <= 0 &&
        this.checkedSevenUp.length <= 0 &&
        this.checkedRangeSmall.length <= 0 &&
        this.checkedRangeBig.length <= 0 &&
        this.checkedSmallBig.length <= 0 &&
        this.checkedOddPair.length <= 0 &&
        this.checkedBlueRed.length <= 0
      ) {
        this.totalMoney = this.amount;
        // console.log(`checkboxbtn: ${this.totalMoney}`);
      } else {
        this.calculateMoney();
      }
      this.errorMessage = null;
    },
    calculateMoney() {
      this.$nextTick(() => {
        if (this.amount) {
          this.totalMoney = 0;
          if (this.checkedOneSix.length > 0) {
            this.totalMoney =
              this.totalMoney + this.amount * this.checkedOneSix.length;
          }
          if (this.checkedSevenUp.length > 0) {
            this.totalMoney =
              this.totalMoney + this.amount * this.checkedSevenUp.length;
          }
          if (this.checkedRangeSmall.length > 0) {
            this.totalMoney =
              this.totalMoney + this.amount * this.checkedRangeSmall.length;
          }
          if (this.checkedRangeBig.length > 0) {
            this.totalMoney =
              this.totalMoney + this.amount * this.checkedRangeBig.length;
          }
          if (this.checkedSmallBig.length > 0) {
            this.totalMoney =
              this.totalMoney + this.amount * this.checkedSmallBig.length;
          }
          if (this.checkedOddPair.length > 0) {
            this.totalMoney =
              this.totalMoney + this.amount * this.checkedOddPair.length;
          }
          if (this.checkedBlueRed.length > 0) {
            this.totalMoney =
              this.totalMoney + this.amount * this.checkedBlueRed.length;
          }
          this.UPDATE_TOTAL_INVOICE(this.totalMoney);
          // console.log(`checkbox: ${this.totalMoney}`);
        } else {
          this.totalMoney = 0;
          this.UPDATE_TOTAL_INVOICE(this.totalMoney);
          // console.log(`checkbox unchecked: ${this.totalMoney}`);
        }
      });
    },
    handleClear() {
      this.amount = 0;
      this.totalMoney = 0;
      this.UPDATE_TOTAL_INVOICE(this.totalMoney);
      //this.amount = null;
      this.errorMessage = null;
    },
    async handleOk() {
      const { amount, isBetting, userInfor } = this;
      if (isBetting) return;
      this.isBetting = true;
      // console.log(amount);
      if (!amount || amount < 1) {
        this.errorMessage = "Please enter your bet amount!";
        this.$toastr.e(`${this.errorMessage}`);
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (userInfor.balance <= amount) {
        this.errorMessage = "Not Enough Balance";
        this.$toastr.e(`${this.errorMessage}`);
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (amount < userInfor.amount_min || amount > userInfor.amount_max) {
        this.errorMessage = `Bet amount must between bet limit`;
        this.$toastr.e(`${this.errorMessage}`);
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (
        this.checkedOneSix.length <= 0 &&
        this.checkedSevenUp.length <= 0 &&
        this.checkedRangeSmall.length <= 0 &&
        this.checkedRangeBig.length <= 0 &&
        this.checkedSmallBig.length <= 0 &&
        this.checkedOddPair.length <= 0 &&
        this.checkedBlueRed.length <= 0
      ) {
        this.errorMessage = `Please select your bet!`;
        this.$toastr.e(`${this.errorMessage}`);
        this.isBetting = false;
        return;
      }
      try {
        let betData = [];
        if (this.checkedOneSix.length > 0) {
          this.checkedOneSix.forEach((yuki_id) => {
            betData.push({ yuki_id, amount });
          });
        }
        if (this.checkedSevenUp.length > 0) {
          this.checkedSevenUp.forEach((yuki_id) => {
            betData.push({ yuki_id, amount });
          });
        }
        if (this.checkedRangeSmall.length > 0) {
          this.checkedRangeSmall.forEach((yuki_id) => {
            betData.push({ yuki_id, amount });
          });
        }
        if (this.checkedRangeBig.length > 0) {
          this.checkedRangeBig.forEach((yuki_id) => {
            betData.push({ yuki_id, amount });
          });
        }
        if (this.checkedSmallBig.length > 0) {
          this.checkedSmallBig.forEach((yuki_id) => {
            betData.push({ yuki_id, amount });
          });
        }
        if (this.checkedOddPair.length > 0) {
          this.checkedOddPair.forEach((yuki_id) => {
            betData.push({ yuki_id, amount });
          });
        }
        if (this.checkedBlueRed.length > 0) {
          this.checkedBlueRed.forEach((yuki_id) => {
            betData.push({ yuki_id, amount });
          });
        }
        // console.log(betData);
        const response = await this.createBet(betData);
        if (response.data.code) {
          this.amount = 0;
          this.UPDATE_TOTAL_INVOICE(0);
            await this.fetchUserInfo();
            this.checkedOneSix = [];
            this.checkedSevenUp = [];
            this.checkedRangeSmall = [];
            this.checkedRangeBig = [];
            this.checkedSmallBig = [];
            this.checkedOddPair = [];
            this.checkedBlueRed = [];
            this.fetchCurrentBetting();
          this.$toastr.s(`${response.data.message.descriptions}`);
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`);
          this.error = true;
        }
        this.isBetting = false;
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },

    _inputAmount(money) {
      this.amount = parseInt(this.amount) + parseInt(money);
      this.calculateMoney();
    },
    formatCoinCurrency(amount) {
      switch (amount) {
        case 1000:
          return "1ព";
        case 5000:
          return "5ព";
        case 10000:
          return "1ម";
        case 20000:
          return "2ម";
        case 50000:
          return "5ម";
        case 100000:
          return "10ម";
        case 200000:
          return "20ម";
        case 500000:
          return "50ម";
        case 1000000:
          return "1ល";
        case 5000000:
          return "5ល";
        default:
          return amount;
      }
    },
    formatBtnCurrencyKh(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "ព";
        case amount >= 10000 && amount <= 999999:
          return amount / 10000 + "ម";
        case amount >= 1000000 && amount <= 99999999999:
          return amount / 1000000 + "ល";
        default:
          return amount;
      }
    },
    formatBtnCurrency(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "K";
        default:
          return amount;
      }
    },
    findImageOfEachYear(yearId) {
      switch (yearId) {
        case 1:
          return "images/1.png";
        case 2:
          return "images/2-1.png";
        case 3:
          return "images/3.png";
        case 4:
          return "images/4.png";
        case 5:
          return "images/5.png";
        case 6:
          return "images/6.png";
        case 7:
          return "images/7.png";
        case 8:
          return "images/8.png";
        case 9:
          return "images/9.png";
        case 10:
          return "images/10.png";
        case 11:
          return "images/11.png";
        case 12:
          return "images/12.png";
        default:
        // code block
      }
    },
    findImageOfNumber(yearId) {
      switch (yearId) {
        case 1:
          return "images/number/1.png";
        case 2:
          return "images/number/2.png";
        case 3:
          return "images/number/3.png";
        case 4:
          return "images/number/4.png";
        case 5:
          return "images/number/5.png";
        case 6:
          return "images/number/6.png";
        case 7:
          return "images/number/7.png";
        case 8:
          return "images/number/8.png";
        case 9:
          return "images/number/9.png";
        case 10:
          return "images/number/10.png";
        case 11:
          return "images/number/11.png";
        case 12:
          return "images/number/12.png";
        default:
        // code block
      }
    },
    
   
    formatMoney(amount) {
      return amount.toLocaleString();
    },
    ...mapActions("$_machine_yuki", ["createBet", "fetchCurrentBetting"]),
    ...mapMutations("$_dashboard", ["BETTING_DIALOG"]),
    ...mapMutations("$_machine_yuki", [
      "BET_TYPE",
      "IS_BETTING",
      "UPDATE_TOTAL_INVOICE",
    ]),
    ...mapActions("$_modules", ["fetchUserInfo"]),
  },
};
</script>