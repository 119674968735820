export default {
    ticket_id : "Ticket ID",
	game_id: "Game ID",
	type_betting : "Betting Type",
	bet_point : "Payout",
	win_loss: "Win/lose",
	print:"Print",
	cancel :"Cancel",
	account : 'Account',
	invoice_no:"Invoice No",
	number:"Number",
	time:"Time",
	bet_point2:"Bet Point",
	date:"Date"
};
