var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"ma-0 w-100"},[_c('div',{staticClass:"machine-baccarat-three-section machine-blue-title text-center"},[_c('div',{staticClass:"machine-klaklouk-button pt-0 machine-play-green-bg",class:_vm.getSelectedApongBtn && _vm.getSelectedApongBtn.button && _vm.getSelectedApongBtn.button.name ==_vm.defineOrder()[1].name 
      && _vm.getSelectedApongBtn.channelType == _vm.passChannelType ?'machine-game-active':'',on:{"click":function($event){_vm._updatedSelectedBtn(_vm.defineOrder()[1])}}},[_c('p',{staticClass:"machine-button-name"},[_c('img',{staticClass:"image-klaklouk",attrs:{"src":_vm.findImgApongButton(1),"alt":""}}),_c('br'),_c('span',{staticClass:"font-apong-ccl apong-mobile"},[_vm._v(" "+_vm._s(_vm.passBetType[0] && _vm.passBetType[0].payout === '4' ? '1:4' : '1:4')+" ")])])]),_c('div',{staticClass:"machine-klaklouk-button mt-3 pt-0 machine-play-green-bg",class:_vm.getSelectedApongBtn && _vm.getSelectedApongBtn.button && _vm.getSelectedApongBtn.button.name ==_vm.defineOrder()[4].name 
      && _vm.getSelectedApongBtn.channelType == _vm.passChannelType ?'machine-game-active':'',on:{"click":function($event){_vm._updatedSelectedBtn(_vm.defineOrder()[4])}}},[_c('p',{staticClass:"machine-button-name"},[_c('img',{staticClass:"image-klaklouk",attrs:{"src":_vm.findImgApongButton(4),"alt":""}}),_c('br'),_c('span',{staticClass:"font-apong-ccl apong-mobile"},[_vm._v(_vm._s(_vm.passBetType[1] && _vm.passBetType[1].payout === '4' ? '1:4' : '1:4'))])])])]),_c('v-spacer'),_c('div',{staticClass:"machine-baccarat-three-section machine-blue-title text-center"},[_c('div',{staticClass:"machine-klaklouk-button pt-0 machine-play-green-bg",class:_vm.getSelectedApongBtn && _vm.getSelectedApongBtn.button && _vm.getSelectedApongBtn.button.name ==_vm.defineOrder()[2].name 
      && _vm.getSelectedApongBtn.channelType == _vm.passChannelType ?'machine-game-active':'',on:{"click":function($event){_vm._updatedSelectedBtn(_vm.defineOrder()[2])}}},[_c('p',{staticClass:"machine-button-name"},[_c('img',{staticClass:"image-klaklouk",attrs:{"src":_vm.findImgApongButton(2),"alt":""}}),_c('br'),_c('span',{staticClass:"font-apong-ccl apong-mobile"},[_vm._v(_vm._s(_vm.passBetType[6] && _vm.passBetType[6].payout === '4' ? '1:4' : '1:4'))])])]),_c('div',{staticClass:"machine-klaklouk-button mt-3 pt-0 machine-play-green-bg",class:_vm.getSelectedApongBtn && _vm.getSelectedApongBtn.button && _vm.getSelectedApongBtn.button.name ==_vm.defineOrder()[5].name 
      && _vm.getSelectedApongBtn.channelType == _vm.passChannelType ?'machine-game-active':'',on:{"click":function($event){_vm._updatedSelectedBtn(_vm.defineOrder()[5])}}},[_c('p',{staticClass:"machine-button-name"},[_c('img',{staticClass:"image-klaklouk",attrs:{"src":_vm.findImgApongButton(5),"alt":""}}),_c('br'),_c('span',{staticClass:"font-apong-ccl apong-mobile"},[_vm._v(_vm._s(_vm.passBetType[4] && _vm.passBetType[4].payout === '4' ? '1:4' : '1:4'))])])])]),_c('v-spacer'),_c('div',{staticClass:"machine-baccarat-three-section machine-blue-title text-center"},[_c('div',{staticClass:"machine-klaklouk-button pt-0 machine-play-green-bg",class:_vm.getSelectedApongBtn && _vm.getSelectedApongBtn.button && _vm.getSelectedApongBtn.button.name ==_vm.defineOrder()[3].name 
      && _vm.getSelectedApongBtn.channelType == _vm.passChannelType ?'machine-game-active':'',on:{"click":function($event){_vm._updatedSelectedBtn(_vm.defineOrder()[3])}}},[_c('p',{staticClass:"machine-button-name"},[_c('img',{staticClass:"image-klaklouk",attrs:{"src":_vm.findImgApongButton(3),"alt":""}}),_c('br'),_c('span',{staticClass:"font-apong-ccl apong-mobile"},[_vm._v(_vm._s(_vm.passBetType[7] && _vm.passBetType[7].payout === '4' ? '1:4' : '1:4'))])])]),_c('div',{staticClass:"machine-klaklouk-button mt-3 pt-0 machine-play-green-bg",class:_vm.getSelectedApongBtn && _vm.getSelectedApongBtn.button && _vm.getSelectedApongBtn.button.name ==_vm.defineOrder()[6].name 
      && _vm.getSelectedApongBtn.channelType == _vm.passChannelType ?'machine-game-active':'',on:{"click":function($event){_vm._updatedSelectedBtn(_vm.defineOrder()[6])}}},[_c('p',{staticClass:"machine-button-name"},[_c('img',{staticClass:"image-klaklouk",attrs:{"src":_vm.findImgApongButton(6),"alt":""}}),_c('br'),_c('span',{staticClass:"font-apong-ccl apong-mobile"},[_vm._v(_vm._s(_vm.passBetType[2] && _vm.passBetType[2].payout === '4' ? '1:4' : '1:4'))])])])])],1),_c('CockXCasinoSubmitButton',{attrs:{"passChannelType":_vm.passChannelType,"selectedSubmit":'selectedSubmitApong'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }