<template>
  <!-- eslint-disable -->
  <div>
    <v-btn 
      @click="print(current._id)"
      x-small class="btn btn-info w-25 pa-3" color="btn-green"
      id="print-button"
    >
    <img :src="printing" class="w-25 text-left"/>
    </v-btn >
    <table :id="`printMe${current._id}`" hidden>
      <tbody>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            sbc2888
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            Bet ID: {{ current._id }}
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.fight')}}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ current.fight_no ? current.fight_no : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.type')}}</td>
                  <td style="padding: 5px; text-align: left">
                    {{ current.betting ? $t('title.'+current.betting) : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.date')}}</td>
                  <td style="padding: 5px; text-align: left">
                    {{current.time}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.cash')}}</td>
                  <td style="padding: 5px; text-align: left">
                    <span> {{ formatMoney(current.bet_amount)+' x ' + current.payout + '= ???'}}</span>
                  </td>
                </tr>
                <!-- <tr>
                  <td style="padding: 5px; text-align: left">Win:</td>
                  <td style="padding: 5px; text-align: left">
                    {{
                      current.amount_win || current.amount_win == 0
                        ? parseInt(current.amount_win)toLocaleString()
                        : "???"
                    }}
                  </td>
                </tr> -->
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
      </tbody>
    </table>
  </div>
</template>

<script>
import clickMixin from "~mixin/MX_ShareFunctionCollection.js"
export default {
  props: ["current"],
  data() {
        return {
            mixins: [clickMixin],
        }
    },
    computed:{
      printing(){
        return require("/public/images/a_page_access_global/printing.png")
      }
    },
  methods: {
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(`printMe${id}`);
    },
  },
};
</script>
