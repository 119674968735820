export default {
    search: 'ស្វែងរក',
    permission_name: 'ឈ្មោះអនុញ្ញាត',
    role_name: 'ឈ្មោះតួនាទី',
    menu_name: 'ឈ្មមីនុយ',
    description: 'ពិពណ៌នា',
    order: 'លំដាប់',
    route_name: 'ឈ្មោះផ្លូវ (Route)',
    url_name: 'ឈ្មោះដំណរភ្ជាប់',
    icon: 'រូបភាព',
    parent: 'ដំណរមេ (Parent)',
    permission: 'ការអនុញ្ញាត',
    visible: 'ដែល​អាច​មើលឃើញ',
    user_name: 'ឈ្មោះអ្នកប្រើប្រាស់',
    bank_account: 'គណនីធនាគារ',
    user_role: 'តួនាទីអ្នកប្រើប្រាស់',
    password: 'ពាក្យសម្ងាត់',
};
