<template>
  <div>
    <v-row class="ma-0 w-100 d-flex">      
      
      <div class="machine-dragon-three-section machine-blue-title flex-grow-1 machine-play-blue-bg" 
        style="width: 100% !important; max-width: 100% !important;"
       :class="getSelectedLottoBtn && getSelectedLottoBtn.button && getSelectedLottoBtn.button.name == passBetType[0].name && getSelectedLottoBtn.channelType == passChannelType ?'machine-game-active':''" 
       @click="_updatedSelectedBtn(passBetType[0])"> 
        <p class="machine-payout-name machine-button-font">
          {{passBetType[0] ? passBetType[0].payout : '0.95'}}
          <br/>
          <span class="machine-button-name cock-casino-button-font fantan-blue white--text">
            {{passBetType[0] && passBetType[0].name.toUpperCase() === 'SMALL' ? $t('title.small') : 'NA'}}
          </span>
          <br/>
          <span class="font-small">
            {{passBetType[0] ? passBetType[0].start_number + "-" + passBetType[0].end_number : '0'}}
          </span>
        </p>
      </div>
      
      <!-- Custom Spacer -->
      <div style="width: 10px;"> <!-- Adjust width as needed -->
      </div>

      <div class="machine-dragon-three-section machine-green-title flex-grow-1 machine-play-red-bg"
        style="width: 100% !important; max-width: 100% !important; "
       :class="getSelectedLottoBtn && getSelectedLottoBtn.button && getSelectedLottoBtn.button.name == passBetType[5].name && getSelectedLottoBtn.channelType == passChannelType?'machine-game-active':''" 
       @click="_updatedSelectedBtn(passBetType[5])"> 
        <p class="machine-payout-name machine-button-font ">
          {{passBetType[5] ? passBetType[5].payout : '0.95'}}
          <br/>
          <span class="machine-button-name cock-casino-button-font fantan-red white--text">
            {{passBetType[5] && passBetType[5].name.toUpperCase() === 'LARGE' ?  $t('title.large') : 'NA'}}
          </span>
          <br/>
          <span class="font-small">
            {{passBetType[5] ? passBetType[5].start_number + "-" + passBetType[5].end_number : '0'}}
          </span>
        </p>
      </div>

    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitLotto'"/>
  </div>
</template>
<script>
import {mapGetters, mapMutations } from "vuex";
import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"

export default {
  components: {
    CockXCasinoSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  computed:{
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedLottoBtn: "getSelectedLottoBtn",
    }),
  },

  methods:{
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_LOTTO_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_LOTTO_BUTTON_IN_ALL",
    ]),
  }
}
</script>

<style>
  .font-small {
    font-size: small;
  }
</style>