<template>
    <div class="row ma-0 mt-2 justify-center">
        <input  class="machine-input-amount"  
        :value="getBettingAmount"
        type="number"
        inputmode="numeric"
        pattern="[0-9]*"
        placeholder="ENTER BET AMOUNT"            
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        @input="(event) => inputAmount(event.target.value)">
        <div class="machine-clear-amount" @click="CLEAR_BETTING_AMOUNT">
            <v-icon color="#fd1416">mdi-window-close</v-icon>
        </div>
    </div>
</template>
<script>
    
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
  },
  data() {
    return {
        amount:0
    }
  },
  
  computed: {
    ...mapGetters("$_dashboard", {
      getButton: "getButton",
    }),
    ...mapGetters("$_modules", {
      getBettingAmount: "getBettingAmount",
    }),
  },
  methods:{
    inputAmount(amount) {
      this.INPUT_BETTING_AMOUNT(amount);
    },
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT",
    ]),
  }
  }
</script>