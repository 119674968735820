import DashboardLive from '../../../modules/FolderGames/CasinoLive';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/casino',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: DashboardLive,
                    name: 'casino'
                }
            ]
    }
];

export default routes;