<template>
  <div class="lottery3d-right">
    <lottery-info  />
    <lottery-betting class="w-100" />
    <ResultsStatement />
  </div>
</template>

<script>

import ResultsStatement from "./SubComponent/RightComponents/ResultsStatement.vue";
import LotteryInfo from "./SubComponent/RightComponents/LotteryInfo.vue";
import LotteryBetting from "./SubComponent/RightComponents/LotteryBetting.vue";
export default {
  components: {
    ResultsStatement,
    LotteryInfo,
    LotteryBetting
  },
  data() {
    return {
    };
  },
};
</script>
