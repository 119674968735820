import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    lastResults:[],
    currentBetting:[],
    previousBetting:[],
    currentGame:[],
    rangeType:[],
    headTailType:[],
    lastOneResult:[],
    lastTwentyResult:[],
    is_Betting : false,
    buttonMoney:[],
    lastFiveResults:[],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
