import LayoutAuth from '@/layouts/login.vue';
import login from '../../../modules/FolderAccounts/login';

const routes = [
    {
        path: '',
        component: LayoutAuth,
        children: [
            {
                path: 'login',
                name: 'login',
                component: login
            }
        ]
    }
]

export default  routes ;