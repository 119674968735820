<template>
  <v-container id="main">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-img lazy-src="images/dt/bg.jpg" src="images/a_each_game/dragon/Dragon-Tiger.jpg">
          <div class="rx-kl-min-max-td" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/img/Asset.png' + '\')' }">
            <div class="rx-kl-min-max-box">
              <div class="rx-kl-game" v-if="channelObj.fight_no">
                <p>#{{ channelObj.fight_no }}</p>
              </div>
              <div class="rx-kl-min-dt" v-if="amountMin">
                <p>{{ formatMoney(amountMin) }}</p>
              </div>
              <div class="rx-kl-max-dt" v-if="amountMax">
                <p>{{ formatMoney(amountMax) }}</p>
              </div>
            </div>
          </div>
          <div class="rx-close-open-box p-0" v-if="getClientTime"
            :class="getClientTime.is_open_betting == true ? 'rx-close-open-box-green' : 'rx-close-open-box-red'">
            <img :src="getClientTime.is_open_betting ? spinningAllow : spinningNotAllow" v-if="getClientTime.time"
              style="width:100%; height:auto; padding :0px" alt="">
            <div v-if="getClientTime.time"
              style="width: 100%; height: 100%; position: absolute; top:0px; display : flex;align-items : center;"><span
                :style="!getClientTime.is_open_betting ? 'color:#e15b64' : ''">{{ getClientTime.time }}</span></div>
            <div v-else
              style="width: 100%; height: 100%; position: absolute; top:0px; display : flex;align-items : center;">
              <span><img :src="spinningClose" style="width:100%; height:auto; padding :0px" alt=""> </span>
            </div>
          </div>


          <div id="main">
            <div class="td-result-l" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/dragon/round-result.png' + '\')' }">
              <div class="td-resul-open-box">
                <div class="td-resul-open-item">

                </div>
              </div>
            </div>

            <div class="td-result-r" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/dragon/round-result.png' + '\')' }">

              <div class="td-resul-open-box">
                <div class="td-resul-open-item">

                </div>
              </div>
            </div>

            <div class="x-td-main">
              <div class="x-td-main-body">

                <div class="td-card-result-l-min "></div>

                <div class="td-card-result-r-min "></div>

                <div class="td-win-lose-result-x td-x-hidden">

                </div>

              </div>

            </div>


            <div class="td-case-x">
              <div class="td-case-f td-x-hidden"
                :style="{ backgroundImage: 'url(\'' + 'images/a_game_access_global/cards/back_card.png' + '\')' }">
                <img id="f-result-x">
              </div>
              <div class="td-case">
              </div>
            </div>

            <v-row justify="center" style="position: absolute;left:20%;transform: translate(-50%, -50%);top:54%;">
              <div id="computerHand">
              </div>
            </v-row>
          </div>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>

import $ from "jquery";
import { mapGetters } from "vuex";
import clickMixin from "~mixin/MX_ShareFunctionCollection.js";

export default {
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s |  DRAGON TIGER`
    },
  data() {
    return {
      message: "Hello world",
      cardsDeck: [],
      mixins: [clickMixin],
    }
  },

  watch: {
    getFirstCard: function () {
      if (this.getFirstCard && this.getFirstCard.barcode) {
        this.dtStopResult();
        this._playSoundTakeCard();
        this.__getFirstCard(require("~public_image/a_game_access_global/cards_big/" + this.getFirstCard.barcode + '.png'));
       
      }

    },
    getSecondCard: function () {

      if (this.getSecondCard && this.getFirstCard.barcode) {
        
        this.dtStopResult();
        
        this.__xCasetSecondCard(this.getSecondCard.count, require("~public_image/a_game_access_global/cards/back_card.png"))
      }
    },

    getDragonCard: function (newVal, oldV) {
      
      if (newVal != oldV && this.getFirstCard == null && this.getSecondCard == null) {
          
   
          $('.td-card-result-l-min').find('.td-card-result-l').removeClass('td-card-sand-l-start-xz');
          $('.td-card-result-l-min').find('.td-card-result-l').css('background-image', 'url(' + require("~public_image/a_game_access_global/cards_animation/" + this.getDragonCard[0].name + ".gif") + ')');
          this._playSoundOpenCard();
          setTimeout(() => {
            
            $('.td-result-l').find('.td-resul-open-box').css('background-image', 'url(' + require("~public_image/a_each_game/dragon/" + this.getDragonCard[0].value + ".png")  + ')');
            $('.td-result-l').find('.td-resul-open-box').css('background-size', '100% 100%');
            $('.td-result-l').find('.td-resul-open-box').css('width', '50%');
            $('.td-result-l').find('.td-resul-open-box').css('height', '50%');
            $('.td-result-l').find('.td-resul-open-box').css('position', 'absolute');
            $('.td-result-l').find('.td-resul-open-box').css('top', '22%');
            $('.td-result-l').find('.td-resul-open-box').css('left', '22%');
            $('.td-result-l').find('.td-resul-open-box').show();
          },2000)

      }


    },

    getTigerCard: function (newVal, oldV) {
     
      if (newVal != oldV && this.getFirstCard == null && this.getSecondCard == null) {
        
        if(!this.getDragonCard){
       
          require("~public_image/a_each_game/tiger_dragon/Tiger/" + this.getTigerCard[0].value + ".png")

        }
          
          $('.td-card-result-r-min').find('.td-card-result-r').removeClass('td-card-sand-r-start');
          $('.td-card-result-r-min').find('.td-card-result-r').css('background-image', 'url(' + require("~public_image/a_game_access_global/cards_animation/" + this.getTigerCard[1].name + ".gif") + ')');
          //  result box 
          this._playSoundOpenCard();
          setTimeout(() => {
            
            $('.td-result-r').find('.td-resul-open-box').css('background-image', 'url(' + require("~public_image/a_each_game/tiger_dragon/Tiger/" + this.getTigerCard[1].value + ".png"), + ')');
            $('.td-result-r').find('.td-resul-open-box').css('background-size', '100% 100%');
            $('.td-result-r').find('.td-resul-open-box').css('width', '50%');
            $('.td-result-r').find('.td-resul-open-box').css('height', '50%');
            $('.td-result-r').find('.td-resul-open-box').css('position', 'absolute');
            $('.td-result-r').find('.td-resul-open-box').css('top', '22%');
            $('.td-result-r').find('.td-resul-open-box').css('left', '22%');
            $('.td-result-r').find('.td-resul-open-box').show();
          },2000)
        
      }
    },
    getCurrentGameResult: function (newVal, oldV) {
      if (newVal != oldV && this.getFirstCard == null && this.getSecondCard == null) {
        if (this.getCurrentGameResult && this.getCurrentGameResult.co_type_of_betting && this.getCurrentGameResult.co_type_of_betting.name) {
          
          
          if(!this.getTigerCard[1] || !this.getDragonCard[0]){
           
            $('.td-card-result-r-min').html('');
            $('.td-card-result-r-min').append('<div class="td-card-result-r"></div>');
            $('.td-card-result-r-min').find('.td-card-result-r').css('background-image', 'url(' + require("~public_image/a_game_access_global/cards_animation/" + this.getCurrentGameResult.card_tiger.barcode + ".gif") + ')');
            $('.td-result-r').find('.td-resul-open-box').css('background-image', 'url(' + require("~public_image/a_each_game/tiger_dragon/Tiger/" + this.getCurrentGameResult.card_tiger.value + ".png"), + ')');
            $('.td-result-r').find('.td-resul-open-box').css('background-size', '100% 100%');
            $('.td-result-r').find('.td-resul-open-box').css('width', '50%');
            $('.td-result-r').find('.td-resul-open-box').css('height', '50%');
            $('.td-result-r').find('.td-resul-open-box').css('position', 'absolute');
            $('.td-result-r').find('.td-resul-open-box').css('top', '22%');
            $('.td-result-r').find('.td-resul-open-box').css('left', '22%');
            $('.td-result-r').find('.td-resul-open-box').show();
            $('.td-card-result-l-min').html('');
            $('.td-card-result-l-min').append('<div class="td-card-result-l"></div>');
            $('.td-card-result-l-min').find('.td-card-result-l').css('background-image', 'url(' + require("~public_image/a_game_access_global/cards_animation/" + this.getCurrentGameResult.card_dragon.barcode + ".gif") + ')');
            $('.td-result-l').find('.td-resul-open-box').css('background-image', 'url(' + require("~public_image/a_each_game/dragon/" + this.getCurrentGameResult.card_dragon.value + ".png")  + ')');
            $('.td-result-l').find('.td-resul-open-box').css('background-size', '100% 100%');
            $('.td-result-l').find('.td-resul-open-box').css('width', '50%');
            $('.td-result-l').find('.td-resul-open-box').css('height', '50%');
            $('.td-result-l').find('.td-resul-open-box').css('position', 'absolute');
            $('.td-result-l').find('.td-resul-open-box').css('top', '22%');
            $('.td-result-l').find('.td-resul-open-box').css('left', '22%');
            $('.td-result-l').find('.td-resul-open-box').show();
          }
          if (this.getCurrentGameResult.co_type_of_betting.name == "DRAGON") {
            this._xsetResultX(require("~public_image/a_each_game/dragon/DragonWin.png"));
            this._playSoundDorgerWin();
          
          }
          if (this.getCurrentGameResult.co_type_of_betting.name == "TIGER") {
            this._xsetResultX(require("~public_image/a_each_game/dragon/TigerWin.png"));
            this._playSoundTigerWin();
            
          }
          if (this.getCurrentGameResult.co_type_of_betting.name == "TIE") {
            this._xsetResultX(require("~public_image/a_each_game/tiger_dragon/Tie.png"));
            this._playSoundTieGame();
            
          }
          let myintervalstop = setInterval(() => {
            this.dtStartCurrentGameResult();
            clearInterval(myintervalstop)
          }, 1000)
        }
      }
    },
    getBackCard: function (newVal) {
      if(newVal){
        
        this.__clearCase();
        this.dtStopResult();
        setTimeout(() => {
          this._playSoundTakeCard();
          this._xgetTigerCard(require("~public_image/a_game_access_global/cards/back_card.png"))   },1500)
          this._xgetDragonCard(require("~public_image/a_game_access_global/cards/back_card.png"))
          this._playSoundTakeCard();
         
    }
      
    },
  },

  computed: {
    spinningAllow() {
      return require("/public/images/spinning-allow.svg")
    },
    spinningNotAllow() {
      return require("/public/images/spinning-not-allow.svg")
    },
    spinningClose() {
      return require("/public/images/spinning-close.svg")
    },
    ...mapGetters("$_modules", {
      getBettingAmount: "getBettingAmount"
    }
    ),
    ...mapGetters("$_dashboard_live", {
      channelObj: "getChannel",
      getClientTime: "getClientTime",
      getCurrentGameResult: "getCurrentGameResult",
      getClientRandom: "getClientRandom",
      amountMin: "getAmountMin",
      amountMax: "getAmountMax",
      getDragonCard: "getDragonCard",
      getTigerCard: "getTigerCard",
      getFirstCard: "getFirstCard",
      getSecondCard: "getSecondCard",
      getBackCard:"getBackCard",
      
    })
  },
  mounted(){

      $('.td-card-result-r-min').html('');
      $('.td-card-result-r-min').append('<div class="td-card-result-r"></div>');
      $('.td-card-result-r-min').find('.td-card-result-r').css('background-image', 'url(' + require("~public_image/a_game_access_global/cards/back_card.png") + ')');
      $('.td-card-result-l-min').html('');
      $('.td-card-result-l-min').append('<div class="td-card-result-l"></div>');
      $('.td-card-result-l-min').find('.td-card-result-l').css('background-image', 'url(' + require("~public_image/a_game_access_global/cards/back_card.png") + ')');
   
  },

  methods: {
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      for (var index = this.passLastTwentyResults.length - 1; index >= 0; index--) {
        const item = this.passLastTwentyResults[index];
        if (index == this.passLastTwentyResults.length - 1) {
          newResult.push({
            ...item,
            row,
            col,
          });
        } else {
          const pre = newResult[newResult.length - 1];
          if (pre.small_large.name != item.small_large.name) {
            newResult.push({
              ...item,
              row: 1,
              col: pre.col + 1,
            });
          } else {
            if (pre.row >= 6) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              newResult.push({
                ...item,
                row: pre.row + 1,
                col: pre.col,
              });
            }
          }
        }
      }
      return newResult;
    },
    __clearCase() {
      $('#f-result-x').hide();
      $('.td-case-f').addClass('td-x-hidden');
      $('.td-case').html('');
    },
    __getFirstCard(x = null) {
      $('#f-result-x').hide();
      $('.td-case-f').removeClass('td-x-hidden');
      $('.td-case-f').addClass('td-case-f-a');
      var clearCard = function () {
        $('.td-case-f').removeClass('td-case-f-a');
        $('#f-result-x').attr('src', x);
        $('#f-result-x').show(100);
      }
      setTimeout(clearCard, 500);
    },
    __xCasetSecondCard(x = null, y = null) {
      this.dtStopResult();
      if(this.getBackCard) this.__clearCase();
      $('.td-case').html('');
      $('.td-case-x').css('display', 'block');
      var i = 1,
        action = function () {
          var rxc = 'xrxkf' + (x.toString()) + ((i - 1).toString());
          var rxca = 'xrxkf' + (x.toString()) + ((i - 1).toString()) + '-a';
          var xc = 'xrxkf' + (x.toString()) + (i.toString());
          var xca = 'xrxkf' + (x.toString()) + (i.toString()) + '-a';
          $('.td-case').find('.' + rxc).removeClass(rxca);
          $('.td-case').append('<div class="' + xc + '"></div>');
          $('.td-case').find('.' + xc).css("background-image", "url(" + y + ")");
          $('.td-case').find('.' + xc).addClass(xca);
          let audio = new Audio("/sound/take_card.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
          i++;
          if (i <= (x + 1)) {
          
            setTimeout(action, 400);
            
          }
          
        };
      action();
    },
    _xsetResultX(x = null) {
      $('.td-win-lose-result-x').css('background-image', 'url(' + x + ')');
    },
    _xgetDragonCard(z=null) {
      $('.td-card-result-l-min').html('');
      $('.td-card-result-l-min').append('<div class="td-card-result-l"></div>');
      $('.td-card-result-l-min').find('.td-card-result-l').css('background-image', 'url(' + z + ')');
      $('.td-card-result-l-min').find('.td-card-result-l').addClass('td-card-sand-l-start-xz');

    },
    _xgetTigerCard(z=null) {
      $('.td-card-result-r-min').html('');
      $('.td-card-result-r-min').append('<div class="td-card-result-r"></div>');
      $('.td-card-result-r-min').find('.td-card-result-r').css('background-image', 'url(' + z + ')');
      $('.td-card-result-r-min').find('.td-card-result-r').addClass('td-card-sand-r-start');
      // setTimeout(function () {
          
      //     // opencard
      //     // $('.td-card-result-r-min').find('.td-card-result-r').removeClass('td-card-sand-r-start');
      //     // $('.td-card-result-r-min').find('.td-card-result-r').css('background-image', 'url(' + x + ')');

      //     setTimeout(function () {
      //       console.log(y,x )
      //     //  result box 
      //       // $('.td-result-r').find('.td-resul-open-box').css('background-image', 'url(' + y + ')');
      //       $('.td-result-r').find('.td-resul-open-box').css('background-size', '100% 100%');
      //       $('.td-result-r').find('.td-resul-open-box').css('width', '50%');
      //       $('.td-result-r').find('.td-resul-open-box').css('height', '50%');
      //       $('.td-result-r').find('.td-resul-open-box').css('position', 'absolute');
      //       $('.td-result-r').find('.td-resul-open-box').css('top', '22%');
      //       $('.td-result-r').find('.td-resul-open-box').css('left', '22%');
      //       $('.td-result-r').find('.td-resul-open-box').show();

      //     }, 1200);

      //   }, 2000);
      
    },
    dtStopResult() {
      $('.td-card-result-r-min').html('');
      $('.td-result-r').find('.td-resul-open-box').hide();
      $('.td-card-result-l-min').html('');
      $('.td-result-l').find('.td-resul-open-box').hide();
      $('.td-win-lose-result-x').hide();

    },

    dtStartCurrentGameResult() {
      //$('.td-win-lose-result-x').removeClass("td-x-hidden");
      $('.td-win-lose-result-x').show();
    },






  }
}
</script>

<style>
.rx-close-open-box {
  position: absolute;
  right: 2%;
  top: 1%;
  width: 11%;
  height: 19%;
  text-align: center;
  border-radius: 100%;

}

.rx-close-open-box-green {
  background-color: rgba(8, 187, 8, 0.401);
}

.rx-close-open-box-red {
  background-color: rgba(255, 0, 0, 0.30);
}

#blockT {
  position: relative;
  top: 153px;
}

/* the Div container for the table img */
#main {
  margin: 0;
  padding: 0;

}

#mainTable {
  z-index: 1;
}

.cards {
  display: inline-block;
}

#cardsDealingComputer,
#cardsDealingUser {
  position: absolute;
  top: 50%;
  left: 20%;
}

#GFX01 {
  transform: rotate(70deg);
  -webkit-transform: rotate(70deg);
  position: absolute;
  left: 100px;
  top: -280px;
  transition: all 0.5s ease-in;
}

#GFX01.effect01 {
  transform: rotate(500deg);
  -webkit-transform: rotate(500deg);
  top: -120px;
  left: -300px;
}

#GFX03 {
  transform: rotate(70deg);
  -webkit-transform: rotate(70deg);
  position: absolute;
  left: 30px;
  top: -280px;
  transition: all 0.5s ease-in;
}

#GFX03.effect03 {
  transform: rotate(500deg);
  -webkit-transform: rotate(500deg);
  top: -120px;
  left: 300px;
}

/* #blockWin{
    z-index: 4;
    width: 224px;
    left: -102px;
    position: absolute;
    top: 32px;
} */

/* computer hand */
#computerHand {
  z-index: 2;
  position: absolute;
  left: 20%;
  top: 50%;
}

#computerCard01,
#computerCard02 {
  visibility: visible;
  z-index: 1;
  position: -webkit-sticky !important;
}

/* computer block cards from the user */
#blockCards {
  z-index: 3;
  position: absolute;
  top: 0px;
}

/* user hand */
#userHand {
  z-index: 2;
  position: absolute;
  left: 315px;
  top: 300px;
}

/* user action button option */
#userAction01 {
  position: absolute;
  top: 450px;
  left: 245px;
}

#userAction02,
#userAction03,
#userAction04 {
  position: absolute;
  top: 450px;
  left: 170px;
}

.userButton {
  -moz-box-shadow: inset 0px 1px 0px 0px #bbdaf7;
  -webkit-box-shadow: inset 0px 1px 0px 0px #bbdaf7;
  box-shadow: inset 0px 1px 0px 0px #bbdaf7;
  background-color: #79bbff;
  -webkit-border-top-left-radius: 15px;
  -moz-border-radius-topleft: 15px;
  border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-radius-topright: 15px;
  border-top-right-radius: 15px;
  -webkit-border-bottom-right-radius: 15px;
  -moz-border-radius-bottomright: 15px;
  border-bottom-right-radius: 15px;
  -webkit-border-bottom-left-radius: 15px;
  -moz-border-radius-bottomleft: 15px;
  border-bottom-left-radius: 15px;
  text-indent: 0;
  border: 1px solid #84bbf3;
  display: inline-block;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  height: 65px;
  line-height: 65px;
  width: 131px;
  text-decoration: none;
  text-align: center;
  text-shadow: 1px 1px 0px #528ecc;
  margin: 5px;
}

.userButton:hover {
  background-color: #843534;
  border-color: #843534;
  text-decoration: none;
  color: white;
}

.userButton:active {
  position: relative;
  top: 1px;
  text-decoration: none;
}

/* mobile */

#blockDMobile {
  position: relative;
  left: -117px;
  top: 62px;
  width: 20px;
}

#blockTMobile {
  position: relative;
  right: -175px;
  top: 62px;
  width: 20px;
}

/* the Div container for the table img */
#mainMobile {
  margin: auto;
  width: 100px;
  top: 0px;
  position: relative;
}


/* computer hand */
#computerHandMobile {
  z-index: 2;
  position: absolute;
  left: 15px;
  top: -20px;
}

#cardsDealingComputerMobile,
#cardsDealingUserMobile {
  position: absolute;
  top: -50px;
  left: 20px;
}

#computerCard01Mobile,
#computerCard02Mobile {
  visibility: visible;
  z-index: 1;
}

/* computer block cards from the user */
#blockCardsMobile {
  z-index: 3;
  position: absolute;
  top: 0px;
}


/* user hand */
#userHandMobile {
  z-index: 2;
  position: absolute;
  left: 15px;
  top: 10px;
}

/* user action button option */
#userAction01Mobile {
  position: absolute;
  top: -50px;
  left: 45px;
}

#userAction02Mobile,
#userAction03Mobile,
#userAction04Mobile {
  position: absolute;
  top: 0px;
  left: 10px;
}

.dragonCard {
  position: absolute;
  left: -290%;
  width: 100px;
}

.tigerCard {
  width: 100px;
  position: relative;
  left: 117%;

}

.dragonCardMobile {
  position: relative;
  left: -80px;
  width: 55px;
  top: 100px
}

.tigerCardMobile {
  position: absolute;
  left: 50%;
  width: 55px;
  top: 100px
}

#GFX01Mobile {
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
  position: absolute;
  left: 20px;
  top: 75px;
  width: 55px;
  transition: all 0.5s ease-in;
}

#GFX01Mobile.effect04 {
  transform: rotate(500deg);
  -webkit-transform: rotate(500deg);
  top: 200px;
  left: -180px;
}


#GFX03Mobile {
  transform: rotate(70deg);
  -webkit-transform: rotate(70deg);
  position: absolute;
  left: 20px;
  top: 75px;
  width: 55px;
  transition: all 0.5s ease-in;
}

#GFX03Mobile.effect05 {
  transform: rotate(500deg);
  -webkit-transform: rotate(500deg);
  top: 200px;
  left: 200px;
}

#blockWinMobile {
  position: absolute;
  left: -5px;
  width: 80px;
  top: 120px;

}

.custom-repeat {
  --animate-repeat: 5;
}

/*==custom*/
.x-td-main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.x-td-main-body {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

}


.rx-kl-min-max-td {
  position: absolute;
  top: 1%;
  left: 0.80%;
  width: 14%;
  height: 14.9%;
  background-size: 100% 100%;
}


.rx-kl-min-max-td .rx-kl-game {
  position: absolute;
  top: 36%;
  left: 69%;
  font-weight: 600;
  color: white;
  text-shadow: 0px .7px rgb(22, 5, 5);
}

.rx-kl-min-max-td .rx-kl-game p {
  font-size: 0.8vw;
}

.rx-kl-min-dt {
  position: absolute;
  top: 38%;
  left: 35%;
  width: 17%;
  height: 17.9%;
  background-size: 100% 100%;
  font-weight: 900;
  color: goldenrod;
  text-shadow: 0px .5px rgb(22, 5, 5);
}

.rx-kl-min-max-dt {
  position: absolute;
  top: 1%;
  left: 0.80%;
  width: 17%;
  height: 17.9%;
  background-size: 100% 100%;

}

.rx-kl-max-dt {
  position: absolute;
  top: 67%;
  left: 35%;
  width: 17%;
  height: 17.9%;
  background-size: 100% 100%;
  font-weight: 900;
  color: goldenrod;
  text-shadow: 0px .5px rgb(22, 5, 5);
}

.rx-kl-min-dt p {
  font-size: 0.7vw;
}

.rx-kl-max-dt p {
  font-size: 0.7vw;
}





.td-result-l {
  position: absolute;
  top: 30%;
  left: 3%;
  width: 10%;
  height: 17%;
  background-size: 100% 100%;
}

.td-result-r {
  position: absolute;
  top: 30%;
  right: 3%;
  width: 10%;
  height: 17%;
  background-size: 100% 100%;
}

.td-resul-open-box {
  position: relative;
  width: 100%;
  height: 100%;

}
.td-card-result-l {
  position: absolute;
  top: 38%;
  left: 21%;
  width: 17%;
  height: 41%;
  background-size: 100% 100%;
}
.td-card-result-r {
  position: absolute;
  top: 38%;
  right: 21%;
  width: 17%;
  height: 41%;
  background-size: 100% 100%;

}
.td-x-hidden {
  display: none;
}

.td-card-sand-l-start-xz {
  animation: lkeyframeszzzz 2s alternate;
}
.td-card-sand-r-start {
  animation: rkeyframesaaa 2s alternate;
}
@keyframes lkeyframeszzzz {
  from {
    top: 0.5%;
    left: 86%;

  }
  to {
    top: 36%;
    left: 21%;

  }
}
@keyframes rkeyframesaaa {
  from {
    top: 0.5%;
    left: 86%;

  }
  to {

    left: 63%;
    top: 36%;
  }
}


.td-win-lose-result-x {

  position: absolute;
  width: 22%;
  height: 9%;
  left: 39%;
  top: 50%;
  background-size: 100% 100%;

}

.dt-card-boot-xz {
  position: absolute;
  width: 16%;
  height: 37%;
  background-size: 100% 100%;
  right: 0;
  top: -11%;

}



/*add new code*/



.td-case-x {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.td-case {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.td-case-f {
  position: absolute;
  top: 22%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.td-case-f img {
  width: 100%;
  height: 100%;
}

.td-case-f-a {
  animation: rxkfxx 2s infinite;
}

@keyframes rxkfxx {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 22%;
    left: 46%;
    width: 8%;
    height: 18%;

  }
}


.xrxkf11 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf11-a {
  animation: rxkf11 2s infinite;
}

@keyframes rxkf11 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;

  }
}

.xrxkf21 {
  position: absolute;
  top: 41.4%;
  left: 41%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf21-a {
  animation: rxkf21 2s infinite;
}

@keyframes rxkf21 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 41%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf22 {
  position: absolute;
  top: 41.4%;
  left: 51%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf22-a {
  animation: rxkf22 2s infinite;
}

@keyframes rxkf22 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 51%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf31 {
  position: absolute;
  top: 41.4%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf31-a {
  animation: rxkf31 2s infinite;
}

@keyframes rxkf31 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf32 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf32-a {
  animation: rxkf32 2s infinite;
}

@keyframes rxkf32 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf33 {
  position: absolute;
  top: 41.4%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf33-a {
  animation: rxkf33 2s infinite;
}

@keyframes rxkf33 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf41 {
  position: absolute;
  top: 41.4%;
  left: 31%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf41-a {
  animation: rxkf41 2s infinite;
}

@keyframes rxkf41 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 31%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf42 {
  position: absolute;
  top: 41.4%;
  left: 41%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf42-a {
  animation: rxkf42 2s infinite;
}

@keyframes rxkf42 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 41%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf43 {
  position: absolute;
  top: 41.4%;
  left: 51%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf43-a {
  animation: rxkf43 2s infinite;
}

@keyframes rxkf43 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 51%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf44 {
  position: absolute;
  top: 41.4%;
  left: 61%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf44-a {
  animation: rxkf44 2s infinite;
}

@keyframes rxkf44 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 61%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf51 {
  position: absolute;
  top: 41.4%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf51-a {
  animation: rxkf51 2s infinite;
}

@keyframes rxkf51 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf52 {
  position: absolute;
  top: 41.4%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf52-a {
  animation: rxkf52 2s infinite;
}

@keyframes rxkf52 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf53 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf53-a {
  animation: rxkf53 2s infinite;
}

@keyframes rxkf53 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf54 {
  position: absolute;
  top: 41.4%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf54-a {
  animation: rxkf54 2s infinite;
}

@keyframes rxkf54 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf55 {
  position: absolute;
  top: 41.4%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf55-a {
  animation: rxkf55 2s infinite;
}

@keyframes rxkf55 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf61 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf61-a {
  animation: rxkf61 2s infinite;
}

@keyframes rxkf61 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf62 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf62-a {
  animation: rxkf62 2s infinite;
}

@keyframes rxkf62 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf63 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf63-a {
  animation: rxkf63 2s infinite;
}

@keyframes rxkf63 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf64 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf64-a {
  animation: rxkf64 2s infinite;
}

@keyframes rxkf64 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf65 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf65-a {
  animation: rxkf65 2s infinite;
}

@keyframes rxkf65 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf66 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf66-a {
  animation: rxkf66 2s infinite;
}

@keyframes rxkf66 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf71 {
  position: absolute;
  top: 41.4%;
  left: 41%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf71-a {
  animation: rxkf71 2s infinite;
}

@keyframes rxkf71 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 41%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf72 {
  position: absolute;
  top: 41.4%;
  left: 51%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf72-a {
  animation: rxkf72 2s infinite;
}

@keyframes rxkf72 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 51%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf73 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf73-a {
  animation: rxkf73 2s infinite;
}

@keyframes rxkf73 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf74 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf74-a {
  animation: rxkf74 2s infinite;
}

@keyframes rxkf74 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf75 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf75-a {
  animation: rxkf75 2s infinite;
}

@keyframes rxkf75 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf76 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf76-a {
  animation: rxkf76 2s infinite;
}

@keyframes rxkf76 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf77 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf77-a {
  animation: rxkf77 2s infinite;
}

@keyframes rxkf77 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf81 {
  position: absolute;
  top: 41.4%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf81-a {
  animation: rxkf81 2s infinite;
}

@keyframes rxkf81 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf82 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf82-a {
  animation: rxkf82 2s infinite;
}

@keyframes rxkf82 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf83 {
  position: absolute;
  top: 41.4%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf83-a {
  animation: rxkf83 2s infinite;
}

@keyframes rxkf83 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf84 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf84-a {
  animation: rxkf84 2s infinite;
}

@keyframes rxkf84 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf85 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf85-a {
  animation: rxkf85 2s infinite;
}

@keyframes rxkf85 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf86 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf86-a {
  animation: rxkf86 2s infinite;
}

@keyframes rxkf86 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf87 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf87-a {
  animation: rxkf87 2s infinite;
}

@keyframes rxkf87 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf88 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf88-a {
  animation: rxkf88 2s infinite;
}

@keyframes rxkf88 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}


.xrxkf91 {
  position: absolute;
  top: 41.4%;
  left: 31%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf91-a {
  animation: rxkf91 2s infinite;
}

@keyframes rxkf91 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 31%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf92 {
  position: absolute;
  top: 41.4%;
  left: 41%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf92-a {
  animation: rxkf92 2s infinite;
}

@keyframes rxkf92 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 41%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf93 {
  position: absolute;
  top: 41.4%;
  left: 51%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf93-a {
  animation: rxkf93 2s infinite;
}

@keyframes rxkf93 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 51%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf94 {
  position: absolute;
  top: 41.4%;
  left: 61%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf94-a {
  animation: rxkf94 2s infinite;
}

@keyframes rxkf94 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 61%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf95 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf95-a {
  animation: rxkf95 2s infinite;
}

@keyframes rxkf95 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf96 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf96-a {
  animation: rxkf96 2s infinite;
}

@keyframes rxkf96 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf97 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf97-a {
  animation: rxkf97 2s infinite;
}

@keyframes rxkf97 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf98 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf98-a {
  animation: rxkf98 2s infinite;
}

@keyframes rxkf98 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf99 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf99-a {
  animation: rxkf99 2s infinite;
}

@keyframes rxkf99 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf101 {
  position: absolute;
  top: 41.4%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf101-a {
  animation: rxkf101 2s infinite;
}

@keyframes rxkf101 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf102 {
  position: absolute;
  top: 41.4%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf102-a {
  animation: rxkf102 2s infinite;
}

@keyframes rxkf102 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf103 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf103-a {
  animation: rxkf103 2s infinite;
}

@keyframes rxkf103 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf104 {
  position: absolute;
  top: 41.4%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf104-a {
  animation: rxkf104 2s infinite;
}

@keyframes rxkf104 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf105 {
  position: absolute;
  top: 41.4%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf105-a {
  animation: rxkf105 2s infinite;
}

@keyframes rxkf105 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf106 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf106-a {
  animation: rxkf106 2s infinite;
}

@keyframes rxkf106 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf107 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf107-a {
  animation: rxkf107 2s infinite;
}

@keyframes rxkf107 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf108 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf108-a {
  animation: rxkf108 2s infinite;
}

@keyframes rxkf108 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf109 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf109-a {
  animation: rxkf109 2s infinite;
}

@keyframes rxkf109 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1010 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1010-a {
  animation: rxkf1010 2s infinite;
}

@keyframes rxkf1010 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf111 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf111-a {
  animation: rxkf111 2s infinite;
}

@keyframes rxkf111 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf112 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf112-a {
  animation: rxkf112 2s infinite;
}

@keyframes rxkf112 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf113 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf113-a {
  animation: rxkf113 2s infinite;
}

@keyframes rxkf113 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf114 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf114-a {
  animation: rxkf114 2s infinite;
}

@keyframes rxkf114 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf115 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf115-a {
  animation: rxkf115 2s infinite;
}

@keyframes rxkf115 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf116 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf116-a {
  animation: rxkf116 2s infinite;
}

@keyframes rxkf116 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf117 {
  position: absolute;
  top: 80.3%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf117-a {
  animation: rxkf117 2s infinite;
}

@keyframes rxkf117 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf118 {
  position: absolute;
  top: 80.3%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf118-a {
  animation: rxkf118 2s infinite;
}

@keyframes rxkf118 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf119 {
  position: absolute;
  top: 80.3%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf119-a {
  animation: rxkf119 2s infinite;
}

@keyframes rxkf119 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1110 {
  position: absolute;
  top: 80.3%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1110-a {
  animation: rxkf1110 2s infinite;
}

@keyframes rxkf1110 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1111 {
  position: absolute;
  top: 80.3%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1111-a {
  animation: rxkf1111 2s infinite;
}

@keyframes rxkf1111 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf121 {
  position: absolute;
  top: 41.4%;
  left: 41%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf121-a {
  animation: rxkf121 2s infinite;
}

@keyframes rxkf121 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 41%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf122 {
  position: absolute;
  top: 41.4%;
  left: 51%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf122-a {
  animation: rxkf122 2s infinite;
}

@keyframes rxkf122 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 51%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf123 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf123-a {
  animation: rxkf123 2s infinite;
}

@keyframes rxkf123 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf124 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf124-a {
  animation: rxkf124 2s infinite;
}

@keyframes rxkf124 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf125 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf125-a {
  animation: rxkf125 2s infinite;
}

@keyframes rxkf125 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf126 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf126-a {
  animation: rxkf126 2s infinite;
}

@keyframes rxkf126 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf127 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf127-a {
  animation: rxkf127 2s infinite;
}

@keyframes rxkf127 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf128 {
  position: absolute;
  top: 80.3%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf128-a {
  animation: rxkf128 2s infinite;
}

@keyframes rxkf128 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf129 {
  position: absolute;
  top: 80.3%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf129-a {
  animation: rxkf129 2s infinite;
}

@keyframes rxkf129 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1210 {
  position: absolute;
  top: 80.3%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1210-a {
  animation: rxkf1210 2s infinite;
}

@keyframes rxkf1210 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1211 {
  position: absolute;
  top: 80.3%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1211-a {
  animation: rxkf1211 2s infinite;
}

@keyframes rxkf1211 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1212 {
  position: absolute;
  top: 80.3%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1212-a {
  animation: rxkf1212 2s infinite;
}

@keyframes rxkf1212 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf131 {
  position: absolute;
  top: 41.4%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;
}

.xrxkf131-a {
  animation: rxkf131 2s infinite;
}

@keyframes rxkf131 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf132 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf132-a {
  animation: rxkf132 2s infinite;
}

@keyframes rxkf132 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf133 {
  position: absolute;
  top: 41.4%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf133-a {
  animation: rxkf133 2s infinite;
}

@keyframes rxkf133 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf134 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf134-a {
  animation: rxkf134 2s infinite;
}

@keyframes rxkf134 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf135 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf135-a {
  animation: rxkf135 2s infinite;
}

@keyframes rxkf135 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf136 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf136-a {
  animation: rxkf136 2s infinite;
}

@keyframes rxkf136 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf137 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf137-a {
  animation: rxkf137 2s infinite;
}

@keyframes rxkf137 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf138 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf138-a {
  animation: rxkf138 2s infinite;
}

@keyframes rxkf138 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf139 {
  position: absolute;
  top: 80.3%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf139-a {
  animation: rxkf139 2s infinite;
}

@keyframes rxkf139 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1310 {
  position: absolute;
  top: 80.3%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1310-a {
  animation: rxkf1310 2s infinite;
}

@keyframes rxkf1310 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1311 {
  position: absolute;
  top: 80.3%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1311-a {
  animation: rxkf1311 2s infinite;
}

@keyframes rxkf1311 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1312 {
  position: absolute;
  top: 80.3%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1312-a {
  animation: rxkf1312 2s infinite;
}

@keyframes rxkf1312 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1313 {
  position: absolute;
  top: 80.3%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1313-a {
  animation: rxkf1313 2s infinite;
}

@keyframes rxkf1313 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf141 {
  position: absolute;
  top: 41.4%;
  left: 31%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf141-a {
  animation: rxkf141 2s infinite;
}

@keyframes rxkf141 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 31%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf142 {
  position: absolute;
  top: 41.4%;
  left: 41%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf142-a {
  animation: rxkf142 2s infinite;
}

@keyframes rxkf142 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 41%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf143 {
  position: absolute;
  top: 41.4%;
  left: 51%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;
}

.xrxkf143-a {
  animation: rxkf143 2s infinite;
}

@keyframes rxkf143 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 51%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf144 {
  position: absolute;
  top: 41.4%;
  left: 61%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf144-a {
  animation: rxkf144 2s infinite;
}

@keyframes rxkf144 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 61%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf145 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf145-a {
  animation: rxkf145 2s infinite;
}

@keyframes rxkf145 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf146 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf146-a {
  animation: rxkf146 2s infinite;
}

@keyframes rxkf146 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf147 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf147-a {
  animation: rxkf147 2s infinite;
}

@keyframes rxkf147 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf148 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf148-a {
  animation: rxkf148 2s infinite;
}

@keyframes rxkf148 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf149 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf149-a {
  animation: rxkf149 2s infinite;
}

@keyframes rxkf149 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1410 {
  position: absolute;
  top: 80.3%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1410-a {
  animation: rxkf1410 2s infinite;
}

@keyframes rxkf1410 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1411 {
  position: absolute;
  top: 80.3%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1411-a {
  animation: rxkf1411 2s infinite;
}

@keyframes rxkf1411 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1412 {
  position: absolute;
  top: 80.3%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1412-a {
  animation: rxkf1412 2s infinite;
}

@keyframes rxkf1412 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1413 {
  position: absolute;
  top: 80.3%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1413-a {
  animation: rxkf1413 2s infinite;
}

@keyframes rxkf1413 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1414 {
  position: absolute;
  top: 80.3%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1414-a {
  animation: rxkf1414 2s infinite;
}

@keyframes rxkf1414 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf151 {
  position: absolute;
  top: 41.4%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf151-a {
  animation: rxkf151 2s infinite;
}

@keyframes rxkf151 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf152 {
  position: absolute;
  top: 41.4%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf152-a {
  animation: rxkf152 2s infinite;
}

@keyframes rxkf152 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf153 {
  position: absolute;
  top: 41.4%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf153-a {
  animation: rxkf153 2s infinite;
}

@keyframes rxkf153 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf154 {
  position: absolute;
  top: 41.4%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf154-a {
  animation: rxkf154 2s infinite;
}

@keyframes rxkf154 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf155 {
  position: absolute;
  top: 41.4%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf155-a {
  animation: rxkf155 2s infinite;
}

@keyframes rxkf155 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 41.4%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf156 {
  position: absolute;
  top: 60.8%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf156-a {
  animation: rxkf156 2s infinite;
}

@keyframes rxkf156 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf157 {
  position: absolute;
  top: 60.8%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf157-a {
  animation: rxkf157 2s infinite;
}

@keyframes rxkf157 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf158 {
  position: absolute;
  top: 60.8%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf158-a {
  animation: rxkf158 2s infinite;
}

@keyframes rxkf158 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf159 {
  position: absolute;
  top: 60.8%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf159-a {
  animation: rxkf159 2s infinite;
}

@keyframes rxkf159 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1510 {
  position: absolute;
  top: 60.8%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1510-a {
  animation: rxkf1510 2s infinite;
}

@keyframes rxkf1510 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 60.8%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}



.xrxkf1511 {
  position: absolute;
  top: 80.3%;
  left: 26%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1511-a {
  animation: rxkf1511 2s infinite;
}

@keyframes rxkf1511 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 26%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1512 {
  position: absolute;
  top: 80.3%;
  left: 36%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1512-a {
  animation: rxkf1512 2s infinite;
}

@keyframes rxkf1512 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 36%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1513 {
  position: absolute;
  top: 80.3%;
  left: 46%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1513-a {
  animation: rxkf1513 2s infinite;
}

@keyframes rxkf1513 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 46%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1514 {
  position: absolute;
  top: 80.3%;
  left: 56%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1514-a {
  animation: rxkf1514 2s infinite;
}

@keyframes rxkf1514 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 56%;
    width: 8%;
    height: 18%;
  }
}

.xrxkf1515 {
  position: absolute;
  top: 80.3%;
  left: 66%;
  width: 8%;
  height: 18%;
  background-size: 100% 100%;

}

.xrxkf1515-a {
  animation: rxkf1515 2s infinite;
}

@keyframes rxkf1515 {
  from {
    top: 0;
    left: 86%;
    width: 3%;
    height: 8%;
  }

  to {
    top: 80.3%;
    left: 66%;
    width: 8%;
    height: 18%;
  }
}
</style>