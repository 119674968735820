<template>
  <v-row no-gutters >
    <div class="lotto-result-between-min content-middle-on-mobi" >
      <v-row no-gutters>
        <v-col cols="12" sm="12" lg="12" md="12" xs="12" class="x-x-lotto-resul-x-1">
          <v-tabs background-color="blue accent-4" dark style="text-align: center" align-tabs="center">
            <v-tabs-slider color="#FFFFFF" style="height:3px !important"></v-tabs-slider>
            <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi mdi-cast </v-icon> -->
              <!-- <i class="bi-coin" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
              <span style="font-size: 33px ;font-family: 'khmer mef1'; font-weight:bold">
                {{ $t("title.result_range") }}
              </span>
            </v-tab>
            <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; font-weight:bold">
                {{ $t("title.result_small_big") }}
              </span>
            </v-tab>
            <v-tab class="g-header">
              <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
              <!-- <i class="bi-clipboard-data" style="font-size: 1.5rem; color: #212427; padding: 5px;"></i> -->
              <span style="font-family: 'khmer mef1'; font-weight:bold">
                {{ $t("title.odd_even") }}
              </span>
            </v-tab>

            <v-tab-item>
              <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
            style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="keno-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="keno-result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderRange" :key="i" class="div-result">
                              <!-- <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn :color="result.range.color" fab dark class="btn-range">
                                  <span class="range-name">
                                    {{ result.range.name }}
                                  </span>
                                </v-btn>
                              </span> -->
                              <span v-if="col == result.col && row == result.row">
                                <div class="trend-item" :class="`keno_${result.range.color}`">
                                  <!-- {{ result.name != 'CANCEL' ? result.fight_no : 'x' }} -->
                                  {{ result.range.name }}
                                </div>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
            style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="keno-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="keno-result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderBigSmall" :key="i" class="div-result">
                              <!-- <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn class="trend-item" :class="result.small_large.color" :color="result.small_large.color" fab dark>
                                  <v-icon>mdi-checkbox-blank-circle</v-icon>
                                </v-btn>
                              </span> -->
                              <span v-if="col == result.col && row == result.row">
                                <div class="trend-item" :class="`ksmall_large_${result.small_large.color}`">
                                </div>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
            style="position: relative; background-color: #ffffff !important;">
                <div class="table-responsive pb-3 gp-scroll">
                  <table class="keno-table">
                    <tbody>
                      <tr v-for="row in rows" :key="row">
                        <td class="keno-result" v-for="col in cols" :key="col">
                          <div>
                            <div v-for="(result, i) in orderOddEven" :key="i" class="div-result">
                              <!-- <span v-if="col == result.col && row == result.row" class="l-result-icon">
                                <v-btn :color="result.small_large.color" :style="' background:' + result.odd_even.color" fab dark class="btn-range">
                                  <span class="range-name">
                                    {{ result.odd_even.name == 'សេស' ? 'o' : 'e' }}
                                  </span>
                                </v-btn>
                              </span> -->
                              <span v-if="col == result.col && row == result.row">
                                  <div class="trend-item" :class="`keno_${result.odd_even.color}`">
                                    {{ result.odd_even.name == 'សេស' ? 'O' : 'E' }}
                                  </div>
                                </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </div>
  </v-row>
</template>

<script>
export default {
  props: [
    "passClientRandom", "passLastTwentyResults"
  ],
  data() {
    return {
      fixCol: 30,
      fixColBac: 20,
      fix_column: 20,
      rows: 6,
      cols: 30
    }
  },
  watch: {
    passLastTwentyResults() {
    },
  },
  computed: {
    orderBigSmall() {
      return this.getListResults(1);
    },
    orderRange() {
      return this.getListResults(2);
    },
    orderOddEven() {
      return this.getListResults(3);
    },
  },
  methods: {
    getListResults(type) {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.passLastTwentyResults != null && this.passLastTwentyResults.length > 0) {
        let resultList = this.passLastTwentyResults;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (type == 1) {
              if (pre.small_large.name != item.small_large.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 2) {
              if (pre.range.name != item.range.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 3) {
              if (pre.odd_even.name != item.odd_even.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            } else if (type == 4) {
              if (pre.blue_red.name != item.blue_red.name) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                if (pre.row >= this.rows) {
                  newResult.push({
                    ...item,
                    row: 1,
                    col: pre.col + 1,
                  });
                } else {
                  newResult.push({
                    ...item,
                    row: pre.row + 1,
                    col: pre.col,
                  });
                }
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col
        } else {
          this.fixCol = this.cols
        }
      }
      return newResult;
    },
  },
};
</script>
<style>
.ksmall_large_cir-red {
  background-color: #ffffff !important;
  border: 4px solid #f34141 !important;
}
.ksmall_large_cir-blue {
  background-color: #ffffff !important;
  border: 4px solid #167df1 !important;
}
.ksmall_large_cir-green {
  background-color: #ffffff !important;
  border: 4px solid #960099993300 !important;
}
.ksmall_large_cir-cancel {
  background-color: #ffffff !important;
  border: 4px solid #6c757d !important;
}
.keno_cir-red {
  color: #ffffff;
  background-color: #f34141 !important;
}
.keno_cir-blue {
  color: #ffffff;
  background-color: #167df1 !important;
}
.keno_cir-green {
  color: #ffffff;
  background-color: #999933 !important;
}
.keno_cir-cancel {
  color: #ffffff;
  background-color: #6c757d !important;
}
.cvcir-blue{
  border: 6px solid blue
}
.cvcir-red{
  border: 6px solid red
}

.keno-table {
  border-spacing: 0px !important;
}

.keno-table tbody tr:nth-child(odd) {
  background-color: #e6f1fd;
}

.keno-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}
  .keno-table td {
    width: 60px;
    height: 60px;
    font-size: 10px;
    text-align: center;
    padding: 0px;
    text-align: center;
  }
  .keno-table tr td {
    border: 1px solid #c5c5c5;
  }
  .keno-table td > div {
    width: 60px;
  }
</style>