<template>
    <div class="sub-game-channel-info-container">
        <span 
        class="machine-game-open-marquee"
          v-if="getClientTime && getClientTime.is_open_betting">{{ getClientTime.time }}
        </span>
        <marquee
          v-else-if="!passChannel.is_maintain && (passChannel.is_open == false || (getClientTime && !getClientTime.is_open_betting))"
          scrollamount="15" class="machine-game-close-marquee"
          width="100%" direction="left;">
          {{ $t('title.gameIsClose') }}
          
        </marquee>
        <!-- .open-betting-countdown -->
        <div v-else class="machine-game-close-marquee">
          <img :src="spinningNotAllow"
              style="width: 50px; height: auto; padding: 0px" alt="" />
        </div>
      </div>
</template>
<script>

import { mapGetters } from "vuex";
export default {

  data() {
    return {
      //
    };
  },
  computed: {
    spinningNotAllow() {
        return require("~public_image/spinning-not-allow.svg");
    },
    ...mapGetters("$_dashboard_live", {
      getClientTime: "getClientTime",
      passChannel: "getChannel",
    }),
  },

};
</script>
<style scoped>
@media screen and (max-width: 568px) {

  .ri-sicbo-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-xcol-r1-c1-dd-1 {
  grid-area: r1c1dd1;
}

.ri-xcol-r1-c6-dd-1 {
  grid-area: r1c6dd1;
}

.ri-taixiu-betting-row-dd-1 {
  display: grid;
  grid-template-areas: "r1c1dd1 r1c6dd1";
  gap: 6px;
  width: 100%;
  height: 100%;
}

.ri-taixiu-betting-col-dd-1 {
  font-size: 1vw;
  font-weight: 900;
  color: #008ea0;
  border: 1px solid white;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 3px;
  text-transform: uppercase;
  width: 95% !important;
}
.ri-taixiu-betting-col-dd-1:hover {
  cursor: pointer;
}
.ri-taixiu-betting-col-dd-1 div {
  width: 99%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ri-taixiu-betting-col-dd-1 div span {
  width: 100%;
  text-align: center;
}

.ri-taixiu-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-x-green-dd-1 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-x-red-dd-1 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}

.dd-x-title-dd-1 {
  display: block;
  font-size: 8vw;
}
.dd-x-text-dd-1 {
  display: block;
}

.dd-x-title-dd-2 {
  display: block;
  font-size: 2vw;
}
.dd-x-text-dd-2 {
  display: block;
}

.dd-x-title-dd-3 {
  display: block;
  font-size: 4vw;
}
.dd-x-text-dd-1 {
  display: block;
  font-size: 12vw;
}
.dd-x-text-dd-2 {
  display: block;
  font-size: 2vw;
}
/*------------------- */
.ri-xcol-r1-c1-dd-2 {
  grid-area: r1c1dd2;
}
.ri-xcol-r1-c2-dd-2 {
  grid-area: r1c2dd2;
}
.ri-xcol-r1-c3-dd-2 {
  grid-area: r1c3dd2;
}
.ri-xcol-r1-c4-dd-2 {
  grid-area: r1c4dd2;
}
.ri-xcol-r1-c5-dd-2 {
  grid-area: r1c5dd2;
}
.ri-xcol-r1-c6-dd-2 {
  grid-area: r1c6dd2;
}
.ri-xcol-r1-c7-dd-2 {
  grid-area: r1c7dd2;
}

.ri-xcol-r3-c2-dd-2 {
  grid-area: r3c2dd2;
}
.ri-xcol-r3-c3-dd-2 {
  grid-area: r3c3dd2;
}
.ri-xcol-r3-c4-dd-2 {
  grid-area: r3c4dd2;
}
.ri-xcol-r3-c5-dd-2 {
  grid-area: r3c5dd2;
}
.ri-xcol-r3-c6-dd-2 {
  grid-area: r3c6dd2;
}
.ri-xcol-r3-c7-dd-2 {
  grid-area: r3c7dd2;
}

.ri-taixiu-betting-col-dd-2 {
  font-size: 1vw;
  font-weight: 900;
  color: white;
  border: 1px solid white;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-radius: 3px;
  text-transform: uppercase;
}
.ri-taixiu-betting-col-dd-2:hover {
  cursor: pointer;
}
.ri-taixiu-betting-col-dd-2 div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ri-taixiu-betting-col-dd-2 div span {
  width: 100%;
  text-align: center;
}

.ri-taixiu-betting-status {
  background-image: linear-gradient(
    315deg,
    #fec84e 0%,
    #ffdea82c 90%
  ) !important;
}
.ri-x-green-dd-2 {
  color: #33f761;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-x-red-dd-2 {
  color: #f53649;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3),
    0px 2px 1px rgba(0, 0, 0, 0.3);
}
.ri-sicbo-count-down{
  width: 100%;
  height: auto;
  background-color: #386faf8f;
  margin-bottom: 6px;
  margin-top: 0px;
  text-align: center;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;
  
  
}
.ri-sicbo-count-down span{
  color: #11f5c4;
  font-size: 8vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
.ri-sicbo-count-down marquee{
  color: #dc3545;
  font-size: 8vw;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3), 1px 3px 2px rgba(0, 0, 0, 0.3), 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-weight: 900;
}
}
@media screen and (min-width: 569px) {

}
</style>