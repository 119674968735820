<template>
        <div class="g-summary pt-0" >
                        <v-tooltip bottom v-for="(item, index) in getSubMenuCasino" :key="index" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text  v-bind="attrs" v-on="on"
                                    class="btn-switch-channel pa-0 "
                                    style="width:14.28%;">
                                    <div v-if="index >= passStartIndex && index <= passEndIndex && item.is_enable"
                                        class=" infinite pulse delay-2s w-100 channel-select row my-0 mx-auto justify-content-center "
                                        @click="_switchChannel(item.channel_type)"
                                        :class="item.channel_type == passSelectedChannel ? 'class-actived' : 'class-not-active'"
                                        :disabled="item.channel_type == passSelectedChannel">
                                        <img :src="correctCasinoChannelName(item.channel_type)" class="img-casino-channel-logo" alt=""/>
                                      
                                        <div
                                            class="dot-position" :style="item.channel_type == passSelectedChannel ? 'color: #212427' :'color:white' ">
                                            <v-chip :color="resultChannelColor(item.channel_type)"
                                                class="animated infinite pulse delay-2s chip-size"
                                                >
                                            </v-chip>
                                        </div>
                                    </div>
                                    <div v-else class="to-hidden">
                                    </div>
                                </v-btn>
                            </template>
                            <span>{{  getGameName(item.channel_type)  }}</span>
                        </v-tooltip>
        </div>
</template>

<script>


import "@/assets/css/homepage.css";
import Mixin from "../../../../../mixin";
import { mapGetters } from "vuex";
export default {
    props: [
        'passChannelStatus',
        "passSelectedChannel",
        "passStartIndex",
        "passEndIndex",
        "channel"
    ],
    data() {
        return {
            mixins: [Mixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
            getSubMenuCasino:"getSubMenuCasino"
        }),
    },
    methods: {
        resultChannelColor(index) {
            if (this.passChannelStatus) {
                for (var i = 0; i < this.passChannelStatus.length; i++) {
                    if (this.passChannelStatus[i].channel_type == index) {
                        if (this.passChannelStatus[i].status == null) {
                            return "orange"
                        } else {
                            if (this.passChannelStatus[i].status) {
                                return "green"
                            }
                            return "red"
                        }
                    }
                }
            }
        },
        _switchChannel(selectedChannel) {
            this.$emit("sentSetChannel", selectedChannel);
        }
    }
}
</script>
