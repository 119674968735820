export default {
	update: 'ធ្វើបច្ចុប្បន្នភាព',
	delete: 'Delete',
	delete_all: 'Delete All',
	view: 'View',
	cancel: 'Cancel',
	yes: 'Yes',
	save: 'Save',
	detail: 'Detail',
	create: 'Create',
	upload_file: 'Upload File',
	clear: 'Clear',
	filter: 'Filter',
	local_leng: 'ខ្មែរ',
	create_invoice: 'Create Invoice',
	print: 'Print',
	create_patient: 'Create Patient',
	create_doctor: 'Create Doctor',
	create_service: 'Create Service',
	change_password: 'ប្តូរពាក្យសម្ងាត់',
	logout: 'ចាកចេញ',
	meron:'ចាក់ក្រហម',
	tie:'ចាក់ស្មើរ',
	wala:'ចាក់ខៀវ',
	search:'ស្វែងរក',
	today:'ថ្ងៃនេះ',
	last_day:'ម្សិលមិញ',
	this_week:'សប្តាហ៏នេះ',
	last_week:'សប្តាហ៏មុន',
	this_month:'ខែនេះ',
	last_month:'ខែមុន',
	small : "តូច",
	large : 'ធំ',
	odd : "សេស",
	even : 'គូ',
	login:'ចូល',
	home:'គេហទំព័រដើម',
	all:'ទាំងអស់',
	cash:"លុយត្រឡប់",
	deposit:"ដាក់ប្រាក់",
	withdraw:"ដកប្រាក់",
	submit:"ដាក់ស្នើរ"
};
