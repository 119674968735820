<template>
  <div class="view-bet-sec pa-0">
    <div class="g-summary pa-5">
      <div class="container px-0 py-0">
        <div class="column left-column">
          <div class="block pr-1">
            <div v-if="walaButton" :class="selectedBtnClassPairPlayer ? 'on-active-btn' : ''"
              class="side-section-betting-btn wala-section-dragon">
              <div class="banker-button" @click="_allowBtnBet('selectedPairPlayer', pairPlayerButton)">
                <div class="side-title truncate py-0 ">
                  <p class="odds mt-4 mb-0"> P PAIR </p>
                </div>
                <div class="truncate side-title font-weight-bold ">
                  <p class="pt-0 pb-1 payout">
                    {{
                      pairPlayerButton
                      ? `PAYOUT =
                    ${pairPlayerButton.payout_display}`
                      : ""
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="block pr-1">
            <div v-if="walaButton" :class="selectedBtnClassWala ? 'on-active-btn' : ''"
              class="side-section-betting-btn wala-section-dragon">
              <div class="banker-button" @click="_allowBtnBet('selectedWala', walaButton)">
                <div class="side-title truncate py-0 ">
                  <p class="odds mt-4 mb-0"> PLAYER </p>
                </div>
                <div class="truncate side-title  ">
                  <p class="pt-0 pb-1 payout">
                    {{
                      walaButton
                      ? `PAYOUT =
                    ${walaButton.payout_display}`
                      : ""
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column px-1">
          <div class="block long-block">
            <div class="side-section-tie tie-section tie-button" :class="selectedBtnClassTie ? 'on-active-btn' : ''"
              @click="_allowBtnBet('selectedTie')" v-if="tieButton">
              <div>
                <div class="odds">
                  {{ $t("title.tie") }}
                </div>
                <div class="side-title pt-1">
                  <P class="truncate font-weight-bold payout">
                    {{ tieButton ? `1 : ${tieButton.payout_display}` : "x.xx" }}
                  </P>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column right-column">
          <div class="block pl-1">
            <div v-if="walaButton" :class="selectedBtnClassPairBanker ? 'on-active-btn' : ''"
              class="side-section-betting-btn meron-section-tiger">
              <div class="banker-button" @click="_allowBtnBet('selectedPairBanker', pairBankerButton)">
                <div class="side-title truncate py-0 ">
                  <p class="odds mt-4 mb-0"> B PAIR </p>
                </div>
                <div class="truncate side-title font-weight-bold ">
                  <p class="pt-0 pb-1 payout">
                    {{
                      pairBankerButton
                      ? `PAYOUT =
                    ${pairBankerButton.payout_display}`
                      : ""
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="block pl-1">
            <div v-if="walaButton" :class="selectedBtnClassMeron ? 'on-active-btn' : ''"
              class="side-section-betting-btn meron-section-tiger">
              <div class="banker-button" @click="_allowBtnBet('selectedMeron', meronButton)">
                <div class="side-title truncate py-0 ">
                  <p class="odds mt-4 mb-0"> BANKER </p>
                </div>
                <div class="truncate side-title font-weight-bold ">
                  <p class="pt-0 pb-1 payout">
                    {{
                      meronButton
                      ? `PAYOUT =
                    ${meronButton.payout_display}`
                      : ""
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CoinButton :passGetButton="getButton" :passChannel="passChannel" :passPlaceCoin="getBettingAmount"
     class="mt-0" />
    <SubmitBtn :passCheckAllowSubmit="_checkAllowSubmit" v-on:handleSubmitBtn="_submitBetting"
      v-on:handleClearBtn="_clearMoney" class="mb-2" />
    <BaccaratMachineReportBettingComponent :passLoadingTodayReport="passLoadingTodayReport" />
    <v-row class="mx-0 justify-center w-100 mt-2">
      <v-col col-12 style="padding: 0">
        <div class="show-on-mobile pt-5">
          <TableResult :rows="6" :cols="35" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import "@/assets/css/homepage.css";
import CoinButton from "~global_components/component_machine_game/CoinBettingButton.vue";
import SubmitBtn from "~global_components/component_casino/SubmitBtn.vue";
import TableResult from "../../TableResults.vue";
import BaccaratMachineReportBettingComponent from "../../_ReportBettingComponent/BaccaratMachineReportBettingComponent.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    BaccaratMachineReportBettingComponent,
    CoinButton,
    SubmitBtn,
    TableResult,
  },
  props: [
    "passChannelStatus",
    "passBettingTypeMaster",
    "passWeight",
    "passSelectedChannel",
    "passUserCurrency",
    "passLoadingTodayReport",
  ],
  data() {
    return {
      selectedButton: {},
      selectedBtnClassMeron: false,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedBtnClassPairBanker: false,
      selectedBtnClassPairPlayer: false,
      csBanker1: "",
      csBanker2: "",
      csBanker3: "",
      csPlayer1: "",
      csPlayer2: "",
      csPlayer3: "",
      totalP: 0,
      totalB: 0,
      selectedCoin: false,
    };
  },
  watch: {},
  computed: {
    meronButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[0];
      else return {};
    },
    walaButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[1];
      else return {};
    },
    tieButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[2];
      else return {};
    },
    pairBankerButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[3];
      else return {};
    },
    pairPlayerButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[4];
      else return {};
    },
    _checkAllowSubmit() {
      if (
        this.getBettingAmount > 0 &&
        (this.selectedBtnClassMeron ||
          this.selectedBtnClassTie ||
          this.selectedBtnClassWala ||
          this.selectedBtnClassPairBanker ||
          this.selectedBtnClassPairPlayer)
      )
        return true;
      else return false;
    },
    ...mapGetters("$_dashboard_live", {
      dialogBetting: "getDialogBetting",
      getButton: "getButton",
      amountMin: "getAmountMin",
      amountMx: "getAmountMax",
      getCountdown: "getCountdown",
      passChannel: "getChannel",
      passCurrentBetting: "getCurrentBetting",
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },

  methods: {
    formatBtnCurrencyKh(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "ព";
        case amount >= 10000 && amount <= 999999:
          return amount / 10000 + "ម";
        case amount >= 1000000 && amount <= 99999999999:
          return amount / 1000000 + "ល";
        default:
          return amount;
      }
    },
    formatBtnCurrency(amount) {
      switch (true) {
        case amount >= 1000 && amount <= 9999:
          return amount / 1000 + "K";
        default:
          return amount;
      }
    },

    _detectCoin(index) {
      switch (index) {
        case 0:
          return "/images/coins/blue.png";
        case 1:
          return "/images/coins/pink.png";
        case 2:
          return "/images/coins/cyan.png";
        case 3:
          return "/images/coins/red.png";
        case 4:
          return "/images/coins/orange.png";
        case 5:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyCoinMeron(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorMeron(money) {
      switch (true) {
        case money < 5:
          return "black";
        case money < 10:
          return "black";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "grey";
        default:
          return "";
      }
    },
    _detectCurrentMoneyCoinWala(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorWala(money) {
      switch (true) {
        case money < 5:
          return "blue";
        case money < 10:
          return "#F92A53";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "grey";
        default:
          return "";
      }
    },
    _detectCurrentMoneyCoinTie(money) {
      switch (true) {
        case money < 5:
          return "/images/coins/blue.png";
        case money < 10:
          return "/images/coins/pink.png";
        case money < 20:
          return "/images/coins/cyan.png";
        case money < 50:
          return "/images/coins/red.png";
        case money < 100:
          return "/images/coins/orange.png";
        case money < 99999999:
          return "/images/coins/grey.png";
        default:
          return "";
      }
    },
    _detectCurrentMoneyColorTie(money) {
      switch (true) {
        case money < 5:
          return "blue";
        case money < 10:
          return "#F92A53";
        case money < 20:
          return "green";
        case money < 50:
          return "red";
        case money < 100:
          return "#f97432";
        case money < 99999999:
          return "white";
        default:
          return "";
      }
    },

    _clearMoney() {
      this.selectedBtnClassMeron = false;
      this.selectedBtnClassTie = false;
      this.selectedBtnClassWala = false;
      this.selectedBtnClassPairBanker = false;
      this.selectedBtnClassPairPlayer = false;
      this.selectedCoin = false;
      this.INPUT_BETTING_AMOUNT(0);
      this.UDPDATE_SELECTE_BTN("");
      this.UPDATE_SELECT_COIN("");
    },
    _submitBetting() {
      let submitBettingData = {
        type_id: this.selectedButton._id,
        amount: this.getBettingAmount,
      };
      this.$emit("sentSubmitBetting", submitBettingData);
      this._clearMoney();
    },
    _allowBtnBet(selectedBtn) {
      // if (!this.selectedCoin) {
      //   this.$toastr.e(this.$t("title.selectCoin"));
      //   return false;
      // }
      switch (selectedBtn) {
        case "selectedMeron":
          // console.log("selectedMeron");
          this.UDPDATE_SELECTE_BTN("selectedBanker");
          this.selectedButton = this.meronButton;
          this.selectedBtnClassMeron = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedTie":
          this.UDPDATE_SELECTE_BTN("selectedTie");
          this.selectedButton = this.tieButton;
          this.selectedBtnClassTie = true;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedWala":
          this.UDPDATE_SELECTE_BTN("selectedPlayer");
          this.selectedButton = this.walaButton;
          this.selectedBtnClassWala = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassPairBanker = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedPairBanker":
          this.UDPDATE_SELECTE_BTN("selectedPairBanker");
          this.selectedButton = this.pairBankerButton;
          this.selectedBtnClassPairBanker = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairPlayer = false;
          break;
        case "selectedPairPlayer":
          this.UDPDATE_SELECTE_BTN("selectedPairPlayer");
          this.selectedButton = this.pairPlayerButton;
          this.selectedBtnClassPairPlayer = true;
          this.selectedBtnClassTie = false;
          this.selectedBtnClassMeron = false;
          this.selectedBtnClassWala = false;
          this.selectedBtnClassPairBanker = false;
          break;
      }
    },
    ...mapMutations("$_dashboard_live", [
      "BETTING_DIALOG",
      "UPDATE_PLAYER_CARD",
      "UPDATE_BANKER_CARD",
      "RESET_CARD",
      "UDPDATE_SELECTE_BTN",
      "UPDATE_SELECT_COIN"
    ]),
    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
    ]),
  },
};
</script>
<style scoped>
@media (min-width: 960px) {
  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid blue;
    min-height: 185px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(113, 253, 113, 0.288);
  }

  .betting-coin-meron {
    width: 40px;
    height: auto;
    margin: auto;
  }

  .betting-coin-wala {
    width: 40px;
    height: auto;
    margin: auto;
  }

  .btn-coin {
    width: 45px;
    height: auto;
    position: relative;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .money-in-coin-position {
    margin-top: -29.5px;
    margin-bottom: 25px;
    margin-left: -2px;
    z-index: 100;
    font-weight: 600;
    text-shadow: 1px 0px 1px black;
    font-size: 13px;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    top: 1px;
    left: -16px;
    font-size: 12.5px;
    font-weight: 600;
    text-shadow: 1px 0px 1px black;
    width: 68px;
  }



  .side-section-betting-btn {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 2px solid #437adb;
    border-radius: 5px;
    z-index: 1000;
  }
}

@media (min-width: 1280px) {
  .side-section {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    min-height: 200px;
  }

  .side-section-tie {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 29%;
    flex: 0 0 29%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(113, 253, 113, 0.288);
  }

  .betting-coin-meron {
    width: 50px;
    height: auto;
    margin: auto;
  }

  .betting-coin-wala {
    width: 60px;
    height: auto;
    margin: auto;
  }

  .btn-coin {
    width: 60px;
    height: auto;
    position: relative;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .betting-coin-meron {
    width: auto;
    height: 58px;
    margin: auto;
  }

  .betting-coin-wala {
    width: auto;
    height: 58px;
    margin: auto;
  }

  .btn-coin {
    width: 60px;
    height: auto;
    position: relative;
  }

  .btn-clear-money {
    background: rgb(201, 201, 214);
    background: linear-gradient(180deg,
        rgba(201, 201, 214, 1) 0%,
        rgba(103, 103, 106, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    width: 150px;
    text-align: center;
    justify-content: center;
  }

  .cross-icon {
    margin-top: -4px;
    margin-right: 2.5px;
    font-size: 17px;
  }

  .btn-submit-money {
    background: rgb(110, 179, 241);
    background: linear-gradient(180deg,
        rgba(110, 179, 241, 0.9108018207282913) 0%,
        rgba(25, 72, 166, 1) 100%);
    border-width: 0.5 !important;
    border-color: #133375 !important;
    border-radius: 6px;
    width: 150px;
    color: white;
    justify-content: center;
  }

  .span-icon {
    border-radius: 7px;
    padding: 11.5px 20px;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .side-section:hover {
    cursor: pointer;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .money-in-coin-position {
    margin-top: -38px;
    margin-bottom: 31px;
    z-index: 100;
    font-weight: 900;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    top: 7px;
    left: -8px;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
  }



  .side-section-betting-btn {
    text-align: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #012681;
    background-image: url("/images/tiger-dragon/dragon.png");
    z-index: 1000;
  }
}

.container {
  display: flex;
}

.column {
  flex: 2;
  text-align: center !important;
}

.left-column {
  flex: 2;
}

.right-column {
  flex: 2;
}

.block {
  margin-bottom: 10px;
  padding: 10px 0x;
}

.long-block {
  height: 200px;
  flex-basis: calc(33.33% - 20px);
}

.meron-section-tiger,
.modal-meron .modal-content {
  color: #fff;
}

.meron-section-tiger {
  color: #fff;
  border: 2px#f34141 solid;
  border-radius: 5px;
}

.meron-section-tiger .side-title,
.modal-meron .modal-bet-title {
  text-transform: uppercase;
  color: #f34141;
}

.wala-section-dragon,
.modal-meron .modal-content {
  color: #fff;
}

.wala-section-dragon {
  color: #fff;
  border: 2px solid #437adb;
  border-radius: 5px;
}

.wala-section-dragon .side-title,
.modal-meron-tiger .modal-bet-title {
  text-transform: uppercase;
  color: #187df1;
}

.pair-betting {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(32, 218, 88, 0.2);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}

.pair-betting:hover {
  cursor: pointer;
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.pair-banker-text {
  font-size: 18px;
  font-weight: 600;
  color: lightgoldenrodyellow;
  text-shadow: 1px 0px black;
}

.selected-p-banker {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}

.banker-button {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.tie-button {
  width: 100%;
  height: 238px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.banker-button :hover {
  cursor: pointer;
}

.selected-p-player {
  width: 100%;
  height: 56px;
  border-radius: 0 0 50% 50%;
  background-color: rgba(218, 165, 32, 0.7);
  border: 2px solid rgb(218, 165, 32);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
}

.open-betting-countdown {
  background-color: #def3f6;
  font-size: 28px;
  font-family: "khmer mef1";
  font-weight: 900;
  height: 42px;
  text-align: center;
  padding-top: 0px;
  text-shadow: 0.2px 0.2px red;
}

.open-betting {
  color: #28a745;
}

.close-betting {
  color: #dc3545;
}

.truncate {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: center !important;
}

.odds {
  font-size: 1.5rem;
  /* margin-top: 10px; */
  color: whitesmoke;
  font-weight: 900;
}

.odds-subtle {
  font-size: 1rempx;
  color: hsla(0, 0%, 100%, 0.8);
  margin-top: 3px;
}

.tie-section {
  border: 2px greenyellow solid;
  border-radius: 5px;
}

.tie-section .side-title {
  color: greenyellow;
}

@media screen and (max-width: 1904px) {
  .text-display-payout {
    font-size: 2rem !important;
  }

  .range-betting-payout {
    font-size: 2rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 1280px) {
  .text-display-payout {
    font-size: 2rem !important;
  }

  .range-betting-payout {
    font-size: 1.3rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 982px) {
  .tie-button {
    height: 249px;
  }

  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.1rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 960px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }

  .block {
    margin-bottom: 10px;
    padding: 5px;
  }
}

@media screen and (max-width: 959px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.2rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 768px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 1.1rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 700px) {
  .text-display-payout {
    font-size: 0.8rem;
  }

  .range-betting-payout {
    font-size: 0.8rem;
  }

  .on-active-btn {
    border: 4px solid gold;
    /* background-color: #fec84e; */
    background-image: linear-gradient(315deg,
        #fec84e 0%,
        #ffdea82c 90%) !important;
  }
}

@media screen and (max-width: 600px) {
  .range-betting-payout {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 529px) {
  .range-betting-payout {
    font-size: 1.2rem !important;
  }
}

@media screen and (max-width: 390px) {
  .text-display-payout {
    font-size: 0.75rem;
  }

  .range-betting-payout {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 375px) {
  .range-betting-payout {
    font-size: 0.9rem;
  }
}

.han2-min-max-limit .max-label {
  float: right !important;
  width: auto !important;
  margin-top: 8px !important;
}

@media screen and (min-width: 1904px) {}

@media screen and (min-width: 1280px) {}

@media screen and (min-width: 982px) {}

@media screen and (min-width: 960px) {}

@media screen and (min-width: 959px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 700px) {}

@media screen and (min-width: 600px) {}

@media screen and (min-width: 529px) {
  .kalakouk-row {
    padding-left: 46px !important;
  }
}

@media screen and (min-width: 450px) {
  .kalakouk-row {
    padding-left: 36px !important;
  }
}

@media screen and (min-width: 428px) {
  .kalakouk-row {
    padding-left: 38px !important;
  }
}

@media screen and (min-width: 414px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 390px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 384px) {
  .kalakouk-row {
    padding-left: 32px !important;
  }
}

@media screen and (min-width: 375px) {
  .kalakouk-row {
    padding-left: 30px !important;
  }
}

.range-betting-container {
  width: 23%;
  height: 90px;
  border-radius: 5px;
  background-color: rgb(0, 102, 153, 0.1);
  border: 1px solid rgb(218, 165, 32);
  position: relative;
}

.range-betting-container-active {
  width: 23%;
  height: 90px;
  border-radius: 5px;
  background-color: #012681;
  border: 3px solid grey;
  transform: scale(1.04);
  position: relative;
}
.payout{
  font-size:.9rem!important;
  font-weight:bold
}
</style>