const LATEST_CHANNEL = (state, data) => {
    state.channel = data;
    state.vdolinks = data.live_urls;
};
const UPDATE_ERROR_MESSAGE = (state, data) => {
    state.errorMessage = data;
};
const LATEST_NUMBER_MASTER = (state, data) => {
    state.numberMaster = data;
};
const LATEST_BETTING_TYPE_MASTER = (state, data) => {
    state.bettingTypeMaster = data;
};
const UPDATE_IS_REPORT = (state, data) => {
    state.isReport = data;
};
const LATEST_RESULT = (state, data) => {
    state.resultData = data;
};
const TODAY_BETTING = (state, data) => {
    state.todayBetting = data;
};
const CURRENT_BETTING = (state, data) => {
    state.currentBetting = data;
};
const CREATE_BETTING = (state, data) => {
    state.creatBetting = data;
};
const UPDATE_CURRENT_BETTING = (state, data) => {
    // console.log(`UPDATE_CURRENT_BETTING: ${JSON.stringify(data)}`);
    state.currentBetting.list.unshift(data);
    // console.log(`UPDATE_CURRENT_BETTING:asdasds ${JSON.stringify(state.currentBetting.list)}`);
};
const UPDATE_TODAY_BETTING = (state, data) => {
    state.todayBetting = data;
};
const UPDATE_CHANNEL = (state, data) => {
    state.channel = data;
};
const UPDATE_CHANNEL_STATUS = (state, data) => {
    state.channelStatus = data;
};
const LATEST_WEIGHT = (state, data) => {
    state.weight = data;
};
const UPDATE_PAYOUT = (state, data) => {
    state.weight.wala.payout = data.payout_wala;
    state.weight.meron.payout = data.payout_meron;
};
const UPDATE_AMOUNT = (state, data) => {
    state.weight.wala.amount = data.wala_amount;
    state.weight.meron.amount = data.meron_amount;
};
const RESET_AMOUNT = (state) => {
    state.weight.wala.amount = 0;
    state.weight.meron.amount = 0;
    // console.log(`socket on client-reset-amount-reset: ${JSON.stringify(state.weight)}`);
};
const UPDATE_RESULT = (state, data) => {
    state.resultData.results = data.objCoResult;
    state.resultData.summary = data.objSummaryCoResult;
    state.currentBetting.list = [];
    state.currentBetting.meron = 0;
    state.currentBetting.wala = 0;
    state.currentBetting.tie = 0;
    // baccarat;
    state.currentBetting.player_amount = 0;
    state.currentBetting.pair_banker_amount = 0;
    state.currentBetting.pair_player_amount = 0;
    state.currentBetting.banker_amount = 0;
    // tiger dragon
    state.currentBetting.dragon_amount = 0;
    state.currentBetting.tiger_amount = 0;
    state.totalLotto = 0;

};
const LATEST_BUTTON = (state, data) => {
    state.button = data;
};
const RELOAD_PAGE = (state) => {
    state.currentBetting.list = {}
    state.todayBetting = []
    state.resultData.results = []
    state.resultData.summary = {}
    state.currentBetting.meron = 0
    state.currentBetting.wala = 0
    state.currentBetting.tie = 0
    state.playerCard1 = '0052';
    state.playerCard2 = '0052';
    state.playerCard3 = '0052';
    state.bankerCard1 = '0052';
    state.bankerCard2 = '0052';
    state.bankerCard3 = '0052';
    state.totalP = null;
    state.totalB = null;
    state.currentGameResult = null;
    state.firstCard = null;
    state.secondCard = null;
};
const CLEAR_CACH = (state) => {
    state.currentBetting.list = {}
    state.todayBetting = []
    state.resultData.results = []
    state.resultData.summary = {}
    state.currentBetting.meron = 0
    state.currentBetting.wala = 0
    state.currentBetting.tie = 0
};
const UPDATE_AMOUNT_MAX = (state, max) => {
    state.weight.amount_max = max
};
const UPDATE_BET_AMOUNT = (state, data) => {

    if (data.betting == "MERON") {
        state.currentBetting.meron = state.currentBetting.meron + data.bet_amount
    } else if (data.betting == "WALA") {
        state.currentBetting.wala = state.currentBetting.wala + data.bet_amount
    } else if (data.betting == "TIE") {
        state.currentBetting.tie = state.currentBetting.tie + data.bet_amount
            // bacarat
        state.currentBetting.tie_amount = state.currentBetting.tie_amount + data.bet_amount
    } else if (data.betting == "PAIR PLAYER") {
        state.currentBetting.pair_player_amount = state.currentBetting.pair_player_amount + data.bet_amount
    } else if (data.betting == "PAIR BANKER") {
        state.currentBetting.pair_banker_amount = state.currentBetting.pair_banker_amount + data.bet_amount
    } else if (data.betting == "PLAYER") {
        state.currentBetting.player_amount = state.currentBetting.player_amount + data.bet_amount
    } else if (data.betting == "BANKER") {
        state.currentBetting.banker_amount = state.currentBetting.banker_amount + data.bet_amount
    }
    // tiger dragon
    else if (data.betting == "DRAGON") {
        state.currentBetting.dragon_amount = state.currentBetting.dragon_amount + data.bet_amount
    } else if (data.betting == "TIGER") {
        state.currentBetting.tiger_amount = state.currentBetting.tiger_amount + data.bet_amount
    }
    // tiger dragon
    else if (data.betting == "small") {
        state.currentBetting.small_amount = state.currentBetting.small_amount + data.bet_amount
    } else if (data.betting == "large") {
        state.currentBetting.large_amount = state.currentBetting.large_amount + data.bet_amount
    }
    // Yuki
    // console.log(`UPDATE_BET_AMOUNT:ghjghj ${JSON.stringify(state.currentBetting)}`);
};
const UPDATE_CH_STATUS = (state, is_open) => {
    state.channel.is_open = is_open
};
const BETTING_DIALOG = (state, payload) => {
    state.dialog_betting = payload;
};
const LOADING = (state, payload) => {
    state.loading_betting = payload;
};
const LOADING_TODAY_REPORT = (state, payload) => {
    state.loadingReport = payload;
};
const UPDATE_DRAGON_CARD = (state, data) => {
    state.dragonCard = data;
};
const UPDATE_COUNTDOWN = (state, data) => {
    state.countDown = data;
    // console.log(`socket on client-start-count-downss: ${JSON.stringify(data)}`);
};
const UPDATE_TIGER_CARD = (state, data) => {
    state.tigerCard = data;
};
// BAccarat
// const UPDATE_PLAYER_CARD = (state, data) => {
// 	state.playerCard1 = '0052.png';
// 	state.playerCard2 = '0052.png';
// 	state.playerCard3 = '0052.png';
// 	state.totalP = 0;
// 	for (let i in data) {
// 		if (data[i].scan_number == 1) state.playerCard1 = data[i].image;
// 		if (data[i].scan_number == 2) state.playerCard2 = data[i].image;
// 		if (data[i].scan_number == 3) state.playerCard3 = data[i].image;
// 		state.totalP = state.totalP + data[i].value;
// 		state.totalP = state.totalP % 10
// 	}
// };

const CLEAR_PLAYER_CARD = (state) => {
    state.playerCard1 = '0052';
    state.playerCard2 = '0052';
    state.playerCard3 = '0052';
    state.bankerCard1 = '0052';
    state.bankerCard2 = '0052';
    state.bankerCard3 = '0052';
    state.totalP = null;
    state.totalB = null;
    state.currentGameResult = null;
    state.clientCard = {}

};
const UPDATE_PLAYER_CARD_UPDATED = (state, data) => {
    // Determine the player card key based on the scanCounter
    const playerCardKey = 'playerCard' + data.scan_number;
    // Reset player cards to the default value if needed
    // state.playerCard1 = state.playerCard2 = state.playerCard3 = '0052';
    // state.totalP = 0;
    // console.log('totalP')
    state[playerCardKey] = data.name;
    state.totalP += data.value;
    state.totalP %= 10;
    // console.log('totalP',   state.totalP )
};


// const UPDATE_BANKER_CARD = (state, data) => {
// 	state.bankerCard1 = '0052.png';
// 	state.bankerCard2 = '0052.png';
// 	state.bankerCard3 = '0052.png';
// 	state.totalB = "";
// 	for (let i in data) {
// 		if (data[i].scan_number == 1) state.bankerCard1 = data[i].image;
// 		if (data[i].scan_number == 2) state.bankerCard2 = data[i].image;
// 		if (data[i].scan_number == 3) state.bankerCard3 = data[i].image;
// 		state.totalB = state.totalB + data[i].value;
// 		state.totalB = state.totalB % 10
// 	}
// };

const UPDATE_BANKER_CARD_UPDATED = (state, data) => {
    // Determine the player card key based on the scanCounter
    const playerCardKey = 'bankerCard' + data.scan_number;
    // Reset player cards to the default value if needed
    // state.bankerCard1 = state.bankerCard2 = state.bankerCard3 = '0052';
    // state.totalB = 0;
    state[playerCardKey] = data.name;
    state.totalB += data.value;
    state.totalB %= 10;
    
};

const RESET_COUNTDOWN = (state) => {
    state.countDown = {
        countDown: 0,
        type: "",
        isPlay: false,
        isCountDown: false
    }
};
const RESET_CARD = (state) => {
    state.playerCard1 = '0052.png';
    state.playerCard2 = '0052.png';
    state.playerCard3 = '0052.png';
    state.bankerCard1 = '0052.png';
    state.bankerCard2 = '0052.png';
    state.bankerCard3 = '0052.png';
    state.totalB = null;
    state.totalP = null;
    // dragon tiger
    state.tigerCard = '';
    state.dragonCard = '';
    // klaklok
    state.listKlaklouk = []
        // apong
    state.listAbong = []
        // yuki
    state.listYuki = {};
    // lotto
    state.listLotto = {};

        state.listTaiSiv = []
    state.listFantan = []


};
const UPDATE_FIST_LOAD_BACARAT_CARD = (state, data) => {
    state.playerCard1 = '0052';
    state.playerCard2 = '0052';
    state.playerCard3 = '0052';
    state.bankerCard1 = '0052';
    state.bankerCard2 = '0052';
    state.bankerCard3 = '0052';
    state.totalB = null;
    state.totalP = null;
    for (let i in data.result.player) {
        if (data.result.player[i].scan_number == 1) state.playerCard1 = data.result.player[i].barcode;
        if (data.result.player[i].scan_number == 2) state.playerCard2 = data.result.player[i].barcode;
        if (data.result.player[i].scan_number == 3) state.playerCard3 = data.result.player[i].barcode;
        state.totalP = state.totalP + data.result.player[i].value;
        state.totalP = state.totalP % 10
    }
    for (let j in data.result.banker) {
        if (data.result.banker[j].scan_number == 1) state.bankerCard1 = data.result.banker[j].barcode;
        if (data.result.banker[j].scan_number == 2) state.bankerCard2 = data.result.banker[j].barcode;
        if (data.result.banker[j].scan_number == 3) state.bankerCard3 = data.result.banker[j].barcode;
        state.totalB = state.totalB + data.result.banker[j].value;
        state.totalB = state.totalB % 10
    }

};

const UPDATE_FIST_LOAD_TIGER_DRAGON_CARD = (state, data) => {
    state.tigerCard = '0052.png';
    state.dragonCard = '0052.png';
    for (let i in data) {
        if (data[i].type == "dragon") {
            state.dragonCard = `${data[i].barcode}.png`;
        } else if (data[i].type == "tiger") {
            state.tigerCard = `${data[i].barcode}.png`;
        }
    }
};

const UPDATE_LOTTO_CARD = (state, data) => {
    state.listLotto = data.number;
    state.totalLotto = data.total;
};
const UPDATE_LOTTO = (state, data) => {
    state.listLotto = data;
    state.totalLotto = data.reduce((a, b) => a + b, 0)
};
// Klaklouk
const UPDATE_KLAKLOUK_CARD = (state, data) => {
    state.listKlaklouk = data;
};

const UPDATE_KLAKLOUK = (state, data) => {
    state.listKlaklouk = data;
};
const RESET_KLAKLOUK_CARD = (state) => {
    state.listKlaklouk = []
};

// ABONG
const UPDATE_ABONG_CARD = (state, data) => {
    state.listAbong.unshift(data.name)
};

const UPDATE_FANTAN = (state, data) => {
    state.listFantan = data;
};

const RESET_ABONG_CARD = (state) => {
    state.listAbong = []
};
// YUKI
const UPDATE_YUKI_CARD = (state, data) => {
    state.listYuki = data
};

const RESET_YUKI_CARD = (state) => {
    state.listYuki = []
};

const UPDATE_AMOUNT_MIN = (state, data) => {
    state.amount_min = data
};

const UPDATE_AMOUNT_MX = (state, data) => {
    state.amount_max = data
};
// TAISIV
const UPDATE_TAISIV = (state, data) => {
    state.listTaiSiv = data;
};
const RESET_TAISIV_CARD = (state) => {
    state.listTaiSiv = []
};

// Button Coin
const IS_SELECTED_COIN = (state, data) => {
    state.isSelectedCoin = data;
};

const UPDATE_CLIENT_TIME = (state, data) => {
    state.clientTime = data;
};
const UPDATE_CURRENT_GAME_RESULT = (state, data) => {
    state.currentGameResult = data;
};
const CLIENT_RANDOM = (state, data) => {
    state.clientRandom = data;
};

const UPDATE_FIRST_CARD = (state, data) => {
    state.firstCard = data;
};

const UPDATE_SECOND_CARD = (state, data) => {
    state.secondCard = data;
};

const APPEND_KLAKLOUK_RESULT = (state, data) => {
    state.resultData.results.unshift(data)
};



const UDPDATE_SELECTE_BTN = (state, data) => {
    state.selectedBtn = data;
};

const UPDATE_SELECT_DRAGON_BUTTON = (state, data) => {
    state.selectedBtnDragon = data;
}
const UPDATE_SELECT_COIN = (state, data) => {
    state.selectedCoin = data
};
const APPEND_KLAKLOUK_CLEAR_START = (state, data) => {
    state.klakloukClearStart = data
};
const SEND_BACK_CARD = (state, data) => {
    state.backCard = data
};
const SEND_CARD = (state, data) => {
    // console.log("SEND CARD", data)
    state.sendCard = data
};
const UPDATE_CLIENT_CARD = (state, data) => {
    state.clientCard = data
};
const CLEAR_TO_DEFAULT = (state) => {
    state.selectedCoin = null;
        state.clientTime = {
            is_countdown: null,
            is_open_betting: null,
            time: null,
        };
        state.listLotto = {};
        state.totalLotto = 0;
        state.listKlaklouk = [];
        state.listTaiSiv = [];
        state.listAbong = [];
        state.listFantan = [];
        state.listYuki = {};
        state.amount_min = 0;
        state.amount_max = 0;
        state.isSelectedCoin = false;
        state.loading_betting = false;
        state.errorMessage = '';
        state.currentGameResult = {};
        state.klakloukClearStart = {};
        state.clientRandom = {};
        state.firstCard = {};
        state.secondCard = {};
        state.selectedBtn = '';
        state.selectedBtnDragon = null

};

const UPDATE_BACCARAT_CARD = (state, data) => {
    state.playerCard1 = '0052';
    state.playerCard2 = '0052';
    state.playerCard3 = '0052';
    state.bankerCard1 = '0052';
    state.bankerCard2 = '0052';
    state.bankerCard3 = '0052';
    state.totalB = null;
    state.totalP = null;
    for (let i in data.player_cards) {
        // console.log("data.player_cards[i].barcode",data.player_cards[i].name)
        if (data.player_cards[i].scan_number == 1) state.playerCard1 = data.player_cards[i].name;
        
        if (data.player_cards[i].scan_number == 2) state.playerCard2 = data.player_cards[i].name;
        if (data.player_cards[i].scan_number == 3) state.playerCard3 = data.player_cards[i].name;
        state.totalP = state.totalP + data.player_cards[i].value;
        state.totalP = state.totalP % 10
    }
    for (let j in data.banker_cards) {
        // console.log("data.banker_cards[j]",data.banker_cards[j].name)
        if (data.banker_cards[j].scan_number == 1) state.bankerCard1 = data.banker_cards[j].name;
        if (data.banker_cards[j].scan_number == 2) state.bankerCard2 = data.banker_cards[j].name;
        if (data.banker_cards[j].scan_number == 3) state.bankerCard3 = data.banker_cards[j].name;
        state.totalB = state.totalB + data.banker_cards[j].value;
        state.totalB = state.totalB % 10
    }
    
};

export default {
    UPDATE_CLIENT_CARD,
    SEND_CARD,
    UPDATE_BACCARAT_CARD,
    SEND_BACK_CARD,
    CLEAR_PLAYER_CARD,
    APPEND_KLAKLOUK_CLEAR_START,
    CLEAR_TO_DEFAULT,
    UPDATE_SELECT_DRAGON_BUTTON,
    UDPDATE_SELECTE_BTN,
    UPDATE_SELECT_COIN,
    APPEND_KLAKLOUK_RESULT,
    CLIENT_RANDOM,
    UPDATE_CURRENT_GAME_RESULT,
    UPDATE_CLIENT_TIME,
    UPDATE_ERROR_MESSAGE,
    LOADING,
    UPDATE_AMOUNT_MX,
    UPDATE_AMOUNT_MIN,
    RESET_KLAKLOUK_CARD,
    LATEST_CHANNEL,
    LATEST_NUMBER_MASTER,
    LATEST_BETTING_TYPE_MASTER,
    LATEST_RESULT,
    TODAY_BETTING,
    CURRENT_BETTING,
    CREATE_BETTING,
    UPDATE_CURRENT_BETTING,
    UPDATE_TODAY_BETTING,
    UPDATE_CHANNEL,
    UPDATE_RESULT,
    LATEST_WEIGHT,
    UPDATE_PAYOUT,
    UPDATE_AMOUNT,
    LATEST_BUTTON,
    RESET_AMOUNT,
    UPDATE_CHANNEL_STATUS,
    RELOAD_PAGE,
    UPDATE_AMOUNT_MAX,
    UPDATE_BET_AMOUNT,
    CLEAR_CACH,
    UPDATE_CH_STATUS,
    BETTING_DIALOG,
    LOADING_TODAY_REPORT,
    UPDATE_COUNTDOWN,
    // Tiger Dragon
    UPDATE_DRAGON_CARD,
    UPDATE_TIGER_CARD,
    UPDATE_FIST_LOAD_TIGER_DRAGON_CARD,
    // Baccarat
    // UPDATE_PLAYER_CARD,
    UPDATE_PLAYER_CARD_UPDATED,
    // UPDATE_BANKER_CARD,
    UPDATE_BANKER_CARD_UPDATED,
    RESET_CARD,
    UPDATE_FIST_LOAD_BACARAT_CARD,
    // Klaklouk
    UPDATE_KLAKLOUK_CARD,
    // lotto
    UPDATE_LOTTO_CARD,
    UPDATE_LOTTO,
    //abong
    UPDATE_ABONG_CARD,
    RESET_ABONG_CARD,
    //YUKI
    UPDATE_YUKI_CARD,
    RESET_YUKI_CARD,
    UPDATE_KLAKLOUK,
    UPDATE_IS_REPORT,
    RESET_COUNTDOWN,
    // TAISIV
    UPDATE_TAISIV,
    RESET_TAISIV_CARD,
    UPDATE_FANTAN,
    // Betting
    IS_SELECTED_COIN,
    UPDATE_FIRST_CARD,
    UPDATE_SECOND_CARD,
};