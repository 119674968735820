<template>
  <div>
    <div id="menu-container" class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center">
      <div class="menu-space">
        <v-row class="ma-0 pl-17 w-100">
          <div class="w-100 d-flex align-center">
            <div class="row ma-0">

              <div class="text-center d-flex align-items-center menu-slot-item " v-for="i in getSubMenuLottery"
                :key="i.name">
                <div class="nav-lottery-main-menu nav-icon-wr row ma-0 pt-0" @click="_goToRoute('/' + i.name)" :class="_findRouteParent($route.path) == i.name
                ? 'main-menu-span-active'
                : ''
                " v-if="userInfo &&
                getSubMenuLottery &&
                getSubMenuLottery.length > 0 && i.is_enable
                ">
                  <img  :src="_urlToImg(i.name)" class="lottery-main-game-image dancing-image" />
                  <p>{{ i.name }} </p>
                </div>
              </div>
            </div>
          </div>
        </v-row>

      </div>
    </div>

    <div :style="!_checkHeightSubmenu() ? 'max-height:0!important' : ''" id="submenu-container"
      class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center w-100">
      <div class="submenu-space">
        <v-row class="ma-0 pl-17">
          <div class="w-100 d-flex align-center">
            <div class="row my-0 center-screen-mobile" v-if="userInfo && getSubMenuLottery"
              style="max-width:100%; width:100%">
              <div class="text-center d-flex align-items-center menu-slot-item" v-for="i in calculateSubMenu()"
                :key="i.name">
                <div v-if="userInfo &&
                getSubMenuLottery &&
                getSubMenuLottery.length > 0 &&
                i && i.name && i.is_enable
                " class="ma-0 lottery-submenu-item w-100" @click="
                i.name ?
                  _goToRoute(
                    '/' +
                    i.name
                      .replace('(', '')
                      .replace(')', '')
                      .toUpperCase()
                  ) : ''
                " :class="channelId == i.channel_type
                ? 'menu-lottery-active'
                : ''
                ">
                  <p>{{ _submenuDefineName(channelId, i.name)}} </p>
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/lottery-tabs.css";
import lotteryMenuFunction from "../../mixin/MX_LotteryMenuFunctionCollection.js";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  mixins: [lotteryMenuFunction],
  data: () => ({
    audioLogItems: [],
    chosenName: "",
  }),
  computed: {

    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      channelId: "channelId",
      getMainMenu: "getMainMenu",
      getSubMenuLottery: "getSubMenuLottery",
      getSubMenuLotteryMenuLotto: "getSubMenuLotteryMenuLotto",
      getSubMenuLotteryMenuYuki: "getSubMenuLotteryMenuYuki",
      getSubMenuLotteryMenuKeno: "getSubMenuLotteryMenuKeno",
      getSubMenuLotteryMenuLottery2d: "getSubMenuLotteryMenuLottery2d",
      getSubMenuLotteryMenuLottery3d: "getSubMenuLotteryMenuLottery3d",
      getSubMenuLotteryMenuLotteryVn: "getSubMenuLotteryMenuLotteryVn"
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  mounted() {
    this.UPDATE_CHANNEL_ID(this.$cookies.get('sbc_channel_type'));
  },
  methods: {
    _findMenuActiveMenu(routePath, name){
      if((routePath == '/LOTTERYVN1' || routePath == '/LOTTERYVN2')){
        return routePath == this._removeSpace('/' + name)
      }
      else{
        return routePath == this._removeSpace('/' + name)
        }
      },
    _submenuDefineName(channelType, name){
      if(channelType == '79' || channelType == '791') return name.replace("(", " ").replace(")", "").replace("LOTTERY", "").replace(' CH','');
      else return name.replace("(", " ").replace(")", "");
    },
    _calculateActive(channelId, menuId) {
      if (channelId == menuId) return true;
      else return false
    },
    _checkHeightSubmenu() {
      let submenu = this.getSubMenuLottery[
        this._calculateMenuArray(this.$route.path)
      ].channels;
      for (let i in submenu) {
        if (submenu[i].is_enable == true) return true;
      }
      return false;
    },
    _checkHeightMenu() {
      let submenu = this.getSubMenuLottery[
        this._calculateMenuArray(this.$route.path)
      ].channels;
      for (let i in submenu) {
        if (submenu[i].is_enable == true) return true;
      }
      return false;
    },
    _goToRoute(path) {
      var selectedChannel = 90;
      if ( path == "/LOTTO160S") {
        path = '/LOTTO';
        selectedChannel = 90
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == "/LOTTO100S") {
        path = '/LOTTO';
        selectedChannel = 901
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == '/LOTTO' || path == "/LOTTO30S") {
        selectedChannel = 902;
        path = '/LOTTO';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      } else if (path == "/YUKI160S") {
        path = '/YUKI';
        selectedChannel = 91
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == "/YUKI100S") {
        selectedChannel = 911;
        path = '/YUKI';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == "/YUKI" || path == "/YUKI30S") {
        selectedChannel = 912;
        path = '/YUKI';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if ( path == "/LOTTERY160S") {
        selectedChannel = 92;
        path = '/LOTTERY2D';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == "/LOTTERY100S") {
        selectedChannel = 921;
        path = '/LOTTERY2D';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == '/LOTTERY 2D' || path == "/LOTTERY30S") {
        selectedChannel = 922;
        path = '/LOTTERY2D';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if ( path == '/LOTTERY3160S') {
        selectedChannel = 89;
        path = '/LOTTERY3D';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == '/LOTTERY3100S') {
        selectedChannel = 891;
        path = '/LOTTERY3D';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == "/LOTTERY 3D" || path == '/LOTTERY330S') {
        selectedChannel = 892;
        path = '/LOTTERY3D';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }

      else if ( path == '/KENO160S') {
        selectedChannel = 99;
        path = '/KENO';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == '/KENO100S') {
        selectedChannel = 991;
        path = '/KENO';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == "/KENO" || path == '/KENO30S') {
        selectedChannel = 992;
        path = '/KENO';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == '/LOTTERY VN' || path == '/LOTTERYVN CH1') {
        selectedChannel = 79;
        path = '/LOTTERYVN1';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }
      else if (path == '/LOTTERYVN CH2') {
        selectedChannel = 791;
        path = '/LOTTERYVN2';
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.UPDATE_CHANNEL_ID(selectedChannel);
      }

      this.UPDATE_EXPAND_MENU(true)
      this.$router.push({ path }).catch(() => {
        /* ignore */
      });
    },
    calculateSubMenu() {
      let selectedGame = this.$route.path;
      switch (true) {
        case (selectedGame == '/LOTTO' || selectedGame == '/LOTTO160S' || selectedGame == '/LOTTO100S' || selectedGame == '/LOTTO30S'):
          return this.getSubMenuLotteryMenuLotto;
        case (selectedGame == '/YUKI' || selectedGame == '/YUKI160S' || selectedGame == '/YUKI100S' || selectedGame == '/YUKI30S'):
          return this.getSubMenuLotteryMenuYuki;
        case selectedGame == '/LOTTERY2D':
          return this.getSubMenuLotteryMenuLottery2d;
        case selectedGame == '/KENO' || selectedGame == '/KENO160S' || selectedGame == '/KENO100S' || selectedGame == '/KENO30S':
          return this.getSubMenuLotteryMenuKeno;
        case selectedGame == '/LOTTERY3D' || selectedGame == '/LOTTERY3160S' || selectedGame == '/LOTTERY3100S' || selectedGame == '/':
          return this.getSubMenuLotteryMenuLottery3d;
        case selectedGame == '/LOTTERYVN1' || selectedGame == '/LOTTERYVN2':
          return this.getSubMenuLotteryMenuLotteryVn;
        default: return 0
      }
    },
    _removeSpace(word) {
      let wordWithoutSpaces = word.replace(/\s/g, "");
      return wordWithoutSpaces;
    },

    ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_EXPAND_MENU"
    ]),
  },
};
</script>
