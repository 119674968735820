/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'
let request = new Request;

const fetchLastResults = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotto/result',
        data
    })
    if (response.data.code) {
        let getLastFiveResults = response.data.data.slice(0, 5);
        commit('LATEST_FIVE_RESULTS', getLastFiveResults);
        commit('LATEST_TWENTY_RESULTS', response.data.data);
        commit('LATEST_ONE_RESULTS', response.data.data[0]);
    }
};


const fetchButton = async ({commit}) => {
    const response = await request.getSlot({
        url: 'm_lotto/button'
    })
    if (response.data.code) {
        commit('GET_BUTTON', response.data.data);
    }
};


const fetchCurrentBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotto/betting/current',
        data
    })
    if (response.data.code) {
        commit('CURRENT_BETTING', response.data.data);
    }
};

const fetchPreviousBetting = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotto/betting/previous',
        data
    })
    if (response.data.code) {
        commit('PREVIOUS_BETTING', response.data.data);
    }
};

const fetchCurrentGame = async ({commit}, data) => {
    
    const response = await request.getSlot({
        url: 'm_lotto/game',
        data
    })
    if (response.data.code) {
        commit('CURRENT_GAME', response.data.data);
    }
};

const fetchBettingType = async ({commit}, data) => {
    const response = await request.getSlot({
        url: 'm_lotto/type',
        data
    })
    if (response.data.code) {
        commit('HEAD_TAIL_TYPE', response.data.data.slice(0, 2));
        commit('RANGE_TYPE', response.data.data.slice(2, 7));
    }
};

const createBet = async ({commit}, {vm, data}) => {
    commit('IS_BETTING', true);
    const response = await request.postSlot({
        url: 'm_lotto/betting',
        data
    })
    if (response.data.code) {
        commit('IS_BETTING', false);
        const response1 = await request.getSlot({
            url: 'm_lotto/betting/current',
            data
        })
        if (response1.data.code) {
            commit('CURRENT_BETTING', response1.data.data);
        }
        vm.$toastr.s(`${response.data.message.descriptions}`)
    }else{
        vm.$toastr.e(`${response.data.message.descriptions}`)
        commit('IS_BETTING', false);
    }
};


export default {
	fetchLastResults,
    fetchCurrentBetting,
    fetchPreviousBetting,
    fetchCurrentGame,
    fetchBettingType,
    createBet,
    fetchButton
};
