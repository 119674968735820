<template>
  <div class="lottery3d-mid">
    <DisplayMainResults :ass_a="ass_a" :ass_b="ass_b" :ass_c="ass_c" :ass_d="ass_d" :ass_e="ass_e" />
    <LotteryBetting />
  </div>
</template>
<script>

import DisplayMainResults from "./SubComponent/MidComponents/DisplayMainResults.vue"
import LotteryBetting from "./SubComponent/MidComponents/LotteryBetting.vue";

export default {
  components: {
    DisplayMainResults,
    LotteryBetting
  },
  props: ['ass_a', 'ass_b', 'ass_c', 'ass_d', 'ass_e'],
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>
