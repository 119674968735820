var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"main"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-img',{attrs:{"lazy-src":require('~public_image/a_each_game/taixiu/taixiu.jpg'),"src":require('~public_image/a_each_game/taixiu/taixiu.jpg')}},[_c('machineChannelInfo',{attrs:{"passGameName":_vm.gameName}}),_c('div',{attrs:{"id":"my-wraper"}},[_c('div',{staticClass:"game-tx-box game-tx-box-center-img px-2"},[_c('v-img',{staticStyle:{"width":"40%"},attrs:{"id":"tx-card-animation","src":_vm.card}})],1),_c('table',{staticClass:"tb-tx-game",staticStyle:{"color":"#ffffff","text-align":"center","font-weight":"bold"}},[_c('tr',[_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value < 7
                    ? 'blink-s-taixiu'
                    : '',staticStyle:{"position":"relative"}},[_c('h4',[_vm._v("SMALL")]),_c('h5',[_vm._v("1:1.95")]),_c('div',{staticClass:"rx-kl-item"})]),_c('td',{staticStyle:{"color":"#ffffff","text-align":"center","font-weight":"bold","vertical-align":"bottom !important"}},[_c('h6',[_vm._v("A-K = 12")]),_c('h6',[_vm._v("Big = 1:1.95")]),_c('h6',[_vm._v("Small = 1:1.95")])]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value > 7
                    ? 'blink-b-taixiu'
                    : '',staticStyle:{"position":"relative"}},[_c('h4',[_vm._v("BIG")]),_c('h5',[_vm._v("1:1.95")]),_c('div',{staticClass:"rx-kl-item"})])])]),_c('table',{staticClass:"tb-tx-game"},[_c('tr',[_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 1
                    ? 'blink-s-taixiu'
                    : '',staticStyle:{"position":"relative"}},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" A ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 2
                    ? 'blink-s-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 2 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 3
                    ? 'blink-s-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 3 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 4
                    ? 'blink-s-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 4 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 5
                    ? 'blink-s-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 5 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 6
                    ? 'blink-s-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 6 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 7
                    ? 'blink-g-taixiu'
                    : '',staticStyle:{"color":"greenyellow"}},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 7 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 8
                    ? 'blink-b-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 8 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 9
                    ? 'blink-b-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 9 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 10
                    ? 'blink-b-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" 10 ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 11
                    ? 'blink-b-taixiu'
                    : '',staticStyle:{"padding":"0 22px"}},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" J ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 12
                    ? 'blink-b-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" Q ")]),_c('td',{staticClass:"table-tr-td",class:_vm.getClientCard && _vm.getClientCard.value == 13
                    ? 'blink-b-taixiu'
                    : ''},[_c('div',{staticClass:"rx-kl-item-small"}),_vm._v(" K ")])])])])],1),_c('div',{staticClass:"td-case-x"},[_c('div',{staticClass:"td-case-taixiu td-x-hidden",style:({ backgroundImage: 'url(\'' + 'images/a_game_access_global/cards/back_card.png' + '\')' })},[_c('img',{attrs:{"id":"f-result-x"}})]),_c('div',{staticClass:"td-case"})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }