<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passIndex}}.{{ passChDesc }} </h5>
    <div class="game-machine-component-result">
      <div class="trends limit-on-tab"  style="position: relative;">
        <!-- <div v-if="showResult && showResult.channel_id!=null" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
              <tr style="height: 38px;">
                <th style="background-color: rgba(40, 90, 226, .93); width: 50%;"><b>Player</b></th>
                <th style="background-color: rgba(224, 80, 73, .93); width: 50%;"><b>Banker</b></th>
              </tr>
              <tr>
                <td style="background-color: rgba(40, 90, 226, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{totalP}}</b></td>
                <td style="background-color: rgba(224, 80, 73, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{totalB}}</b></td>
              </tr>
            </table>
          </div>
        </div> -->
        <div v-if="passTime && !passTime.is_open_betting && passTime.time == 0" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;">
        <!-- <div style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;"> -->
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
              <tr style="height: 38px;">
                <th style="background-color: rgba(40, 90, 226, .93); width: 50%;"><b>Player</b></th>
                <th style="background-color: rgba(224, 80, 73, .93); width: 50%;"><b>Banker</b></th>
              </tr>
              <tr>
                <td style="background-color: rgba(40, 90, 226, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;">
                  <div style="position: relative; left: 60%; top: 0%; transform: translateX(-50%);">
                    <div id="blockCardsCasino">
                      <img id="block01P" v-if="block01P " :src="block01P" style="max-width: 55px !important; height: auto; margin-right: 10px" class="blockcards-size" />
                      <img id="block02P" v-if="block02P" :src="block02P" style="max-width: 55px !important; margin-right: 10px" class="blockcards-size" />
                      <img id="block03P" v-if="isShow3P" style="max-width: 55px !important; height: auto" :src="block03P" class="card-animation blockcards-size" />
                    </div>
                  </div>
                </td>
                <td style="background-color: rgba(224, 80, 73, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;">
                  <div style="position: relative; left: 60%; top: 0%; transform: translateX(-50%);">
                    <div id="blockCardsCasino">
                      <img id="block01B" v-if="block01B" :src="block01B" style="max-width: 55px !important; height: auto; margin-right: 10px" class="blockcards-size" />
                      <img id="block02B" v-if="block02B" :src="block02B" style="margin-right: 10px; max-width: 55px !important; height: auto" class="blockcards-size" />
                      <img id="block03B" v-if="isShow3B" :src="block03B" class="card-animation blockcards-size" style="max-width: 55px !important; height: auto" />
                    </div>
                  </div>
                </td>
              </tr>
              <!-- <tr>
                <td style="background-color: rgba(40, 90, 226, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{showResult.card_tiger.value}}</b></td>
                <td style="background-color: rgba(224, 80, 73, .83); font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{showResult.card_dragon.value}}</b></td>
              </tr> -->
            </table>
          </div>
        </div>
    <div>
      <div class="trend-legend-custome gp-panel-bg d-flex justify-content-between align-items-center" style="background-color: #ffffff !important" >      
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-wala">{{resultObj.summary ? resultObj.summary.P : 0}}</span>
          <p class="mb-0 count-label">Player</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-wala">{{resultObj.summary ? resultObj.summary.PP : 0}}</span>
          <p class="mb-0 count-label">Pair Player</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-meron">{{resultObj.summary ? resultObj.summary.B : 0}}</span>
          <p class="mb-0 count-label">Banker</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-meron">{{resultObj.summary ? resultObj.summary.PB : 0}}</span>
          <p class="mb-0 count-label">Pair Banker</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1">
          <span class="count rounded-circle trend-draw">{{resultObj.summary ? resultObj.summary.T : 0}}</span>
          <p class="mb-0 count-label">Tie</p>
        </div>
      </div>
      <div class="px-3 pb-0 gp-panel-bg rounded-bottom landscape-trend"
      style="position: relative; background-color: #ffffff !important">
        <v-col cols="12" sm="12" style="padding: 0">
          <v-row style="padding: 0; margin: 0">
            <v-col
              cols="6"
              md="6"
              sm="6"
              lg="6"
              style="padding: 0 5px 0 0; margin: 0">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="machine-table" border="1px">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in fixColBac" :key="col">
                        <div>
                          <div v-for="(result, i) in orderBaccaratResult" :key="i">
                            <span v-if="col == result.col && row == result.row">
                              <v-badge
                                v-if="result.is_pair_player && result.is_pair_banker "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10" >
                                <v-badge
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-avatar class="bar-result"
                                  :class="'a' + result.color"
                                    size="18"
                                    style="
                                      margin: 0px;
                                      color: #ffffff;
                                      font-weight: bold;
                                    "
                                  >
                                    {{
                                      result.name == "BANKER"
                                        ? "B"
                                        : result.name == "PLAYER"
                                        ? "P"
                                        : result.name == "TIE"
                                        ? "T"
                                        : "C"
                                    }}
                                  </v-avatar>
                                </v-badge>
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  result.is_pair_player &&
                                  !result.is_pair_banker
                                "
                                bordered
                                bottom
                                color="blue"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-avatar
                                :class="'a' + result.color"
                                  class="bar-result"
                                    size="18"
                                  style="
                                    margin: 0px;
                                    color: #ffffff;
                                    font-weight: bold;
                                  "
                                >
                                  {{
                                    result.name == "BANKER"
                                      ? "B"
                                      : result.name == "PLAYER"
                                      ? "P"
                                      : result.name == "TIE"
                                      ? "T"
                                      : "C"
                                  }}
                                </v-avatar>
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  !result.is_pair_player &&
                                  result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-avatar
                                :class="'a' + result.color"
                                  class="bar-result"
                                    size="18"
                                  style="
                                    margin: 0px;
                                    color: #ffffff;
                                    font-weight: bold;
                                  "
                                >
                                  {{
                                    result.name == "BANKER"
                                      ? "B"
                                      : result.name == "PLAYER"
                                      ? "P"
                                      : result.name == "TIE"
                                      ? "T"
                                      : "C"
                                  }}
                                </v-avatar>
                              </v-badge>
                              <v-avatar v-else
                              :class="'a' + result.color"
                                class="bar-result"
                                    size="18"
                                style="
                                  margin: 0px;
                                  color: #ffffff;
                                  font-weight: bold;">
                                {{
                                  result.name == "BANKER"
                                    ? "B"
                                    : result.name == "PLAYER"
                                    ? "P"
                                    : result.name == "TIE"
                                    ? "T"
                                    : "C"
                                }}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="6" lg="6" style="padding: 0; margin: 0">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="machine-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in fixCol" :key="col">
                        <div>
                          <div v-for="(result, i) in orderResult" :key="i">
                            <span v-if="col == result.col && row == result.row">
                              <v-badge
                                v-if="
                                  result.is_pair_player && result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-badge
                                  bordered
                                  bottom
                                  color="blue"
                                  dot
                                  offset-x="10"
                                  offset-y="10"
                                >
                                  <v-icon
                                class="icon-result"
                                    :class="'c' + result.color"
                                    style="padding: 0; margin: 0"
                                    >mdi-circle-outline</v-icon
                                  >
                                </v-badge>
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  result.is_pair_player &&
                                  !result.is_pair_banker
                                "
                                bordered
                                bottom
                                color="blue"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-icon
                                class="icon-result"
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </v-badge>
                              <v-badge
                                v-else-if="
                                  !result.is_pair_player &&
                                  result.is_pair_banker
                                "
                                bordered
                                left
                                color="red"
                                dot
                                offset-x="10"
                                offset-y="10"
                              >
                                <v-icon
                                class="icon-result"
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </v-badge>
                              <span v-else>
                                <v-icon
                                class="icon-result"
                                  :class="'c' + result.color"
                                  style="padding: 0; margin: 0"
                                  >mdi-circle-outline</v-icon
                                >
                              </span>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>
  </div>
</div>
    <div class="game-machine-component-reserve-space"></div>
  </div>
</template>
<script>

import shareFunction from "~mixin/MX_ShareFunctionCollection.js";
import { mapActions } from "vuex";
export default {
  mixins: [shareFunction],
  props:['baccaratScanCard', 'passGame', 'passTime', 'passIndex', 'channelType', 'resultObjS', 'passChDesc', 'showResult', 'totalP','totalB'],
  data() {
    return {
      rows: 6,
      cols: 20,
      fixCol: 30,
      fixColBac: 20,
      resultObj: {},
      block01P: require('~public_image/a_game_access_global/cards/back_card.png'),
      block02P: require('~public_image/a_game_access_global/cards/back_card.png'),
      block03P: require('~public_image/a_game_access_global/cards/back_card.png'),
      block01B: require('~public_image/a_game_access_global/cards/back_card.png'),
      block02B: require('~public_image/a_game_access_global/cards/back_card.png'),
      block03B: require('~public_image/a_game_access_global/cards/back_card.png'),
      playerCard1: "0052",
      playerCard2: "0052",
      playerCard3: "0052",
      bankerCard1: "0052",
      bankerCard2: "0052",
      bankerCard3: "0052",
      isShow3B: false,
      isShow3P: false,
    }
  },
  watch: {
    resultObjS: function() {
      // console.log(`BaccaratResult`);
      this.getResult(this.channelType);
    },
    baccaratScanCard: function(newVal){
      console.log('watch_baccaratScanCard', `${JSON.stringify(newVal)}`);
      if(newVal.banker_cards){
        if(newVal.banker_cards.length > 0){
          if(newVal.banker_cards.length == 3){
            this.isShow3B = true;
          }
          for (var index = 0; index <= newVal.banker_cards.length; index++) {
            // console.log('watch_scanbanker_cards', `${JSON.stringify(newVal.banker_cards[index].name)}`);
            if(newVal.banker_cards[index].scan_number == 1){
              if (this.bankerCard1 == "0052"){
                new Promise((resolve) => setTimeout(resolve, 1500));
                this.bankerCard1 = "../../../../../../images/a_game_access_global/cards_animation/" + newVal.banker_cards[index].name + ".gif";
                this.block01B = this.bankerCard1
              }
            }else if(newVal.banker_cards[index].scan_number == 2){
              if (this.bankerCard2 == "0052"){
                new Promise((resolve) => setTimeout(resolve, 1500));
                this.bankerCard2 = "../../../../../../images/a_game_access_global/cards_animation/" + newVal.banker_cards[index].name + ".gif";
                this.block02B = this.bankerCard2
              }
            }else if(newVal.banker_cards[index].scan_number == 3){
              if (this.bankerCard3 == "0052"){
                new Promise((resolve) => setTimeout(resolve, 1500));
                this.bankerCard3 = "../../../../../../images/a_game_access_global/cards_animation/" + newVal.banker_cards[index].name + ".gif";
                this.block03B = this.bankerCard3
              }
            }
          }
        }
      }else{
        this.isShow3B = false;
        this.bankerCard1 = "0052";
        this.bankerCard2 = "0052";
        this.bankerCard3 = "0052";
        this.block03B = require('~public_image/a_game_access_global/cards/back_card.png');
        this.block02B = require('~public_image/a_game_access_global/cards/back_card.png');
        this.block01B = require('~public_image/a_game_access_global/cards/back_card.png');
      }
      if(newVal.player_cards){
        if(newVal.player_cards.length > 0){
          if(newVal.player_cards.length == 3){
            this.isShow3P = true;
          }
          for (var i = 0; i <= newVal.player_cards.length; i++) {
            // console.log('watch_scanplayer_cards', `${JSON.stringify(newVal.player_cards[i].name)}`);
            if(newVal.player_cards[i].scan_number == 1){
              if (this.playerCard1 == "0052"){
                new Promise((resolve) => setTimeout(resolve, 1500));
                this.playerCard1 = "../../../../../../images/a_game_access_global/cards_animation/" + newVal.player_cards[i].name + ".gif";
                this.block01P = this.playerCard1
              }
            }else if(newVal.player_cards[i].scan_number == 2){
              if (this.playerCard2 == "0052"){
                new Promise((resolve) => setTimeout(resolve, 1500));
                this.playerCard2 = "../../../../../../images/a_game_access_global/cards_animation/" + newVal.player_cards[i].name + ".gif";
                this.block02P = this.playerCard2
              }
            }else if(newVal.player_cards[i].scan_number == 3){
              if (this.playerCard3 == "0052"){
                new Promise((resolve) => setTimeout(resolve, 1500));
                this.playerCard3  = "../../../../../../images/a_game_access_global/cards_animation/" + newVal.player_cards[i].name + ".gif";
                this.block03P = this.playerCard3
              }
            }
          }
        }
      }else{
        this.isShow3P = false;
        this.playerCard1 = "0052";
        this.playerCard2 = "0052";
        this.playerCard3 = "0052";
        this.block01P = require('~public_image/a_game_access_global/cards/back_card.png');
        this.block02P = require('~public_image/a_game_access_global/cards/back_card.png');
        this.block03P = require('~public_image/a_game_access_global/cards/back_card.png');
      }
    }
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
    orderBaccaratResult() {
      return this.getBaccaratResults();
    },
    getColor(resultName) {
      if (resultName == "BANKER") {
        return "error";
      } else if (resultName == "PLAYER") {
        return "primary";
      } else if (resultName == "TIE") {
        return "success";
      } else {
        return "default";
      }
    },
  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  methods: {
    // async getPlayerCard1() {
    //   if (this.getPlayerCard1 != "0052") {
    //     var element1P = document.getElementById("block01P");
    //     element1P.style.display = "block";
    //     this.card1 = this.getPlayerCard1;
    //     await new Promise((resolve) => setTimeout(resolve, 1500));
    //     let p1 = "images/a_game_access_global/cards_animation/" + this.card1 + ".gif";
    //     this.block01P = p1
    //     // this._playSoundOpenCard();
    //   } 
    // },
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        //console.log(`getResult2 >>>> ${response}`)
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    getBaccaratResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj.results != null) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          // //console.log(item)
          if (row <= this.rows) {
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            row = 1;
            col += 1;
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
        if (col > this.cols) {
          this.fixColBac = col;
        } else {
          this.fixColBac = this.cols;
        }
      }
      return newResult;
    },
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (
        this.resultObj.results != null &&
        this.resultObj.results.length > 0
      ) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (pre.name != item.name) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              if (pre.row >= this.rows) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col;
        } else {
          this.fixCol = this.cols;
        }
      }
      //console.log(newResult)
      return newResult;
    },
    ...mapActions("$_machine_games", ["fetchMaBaResult"]),
  }
}
</script>
<style>
  /* .v-icon.v-icon{
    font-size: 18px !important
  } */

  .v-application .pb-3{
    padding-bottom: 2px !important;
  }
  #blockCardsCasino {
  z-index: 3;
  position: absolute;
  top: -55px;
  }
</style>