
const getLastResults = (state) => state.lastResults;
const getCurrentBetting = (state) => state.currentBetting;
const getPreviousBetting = (state) => state.previousBetting;
const getCurrentGame = (state) => state.currentGame;
const getLotteryChannel = (state) => state.lotteryChannel;
const getLastOneResult = (state) => state.lastOneResult;
const getLastTwentyResult = (state) => state.lastTwentyResult;
const getIsBetting = (state) => state.is_Betting;
const getButtonMoney = (state) => state.buttonMoney;
const getLastFiveResults = (state) => state.lastFiveResults;
const getBettingAmount = (state) => state.bettingAmount;
const getBettingData = (state) => state.bettingData;
const getNumbers = (state) => state.numbers;
const getSelectedNumber = (state) => state.selectedNumber;
const getSelectAllPost = (state) => state.selectedAllPost;
const getSelectedBettingType = (state) => state.bettingType;
const getSelectedChannelPost = (state) => state.selectedPost;
const getSelectedListBettingNumber = (state) => state.selectedListBettingNumber;
const getTotalInvoice = (state) => state.totalInvoice;
const getClientTime = (state) => state.clientTime;
const getLimit = (state) => state.limitLottery3Betting;
const getListDetailInvoice = (state) => state.ListDetailInvoice;
const getDialogInvoice = (state) => state.dialogDetailInovice;
const getLoadingDetailDialog = (state) => state.loadingDetailDialog;
const getLottery3Output = (state) => state.output;
const getLottery3Result_A = (state) => state.result_a;
const getLottery3Result_B= (state) => state.result_b;
const getLottery3Result_C = (state) => state.result_c;
const getLottery3Result_D = (state) => state.result_d;
const getLottery3Result_E = (state) => state.result_e;
const getNormalBetNumber = (state) => state.selectNormalBetNumber;
const getMultiBetNumber = (state) => state.selectMultiBetNumber;
const getselectedLo = (state) => state.selectedLo;
const getselectedMulti = (state) => state.selectedMulti;
const getselectedNormal = (state) => state.selectedNormal;
const getselectedA = (state) => state.betA;
const getselectedB = (state) => state.betB;
const getselectedC = (state) => state.betC;
const getselectedD = (state) => state.betD;
const getselectedE = (state) => state.betE;
const getClearBettingNumber = (state) => state.clearBettingNumber;

export default {
    getClearBettingNumber,
    getselectedA,
    getselectedB,
    getselectedC,
    getselectedD,
    getselectedE,
    getselectedNormal,
    getselectedLo,
    getselectedMulti,
    getNormalBetNumber,
    getMultiBetNumber,
    getLottery3Result_A,
    getLottery3Result_B,
    getLottery3Result_C,
    getLottery3Result_D,
    getLottery3Result_E,
    getLottery3Output,
    getLoadingDetailDialog,
    getListDetailInvoice,
    getLimit,
    getClientTime,
    getTotalInvoice,
    getSelectAllPost,
    getSelectedListBettingNumber,
    getSelectedChannelPost,
    getSelectedBettingType,
    getSelectedNumber,
    getNumbers,
    getBettingData,
    getBettingAmount,
    getLastResults,
    getCurrentBetting,
    getPreviousBetting,
    getCurrentGame,
    getLotteryChannel,
    getLastOneResult,
    getLastTwentyResult,
    getIsBetting,
    getButtonMoney,
    getLastFiveResults,
    getDialogInvoice,
};
