export default {
  permission_list: "Permission List",
  role_list: "Role List",
  user_list: "User List",
  menu_list: "Menu List",
  create_permission: "Create Permission",
  update_permission: "Update Permission",
  create_role: "Create Role",
  update_role: "Update Role",
  create_user: "Create User",
  update_user: "Update User",
  create_menu: "Create Menu",
  update_menu: "Update Menu",
  change_password_user: "Change Password User",
  welcome: "Welcome:",
  account_balance: "Account Balance",
  user_role: "User Role",
  please_detail :"See Detail",
  No: "NO",
  no: "NO",
  your_balance: "Your Balance",
  home: "Home",
  sbc: "SBC",
  status: "Status",
  time: "TIME",
  result: "Result",
  five_k: "1.5 $",
  ten_k: "2.5 $",
  twenty_k: "5 $",
  five_k1: "12.5 $",
  ten_100k: "25 $",
  twenty_200k: "50 $",
  five_500k: "125 $",
  one_1m: "1M $",
  payout: "PAYOUT",
  current_betting: "BETTING",
  today_report: "REPORT",
  type: "Type",
  amount: "Amount",

  action: "Action",
  my_profile: "My Profile",
  persional_info: "PERSONAL",
  change_password: "CHANGE PASSWORD",
  result_betting: "RESULT BETTING",
  result_win: "RESULT WIN",
  report: "REPORT",
  MERON: "MERON",
  WALA: "WALA",
  login: "LOGIN",
  deposit: "Deposit",
  withdraw: "Withdraw",
  betting: "Statement",
  statement:"Statement",
  all_betting: "Statement(All)",
  betting1: "BET",
  channel1: "CHANNEL 1",
  channel2: "CHANNEL 2",
  channel3: "CHANNEL 3",
  channel4: "CHANNEL 4",
  min: "MIN",
  max: "MAX",
  log: "ចូល​ | LOGIN",
  logging: "Logging in",
  changeLanguage: "Change Language",
  password: "Enter your password",
  SignIn: "Secure Login",
  bet_between: "Range",
  bet_small_large: "Under / Over",
  all_statement: "All Statement",
  total_win_lose: "Total Win/Lose",
  start_date: "Start Date",
  end_date: "End Date",
  fight_no: "Fight No",
  channel: "Channel",
  group_no: "Group No",
  date_time: "Date Time",
  cast: "Cash",
  amount_win: "Amount Win",
  total_amount: "Total Amount",
  // start from here
  TIGER: "Tiger",
  DRAGON: "Dragon",
  gameIsClose: "Game is close",
  'This Game is close': 'This Game is close',
  tie: "TIE",
  draw: "DRAW",
  fight_sign: "#",
  fight: "Fight",
  cancel: "CANCEL",
  confirm: "CONFIRM",
  Close: "CLOSE",
  Open: "OPEN",
  Not_Open: "LIVE",
  //sidebar
  user_center: "User Center",
  all_report: "All Report",
  cash_ins: "Cash Ins",
  withdrawals: "Withdrawals",
  languages: "Languages",
  other: "Other",
  logout: "Logout",
  small: "UNDER",
  large: "OVER",
  thin: "Under",
  thick: "Over",
  return: "Return",
  Old_Balance: "Old Balance",
  New_Balance: "New Balance",
  old_balance: "Old Balance",
  game: "Game No",
  bettingType: "BETTING TYPE",
  new_balance: "New Balance",
  Amount: "Amount",
  dateTime: "Date Time",
  cash: "Cash",
  Cash: "Cash",
  Rdeposit: "Deposit Report",
  Rwithdraw: "Withdraw Report",
  red: "Red",
  blue: "Blue",
  even: "EVEN",
  odd: "ODD",
  selectCoin: "Please Select Coin",
  _result: "Result= ",
  betting_status: "BETTING",
  meron: "MERON",
  wala: "WALA",
  bet_wala: "BET WALA",
  bet_meron: "BET MERON",
  bet_draw: "BET DRAW",
  bet_tie: "BET DRAW",
  TIE: "Tie",
  credit: "CREDIT",
  Rememberpassword: "Remember Password",
  date: "Date",
  sender: "Sender",
  reciever: "Receiver",
  bank_reciever: "Bank Receiver",
  account_name: "Account Name",
  send_date: "Send Date",
  receive_date: "Receive Date",
  register: "ចុះឈ្មោះ | Register",
  promote_login_header: "Promotion fisrt deposit 100%",
  chat_with_one: "Chat with sbc2888",
  need_help: "Hi! How can we help you?",
  chat_now: "Chat now",
  register_member: "REGISTER MEMBER",
  user_name: "User Name",
  full_name: "Full Name",
  confirm_password: "Confirm Password",
  password_register: "Password",
  contact_no: "Contact Number",
  confirm_age: " I am at least 18 years of age and I have read, ",
  confirm_age_red:
    "accept and agree to the Terms & Conditions, Rules, Privacy Policy, Cookie Policy and policies relating to age verification",
  check_confirm_password: "Not match",
  success_register: "register sucessful",
  account_number: "Account Number",
  note: "Note",
  current_password: "Current Password",
  new_password: "New Password",
  field_required: "This field is required",
  hotline: "Hotline",
  required: "Please match the request format 1",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  PENDING: "PENDING",
  REJECT: "Reject",
  SUCCESS: "Success",
  choseBank: "Choose Bank",
  game_is_close: "Game closed",
  not_enought_balance: "Not Enought Balance",
  inputAmount: "Please Input Amount",
  limit: "Tie Limit",
  betting_count_over_limit: "Betting Count Over Limit",
  copy: "Copy",

  game_no: "GAME No:",
  betLimit: "Bet Limit",
  total_betting_an_invoice: "Total In an invoice",
  yuki_wipe: "Rat",
  yuki_street: "Ox",
  yuki_karl: "Tiger",
  yuki_thao: "Rabbit",
  yuki_factory: "Dragon",
  yuki_net: "Snake",
  yuki_thin: "Under",
  yuki_thick: "Over",
  yuki_mimi: "Horse",
  yuki_mom: "Goat",
  yuki_vk: "Monkey",
  yuki_roka: "Rooster",
  yuki_kor: "Pig",
  yuki_odd: "Odd",
  yuki_pair: "Pair",
  yuki_red: "Red",
  yuki_blue: "Blue",
  yuki_dog: "Dog",
  yuki_null: "null",
  outstanding: "Outstanding",
  lo_current_betting: "Current Betting",
  lo_previous_betting: "Previous Betting",
  ticket: "Ticket",
  invoice_detail: "Invoice Detail",
  game_id: "Game ID",
  result_year: "Result Year",
  result_small_big: "Result Small/Big",
  BIG: "BIG",
  big: "BIG",
  result_range: "Result Range",
  odd_even: "Odd / Even",
  blue_red: "Blue/Red",
  no_data: "No data",
  total_betting_a_game: "Total In a game",
  pleaseBet: "Please Bet",
  submit_betting: "Sumit",
  bet_type: "BETTINGTYPE",
  combination:"combination",
  yuki_bet_amount: "Please enter your bet amount!",
  yuki_enough_balance: "Not Enough Balance",
  yuki_bet_limit: "Bet amount must between bet limit",
  yuki_select_your_bet: "Please select your bet!",
  normal_betting: "Normal",
  go_with_tail: "Bet Tail",
  go_with_head: "Bet Head",
  all_post: "All",
  post_channel: "Post",
  lottery_result: "Lottery Result",
  game_close: "BETTING CLOSE",
  D:"D",
  player:"PLAYER",
  banker:"BANKER",
  '19':"19",
  "pair player":"PAIR PLAYER",
  "pair banker":"PAIR BANKER",
  "PAIR PLAYER" : "PAIR PLAYER",
  "PAIR BANKER" : "PAIR BANKER",
  tiger:"TIGER",
  dragon:"DRAGON",
  Dragon:"DRAGON",
  over:"OVER",
  under:"UNDER",
  Rooster:"Rooster",
  Rabit:"Rabit",
  Rat:"Rat",
  a:"A",
  b:"B",
  c:"C",
  // lotto
  total_betting: "Total Betting",
  total: "Total",

  total_amount_win: "Total Repayment",
  yuki_war: "War",
  bet_total: "Total Amount",
  total_cast: "Total Cash",

  result_number: "Result Number",
  x_win: "Win",
  x_lose: "Lose",
  select_bank: "Select Bank",
  clear: "CLEAR",
  BANKER: "BANKER",
  PLAYER: "PLAYER",
  Banker1: "BANKER 1",
  Player1: "PLAYER 1",
  Banker2: "BANKER 2",
  Player2: "PLAYER 2",
  Banker3: "BANKER 3",
  Player3: "PLAYER 3",
  invoice: "Invoices",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  ក្តាម: "Crab",
  ក្ដាម: "Crab",
  មាន់: "COCK",
  ត្រី: "FISH",
  ខ្លា: "TIGER",
  បង្គង: "LOBSTER",
  ឃ្លោក: "GROUD",
  PAIRBANKER: "PAIR BANKER",
  PAIRPLAYER: "PAIR PLAYER",
  បង្កង: "LOBSTER",
  pleaes_detail: "See Detail Invoice",
  game_processing: "In progress...",
  showDetailInvoice: "Detail Invoice",
  banker1: "BANKER-1",
  banker2: "BANKER-2",
  banker3: "BANKER-3",
  player1: "PLAYER-1",
  player2: "PLAYER-2",
  player3: "PLAYER-3",
  tie1: "TIE-1",
  tie2: "TIE-2",
  tie3: "TIE-3",
  '20':'20',
  //sicbo
  small_tx:"SMALL",
  large_tx:"BIG",
  sicbo_small: "SMALL",
  sicbo_large: "BIG",
  sicbo_even: "EVEN",
  sicbo_odd: "ODD",
  17:"17",
  16:"16",
  15:"15",
  14:"14",
  13:"13",
  12:"12",
  11:"11",
  Thin:"Thin",
  Thick:"Thick",
  // Taixiu

  taismall: "SMALL",
  taibig: "BIG",
  taixiusmall: "S",
  taixiubig: "B",
  taixiu7: "7",
  A:"A",
  7:"7",
  8:"8",
  9:"9",
  10:"10",
  '18':"18",
  J:"J",
  Q:"Q",
  K:"K",
  diamond:"♦",
  heart:"♥",
  club:"♣",
  spade:"♠",
  "4-5-6":"4-5-6",
  "A-2-3":"A-2-3",
  "8-9-10":"8-9-10",
  "J-Q-K":"J-Q-K",
  "win":"WIN",
  "lose":"LOSE",
  undefined:"---",
  "Please contact your upline":"Please contact your upline",

  //DD
  card_type:"CARD TYPE",
  dd_small: "SMALL",
  dd_big: "BIG",
  loading:"Loading...",
  c_MERON:'MERON',
  c_WALA:'WALA',
  c_TIE:"TIE",
  c_draw:"TIE",
  c_CANCEL:"CANCEL",
  gameIsLive:"Game will Start Soon...",
  gameIsOpen:"Please Bet, Good Luck",
  boxing_MERON:"Red",
  boxing_WALA:"Blue",
  boxing_draw:"DRAW",
  roulette_MERON:"RED",
  roulette_red:"RED",
  roulette_TIE:"0",
  roulette_draw:"0",
  roulette_WALA:"Black",
  roulette_black:"Black",
  enter:"Enter",
  gameType:"GAME-TYPE",
  totalAmount:"TOTAL-AMOUNT",
  amountWin:'AMOUNT-WIN',
  waitingResult:"Waiting for Result...",
  print:"Print",
  resultStatement:"Result Statement",
  small_odd:"U / ODD",
  small_even:"U / EVEN",
  large_odd:"O / ODD",
  large_even:"O / EVEN",
  not_enought_amount : "NOT ENOUGH BALANCE",
  previous_game: "Previous No",
  "A C":"A C",
  "C":"C",
  "A B C D E":"A B C D E",
  B:"B",
  "large|odd":"OVER|ODD",
  "small|odd":"UNDER|ODD",
  "large|even":"OVER|EVEN",
  "small|even":"UNDER|EVEN",
  welcomeText:"Welcome to SBC2888, Please Enjoy ...",
  welcomeAccount:"Welcome",
  LO:"LO",
  CANCEL:"CANCEL",
  '2-3':"2-3",
  'Ox':"OX",
  'Pig':"PIG",
  'Tiger':"Tiger",
  "Goat":"Goat",
  "Monkey":"Monkey",
  Horse:"Horse",
  "4-5":"4-5",
  "3-4":"3-4",
  "Rabbit":"Rabit",
  enter_game:"Enter The Game",
  '2digit':"2 DIGIT",
  '3digit':"3 DIGIT",
  bac4_MERON:'BANKER',
  bac4_WALA:'PLAYER',
  bac4_draw:'DRAW',
  bac4_TIE:'DRAW',
};
