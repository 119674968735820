<template>
  <v-container class="bg-surface-variant pa-0">
    <div class="pcoded-main-container">
      <div class="main-body">
        <div class="page-wrapper" style="overflow: hidden">
          <div class="page-body" style="overflow: hidden; background-color:transparent;">
            <div class="lottery-menu-nav-head">
              <MainMenu />
            </div>
            <LotteryMenuTab />
            <v-row no-gutters class="yuki-border mx-auto justify-content-center yuki-main-container">
              <v-col class=" yuki-order-1" style="overflow-y: auto; ">
                <LeftSection />
              </v-col>
              <v-col class="yuki-border yuki-order-2" style="overflow-y: auto; overflow-x: hidden; ">
                <GameContent :passCurrentGame="currentGame" :passGetLimit="getLimit" :passClientResult="clientResult"
                  :passClientRandom="clientRandom" :passRandomNumber="randomNumber" :passClientTime="clientTime"
                  :passSixBtn="getFirstSixBtn" :passSixToTwelve="getSixToTwelve" :passRangeSmall="getRangeSmall"
                  :passRangeBig="getRangeBig" :passSmallBig="getSmallBig" :passOddPair="getOddPair"
                  :passBlueRed="getBlueRed" :passGetSelectBtn="getSelectBtn">
                </GameContent>
                <GameResult />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import LeftSection from "./components/leftSection.vue";
import GameContent from "../../../../components/component_machine_game/YukiGameComponent/Game_Content.vue";
import GameResult from "./components/GameResult.vue";
import MainMenu from "../../../../components/MainMenu.vue";
import LotteryMenuTab from "../../../../components/component_lottery_menu/lottery_menu_tab.vue";

import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_machine_share_style.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_max_480.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_min_481.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_min_768.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_min_993.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_min_1024.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_min_1281.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_min_1440.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_min_1680.css";
import "@/assets/css/lottery_menu/yuki_menu_lottery/yuki_menu_lottery_screen_min_1920.css";

import MX_Socket_LottoMenu_Yuki from "@/mixin/SocketGame/LottoMenu/MX_Socket_Yuki.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  mixins: [MX_Socket_LottoMenu_Yuki],
  components: {
    LeftSection,
    GameContent,
    GameResult,
    MainMenu,
    LotteryMenuTab
  },
  data() {
    return {
      orderResult: {},
      orderResultUnderOver: {},
      clientTime: {
        is_countdown: null,
        is_open_betting: null,
        time: null,
      },
      clientRandom: {
        "randmon_time": null,
        "is_open": false
      },
      clientResult: null,
      randomNumber: 0
    }
  },
  watch: {
    channelId: async function (newVal, oldVal) {
      if (newVal != oldVal && oldVal != 1) {
        this.reloaPage();
      }
    }
  },
  computed: {
    ...mapGetters("$_modules", {
      channelId: "channelId",
      isSound: "getIsSound"
    }),
    ...mapGetters("$_machine_yuki", {
      lastTwentyResults: "getLastTwentyResult",
      TypeMaster: "getTypeMaster",
      currentGame: "getCurrentGame",
      getLimit: "getLimit",
      getFirstSixBtn: "getFirstSixBtn",
      getSixToTwelve: "getSixToTwelve",
      getRangeSmall: "getRangeSmall",
      getRangeBig: "getRangeBig",
      getSmallBig: "getSmallBig",
      getOddPair: "getOddPair",
      getBlueRed: "getBlueRed",
      getSelectBtn: "getSelectBtn",

    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  created() {
    this._connectSocketYuki();
    this._initialData();
  },
  methods: {
    async reloaPage() {
      this.redefaultVariable();
      this.randomNumber = 0;
      this.clientResult = null;
      this.RELOAD_PAGE_YUKI();
      this._connectSocketYuki();
      await this._initialData();
    },
    redefaultVariable() {
      
      this.clientRandom = {
        "randmon_time": null,
        "is_open": false
      },
      this.bonus = [];
      this.orderResult = {},
        this.orderResultUnderOver = {},
        this.clientTime = {
          is_countdown: null,
          is_open_betting: null,
          time: null,
        };
    },

    _initialData() {
      this.fetchUserInfo();
      this.fetchLastResults();
      this.fetchCurrentBetting();
      this.fetchCurrentGame();
      this.fetchPreviousBetting();
      this.fetchTypeMaster();
      this.fetchButton();
      this.fetchLimit();
    },

    _clearResult() {
      new Promise((resolve) => setTimeout(resolve, 5000));
      this.clientResult = null;
    },
    _playSoundBang() {
      if (this.isSound) {
        let audio = new Audio("/sound/bangbang.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () { });
        }
      }
    },
    _playSoundBetDown() {
      if (this.isSound) {
        let audio = new Audio("/sound/betdown_please.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () { });
        }
      }
    },
    _playSoundTing() {
      if (this.isSound) {
        let audio = new Audio("/sound/ting1s.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () { });
        }
      }
    },
    _playSoundGoodLuck() {
      if (this.isSound) {
        let audio = new Audio("/sound/good_luck.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () { });
        }
      }
    },
    _playSoundCongratulation() {
      if (this.isSound) {
        let audio = new Audio("/sound/congratulations.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () { });
        }
      }
    },

    ...mapMutations("$_machine_yuki", [
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
      "UPDATE_TYPE_MASTER",
      "UPDATE_TOTAL_INVOICE",
      "RELOAD_PAGE_YUKI"
    ]),
    ...mapActions("$_machine_yuki", [
      "fetchLastResults",
      "fetchCurrentBetting",
      "fetchPreviousBetting",
      "fetchCurrentGame",
      "SocketInAction",
      "fetchButton",
      "fetchTypeMaster",
      "fetchLimit"
    ]),
    ...mapActions("$_modules", [
      "fetchUserInfo",
      "updateUserInfo"
    ]),
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s |  YUKI`
    },
  async beforeRouteLeave(to, from, next) {
    this._disconnectSocketYuki();
    next();
  }
}
</script>

<style>
@media (min-width:760px) {
  .curtain__prize {
    height: 97.3%;
  }

  .lotto_prize {
    overflow: auto;
  }
}

@media (max-width:759px) {
  .curtain__prize {
    height: 93%;
  }

  .lotto_prize {
    height: fit-content !important;
    overflow: hidden;
  }
}

.curtain__prize {
  /*This sets the background color and an image (purposesly small to not take up the whole background).  The image is centered and not repeated so that it looks like an img element was used, but is not*/
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  /* Forces the prize position into the container start */
  z-index: 1;
  /* Places the prize behind the panels, which are z-index 2 */
  width: 98%;
  /*Makes sure that it fills the whole container*/


}

input[type=checkbox] {
  position: absolute;
  /* Force the checkbox at the start of the container */
  cursor: pointer;
  /* Indicate the curtain is clickable */
  width: 88%;
  /* The checkbox is as wide as the component */
  height: 88%;
  /* The checkbox is as tall as the component */
  z-index: 100;
  /* Make sure the checkbox is on top of everything else */
  opacity: 0;
  /* Hide the checkbox */
}

/* When the checkbox is checked... */
/* Slide the first panel in */
input[type=checkbox]:checked~div.curtain__panel--left {
  transform: translateX(0);
}

/* Slide the second panel in */
input[type=checkbox]:checked~div.curtain__panel--right {
  transform: translateX(0);
}

.checkbox-style {
  position: absolute;
  top: 1.5px;
  right: 0px;
  z-index: 9;
}

input[type=checkbox] {
  transform: scale(1.2);
  margin-right: 2px;
}

.v-dialog>.v-card>.v-card__text {
  padding-bottom: 10px !important;
}

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 50px;
  min-width: 60px;
  padding: 0 0px;
}

.v-btn__content {
  height: 100% !important;
}

.checkbox-style1 {
  position: absolute;
  top: 1.5px;
  right: -2px;
  margin-top: -10px
}



@media(max-width:760px) {



  .limit-value {
    font-size: .8rem
  }

  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.2rem;
    margin: 0px !important;

  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-coin-cover {
    width: 100%
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}


@media(min-width:1280px) {
  .limit-value {
    font-size: .9rem
  }

  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.3rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:1600px) {
  .limit-value {
    font-size: 1rem
  }

  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.3rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1.4rem;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

.on-active-btn {
  color: goldenrod !important;
  text-shadow: 1px 1px black;
  /* background-color: #fec84e; */
  background-image: linear-gradient(315deg, #72dfdf 0%, #8ceeee2c 100%) !important;
}

.on-active-btn>span>span {
  color: gold !important;
  text-shadow: 1px 1px black;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.hold-coin {
  position: sticky;
  left: 0px;
  z-index: 100;
  background: #013366;
  font-size: bolder;
  border-right: 2px dashed white;
}

.coin-container {
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.coin-container:hover {
  cursor: pointer;
}

.display-money {
  background-color: rgb(255, 255, 255, .9);
  height: 40px;
  border-radius: 5px;
  margin: 5px 0px 7px 0px;
  color: #212427;
  font-weight: bolder;
  text-shadow: 1px 0.2px #406386;
  font-size: 1.3rem;
  padding-top: 5px;
}


.yk-bet-input-number span .v-icon.v-icon {
  line-height: 45px;
  color: red !important;
}

/*.bet-Box{
  background: none;
}*/
.btn-top-img {
  width: 70%;
  position: absolute;
  top: 2%;
  left: 0%;
}

.bet-Box {
  background: none;
}

.bet-Box-blue {
  background: linear-gradient(237deg, transparent, transparent 93.71%),
    linear-gradient(137deg, transparent, transparent 92.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .6) 0%, rgba(0, 0, 255, .0) 50.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .7) 0%, rgba(0, 0, 255, .0) 55.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .8) 30%, rgba(0, 0, 255, .0) 30.71%);
  height: 100%;
  width: 100%;
  position: relative;
}

.bet-Box-red {
  background: linear-gradient(237deg, transparent, transparent 93.71%),
    linear-gradient(137deg, transparent, transparent 92.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.6) 0%, rgba(0, 0, 255, .0) 50.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.7) 0%, rgba(0, 0, 255, .0) 55.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.8) 30%, rgba(0, 0, 255, .0) 30.71%);
  height: 100%;
  width: 100%;
  position: relative;
}

.my-edit-num {

  position: absolute;
  bottom: 12%;
  right: 4%;
  color: white;
  font-weight: 800;
}

.bottom-payout {
  color: white;
}

.v-chip,
.v-chip__content,
.v-list-item__title,
span {

  font-weight: 700;
}

.yuki-span-bet-name {
  position: absolute;
  top: 62%;
  left: 12%;
  font-size: 1rem;
  font-weight: 900;
}

.yuki-span-bet-payout {
  position: absolute;
  top: 25%;
  left: 66%;
  font-size: 1rem;
  font-weight: 900;
}

.yuki-btn-bottom-text-blue {
  color: blue;
}

.yuki-btn-bottom-text-red {
  color: red;
}

.curtain-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.curtains {
  width: 100%;
  height: 355px;
  z-index: 0 !important;
  margin-top: -119px !important;
  margin-bottom: 20px;
  object-fit: contain;
  margin-left: 0px;
}

.curtains-header {
  width: 100%;
  height: 50px !important;
  z-index: 1 !important;
}

.result-img {
  width: 33%;
  height: auto;
  position: absolute !important;
  top: 24%;
  right: 34%;
}

@keyframes blink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.result-img-blink {
  transition: 0.3s ease-in;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.open-curtain {
  position: absolute;
  width: 100%;
  -webkit-animation: curtain 14.2s !important;
  animation: curtain 14.2s !important;
  z-index: 1;
  padding-left: 20px;
}

@keyframes curtain {
  0% {
    left: -30px;
  }

  50% {
    left: 43%;
  }

  100% {
    left: 100%;
  }
}

.v-tabs:not(.v-tabs--vertical).v-tabs--right>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}

.classMobile {
  padding: 0 !important;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}


.blink-bonus {
  animation: blink-bonus 1s steps(5, start) infinite;
  -webkit-animation: blink-bonus 1s steps(5, start) infinite;
  animation: blink-bonus 1s infinite;
}

@keyframes blink-bonus {
  0% {
    box-shadow: 2px 2px 2px 2px transparent inset;

  }

  50% {
    box-shadow: 0px 0px 0px 0px transparent inset;
    background: gold;
  }

  100% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }
}

@media(min-width:1920px) {}

button:focus {
  outline: none !important;
}
</style>
