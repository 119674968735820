<template>
  <div>
    <v-col col-12 class="w-100 pt-0 px-2">
      <div class="g-summary-machine-tiger pa-0">
        <div class="sides-wrapper gp-panel-bg rounded">
            <div class="d-flex sides px-0">
                <div v-if="walaButton" class="side-section-machine-tiger meron-section-machine-tiger pb-1 side-1"
                :class="walaButton._id == getSelectedDragonButton ? 'on-active-btn' : ''"
                    @click="_selectButton(walaButton._id)">
                    <div class=" mb-0 "><span style="font-weight:600">{{
                        formatMoney(currentBetting.dragon_amount)
                    }}</span>
                        <div class="odds-machine-tiger">{{ walaButton?' '+ walaButton.payout_display: 'x.xx'}}</div>
                        <div class="side-title-machine-tiger pt-1">
                            <div class="truncate-meron-machine-tiger font-weight-bold">{{walaButton? $t('title.'+walaButton.name) :"" }}</div>
                        </div>
                    </div>
                </div>
                <div class="side-section-tie-machine-tiger tie-section-machine-tiger  mx-2 side-1"
                    :class="tieButton._id == getSelectedDragonButton ? 'on-active-btn' : ''" 
                    @click="_selectButton(tieButton._id)"
                    v-if="meronButton">
                    <div class=" mb-0 "><span style="font-weight:600">{{ 
                      formatMoney(currentBetting.tie_amount)
                    }}</span>

                        <div class="odds-machine-tiger font-weight-bold">{{ tieButton?`${tieButton.payout_display}`: 'x.xx' }}</div>
                        <div class="side-title-machine-tiger pt-1">
                            <div class="truncate-tie-machine-tiger font-weight-bold">{{$t('title.tie')}}</div>
                        </div>
                    </div>
                </div>
                <div class="side-section-machine-tiger wala-section-machine-tiger pb-1 side-1"
                    :class="meronButton._id == getSelectedDragonButton ? 'on-active-btn' : ''" 
                    v-if="meronButton"
                    @click="_selectButton(meronButton._id)">
                    <div class="mb-0"><span style="font-weight:600">{{
                      formatMoney(currentBetting.tiger_amount)
                    }}</span>
                        <div class="odds-machine-tiger">{{ meronButton? ' '+ meronButton.payout_display: 'x.xx'}}</div>
                        <div class="side-title-machine-tiger pt-1">
                            <div class="truncate-wala-machine-tiger font-weight-bold">{{meronButton? $t('title.'+meronButton.name) :""}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Mixin from "~mixin/MX_ShareFunctionCollection.js";

export default {
  data() {
    return {
      mixins: [Mixin],
      bettingMoney: 0,
      selectedButton: {},
      activeItem: null,
      selectedBtnClassMeron: false,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedCoin: false,

    };
  },
  
  computed: {
      meronButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.bettingTypeMaster)
                return this.bettingTypeMaster[2]
            else return {}
        },
        
    ...mapGetters("$_dashboard_live", {
      channel: "getChannel",
      channelStatus: "getChannelStatus",
      bettingTypeMaster: "getBettingTypeMaster",
      currentBetting: "getCurrentBetting",
      getSelectedDragonButton:"getSelectedDragonButton"
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },
  methods: {
    _selectButton(selectedButtonId) {
        this.UPDATE_SELECT_DRAGON_BUTTON(selectedButtonId)
        this.selectedButtonId = selectedButtonId
        this.$emit("sentSelectedButton");
    },
    ...mapMutations("$_dashboard_live", ["UPDATE_SELECT_DRAGON_BUTTON"]),
  },
};
</script>