<template>
    <v-flex class="login-logo-section-mobile" >
        <div class="register-panel m-auto text-center">
            <img src="../../../../../public/images/logo_background/logo-login.png" class="logo-mobile" alt="">
            <div id="wrapper" style="position:relative">
                <div class="auth-page login-wrapper">
                    <div class="register-panel position-relative py-2 text-left">
                        <v-form ref="form" v-model="validate" lazy-validation @submit.prevent="register" class="form-container">
                            <div class="form-group fg-container pt-2">
                                <div class="btn-login-title">
                                    <img src="../../../../../public/images/a_page_access_global/login-icons/register_label.png" class="logo-register">
                                    <img class="register-member" src="../../../../../public/images/a_page_access_global/login-icons/register_member.png">
                                </div>
                            </div>
                            <div class="form-group section-input pb-0 mb-0">
                                <div class="form-group">
                                    <label class="h6 pt-0 pb-0 prime-color font-weight-bold">ឈ្មោះអ្នកប្រើប្រាស់ | UESRNAME</label>
                                    <div class="input-group prime-input" style="">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text prime-bg-color" style="margin-left:-1px;">
                                          <v-icon color="white">mdi-account </v-icon>
                                        </div>
                                      </div>
                                      <input v-model="passUser.user_name" class="form-control"
                                             placeholder="Username" required
                                             type="text" @input="error = false">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="h6 pt-0 pb-0 prime-color font-weight-bold">ឈ្មោះពេញ | FULL NAME</label>
                                    <div class="input-group prime-input" style="">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text prime-bg-color" style="margin-left:-1px;">
                                          <v-icon color="white">mdi-account </v-icon>
                                        </div>
                                      </div>
                                      <input v-model="passUser.fullname" class="form-control"
                                             placeholder="Username" required
                                             type="text" @input="error = false">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="h6 pt-0 pb-0 prime-color font-weight-bold">ប្រភេទលុយ | CURRENCY TYPE</label>
                                    <div class="input-group prime-input" style="">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text prime-bg-color" style="margin-left:-1px;">
                                          <v-icon color="white">mdi-cash </v-icon>
                                        </div>
                                      </div>
                                      <select v-model="passUser.currency_type"  class="form-control register-select-currency" >
                                        <option value="1">Dollar $ | ដុល្លា</option>
                                        <option value="2">Riel ៛| លុយខ្មែរ រៀល</option>
                                        <option value="4">đồng ₫ | ដុង</option>
                                        <option value="5">Baht ฿| បាត</option>
                                      </select>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label class="h6 pt-0 pb-0 prime-color font-weight-bold">លេខសម្ងាត់ | Password</label>
                                    <div class="input-group prime-input mb-0" style="">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text prime-bg-color" style="margin-left:-1px;">
                                          <v-icon color="white">mdi-lock </v-icon>
                                        </div>
                                      </div>
                                      <input v-model="passUser.password" class="form-control mb-0"
                                             placeholder="Password" required
                                             type="password" @input="error = false" >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="h6 pt-0 pb-0 prime-color font-weight-bold">បញ្ជាក់លេខសម្ងាត់ | Confirm Password</label>
                                    <div class="input-group prime-input mb-0" style="">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text prime-bg-color" style="margin-left:-1px;">
                                          <v-icon color="white">mdi-lock </v-icon>
                                        </div>
                                      </div>
                                      <input v-model="passUser.confirm_password" class="form-control mb-0"
                                             placeholder="Confirm Password" required
                                             type="password" @input="error = false" >
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <label class="h6 pt-0 pb-0 prime-color font-weight-bold">លេខទូរសព្ទ | Phone</label>
                                    <div class="input-group prime-input mb-0" style="">
                                      <div class="input-group-prepend">
                                        <div class="input-group-text prime-bg-color" style="margin-left:-1px;">
                                          <v-icon color="white">mdi-phone-in-talk </v-icon>
                                        </div>
                                      </div>
                                      <input v-model="passUser.phone" class="form-control mb-0"
                                             placeholder="Phone Number" required
                                             type="text" @input="error = false" >
                                    </div>
                                </div>
                            </div>
                            <div v-if="error" style="color:#9e000e; text-align:center; padding-bottom: 20px" >{{ errorMessage }}</div>
                            <div class="pt-5" style="width: 100%;text-align: center;">
                                <button v-if="!passLoading" type="submit" class="btn px-5 pt-2 submit-btn"
                                    >
                                    {{ $t('title.register') }}
                                </button>
                                <v-btn v-if="passLoading"
                                    :disabled="passLoading"
                                    type="submit" color="grey-lighten-3" class="px-5 btn btn-secondary "
                                    style="border-radius:5px;">
                                    <v-icon>
                                        mdi-refresh mdi-spin
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-form>
                    </div>
                </div>
            </div>
        </div>
    </v-flex>
</template>   
<script>
import "@/assets/css/login.css";
export default {
    props: ['passUser', 'passValid', 'passLoading', 'passLanguage', 'error', 'errorMessage'],
    data() {
        return {
            rules: {
                required: [(value) => !!value || 'Required.'],
            },
            validate: false,
            formLogo: '/images/logo-login.png',
            isShowText: false,
            isShowPassowrd:false
        }
    },
    methods: {
        _clearError(){
            this.$emit("clearError");
        },
        reset() {
            this.$refs.form.reset();
        },
        async register() {
            if (this.$refs.form.validate()) {
                this.$emit("register", this.passUser);
            } else {
                this.reset();
            }
        },
        _changeLanguage(language) {
            this.$emit("changeLanguage", language);
        }
    }
}
</script>
<style>
#input-30, #input-34,label{
    color: #000 !important;
}
#input-42,#input-46{
    color: #000 !important;
}
.theme--light.v-label{
    color: #000000 !important;
}

</style>