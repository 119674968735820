
const getLastResults = (state) => state.lastResults;
const getCurrentBetting = (state) => state.currentBetting;
const getPreviousBetting = (state) => state.previousBetting;
const getCurrentGame = (state) => state.currentGame;
const getLotteryChannel = (state) => state.lotteryChannel;
const getLastOneResult = (state) => state.lastOneResult;
const getLastTwentyResult = (state) => state.lastTwentyResult;
const getIsBetting = (state) => state.is_Betting;
const getButtonMoney = (state) => state.buttonMoney;
const getLastFiveResults = (state) => state.lastFiveResults;
const getBettingAmount = (state) => state.bettingAmount;
const getBettingData = (state) => state.bettingData;
const getNumbers = (state) => state.numbers;
const getSelectedNumber = (state) => state.selectedNumber;
const getSelectAllPost = (state) => state.selectedAllPost;
const getSelectedBettingType = (state) => state.bettingType;
const getSelectedChannelPost = (state) => state.selectedPost;
const getSelectedListBettingNumber = (state) => state.selectedListBettingNumber;
const getTotalInvoice = (state) => state.totalInvoice;
const getClientTime = (state) => state.clientTime;
const getLottery3Result_A = (state) => state.result_a;

export default {
    getLottery3Result_A,
    getClientTime,
    getTotalInvoice,
    getSelectAllPost,
    getSelectedListBettingNumber,
    getSelectedChannelPost,
    getSelectedBettingType,
    getSelectedNumber,
    getNumbers,
    getBettingData,
    getBettingAmount,
    getLastResults,
    getCurrentBetting,
    getPreviousBetting,
    getCurrentGame,
    getLotteryChannel,
    getLastOneResult,
    getLastTwentyResult,
    getIsBetting,
    getButtonMoney,
    getLastFiveResults
};
