<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <div class="game-machine-component">
    <v-row class="ma-0">
      <h5 class=" game-machine-component-title display-desc-mobile pt-3 pl-2">{{ passIndex}}.{{ passChDesc }}</h5>  
      <GameResult :scanResult="scanResult" :scanPlayer="scanPlayer" :scanBanker="scanBanker" :sResultKk="sResultKk" :passUrl="passUrl" :totalP="totalP" :totalB="totalB" :passGame="passGame" :passIndex="passIndex" :channelType="channelType" :resultObjS="newGame" :passChDesc="passChDesc" :updateChInfo="updateChInfo"/>
      <GameInfo :updateChInfo="updateChInfo" :passGame="passGame" :resultObjS="resultObj" :passChannelInfo="chInfo" :channelType="channelType" :passTime="time" :chInfo="chInfo"/>
      <GameButton :passBetType="passBetType" :channelType="channelType" :passGame="passGame" :passTime="time" :passChannelInfo="chInfo"/>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import GameButton from "../SubComponent/GameButton/index.vue";
import GameInfo from "../SubComponent/GameInfo.vue";
import GameResult from "../SubComponent/GameResult/index.vue";
import { io } from "socket.io-client";

export default {
  components: {
    GameButton,
    GameInfo,
    GameResult,
  },
  props: [
    "passGame",
    "passIndex",
    "passBetType",
    "channelType",
    "passItem",
    "passPage",
    "passChDesc",
    "passChannelInfo",
    "passUrl"
  ],
  data() {
    return {
      time: 0,
      chInfo: this.passChannelInfo,
      resultObj: {
        results:[]
      },
      resultList: {
        results:[]
      },
      newGame: {},
      updateChInfo: {},
      totalB:0,
      totalP:0,
      scanResult:{},
      sResultKk:[],
      scanPlayer:[],
      scanBanker:[],
      statement:{
        rows_per_page: 10,
        page: 1
      },
    };
  },
  mounted() {
    this._powerSocket();
    this.listenerSocket();
    this.getChannelInfo(this.channelType);
  },
  watch: {
    passPage: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.toDisconnectSocket(this.fintPrototypeSocket());
        await this._powerSocket();
        await this.listenerSocket();
      }
    },
  },
  methods: {
    async getChannelInfo(type){
      try {
        const response = await this.fetchChannelInfo(type);
        if (response.data.code) {
            this.updateChInfo = response.data.data;
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
        }
      } catch (error) {
        return error;
      }
    },
    fintPrototypeSocket() {
      switch (this.passIndex) {
        case 1:
          return this.$socket1 ? this.$socket1 : false;
        case 2:
          return this.$socket2 ? this.$socket2 : false;
        case 3:
          return this.$socket3? this.$socket3 : false;
        case 4:
          return this.$socket4 ? this.$socket4 : false;
        case 5:
          return this.$socket5 ? this.$socket5 : false;
        case 6:
          return this.$socket6 ? this.$socket6 : false;
        case 7:
          return this.$socket7 ? this.$socket7 : false;
        case 8:
          return this.$socket8 ? this.$socket8 : false;
        case 9:
          return this.$socket9 ? this.$socket9 : false;
        case 10:
          return this.$socket10 ? this.$socket10 : false;
        case 11:
          return this.$socket11 ? this.$socket11 : false;
        case 12:
          return this.$socket12 ? this.$socket12 : false;
        case 13:
          return this.$socket13 ? this.$socket13 : false;
        case 14:
          return this.$socket14 ? this.$socket14 : false;
        case 15:
          return this.$socket15 ? this.$socket15 : false;
        case 16:
          return this.$socket16 ? this.$socket16 : false;
        case 17:
          return this.$socket17 ? this.$socket17 : false;
        case 18:
          return this.$socket18 ? this.$socket18 : false;
        case 19:
          return this.$socket19 ? this.$socket19 : false;
        case 20:
          return this.$socket20 ? this.$socket20 : false;
        case 21:
          return this.$socket21 ? this.$socket21 : false;
      }
    },
    toDisconnectSocket(value) {
      try {
        if(value != false){
          value.disconnect();
          value.removeAllListeners();
        }
        
      } catch (e) {
        //ignore
      }

    },
    listenerSocket() {
      try {
        let data = this.fintPrototypeSocket();
        data.on("client-start-count-down", (data) => {
          // console.log('client-start-count-down', `${JSON.stringify(data)}`);
          if (data) {
            this.time = data;
            this.resultObj = {};
          }
        });
        data.on("client-time", (data) => {
          // console.log('client-time', `${JSON.stringify(data)}`);
          if (data) {
            this.time = data;
            this.resultObj = {};
          }
        });
        data.on("client-result", (data) => {
          // console.log('client-result', `${JSON.stringify(data)}`);
          if (data) {
            this.resultObj = data;
          }
        });
        data.on("client-update-channel-info", (data) => {
          // console.log('client-update-channel-info', `${JSON.stringify(data)}`);
          if (data) {
            this.chInfo = data;
            this.updateChInfo = data;
          }
        });
        data.on("client-new-game", (data) => {
          // console.log('client-new-game', `${JSON.stringify(data)}`);
          this.newGame = data;
          this.resultObj = {}
          this.fetchCockXCasinoStatement(this.statement);
        });
        data.on("client-defind-result-and-new-channel", (data) => {
          // console.log('client-defind-result-and-new-channel', `${JSON.stringify(data)}`);
          if (data) {
            this.newGame = data;
            this.scanResult = {};
            this.sResultKk = [];
            this.scanPlayer = [];
            this.scanBanker = [];
            // console.log('client-defind-result', `${JSON.stringify(this.newGame)}`);
            // this.resultList.summary = data.objSummaryCoResult
          }
        });
        data.on("client-scan-player", data => {
          // console.log('client-scan-player', `${JSON.stringify(data)}`);
          if (data) {
            if(this.channelType == 6 || this.channelType == 7 || this.channelType == 18){
              this.scanPlayer = data;
            }else if(this.channelType == 8 || this.channelType == 9 || this.channelType == 19){
              this.scanPlayer.push(data);
            }
            // this.playSound('bet-touch.mp3');
            // if (this.$cookies.get('sbc_channel_type') == 6
            //   || this.$cookies.get('sbc_channel_type') == 7) this.UPDATE_PLAYER_CARD(data);
            // if (this.$cookies.get('sbc_channel_type') == 8
            //   || this.$cookies.get('sbc_channel_type') == 9) this.UPDATE_DRAGON_CARD(data.image);
          }
        });
        data.on("client-scan-banker", data => {
          // console.log('client-scan-banker', `${JSON.stringify(data)}`);
          if (data) {
            if(this.channelType == 6 || this.channelType == 7 || this.channelType == 18){
              this.scanBanker = data;
            }else if(this.channelType == 8 || this.channelType == 9 || this.channelType == 19){
              this.scanBanker.push(data);
            }
          //   this.playSound('bet-touch.mp3');
          //   if (this.$cookies.get('sbc_channel_type') == 6
          //     || this.$cookies.get('sbc_channel_type') == 7) this.UPDATE_BANKER_CARD(data);
          //   if (this.$cookies.get('sbc_channel_type') == 8
          //     || this.$cookies.get('sbc_channel_type') == 9) this.UPDATE_TIGER_CARD(data.image);
          }
        });
        data.on("client-scan-result", (data) => {
          // console.log('client-scan-result', `${JSON.stringify(data)}`);
          if (data) {
            if(this.channelType == 5 || this.channelType == 14){
              this.sResultKk = data
            }else{
              this.scanResult = data;
            }
            // if (this.$cookies.get('sbc_channel_type') == 5) this.UPDATE_KLAKLOUK_CARD(data);
            // if (this.$cookies.get('sbc_channel_type') == 10) this.UPDATE_LOTTO_CARD(data);
            // if (this.$cookies.get('sbc_channel_type') == 11) {
            //   this.UPDATE_YUKI_CARD(data);
            // }
            // if (this.$cookies.get('sbc_channel_type') == 12 || this.$cookies.get('sbc_channel_type') == 17) {
            //   this.scanResult = data;
            //   this.UPDATE_ABONG_CARD(data)
            // }
            // if (this.$cookies.get('sbc_channel_type') == 13) this.UPDATE_FANTAN(data);
            // if (this.$cookies.get('sbc_channel_type') == 14) this.UPDATE_TAISIV(data);
          }
        });
        data.on("client-scan-card", (data) => {
          if (data) {
            this.totalbaccaratResult(data);
          }
        });
      }
      catch (e) {
        //ignore
      }
    },

    _powerSocket() {
      try{
        let token = this.$cookies.get("sbc_token");
        const socket = io(
          `${process.env.VUE_APP_WEBSOCKET_URL + this.passItem}`,
          {
            extraHeaders: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        socket.on("connect", () => {
          console.log("socket: Connectd" + this.passItem);
        });
        socket.on("connect_error", (err) => {
          console.log("socket: Message err == ", err);
        });
        socket.on("disconnect", () => {
          console.log(`socket: Disconnection`, this.passItem);
        });
        socket.on("error", (err) => {
          console.log("socket: Error connecting to server", err);
        });
        socket.on("reconnect", (number) => {
          console.log("socket: Reconnected to server", number);
        });
        socket.on("reconnect_attempt", () => {
          console.log("socket: Reconnect Attempt", "alert problem");
        });
        socket.on("reconnecting", (number) => {
          console.log("socket: Reconnecting to server", number);
        });
        socket.on("reconnect_error", (err) => {
          console.log("socket: Reconnect Error", err);
        });
        socket.on("reconnect_failed", () => {
          console.log("socket: Reconnect failed", "offline");
        });
        switch (this.passIndex) {
          case 1:
            Vue.prototype.$socket1 = socket;
            break;
          case 2:
            Vue.prototype.$socket2 = socket;
            break;
          case 3:
            Vue.prototype.$socket3 = socket;
            break;
          case 4:
            Vue.prototype.$socket4 = socket;
            break;
          case 5:
            Vue.prototype.$socket5 = socket;
            break;
          case 6:
            Vue.prototype.$socket6 = socket;
            break;
          case 7:
            Vue.prototype.$socket7 = socket;
            break;
          case 8:
            Vue.prototype.$socket8 = socket;
            break;
          case 9:
            Vue.prototype.$socket9 = socket;
            break;
          case 10:
            Vue.prototype.$socket10 = socket;
            break;
          case 11:
            Vue.prototype.$socket11 = socket;
            break;
          case 12:
            Vue.prototype.$socket12 = socket;
            break;
          case 13:
            Vue.prototype.$socket13 = socket;
            break;
          case 14:
            Vue.prototype.$socket14 = socket;
            break;
          case 15:
            Vue.prototype.$socket15 = socket;
            break;
          case 16:
            Vue.prototype.$socket16 = socket;
            break;
          case 17:
            Vue.prototype.$socket17 = socket;
            break;
          case 18:
            Vue.prototype.$socket18 = socket;
            break;
          case 19:
            Vue.prototype.$socket19 = socket;
            break;
          case 20:
            Vue.prototype.$socket20 = socket;
            break;
          case 21:
            Vue.prototype.$socket21 = socket;
            break;
        }
      }catch(e){
        //ignore
      }
    },
    totalbaccaratResult(data){
      for (let i in data.player_cards) {
        console.log("data.player_cards[i].barcode",data.player_cards[i].value)
        this.totalP = this.totalP + data.player_cards[i].value;
        this.totalP = this.totalP % 10
    }
    for (let j in data.banker_cards) {
        console.log("data.banker_cards[j]",data.banker_cards[j].value)
        this.totalB = this.totalB + data.banker_cards[j].value;
        this.totalB = this.totalB % 10
    }
    },
    ...mapActions("$_cockfight_casino_live", [
      "fetchCockXCasinoStatement", "fetchChannelInfo"
    ]),
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_BACCARAT_CARD"
    ]),
  },
};
</script>