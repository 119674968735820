var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.passBettingTypeMaster && _vm.passBettingTypeMaster[0])?_c('div',{staticClass:"pt-0 px-0 pb-2 px-md-0"},[_c('SubGameChannelInfo'),_c('div',{staticClass:"g-summary"},[_c('div',{staticClass:"col-12 px-0"},[_c('div',{staticClass:"row px-0 mx-0"},[_c('v-spacer'),_c('div',{staticClass:"dd-x-title-dd-1 ri-taixiu-betting-col-dd-1 ri-x-green-dd-1 col-5 text-center pr-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[0]._id
              ? 'ri-sicbo-betting-status'
              : '',staticStyle:{"width":"100% !important"},on:{"click":function($event){return _vm.__xbetting(
              _vm.passBettingTypeMaster[0]._id,
              _vm.passBettingTypeMaster[0].name
            )}}},[_vm._v(" "+_vm._s(_vm.$t("title.tai" + _vm.passBettingTypeMaster[0].name))+" ")]),_c('v-spacer'),_c('div',{staticClass:"dd-x-title-dd-1 ri-taixiu-betting-col-dd-1 ri-x-red-dd-1 col-5 text-center pl-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[1]._id
              ? 'ri-sicbo-betting-status'
              : '',staticStyle:{"width":"95% !important"},on:{"click":function($event){return _vm.__xbetting(
              _vm.passBettingTypeMaster[1]._id,
              _vm.passBettingTypeMaster[1].name
            )}}},[_vm._v(" "+_vm._s(_vm.$t("title.tai" + _vm.passBettingTypeMaster[1].name))+" ")]),_c('v-spacer')],1)]),_c('div',{staticClass:"ri-taixiu-betting-row-dd-2 mt-3 mx-0"},[_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r1-c1-dd-2 w-100",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[2]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[2]._id,
            _vm.passBettingTypeMaster[2].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[2].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r1-c2-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[3]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[3]._id,
            _vm.passBettingTypeMaster[3].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[3].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r1-c3-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[4]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[4]._id,
            _vm.passBettingTypeMaster[4].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[4].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r1-c4-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[8]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[8]._id,
            _vm.passBettingTypeMaster[8].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-text-dd-1",staticStyle:{"color":"greenyellow"}},[_vm._v(_vm._s(_vm.passBettingTypeMaster[8].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r1-c5-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[9]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[9]._id,
            _vm.passBettingTypeMaster[9].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[9].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r1-c6-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[10]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[10]._id,
            _vm.passBettingTypeMaster[10].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[10].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r1-c7-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[11]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[11]._id,
            _vm.passBettingTypeMaster[11].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[11].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r3-c2-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[5]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[5]._id,
            _vm.passBettingTypeMaster[5].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[5].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r3-c3-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[6]._id
            ? 'ri-sicbo-betting-status'
            : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[6]._id,
            _vm.passBettingTypeMaster[6].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(" "+_vm._s(_vm.passBettingTypeMaster[6].name)+" ")])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r3-c4-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[7]._id
        ? 'ri-sicbo-betting-status'
        : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[7]._id,
            _vm.passBettingTypeMaster[7].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[7].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r3-c5-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[12]._id
          ? 'ri-sicbo-betting-status'
          : '',on:{"click":function($event){return _vm.__xbetting(
              _vm.passBettingTypeMaster[12]._id,
              _vm.passBettingTypeMaster[12].name
            )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[12].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r3-c6-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[13]._id
        ? 'ri-sicbo-betting-status'
        : '',on:{"click":function($event){return _vm.__xbetting(
            _vm.passBettingTypeMaster[13]._id,
            _vm.passBettingTypeMaster[13].name
          )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[13].name))])])])]),_c('div',{staticClass:"ri-taixiu-betting-col-dd-2 ri-xcol-r3-c7-dd-2",class:_vm.getDdBetting.bettingid == _vm.passBettingTypeMaster[14]._id
      ? 'ri-sicbo-betting-status'
      : '',on:{"click":function($event){return _vm.__xbetting(
          _vm.passBettingTypeMaster[14]._id,
          _vm.passBettingTypeMaster[14].name
        )}}},[_c('div',[_c('span',[_c('span',{staticClass:"dd-x-title-dd-3"},[_vm._v(_vm._s(_vm.passBettingTypeMaster[14].name))])])])])])]),_c('CoinButton',{staticClass:"mt-2",attrs:{"passGetButton":_vm.getButton,"passChannel":_vm.passChannel,"passPlaceCoin":_vm.getBettingAmount}}),_c('SubmitBtn',{staticClass:"mb-2",attrs:{"passCheckAllowSubmit":_vm._checkAllowSubmit},on:{"handleSubmitBtn":_vm._submitBetting,"handleClearBtn":_vm._clearMoney}}),_c('TaiXiuMachineReportBettingComponent',{attrs:{"passLoadingTodayReport":_vm.passLoadingTodayReport}}),_c('v-row',{staticClass:"mx-0 justify-center w-100 mt-2"},[_c('v-col',{staticStyle:{"padding":"0"},attrs:{"col-12":""}},[_c('div',{staticClass:"show-on-mobile pt-5"},[_c('TableResult',{attrs:{"rows":6,"cols":35}})],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }