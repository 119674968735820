export default {
    search: 'ស្វែងរក',
    permission_name: 'ឈ្មោះ Permission',
    role_name: 'ឈ្មោះ Role',
    menu_name: 'ឈ្មោះ Menu',
    description: 'បរិយាយ',
    order: 'លេខលំដាប់',
    route_name: 'ឈ្មោះ Route',
    url_name: 'ឈ្មោះ URL',
    icon: 'រូបតំណាង',
    parent: 'ទិន្នន័យមេ',
    permission: 'សិទ្ធអ្នកប្រើប្រាស់',
    visible: 'អាចមើលឃើញ',
    user_name: 'ឈ្មោះអ្នកប្រើប្រាស់',
    bank_account: 'ព័ត៌មានគណនីធនាគារ',
    user_role: 'ប្រភេទអ្នកប្រើប្រាស់',
    password: 'Mật khẩu',
};
