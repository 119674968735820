<template>
  <div class="total-invoice-title">
    <ul class="mb-0 px-2">
      <li>
        <span class="mt-5"> {{ $t("title.total_betting_an_invoice") }} </span>
        <span class="float-right " style="font-weight:bold"
          :style="getTotalInvoice > userInfor.balance ? 'color:red' : 'color:green'">
          {{ getTotalInvoice ? (getTotalInvoice).toLocaleString() : '0.00' }} {{
          _displayCurrencySymbol(userInfor.account_currency) }}
        </span>
      </li>
      <li>
        <span class="mt-5"> {{ $t("title.total_betting_a_game") }} </span>
        <span class="float-right " style="font-weight:bold">
          {{ currentBetting && currentBetting.total_amount ? (currentBetting.total_amount).toLocaleString() : '0.00' }}
          {{ _displayCurrencySymbol(userInfor.account_currency) }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Mixin from "~mixin";
export default {
  // DEFINE local VARIABLE
  mixins: [Mixin],
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_lottery_vn", {
      currentGame: "getCurrentGame",
      getTotalInvoice: 'getTotalInvoice',
      currentBetting: "getCurrentBetting"
    }),
  },
  methods: {
  },
};
</script>