<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }} </h5>
    <div class="game-machine-component-result">
      <div class="trends limit-on-tab" style="position: relative;">
        <div v-if="scanResult && scanResult.name"
          style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center"
          :style="findColorByName(scanResult.name) == 'ខៀវ' ? 'background-color:rgba(40, 90, 226, .83)' : 'background-color:rgba(224, 80, 73, .83)'">
          <div class="h-100">
            <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
              <tr style="height: 38px;">
                <th style="color: #ffffff;"
                  :style="findColorByName(scanResult.name) == 'ខៀវ' ? 'background-color:rgba(40, 90, 226, .83)' : 'background-color:rgba(224, 80, 73, .83)'">
                  <b>{{ scanResult.name }}</b>
                </th>
              </tr>
              <tr>
                <td>
                  <v-img v-if="findImageByName(scanResult.name) != 'cancel'" :src="findImageByName(scanResult.name)"
                    width="78" style=" text-align: center; margin-right: auto; margin-left: auto;height: 78px;"></v-img>
                  <v-avatar v-else color="grey" size="19">
                    <span class="white--text">c</span>
                  </v-avatar>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <!-- <div class="trend-legend-custome  gp-panel-bg  d-flex  justify-content-between align-items-center"
                style="background-color: #ffffff !important;">
            </div> -->
          <div class="px-3 pb-4 gp-panel-bg  landscape-trend"
            style="position: relative; background-color: #ffffff !important; padding: 0 !important;">
            <v-col cols="12" sm="12" style="padding: 0">
              <v-row style="padding: 0; margin: 0">
                <v-col cols="6" md="6" sm="6" lg="6" style="padding: 0; margin: 0">

                  <VideoLive :passurl="passUrl" :channelType="channelType" />

                </v-col>
                <v-col cols="6" md="6" sm="6" lg="6" style="padding:  10px 0px 0 0; margin: 0">
                  <div class="table-responsive gp-scroll">
                    <table class="machine-table">
                      <tbody>
                        <tr v-for="row in rows" :key="row">
                          <td class="result" v-for="col in channel.fix_column" :key="col">
                            <div v-if="channel.column - channel.fix_column <= 0">
                              <div v-for="(result, i) in resultObj.results" :key="i"
                                :style="'background-color:' + result.blue_red.name" class="w-100 h-100">
                                <span v-if="col == result.cid && row == result.rid">
                                  <v-img v-if="findImageOfEachYear(result.year.year_number) != 'cancel'"
                                    :src="findImageOfEachYear(result.year.year_number)"></v-img>
                                  <v-avatar v-else color="grey" size="19">
                                    <span class="white--text">c</span>
                                  </v-avatar>
                                </span>
                              </div>
                            </div>
                            <div v-else>
                              <div v-for="(result, i) in resultObj.results" :key="i">
                                <span
                                  v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid">
                                  <v-img :src="findImageOfEachYear(result.year.year_number)"></v-img>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </div>
      </div>
    </div>
    <div class="game-machine-component-reserve-space"></div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import VideoLive from "../GameResult/VideoLive.vue";
import Mixin from "../../../../../../mixin/MX_FindImageFunctionCollection.js";
export default {
  components: {
    VideoLive
  },
  props: ['passUrl', 'passGame', 'passIndex', 'channelType', 'resultObjS', 'passChDesc', 'showResult', 'scanResult'],
  data() {
    return {
      mixins: [Mixin],
      rows: 6,
      cols: 35,
      isShow: false,
      resultObj: {
        results: []
      },
      channel: {
        column: 20,
        fix_column: 20
      }
    }
  },
  watch: {
    resultObjS: function (newVal) {
      if (newVal.objCoResult) {
        // this.countDownTimer();
        this.resultObj.results = newVal.objCoResult
      }
    }
  },
  async created() {
    // console.log(`getChannel >>>>${JSON.stringify(this.channel)}`)
    this.getChannel(this.channelType);
    // this.getResult(this.channelType);
  },
  methods: {
    countDownTimer() {
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, 1000)
    },
    async getChannel(channelType) {
      // console.log(`getChannel1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaChannel(channelType);
        // console.log(`getChannel2 >>>> ${JSON.stringify(response)}`)
        if (response.data.code) {
          this.channel = response.data.data
        }
      } catch (error) {
        // console.log(`getChannelE >>>> ${error}`)
        this.error = true;
        return error;
      }
      this.getResult(this.channelType);
    },
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        // console.log(`getResult2 >>>> ${JSON.stringify(response)}`)
        if (response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    findImageOfEachYear(yearId) {
      switch (yearId) {
        case 1:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 1.png");
        case 2:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 2.png");
        case 3:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 3.png");
        case 4:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 4.png");
        case 5:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 5.png");
        case 6:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 6.png");
        case 7:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 7.png");
        case 8:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 8.png");
        case 9:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 9.png");
        case 10:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 10.png");
        case 11:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 11.png");
        case 12:
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 12.png");
        default:
          return 'cancel';
      }
    },
    findImageByName(name) {
      switch (name) {
        case 'ជូត':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 1.png");
        case 'ឆ្លូវ':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 2.png");
        case 'ខាល':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 3.png");
        case 'ថោះ':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 4.png");
        case 'រោង':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 5.png");
        case 'ម្សាញ់':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 6.png");
        case 'មមី':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 7.png");
        case 'មមែ':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 8.png");
        case 'វក':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 9.png");
        case 'រកា':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 10.png");
        case 'ច':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 11.png");
        case 'កោ':
          return require("../../../../../../../public/images/a_each_game/newYuki/Animal bet/Asset 12.png");
        default:
          return 'cancel';
      }
    },
    findColorByName(name) {
      if (name == 'ជូត' || name == 'ឆ្លូវ' || name == 'ខាល' || name == 'មមី' || name == 'មមែ' || name == 'វក') {
        return 'ខៀវ';
      } else {
        return 'ក្រហម';
      }
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult", "fetchMaBaChannel"]),
  }
}
</script>
<style>
/* .v-icon.v-icon{
    font-size: 18px !important
  } */

.v-application .pb-3 {
  padding-bottom: 2px !important;
}
</style>