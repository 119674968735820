<template>
  <div>
    <v-row no-gutters class="mb-14">
      <v-col cols="12">

        <div class="list-result-head">
          <ul class="mb-0">
            <li>
              <span class="mt-5"> {{ $t("title.total_betting_a_game") }} </span>
              <span class="float-right pr-5 " style="font-weight:bold">
                {{ currentBetting && currentBetting.total_amount ?
                _yukiDiv(currentBetting.total_amount).toLocaleString() : '0.00' }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}
              </span>
            </li>
          </ul>
        </div>
        <div>
          <div>
            <div class="title-current">{{ $t("title.result") }}</div>
          </div>
          <div class="list-result-body">

            <table class="tb-bet">
              <tr v-for="(item, i) in getLastFiveResults" :key="i" class="ng-star-inserted current-betting-tr">
                <td>
                  {{ item.date.split("-").join("").slice(0, 8) + "#" + item.no }}
                </td>
                <td class=" text-center">
                  {{ $t(_g_translate(item.small_large.name)) }}
                </td>
                <td class=" text-center">
                  {{ item.range.name }}
                </td>
                <td class="pr-3">
                  <v-img :src="findImageOfEachAnimal(item.year.year_number)" class="result-list-img pr-1" width="40"
                    style=" float: right;"></v-img>
                </td>
              </tr>
            </table>

          </div>
        </div>
        <div>
          <div>
            <div class="title-current">
              {{ $t("title.lo_current_betting") }}
            </div>
            <h6 class="sub-title pr-1 pl-2" style="color: #212427" v-if="currentBetting &&
                currentBetting.count &&
                currentBetting.count > 0
                ">
              {{
                currentBetting && currentBetting.list
                  ? moment(currentBetting.date).format("YYYYMMDD") +
                  "#" +
                  currentBetting.list[0].no
                  : "loading..."
              }}
              <span class="float-right">
                <button class="btn-link link print-all" @click="openPrintMultiple()">
                  {{ $t("title.ticket") }}
                </button>
              </span>
            </h6>
            <h6 class="sub-title pr-1 pl-5" v-else>
              {{ $t("title.no_data") }}
            </h6>
          </div>
          <v-alert elevation="1" class="pa-0">
            <div
              style="padding: 15px;padding-top:15px;margin:-5px;margin-top:0px;margin-bottom: 5px; background-color: blanchedalmond"
              v-for="(item, i) in currentBetting.list" :key="i">
              <span class="float-right">
                <button style="color:#cfb006" class="btn-link link print-all" @click="printTicket(item, item._id)">
                  {{ $t("title.ticket") }}
                </button>
                <input type="checkbox" :value="[{ item }]" v-model="selectedAddOns" class="myCheck" />
              </span>
              <div>
                <button style="padding: 0px;" class="btn-link link" @click="_goToDetail(item.invoice_no)">
                  <p>{{ $t("header.see_detail") }}</p>
                </button>
                <p style="font-size: 12px; line-height: 1">
                  {{ $t("header.invoice_id") }}៖
                  <span style="font-weight: bold; color: #27448c">#{{ item.invoice_no }}</span>
                </p>
                <p style="font-size: 12px; line-height: 1">
                  {{ $t("header.fight_no") }}៖
                  <span style="font-weight: bold; color: #27448c">#{{ item.no }}</span>
                </p>
                <p style="font-size: 12px; line-height: 1">
                  {{ $t("title.total_amount") }}៖
                  <span style="font-weight: bold; color: #27448c">{{
                item.total_amount ? _yukiDiv(item.total_amount).toLocaleString() : "0.00"
              }} {{ _displayCurrencySymbol(userInfor.account_currency) }}</span>
                </p>
                <p style="font-size: 12px; line-height: 1">
                  {{ $t("title.bet_type") }}៖
                  <span v-for="(bet, i) in item.bet_type" :key="i"
                    style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
                </p>
                <p style="font-size: 12px; line-height: 1">
                  {{ $t("header.date") }}៖
                  <span style="font-weight: bold; color: #27448c">{{
                item.time
              }}</span>
                </p>
              </div>
            </div>
          </v-alert>
        </div>
        <div>
          <div>
            <div class="title-current">
              {{ $t("title.lo_previous_betting") }}
            </div>
            <h6 class="sub-title pr-1 pl-2 py-1 my-0 bg-white" style="color: #212427"
              v-if="previousBetting && previousBetting.one && previousBetting.one.length > 0">
              {{ previousBetting && previousBetting.one ? moment(previousBetting.one.time).format("YYYYMMDD") + "#" +
                previousBetting.one[0].no : "loading..." }}
              <span class="float-right">
                <button class="btn-link link print-all" @click="openPrintMultiple()">
                  {{ $t("title.ticket") }}
                </button>
              </span>
            </h6>
            <h6 class="sub-title pr-1 pl-5" v-else>
              {{ $t("title.no_data") }}
            </h6>
            <!-- previousBetting one-->
            <v-alert elevation="0" class="pa-0 py-1 border-0" v-if="previousBetting.one && previousBetting.one.length > 0">
              <div 
                class="invoice-yuki-style"
                :class="item.total_amount_win > 0 ? 'invoice-yuki-win' : 'invoice-yuki-lose'"
                v-for="(item, i) in  previousBetting.one" :key="i">
                <span class="float-right">
                  <button style="color:#cfb006" class="btn-link link print-all" @click="printTicket(item, item._id)">
                    {{ $t("title.ticket") }}
                  </button>
                  <input type="checkbox" :value="[{ item }]" v-model="selectedAddOns" class="myCheck" />
                </span>
                <div>
                  <button style="padding: 0px;" class="btn-link link" @click="_goToDetail(item.invoice_no)">
                    <p>{{ $t("header.see_detail") }}</p>
                  </button>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.invoice_id") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.invoice_no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.fight_no") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.bet_total") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.total_amount ? _yukiDiv(item.total_amount).toLocaleString() : "0.00"
              }} {{ _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.bet_type") }}៖
                    <span v-for="(bet, i) in item.bet_type" :key="i"
                      style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount_win") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_amount_win ?
                _yukiDiv(item.total_amount_win).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }} </span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_cast") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_cast ?
                _yukiDiv(item.total_cast).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.date") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.time
              }}</span>
                  </p>
                </div>
              </div>
            </v-alert>

            <h6 class="sub-title pr-1 pl-2 py-1 my-0 bg-white" style="color: #212427"
              v-if="previousBetting && previousBetting.two && previousBetting.two.length > 0">
              {{ previousBetting && previousBetting.two ? moment(previousBetting.two.time).format("YYYYMMDD") + "#" +
                previousBetting.two[0].no : "loading..." }}
              <span class="float-right">
                <button class="btn-link link print-all" @click="openPrintMultiple()">
                  {{ $t("title.ticket") }}
                </button>
              </span>
            </h6>
            <v-alert elevation="1" class="pa-0" v-if="previousBetting.two && previousBetting.two.length > 0">
              <div         class="invoice-yuki-style"
              :class="item.total_amount_win > 0 ? 'invoice-yuki-win' : 'invoice-yuki-lose'"
                 v-for="(item, i) in  previousBetting.two" :key="i">
                <span class="float-right">
                  <button style="color:#cfb006" class="btn-link link print-all" @click="printTicket(item, item._id)">
                    {{ $t("title.ticket") }}
                  </button>
                  <input type="checkbox" :value="[{ item }]" v-model="selectedAddOns" class="myCheck" />
                </span>
                <div>
                  <button style="padding: 0px;" class="btn-link link" @click="_goToDetail(item.invoice_no)">
                    <p>{{ $t("header.see_detail") }}</p>
                  </button>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.invoice_id") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.invoice_no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.fight_no") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.total_amount ? _yukiDiv(item.total_amount).toLocaleString() : "0.00"
              }} {{ _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.bet_type") }}៖
                    <span v-for="(bet, i) in item.bet_type" :key="i"
                      style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount_win") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_amount_win ?
                _yukiDiv(item.total_amount_win).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_cast") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_cast ?
                _yukiDiv(item.total_cast).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.date") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.time
              }}</span>
                  </p>
                </div>
              </div>
            </v-alert>
            <!-- previousBetting three-->
            <h6 class="sub-title pr-1 pl-2" style="color: #212427"
              v-if="previousBetting && previousBetting.three && previousBetting.three.length > 0">
              {{ previousBetting && previousBetting.three ? moment(previousBetting.three.time).format("YYYYMMDD") + "#"
                + previousBetting.three[0].no : "loading..." }}
              <span class="float-right">
                <button class="btn-link link print-all" @click="openPrintMultiple()">
                  {{ $t("title.ticket") }}
                </button>
              </span>
            </h6>
            <v-alert elevation="2" class="pa-0" v-if="previousBetting.three && previousBetting.three.length > 0">
              <div
              class="invoice-yuki-style"
              :class="item.total_amount_win > 0 ? 'invoice-yuki-win' : 'invoice-yuki-lose'"
                v-for="(item, i) in  previousBetting.three" :key="i">
                <span class="float-right">
                  <button style="color:#cfb006" class="btn-link link print-all" @click="printTicket(item, item._id)">
                    {{ $t("title.ticket") }}
                  </button>
                  <input type="checkbox" :value="[{ item }]" v-model="selectedAddOns" class="myCheck" />
                </span>
                <div>
                  <button style="padding: 0px;" class="btn-link link" @click="_goToDetail(item.invoice_no)">
                    <p>{{ $t("header.see_detail") }}</p>
                  </button>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.invoice_id") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.invoice_no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.fight_no") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.total_amount ? _yukiDiv(item.total_amount).toLocaleString() : "0.00"
              }} {{ _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.bet_type") }}៖
                    <span v-for="(bet, i) in item.bet_type" :key="i"
                      style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount_win") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_amount_win ?
                _yukiDiv(item.total_amount_win).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_cast") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_cast ?
                _yukiDiv(item.total_cast).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.date") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.time
              }}</span>
                  </p>
                </div>
              </div>
            </v-alert>
       
            <!-- previousBetting four-->
            <h6 class="sub-title pr-1 pl-2" style="color: #212427"
              v-if="previousBetting && previousBetting.four && previousBetting.four.length > 0">
              {{ previousBetting && previousBetting.four ? moment(previousBetting.four.time).format("YYYYMMDD") + "#" +
                previousBetting.four[0].no : "loading..." }}
              <span class="float-right">
                <button class="btn-link link print-all" @click="openPrintMultiple()">
                  {{ $t("title.ticket") }}
                </button>
              </span>
            </h6>
            <v-alert elevation="2" class="pa-0" v-if="previousBetting.four && previousBetting.four.length > 0">
              <div
              class="invoice-yuki-style"
              :class="item.total_amount_win > 0 ? 'invoice-yuki-win' : 'invoice-yuki-lose'"
                v-for="(item, i) in  previousBetting.four" :key="i">
                <span class="float-right">
                  <button style="color:#cfb006" class="btn-link link print-all" @click="printTicket(item, item._id)">
                    {{ $t("title.ticket") }}
                  </button>
                  <input type="checkbox" :value="[{ item }]" v-model="selectedAddOns" class="myCheck" />
                </span>
                <div>
                  <button style="padding: 0px;" class="btn-link link" @click="_goToDetail(item.invoice_no)">
                    <p>{{ $t("header.see_detail") }}</p>
                  </button>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.invoice_id") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.invoice_no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.fight_no") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.total_amount ? _yukiDiv(item.total_amount).toLocaleString() : "0.00"
              }} {{ _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.bet_type") }}៖
                    <span v-for="(bet, i) in item.bet_type" :key="i"
                      style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount_win") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_amount_win ?
                _yukiDiv(item.total_amount_win).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_cast") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_cast ?
                _yukiDiv(item.total_cast).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.date") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.time
              }}</span>
                  </p>
                </div>
              </div>
            </v-alert>
            <!-- <div class="list-result-body previous-betting-list" v-if="previousBetting && previousBetting.four && previousBetting.four.length > 0">
              <table class="tb-bet">
                <tr v-for="(item, i) in previousBetting.four" :key="i" class="ng-star-inserted current-betting-tr" >
                  <td v-if="item.betting && item.betting.length > 1">
                    <span v-if="$i18n.locale == 'khmer'" style="font-weight:700">
                      {{ item.betting && item.betting[0] == "s" ? "តូច" : "ធំ" }}
                    </span>
                    <span v-else style="font-weight:700">
                      {{ item.betting && item.betting[0] == "s" ? "U" : "O" }}
                    </span>
                  </td>
                  <td v-else style="font-weight:700">
                    {{ item.betting && item.betting[0] ? item.betting[0] : "" }}
                  </td>
                  <td>
                    {{ item.amount }}
                  </td>
                  <td class="print">
                    <button class="btn-link link" @click="printTicket(item, item._id)" >
                      <span class="fontKhmer">{{ $t("title.ticket") }}</span>
                    </button>
                    <input
                      type="checkbox"
                      :value="[{ item }]"
                      v-model="selectedAddOns"
                      class="myCheck"
                    />
                  </td>
                </tr>
              </table>
            </div> -->
            <!-- previousBetting five-->
            <h6 class="sub-title pr-1 pl-2 mt-1" style="color: #212427"
              v-if="previousBetting && previousBetting.five && previousBetting.five.length > 0">
              {{ previousBetting && previousBetting.five ? moment(previousBetting.five.time).format("YYYYMMDD") + "#" +
                previousBetting.five[0].no : "loading..." }}
              <span class="float-right ">
                <button class="btn-link link print-all" @click="openPrintMultiple()">
                  {{ $t("title.ticket") }}
                </button>
              </span>
            </h6>
            <v-alert elevation="2" class="pa-0" v-if="previousBetting.five && previousBetting.five.length > 0">
              <div
              class="invoice-yuki-style"
              :class="item.total_amount_win > 0 ? 'invoice-yuki-win' : 'invoice-yuki-lose'"
                v-for="(item, i) in  previousBetting.five" :key="i">
                <span class="float-right">
                  <button style="color:#cfb006" class="btn-link link print-all" @click="printTicket(item, item._id)">
                    {{ $t("title.ticket") }}
                  </button>
                  <input type="checkbox" :value="[{ item }]" v-model="selectedAddOns" class="myCheck" />
                </span>
                <div>
                  <button style="padding: 0px;" class="btn-link link" @click="_goToDetail(item.invoice_no)">
                    <p>{{ $t("header.see_detail") }}</p>
                  </button>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.invoice_id") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.invoice_no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.fight_no") }}៖
                    <span style="font-weight: bold; color: #27448c">#{{ item.no }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.total_amount ? _yukiDiv(item.total_amount).toLocaleString() : "0.00"
              }} {{ _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.bet_type") }}៖
                    <span v-for="(bet, i) in item.bet_type" :key="i"
                      style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_amount_win") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_amount_win ?
                _yukiDiv(item.total_amount_win).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("title.total_cast") }}៖
                    <span style="font-weight: bold; color: #27448c">{{ item.total_cast ?
                _yukiDiv(item.total_cast).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
                  </p>
                  <p style="font-size: 12px; line-height: 1">
                    {{ $t("header.date") }}៖
                    <span style="font-weight: bold; color: #27448c">{{
                item.time
              }}</span>
                  </p>
                </div>
              </div>
            </v-alert>
            <!-- <div class="list-result-body previous-betting-list" v-if="previousBetting && previousBetting.five && previousBetting.five.length > 0">
              <table class="tb-bet">
                <tr v-for="(item, i) in previousBetting.five" :key="i" class="ng-star-inserted current-betting-tr" >
                  <td v-if="item.betting && item.betting.length > 1">
                    <span v-if="$i18n.locale == 'khmer'" style="font-weight:700">
                      {{ item.betting && item.betting[0] == "s" ? "តូច" : "ធំ" }}
                    </span>
                    <span v-else style="font-weight:700">
                      {{ item.betting && item.betting[0] == "s" ? "U" : "O" }}
                    </span>
                  </td>
                  <td v-else style="font-weight:700">
                    {{ item.betting && item.betting[0] ? item.betting[0] : "" }}
                  </td>
                  <td>
                    {{ item.amount }}
                  </td>
                  <td class="print">
                    <button class="btn-link link" @click="printTicket(item, item._id)" >
                      <span class="fontKhmer">{{ $t("title.ticket") }}</span>
                    </button>
                    <input
                      type="checkbox"
                      :value="[{ item }]"
                      v-model="selectedAddOns"
                      class="myCheck"
                    />
                  </td>
                </tr>
              </table>
            </div> -->
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- Print One Ticket On Select -->
    <v-dialog v-model="dialogPrintTicket" persistent max-width="500px">
      <div id="printMe" hidden>
        <div style="max-width: 260px; margin-left: 0px" v-if="items">
          <div class="kh-mef-14" style="text-align: center">
            <span style="font-size: 18px" class="fontKhmer">
              {{ userInfor.user_name }}</span>
            <div>
              <span style="font-size: 14px" class="fontKhmer">{{
                drawTicketLineOnPrint
              }}</span>
            </div>
          </div>
          <div style="display: block; width: 260px">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("invoice.ticket_id") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 11px;
                    margin-left: 15px;
                  ">
              {{ items._id }}
            </span>
          </div>
          <div style="display: block; width: 260px">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("invoice.game_id") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  ">
              {{
                items.time
                  ? items.time.split("-").join("").slice(0, 8) + "#" + items.no
                  : "loading..."
              }}
            </span>
          </div>

          <div style="display: block; width: 260px">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("title.total_amount") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  ">
              {{
                items.total_amount
                  ? _yukiDiv(items.total_amount).toLocaleString()
                  : "0.00"
              }} {{ _displayCurrencySymbol(userInfor.account_currency) }}
            </span>
          </div>

          <div style="display: block; width: 260px">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("invoice.type_betting") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  ">
              <span v-if="$i18n.locale == 'khmer'">
                <span v-for="(bet, i) in items.bet_type" :key="i"
                  style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
                <!--
                    {{
                      items.betting && items.betting[0] == "s"
                        ? "តូច"
                        : items.betting && items.betting[0] == "l"
                        ? "ធំ"
                        : items.betting
                        ? items.betting[0]
                        : "loading..."
                    }}
                    -->
              </span>
              <span v-else-if="$i18n.locale != 'khmer'">
                <span v-for="(bet, i) in items.bet_type" :key="i"
                  style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
                <!--
                    {{
                      items.betting && items.betting[0] == "s"
                        ? "Under"
                        : items.betting && items.betting[0] == "l"
                        ? "Over"
                        : items.betting
                        ? items.betting[0]
                        : "loading..."
                    }}
                    -->
              </span>
            </span>
          </div>

          <div style="display: block; width: 260px" v-if="items.total_amount_win">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("title.total_amount_win") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  ">
              {{ items.total_amount_win ? _yukiDiv(items.total_amount_win).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}
            </span>
          </div>

          <div style="display: block; width: 260px" v-if="items.total_cast || items.total_cast == 0">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("title.total_cast") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  ">
              {{ items.total_cast ? _yukiDiv(items.total_cast).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}
            </span>
          </div>


          <div style="display: block; width: 260px">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("invoice.date") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  ">
              {{ items.time }}
            </span>
          </div>

          <div style="display: block; width: 260px">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("invoice.bet_point") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  ">
              {{ items.amount }}
            </span>
          </div>
          <div style="display: block; width: 260px">
            <span class="fontKhmer" style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  ">
              {{ $t("invoice.win_loss") }}:
            </span>
            <span class="fontKhmer" style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  ">
              {{ items.is_win == null ? "???" : items.is_win }}
            </span>
          </div>
          <div style="margin-left: 10px">
            <span style="font-size: 14px" class="fontKhmer">{{
                drawTicketLineOnPrint
              }}</span>
          </div>
          <div style="text-align: center; margin-top: -30px">
            <p class="fontKhmer" style="font-size: 16px; text-align: center; margin-left: -10px">
              {{ WebsiteName }}
            </p>
          </div>
        </div>
      </div>
      <v-card class="justify-center">
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" style="width:15%" @click="closePrintDialog()"> <span></span> {{
                $t("invoice.cancel")
              }}</v-btn>
          <v-btn color="red darken-1" style="width:15%" @click="print()">{{
                $t("invoice.print")
              }}</v-btn>
        </v-card-actions>
        <v-card-title class="justify-center username-title">
          {{ userInfor.user_name }}
        </v-card-title>
        <v-card-title class="justify-center draw-ticket-line">
          <span>{{ drawTicketLine }}</span>
        </v-card-title>
        <v-card-title class="justify-center field-title">
          <v-flex md3 sm3> {{ $t("invoice.ticket_id") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1 view-id-dialog">
            {{ items._id }}
          </v-flex>
        </v-card-title>
        <v-card-title class="justify-center field-title">
          <v-flex md3 sm3> {{ $t("invoice.game_id") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1">
            {{
                items.time
                  ? items.time.split("-").join("").slice(0, 8) + "#" + items.no
                  : ""
              }}
          </v-flex>
        </v-card-title>
        <v-card-title class="justify-center field-title">
          <v-flex md3 sm3> {{ $t("title.total_amount") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1">
            {{
                items.total_amount
                  ? _yukiDiv(items.total_amount).toLocaleString()
                  : "0.00"
              }} {{ _displayCurrencySymbol(userInfor.account_currency) }}
          </v-flex>
        </v-card-title>

        <v-card-title class="justify-center field-title">
          <v-flex md3 sm3> {{ $t("invoice.type_betting") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1">
            <span v-if="$i18n.locale == 'khmer'">
              <span v-for="(bet, i) in items.bet_type" :key="i"
                style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
              <!--
                  {{
                    items.betting && items.betting[0] == "s"
                      ? "តូច"
                      : items.betting && items.betting[0] == "l"
                      ? "ធំ"
                      : items.betting
                      ? items.betting[0]
                      : "loading..."
                  }}
                  -->
            </span>
            <span v-else-if="$i18n.locale != 'khmer'">
              <span v-for="(bet, i) in items.bet_type" :key="i"
                style="font-weight: bold; color: #27448c">{{ $t(_g_translate(bet.name)) }} </span>
              <!--
                  {{
                    items.betting && items.betting[0] == "s"
                      ? "Under"
                      : items.betting && items.betting[0] == "l"
                      ? "Over"
                      : items.betting
                      ? items.betting[0]
                      : "loading..."
                  }}
                  -->
            </span>
          </v-flex>
        </v-card-title>

        <v-card-title class="justify-center field-title" v-if="items.total_amount_win">
          <v-flex md3 sm3> {{ $t("title.total_amount_win") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1">

            <span style="font-weight: bold; color: #27448c">{{ items.total_amount_win ?
                _yukiDiv(items.total_amount_win).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
          </v-flex>
        </v-card-title>

        <v-card-title class="justify-center field-title" v-if="items.total_cast || items.total_cast == 0">
          <v-flex md3 sm3> {{ $t("title.total_cast") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1">

            <span style="font-weight: bold; color: #27448c">{{ items.total_cast ?
                _yukiDiv(items.total_cast).toLocaleString() : "0.00" }} {{
                _displayCurrencySymbol(userInfor.account_currency) }}</span>
          </v-flex>
        </v-card-title>

        <v-card-title class="justify-center field-title">
          <v-flex md3 sm3> {{ $t("invoice.date") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1">
            {{ items.time }}
          </v-flex>
        </v-card-title>


        <v-card-title class="justify-center field-title">
          <v-flex md3 sm3> {{ $t("invoice.bet_point") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1">
            {{ items.amount }}
          </v-flex>
        </v-card-title>

        <v-card-title class="justify-center field-title">
          <v-flex md3 sm3> {{ $t("invoice.win_loss") }}: </v-flex>
          <v-flex md6 sm6 class="justify-start ml-1">
            {{ items.is_win == null ? "???" : items.is_win }}
          </v-flex>
        </v-card-title>


        <v-card-title class="justify-center draw-ticket-line">
          <span>{{ drawTicketLine }}</span>
        </v-card-title>
        <v-card-title style="margin-top: -25px" class="justify-center">
          <span style="font-size: 14px; opacity: 0.5">{{ WebsiteName }}</span>
        </v-card-title>
      </v-card>
    </v-dialog>

    <!-- Print selected tickets -->

    <v-dialog v-model="dialogPrintTicketMulti" persistent max-width="500px" style="margin-top: 170px !important">
      <div id="printMeMultiTicket" hidden>
        <div style="max-width: 260px; margin-left: 0px" v-for="(item, i) in this.selectedAddOns" :key="i">
          <div class="kh-mef-14">
            <div class="kh-mef-14" style="text-align: center">
              <span class="fontKhmer" style="font-size: 28px; margin-left: -25px">
                {{ userInfor.user_name }}</span>
              <div>
                <span class="fontKhmer" style="font-size: 18px">{{
                drawTicketLine
              }}</span>
              </div>
            </div>
            <div style="display: block; width: 260px">
              <span class="fontKhmer" style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    ">
                {{ $t("invoice.ticket_id") }}:
              </span>
              <span class="fontKhmer" style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    ">
                {{ item[0].item._id }}
              </span>
            </div>
            <div style="display: block; width: 260px">
              <span class="fontKhmer" style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    ">
                {{ $t("invoice.game_id") }}:
              </span>
              <span class="fontKhmer" style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    ">
                {{
                item[0].item.time
                  ? item[0].item.time.split("-").join("").slice(0, 8) +
                  "#" +
                  item[0].item.no
                  : "loading..."
              }}
              </span>
            </div>
            <div class="row">
              <div class="col-5 fontKhmer" style="font-weight: bold; font-size: 12.5px">
                {{ $t("invoice.date") }}:
              </div>
              <div class="col-5 fontKhmer" style="
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: -25px;
                    ">
                {{ item[0].item.time }}
              </div>
            </div>
            <div style="display: block; width: 260px">
              <span class="fontKhmer" style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    ">
                {{ $t("invoice.type_betting") }}:
              </span>
              <span class="fontKhmer" style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    ">
                <span v-if="$i18n.locale == 'khmer'">
                  {{
                item[0].item.betting && item[0].item.betting[0] == "s"
                  ? "តូច"
                  : item[0].item.betting && item[0].item.betting[0] == "l"
                    ? "ធំ"
                    : item[0].item.betting
                      ? item[0].item.betting[0]
                      : "loading..."
              }}
                </span>
                <span v-else-if="$i18n.locale != 'khmer'">
                  {{
                item[0].item.betting && item[0].item.betting[0] == "s"
                  ? "Under"
                  : item[0].item.betting && item[0].item.betting[0] == "l"
                    ? "Over"
                    : item[0].item.betting
                      ? item[0].item.betting[0]
                      : "loading..."
              }}
                </span>
              </span>
            </div>
            <div style="display: block; width: 260px">
              <span class="fontKhmer" style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    ">
                {{ $t("invoice.bet_point") }}:
              </span>
              <span class="fontKhmer" style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    ">
                {{ item[0].item.amount }}
              </span>
            </div>
            <div style="display: block; width: 260px">
              <span class="fontKhmer" style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    ">
                {{ $t("invoice.win_loss") }}:
              </span>
              <span class="fontKhmer" style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    ">
                {{ item[0].item.is_win == null ? "???" : item[0].item.is_win }}
              </span>
            </div>
            <div>
              <span style="font-size: 18px; opacity: 0.9">{{
                drawTicketLine
              }}</span>
            </div>
            <div style="text-align: center">
              <p class="fontKhmer" style="font-size: 16px; text-align: center; margin-left: -10px">
                {{ WebsiteName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <v-card class="justify-center">
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" style="width:15%" @click="closePrintMultiDialog()">{{
                $t("invoice.cancel")
              }}</v-btn>
          <v-btn color="red darken-1" style="width:15%" @click="printMulti()">{{
                $t("invoice.print")
              }}</v-btn>
        </v-card-actions>
        <div v-for="(item, i) in this.selectedAddOns" :key="i">
          <v-card-title class="justify-center username-title">
            {{ userInfor.user_name }}
          </v-card-title>
          <v-card-title class="justify-center draw-ticket-line">
            <span>{{ drawTicketLine }}</span>
          </v-card-title>
          <v-card-title class="justify-center field-title">
            <v-flex md3 sm3> {{ $t("invoice.ticket_id") }}: </v-flex>
            <v-flex md6 sm6 class="justify-start ml-1">
              {{ item[0].item._id }}
            </v-flex>
          </v-card-title>
          <v-card-title class="justify-center field-title">
            <v-flex md3 sm3> {{ $t("invoice.game_id") }}: </v-flex>
            <v-flex md6 sm6 class="justify-start ml-1">
              {{
                item[0].item.time
                  ? item[0].item.time.split("-").join("").slice(0, 8) +
                  "#" +
                  item[0].item.no
                  : ""
              }}
            </v-flex>
          </v-card-title>
          <v-card-title class="justify-center field-title">
            <v-flex md3 sm3> {{ $t("invoice.date") }}: </v-flex>
            <v-flex md6 sm6 class="justify-start ml-1">
              {{ item[0].item.time }}
            </v-flex>
          </v-card-title>
          <v-card-title class="justify-center field-title">
            <v-flex md3 sm3> {{ $t("invoice.type_betting") }}: </v-flex>
            <v-flex md6 sm6 class="justify-start ml-1 pl-5">
              <span v-if="$i18n.locale == 'khmer'">
                {{
                item[0].item.betting && item[0].item.betting[0] == "s"
                  ? "តូច"
                  : item[0].item.betting && item[0].item.betting[0] == "l"
                    ? "ធំ"
                    : item[0].item.betting
                      ? item[0].item.betting[0]
                      : "loading..."
              }}
              </span>
              <span v-else-if="$i18n.locale != 'khmer'">
                {{
                item[0].item.betting && item[0].item.betting[0] == "s"
                  ? "Under"
                  : item[0].item.betting && item[0].item.betting[0] == "l"
                    ? "Over"
                    : item[0].item.betting
                      ? item[0].item.betting[0]
                      : "loading..."
              }}
              </span>
            </v-flex>
          </v-card-title>
          <v-card-title class="justify-center field-title">
            <v-flex md3 sm3> {{ $t("invoice.bet_point") }}: </v-flex>
            <v-flex md6 sm6 class="justify-start ml-1">
              {{ item[0].item.amount }}
            </v-flex>
          </v-card-title>
          <v-card-title class="justify-center field-title">
            <v-flex md3 sm3> {{ $t("invoice.win_loss") }}: </v-flex>
            <v-flex md6 sm6 class="justify-start ml-1">
              {{ item[0].item.is_win == null ? "???" : item[0].item.is_win }}
            </v-flex>
          </v-card-title>
          <v-card-title class="justify-center draw-ticket-line">
            <span>{{ drawTicketLine }}</span>
          </v-card-title>
          <v-card-title style="margin-top: -25px" class="justify-center">
            <span style="font-size: 14px; opacity: 0.5">{{ WebsiteName }}</span>
          </v-card-title>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog :value="dialog" max-width="1200" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon style="color:red!important">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>{{ $t("title.invoice_detail") }}ssss</v-toolbar-title>
            </v-col>
          </v-row>
          <v-data-table style="font-family: 'khmer mef1'" class="elevation-1" item-key="date" :headers="headersDetail"
            :footer-props="footerProps" :items="detailData ? detailData : []"
            :server-items-length="detailData ? parseInt(detailData.count) : null"
            :sort-desc.sync="dataRequest.descendingFormat" :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page" :items-per-page.sync="dataRequest.rowsPerPage" @update:page="updatePage"
            @update:items-per-page="updatePage" @update:sort-desc="updatePage" :loading="getLoadingDetail"
            mobile-breakpoint="0">
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ detailData ? (detailData.indexOf(item) + ((dataRequest.page - 1) * dataRequest.rowsPerPage)) + 1
                : "loading ..." }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.no }}</td>
                <!-- <td>
                  <v-chip label :color=item.bet_color style="padding:15px;color:#ffffff; font-size:13px;height:22px">
                      <span class="white--text">{{item.bet_type}}</span>
                  </v-chip>
                </td> -->
                <td>
                  <!-- <v-btn
                    class="mx-1"
                    fab
                    dark
                    small
                    :color="item.bet_type.color"
                  > -->
                  <span :style="{ color: item.bet_type.color }">{{ $t(_g_translate(item.bet_type.name)) }}</span>
                  <!-- </v-btn> -->
                </td>
                <td>
                  {{ item.return_amount }}
                </td>
                <td>{{ $t(_g_translate(item.bet_result.name)) }}</td>
              </tr>
            </template>
            <template slot="body.append" v-if="detailData.total_amount">
              <td :colspan="5" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.bet_total") }}
              </td>
              <td
                :class="detailData.total_amount < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
                {{ detailData ? (detailData.total_amount) : "loading ..."}}
              </td>
              <td></td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import findImg from "~mixin/MX_FindImageFunctionCollection.js";
import moment from "moment";
export default {
  mixins: [findImg],
  // DEFINE local VARIABLE
  data() {
    return {
      message: "Hello world",
      previousGame: {},
      current: {},
      dialogPrintTicket: false,
      dialogPrintTicketMulti: false,
      format: "YYYY-MM-DD",
      selectedAddOns: [],
      detailData: [],
      dialog: false,
      items: [],
      ticket_id: "",
      getLoadingDetail: false,
      drawTicketLine: "--------------------------------------------------------",
      drawTicketLineOnPrint: "------------------------------------------------",
      WebsiteName: "Yuki",
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      dataRequest: {
        descendingFormat: [true],
        sortByFormat: ["_id"],
        descending: true,
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
      },
      headersDetail: [
        {
          text: this.$t("title.no"),
          align: 'start',
          value: 'no',
        },
        { text: this.$t("title.date_time"), value: 'time' },
        { text: this.$t("title.yuki_war"), value: 'no' },
        { text: this.$t("title.bet_type"), value: 'betting' },
        { text: this.$t("title.total_amount_win"), value: 'amount' },
        { text: this.$t("title.result"), value: 'result' }
      ],
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_machine_yuki", {
      latestResults: "getLastResults",
      getLastFiveResults: "getLastFiveResults",
      currentBetting: "getCurrentBetting",
      previousBetting: "getPreviousBetting",
      currentGame: "getCurrentGame",
      countTime: "getCountTime",
      getTotalInvoice: 'getTotalInvoice'
    }),
  },
  methods: {
    moment,

    _g_translate(x) {
      switch (x) {
        case "ជូត":
          return "title.yuki_wipe";
        case "ឆ្លូវ":
          return "title.yuki_street";
        case "ខាល":
          return "title.yuki_karl";
        case "ថោះ":
          return "title.yuki_thao";
        case "រោង":
          return "title.yuki_factory";
        case "ម្សាញ់":
          return "title.yuki_net";
        case "ស្តើង":
          return "title.yuki_thin";
        case "ក្រាស់":
          return "title.yuki_thick";
        case "មមី":
          return "title.yuki_mimi";
        case "មមែ":
          return "title.yuki_mom";
        case "វក":
          return "title.yuki_vk";
        case "រកា":
          return "title.yuki_roka";
        case "កុរ":
          return "title.yuki_kor";
        case "សេស":
          return "title.yuki_odd";
        case "គូ":
          return "title.yuki_pair";
        case "ក្រហម":
          return "title.yuki_red";
        case "ខៀវ":
          return "title.yuki_blue";
        case "ច":
          return "title.yuki_dog";
        case "Ox":
          return "title.yuki_street";
        case "Rat":
          return "title.yuki_wipe";
        case "Tiger":
          return "title.yuki_karl";
        case "Monkey":
          return "title.yuki_vk";
        case "Horse":
          return "title.yuki_mimi";
        case "Goat":
          return "title.yuki_mom";
        case "Rabbit":
          return "title.yuki_thao";
        case "Dragon":
          return "title.yuki_factory";
        case "Snake":
          return "title.yuki_net";
        case "Dog":
          return "title.yuki_dog";
        case "Pig":
          return "title.yuki_kor";
        case "Rooster":
          return "title.yuki_roka";
        case "Thin":
          return "title.yuki_thin";
        case "Thick":
          return "title.yuki_thick";
        case "even":
          return "title.yuki_pair";
        case "odd":
          return "title.yuki_odd";
        case "blue":
          return "title.yuki_blue";
        case "red":
          return "title.yuki_red";
        default:
          return x;
      }
    },
    _yukiDiv(m) { return (m); },
    _yukiMul(m) { return (m); },
    printTicket(item, ticket_id) {
      this.items = item;
      // console.log(this.items)
      this.ticket_id = ticket_id;
      this.dialogPrintTicket = true;
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.invoice_id);
      this.getLoadingDetail = false;
    },
    _goToDetail(_id) {
      this.invoice_id = _id;
      this.loadDetailData(_id);
      this.dialog = true;
    },
    async loadDetailData(_id) {
      this.detailData = [];
      this.getLoadingDetail = true;
      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
      const response = await this.$request.getSlot({
        url: 'm_yuki/report/detail?module_bet_id=' + _id
      });
      if (response.data.code) {
        this.detailData = response.data.data;
        this.getLoadingDetail = false;
      }
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    closePrintDialog() {
      this.dialogPrintTicket = false;
    },
    openPrintMultiple() {
      if (this.selectedAddOns != "") {
        this.dialogPrintTicketMulti = true;
      }
    },
    printMulti() {
      this.$htmlToPaper("printMeMultiTicket");
    },
    closePrintMultiDialog() {
      // var inputs = document.getElementsByClassName("myCheck");
      // for (var i = 0, l = inputs.length; i < l; ++i) {
      //   if (inputs[i].checked) {
      //     inputs[i].checked = false;
      //   }
      // }
      this.selectedAddOns = [];
      this.dialogPrintTicketMulti = false;
    },
    ...mapMutations("$_machine_yuki", [
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
    ]),
  },
};
</script>
<style>
.current-betting-tr>td {
  font-size: 0.8rem !important;
  color: #212427;

}

.tb-bet {
  table-layout: auto;
  padding-right: 5px;
  width: 100%
}

table {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
  text-indent: initial;
  border-color: grey;
}

table>tr {
  vertical-align: middle;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.tb-bet td:first-child {
  width: 40px;
}

.tb-bet td,
.left-side>section .recent-bet .tb-bet th {
  outline: none;
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 5px;
}

td {
  display: table-cell;
  vertical-align: inherit;
}

.result-loss {
  color: #8b0000;
}

.result-win {
  color: #00f;
}

.sub-title {
  color: #0c478b !important;
  font-size: 0.85rem !important;
  padding-top: 7px;
  padding-bottom: 3px;
  height: 22px;
  font-weight: 700;
}

.current-betting-list {
  height: 150px;
  overflow-y: scroll;
}

.previous-betting-list {
  height: 187px;
  overflow-y: scroll;
}

.current-betting-tr:nth-child(odd) {
  background: #e7e9eb;
}

.print-all {
  color: red !important;
  font-size: 12px !important;
}

.myCheck {
  padding-left: 5px;
  margin-left: 5px
}

.tb-bet td,
.left-side>section .recent-bet .tb-bet th {
  text-align: right;
}

.view-id-dialog {
  font-size: 1rem !important;
}

.flex.md3 {
  font-size: 1rem;
}

.v-dialog>.v-card>.v-card__title {
  font-size: 1rem !important;
}

.v-dialog>.v-card>.v-card__actions {
  padding-top: 16px !important;
}
</style>
