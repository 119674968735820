import router from './router';
import Vue from 'vue';

// const cookies = window.$cookies;

// export const generateErrorNotify = (title, desc) => {
// notification.error({
//     message: title,
//     description: desc,
//     onClick: () => {
//         cookies.remove("error");
//     }
// });
// setTimeout(() => {
//     cookies.remove("error");
// }, 3000);
// };

// export const generateNotify = (type, title, desc) => {
// notification[type]({
//     message: title,
//     description: desc
// });
// };

// export const generateModal = (type, title, desc, func) => {
//     Modal[type]({
//         title: title,
//         content: desc,
//         onOk: () => {
//             func();
//         }
//     });
// };

export const handleError = (status) => {
	if (status == 401) {
		const allCookies = Vue.$cookies.keys();
		allCookies.forEach((cookie) => Vue.$cookies.remove(cookie));
		Vue.$cookies.remove('error');
		router.push({name: 'login'}).catch(()=>{
			// ignore
		});
		setTimeout(() => {
			Vue.$cookies.remove('error');
		}, 3000);
	} else if (status == 404) {
		// generateErrorNotify("Request Not Found!");
	} else if (status == 422) {
		return false;
	} else if (status >= 500 && status <= 599) {
		// generateErrorNotify("Internal Server Error");
		this.$toastr.e(`Internal Server Error!`)
	} else {
		// generateErrorNotify("There is something error.");
	}
};
