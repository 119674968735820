const getDarkMode = (state) => state.darkMode;
const getBettingAmount = (state) => state.bettingAmount;
const getBettingKlakloukAmount = (state) => state.bettingKlakloukAmount;
const getBettingSicboAmount = (state) => state.bettingSicboAmount;
const getBettingDdAmount = (state) => state.bettingDdAmount;
const getUserInfo = (state) => state.userInfo;
const getChannelId = (state) => state.channelId;
const getChannelList = (state) => state.channelList;
const getIsSound = (state) => state.isSoundOn;
const getIsAudioPlay = (state) => state.isAudioPlay;
const getNotiCount = (state) => state.notiCount;
const getNotList = (state) => state.notiList;
const getNotiDetail = (state) => state.notiDetail;
const getAgentAccountList = (state) => state.agentAccountList;
const getUserBank = (state) => state.userBank;
const getLoading = (state) => state.loading;
const getDialog = (state) => state.dialog;
const getNotiData = (state) => state.notiData;
const getMainMenu = (state) => state.mainMenuId;
// const getNotList = (state) => state.notiList;
const getEditDialog = (state) => state.editDialog;
const getExpandMenu = (state) => state.expandMenu;
const getExpandMenuSmall = (state) => state.expandMenuSmall;
const getPromoText = (state) => state.promotText;
const getSubMenuCock = (state) => state.subMenuCock;
const getSubMenuCasino = (state) => state.subMenuCasino;
const getSubMenuCockXCasino = (state) => state.subMenuCockXCasino;
const getSubMenuLottery = (state) => state.subMenuLottery;
const getSubMenuLotteryMenuLotto = (state) => state.subMenuLotteryMenuLotto;
const getSubMenuLotteryMenuYuki = (state) => state.subMenuLotteryMenuYuki;
const getSubMenuLotteryMenuKeno = (state) => state.subMenuLotteryMenuKeno;
const getSubMenuLotteryMenuLottery2d = (state) => state.subMenuLotteryMenuLottery2d;
const getSubMenuLotteryMenuLottery3d = (state) => state.subMenuLotteryMenuLottery3d;
const getSubMenuLotteryMenuLotteryVn = (state) => state.subMenuLotteryMenuLotteryVn;
const getSubMenuMachineGame = (state) => state.subMenuMachineGame;
const getSubMenuMachineGameMenuAll = (state) => state.subMenuMachineGameMenuAll;
const getSubMenuMachineGameMenuBaccarat = (state) => state.subMenuMachineGameMenuBaccarat;
const getSubMenuMachineGameMenuDragonTiger = (state) => state.subMenuMachineGameMenuDragonTiger;
const getSubMenuMachineGameMenuDoden = (state) => state.subMenuMachineGameMenuDoden;
const getSubMenuMachineGameMenuTaiXiu = (state) => state.subMenuMachineGameMenuTaiXiu;
const getSubMenuMachineGameMenuSicBo = (state) => state.subMenuMachineGameMenuSicBo;
const getSubMenuMachineGameMenuKlaklouk = (state) => state.subMenuMachineGameMenuKlaKlouk;



export default {
    getSubMenuLotteryMenuLotteryVn,
    getSubMenuMachineGameMenuSicBo,
    getSubMenuMachineGameMenuKlaklouk,
    getSubMenuMachineGameMenuDragonTiger,
    getSubMenuMachineGameMenuDoden,
    getSubMenuMachineGameMenuTaiXiu,
    getSubMenuMachineGameMenuBaccarat,
    getSubMenuMachineGameMenuAll,
    getSubMenuMachineGame,
    getSubMenuLotteryMenuLottery3d,
    getSubMenuLotteryMenuLottery2d,
    getSubMenuLotteryMenuKeno,
    getSubMenuLotteryMenuLotto,
    getSubMenuLotteryMenuYuki,
    getSubMenuLottery,
    getSubMenuCockXCasino,
    getSubMenuCasino,
    getSubMenuCock,
    getPromoText,
    getExpandMenuSmall,
    getExpandMenu,
    getBettingAmount,
    getBettingKlakloukAmount,
    getBettingSicboAmount,
    getBettingDdAmount,
    getNotiDetail,
    getDarkMode,
    getUserInfo,
    getChannelId,
    getChannelList,
    getIsSound,
    getIsAudioPlay,
    getNotiCount,
    getNotList,
    getUserBank,
    getAgentAccountList,
    getLoading,
    getDialog,
    getNotiData,
    getEditDialog,
    getMainMenu
};