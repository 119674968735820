<template>
  <div class="row ma-0 w-100 mx-auto text-center">
    <div class="row ma-0 px-0">
      <div v-for="(button, index) in passGetButton" :key="button._id" class="ma-0 mb-2 p-0 col-3">
        <div @click="_inputAmount(button.amount)" class="coin-live-container">
          <img :src="_detectCoin(index)" class="btn-coin-betting text-center" />
          <div text class="text prevent-select btn-coin-betting-label">
            {{ button.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import soundFunction from "~mixin/MX_SoundFunctionCollection.js";


export default {
  props: ["passGetButton", "passChannel", "passPlaceCoin"],
  mixins: [soundFunction],
  data() {
    return {
      selectedButton: {},
      coinClickCounter: 0,
    };
  },

  computed: {
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount"
    }),
  },
  methods: {
    _detectCoin(index) {
      switch (index) {
        case 0:
          return require('~public_image/a_game_access_global/coins/1.png');
        case 1:
          return require('~public_image/a_game_access_global/coins/2.png');
        case 2:
          return require('~public_image/a_game_access_global/coins/3.png');
        case 3:
          return require('~public_image/a_game_access_global/coins/4.png');
        case 4:
          return require('~public_image/a_game_access_global/coins/5.png');
        case 5:
          return require('~public_image/a_game_access_global/coins/6.png');
        case 6:
          return require('~public_image/a_game_access_global/coins/7.png');
        case 7:
          return require('~public_image/a_game_access_global/coins/8.png');
        case 33:
          return require('~public_image/a_game_access_global/coins/1.png');
        default:
          return "";
      }
    },
    _inputAmount(money) {
      if (this.getBettingAmount == 0) {
        this.coinClickCounter = 0;
      }
      this._playSoundInsertCoin()
      this.coinClickCounter++;
      this.UPDATE_SELECT_COIN(this.coinClickCounter);
      this.UPDATE_UPDATE_BETTING_AMOUNT(money);
    },
  
    ...mapMutations("$_dashboard_live", [
      "UPDATE_SELECT_COIN",
    ]),
    ...mapMutations("$_modules", ["UPDATE_UPDATE_BETTING_AMOUNT"]),
  },
};
</script>
<style scoped>
@media (max-width: 760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.2rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-coin-cover {
    width: 100%;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media (min-width: 760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media (min-width: 960px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: "khmer mef1";
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media (min-width: 1280px) {





}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.hold-coin {
  position: sticky;
  left: 0px;
  z-index: 100;
  background: #013366;
  font-size: bolder;
  border-right: 2px dashed white;
}

.coin-container {
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.coin-container:hover {
  cursor: pointer;
}

.display-money {
  background-color: #7592af65;
  height: 40px;
  border-radius: 5px;
  margin: 5px 0px 7px 0px;
  color: #212427;
  font-weight: bolder;
  text-shadow: 1px 0.2px #406386;
  font-size: 1.3rem;
  padding-top: 5px;
  color: white;
}
</style>