export default {
	home: 'ទំព័រដើម',
	invoice: 'វិក្កយបត្រ',
	patients: 'Patients',
	doctors: 'Doctors',
	stock: 'Stock',
	services: 'Services',
	settings: 'Settings',
	contact: 'Contact',
	new_invoice: 'New Invoice',
	detail_invoice: 'Detail Invoice',
	logout: 'ចាក់ចេញ',
	kk1: 'ខ្លា',
	kk2: 'ឃ្លោក',
	kk3: 'មាន់',
	kk4: 'បង្គង',
	kk5: 'ក្តាម',
	kk6: 'ត្រី',
	tsOdd: 'សេស',
	tsEven: 'គូ',
	ts1: '1',
	ts2: '2',
	ts3: '3',
	ts4: '4',
	ts5: '5',
	ts6: '6',
	tsBig: 'ធំ',
	tsSmall: 'តូច',
	betting_history:"ប្រវត្តិការចាក់",
	withdraw:"ដក់ប្រាក់",
	deposit:"ដាក់ប្រាក់",
	account_detail:"គណនីលម្អិត",
};
