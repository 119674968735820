import LOTTERYVN2 from '@/modules/FolderGames/LotteryMenu/LotteryVN/LotteryVN_2';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/LOTTERYVN2',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: LOTTERYVN2,
                    name: 'LOTTERYVN2'
                }
            ]
    }
];

export default routes;