<template>
    <div>
        <v-container class="bg-machine-game-surface-variant pa-0">
            <div class="keno-main-container">
                <div class="main-body" style="overflow:hidden">
                    <div class="page-wrapper" style="overflow:hidden">
                        <div class="page-body" style="overflow:hidden">
                            <div class="lottery-menu-nav-head">
                                <MainMenu />
                            </div>
                            <SubmenuSport />
                            <div class="machine-game-body">
                                <img :src="require('~public_image/a_each_game/sport/sport_promo.jpg')" alt=""
                                    class="w-100 h-100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-container>

    </div>
</template>

<script>
import "@/assets/css/cock_and_casino_live_menu/screen_max_480.css";
import "@/assets/css/cock_and_casino_live_menu/screen_min_481.css";
import "@/assets/css/cock_and_casino_live_menu/screen_min_768.css";
import "@/assets/css/cock_and_casino_live_menu/screen_min_1025.css";
import "@/assets/css/cock_and_casino_live_menu/screen_min_993.css";
import "@/assets/css/cock_and_casino_live_menu/screen_min_1281.css";
import "@/assets/css/cock_and_casino_live_menu/screen_min_1441.css";
import "@/assets/css/cock_and_casino_live_menu/screen_min_1661.css";
import "@/assets/css/cock_and_casino_live_menu/screen_min_1824.css";
import "@/assets/css/cock_and_casino_live_menu/machine_share_style.css";
import SubmenuSport from "./component_sport/submenu-sport.vue";
import dashboardFunc from "../../../mixin/MX_ShareFunctionCollection.js"
import MainMenu from "../../../components/MainMenu.vue";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
    mixins: [dashboardFunc],
    components: {
        MainMenu,
        SubmenuSport
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        titleTemplate: `%s |  COCKFIGHT & CASINO-LIVE`
    },
    props: {
        page: {
            type: String,
        }
    },
    data() {
        return {
            active_tab: 0,
            newChannelList: [],
            channelSelected: null,
            tabs: null,
            loading: false,
            error: false,
            bettingObj: {
                number_id: null,
                amount: null
            },
            currencyType: "",
            betSelected: null,
            isBetsuccess: true,
            numberRule: val => {
                if (val < 0) return 'Please enter a positive number'
                return true
            },
            isBetting: false,
            userCurrency: '',

            data: {
                rows_per_page: 6,
                page: 1,
            },
            channel: null
        }
    },
    watch: {
        channelId: async function (newVal, oldVal) {
            if (newVal != oldVal && oldVal != 1) {
                await this._initialData();
            }
        },
        page: async function (newVal, oldVal) {
            if (newVal != oldVal) {
                await this.fetchUserInfo();
            }
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
            isSound: "getIsSound",
            userNotList: "getNotList",
        }),
        ...mapState("$_modules", {
            channelId: "channelId",
        }),
        getStatus() {
            return this.checkSattus()
        },
        getStatusColor() {
            return this.checkSattusColor()
        },
        getResultStatus() {
            return this.resultStatue()
        },
        getChannelsColor(index) {
            return this.resultChannelColor(index)
        },
    },
    async created() {
        if (this.$cookies.get('sbc_channel_type') != null) {
            this.channelSelected = this.$cookies.get('sbc_channel_type');
            this.UPDATE_CHANNEL_ID(this.$cookies.get('sbc_channel_type'));
        } else {
            this.channelSelected = 1;
            this.UPDATE_CHANNEL_ID(1);
        }
        this.channelSelected ? await this._initialData() : '';
    },
    methods: {
        validateBeforeBetting(submitBettingData) {
            const money = submitBettingData.amount;
            const userBalance = this.userInfor.balance;
            if (this.getBettingStatus() && submitBettingData.amount > 0 && userBalance >= money && this.isBetting == false) {
                return true
            }
            else if (!this.getBettingStatus()) {
                this.bettingStatus = "Game is not open";
                this.isBetsuccess = false;
                this.UPDATE_ERROR_MESSAGE(this.$t('title.game_is_close'))
                this.$toastr.e(this.$t('title.game_is_close'))
                return false
            }
            else if (submitBettingData.amount == 0 || submitBettingData.amount == null) {
                // console.log(submitBettingData)
                this.bettingStatus = this.$t('title.inputAmount');
                this.isBetsuccess = false
                this.UPDATE_ERROR_MESSAGE(this.$t('title.inputAmount'))
                this.$toastr.e(this.$t('title.inputAmount'));
                return false
            }
            else if (userBalance < money) {
                this.bettingStatus = "Not enought Balance"
                this.isBetsuccess = false;
                this.UPDATE_ERROR_MESSAGE(this.$t('title.not_enought_balance'))
                this.$toastr.e(this.$t(`title.not_enought_balance`))
                return false
            }
            else if (this.isBetting == true) {
                this.bettingStatus = "We processing your previous betting, please wait"
                this.isBetsuccess = false
                this.UPDATE_ERROR_MESSAGE(this.$t('title.loading'))
                this.$toastr.e(this.$t('title.loading'))
                return false
            }
            else {
                this.UPDATE_ERROR_MESSAGE(this.$t(`something when wrong`))
                this.$toastr.e(`something when wrong`)
                return false
            }
        },

        async _initialData() {
            await this.getCockXCasinoGameButton();
        },
        setChannelType(channelType) {
            if ((this.channelSelected) != channelType) {
                this.channelSelected = channelType;
                this.$cookies.set("sbc_channel_type", channelType);
                this.UPDATE_CHANNEL_ID(channelType);
                this.UPDATE_IS_REPORT(true)
            }
        },
        getBettingStatus() {
            if (this.channel.is_open && this.userInfor.enable && this.channel.enable) {
                return true
            }
            return false
        },
        checkSattus() {
            if (this.channel.is_open == null) {
                return "Not Open"
            } else {
                if (this.channel.is_open) {
                    return "Open"
                }
                return "Close"
            }
        },
        resultStatue() {
            if (this.channel.is_open == null && this.resultObj.results[0]) {
                return true
            } else {
                return false
            }
        },
        resultChannelColor(index) {
            if (this.channelStatus) {
                for (var i = 0; i < this.channelStatus.length; i++) {
                    if (this.channelStatus[i].channel_type == index) {
                        if (this.channelStatus[i].status == null) {
                            return "orange"
                        } else {
                            if (this.channelStatus[i].status) {
                                return "green"
                            }
                            return "red"
                        }
                    }
                }
            }
        },
        currencyFormat(num) {
            return parseInt(num).toLocaleString()
        },
        payoutFormat(num) {
            return num
        },
        checkSattusColor() {
            if (this.channel.is_open == null) {
                return "text-warning"
            } else {
                if (this.channel.is_open) {
                    return "text-success"
                }
                return "text-danger"
            }
        },
        _resetBetting() {
            this.CLEAR_BETTING_AMOUNT()
            this.isBetting = false;
            this.LOADING(false);
            this.UPDATE_ERROR_MESSAGE()
        },
        ...mapActions("$_cockfight_casino_live", [
            "getCockXCasinoGameButton"]),
        ...mapActions("$_modules", [
            "updateBalance", "updateUserInfo", "fetchUserInfo", "fetchNotList",
            "fetchNotCount", "refreshNotiList"]),
        ...mapMutations("$_modules", [
            "UPDATE_CHANNEL_ID",
            "UPDATE_USER_INFO",
            "UPDATE_NOTI_DATA",
            "CLEAR_BETTING_AMOUNT"
        ]),

    },
    async beforeRouteLeave(to, from, next) {

        // this.CLEAR_CACH()
        next()
    }
};

</script>