import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    channel: {
        enable: null,
        is_open: null,
        live_urls: [],
        _id: null,
        info: null,
        video_info: null,
        date: null,
        fight_no: 0,
        group_no: null,
        column: 20,
        fix_column: 20,
        is_maintain: null,
        maintain_desc: null,
        amount_min: 0,
        amount_max: 0,
    },
    isReport: true,
    channelStatus: [],
    numberMaster: [],
    resultData: {
        results: [],
        summary: {
            one: 0,
            two: 0,
            three: 0,
            four: 0,
            five: 0,
            six: 0,
            CANCEL: 0,
            DRAGON: 0,
            TIE: 0,
            TIGER: 0,
            MERON: 0,
            WALA: 0,
            BLUE: 0,
            EVEN: 0,
            ODD: 0,
            RED: 0,
            YEAR1: 0,
            YEAR2: 0,
            YEAR3: 0,
            YEAR4: 0,
            YEAR5: 0,
            YEAR6: 0,
            YEAR7: 0,
            YEAR8: 0,
            YEAR9: 0,
            YEAR10: 0,
            YEAR11: 0,
            YEAR12: 0,
            LARGE: 0,
            RANGE1: 0,
            RANGE2: 0,
            RANGE3: 0,
            RANGE4: 0,
            RANGE5: 0,
            SMALL: 0,
            B: 0,
            P: 0,
            PB: 0,
            PP: 0,
            T: 0,
        },
        currentBetting: {}
    },
    todayBetting: [],
    currentBetting: {
        list: [],
        meron: 0,
        tie: 0,
        wala: 0
    },
    createBetting: null,
    bettingTypeMaster: [],
    weight: {
        wala: {
            payout: 0,
            amount: 0
        },
        meron: {
            payout: 0,
            amount: 0
        },
        amount_min: 0,
        amount_max: 0
    },
    countDown: {
        countDown: 0,
        type: "",
        isPlay: false,
        isCountDown: false
    },
    button: [],
    vdolinks: [],
    dialog_betting: false,
    loadingReport: false,
    //Tiger Dragon
    tigerCard: "0052.png",
    dragonCard: "0052.png",
    // Baccarat
    playerCard1: '0052.png',
    playerCard2: '0052.png',
    playerCard3: '0052.png',
    bankerCard1: '0052.png',
    bankerCard2: '0052.png',
    bankerCard3: '0052.png',
    playerCardValue1: '',
    playerCardValue2: '',
    playerCardValue3: '',
    bankerCardValue1: '',
    bankerCardValue2: '',
    bankerCardValue3: '',
    totalP: null,
    totalB: null,
    listLotto: {},
    totalLotto: 0,
    listKlaklouk: [],
    listTaiSiv: [],
    listAbong: [],
    listFantan: [],
    listYuki: {},
    amount_min: 0,
    amount_max: 0,
    isSelectedCoin: false,
    loading_betting: false,
    errorMessage: '',
    clientTime: {
        is_countdown: null,
        is_open_betting: null,
        time: null,
    },
    currentGameResult: null,
    klakloukClearStart: null,
    clientRandom: {},
    firstCard: null,
    secondCard: null,
    selectedBtn: '',
    selectedBtnDragon: null,
    selectedCoin: '',
    backCard:false,
    sendCard:false,
    clientCard:null
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};