import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    darkMode: true,
    balance: 0,
    userInfo: {
        currencySymbol: "",
        balance: 0
    },
    mainMenuId: 'cock',
    channelId:  1,
    slotGame: null,
    channelList: [],
    isSoundOn: true,
    isAudioPlay: false,
    notiCount: 0,
    notiList: {},
    agentAccountList: [],
    userBank: {},
    deposit: {},
    withdraw: {},
    loading: false,
    notiDetail: {},
    dialog: false,
    notiData: [],
    editDialog: false,
    bettingAmount: 0,
    bettingKlakloukAmount: { gourd: { game_id: '', amout: 0, display: false }, chicken: { game_id: '', amout: 0, display: false }, lobster: { game_id: '', amout: 0, display: false }, crab: { game_id: '', amout: 0, display: false }, fish: { game_id: '', amout: 0, display: false }, tiger: { game_id: '', amout: 0, display: false }, status: { key: "", name: "" } },
    bettingSicboAmount: { bettingname: '', amount: 0, bettingid: '', status: false },
    bettingDdAmount: { bettingname: '', amount: 0, bettingid: '', status: false },
    expandMenu: false,
    expandMenuSmall: false,
    promoText:{},
    subMenuCock:[],
    subMenuCasino:[],
    subMenuCockXCasino:[],
    subMenuLottery:[],
    subMenuLotteryMenuLotto:[],
    subMenuLotteryMenuYuki:[],
    subMenuLotteryMenuKeno:[],
    subMenuLotteryMenuLottery2d:[],
    subMenuLotteryMenuLottery3d:[],
    subMenuLotteryMenuLotteryVn:[],
    subMenuMachineGame:[],
    subMenuMachineGameMenuAll:[],
    subMenuMachineGameMenuBaccarat:[],
    subMenuMachineGameMenuDragonTiger:[],
    subMenuMachineGameMenuTaiXiu:[],
    subMenuMachineGameMenuDoden:[],
    subMenuMachineGameMenuSicBo:[],
    subMenuMachineGameMenuKlaKlouk:[],
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};