<template>
    <div class="game-machine-component-mid">
        <div class="game-machine-status-container">
            <v-row class="ma-0" v-if="!_isChannelCock(channelType)">
                
                <div class="cock-casino-status" >
                    <p class="machine-status-text" :class="textColorBaseOnChannelStatus(updateChInfo.is_open)">
                      {{ $t(`title.${checkChannelStatus(updateChInfo.is_open)}`)}}
                    </p>
                </div>
                <v-spacer></v-spacer>
                <div class="game-machine-countdown" v-if="passTime.time && passTime.time > 0">
                    <p class="machine-countdown-text">{{ passTime.time }}</p>
                </div>
                <div class="game-machine-countdown" v-else-if=" passTime.countDown && passTime.countDown > 0  ">
                    <p class="machine-countdown-text">{{ passTime.countDown }}</p>
                </div>
                <div class="game-machine-countdown" v-else>
                    <p class="machine-countdown-text" style="font-size:0">  
                        <v-progress-circular
                        indeterminate
                        :width="2"
                        style=""
                        class="loading-time"
                        color="amber"
                      ></v-progress-circular></p>
                </div>
            </v-row>
            <v-row class="ma-0" v-if="_isChannelCock(channelType)">
                <div class="cock-casino-status w-100" >
                    <p class="machine-status-text" :class="textColorBaseOnChannelStatus(chInfo.is_open)">
                      {{ $t(`title.${checkChannelStatus(chInfo.is_open)}`)}}
                    </p>
                </div>
               
            </v-row>
        </div>

        <!--cock-casino-live-component-mid-logo-result-->
        <div class="cock-casino-live-component-mid-logo-result">
            <img  :src="findMachineGameLogo(channelType)" alt=""/>
            <div class="game-info-result mc-cir " 
            v-if="resultObjS && resultObjS.co_type_of_betting"
            :class="resultObjS && resultObjS.co_type_of_betting && resultObjS.co_type_of_betting.color ? 'mc-'+ resultObjS.co_type_of_betting.color :''" >
                <span class="zoom-in-zoom-out">
                    {{ resultObjS.co_type_of_betting.name}}
                </span>
            </div>
        </div>
        <div class="game-machine-component-mid-enter">
            <div @click="_goToSubGame(channelType)">
                <span><v-icon>mdi-play-circle</v-icon> {{$t('title.enter')}}</span>
            </div>
        </div>
    </div>
</template>
<script>

import findImg from "../../../../../mixin/MX_FindImageFunctionCollection.js"
import { mapMutations } from "vuex";

export default {
    mixins: [findImg],
    props:['passGame', 'channelType', 'passTime', 'chInfo', "resultObjS", 'updateChInfo'],
    methods:{
        _isChannelCock(channelType){
            switch(channelType){
                case "1" : return true;
                case "2" : return true;
                case "3" : return true;
                case "4" : return true;
                case "15" : return true;
                case "16" : return true;
                default : return false;
            }
        },
        _goToSubGame(channelType){
            let path;
            switch(true){
                case (  channelType == '1'  || channelType == '2' || channelType == '3' ||
                        channelType == '4' || channelType == '15' || channelType == '16' ):
                    path = '/cockfight';
                    this.$cookies.set("sbc_channel_type", this.channelType);
                    this.UPDATE_CHANNEL_ID(this.channelType);
                    this.$cookies.set("sbc_defaut_main_menu", 'cock');
                    break;
                case (  channelType == '5' || channelType == '6' || channelType == '7' || channelType == '8' || 
                    channelType == '9' || channelType == '10' || channelType == '11' || channelType == '12' ||
                    channelType == '13' || channelType == '14' || channelType == '17' || channelType == '18' ||
                    channelType == '19' || channelType == '20' || channelType == '21'):
                    path = '/casino';
                    this.UPDATE_CHANNEL_ID(this.channelType);
                    this.$cookies.set("sbc_channel_type", this.channelType);
                    this.$cookies.set("sbc_live_channel", this.channelType);
                    this.$cookies.set("sbc_defaut_main_menu", 'casino');
                    break;
               
            }
            this.$router.push({ path }).catch(() => {
                /* ignore */
            });
        },
        checkChannelStatus(data) {
            if (data == null) {
                return "Not_Open"
            } else {
                if (data) {
                    return "Open"
                }
                return "Close"
            }
        },
        textColorBaseOnChannelStatus(data) {
            if (data == null) {
                return "text-warning"
            } else {
                if (data) {
                    return "open-betting"
                }
                return "text-danger"
            }
        },
        ...mapMutations("$_modules", [
            "UPDATE_CHANNEL_ID",
            "UPDATE_USER_INFO",
            "UPDATE_NOTI_DATA",
            "CLEAR_BETTING_AMOUNT"
            ]),
}
}
</script>
<style scoped>

.open-betting {
    color: #62da7e;
   
}
.close-betting {
    color: #dc3545;
}

.game-machine-component-mid-logo-result {
    padding: 0 !important;
}
.cock-casino-live-component-mid-logo-result>img {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: fill !important;
}
</style>