<template>
  <div class="w-100">
    <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }}</h5>
    <div class="game-machine-component-result ">
      <div class="trends limit-on-tab" style="position: relative;">
        <!-- <div v-if="isShow">
          <div v-if="resultObj.results && resultObj.results.length > 0" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; background-color: rgba(40, 90, 226, .83); align-items: center;">
            <div class="h-100">
              <table style="width: 100%; border-spacing: 30px; height: 100%;">
                <tr style="height: 38px;">
                  <th style="background-color: rgba(40, 90, 226, .83); color: #ffffff;" colspan="3"><b>Result</b></th>
                </tr>
                <tr>
                  <td>
                    <v-img :src="findImageOfEachAnimal(resultObj.results[0].result1_name)" width="48" style=" text-align: center; margin-right: auto; margin-left: auto;height: 48px;"></v-img>
                  </td>
                  <td>
                    <v-img :src="findImageOfEachAnimal(resultObj.results[0].result2_name)" width="48" style=" text-align: center; margin-right: auto; margin-left: auto;height: 48px;"></v-img>
                  </td>
                  <td>
                    <v-img :src="findImageOfEachAnimal(resultObj.results[0].result3_name)" width="48" style=" text-align: center; margin-right: auto; margin-left: auto;height: 48px;"></v-img>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div> -->
        <div v-if="sResultKk && sResultKk.length > 0" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; background-color: rgba(40, 90, 226, .83); align-items: center;">
            <div class="h-100">
              <table style="width: 100%; border-spacing: 30px; height: 100%;">
                <tr style="height: 38px;">
                  <th style="background-color: rgba(40, 90, 226, .83); color: #ffffff;" colspan="3"><b>Result</b></th>
                </tr>
                <tr>
                  <td  v-for="i in sResultKk" :key="i">
                    <v-img :src="findImageOfEachAnimal(i.name)" width="48" style=" text-align: center; margin-right: auto; margin-left: auto;height: 48px;"></v-img>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        <div>
          <!-- <div class="trend-legend-custome  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-wala">{{resultObj.summary["NUMBER-1"]}}</span>
              <p class="mb-0 count-label">{{ $t("menu.kk1") }}</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-wala">{{resultObj.summary["NUMBER-1"]}}</span>
              <p class="mb-0 count-label">{{ $t("menu.kk2") }}</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-wala">{{resultObj.summary["NUMBER-1"]}}</span>
              <p class="mb-0 count-label">{{ $t("menu.kk3") }}</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-wala">{{resultObj.summary["NUMBER-1"]}}</span>
              <p class="mb-0 count-label">{{ $t("menu.kk4") }}</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-wala">{{resultObj.summary["NUMBER-1"]}}</span>
              <p class="mb-0 count-label">{{ $t("menu.kk5") }}</p>
            </div>
            <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                class="count rounded-circle trend-wala">{{resultObj.summary["NUMBER-1"]}}</span>
              <p class="mb-0 count-label">{{ $t("menu.kk6") }}</p>
            </div>
          </div> -->
        </div>
      <div class="px-3 pb-0 gp-panel-bg landscape-trend" 
                style="position: relative; background-color: #ffffff !important;padding: 0 !important;">
        <v-col cols="12" sm="12" style="padding: 0">
                    <v-row style="padding: 0; margin: 0">
                        <v-col
                        cols="6"
                        md="6"
                        sm="6"
                        lg="6"
                        style="padding: 0; margin: 0">
                            <VideoLive :passurl="passUrl" :channelType="channelType" />
                        </v-col>
                        <v-col cols="6" md="6" sm="6" lg="6" style="padding:  10px 0px 0 0; margin: 0"> 
                <div class="table-responsive gp-scroll">
                  <table class="k-machine-table">
                    <tbody>
                      <tr>
                        <td v-for="col in cols" :key="col">
                          <div>
                            <div
                              v-for="(result, i) in resultObj.results
                                ? resultObj.results.slice().reverse()
                                : resultObj.results"
                              :key="i"
                              class="text-center"
                              style="
                                margin-left: auto;
                                margin-right: auto;
                                text-align: center;
                              "
                            >
                              <div v-if="col - 1 == i">
                                <div class="machine-list-klaklouk"
                                >
                                  <b >#{{ result.fight_no }}</b>
                                </div>
                                <v-img
                                  class="machine-list-klaklouk-img"
                                  v-if="result.result1_name"
                                  :src="findImageOfEachAnimal(result.result1_name)"
                                ></v-img>
                                <v-img
                                  v-if="result.result2_name"
                                  :src="findImageOfEachAnimal(result.result2_name)"
                                  class="machine-list-klaklouk-img"
                                ></v-img>
                                <v-img
                                  v-if="result.result3_name"
                                  :src="findImageOfEachAnimal(result.result3_name)"
                                  class="machine-list-klaklouk-img"
                                ></v-img>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                        </v-col>
                    </v-row>
                </v-col>
      </div>
      </div>
    </div>
    <div class="game-machine-component-reserve-space"></div>
  </div>
</template>
<script>

import shareFunction from "../../../../../../mixin/MX_ShareFunctionCollection.js";
import { mapActions } from "vuex";
import VideoLive from "../GameResult/VideoLive.vue";

export default {
  components: {
    VideoLive
  },
  mixins: [shareFunction],
  props:['passGame', 'passIndex', 'channelType', 'passChDesc', 'showResult', 'resultlist', 'resultObjS', 'passUrl', 'sResultKk'],
  data() {
    return {
      rows: 6,
      cols: 20,
      isShow: false,
      resultObj: {
        results:[]
      }
    }
  },
  async created() {
    ////console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  watch: {
    resultObjS: function (newVal) {
      if(newVal.objCoResult){
        // this.countDownTimer();
        this.resultObj.results = newVal.objCoResult
      }
    }
  },
  methods: {
    countDownTimer() {
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, 1000)
    },
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        //console.log(`getResult2 >>>> ${response}`)
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    findImageOfEachAnimal(animalName) {
      switch (animalName) {
          case "ក្តាន់":
            return "/images/a_each_game/klaklok/0001.png";
          case "ឃ្លោក":
            return "/images/a_each_game/klaklok/0002.png";
          case "មាន់":
            return "/images/a_each_game/klaklok/0003.png";
          case "បង្កង":
            return "/images/a_each_game/klaklok/0004.png";
          case "ក្ដាម":
            return "/images/a_each_game/klaklok/0005.png";
          case "ត្រី":
            return "/images/a_each_game/klaklok/0006.png";
          case "ខ្លា":
            return "/images/a_each_game/klaklok/0007.png";
          case "CANCEL":
            return "images/a_each_game/klaklok/0010.png";
          default:
            return "";
      }
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult"]),
  }
}
</script>
<style scoped>
 
.k-table {
  max-width: 100% !important;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}
.v-application .py-md-7, .py-sm-7{
  padding: 0 !important;
}
</style>