import yuki from '@/modules/FolderGames/LotteryMenu/yuki';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/YUKI',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: yuki,
                    name: 'YUKI'
                }
            ]
    }
];

export default routes;