import DashboardLive from '~modules/FolderGames/MachineGames/SubGameInMachineGame';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/BACCARAT',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: DashboardLive,
                    name: 'BACCARAT'
                }
            ]
    }
];

export default routes;