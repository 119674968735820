<template>
  <div>    
    <v-row class="ma-0 w-100">
      <!-- {{ getSelectedHanBtn }} -->
      <div class="machine-baccarat-three-section machine-blue-title text-center">
        <div class="machine-klaklouk-button pt-0 machine-red-title machine-play-red-bg"
        :class="getSelectedHanBtn && getSelectedHanBtn.button && getSelectedHanBtn.button.name == defineOrder()[0].name 
        && getSelectedHanBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[0])"> 
          <p class="machine-button-name font-apong-ccl-han2 white--text">
            <span class="font-apong-ccl-han2">{{passBetType[0] ? passBetType[0].name.toUpperCase() : 'BANKER 1'}} </span>
          </p>
          <p class="machine-payout font-apong-ccl-han2">
              <span class="font-apong-ccl-han2">{{passBetType[0] ? passBetType[0].payout : '1.95'}}</span>
          </p>
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0 machine-blue-title machine-play-blue-bg" :class=" getSelectedHanBtn && getSelectedHanBtn.button && getSelectedHanBtn.button.name ==defineOrder()[3].name && getSelectedHanBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[3])"> 
          <p class="machine-button-name font-apong-ccl-han2 white--text">
            <span class="font-apong-ccl-han2">{{passBetType[2] ? passBetType[2].name.toUpperCase() : 'PLAYER 1'}}</span>
          </p>
          <p class="machine-payout font-apong-ccl-han2">
            <span class="font-apong-ccl-han2">{{passBetType[2] ? passBetType[2].payout : '1.95'}}</span>
          </p>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-baccarat-three-section machine-green-title text-center">
        <div class="machine-klaklouk-button pt-0 machine-red-title machine-play-red-bg" :class=" getSelectedHanBtn && getSelectedHanBtn.button && getSelectedHanBtn.button.name ==defineOrder()[1].name && getSelectedHanBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[1])">
          <p class="machine-button-name font-apong-ccl-han2 white--text">
            <span class="font-apong-ccl-han2">{{passBetType[5] ? passBetType[5].name.toUpperCase() : 'BANKER 2'}}</span>
          </p>
          <p class="machine-payout font-apong-ccl-han2">
            <span class="font-apong-ccl-han2">{{passBetType[5] ? passBetType[5].payout : '1.95'}}</span>
          </p>
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0 machine-blue-title machine-play-blue-bg" :class=" getSelectedHanBtn && getSelectedHanBtn.button && getSelectedHanBtn.button.name ==defineOrder()[4].name && getSelectedHanBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[4])">
          <p class="machine-button-name font-apong-ccl-han2 white--text">
            <span class="font-apong-ccl-han2">{{passBetType[1] ? passBetType[1].name.toUpperCase() : 'PLAYER 2'}}</span>
          </p>
          <p class="machine-payout font-apong-ccl-han2">
            <span class="font-apong-ccl-han2">{{passBetType[1] ? passBetType[1].payout : '1.95'}}</span>
          </p>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="machine-baccarat-three-section machine-red-title text-center">
        <div class="machine-klaklouk-button pt-0 machine-red-title machine-play-red-bg" :class="getSelectedHanBtn && getSelectedHanBtn.button && getSelectedHanBtn.button.name ==defineOrder()[2].name && getSelectedHanBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[2])"> 
          <p class="machine-button-name font-apong-ccl-han2 white--text">
            <span class="font-apong-ccl-han2">{{passBetType[3] ? passBetType[3].name.toUpperCase() : 'BANKER 3'}}</span>
          </p>
          <p class="machine-payout font-apong-ccl-han2">
            <span class="font-apong-ccl-han2">{{passBetType[3] ? passBetType[3].payout : '1.95'}}</span>
          </p>
        </div>
        <div class="machine-klaklouk-button mt-3 pt-0 machine-blue-title machine-play-blue-bg" :class=" getSelectedHanBtn && getSelectedHanBtn.button && getSelectedHanBtn.button.name ==defineOrder()[5].name && getSelectedHanBtn.channelType == passChannelType ?'machine-game-active':''" @click="_updatedSelectedBtn(defineOrder()[5])"> 
          <p class="machine-button-name font-apong-ccl-han2 white--text">
            <span class="font-apong-ccl-han2">{{passBetType[4] ? passBetType[4].name.toUpperCase() : 'PLAYER 3'}}</span>
          </p>
          <p class="machine-payout font-apong-ccl-han2">
            <span class="font-apong-ccl-han2">{{passBetType[4] ? passBetType[4].payout : '1.95'}}</span>
          </p>
        </div>
      </div>
    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitHan'"/>
  </div>
</template>
<script>

import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"
import findImg from "../../../../../../mixin/MX_FindImageFunctionCollection.js"

import {mapGetters, mapMutations } from "vuex";

export default {
  mixins: [findImg],
  components: {
    CockXCasinoSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  computed:{
    
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedHanBtn: "getSelectedHanBtn",
    }),
  },

  methods:{
    defineOrder(){
      return this.passBetType.slice().sort(function(a, b){
        return (a.order > b.order) ? 1 : -1;
      });
    },
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_HAN_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_HAN_BUTTON_IN_ALL",
    ]),
  }
}
</script>

<style>
 .fan-button {
  font-size: medium !important;
 }

.margin-fantan-button-name {
  margin: 0 !important;
}
</style>