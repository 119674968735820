<template>
  <!-- eslint-disable -->
  <div v-if="todayReport">
    <v-btn 
      @click="print(todayReport._id)"
      x-small class="btn btn-info w-25 pa-3" color="btn-green"
      id="print-button"
    >
    <img :src="printing" class="w-25 "/>
    </v-btn >
    <table :id="`printMe${todayReport._id}`" hidden>
      <tbody>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            {{ website }}
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
           {{$t('title.no')}}: {{ todayReport._id }}
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.fight')}}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.fight_no ? todayReport.fight_no : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.type')}}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport ? $t('title.'+todayReport.betting)  : ''}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.date')}}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{todayReport.time}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.result')}}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport ? $t('title.'+todayReport.result_name)  : ''}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.cash')}}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.is_win == true ? formatMoney(todayReport.bet_amount) + ' x ' + todayReport.payout + ' = ' + formatMoney(todayReport.case)  :  todayReport.amount }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('title.total_win_lose')}}</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{todayReport.is_win ? $t('title.win') : $t('title.lose')}}</span>
                  </td>
                </tr>
                <!-- <tr>
                  <td style="padding: 5px; text-align: left">Win:</td>
                  <td style="padding: 5px; text-align: left">
                    {{
                      current.amount_win || current.amount_win == 0
                        ? parseInt(current.amount_win)toLocaleString()
                        : "???"
                    }}
                  </td>
                </tr> -->
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import clickMixin from "~mixin/MX_ShareFunctionCollection.js"
export default {
  props: ["todayReport"],
  data() {
        return {
            mixins: [clickMixin],
            website:"sbc2888"
        }
    },
    computed:{
      printing(){
        return require("/public/images/a_page_access_global/printing.png")
      }
    },
  methods: {
    _resultMoney(today){
            if(today.amount_win <=0) return this.formatMoney(today.amount_win)
            else return this.formatMoney(today.case)
        },
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(`printMe${id}`);
    },
  },
};
</script>