const LATEST_FIVE_RESULTS = (state, data) => {
    state.lastFiveResults = data;
};
const CURRENT_BETTING = (state, data) => {
    state.currentBetting = data;
};
const GET_BUTTON = (state, data) => {
    state.buttonMoney = data;
};
const INPUT_BETTING_AMOUNT = (state, data) => {
    state.bettingAmount = data;
};
const UPDATE_LOTTERY3D_LIMIT = (state, data) => {
    state.limitYukiBetting = data;
};

const UPDATE_DATA_BETTING = (state, data) => {
    state.bettingData =  data
};
const APPEND_CURRENT_BETTING = (state, data) => {
    state.currentBetting.total_amount = state.currentBetting.total_amount + data.total_amount;
    state.currentBetting.list.unshift(data);
};
const PREVIOUS_BETTING = (state, data) => {
    state.previousBetting = data;
};
const CURRENT_GAME = (state, data) => {
    state.currentGame = data;
};
const CHANNEL_POST = (state, data) => {
    state.lotteryChannel = data;
};
const LATEST_ONE_RESULTS = (state, data) => {
    if(data!=null){
        state.lastOneResult = data;
    }
    
};
const LATEST_TWENTY_RESULTS = (state, data) => {
    state.lastTwentyResult = data;
};

const IS_BETTING = (state, data) => {
    state.is_Betting = data;
};

const UPDATE_CLIENT_TIME = (state, data) => {
    state.clientTime = data;
};

// New define
const UPDATE_BETTING_TYPE = (state, data) => {
    state.bettingType = data;
    state.selectedListBettingNumber = [];
    state.numbers.forEach((item, index) => state.selectedNumber[index] = false)
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATED_CHANNEL_POST = (state, data) => {
    state.selectedLo = false;
    if(!state.selectedPost.includes(data)) {
        state.selectedPost.push(data)
    } else{
        state.selectedPost.splice(state.selectedPost.indexOf(data), 1);
    }
    if(state.selectedPost.length <5 )  state.selectedAllPost = false;
    if(state.selectedPost.length == 5 ){
        state.selectedAllPost = true;
    }
    state.totalInvoice = state.selectedPost.length * state.selectMultiBetNumber.length *  state.bettingAmount;
};
const UPDATED_SELECT_LIST_BETTING = (state, data) => {
    state.selectedListBettingNumber.push(data);
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UNSELECT_LIST_BETTING = (state, data) => {
    state.selectedListBettingNumber =  state.selectedListBettingNumber.filter((n) => {
        return n != data
      });
      state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UNSELECT_SPECIFIC_BETTING = (state, data) => {
    state.selectedNumber[data] = false;
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};

const FILTER_UNSELECTED = (state, data) => {
    state.selectedListBettingNumber = state.selectedListBettingNumber.filter((number) => {
        return !data.xcludeArr.includes(number);
      }
    );
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const LIST_DETAIL_INVOICE = (state, data) => {
    state.ListDetailInvoice = data;
};
const UPDATE_DIALOG_INVOICE = (state, data) => {
    state.dialogDetailInovice = data;
};

const UPDATE_LOADING_DIALOG_INVOICE = (state, data) => {
    state.loadingDetailDialog = data;
};
const CLEAR_RESULT_LOTTERY3 = (state) => {

    state.result_a = ['???','???','???','???','???'];
    state.result_b = ['???','???','???','???','???'];
    state.result_c = ['???','???','???','???','???'];
    state.result_d = ['???','???','???','???','???'];
    state.result_e = ['???','???','???','???','???'];

};
const CLEAR_TO_DEFAULT = (state) => {
    state.bettingAmount = 0;
    state.totalInvoice = 0;
    state.result_a = ['???','???','???','???','???'];
    state.result_b = ['???','???','???','???','???'];
    state.result_c = ['???','???','???','???','???'];
    state.result_d = ['???','???','???','???','???'];
    state.result_e = ['???','???','???','???','???'];
    state.selectedLo = false;
    state.selectedMulti = false;
    state.clearBettingNumber = !state.clearBettingNumber;
    state.clientTime = {
        is_countdown: null,
        is_open_betting: null,
        time: null,
      }
};
const UPDATE_LRY3_RESULT_A = (state,data) => {
    state.result_a = data;
};
const UPDATE_LRY3_RESULT_B = (state,data) => {
    state.result_b = data;
};
const UPDATE_LRY3_RESULT_C = (state,data) => {
    state.result_c = data;
};
const UPDATE_LRY3_RESULT_D = (state, data) => {
    state.result_d = data;
};
const UPDATE_LRY3_RESULT_E = (state, data) => {
    state.result_e = data;
};
const APPEND_RESULT_STATEMENT = (state, data) => {
    state.lastTwentyResult.unshift(data);
};
const SET_NORMAL_BET_NUMBER = (state, data) => {
    state.selectNormalBetNumber = data;
};
const SET_MULTI_BET_NUMBER = (state, data) => {
    state.selectMultiBetNumber = data;
};
const UPDATED_SELECT_ALL_CHANNEL_POST = (state) => {
    state.selectedAllPost = !state.selectedAllPost;
    if(state.selectedAllPost) {
        state.selectedLo = false;
        state.selectedPost = ["a","b","c","d","e"];
        state.betA =true;
        state.betB =true;
        state.betC =true;
        state.betD =true;
        state.betE =true;
        state.totalInvoice =  state.selectedPost.length * state.selectMultiBetNumber.length *  state.bettingAmount;
    } else {
        state.selectedPost = [];
        state.betA =false;
        state.betB =false;
        state.betC =false;
        state.betD =false;
        state.betE =false;
        state.totalInvoice =  state.selectMultiBetNumber.length *  state.bettingAmount;
    }
};
const UPDATE_SELECTED_LO = (state) => {
    state.selectedLo = !state.selectedLo;
    if(state.selectedLo){
        state.selectedAllPost = false;
        state.totalInvoice =  5 * state.selectMultiBetNumber.length *  state.bettingAmount;
    }else{
        state.totalInvoice =  state.selectMultiBetNumber.length *  state.bettingAmount;
    }
};
const UPDATE_SELECTED_MULTI = (state, data) => {
    if(data){
        if(state.selectedLo && state.selectedPost.length == 0){
            state.totalInvoice =  5 * state.selectMultiBetNumber.length *  state.bettingAmount;
        }else{
            state.totalInvoice =  state.selectedPost.length * state.selectMultiBetNumber.length *  state.bettingAmount;
        }
    }
    state.selectedMulti = data;
};
const UPDATE_SELECTED_NORMAL = (state, data) => {
    if(data){
        if(state.selectedLo && state.selectedPost.length == 0){
            state.totalInvoice =  5 * state.bettingAmount;
        }else{
            state.totalInvoice =  state.selectedPost.length *  state.bettingAmount;
        }
    }
    state.selectedNormal = data;
};
const UPDATE_BETTING_AMOUNT = (state, data) => {
 
    state.bettingAmount =  parseFloat(state.bettingAmount) + parseFloat(data);
    if(state.selectedLo && state.selectedPost.length == 0){
        state.totalInvoice =  5 * state.selectMultiBetNumber.length *  state.bettingAmount;
    }else{
        state.totalInvoice =  state.selectedPost.length * state.selectMultiBetNumber.length * state.bettingAmount;
    }
};
const RESET_BETTING = (state) => {
    state.betNumber = "";
    state.numberTemp= "";
    state.selectedNormal = true;
    state.selectedMulti = false;
    state.selectedAllPost = false;
    state.selectedLo = false;
    state.selectMultiBetNumber = [];
    state.selectedPost = [];
    state.betA =false;
    state.betB =false;
    state.betC =false;
    state.betD =false;
    state.betE =false;
};
const SET_BET_A = (state, data) => {
    state.betA = data;
};
const SET_BET_B = (state, data) => {
    state.betB = data;
};
const SET_BET_C = (state, data) => {
    state.betC = data;
};
const SET_BET_D = (state, data) => {
    state.betD = data;
};
const SET_BET_E = (state, data) => {
    state.betE = data;
};
export default {
    CLEAR_RESULT_LOTTERY3,
    SET_BET_A,
    SET_BET_B,
    SET_BET_C,
    SET_BET_D,
    SET_BET_E,
    RESET_BETTING,
    UPDATE_SELECTED_NORMAL,
    UPDATE_SELECTED_MULTI,
    UPDATE_SELECTED_LO,
    SET_NORMAL_BET_NUMBER,
    SET_MULTI_BET_NUMBER,
    APPEND_RESULT_STATEMENT,
    UPDATE_LRY3_RESULT_A,
    UPDATE_LRY3_RESULT_B,
    UPDATE_LRY3_RESULT_C,
    UPDATE_LRY3_RESULT_D,
    UPDATE_LRY3_RESULT_E,
    UPDATE_LOADING_DIALOG_INVOICE,
    LIST_DETAIL_INVOICE,
    UPDATE_DIALOG_INVOICE,
    //define
    UPDATE_LOTTERY3D_LIMIT,
    UPDATE_CLIENT_TIME,
    CLEAR_TO_DEFAULT,
    UPDATE_BETTING_TYPE,
    UPDATED_CHANNEL_POST,
    UPDATED_SELECT_ALL_CHANNEL_POST,
    UPDATED_SELECT_LIST_BETTING,
    UNSELECT_LIST_BETTING,
    UNSELECT_SPECIFIC_BETTING,
    FILTER_UNSELECTED,
    // --
    UPDATE_DATA_BETTING,
    UPDATE_BETTING_AMOUNT,
    LATEST_FIVE_RESULTS,
    LATEST_TWENTY_RESULTS,
    CURRENT_BETTING,
    PREVIOUS_BETTING,
    CURRENT_GAME,
    CHANNEL_POST,
    LATEST_ONE_RESULTS,
    APPEND_CURRENT_BETTING,
    IS_BETTING,
    GET_BUTTON,
    INPUT_BETTING_AMOUNT,
};
