<template>
  <div class="keno-left">
    <v-row no-gutters>
      <v-col cols="12">
        <TotalBettingInvoice></TotalBettingInvoice>
        <CurrentBettingStatement v-on:detailInvoice="_goToDetail"></CurrentBettingStatement>
        <PreviousBettingStatement  v-on:detailInvoice="_goToDetail"></PreviousBettingStatement>
        <DetailStatement v-if="getDialogInvoice" :selectedItem="selectedItem" ></DetailStatement>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TotalBettingInvoice from "./SubComponent/LeftComponents/TotalBettingInvoice.vue";
import CurrentBettingStatement from "./SubComponent/LeftComponents/CurrentBettingStatement.vue";
import PreviousBettingStatement from "./SubComponent/LeftComponents/PreviousBettingStatement.vue";
import DetailStatement from "./SubComponent/LeftComponents/DetailStatement.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
import Mixin from "~mixin";
export default {
  components: {
    TotalBettingInvoice,
    CurrentBettingStatement,
    PreviousBettingStatement,
    DetailStatement
  },
  // DEFINE local VARIABLE
  data() {
    return {
      mixins: [Mixin],
      previousGame: {},
      current: {},
      dialogPrintTicket: false,
      dialogPrintTicketMulti: false,
      format: "YYYY-MM-DD",
      selectedAddOns: [],
      items: [],
    ticket_id: "",
    drawTicketLine: "--------------------------------------------------------",
    drawTicketLineOnPrint: "------------------------------------------------",
    WebsiteName: "pp77",
    headersDetail: [
      { text: this.$t("title.date_time"), value: 'time' },
      { text:  this.$t("title.game"), value: 'no' },
      { text: this.$t("title.bet_type"), value: 'bet_type' },
      { text: this.$t("title.amount"), value: 'amount' },
      { text:this.$t("title.total_cast"),value:'total_cast'},
      { text: this.$t("title.result"),value:'result'},
      { text: this.$t("title.result_number"), value: 'result_number'}

    ],
    detailData: [],
    dialog: false,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    dataRequest:{
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
    },
    getLoadingDetail:false,
    invoice_id: null,
    betALength: {},
    betBLength: {},
    betCLength: {},
    betDLength: {},
    betELength: {},
    labelA:null,
    labelB:null,
    labelC:null,
    labelD:null,
    labelE:null,
    selectedItem:{}
    }},
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_lottery3d", {
      getDialogInvoice:'getDialogInvoice',
      getLoadingDetailDialog:"getLoadingDetailDialog"
    }),
  },
  methods: {
    moment,
    _goToDetail(data){
      this.selectedItem = data;
      this.invoice_id = data.invoice_no;
      this.loadDetailData(data.invoice_no);
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.invoice_id);  
      this.getLoadingDetail = false;
    },
    async loadDetailData(_id){
      const data = {
        data: this.dataRequest,
        id: _id
      }
      await this.fetchDetailInvoice(data);

    },
    printTicket(item, ticket_id) {
      this.items = item;
      this.ticket_id = ticket_id;
      this.dialogPrintTicket = true;
    },
    printTicket2(item) {
      // console.log('@item:',item);
      this.items = item;
      this.betALength = item.bet_a.number.length
      this.betBLength = item.bet_b.number.length
      this.betCLength = item.bet_c.number.length
      this.betDLength = item.bet_d.number.length
      this.betELength = item.bet_e.number.length
      this.labelA = item.bet_a.label
      this.labelB = item.bet_b.label
      this.labelC = item.bet_c.label
      this.labelD = item.bet_d.label
      this.labelE = item.bet_e.label
      this.dialogPrintTicket2 = true;
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    closePrintDialog() {
      this.dialogPrintTicket = false;
    },
    openPrintMultiple() {
      if (this.selectedAddOns != "") {
        this.dialogPrintTicketMulti = true;
      }
    },
    printMulti() {
      this.$htmlToPaper("printMeMultiTicket");
    },
    closePrintMultiDialog() {
      var inputs = document.getElementsByClassName("myCheck");
      for (var i = 0, l = inputs.length; i < l; ++i) {
        if (inputs[i].checked) {
          inputs[i].checked = false;
        }
      }
      this.selectedAddOns = [];
      this.dialogPrintTicketMulti = false;
    },
    ...mapActions("$_lottery3d",["fetchDetailInvoice"]),
    ...mapMutations("$_lottery3d", [
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
    ]),
  },
};
</script>