<template>
  <v-row no-gutters>
    <v-col cols="12">
      <div class="my-1 mx-0">
        <div class="w-100">
          <v-btn class="lottery-vn-btn-name" @click="selectLo()"
            :class="btnLoSelected ? 'btn-lotery3-all-lo-dis' : 'btn-lotery3-all-lo'" prepend-icon="$vuetify">
            LO
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-row class="my-1 mx-0">
        <div v-for="i in alphabet_btn" :key="i" class="lottery3d-channel-column">
          <v-btn class="lottery-vn-btn-name text-uppercase" @click="selectChannel(i)"
            :class="_calThatBtn(i) ? 'btn-lotery3-channel-dis' : 'btn-lotery3-channel'+i">
            {{ i }} 
          </v-btn>
        </div>
      </v-row>
    </v-col>
    <v-col cols="12">
      <div class="my-1 mx-0">
        <div class="w-100">
          <v-btn class="lottery-vn-btn-name" @click="selectAll()"
            :class="btnAllSelected ? 'btn-lotery3-all-lo-dis' : 'btn-lotery3-all-lo'" prepend-icon="$vuetify">
            ALL(A, B, C, D, E)
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-row class="my-1 mx-0">
        <div class="w-50">
          <v-btn @click="selectNormal()" :disabled="btnNormalSelected" class="btn-lotery3-50 lottery-vn-btn-name"
            prepend-icon="$vuetify">
            ធម្មតា
          </v-btn>
        </div>
        <div class="w-50">
          <v-btn  @click="selectMulti()" :disabled="btnMultiSelected" class="btn-lotery3-50 lottery-vn-btn-name"
            prepend-icon="$vuetify">
            គុណ
          </v-btn>
        </div>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row class=" my-1 mx-0 betting-number-box">
        <div class="lvn-input-box">
          <p class="betting-number-text">{{ numberTemp }}</p>
        </div>
        <span  class="lvn-input-icon">
          <v-icon  @click="handleClear">mdi-close</v-icon>
        </span>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row class="my-1 mx-0">
        <div class="lottery3d-betting-number" v-for="(i, n) in 10" :key="n">
          <v-btn @click="numSelect(n)" class="btn-lotery3-cus lottery-vn-btn-name">
            {{ n }}
          </v-btn>
        </div>

      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      betNumber: "",
      numberTemp: "",
      results: [],
      alphabet_btn:['a','b','c','d','e']
    }
  },
  computed: {
    
    ...mapGetters("$_lottery_vn", {
      getLotteryChannel: "getLotteryChannel",
      getBettingAmount: "getBettingAmount",
      getNormalBetNumber: "getNormalBetNumber",
      getMultiBetNumber: "getMultiBetNumber",
      getSelectedChannelPost: "getSelectedChannelPost",
      btnMultiSelected: "getselectedMulti",
      btnLoSelected: "getselectedLo",
      getTotalInvoice: "getTotalInvoice",
      btnNormalSelected: "getselectedNormal",
      btnAllSelected: "getSelectAllPost",
      btnASelected: "getselectedA",
      btnBSelected: "getselectedB",
      btnCSelected: "getselectedC",
      btnDSelected: "getselectedD",
      btnESelected: "getselectedE",
      getClearBettingNumber: "getClearBettingNumber"
    }),
  },
  watch: {
    getClearBettingNumber: async function () {
      this.handleClear();
    },
  },
  methods: {
    _calThatBtn(channel){
      switch(channel){
        case 'a': return this.btnASelected;
        case 'b': return this.btnBSelected;
        case 'c': return this.btnCSelected;
        case 'd': return this.btnDSelected;
        case 'e': return this.btnESelected;
      }
    },
    selectChannel(channel) {
      if (this.betNumber.length >= 2) {
        this.UPDATED_CHANNEL_POST(channel)
        if (channel == 'a') {
          this.SET_BET_A(!this.btnASelected);
        } else if (channel == 'b') {
          this.SET_BET_B(!this.btnBSelected);
        } else if (channel == 'c') {
          this.SET_BET_C(!this.btnCSelected);
        } else if (channel == 'd') {
          this.SET_BET_D(!this.btnDSelected);
        } else if (channel == 'e') {
          this.SET_BET_E(!this.btnESelected);
        }
      } else {
        this.$toastr.e("Please input number!")
      }
    },
    selectAll() {
      if (this.betNumber.length >= 2) {
        this.UPDATED_SELECT_ALL_CHANNEL_POST();
      } else {
        this.$toastr.e("Please input number!")
      }
    },
    selectLo() {
      if (this.betNumber.length >= 2) {
        this.UPDATE_SELECTED_LO()
        if (this.btnLoSelected) {
          // this.UPDATED_SELECT_ALL_CHANNEL_POST(this.btnAllSelected)
          this.SET_BET_A(false);
          this.SET_BET_B(false);
          this.SET_BET_C(false);
          this.SET_BET_D(false);
          this.SET_BET_E(false);
        }
      } else {
        this.$toastr.e("Please input number!")
      }
    },
    selectNormal() {
      this.results = [];
      this.numberTemp = this.betNumber;
      this.UPDATE_SELECTED_MULTI(false);
      this.SET_MULTI_BET_NUMBER([this.betNumber]);
      this.UPDATE_SELECTED_NORMAL(true);
    },
    selectMulti() {
      if (this.betNumber.length >= 2) {
        let temp = "";
        for (const perm of this.subsetPermutations(this.betNumber)) {
          this.results.push(perm.join(""));
          temp = temp + " " + perm.join("");
        }
        this.numberTemp = temp;
        this.SET_MULTI_BET_NUMBER(this.results);
        this.UPDATE_SELECTED_NORMAL(false);
        this.UPDATE_SELECTED_MULTI(true);
      } else {
        this.$toastr.e("Please input number!")
      }
    },
    numSelect(num) {
      if (this.betNumber.length < 3) {
        this.betNumber = this.betNumber + num;
        this.numberTemp = this.betNumber;
        if (this.betNumber.length >= 2) {
          this.SET_NORMAL_BET_NUMBER([this.betNumber]);
          this.SET_MULTI_BET_NUMBER([this.betNumber])
        }
      }
    },
    handleClear() {
      this.betNumber = "";
      this.numberTemp = "";
      this.RESET_BETTING()
    },
    subsetPermutations(number) {
      const counts = {};
      const size = number.length;
      const arr = Array.from(number);
      for (const el of arr) {
        counts[el] = (counts[el] ?? 0) + 1;
      }
      const unique = Object.keys(counts);
      const result = Array.from({ length: size });
      function* recurse(depth) {
        if (depth == size) {
          yield result;
        } else {
          for (const el of unique) {
            if (counts[el]) {
              result[depth] = el;
              counts[el]--;
              yield* recurse(depth + 1)
              counts[el]++;
            }
          }
        }
      }
      return recurse(0);
    },
    ...mapMutations("$_lottery_vn", [
      "SET_NORMAL_BET_NUMBER",
      "SET_MULTI_BET_NUMBER",
      "UPDATED_CHANNEL_POST",
      "UPDATE_SELECTED_NORMAL",
      "UPDATED_SELECT_ALL_CHANNEL_POST",
      "UPDATE_SELECTED_MULTI",
      "UPDATE_SELECTED_LO",
      "SET_BET_A",
      "SET_BET_B",
      "SET_BET_C",
      "SET_BET_D",
      "SET_BET_E",
      "RESET_BETTING"
    ]),
  }
}
</script>
