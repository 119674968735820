<template>
    <!-- Result B -->
    <div class="lottery3d-result-column" :class="paddingSize">
        <div class="lottery3d-result-element">
            <div class="lottery3d-result-title rounded-b-0">
                <p>{{ ColumnName }}</p>
            </div>
            <div class="lottery3d-result-ball-container">
                <div class="lottery3d-result-ball" :style="`background:${colorResult}`">
                    <v-layout v-if="result && result[0]">
                        <v-img v-for="(j, index2) in result[0].trim()" :key="index2" :src="ballImgResult" loading="eager"
                            class="each-lottery3d-ball" :class="ass ? 'heartBeat' : ''" contain>
                            <p class="lottery3d-ball-number " :class="j=='?'? 'text-danger':''">{{  j=='' || j==null ? '?' : j }}</p>
                        </v-img>
                    </v-layout>
                    <v-img v-if="result && result[0] && result[0].length < 3" :src="ballImgResult" class="each-lottery3d-ball" contain
                        loading="eager">
                        <p class="lottery3d-ball-number text-danger">?</p>
                    </v-img>
                    <v-img v-if="result && result[0]  && result[0].length < 2" :src="ballImgResult" class="each-lottery3d-ball" contain
                        loading="eager ">
                        <p class="lottery3d-ball-number text-danger" >?</p>
                    </v-img>
                    <v-img v-if="result && result[0]  && result[0].length < 1" :src="ballImgResult" class="each-lottery3d-ball heartBeat" contain
                        loading="eager">
                        <p class="lottery3d-ball-number text-danger">?</p>
                    </v-img>
                </div>
            </div>
        </div>
        <div class="lottery3d-result-lo-element mt-2">
            <div class="lottery3d-result-ball-result-lo-container">
                <div v-for="(k, index3) in result.slice(1)" :key="index3" class="lottery3d-result-ball-result-lo"
                    style="background:#feffff">
                    <v-img v-for="(n, index4) in k.trim()" :key="index4" :src="ballImg(6)" loading="eager"
                        class=" each-lottery3d-ball heartBeat" contain>
                        <p class="lottery3d-ball-number" :class="n=='?'? 'text-danger':''">{{  n }} </p>
                    </v-img>
                    <v-img v-if="k.slice(1).length < 2" :src="ballImg(6)" class="each-lottery3d-ball px-0" contain
                        loading="eager">
                        <p class="lottery3d-ball-number text-danger">?</p>
                    </v-img>
                    <v-img v-if="k.slice(1).length < 1" :src="ballImg(6)" class="each-lottery3d-ball " contain
                        loading="eager">
                        <p class="lottery3d-ball-number text-danger" >?</p>
                    </v-img>
                </div>

                <div v-if="result.length < 5" class="lottery3d-result-ball-result-lo " style="background:#feffff">
                    <v-img v-for="(n, index4) in 3" :key="index4" :src="ballImg(6)" class="each-lottery3d-ball"
                        loading="eager" contain>
                        <p class="lottery3d-ball-number text-danger">?</p>
                    </v-img>
                </div>
                <div v-if="result.length < 4" class="lottery3d-result-ball-result-lo" style="background:#feffff">
                    <v-img v-for="(n, index4) in 3" :key="index4" :src="ballImg(6)" class="each-lottery3d-ball"
                        loading="eager" contain>
                        <p class="lottery3d-ball-number text-danger">?</p>
                    </v-img>
                </div>
                <div v-if="result.length < 3" class="lottery3d-result-ball-result-lo" style="background:#feffff">
                    <v-img v-for="(n, index4) in 3" :key="index4" :src="ballImg(6)" class="each-lottery3d-ball"
                        loading="eager " contain>
                        <p class="lottery3d-ball-number text-danger">?</p>
                    </v-img>
                </div>
                <div v-if="result.length < 2" class="lottery3d-result-ball-result-lo " style="background:#feffff">
                    <v-img v-for="(n, index4) in 3" :key="index4" :src="ballImg(6)" class="each-lottery3d-ball"
                        loading="eager " contain>
                        <p class="lottery3d-ball-number text-danger">?</p>
                    </v-img>
                </div>
            </div>
        </div>
        <div class="bg-primary " style="display:none"> {{ ass }}</div>
    </div>
</template>

<script>


import { mapGetters } from "vuex";

import Mixin from "~mixin";
export default {
    mixins: [Mixin],
    // DEFINE local VARIABLE
    props: [ "passClientRandom", "result", 'ass', 'paddingSize', 'colorResult', 'ballImgResult', 'ColumnName'],
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_lottery3d", {
            getLastOneResult: 'getLastOneResult',
            getLottery3Output: 'getLottery3Output',
            getLottery3Result_A: "getLottery3Result_A",
            getLottery3Result_B: "getLottery3Result_B",
            getLottery3Result_C: "getLottery3Result_C",
            getLottery3Result_D: "getLottery3Result_D",
            getLottery3Result_E: "getLottery3Result_E",
        }),
    },
    methods: {
        ballImg(id) {
            switch (id) {
                case 1: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_yellow.png');
                case 2: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_green.png');
                case 3: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_pink.png');
                case 4: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_red.png');
                case 5: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_sea.png');
                case 6: return require('~public_image/a_each_game/lottery3d/lottery_ball/ball_white.png');
            }
        },

    },
};
</script>
