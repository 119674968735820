<template>
    <div class="w-100">
      <h5 class=" game-machine-component-title display-desc-desktop">{{ passChDesc }} </h5>
      <div class="game-machine-component-result">
        <div class="trends limit-on-tab"  style="position: relative;">

          <div v-if="isShow">
            <div v-if="resultObj.results && resultObj.results.length > 0 && resultObj.results[0]" style="text-align: center; top: 0; bottom: 0; right: 0; left: 0; position:absolute; z-index:4; color: black; align-items: center;"
                :style="resultObj.results[0].name == 'WALA' ? 'background-color:rgba(40, 90, 226, .83)' : resultObj.results[0].name == 'MERON' ? 'background-color:rgba(224, 80, 73, .83)' : resultObj.results[0].name == 'TIE' ? 'background-color:rgba(68, 106, 30, .83)' : 'background-color:rgba(168, 168, 168, .83)'">
              <div class="h-100">
                <table style="width: 100%; border-spacing: 30px; height: 100%; color: #ffffff;">
                  <tr style="height: 38px;">
                    <th style="color: #ffffff;"
                      :style="resultObj.results[0].name == 'WALA' ? 'background-color:rgba(40, 90, 226, .83)' : resultObj.results[0].name == 'MERON' ? 'background-color:rgba(224, 80, 73, .83)' : resultObj.results[0].name == 'TIE' ? 'background-color:rgba(68, 106, 30, .83)' : 'background-color:rgba(168, 168, 168, .83)'">
                      <b>Result</b>
                    </th>
                  </tr>
                  <tr>
                    <td style="font-size: 42px; text-shadow: 1px 1px 2px #000;"><b>{{resultObj.results[0].name}}</b></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
            <div>
            <!-- <div class="trend-legend-custome  gp-panel-bg  d-flex  justify-content-between align-items-center"
                style="background-color: #ffffff !important;">
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle"
                    :class="detectTrendColor(this.channelType, 'meron')">{{ resultObj.summary.MERON }}</span>
                <p class="mb-0 count-label">{{'MERON'}}</p>
                </div>
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle "
                    :class="detectTrendColor(this.channelType, 'wala')">{{ resultObj.summary.WALA }}</span>
                <p class="mb-0 count-label">{{ 'WALA' }}</p>
                </div>
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle "
                    :class="detectTrendColor(this.channelType, 'tie')">{{ resultObj.summary.TIE }}</span>
                <p class="mb-0 count-label">{{ 'draw' }}</p>
                </div>
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle bg-secondary">{{
                resultObj.summary.CANCEL }}</span>
                <p class="mb-0 count-label">{{ $t('title.cancel') }}</p>
                </div>
            </div> -->
            <div class="px-3 pb-4 gp-panel-bg landscape-trend machine"
                style="position: relative; background-color: #ffffff !important; padding: 0 !important;">
                <v-col cols="12" sm="12" style="padding: 0">
                    <v-row style="padding: 0; margin: 0">
                        <v-col
                        cols="6"
                        md="6"
                        sm="6"
                        lg="6"
                        style="padding: 0; margin: 0" class="machine-game">
                        <VideoLive :passurl="passUrl" :channelType="channelType"/>
                        </v-col>
                        <v-col cols="6" md="6" sm="6" lg="6" style="padding: 10px 0 0 0; margin: 0">
                            <div class="table-responsive gp-scroll">
                                <table class="machine-table" >
                                    <tbody>
                                    <tr v-for="row in rows" :key="row">
                                        <td v-for="col in (channel.fix_column + 9)" :key="col">
                                            <div>
                                                <div v-if="channel.column - channel.fix_column <= 0" class="mx-auto w-100">
                                                    <div v-for="(result, i) in resultObj.results" :key="i">
                                                    <span v-if="col == result.cid && row == result.rid">
                                                        <div class="live-result" :class="'' + _resultColor(channelType, result.color)">
                                                        {{ result.name != 'CANCEL' ? result.fight_no : 'x' }}
                                                        </div>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div v-for="(result, i) in resultObj.results" :key="i">
                                                    <span v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid">
                                                        <div class="live-result" :class="'' + _resultColor(channelType, result.color)">
                                                        {{ result.name != 'CANCEL' ? result.fight_no : 'x' }}
                                                        </div>
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </div>
            </div>
        </div>
    </div>
    <!-- <div class="game-machine-component-reserve-space"></div> -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VideoLive from "../GameResult/VideoLive.vue";
import Mixin from "~mixin";
export default {
  components: {
    VideoLive
  },
  props:['updateChInfo', 'passUrl', 'passGame', 'passIndex', 'channelType', 'resultObjS', 'passChDesc', 'showResult', 'totalP','totalB'],
  data() {
    return {
      mixins: [Mixin],
      rows: 6,
      cols: 20,
      fixCol: 30,
      isShow: false,
      fixColBac: 20,
      resultObj: {
        results:[]
      },
      
    }
  },
  watch: {
    resultObjS: function (newVal) {
      if(newVal.objCoResult){
        this.isShow = true;
        // this.countDownTimer();
        this.resultObj.results = newVal.objCoResult
      }
    },
    updateChInfo: function (newVal) {
      if(newVal){
        if(newVal.is_open){
          this.isShow = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters("$_dashboard", {
      channel: "getChannel",
    }),
    getColor(resultName) {
      if (resultName == "BANKER") {
        return "error";
      } else if (resultName == "PLAYER") {
        return "primary";
      } else if (resultName == "TIE") {
        return "success";
      } else {
        return "default";
      }
    },

  },
  async created() {
    //console.log(`getResult >>>>${this.channelType}`)
    this.getResult(this.channelType);
  },
  methods: {
    countDownTimer() {
      this.isShow = true;
      setTimeout(() => {
        this.isShow = false;
      }, 2000)
    },
    async getResult(channelType) {
      // console.log(`getResult1 >>>>${channelType}`)
      try {
        const response = await this.fetchMaBaResult(channelType);
        // console.log(`getResult2 >>>> ${response}`)
        if(response.data.code) {
          this.resultObj = response.data.data
        }
      } catch (error) {
        // this.$toastr.e(`${error}`)
        this.error = true;
        return error;
      }
    },
    ...mapActions("$_cockfight_casino_live", ["fetchMaBaResult"]),
  }
}
</script>
<style>
  /* .v-icon.v-icon{
    font-size: 18px !important
  } */
  div.v-avatar{
    font-size: 8px !important;
  }
</style>