import deposit from '../../../modules/FolderStatement/deposit';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/deposit',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: deposit,
                    // name: 'home'
                }
            ]
    }
];

export default routes;