var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trends limit-on-tab"},[_c('div',[_c('div',{staticClass:"trend-legend gp-panel-bg d-flex justify-content-between align-items-center",staticStyle:{"background-color":"#ffffff !important"}},[_c('div',{staticClass:"bad-cont d-inline-flex align-items-center mr-1"},[_c('span',{staticClass:"count rounded-circle trend-wala"},[_vm._v(_vm._s(_vm.resultObj.summary.TIGER))]),_c('p',{staticClass:"mb-0 count-label"},[_vm._v("Tiger")])]),_c('div',{staticClass:"bad-cont d-inline-flex align-items-center mr-1"},[_c('span',{staticClass:"count rounded-circle trend-meron"},[_vm._v(_vm._s(_vm.resultObj.summary.DRAGON))]),_c('p',{staticClass:"mb-0 count-label"},[_vm._v("Dragon")])]),_c('div',{staticClass:"bad-cont d-inline-flex align-items-center mr-1"},[_c('span',{staticClass:"count rounded-circle trend-draw"},[_vm._v(_vm._s(_vm.resultObj.summary.TIE))]),_c('p',{staticClass:"mb-0 count-label"},[_vm._v("Tie")])])]),_c('div',{staticClass:"px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend",staticStyle:{"position":"relative","background-color":"#ffffff !important"}},[_c('v-col',{staticStyle:{"padding":"5px 0"},attrs:{"cols":"12","sm":"12"}},[_c('v-row',{staticStyle:{"padding":"0","margin":"0"}},[_c('v-col',{staticStyle:{"padding":"0 5px 0 0","margin":"0"},attrs:{"cols":"6","md":"6","sm":"6","lg":"6"}},[_c('div',{staticClass:"table-responsive pb-3 gp-scroll"},[_c('table',{staticClass:"sub-machine-table"},[_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row},_vm._l((_vm.fixColBac),function(col){return _c('td',{key:col},[_c('div',_vm._l((_vm.orderBaccaratResult),function(result,i){return _c('div',{key:i},[(col == result.col && row == result.row)?_c('span',[(
                                result.is_pair_player && result.is_pair_banker
                              )?_c('v-badge',{attrs:{"bordered":"","left":"","color":"red","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"blue","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-avatar',{staticStyle:{"margin":"0px","color":"#ffffff","font-weight":"bold"},attrs:{"color":'a' + result.color,"size":"23"}},[_vm._v(" "+_vm._s(result.name == "DRAGON" ? "D" : result.name == "TIGER" ? "G" : result.name == "TIE" ? "T" : "C")+" ")])],1)],1):(
                                result.is_pair_player &&
                                !result.is_pair_banker
                              )?_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"blue","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-avatar',{staticStyle:{"margin":"0px","color":"#ffffff","font-weight":"bold"},attrs:{"color":'a' + result.color,"size":"23"}},[_vm._v(" "+_vm._s(result.name == "DRAGON" ? "D" : result.name == "TIGER" ? "G" : result.name == "TIE" ? "T" : "C")+" ")])],1):(
                                !result.is_pair_player &&
                                result.is_pair_banker
                              )?_c('v-badge',{attrs:{"bordered":"","left":"","color":"red","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-avatar',{staticStyle:{"margin":"0px","color":"#ffffff","font-weight":"bold"},attrs:{"color":'a' + result.color,"size":"23"}},[_vm._v(" "+_vm._s(result.name == "DRAGON" ? "D" : result.name == "TIGER" ? "G" : result.name == "TIE" ? "T" : "C")+" ")])],1):_c('v-avatar',{staticStyle:{"margin":"0px","color":"#ffffff","font-weight":"bold"},attrs:{"color":'a' + result.color,"size":"23"}},[_vm._v(" "+_vm._s(result.name == "DRAGON" ? "D" : result.name == "TIGER" ? "G" : result.name == "TIE" ? "T" : "C")+" ")])],1):_vm._e()])}),0)])}),0)}),0)])])]),_c('v-col',{staticStyle:{"padding":"0","margin":"0"},attrs:{"cols":"6","md":"6","sm":"6","lg":"6"}},[_c('div',{staticClass:"table-responsive pb-3 gp-scroll"},[_c('table',{staticClass:"sub-machine-table"},[_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row},_vm._l((_vm.fixCol),function(col){return _c('td',{key:col},[_c('div',_vm._l((_vm.orderResult),function(result,i){return _c('div',{key:i},[(col == result.col && row == result.row)?_c('span',[(
                                result.is_pair_player && result.is_pair_banker
                              )?_c('v-badge',{attrs:{"bordered":"","left":"","color":"red","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"blue","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-icon',{class:'c' + result.color,staticStyle:{"padding":"0","margin":"0"}},[_vm._v("mdi-circle-outline")])],1)],1):(
                                result.is_pair_player &&
                                !result.is_pair_banker
                              )?_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"blue","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-icon',{class:'c' + result.color,staticStyle:{"padding":"0","margin":"0"}},[_vm._v("mdi-circle-outline")])],1):(
                                !result.is_pair_player &&
                                result.is_pair_banker
                              )?_c('v-badge',{attrs:{"bordered":"","left":"","color":"red","dot":"","offset-x":"10","offset-y":"10"}},[_c('v-icon',{class:'c' + result.color,staticStyle:{"padding":"0","margin":"0"}},[_vm._v("mdi-circle-outline")])],1):_c('span',[_c('v-icon',{class:'c' + result.color,staticStyle:{"padding":"0","margin":"0"}},[_vm._v("mdi-circle-outline")])],1)],1):_vm._e()])}),0)])}),0)}),0)])])])],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }