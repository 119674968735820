import { mapMutations } from "vuex";
export default {
  methods: {
    _disconnectGlobalSocket() {
      if (this.$globalSocket) {
        this.$globalSocket.disconnect();
        this.$globalSocket.removeAllListeners();
      }
    },
    _globalSocketListener() {
      this._disconnectGlobalSocket();
      this.$connectGlobalSocket();

      this.$globalSocket.on("client-status-channel-type", (data) => {
        if (data) {
          this.UPDATE_CHANNEL_STATUS(data);
        }
      });
      this.$globalSocket.on("client-update-user-info", (data) => {
        if (data) {
          if (data.is_suspend) {
            this.$toastr.e(`Your account have been blooked!`)
            this.onLogout()
          }
          this.updateBalance(data.balance);
        }
      });
      this.$globalSocket.on("client-notification", (data) => {
          if (data) {
            this.getNotification();
          }
        });

    },
    ...mapMutations("$_dashboard", [
      "UPDATE_CHANNEL_STATUS",
    ]),
  }
}