<template>
  <v-container class="pt-0">

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-img lazy-src="images/a_each_game/klaklok/imgv2/klakloktable.jpg" src="images/a_each_game/klaklok/imgv2/klakloktable.jpg">
          <machineChannelInfo :passGameName="gameName" />

          <div class="rx-close-open-box p-0" v-if="getClientTime"
            :class="getClientTime.is_open_betting == true ? 'rx-close-open-box-green' : 'rx-close-open-box-red'">
            <img :src="getClientTime.is_open_betting ? spinningAllow : spinningNotAllow" v-if="getClientTime.time"
              style="width:100%; height:auto; padding :0px" alt="">
            <div v-if="getClientTime.time"
              style="width: 100%; height: 100%; position: absolute; top:0px; display : flex;align-items : center;"><span
                :style="!getClientTime.is_open_betting ? 'color:#e15b64' : ''">{{ getClientTime.time }}</span></div>
            <div v-else
              style="width: 100%; height: 100%; position: absolute; top:0px; display : flex;align-items : center;">
              <span><img :src="spinningClose" style="width:100%; height:auto; padding :0px" alt=""> </span>
            </div>
          </div>

          <div class="rx-kl-box">
            <div class="rx-kl-item" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/imgv2/x-1.jpg' + '\')' }">
              <div class="rx-kl-cion" v-if="getBettingKlakloukAmount.tiger.display == true"
                :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/img/Coin/x-coin-1.png' + '\')' }">
                <span>{{ getBettingKlakloukAmount.tiger.amout }}</span>
              </div>
            </div>
            <div class="rx-kl-item" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/imgv2/x-2.jpg' + '\')' }">
              <div class="rx-kl-cion" v-if="getBettingKlakloukAmount.gourd.display == true"
                :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/img/Coin/x-coin-5.png' + '\')' }">
                <span>{{ getBettingKlakloukAmount.gourd.amout }}</span>
              </div>
            </div>
            <div class="rx-kl-item" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/imgv2/x-3.jpg' + '\')' }">
              <div class="rx-kl-cion" v-if="getBettingKlakloukAmount.chicken.display == true"
                :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/img/Coin/x-coin-10.png' + '\')' }">
                <span>{{ getBettingKlakloukAmount.chicken.amout }}</span>
              </div>
            </div>

            <div class="rx-kl-item" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/imgv2/x-4.jpg' + '\')' }">
              <div class="rx-kl-cion" v-if="getBettingKlakloukAmount.lobster.display == true"
                :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/img/Coin/x-coin-20.png' + '\')' }">
                <span>{{ getBettingKlakloukAmount.lobster.amout }}</span>
              </div>
            </div>
            <div class="rx-kl-item" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/imgv2/x-5.jpg' + '\')' }">
              <div class="rx-kl-cion" v-if="getBettingKlakloukAmount.crab.display == true"
                :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/img/Coin/x-coin-50.png' + '\')' }">
                <span>{{ getBettingKlakloukAmount.crab.amout }}</span>
              </div>
            </div>
            <div class="rx-kl-item" :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/imgv2/x-6.jpg' + '\')' }">
              <div class="rx-kl-cion" v-if="getBettingKlakloukAmount.fish.display == true"
                :style="{ backgroundImage: 'url(\'' + 'images/a_each_game/klaklok/img/Coin/x-coin-100.png' + '\')' }">
                <span>{{ getBettingKlakloukAmount.fish.amout }}</span>
              </div>
            </div>
          </div>
          <!--

          <v-row id="startGame" justify="center"
            style="display:none;position: absolute;left:53%;transform: translate(-50%, -50%);top:10%">
            <v-img max-height="100" max-width="100" lazy-src="images/a_each_game/klaklok/img/plate.png"
              src="images/a_each_game/klaklok/img/plate.png" class="animate__animated animate__bounce animate__repeat-3"></v-img>
          </v-row>

          <v-row id="zoomGame" justify="center"
            style="display:none;position: absolute;left:53%;transform: translate(-50%, -50%);top:50%">
            <v-img max-height="150" max-width="150" lazy-src="images/a_each_game/klaklok/img/plate.png"
              src="images/a_each_game/klaklok/img/plate.png" class="animate__animated animate__zoomInDown animate__repeat-1"></v-img>
          </v-row>

          <v-row id="resultWin" justify="center"
            style="display:none;position: absolute;left:53%;transform: translate(-50%, -50%);top:50%">
            <v-img id="zoomGame" max-height="164" max-width="164" src="images/a_each_game/klaklok/img/On Plate.png"
              style="position: absolute;z-index: 1;left: 9%; top:6%"
              class="animate__animated animate__bounceOutUp  animate__repeat-1  "></v-img>
            <v-img id="zoomGame" class="x-Plate x-zoomGamexxxx" max-height="199" max-width="199"
              src="images/a_each_game/klaklok/img/Under_Plate.png">
              <img id="x_result1_name" style="width: 52px; position: relative;left: 23.5%;top:23%" />
              <img id="x_result2_name" style="width: 52px; position: relative;left: 24.5%;top:23%" />
              <br />
              <img id="x_result3_name" style="width: 52px; position: relative;left: 36.5%;top:3.5%" />

            </v-img>
          </v-row>

          -->

          <span id="klaklouk-result-x-ri">
  
          </span>
          


        </v-img>
      </v-col>
    </v-row>


  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import machineChannelInfo from "~global_components/component_machine_game/machineChannelInfo.vue";
export default {
  components: {
    machineChannelInfo,
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s |  KLAKLOUK`
    },
  data() {
    return {
      gameName: "Klaklouk",
    }
  }, computed: {

    spinningAllow() {
      return require("/public/images/spinning-allow.svg")
    },
    spinningNotAllow() {
      return require("/public/images/spinning-not-allow.svg")
    },
    spinningClose() {
      return require("/public/images/spinning-close.svg")
    },
    ...mapGetters("$_modules", {
      getBettingAmount: "getBettingAmount",
      getBettingKlakloukAmount: "getBettingKlakloukAmount",
    }),
    ...mapGetters("$_dashboard_live", {
      channelObj: "getChannel",
      getClientTime: "getClientTime",
      getCurrentGameResult: "getCurrentGameResult",
      getClientRandom: "getClientRandom",
      amountMin: "getAmountMin",
      amountMax: "getAmountMax",
      getClearStart: "getKlakloukClearStart",
    })
  },
  watch: {
    getCurrentGameResult: function (newVal, oldVal) {


      if (newVal != oldVal && this.getCurrentGameResult != null && this.getClientTime.is_countdown == true) {
        this.__xvxOpen();


        // this.btnZoom();
        //  this.doOpenx();
        //   this.btnOpen();

        //console.log(newVal)
      }
    },
    /*
        getClientRandom: function (newVal) {
          
          if (newVal.is_open == false) {
            this.btnStart()
          }
          else if (newVal.is_open == true) this.btnZoom()
          
         
        },
    */


    getClearStart: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.__xvxStart();
      }
      // this.btnStart();

    }


  },


  methods: {


    doHiddenPlate() {
      document.getElementById('resultWin').style.display = 'none';
    },
    /*
    doReloadx() {
      try {
        if (this.channelObj.result) {

          document.getElementById('x_result1_name').src = this._getImgItemsResult(this.channelObj.result.result_1.name);
          document.getElementById('x_result2_name').src = this._getImgItemsResult(this.channelObj.result.result_2.name);
          document.getElementById('x_result3_name').src = this._getImgItemsResult(this.channelObj.result.result_3.name);

       
        } else {
          document.getElementById('resultWin').style.display = 'none';
        }
      } catch (error) {
        document.getElementById('resultWin').style.display = 'none';
      }
    },
    */
    doOpenx() {

      document.getElementById('x_result1_name').src = this._getImgItemsResult(this.getCurrentGameResult.result1_name);
      document.getElementById('x_result2_name').src = this._getImgItemsResult(this.getCurrentGameResult.result2_name);
      document.getElementById('x_result3_name').src = this._getImgItemsResult(this.getCurrentGameResult.result3_name);

    },
    detectResultImg() {

    },
    btnStart: function () {
      document.documentElement.style.setProperty('--animate-duration', '2s');
      document.getElementById('startGame').style.display = 'block';
      document.getElementById('resultWin').style.display = 'none';
      document.getElementById('zoomGame').style.display = 'none';


    },
    btnZoom: function () {
      //this._xplaySoundBang();
      //document.documentElement.style.setProperty('--animate-duration', '2s');
      // document.getElementById('zoomGame').className = 'animate__animated animate__zoomInDown animate__repeat-1';
      document.getElementById('startGame').style.display = 'none';
      document.getElementById('zoomGame').style.display = 'block';
      document.getElementById('resultWin').style.display = 'none';

    },
    btnOpen: function () {

      document.documentElement.style.setProperty('--animate-duration', '2s');
      // document.getElementById('openPlate').className = 'animate__animated animate__bounceOutUp animate__repeat-1';
      $('#resultWin').find('.animate__animated').addClass('animate__bounceOutUp');
      $('#resultWin').find('.animate__animated').removeClass('animate__bounceInDown');
      document.getElementById('startGame').style.display = 'none';
      document.getElementById('zoomGame').style.display = 'none';
      document.getElementById('resultWin').style.display = 'block';
      setTimeout(() => {
        $('#resultWin').find('.animate__animated').removeClass('animate__bounceOutUp');
        $('#resultWin').find('.animate__animated').addClass('animate__bounceInDown');


      }, 8000)


    },

    __xvxStart() {
      this._xplaySoundBang();
      $('#klaklouk-result-x-ri').html('');
      $('#klaklouk-result-x-ri').html('<div class="klaklou-xri-plate"><div class="klaklou-xri-body"><div class="klaklou-xri-on-plate"></div></div></div>');
      $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').addClass('klaklou-xri-plate-an');
      //console.log("============__xvxStart==========")
    },

    __xvxOpen() {

      //console.log("============__xvxOpen==========")
      $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').addClass('klaklou-xri-plate-an-down');
      $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').removeClass('klaklou-xri-plate-an');

      
      setTimeout(() => {
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').find('.klaklou-xri-body').html('<div class="klaklou-xri-item-1">' +
          '</div><div class="klaklou-xri-item-2">' +
          '</div><div class="klaklou-xri-item-3"></div>' +
          '<div class="klaklou-xri-on-plate"></div>');


        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').find('.klaklou-xri-body').find('.klaklou-xri-item-1').css('background-image', 'url(' + this._getImgItemsResult(this.getCurrentGameResult.result1_name) + ')');
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').find('.klaklou-xri-body').find('.klaklou-xri-item-2').css('background-image', 'url(' + this._getImgItemsResult(this.getCurrentGameResult.result2_name) + ')');
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').find('.klaklou-xri-body').find('.klaklou-xri-item-3').css('background-image', 'url(' + this._getImgItemsResult(this.getCurrentGameResult.result3_name) + ')');
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').find('.klaklou-xri-body').find('.klaklou-xri-on-plate').addClass('klaklou-xri-plate-open-an-up');
      }, 700)

      setTimeout(() => {
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').find('.klaklou-xri-body').find('.klaklou-xri-on-plate').addClass('klaklou-xri-plate-open-an-down');
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').find('.klaklou-xri-body').find('.klaklou-xri-on-plate').removeClass('klaklou-xri-plate-open-an-up');
      }, 8000)

      setTimeout(() => {
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').find('.klaklou-xri-body').html('<div class="klaklou-xri-on-plate"></div>');
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').addClass('klaklou-xri-plate-an-up');
        $('#klaklouk-result-x-ri').find('.klaklou-xri-plate').removeClass('klaklou-xri-plate-an-down');
      }, 8900)


    },
    __close: function () {

    },
    _getImgItemsResult(x) {
      switch (x) {
        case "ឃ្លោក":
          return require("/public/images/a_each_game/klaklok/imgv2/items/ឃ្លោក.png");

        case "មាន់":
          return require("/public/images/a_each_game/klaklok/imgv2/items/មាន់.png");

        case "បង្កង":
          return require("/public/images/a_each_game/klaklok/imgv2/items/បង្កង.png");

        case "ក្ដាម":
          return require("/public/images/a_each_game/klaklok/imgv2/items/ក្ដាម.png");

        case "ខ្លា":
          return require("/public/images/a_each_game/klaklok/imgv2/items/ខ្លា.png");
        case "ត្រី":
          return require("/public/images/a_each_game/klaklok/imgv2/items/ត្រី.png");
        case "CANCEL":
          return "images/a_each_game/klaklok/0010.png";
        default:
          return "";
        // code block
      }
    },

    _xplaySoundBang() {
      let audio = new Audio("/sound/dice-shake-.mp3");
      let promise = audio.play();
      //audio.loop=true;
      if (promise) {
        promise.catch(function () { });
      }
    }


  },
};
</script>

<style>
/*new update*/
.klaklou-xri-plate {
  position: absolute;
  width: 7vw;
  height: 5vw;
  top: 6%;
  left: 45%;
  background-image: url('~@/assets/images/klaklok/img/Under_Plate.png');
  background-size: 100% 100%;
}

.klaklou-xri-body {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.klaklou-xri-on-plate {
  position: absolute;
  width: 88%;
  height: 88%;
  left: 5%;
  top: 0;
 background-image: url('~@/assets/images/klaklok/img/OnPlate.png');
  background-size: 100% 100%;
}

.klaklou-xri-plate-an {
  animation-name: klupdown;
  animation-duration: 0.7s;
  animation-iteration-count: forwards;
  /*animation: klupdown 0.7s forwards;*/
}

@keyframes klupdown {
  0% {
    top: 0.1%;
  }

  20% {
    top: 9%;
  }

  40% {
    top: 0.1%;
  }

  60% {
    top: 9%;
  }

  80% {
    top: 0.1%;
  }

  100% {
    top: 6%;
    display: none;
  }
}

.klaklou-xri-plate-an-down {
  animation: kldown 0.6s forwards;
}

@keyframes kldown {
  0% {
    top: 6%;
    left: 45%;
    width: 7vw;
    height: 5vw;
  }

  50% {
    top: 35%;
    left: 45%;
    width: 7vw;
    height: 5vw;
  }

  100% {
    top: 35%;
    left: 38%;
    width: 15.5vw;
    height: 9vw;
  }

}

.klaklou-xri-plate-an-up {
  animation: klup 0.6s forwards;
}

@keyframes klup {
  0% {
    top: 35%;
    left: 38%;
    width: 15.5vw;
    height: 9vw;
  }

  50% {
    top: 35%;
    left: 45%;
    width: 7vw;
    height: 5vw;
  }

  100% {
    top: 6%;
    left: 45%;
    width: 7vw;
    height: 5vw;
  }

}

.klaklou-xri-plate-open-an-up {
  animation: klopenup 1s forwards;
}

@keyframes klopenup {
  0% {
    top: 0;
    opacity: 100%;
  }

  50% {
    opacity: 100%;
  }

  100% {
    top: -100%;
    opacity: 0%;
  }
}

.klaklou-xri-plate-open-an-down {
  animation: klopendown 1s forwards;
}

@keyframes klopendown {
  0% {
    top: -100%;
    opacity: 0%;
  }

  50% {
    opacity: 100%;
  }

  100% {
    top: 0;
    opacity: 100%;
  }

}

.klaklou-xri-item-1 {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background-size: 100% 100%;
  left: 5.5vw;
  top: 1.5vw;
 
}

.klaklou-xri-item-2 {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background-size: 100% 100%;
  left: 4vw;
  top: 4vw;
 
}

.klaklou-xri-item-3 {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background-size: 100% 100%;
  left: 7.7vw;
  top: 3.5vw;
  
}

/*end new update*/
.rx-kl-box {
  position: absolute;
  top: 16%;
  left: 22%;
  width: 60%;
  height: 65%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0px;
}

.rx-kl-item {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: relative;

}

.rx-kl-item:nth-child(1) {
  border-top-left-radius: 10px;
}

.rx-kl-item:nth-child(3) {
  border-top-right-radius: 10px;
}

.rx-kl-item:nth-child(4) {
  border-bottom-left-radius: 10px;
}

.rx-kl-item:nth-child(6) {
  border-bottom-right-radius: 10px;
}

.rx-kl-cion {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 20%;
  height: 20%;
  background-size: 100% 100%;
  text-align: center;

}

/*
.rx-kl-cion img{
  width: 100%;
  height: auto;

}
*/
.rx-kl-cion span {
  font-weight: 900;
  display: block;
  padding-top: 10%;

}

.rx-kl-min-max.rx-kl-min-max {
  position: absolute;
  top: 1%;
  left: 0.80%;
  width: 17%;
  height: 19.9%;
  background-size: 100% 100%;
}

.rx-kl-min-max .rx-kl-min-max-box {
  position: relative;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
}

.rx-kl-min-max .rx-kl-game {
  font-weight: 900;
  color: white;
  text-shadow: 0px .7px rgb(22, 5, 5);
  position: absolute;
  left: 80%;
  top: 41%;
}

.rx-kl-min-max .rx-kl-game p {
  font-size: 0.8vw;
}

.rx-kl-min-max .rx-kl-min {
  position: absolute;
  top: 41%;
  left: 35%;
  font-weight: 900;
  color: goldenrod;
  text-shadow: 0px .5px rgb(22, 5, 5);
}

.rx-kl-min-max .rx-kl-max {
  position: absolute;
  top: 72%;
  left: 35%;
  font-weight: 900;
  color: goldenrod;
  text-shadow: 0px .5px rgb(22, 5, 5);

}

.rx-kl-min-max .rx-kl-min p {
  font-size: 0.8vw;
}

.rx-kl-min-max .rx-kl-max p {
  font-size: 0.8vw;
}

.rx-close-open-box {
  position: absolute;
  right: 2%;
  top: 1%;
  width: 11%;
  height: 19%;
  text-align: center;
  border-radius: 100%;

}

.rx-close-open-box span {
  font-weight: 900;
  font-size: 2.5vw;
  color: white;
  width: 100%;
  text-align: center;
}

.rx-close-open-box-green {
  background-color: rgba(8, 187, 8, 0.401);
}

.rx-close-open-box-red {
  background-color: rgba(255, 0, 0, 0.30);
}

@media screen and (max-width: 1904px) {
  .rx-kl-cion {
    top: 40%
  }
}

@media screen and (max-width:1280px) {
  .rx-kl-cion {
    top: 40%
  }
}

@media screen and (max-width:982px) {
  .rx-kl-cion {
    top: 40%
  }
}

@media screen and (max-width:960px) {
  .rx-kl-cion {
    top: 40%
  }
}

@media screen and (max-width:959px) {
  .rx-kl-cion {
    top: 40%
  }
}

@media screen and (max-width:768px) {
  .rx-kl-cion {
    top: 30%
  }

  .rx-kl-cion span {
    font-size: .40rem;
    padding-top: 6%;
  }


  .klaklou-xri-plate {
    position: absolute;
    width: 8vw;
    height: 5vw;
    top: 6%;
    left: 43%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

  }

  @keyframes klup {
    0% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 6%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 8.5vw;
    height: 8.5vw;
    left: 13vw;
    top: 1.7vw;
  }

  .klaklou-xri-item-2 {
    width: 8.5vw;
    height: 8.5vw;
    left: 18vw;
    top: 5vw;
  }

  .klaklou-xri-item-3 {
    width: 8.5vw;
    height: 8.5vw;
    left: 7.9vw;
    top: 5vw;
  }


}

@media screen and (max-width:700px) {
  .rx-kl-cion {
    top: 26%;
    width: 27%;
    height: 27%;
  }

  .klaklou-xri-plate {
    position: absolute;
    width: 8vw;
    height: 5vw;
    top: 6%;
    left: 43%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

  }

  @keyframes klup {
    0% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 6%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 8.5vw;
    height: 8.5vw;
    left: 13vw;
    top: 1.7vw;
  }

  .klaklou-xri-item-2 {
    width: 8.5vw;
    height: 8.5vw;
    left: 18vw;
    top: 5vw;
  }

  .klaklou-xri-item-3 {
    width: 8.5vw;
    height: 8.5vw;
    left: 7.9vw;
    top: 5vw;
  }

}

@media screen and (max-width:600px) {
  .rx-kl-cion {
    top: 30%;
    width: 27%;
    height: 27%;
  }

  .klaklou-xri-plate {
    position: absolute;
    width: 8vw;
    height: 5vw;
    top: 6%;
    left: 43%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

  }

  @keyframes klup {
    0% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 6%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 8.5vw;
    height: 8.5vw;
    left: 13vw;
    top: 1.7vw;
  }

  .klaklou-xri-item-2 {
    width: 8.5vw;
    height: 8.5vw;
    left: 18vw;
    top: 5vw;
  }

  .klaklou-xri-item-3 {
    width: 8.5vw;
    height: 8.5vw;
    left: 7.9vw;
    top: 5vw;
  }

}

@media screen and (max-width:529px) {
  .rx-kl-cion {
    top: 36%;
    width: 25%;
    height: 25%;
  }

  .rx-kl-cion span {
    font-size: .60rem;
    padding-top: 6%;
  }

  .rx-kl-max p {
    font-size: 1.1vw;
  }

  .rx-kl-min p {
    font-size: 1.1vw;
  }

  .rx-close-open-box span {
    font-size: 1.4rem;
  }

  .rx-kl-game p {
    font-size: 2.3vw;
  }

  .klaklou-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 45%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .klaklou-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .klaklou-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width:450px) {
  .rx-kl-cion {
    top: 32%;
    width: 27%;
    height: 27%;
  }

  .rx-kl-max p {
    font-size: 1.1vw;
  }

  .rx-kl-min p {
    font-size: 1.1vw;
  }

  .klaklou-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 45%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .klaklou-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .klaklou-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }



}

@media screen and (max-width:428px) {
  .rx-kl-cion {
    top: 32%;
    width: 26%;
    height: 26%;
  }

  .rx-kl-cion span {
    font-size: .56rem;
    padding-top: 5%;
  }

  .klaklou-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 45%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .klaklou-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .klaklou-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width:414px) {

  .rx-kl-cion {
    top: 32%;
    width: 26%;
    height: 26%;
  }

  .klaklou-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 45%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .klaklou-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .klaklou-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width: 390px) {
  .rx-kl-cion {
    top: 32%;
    width: 28%;
    height: 28%;
  }

  .rx-kl-cion span {
    font-size: .50rem;
    padding-top: 6%;
  }

  .klaklou-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 45%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .klaklou-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .klaklou-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width: 384px) {
  .rx-kl-cion {
    top: 32%;
    width: 26%;
    height: 26%;
  }

  .rx-kl-cion span {
    font-size: .48rem;
    padding-top: 6%;
  }


  .klaklou-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 45%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .klaklou-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .klaklou-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width: 375px) {
  .rx-kl-cion {
    top: 32%;
    width: 25%;
    height: 25%;
  }

  .klaklou-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 45%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .klaklou-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .klaklou-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .klaklou-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }
}

.custom-repeat {
  --animate-repeat: 5;
}
</style>

