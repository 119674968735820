export default {
    permission: 'ការអនុញ្ញាត្ត',
    role: 'តួនាទី',
    description: 'ពិពណ៌នា',
    menu_name: 'ឈ្មោះមីនុយ',
    icon: 'រូបតំណាង',
    order: 'លំដាប់',
    active: 'បើក/បិទ',
    actions: 'សកម្ម',
    balance: 'តុល្យភាព',
    created_at: 'ថ្ងៃបង្កើត',
    bank_account: 'ឈ្មោះធនាគារ',
    user_name: 'អ្នកប្រើប្រាស់',
    deposit_withdraw: 'ដក់/ដាក់ប្រាក់',
    no :"ល.រ",
    game:"ហ្គាម",
    date:"លុប",
    result:"លទ្ធផល",
    detail:"លំអិត",
    headtail:"ធំ / តូច",
    range:"Range",
    id:"Id",
    account:"គណនី",
    bet:"ទឹកប្រាក់ភ្នាល់",
    type:"ប្រភេទ",
    payout:"payout",
    winLose:"ឈ្នះ/ចាញ់",
    oldBalance:"តុល្យភាពចាស់",
    newBalance:"តុល្យភាពថ្មី",
    amountw: "ចំនួនទឹកប្រាក់",
    small_large: "តូច / ធំ",
    invoice_no: "លេខវិក្កយបត្រ",
    turn_over: "បង្វិល",
    total_cast:"សរុប Cash",
    bettingType:'ប្រភេទនៃការភ្នាល់',
    post:'ប៉ុស្តិ៍',
    game_no:'ហ្គាម ID',
    group:'ក្រុម',
    action:'សកម្មភាព',
    old_balance:'តុល្យភាពចាស់',
    new_balance:'តុល្យភាពថ្មី',
    dateTime:'កាលបរិច្ឆេទ',
    Amount:'ចំនួនទឹកប្រាក់',
    Balance:'តុល្យភាព',
    Old_Balance:"តុល្យភាពចាស់",
    New_Balance:'តុល្យភាពថ្មី',
    
    all_statement: 'របាយការណ៍សរុប',
    total_win_lose: 'សរុបឈ្នះ/ចាញ់',
    start_date: 'ថ្ងៃចាប់ផ្តើម',
    end_date: 'ថ្ងៃបញ្ចប់',
    fight_no: 'ល.រ',
    channel: 'ប៉ុស្តិ៍',
    group_no: 'លេខក្រុម',
    date_time: 'កាលបរិច្ឆេទ',
    cast: 'Cash',
    amount_win: 'ចំនួនទឹកប្រាក់ឈ្នះ',


    see_detail:"លំអិត",
    invoice_id:"លេខវិក្កយបត្រ",
    
};
