import Vue from 'vue'
import App from './App.vue'
import router from './services/router'
import Request from './services/axios.js';
import VueCookies from 'vue-cookies';
import VueHtmlToPaper from 'vue-html-to-paper';
import store from './services/store.js'
import vuetify from '@/plugins/vuetify'
import mixin from './mixin';
import VueToastr from 'vue-toastr';
import i18n from './services/i18n';
import {io} from "socket.io-client";
import VueCountryCode from "vue-country-code-select";
import AnimateCSS from 'animate.css';
import VueMeta from 'vue-meta'
// import $ from "jquery";

import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

const options = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: ['css/print.css']
};


// set global component
// Vue.use($)
Vue.use(VueMeta);
Vue.use(AnimateCSS);
Vue.use(router);
Vue.mixin(mixin);
Vue.use(VueCookies);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueCountryCode);
Vue.use(VueToastr, {defaultTimeout: 500});

Vue.config.productionTip = false
Vue.prototype.$request = new Request();

function _detectUrl(){
        let pathName = window.location.pathname
        switch(pathName){
            default:
                return false;
        }
    }
function _detectSocketNameSpace(channelType){

        switch(channelType){
            case "1" : return "cock-ch1"
            case "2" : return "cock-ch2"
            case "3" : return "cock-ch3"
            case "4" : return "cock-ch4"
            case "5" : return "klaklouk-ch5"
            case "6" : return "baccarat-ch6"
            case "7" : return "baccarat-ch7"
            case "8" : return "dragon-ch8"
            case "9" : return "dragon-ch9"
            case "10" : return "lotto-ch10"
            case "11" : return "yuki-ch11"
            case "12" : return "abong-ch12"
            case "13" : return "fantan-ch13"
            case "14" : return "taisiev-ch14"
            case "15" : return "cock-ch15"
            case "16" : return "cock-ch16"
            case "18" : return "baccarat-ch18"
            case "19" : return "dragon-ch19"
            case "20" : return "han2-ch20"
            case "21" : return "han3-ch21"
            case "79" : return "slot-lotvn"
            case "791" : return "slot-lotvn2"
            case "99" : return "slot-keno"
            case "991" : return "slot-keno2"
            case "992" : return "slot-keno3"
            case "89" : return "slot-lot3"
            case "891" : return "slot-lot32"
            case "892" : return "slot-lot33"
                     //Lotto 30s
            case "902" : return "slot-lotto3"
                     // Lotto 100s
            case "901" : return "slot-lotto2"
                     // Lotto 160s
            case "90" : return "slot-lotto"
                     //Yuki 30s
                     case "912" : return "slot-yuki3"
                     //Yuki 100s
                     case "911" : return "slot-yuki2"
                     //Yuki 160s
            case "91" : return "slot-yuki"
            case "921" : return "slot-pp782"
            case "922" : return "slot-pp783"
            case "92" : return "slot-pp78"
            case "93":
                return 'slot-klaklouk'
            case  "931":
                return 'slot-klaklouk2'
            case  "932":
                return 'slot-klaklouk3'
            case  "933":
                return 'slot-klaklouk4'
            case  "934":
                return 'slot-klaklouk5'
            case  "935":
                return 'slot-klaklouk6'
            case  "936":
                return 'slot-klaklouk7'
            case  "937":
                return 'slot-klaklouk8'
            case  "938":
                return 'slot-klaklouk9'
            case  "939":
                return 'slot-klaklouk10'
            case  "94":
                return 'slot-dragon'
            case  "941":
                return 'slot-dragon2'
            case  "942":
                return 'slot-dragon3'
            case  "943":
                return 'slot-dragon4'
            case  "944":
                return 'slot-dragon5'
            case  "945":
                return 'slot-dragon6'
            case  "946":
                return 'slot-dragon7'
            case  "947":
                return 'slot-dragon8'
            case  "948":
                return 'slot-dragon9'
            case  "949":
                return 'slot-dragon10'
            case  "95":
                return 'slot-baccarat'
            case  "951":
                return 'slot-baccarat2'
            case  "952":
                return 'slot-baccarat3'
            case  "953":
                return 'slot-baccarat4'
            case  "954":
                return 'slot-baccarat5'
            case  "955":
                return 'slot-baccarat6'
            case  "956":
                return 'slot-baccarat7'
            case  "957":
                return 'slot-baccarat8'
            case  "958":
                return 'slot-baccarat9'
            case  "959":
                return 'slot-baccarat10'
            case  "96":
                return 'slot-doden'
            case  "961":
                return 'slot-doden2'
            case  "962":
                return 'slot-doden3'
            case  "963":
                return 'slot-doden4'
            case  "964":
                return 'slot-doden5'
            case  "965":
                return 'slot-doden6'
            case  "966":
                return 'slot-doden7'
            case  "967":
                return 'slot-doden8'
            case  "968":
                return 'slot-doden9'
            case  "969":
                return 'slot-doden10'
            case  "97":
                return 'slot-sicbo'
            case  "971":
                return 'slot-sicbo2'
            case  "972":
                return 'slot-sicbo3'
            case  "973":
                return 'slot-sicbo4'
            case  "974":
                return 'slot-sicbo5'
            case  "975":
                return 'slot-sicbo6'
            case  "976":
                return 'slot-sicbo7'
            case  "977":
                return 'slot-sicbo8'
            case  "978":
                return 'slot-sicbo9'
            case  "979":
                return 'slot-sicbo10'

            case  "98":
                return 'slot-taixiu'
            case  "981":
                return 'slot-taixiu2'
            case  "982":
                return 'slot-taixiu3'
            case  "983":
                return 'slot-taixiu4'
            case  "984":
                return 'slot-taixiu5'
            case  "985":
                return 'slot-taixiu6'
            case  "986":
                return 'slot-taixiu7'
            case  "987":
                return 'slot-taixiu8'
            case  "988":
                return 'slot-taixiu9'
            case  "989":
                return 'slot-taixiu10'
            default:
                return false;
        }
    } 
    function _powerSocketGlobal(token){
        const globalSocket = io(`${process.env.VUE_APP_WEBSOCKET_URL}nsp-sbc`, {
            extraHeaders: {
                "Content-Type": "application/json",
                "Authorization": token
            }
        });
        globalSocket.on('client-update-channel-type', () => {
            //
          });
          globalSocket.on('client-status-channel-type', () => {
            //
          });
          globalSocket.on('client-update-user-info', () => {
            //
          });
          globalSocket.on("connect", () => {
            // console.log('globalSocket is ready')
        });
        globalSocket.on("connect_error", (err) => {
            console.log('socket: Message err == ', err);
        });
        globalSocket.on('disconnect', () => {
            console.log('global socket cut out');
        });
        globalSocket.on('error', (err) => {
            console.log('socket: Error connecting to server', err);
        });
        globalSocket.on('reconnect', (number) => {
            console.log('socket: Reconnected to server', number);
        });
        globalSocket.on('reconnect_attempt', () => {
            console.log('socket: Reconnect Attempt', 'alert problem');
        });
        globalSocket.on('reconnecting', (number) => {
            console.log('socket: Reconnecting to server', number);
        });
        globalSocket.on('reconnect_error', (err) => {
            console.log('socket: Reconnect Error', err);
        });
        globalSocket.on('reconnect_failed', () => {
            console.log('socket: Reconnect failed', 'offline');
        });
        Vue.prototype.$globalSocket = globalSocket
    }

function _powerSocket(nameSpace, token, channelType){
    const socket = io(`${process.env.VUE_APP_WEBSOCKET_URL+nameSpace}`, {
        extraHeaders: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    });
    socket.on("connect", () => {
        console.log('socket: Connectd'+channelType)
    });
    socket.on("connect_error", (err) => {
        console.log('socket: Message err == ', err);
    });
    socket.on('disconnect', () => {
        console.log('socket: Disconnection'+channelType);
    });
    socket.on('error', (err) => {
        console.log('socket: Error connecting to server', err);
    });
    socket.on('reconnect', (number) => {
        console.log('socket: Reconnected to server', number);
    });
    socket.on('reconnect_attempt', () => {
        console.log('socket: Reconnect Attempt', 'alert problem');
    });
    socket.on('reconnecting', (number) => {
        console.log('socket: Reconnecting to server', number);
    });
    socket.on('reconnect_error', (err) => {
        console.log('socket: Reconnect Error', err);
    });
    socket.on('reconnect_failed', () => {
        console.log('socket: Reconnect failed', 'offline');
    });
    Vue.prototype.$socket = socket
}
//set global socket

Vue.prototype.$connectSocket = (token = Vue.$cookies.get('sbc_token'), channelType = Vue.$cookies.get('sbc_channel_type') ? Vue.$cookies.get('sbc_channel_type') : 1) => {
    var slotChannelType = _detectUrl();
    if (slotChannelType !== false) channelType = slotChannelType;
    if (token) {
        const nameSpace = _detectSocketNameSpace(channelType);
        _powerSocket(nameSpace, token, channelType)
    }
};

Vue.prototype.$connectGlobalSocket = (token = Vue.$cookies.get('sbc_token')) => {
    if (token) {
        _powerSocketGlobal(token);
    }
};

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
