import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    channel: {
        enable: null,
        is_open: null,
        live_urls: [],
        _id: null,
        info: null,
        video_info: null,
        date: null,
        fight_no: 0,
        group_no: null,
        column: 20,
        fix_column: 20,
        is_maintain: null,
        maintain_desc: null,
        amount_min:0,
        amount_max:0,
    },
    isReport: true,
    channelStatus: [],
    numberMaster: [],
    resultData: {
        results: [],
        summary:{
            one: 0,
            two: 0,
            three: 0,
            four: 0,
            five: 0,
            six: 0,
            CANCEL: 0,
            DRAGON: 0,
            TIE: 0,
            TIGER: 0,
            MERON: 0,
            WALA: 0,
            BLUE: 0,
            EVEN: 0,
            ODD: 0,
            RED: 0,
            YEAR1: 0,
            YEAR2: 0,
            YEAR3: 0,
            YEAR4: 0,
            YEAR5: 0,
            YEAR6: 0,
            YEAR7: 0,
            YEAR8: 0,
            YEAR9: 0,
            YEAR10: 0,
            YEAR11: 0,
            YEAR12: 0,
            LARGE: 0,
            RANGE1: 0,
            RANGE2: 0,
            RANGE3: 0,
            RANGE4: 0,
            RANGE5: 0,
            SMALL: 0,
            B: 0,
            P: 0,
            PB: 0,
            PP: 0,
            T: 0,
        },
        currentBetting: {}
    },
    todayBetting: [],
    currentBetting: {
        list: [],
        meron: 0,
        tie: 0,
        wala:0
    },
    createBetting: null,
    bettingTypeMaster: [],
    weight: {
        wala: {
            payout: 0,
            amount: 0
        },
        meron: {
            payout: 0,
            amount: 0
        },
        amount_min: 0,
        amount_max: 0
    },
    countDown: {
        countDown: 0,
        type: "",
        isPlay: false,
        isCountDown: false
    },
    button: [],
    vdolinks: [],
    dialog_betting:false,
    loadingReport:false,
    //Tiger Dragon
    tigerCard :"0052.png",
    dragonCard :"0052.png",
    // Baccarat
    playerCard1:'0052.png',
    playerCard2:'0052.png',
    playerCard3:'0052.png',
    bankerCard1:'0052.png',
    bankerCard2:'0052.png',
    bankerCard3:'0052.png',
    totalP:0,
    totalB:0,
    listLotto:{},
    totalLotto:0,
    listKlaklouk:[],
    listTaiSiv:[],
    listAbong:[],
    listFantan:[],
    listYuki:{},
    amount_min:0,
    amount_max:0,
    isSelectedCoin :false,
    loading_betting : false,
    errorMessage :'',
    cockSeletedBtn:{
        selectedButton : {},
        selectedBtnClassMeron : false,
        selectedBtnClassTie : false,
        electedBtnClassWala : false,    
    },
    machine_resulte:{},
    machine_statement_configured_data:{   
        rows_per_page: 10,
        page: 1,
    },
    machine_statement:{},

    //FOR BUTTON BETTING
    selectedBaccaratButton_InAll:{},
    selectedTigerButton_InAll:{},
    selectedTaixiuButton_InAll:{},
    selectedDodenButton_InAll:{},
    selectedSicboButton_InAll:{},
    selectedKlakloukButton_InAll:{},
    selectedLottoButton_InAll:{},
    selectedHanButton_InAll:{},
    selectedTaisevButton_InAll:{},
    selectedYukiButton_InAll:{},
    machineButton:{}
    
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
