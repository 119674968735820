<template>
  <v-container class="bg-surface-variant pa-0">
    <div class="pcoded-main-container">
      <div class="main-body">
        <div class="page-wrapper" style="overflow: hidden">
          <div class="page-body" style="overflow: hidden">
            <div class="lottery-menu-nav-head">
              <MainMenu />
            </div>
            <LotteryMenuTab />
            <v-row no-gutters class="lotto-border" style="overflow: hidden">
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                xxl="3"
                class="lotto-border my-order-left"
                style="overflow: auto !important"
              >
                <LeftSection />
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                xxl="6"
                class="lotto-border my-order-middle"
                style="overflow: hidden; overflow-y: auto"
              >
                <MiddleSection
                  :passRandomNumber="randomNumber"
                  :passClientRandom="clientRandom"
                  :result_a="result_a" :ass_a="ass_a"
              
                />
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                xxl="3"
                class="lotto-border my-order-right"
                style="background-color: #0437b4; overflow: hidden !important"
              >
                <RightSection
                  :passRandomNumber="randomNumber"
                  :passClientRandom="clientRandom"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
// IMPORT STYLE
import "@/assets/css/lottery_menu/lottery2d/screen_max_480.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_481.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_768.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_960.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_993.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_1025.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_1281.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_1441.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_1661.css";
import "@/assets/css/lottery_menu/lottery2d/screen_min_1824.css";

import LeftSection from "./components/leftSection.vue";
import MiddleSection from "./components/MiddleSection.vue";
import RightSection from "./components/RightSection.vue";
import MainMenu from "../../../../components/MainMenu.vue";
import LotteryMenuTab from "../../../../components/component_lottery_menu/lottery_menu_tab.vue";
import MX_Socket_LottoMenu_Lottery from "@/mixin/SocketGame/LottoMenu/MX_Socket_Lottery.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  mixins: [ MX_Socket_LottoMenu_Lottery],
  components: {
    LeftSection,
    MiddleSection,
    RightSection,
    MainMenu,
    LotteryMenuTab
  },
  data() {
    return {
      orderResult: {},
      orderResultUnderOver: {},
      clientRandom: [],
      randomNumber: 0,
      result_a: [],
      outData: 0,
      ass_a:null
    };
  },
  watch: {
    channelId: async function (newVal, oldVal) {
      if (newVal != oldVal && oldVal != 1) {
        this._connectSocketLottery();
        this.result_a = ['??', '??', '??', "??", '??'];
        this.ass_a = null;
        this.outData = 0;
        this.CLEAR_TO_DEFAULT();
        await this._initialData();
      }
    },
  },
  computed: {
    ...mapGetters("$_modules", {
      channelId: "channelId",
      isSound: "getIsSound",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  created() {
    this.result_a = ['??', '??', '??', "??", '??'];
    this.ass_a = null;
    this.outData = 0;
    this.CLEAR_TO_DEFAULT();
    this._connectSocketLottery();
    this._initialData();
  },
  methods: {
    async _initialData() {
      await this.fetchLastResults();
      await this.fetchCurrentBetting();
      await this.fetchCurrentGame();
      await this.fetchPreviousBetting();
      await this.fetchBettingType();
      await this.fetchButton();
      // await this.fetchLimit();
    },

    fromFinalToResult(result) {
      let _ts = this;
      if(result && result.length > 0){
        for (let i = 0; i < result.length; i++) {
        (function initPerCharAnimation(c) {
          setTimeout(() => {
            shuffleResult(c)
          }, 3000 * i)
        }(result.substring(0, i + 1).split('')))
      }
      }
      
      function shuffleResult(final) {
        const original = final.concat()
        let i = 0
        for (; i < 9; i++) {
          setTimeout(() => {
            // TODO use random chars instead of numbers when final.at(-1) is a char
            final[final.length - 1] = Math.random() * 10 | 0
            _ts.output(final.join(''));
          }, 140 * i);
        }

        setTimeout(() => {
          _ts.output(original.join(''));
          _ts._playSoundBang();
        }, 140 * i)
      }
    },
    output(data) {
        this.ass_a = data;
        this.result_a[this.result_a.length > 1 ? this.result_a.length - 1 : 0] = data;
        // let sentData = {
        //   data : this.result_a,
        // }
        // this.passResultRandom(sentData);
    },
    ...mapMutations("$_machine_lottery", [
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
      "CLEAR_TO_DEFAULT",
      "UPDATE_CLIENT_TIME",
    ]),
    ...mapActions("$_machine_lottery", [
      "fetchLastResults",
      "fetchCurrentBetting",
      "fetchPreviousBetting",
      "fetchCurrentGame",
      "SocketInAction",
      "fetchButton",
      "fetchBettingType",
    ]),
    ...mapActions("$_modules", ["fetchUserInfo"]),
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s |  LOTTERY2D`
    },
  async beforeRouteLeave(to, from, next) {
    this._disconnectSocketLottery();
    next();
  },
};
</script>