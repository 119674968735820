<template>
  <div class="plyr-casino-row">
    <v-row class="m-0">
      <div class="plyr-casino-col-1">
        <div class="video-section">
          <TabChannelGame :passSelectedChannel="passSelectedChannel" :passChannelStatus="passChannelStatus"
            :passStartIndex="0" :passEndIndex="6" :channel="passChannel" v-on:sentSetChannel="_switchChannel" />
          <VideoLiveForDesktop :channel="passChannel" :passSelectedChannel="passSelectedChannel" />
          <ResultVideoDistribution :passSelectedChannel="passSelectedChannel" :passChannel="passChannel" />
          <TabChannelGame v-if="passEnabledChannel > 7" :passSelectedChannel="passSelectedChannel"
            :passChannelStatus="passChannelStatus" :passStartIndex="7" :passEndIndex="14"
            v-on:sentSetChannel="_switchChannel" />
          <TabChannelGame v-if="passEnabledChannel > 14" :passSelectedChannel="passSelectedChannel"
            :passChannelStatus="passChannelStatus" :passStartIndex="15" :passEndIndex="22"
            v-on:sentSetChannel="_switchChannel" />
        </div>
        <TableResult class="casino-live-hide-on-mobile" :passSelectedChannel="passSelectedChannel" />
      </div>
      <div class="plyr-casino-col-2">
        <div class="casino-live-define casino_live-container">
          <ChannelInfo :passChannel="passChannel" />
          <BettingComponent :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
            :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster" :passWeight="passWeight"
            v-on:sentSubmitBetting="receiveSubmitBetting" :passSelectedChannel="passSelectedChannel"
            :passUserCurrency="passUserCurrency" />
          <input-amount-money />
          <CoinButton :passGetButton="passGetButton" />
          <SubmitBtn v-on:handleSelectButton="_submitBetting()" />
          <LimitBetting />
          <ReportBetting :passSelectedChannel="passSelectedChannel" :passLoadingTodayReport="passLoadingTodayReport" />
          <TableResult class="casino-live-hide-on-desktop my-2" :rows="6" :cols="30" :passSelectedChannel="passSelectedChannel" />
        </div>
      

      </div>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VideoLiveForDesktop from "../videoLiveForDesktop.vue";
import TableResult from "../TableResults.vue";
import BettingComponent from "./BettingComponent.vue";
import ReportBetting from "../ReportBetting.vue";
import ResultVideoDistribution from "./ResultVideoDistribution.vue";
import TabChannelGame from "./TabChannelGame.vue";
import InputAmountMoney from '~global_components/component_casino_live/SharedCasinoLiveComponents/InputAmountMoneyLive.vue';
import CoinButton from "~global_components/component_casino_live/SharedCasinoLiveComponents/CoinBettingButton.vue";
import ChannelInfo from "~global_components/component_casino_live/SharedCasinoLiveComponents/ChannelInfoLive.vue";
import LimitBetting from "~global_components/component_casino_live/SharedCasinoLiveComponents/LimitBettingLive.vue";
import SubmitBtn from "~global_components/component_casino_live/SharedCasinoLiveComponents/SubmitBtn.vue";
export default {
  components: {
    TabChannelGame,
    VideoLiveForDesktop,
    TableResult,
    BettingComponent,
    ReportBetting,
    ResultVideoDistribution,
    CoinButton, ChannelInfo, LimitBetting, SubmitBtn, InputAmountMoney
  },
  props: [
    "passEnabledChannel",
    "passChannel",
    "passChannelStatus",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passLoadingTodayReport",
    "passSelectedChannel",
    "passCurrentBetting",
    "passUserCurrency",
  ],
  data() {
    return {
      // DEFINE VARIABLE HERE
    };
  },
  computed: {

    ...mapGetters("$_casino_live", {
      getSwitchChannelLoading: "getSwitchChannelLoading",
      dialogBetting: "getDialogBetting",
      getCasinoLiveSeletedBtn:"getCasinoLiveSeletedBtn"
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },
  methods: {
    _submitBetting() {
      this.bettingMoney = this.getBettingAmount;
      let submitBettingData = {
        type_id: this.getCasinoLiveSeletedBtn.selectedButton._id,
        amount: this.bettingMoney,
      };
      this.$emit("sentSubmitBetting", submitBettingData);
    },
    receiveSubmitBetting(recieveBettingData) {
      this.$emit("sentSubmitBetting", recieveBettingData);
    },
    _switchChannel(selectedChannel) {
      this.$emit("sentSetChannel", selectedChannel);
    },
  },
};
</script>