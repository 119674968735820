<template>
    <div class="flexi-machine-right">
        <div class="game-machine-betting-component">
          <div class="game-machine-input">
            <ListCoins/>
            <MachineInputAmount/>
            <OutStanding/>
            <h5>{{ $t('title.statement') }}</h5>
          </div>
          <div class="game-machine-statement">
            <Statement/>
          </div>

        </div>
    </div>
</template>
<script>
import ListCoins from "../SubComponent/ListCoins.vue"
import MachineInputAmount from "../SubComponent/MachineInputAmount.vue"
import OutStanding from "../SubComponent/OutStanding.vue"
import Statement from "../SubComponent/Statement.vue"

export default {
  components: {
    ListCoins,
    MachineInputAmount,
    OutStanding,
    Statement
    },
  }
</script>