<template>
  <div>
    <v-row class="ma-0 w-100">
      <div style="width: 100% !important; max-width: 100% !important;" class="machine-baccarat-three-section machine-blue-title flex-grow-1">
        <div  class="machine-baccrat-button machine-play-blue-bg" :class="getSelectedYukiBtn && getSelectedYukiBtn.button 
        && getSelectedYukiBtn.button.name == passBetType[2].name && getSelectedYukiBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[2])"> 
          <p class="machine-payout-name machine-button-font fantan-top-button" >
            {{  passBetType[2] && passBetType[2].payout  ?  '1:' + passBetType[2].payout : '1:0.95' }}
          <br/>
          <span class="machine-button-name machine-button-font fantan-blue">{{passBetType[2] ? passBetType[2].name.toUpperCase() : 'NA'}}</span>
          </p>
        </div>
        <div class="machine-baccrat-button reserver-margin machine-play-blue-bg" :class="getSelectedYukiBtn && getSelectedYukiBtn.button 
        && getSelectedYukiBtn.button.name == passBetType[17].name && getSelectedYukiBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[17])"> 
          <p class="machine-payout-name machine-button-font fantan-top-button">
            {{ passBetType[17] && passBetType[17].payout ? '1:' + passBetType[17].payout : '1:0.95' }}
          <br/>
          <span class="machine-button-name machine-button-font taisev-yellow-title white--text">{{passBetType[17] ? passBetType[17].name.toUpperCase() : 'NA'}}</span>
          </p>
          
        </div>
      </div>

      <!-- Custom Spacer -->
      <div style="width: 10px;"> <!-- Adjust width as needed --></div>

      <div style="width: 100% !important; max-width: 100% !important;" class="machine-baccarat-three-section machine-red-title flex-grow-1">
        <div class="machine-baccrat-button machine-play-red-bg " :class="getSelectedYukiBtn && getSelectedYukiBtn.button 
        && getSelectedYukiBtn.button.name == passBetType[19].name && getSelectedYukiBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[19])"> 
          <p class="machine-payout-name machine-button-font fantan-top-button">
            {{passBetType[19] ? passBetType[19].payout : '1:0.95'}}
            <br/>
            <span class="machine-button-name machine-button-font fantan-red fantan-top-button">
              {{passBetType[19] ? passBetType[19].name.toUpperCase() : 'NA'}}
            </span>
          </p>
        </div>
        <div  class=" machine-baccrat-button reserver-margin machine-play-red-bg" :class="getSelectedYukiBtn && getSelectedYukiBtn.button 
        && getSelectedYukiBtn.button.name == passBetType[20].name && getSelectedYukiBtn.channelType == passChannelType ?'machine-game-active':''" 
        @click="_updatedSelectedBtn(passBetType[20])"> 
          <p class="machine-payout-name machine-button-font fantan-top-button">
            {{passBetType[20] ? passBetType[20].payout : '0.95'}}
            <br/>
            <span class="machine-button-name machine-button-font taisev-dark-green-title white--text">{{passBetType[20] ? passBetType[20].name.toUpperCase() : 'NA'}}</span>
          </p>
          
        </div>
      </div>
    </v-row>
    <CockXCasinoSubmitButton :passChannelType="passChannelType" :selectedSubmit="'selectedSubmitYuki'"/>
  </div>
</template>
<script>
import {mapGetters, mapMutations } from "vuex";
import CockXCasinoSubmitButton from "../../../../../../components/component_cock_x_casino/machine_game_submit_button.vue"

export default {
  components: {
    CockXCasinoSubmitButton,
  },
  props:['passBetType', 'passChannelType'],
  computed:{
    ...mapGetters("$_cockfight_casino_live", {
      getSelectedYukiBtn: "getSelectedYukiBtn",
    }),
  },

  methods:{
    _updatedSelectedBtn(button){
      let data = {
        button : button,
        channelType : this.passChannelType
      }
      this.UPDATE_SELECTED_YUKI_BUTTON_IN_ALL(data);
    },
    ...mapMutations("$_cockfight_casino_live", [
      "UPDATE_SELECTED_YUKI_BUTTON_IN_ALL",
    ]),
  }
}
</script>