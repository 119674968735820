<template>
  <div class="navbar-container navbar-right" >
    <ul class="nav-right pl-0"> 
      <li class="nav-right-li nav-deposit" >
        <a @click="_goToRoute('/deposit-request')">
          <div class="name-container nav-right-title nav-transaction-color">
            {{ $t('title.deposit') }}
          </div>
        </a>
      </li>
      <li class="nav-right-li nav-withdraw mx-2">
        <a @click="_goToRoute('/withdraw-request')">
          <div class="name-container nav-right-title nav-transaction-color" >
            {{ $t('title.withdraw') }}
          </div>
        </a>
      </li>
      <li class="nav-right-li nav-logout" >
        <div class="name-container nav-right-title nav-logout-color">
          <span style="" class="user-balance">{{ formatMoney(userInfo.balance) }} {{ _displayCurrencySymbol(userInfo.account_currency) }}</span>
        </div>
      </li>
      <li class="">
        <div class="text" style="position: relative">
          <div class="ma-1 name-container ">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn text class="nav-report-notication" v-bind="attrs" v-on="on" @click="getListNotification()">
                  {{ $t('title.report') }} 
                  <v-badge v-if="getNotiCount && getNotiCount.count >=0" :content="getNotiCount.count>0 ? getNotiCount.count : '' " :color="getNotiCount.count>0 ? 'error' : ''">
                    <v-icon :style="`color:${calculateRingbellStype}`" class="icon-style">mdi-bell</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <template>
                <div id="scroll-target" class="parent-height">
                  <v-list v-scroll:#scroll-target="onScroll" class="vivo pt-0" :style="`height:${height}px; display:table!important;`">
                    <v-list-item link class="py-0 w-100 notification-list">
                      <v-list-item-title class="100% pb-0">
                        <span class="pl-3 notify-header noti-row">
                          {{ $t("title.amount") }}
                        </span>
                        <span class="pl-3 notify-header noti-row">
                          {{ $t("title.type") }}
                        </span>
                        <span class="pl-3 notify-header noti-row">
                          {{ $t("title.date") }}
                        </span>
                        <span class="pl-3 notify-header noti-row">
                          {{ $t("title.status") }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                    <div v-for="(notification, i) in getNotiData" :key="i" class="pipo pt-0 mt-0">
                      <v-list-item link @click="getDetailNotification(notification._id)">
                        <v-list-item-title class="w-100" style="display: inline">
                          <span class="pl-2 notify-text noti-row">
                            {{ formatMoney(notification.amount) }} <br /> <span class="mt-1"
                              style="line-height:18px">{{ notification && notification.promotion_percentage > 0 ?
                                '(+' + notification.promotion_percentage + '%)' : '' }}</span>
                          </span>
                          <span class="pl-2 notify-text noti-row" :style="`color:${calculateColorType(
                            notification.type
                          )}!important`">
                            {{ $t(`title.${notification.type}`) }}
                          </span>
                          <span class="pl-2 notify-text noti-row">
                            {{
                              moment(notification.send_date).format(
                                "YYYY-MM-DD"
                              )
                            }}
                          </span>
                          <span class="pl-2 notify-text noti-row">
                            <v-icon :style="`color:${calculateColorStatus(
                                  notification.status
                                )}`">
                              {{
                                calculateIconStatus(notification.status)
                              }}</v-icon>
                            <v-badge v-if="!notification.is_read" style="padding-top: 0px; font-size: 5px"
                              class="read-unread"></v-badge>
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                    </div>
                    <div style="text-align: center; padding: 7px 0px" v-if="loading">
                      <v-progress-circular model-value="20" v-model="loading" color="amber"></v-progress-circular>
                    </div>
                  </v-list>
                </div>
              </template>
            </v-menu>
          </div>
        </div>
      </li>
      <li class="user-profile header-notification">
        <div class="text" style="position: relative">
          <div class="mx-2 name-container" @click="_updateExpand()" v-click-outside="{
            handler: onClickOutside,
            include
          }">
            <v-icon v-if="!expandMenu" class="icon-style mt-3 ">mdi-menu</v-icon>
            <v-icon v-if="expandMenu" class="icon-style mt-3 ">mdi-menu-open</v-icon>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import dashboardFunc from "~mixin/MX_ShareFunctionCollection.js"


export default {
  dashboardFunc: [dashboardFunc],

  data: () => ({
    expandNot: false,
    data: {
      rows_per_page: 6,
      page: 1,
    },
    posts: {},
    bottom: false,
    beers: [],
    editAmount: 0,
    scrollTop: 0,
    offsetTop: {},
    height: 600,
    lading: false,
    numberRule: (amount) => {
      if (amount < 0) return "Please enter a positive number";
      return true;
    },
  }),
  computed: {
    logoHeader(){
      return require('../../../public/images/logo_background/Logo-header.png')
    },
    calculateRingbellStype() {
      if (this.getNotiCount.count > 0) return "red";
      else return "grey";
    },

    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      userNotList: "getNotList",
      loading: "getLoading",
      getNotiCount: "getNotiCount",
      dialog: "getDialog",
      editDialog: "getEditDialog",
      getNotiDetail: "getNotiDetail",
      getNotiData: "getNotiData",
      expandMenu: "getExpandMenu",
    }),
  },
  created() {
    this.initialData();
  },
  watch: {
    lang() { },
    height() { },
    bottom(bottom) {
      if (bottom) {
        this.addBeer();
      }
    },
  },

  methods: {
     onClickOutside(){
      this.UPDATE_EXPAND_MENU(true);
    },
    include(){
      return [document.querySelector('.included')]
    },
    _updateExpand(){
      this.UPDATE_EXPAND_MENU(this.expandMenu);
    },
    changeLang(lang) {
      if (lang == "khmer") this.lang = "flag-kh.png";
      else this.lang = "flag-en.webp";
      this.$i18n.locale = lang;
      this.$cookies.set("local", lang);
    },
    calculateColorType(type) {
      if (type == "DEPOSIT") return "#f34141";
      if (type == "WITHDRAW") return "#167df1";
    },
    async initialData() {
      await this.fetchNotCount(this.data);
    },
    async getListNotification() {
      this.data = {
        rows_per_page: 6,
        page: 1,
      };
      this.height = 600,
      this.UPDATE_NOTI_DATA([]);
      await this.fetchNotList(this.data)
      await this.fetchNotCount(this.data);
      this.UPDATE_NOTI_DATA(this.userNotList.reports);
    },
    moment,
    changeHeight(a) {
      if (a) this.height = 600;
      else this.height = this.getNotiData.length * 52;
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
      this.bottom = this.bottomVisible(e);
    },
    bottomVisible(e) {
      const scrollY = e.target.scrollTop;
      const pageHeight = 150;
      const bottomOfPage = scrollY >= pageHeight;
      return bottomOfPage || pageHeight < scrollY;
    },
    async addBeer() {
      this.data = {
        rows_per_page: 6,
        page: this.data.page + 1,
      };
      await this.fetchNotList(this.data).then(() => {
        if (
          this.userNotList &&
          this.userNotList.reports &&
          this.userNotList.reports.length != 0
        ) {
          this.getNotiData.push(...this.userNotList.reports);
          this.changeHeight();
          if (this.bottomVisible()) {
            this.addBeer();
          }
        } else {
          this.data = {
            rows_per_page: 6,
            page: this.data.page - 1,
          };
        }
      });
    },
    _goToRoute(path) {
      this.$router.push({ path }).catch(() => { /* ignore */ });
    },
    _detectLanguage(lang) {
      try{
        return require(`../../assets/images/${lang}`)
      }catch(e){
        // console.log(e)
        return {}
      }
   
    },
    onLogout() {
      if (this.$socket) {
        this.$socket.removeAllListeners();
        this.$socket.disconnect();
      }
      this.$cookies.remove("sbc_channel_type");
      this.$cookies.remove("sbc_token");
      this.$router.push("/login");
    },
      
    calculateIconStatus(status) {
      switch (status) {
        case "SUCCESS":
          return "mdi-check-circle-outline";
        case "PENDING":
          return "mdi-alert-circle-outline";
        case "REJECT":
          return "mdi-cancel";
        default:
          return "";
      }
    },
    calculateColorStatus(status) {
      switch (status) {
        case "SUCCESS":
          return "green";
        case "PENDING":
          return "#633e2c";
        case "REJECT":
          return "red";
        default:
          return "";
      }
    },
    async getDetailNotification(postId) {
      let data = {
        req_id: postId,
      };
      await this.fetchNotiDetail(data);
    },
    async removeRequest(item) {
      if (item.type == "DEPOSIT") {
        let data = {
          req_id: item._id,
        };
        let res = await this.removeDeposit({},{ vm: this, data: data });
        if (res == true)
          this.data = {
            rows_per_page: 6,
            page: 1,
          };
      }
      if (item.type == "WITHDRAW") {
        let data = {
          req_id: item._id,
        };
        let res = await this.removeWithdraw({},{ vm: this, data: data });
        if (res == true)
          this.data = {
            rows_per_page: 6,
            page: 1,
          };
      }
    },
    closeDialog() {
      this.DIALOG(false);
      this.getListNotification();
    },
    async updateRequest(item) {
      if (this.editAmount <= 0) {
        this.$toastr.e("Please enter deposit amount!");
      } else {
        if (item.type == "DEPOSIT") {
          try {
            let data = {
              req_id: item._id,
              agent_account_id: item.bank_reciever._id,
              amount: this.editAmount,
              note: "",
            };
            // this.loading = true;
            const response = await this.updateDeposit({ vm: this, data: data });
            if (response.data.code) {
              this.$toastr.s("Your deposit request have been update!");
              // this.loading = false;
              this.UPDATE_AMOUNT_NOTI_DETAIL(this.editAmount);
              this.closeEditDialog();
            } else {
              this.$toastr.e(response.data.message.descriptions);
              this.error = true;
            }
          } catch (error) {
            this.error = true;
            return error;
          } finally {
            // this.loading = false;
          }
        }
        if (item.type == "WITHDRAW") {
          try {
            let data = {
              req_id: item._id,
              amount: this.editAmount,
              note: "",
            };
            // this.loading = true;
            const response = await this.updateWithdraw({ vm: this, data: data });
            if (response.data.code) {
              this.$toastr.s("Your deposit withdraw have been update!");
              // this.loading = false;
              this.UPDATE_AMOUNT_NOTI_DETAIL(this.editAmount);
              this.closeEditDialog();
              this.closeEditDialog();
            } else {
              this.$toastr.e(response.data.message.descriptions);
              this.error = true;
            }
          } catch (error) {
            this.error = true;
            return error;
          } finally {
            // this.loading = false;
          }
        }
      }
    },
    async editRequest(notification) {
      this.EDIT_DIALOG(true);
      this.editAmount = notification.amount;
    },

    closeEditDialog() {
      this.EDIT_DIALOG(false);
    },
    ...mapActions("$_modules", [
      "fetchNotList",
      "fetchNotCount",
      "fetchNotiDetail",
      "removeDeposit",
      "removeWithdraw",
      "refreshNotiList",
      "updateDeposit",
      "updateWithdraw",
    ]),
    ...mapMutations("$_modules", [
      "DIALOG",
      "EDIT_DIALOG",
      "UPDATE_NOTI_DATA",
      "UPDATE_AMOUNT_NOTI_DETAIL",
      "UPDATE_CHANNEL_ID",
      "UPDATE_EXPAND_MENU"
    ]),
  },
};
</script>
