<template>
  <div class="trends limit-on-tab">
    <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
      style="position: relative; margin-top: 12px;">
      <div class="table-responsive pb-3 gp-scroll">
        <table class="kk-table" style="height:167px">
          <tbody>
            <tr class="han2-tr-result">
              <td>
                <div>
                  <div width="50" style="
                width:50px; height:33.5px
                margin-right: -1px;
                text-align: center;
                margin-bottom: 0px; color: #212427; 
              ">
                    <b style="font-weight:700; font-size:.9rem;"></b>
                  </div>
                  <div>
                    <p style="color:white; font-weight:700; font-size:1.3rem; padding-top:9px"> P1</p>
                    <p style="color:white; font-weight:700; font-size:1.3rem; padding-top:9px"> P2</p>
                    <p style="color:white; font-weight:700; font-size:1.3rem; padding-top:9px"> P3</p>
                  </div>
                </div>
              </td>
              <td v-for="col in cols" :key="col">
                
                <div>
                  <div
                    v-for="(result, i) in (resultObj.results ? resultObj.results.slice().reverse() : resultObj.results)"
                    :key="i" class="text-center"
                    style="width:50px;max-width:50px;margin-left: auto; margin-right: auto; text-align: center;">
                    <div v-if="(col - 1) == i">
                      <div width="50" style="
                        margin-right: -1px;
                        border: 1px solid #535151;
                        text-align: center;
                        margin-bottom: 0px; color: white; 
                        background-color: #1f3147">
                        <b style="font-weight:700; font-size:.9rem">#{{ result.fight_no }}</b>
                      </div>
                      <p> <span :class="_getResultClass(result.result1_name).c">{{ i == 0
                        ? _getResultClass(result.result1_name).f : _getResultClass(result.result1_name).n }}</span></p>
                      <p> <span :class="_getResultClass(result.result2_name).c">{{ i == 0
                        ? _getResultClass(result.result2_name).f : _getResultClass(result.result2_name).n }}</span></p>
                      <p><span :class="_getResultClass(result.result3_name).c">{{ i == 0
                        ? _getResultClass(result.result3_name).f : _getResultClass(result.result3_name).n }}</span></p>
                      </div>
                  </div>
                  <div v-if="col>resultObj.results.length">
                    <div width="50" style="
                    height:34px
                    margin-right: -1px;
                    border: 0.5px solid #535151;
                    text-align: center;
                    margin-bottom: 0px; color: white; 
                    background-color: #1f3147">
                    
                  </div>
                  <div>
                    <p style="color: #212427; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                    <p style="color: #212427; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                    <p style="color: #212427; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                  </div>
                
                  </div>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
  </div>

</template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    props: ["rows", "cols"],
  
    computed: {
        ...mapGetters("$_dashboard", {
            resultObj: "getResult",
            channel: "getChannel",
        }),
        orderResult() {
            return this.getListResults();
        },
        number(bet) {
        return this.getNumber(bet);
        }
    },
    methods: {
        getNumber(bet){
        let number = [];
        number = bet.split(":");
        return number[1]
        },
      getListResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.resultObj.results != null && this.resultObj.results.length > 0){
            let resultList = this.resultObj.results
            for (var index = resultList.length - 1; index >= 0; index--) {
                const item = resultList[index];
                if(index == resultList.length - 1){
                    newResult.push({
                        ...item,
                        row,
                        col,
                    });
                }else{
                    const pre = newResult[newResult.length - 1];
                    if(pre.name != item.name){
                        newResult.push({ 
                        ...item,
                        row: 1,
                        col: pre.col + 1,
                        });
                    }else{
                        if (pre.row >= 6) {
                            newResult.push({
                                ...item,
                                row: 1,
                                col: pre.col + 1,
                            });
                        } else {
                            newResult.push({
                                ...item,
                                row: pre.row + 1,
                                col: pre.col,
                            });
                        }
                    }
                }
            }
        }
      // console.log(newResult)
      return newResult;
    },
      _resultColor(color) {
        switch (color) {
          case 'cir-blue':
            return 'trend-wala'
          case 'cir-red':
            return 'trend-meron'
          case 'cir-green':
            return 'trend-draw'
          case 'cir-cancel':
            return 'trend-cancel'
          default:
            return ''
        }
      },
      _getResultClass(x) {
      switch (x) {
        case 'Banker 1':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Banker 2':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Banker 3':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Player 1':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'Player 2':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'Player 3':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'CANCEL':
          return { c: 'han2-r-td-cancel', n: 'C', f: 'C', cf: 'han2-r-td-cancel-f' }
        case 'Tie':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 1':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 2':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 3':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
        default:
          return ''
      }
    }
    },
  };
  </script>
  
  <style scoped>
  .history-result td {
    text-align: center;
    height: 45px;
    width: 45px;
    padding: 2px;
    background: #f2f6f9;
    border: 1px solid #96afde;
    vertical-align: middle;
    box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
    -moz-box-shadow: 0 0 2px 1px #fff;
    -webkit-box-shadow: 0 0 2px 1px #fff;
    -ms-box-shadow: 0 0 2px 1px #fff;
    -o-box-shadow: 0 0 2px 1px #fff;
  }
  
  .round-blue {
    background: url(../../../../../assets/images/round-blue.png) no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .round-red {
    background: url(../../../../../assets/images/round-red.png) no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .cycle-blue {
    background: url(../../../../../assets/images/cycle-blue.png) no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .cycle-red {
    background: url(../../../../../assets/images/cycle-red.png) no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .center-padding-0 {
    text-align: center;
    padding: 0px !important;
  }
  
  .result-table {
    width: 100%;
    margin: 0;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    ;
  }
  
  .trend-table{
    border-spacing: 0px !important;
  }
  .trend-table td {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 10px;
    text-align: center;
    border: 1px solid #c5c5c5;
    padding: 0px;
  }
  
  td>div {
    width: 35px;
  }

  .kk-table td {
  width: 50px;
  height: 163px;
  line-height: 30px;
  font-size: 10px;
  text-align: center;
  /*border: 1px solid #c5c5c5;*/
  padding: 0px;
}

.kk-table td>div {
  width: 50px;
}

table tr td {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  /*border: 1px solid white;*/
}

table {
  border-collapse: collapse;
  width: 100% !important;
  max-width: 100% !important;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}

.han2-tr-result td p {
  border: 1px solid #9e9e9e;
  margin-bottom: 0px;
  margin-right: -1px;
  /*border-radius: 25px;*/
  padding: 1px;
  height: 50px;
}

.han2-tr-result td p span {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;

  font-weight: 900;
  padding-top: 6px;
}

.han2-r-td-tie {
  background-color: #4caf50;
  font-size: 1.2rem;
  color: white;
}

.han2-r-td-cancel {
  background-color: #9e9e9e;
  font-size: 1.2rem;
  color: white;
}

.han2-r-td-player {
  background-color: #0b4592;
  font-size: 1.2rem;
  color: white;
}

.han2-r-td-banker {
  background-color: #b31013;
  font-size: 1.2rem;
  color: white;
}

.han2-r-td-banker-f {
  color: #b31013;
  font-size: 1.5rem;
  color: white;
}

.han2-r-td-player-f {
  color: #0b4592;
  font-size: 1.5rem;
  color: white;
}

.han2-r-td-cancel-f {
  color: white;
  font-size: 1.5rem;
}
  </style>