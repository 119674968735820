<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0 pt-0">
        <ChannelInfo
            :passChannel="passChannel"
        />
        <div>
            <div class="g-summary">

                <div class="sides-wrapper gp-panel-bg rounded"   :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                    <div class="d-flex sides px-2 w-100 mx-auto justify-center">
                        <div v-if="walaButton" :class="selectedBtnClassWala ? 'on-active-btn' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            class="side-section-dragon wala-section-dragon pb-1 side-1">
                            <div @click="_allowBtnBet('selectedPairPlayer', pairPlayerButton)"
                                :class="selectedBtnClassPairPlayer ? 'selected-p-player ' : 'pair-betting'">
                                <span class="pair-banker-text font-weight-bold"> {{pairPlayerButton? `PAIR x
                                ${pairPlayerButton.payout_display}`: '' }}</span>
                                  <br>
                                    {{ passCurrentBetting && passCurrentBetting.pair_player_amount >0? ` ${
                                        userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.pair_player_amount) : formatBtnCurrency(passCurrentBetting.pair_player_amount)
                                        }` :"" }}
                            </div>
                            <div class="banker-button" @click="_allowBtnBet('selectedWala', walaButton)">
                                <div class=" pt-1 mb-0 truncate"><span style="font-weight:600">{{
                                    userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.player_amount) : formatBtnCurrency(passCurrentBetting.player_amount)
                                }}</span>

                                    <div class="odds">{{ walaButton.payout_display }}</div>
                                    <div class="side-title pt-1">
                                        <div class="truncate font-weight-bold">{{ walaButton.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div class="side-section-tie tie-section  mx-2 side-1 "
                            :class="selectedBtnClassTie ? 'on-active-btn' : ''" 
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            @click="_allowBtnBet('selectedTie')"
                            v-if="tieButton">
                            <div class="pt-15 mb-0 "><span style="font-weight:600">
                                {{
                                `${ userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.tie_amount) : formatBtnCurrency(passCurrentBetting.tie_amount) }`
                            }}
                            </span>

                                <div class="odds">{{ tieButton? `1 : ${tieButton.payout_display}`: 'x.xx' }}</div>
                                <div class="side-title pt-1">
                                    <span class="truncate font-weight-bold" style=" font-family:'Koh Santepheap'">{{$t('title.tie')}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="meronButton && pairBankerButton" 
                        :class="selectedBtnClassMeron ? 'on-active-btn' : ''"
                        :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            class="side-section-tiger meron-section-tiger pb-1  side-1">
                            <div @click="_allowBtnBet('selectedPairBanker', pairBankerButton)"
                                :class="selectedBtnClassPairBanker ? 'selected-p-banker ' : 'pair-betting'">
                                <span class="pair-banker-text font-weight-bold">
                                    {{pairBankerButton? `PAIR x ${pairBankerButton.payout_display}` :"" }}
                                </span>
                                    <br>
                                    {{passCurrentBetting && passCurrentBetting.pair_banker_amount >0? ` ${
                                        `${ userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.pair_banker_amount) : formatBtnCurrency(passCurrentBetting.pair_banker_amount) }`
                                        }` :"" }}
                                
                            </div>
                            <div class="banker-button" @click="_allowBtnBet('selectedMeron', meronButton)">
                                <div class=" pt-1 mb-0 "><span style="font-weight:600">{{
                                    userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.banker_amount) : formatBtnCurrency(passCurrentBetting.banker_amount)
                                }}</span>
                                    <div class="odds">{{ meronButton.payout_display }}</div>
                                    <div class="side-title pt-1">
                                        <div class="truncate font-weight-bold">{{ meronButton.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CoinButton 
                :passGetButton="passGetButton" 
                :passChannel="passChannel"
                :passPlaceCoin="bettingMoney"
                v-on:handleInputMonay="_inputAmount"/>
            <SubmitBtn :passCheckAllowSubmit="_checkAllowSubmit" v-on:handleSubmitBtn="_submitBetting"
                v-on:handleClearBtn="_clearMoney" />
            <LimitBetting/>
        </div>
    </div>
</template>

<script>

import "@/assets/css/homepage.css";
import CoinButton from '../../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../../components/ChannelInfoLive.vue';
import LimitBetting from '../../../../../components/LimitBettingLive.vue';
import SubmitBtn from '../../../../../components/SubmitBtn.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn },
    props: ["passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel',
        'passUserCurrency'
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            selectedBtnClassMeron: false,
            selectedBtnClassTie: false,
            selectedBtnClassWala: false,
            selectedBtnClassPairBanker: false,
            selectedBtnClassPairPlayer: false,
            csBanker1: '',
            csBanker2: '',
            csBanker3: '',
            csPlayer1: '',
            csPlayer2: '',
            csPlayer3: '',
            totalP: 0,
            totalB: 0,
            selectedCoin: false,
        }

    },
    watch: {
  
    },
    computed: {
        meronButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        pairBankerButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[3]
            else return {}
        },
        pairPlayerButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[4]
            else return {}
        },
        _checkAllowSubmit(){
            if( this.bettingMoney> 0 && ( this.selectedBtnClassMeron || this.selectedBtnClassTie || this.selectedBtnClassWala || this.selectedBtnClassPairBanker || this.selectedBtnClassPairPlayer)) return true;
            else return false;
        },
        ...mapGetters("$_casino_live", {
            dialogBetting: "getDialogBetting",
            amountMin: "getAmountMin",
            amountMx: "getAmountMax",
            getCountdown :"getCountdown"
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
 
    methods: {

        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <=999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <=99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
       
        _detectCoin(index) {
            switch (index) {
                case 0:
                    return '/images/coins/blue.png';
                case 1:
                    return '/images/coins/pink.png';
                case 2:
                    return '/images/coins/cyan.png';
                case 3:
                    return '/images/coins/red.png';
                case 4:
                    return '/images/coins/orange.png';
                case 5:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinMeron(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorMeron(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'green';
                case money < 50:
                    return 'red';
                case money < 100:
                    return '#f97432';
                case money < 99999999:
                    return 'grey';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinWala(money) {
            switch (true) {
                case money < 5:
                    return '/images/a_game_access_global/coins/blue.png';
                case money < 10:
                    return '/images/a_game_access_global/coins/pink.png';
                case money < 20:
                    return '/images/a_game_access_global/coins/cyan.png';
                case money < 50:
                    return '/images/a_game_access_global/coins/red.png';
                case money < 100:
                    return '/images/a_game_access_global/coins/orange.png';
                case money < 99999999:
                    return '/images/a_game_access_global/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorWala(money) {
            switch (true) {
                case money < 5:
                    return 'blue';
                case money < 10:
                    return '#F92A53';
                case money < 20:
                    return 'green';
                case money < 50:
                    return 'red';
                case money < 100:
                    return '#f97432';
                case money < 99999999:
                    return 'grey';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinTie(money) {
            switch (true) {
                case money < 5:
                    return '/images/a_game_access_global/coins/blue.png';
                case money < 10:
                    return '/images/a_game_access_global/coins/pink.png';
                case money < 20:
                    return '/images/a_game_access_global/coins/cyan.png';
                case money < 50:
                    return '/images/a_game_access_global/coins/red.png';
                case money < 100:
                    return '/images/a_game_access_global/coins/orange.png';
                case money < 99999999:
                    return '/images/a_game_access_global/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorTie(money) {
            switch (true) {
                case money < 5:
                    return 'blue';
                case money < 10:
                    return '#F92A53';
                case money < 20:
                    return 'green';
                case money < 50:
                    return 'red';
                case money < 100:
                    return '#f97432';
                case money < 99999999:
                    return 'white';
                default:
                    return ''
            }
        },
        _inputAmount(money) {
            
                this.selectedCoin = true;
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)

        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassMeron = false;
            this.selectedBtnClassTie = false;
            this.selectedBtnClassWala = false;
            this.selectedBtnClassPairBanker = false;
            this.selectedBtnClassPairPlayer = false;
            this.selectedCoin = false;
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.selectedCoin ) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedMeron':
                    this.selectedButton = this.meronButton;
                    this.selectedBtnClassMeron = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassWala = false;
                    this.selectedBtnClassPairBanker = false;
                    this.selectedBtnClassPairPlayer = false;
                    break;
                case 'selectedTie':
                    this.selectedButton = this.tieButton;
                    this.selectedBtnClassTie = true;
                    this.selectedBtnClassMeron = false;
                    this.selectedBtnClassWala = false;
                    this.selectedBtnClassPairBanker = false;
                    this.selectedBtnClassPairPlayer = false;
                    break;
                case 'selectedWala':
                    this.selectedButton = this.walaButton;
                    this.selectedBtnClassWala = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassMeron = false;
                    this.selectedBtnClassPairBanker = false;
                    this.selectedBtnClassPairPlayer = false;
                    break;
                case 'selectedPairBanker':
                    this.selectedButton = this.pairBankerButton;
                    this.selectedBtnClassPairBanker = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassMeron = false;
                    this.selectedBtnClassWala = false;
                    this.selectedBtnClassPairPlayer = false;
                    break;
                case 'selectedPairPlayer':
                    this.selectedButton = this.pairPlayerButton;
                    this.selectedBtnClassPairPlayer = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassMeron = false;
                    this.selectedBtnClassWala = false;
                    this.selectedBtnClassPairBanker = false;
                    break;
            }
        },
        ...mapMutations("$_casino_live", [
            "BETTING_DIALOG", "UPDATE_PLAYER_CARD", "UPDATE_BANKER_CARD", "RESET_CARD"
        ]),
    }
}
</script>
<style scoped>
@media(min-width:960px) {
    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 185px;

    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .betting-coin-meron {
        width: 40px;
        height: auto;
        margin: auto;

    }

    .betting-coin-wala {
        width: 40px;
        height: auto;
        margin: auto;

    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
            border: 4px solid gold;
            /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%)!important;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width:150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        border: 4px solid gold;
            /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%)!important;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -29.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 68px;
    }

    .side-section-tiger {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 200px;
        background-image: url('/images/tiger-dragon/tiger.png');
        z-index: 1000;
    }

    .side-section-dragon {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        background-image: url('/images/tiger-dragon/dragon.png');
        z-index: 1000;
    }
}

@media(min-width:1280px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 200px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .betting-coin-meron {
        width: 50px;
        height: auto;
        margin: auto;
    }

    .betting-coin-wala {
        width: 60px;
        height: auto;
        margin: auto;

    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width:150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        border: 4px solid gold;
            /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%)!important;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .betting-coin-meron {
        width: auto;
        height: 58px;
        margin: auto;

    }

    .betting-coin-wala {
        width: auto;
        height: 58px;
        margin: auto;

    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width:150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

    .on-active-btn {
        border: 4px solid gold;
            /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%)!important;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -38px;
        margin-bottom: 31px;
        z-index: 100;
        font-weight: 900;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 7px;
        left: -8px;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .side-section-tiger {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 200px;
        background-image: url('/images/tiger-dragon/tiger.png');
        z-index: 1000;
    }

    .side-section-dragon {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        background-image: url('/images/tiger-dragon/dragon.png');
        z-index: 1000;
    }
}

.meron-section-tiger,
.modal-meron .modal-content {
    color: #fff
}

.meron-section-tiger {
    color: #fff;
    border: 2px#f34141 solid;
    border-radius: 5px;
}

.meron-section-tiger .side-title,
.modal-meron .modal-bet-title {
    text-transform: uppercase;
    color: #f34141
}


.wala-section-dragon,
.modal-meron .modal-content {
    color: #fff
}

.wala-section-dragon {
    color: #fff;
    border: 2px#217faa solid;
    border-radius: 5px;
}

.wala-section-dragon .side-title,
.modal-meron-tiger .modal-bet-title {
    text-transform: uppercase;
    color: #187df1
}

.pair-betting {
    width: 100%;
    height: 56px;
    border-radius: 0 0 50% 50%;
    background-color: rgba(32, 218, 88, 0.2);
    border: 2px solid rgb(218, 165, 32);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    padding-top: 5px;
}

.pair-betting:hover {
    cursor: pointer;
    width: 100%;
    height: 56px;
    border-radius: 0 0 50% 50%;
    background-color: rgba(218, 165, 32, 0.7);
    border: 2px solid rgb(218, 165, 32);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.pair-banker-text {
    font-size:18px;
    font-weight: 600;
    color: lightgoldenrodyellow;
    text-shadow: 1px 0px black
}

.selected-p-banker {
    width: 100%;
    height: 56px;
    border-radius: 0 0 50% 50%;
    background-color: rgba(218, 165, 32, 0.7);
    border: 2px solid rgb(218, 165, 32);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    padding-top: 5px
}

.banker-button {
    width: 100%;
    height: 147px
}

.banker-button :hover {
    cursor: pointer;
}

.selected-p-player {
    width: 100%;
    height: 56px;
    border-radius: 0 0 50% 50%;
    background-color: rgba(218, 165, 32, 0.7);
    border: 2px solid rgb(218, 165, 32);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    padding-top: 5px
}

.open-betting-countdown {
    background-color: #def3f6;
    font-size: 28px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 0px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}
</style>