<template>
  <v-flex>
    <div id="video-container">
      <KlakloukGameContent  v-if="checkerGame(_selectedGame(channelId)) == 'klaklouk'" />
      <TigerDragonGameContent  v-if="checkerGame(_selectedGame(channelId)) == 'dragon'" />
      <BaccaratGameContent v-if="checkerGame(_selectedGame(channelId)) == 'baccarat'"/>
      <DDGameContent v-if="checkerGame(_selectedGame(channelId)) == 'doden'"/>
      <SICBOGameContent v-if="checkerGame(_selectedGame(channelId)) == 'sicbo'"/>
      <TaiXiuGameContent v-if="checkerGame(_selectedGame(channelId)) == 'taixiu'"/>
      <HamGameContent v-if="_selectedGame(channelId) == 'slot-ham'"/>
      <ThreeFaceGameContent v-if="_selectedGame(channelId) == 'slot-three_face'"/>
    </div>
  </v-flex>
</template>

<script>

import { mapActions, mapState, mapGetters } from "vuex";
import KlakloukGameContent from "./_GameContent/KlakloukGameContentComponent.vue"
import TigerDragonGameContent from "./_GameContent/TigerDragonGameContentComponent.vue"
import BaccaratGameContent from "./_GameContent/BaccaratGameContentComponent.vue"
import DDGameContent from "./_GameContent/DodenGameContentComponent.vue"
import SICBOGameContent from "./_GameContent/SicBoGameContentComponent.vue"
import TaiXiuGameContent from "./_GameContent/TaiXiuGameContentComponent.vue"
import HamGameContent from "./_GameContent/HamGameContentComponent.vue"
import ThreeFaceGameContent from "./_GameContent/ThreeFaceGameContentComponent.vue"
export default {
  components: {ThreeFaceGameContent, HamGameContent, KlakloukGameContent, TigerDragonGameContent, BaccaratGameContent, DDGameContent, SICBOGameContent,TaiXiuGameContent },
  data() {
    return {
      urlToken: ""
    };
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      channel: "getChannel",
    }),
    ...mapState("$_dashboard_live", {
      vdolinks: "vdolinks",
    }),
    ...mapGetters("$_modules", {
      channelId: "getChannelId",
    }),
  },
  watch: {
    vdolinks: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.getUrl()
      }
    },
  },
  methods: {
    checkerGame(page){
      switch(true){
        case (page =="slot-baccarat" || page =="slot-baccarat10" ||page =="slot-baccarat2" ||page =="slot-baccarat3" ||page =="slot-baccarat4" ||page =="slot-baccarat5" ||page =="slot-baccarat6" ||page =="slot-baccarat7" ||page =="slot-baccarat8" ||page =="slot-baccarat9" )
            : return 'baccarat';
        case (page =="slot-dragon" || page =="slot-dragon10" ||page =="slot-dragon2" ||page =="slot-dragon3" ||page =="slot-dragon4" ||page =="slot-dragon5" ||page =="slot-dragon6" ||page =="slot-dragon7" ||page =="slot-dragon8" ||page =="slot-dragon9" )
        : return 'dragon';
        case (page =="slot-klaklouk" || page =="slot-klaklouk10" ||page =="slot-klaklouk2" ||page =="slot-klaklouk3" ||page =="slot-klaklouk4" ||page =="slot-klaklouk5" ||page =="slot-klaklouk6" ||page =="slot-klaklouk7" ||page =="slot-klaklouk8" ||page =="slot-klaklouk9" )
        : return 'klaklouk'
        case (page =="slot-doden" || page =="slot-doden10" ||page =="slot-doden2" ||page =="slot-doden3" ||page =="slot-doden4" ||page =="slot-doden5" ||page =="slot-doden6" ||page =="slot-doden7" ||page =="slot-doden8" ||page =="slot-doden9" )
        : return 'doden'
        case (page =="slot-taixiu" || page =="slot-taixiu10" ||page =="slot-taixiu2" ||page =="slot-taixiu3" ||page =="slot-taixiu4" ||page =="slot-taixiu5" ||page =="slot-taixiu6" ||page =="slot-taixiu7" ||page =="slot-taixiu8" ||page =="slot-taixiu9" )
        : return 'taixiu'
        case (page =="slot-sicbo" || page =="slot-sicbo10" ||page =="slot-sicbo2" ||page =="slot-sicbo3" ||page =="slot-sicbo4" ||page =="slot-sicbo5" ||page =="slot-sicbo6" ||page =="slot-sicbo7" ||page =="slot-sicbo8" ||page =="slot-sicbo9" )
        : return 'sicbo'
      }
    },
    async getVideoToken() {
      try {
        const response = await this.fetchVideoToken();
        if (response.data.code) {
          if (response.data.data.token && response.data.data.playOrder) {
            this.urlToken = `${this.getUrl()}&playOrder=${response.data.data.playOrder}&token=${response.data.data.token}`;
          } else if (response.data.data.token && !response.data.data.playOrder) {
            this.urlToken = `${this.getUrl()}&token=${response.data.data.token}`;
          } else if (!response.data.data.token && response.data.data.playOrder) {
            this.urlToken = `${this.getUrl()}&playOrder=${response.data.data.playOrder}`;
          } else {
            this.urlToken = this.getUrl();
          }
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
        }
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    getUrl() {
      if (
        this.vdolinks &&
        (this.vdolinks[0] || this.vdolinks[1])
      ) {
        if (this.vdolinks[0]) {
          return this.urlToken = this.vdolinks[0];
        } else {
          return this.urlToken = this.vdolinks[1];
        }
      }
    },
    handler: function (e) {
      e.preventDefault();
    },
    ...mapActions("$_dashboard_live", [
      "fetchVideoToken",
    ]),
  },
};
</script>


<style scoped>
.black-screen {
  background-color: #212427;
}


.video-container {
  padding-bottom: 56%;
  margin-top: 0px;
  position: relative;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
  
}

.iframe-vid {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}
</style>
