<template>
  <div>
    <ChannelInfo />
    <BettingSection   />
    <input-amount-money />
    <ListCoins />
    <SubmitBtnCock  v-on:handleSelectButton="_submitBetting()"  />
    <LimitBetting />
    <ReportBetting :passLoadingTodayReport="passLoadingTodayReport" />
    <div class="show-on-mobile pt-5">
      <TableResult :rows="6" :cols="30" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import ChannelInfo from "../../../../../../components/component_cock/ChannelInfo.vue";
import ListCoins from "../../../../../../components/component_cock/ListCoins.vue";
import BettingSection from "../../../../../../components/component_cock/CockBettingSection.vue";
import ReportBetting from "../../ReportBetting.vue";
import Mixin from "../../../../../../mixin/MX_ShareFunctionCollection.js";
import TableResult from "../../TableResults.vue";
import LimitBetting from "../../../../../../components/component_cock/LimitBetting.vue";
import InputAmountMoney from '../../../../../../components/component_cock/InputAmountMoney.vue';
import SubmitBtnCock from "../../../../../../components/component_cock/SubmitBtnCock.vue";

export default {
  components: {
    ChannelInfo,
    BettingSection,
    ReportBetting,
    TableResult,
    LimitBetting,
    ListCoins,
    InputAmountMoney,
    SubmitBtnCock
},
  props: [
    "passChannel",
    "passResult",
    "passGetButton",
    "passBettingTypeMaster",
    "passWeight",
    "passTodayReport",
    "passLoadingTodayReport",
    "passChannelList",
    "passSelectedChannel",
    "passCurrentBetting",
    "passNewChannelList",
    "passChannelType",
    "passUserCurrency",
  ],
  data() {
    return {
      mixins: [Mixin],
      bettingMoney: 0,
      activeItem: null,
    };
  },
  computed: {
    meronButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[0];
      else return {};
    },
    walaButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[1];
      else return {};
    },
    tieButton() {
      if (this.passBettingTypeMaster) return this.passBettingTypeMaster[2];
      else return {};
    },
    ...mapGetters("$_dashboard", {
      channelObj: "getChannel",
      getButton: "getButton",
      currentBetting: "getCurrentBetting",
      loading: "getLoading",
      errorMessage: "getErrorMessage",
      getSelectedCockBtn :"getSelectedCockBtn"
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
    }),
  },
  methods: {
    receiveSubmitBetting(recieveBettingData) {
      this.$emit("sentSubmitBetting", recieveBettingData);
    },
    _switchChannel(selectedChannel) {
      this.$emit("sentSetChannel", selectedChannel);
    },
    _submitBetting() {
      this.bettingMoney = this.getBettingAmount;
      let submitBettingData = {
        type_id: this.getSelectedCockBtn.selectedButton._id,
        amount: this.bettingMoney,
      };
      this.$emit("sentSubmitBetting", submitBettingData);
    },


    ...mapMutations("$_modules", [
      "INPUT_BETTING_AMOUNT",
      "CLEAR_BETTING_AMOUNT"
    ]),
  },
};
</script>

<style>
.btn-switch-channel:has(.to-hidden) {
  display: none !important;
}

.video-section {
  position: relative;
  overflow: hidden;
  background-color: #012d98
}

.show-on-mobile {
  display: none;
}

@media (max-width: 768px) {
  .show-on-mobile {
    display: block;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>