const getChannel = (state) => state.channel;
const getAmountMin = (state) => state.amount_min
const getAmountMax = (state) => state.amount_max
const getChannelStatus = (state) => state.channelStatus;
const getNumberMaster = (state) => state.numberMaster;
const getBettingTypeMaster = (state) => state.bettingTypeMaster;
const getResult = (state) => state.resultData;
const getTodayBetting = (state) => state.todayBetting;
const getCurrentBetting = (state) => state.currentBetting;
const getCreateBetting = (state) => state.createBetting;
const getWeight = (state) => state.weight;
const getButton = (state) => state.button;
const getVdoLinks = (state) => state.vdolinks;
const getDialogBetting = (state) => state.dialog_betting;
const getLoadingTodayReport = (state) => state.loadingReport;
const getCountdown = (state) => state.countDown;
const getIsReport = (state) => state.isReport;
const getLoading = (state) => state.loading_betting;
const getErrorMessage = (state) => state.errorMessage;

// Tiger Dragon
const getDragonCard = (state) => state.dragonCard;
const getTigerCard = (state) => state.tigerCard;
// Baccarat
const getPlayerCard1 = (state) => state.playerCard1;
const getPlayerCard2 = (state) => state.playerCard2;
const getPlayerCard3 = (state) => state.playerCard3;
const getBankerCard1 = (state) => state.bankerCard1;
const getBankerCard2 = (state) => state.bankerCard2;
const getBankerCard3 = (state) => state.bankerCard3;
const getPlayerCardValue1 = (state) => state.playerCardValue1;
const getPlayerCardValue2 = (state) => state.playerCardValue2;
const getPlayerCardValue3 = (state) => state.playerCardValue3;
const getBankerCardValue1 = (state) => state.bankerCardValue1;
const getBankerCardValue2 = (state) => state.bankerCardValue2;
const getBankerCardValue3 = (state) => state.bankerCardValue3;


const getBankerPoint = (state) => state.totalB;
const getPlayerPoint = (state) => state.totalP;
//lotto
const getListLotto = (state) => state.listLotto;
const getTotalLotto = (state) => state.totalLotto;
// klaklouk
const getListKlaklouk = (state) => state.listKlaklouk;
// abong
const getListAbong = (state) => state.listAbong;
//yuki
const getListYuki = (state) => state.listYuki;
// Taisiv
const getListTaiSiv = (state) => state.listTaiSiv;
// fantan
const getListFantan = (state) => state.listFantan;
// betting coin
const getIsSelectedCoin = (state) => state.isSeletedCoin;
const getClientTime = (state) => state.clientTime;
const getCurrentGameResult = (state) => state.currentGameResult
const getKlakloukClearStart = (state) => state.klakloukClearStart
const getClientRandom = (state) => state.clientRandom
const getSelectedBtn = (state) => state.selectedBtn;
const getSelectedDragonButton = (state) => state.selectedBtnDragon;
const getSelectedCoin = (state) => state.selectedCoin;

const getFirstCard = (state) => state.firstCard;
const getSecondCard = (state) => state.secondCard;
const getBackCard = (state) => state.backCard;
const getCard = (state) => state.sendCard;
const getClientCard = (state) => state.clientCard;

export default {
    getClientCard,
    getCard,
    getBackCard,
    getKlakloukClearStart,
    getClientRandom,
    getCurrentGameResult,
    getClientTime,
    getSelectedBtn,
    getSelectedCoin,
    getErrorMessage,
    getLoading,
    getAmountMin,
    getAmountMax,
    getTotalLotto,
    getListLotto,
    getChannel,
    getChannelStatus,
    getNumberMaster,
    getResult,
    getTodayBetting,
    getCurrentBetting,
    getCreateBetting,
    getBettingTypeMaster,
    getWeight,
    getButton,
    getVdoLinks,
    getDialogBetting,
    getLoadingTodayReport,
    getCountdown,
    //Tiger Dragon
    getDragonCard,
    getTigerCard,
    // Baccarat
    getPlayerCard1,
    getPlayerCard2,
    getPlayerCard3,
    getBankerCard1,
    getBankerCard2,
    getBankerCard3,
    getPlayerCardValue1,
    getPlayerCardValue2,
    getPlayerCardValue3,
    getBankerCardValue1,
    getBankerCardValue2,
    getBankerCardValue3,
    getBankerPoint,
    getPlayerPoint,
    // klaklouk
    getListKlaklouk,
    // abong
    getListAbong,
    getListYuki,
    getIsReport,
    getListTaiSiv,
    getListFantan,
    // betting coin
    getIsSelectedCoin,
    getSelectedDragonButton,
    getFirstCard,
    getSecondCard,
};