<template>
  <v-container id="main">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-img
          :lazy-src="require('~public_image/a_each_game/taixiu/taixiu.jpg')"
          :src="require('~public_image/a_each_game/taixiu/taixiu.jpg')"
        >
          <machineChannelInfo :passGameName="gameName" />
          <div id="my-wraper">
       
            <div class="game-tx-box game-tx-box-center-img px-2">
              <v-img
                id="tx-card-animation"
                :src="card"
                style="width: 40%"
              ></v-img>
            </div>
            <table
              class="tb-tx-game"
              style="color: #ffffff; text-align: center; font-weight: bold"
            >
              <tr>
                <td
                  class="table-tr-td"
                  style="position: relative;"
                  :class="
                    getClientCard && getClientCard.value < 7
                      ? 'blink-s-taixiu'
                      : ''
                  "
                >
                  <h4>SMALL</h4>
                  <h5>1:1.95</h5>
                  <div class="rx-kl-item">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[0]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                 
                </td>
                <td
                
                  style="
                
                    color: #ffffff;
                    text-align: center;
                    font-weight: bold;
                    vertical-align: bottom !important;
                  "
                >
                  <h6>A-K = 12</h6>
                  <h6>Big = 1:1.95</h6>
                  <h6>Small = 1:1.95</h6>
                </td>
                <td
                  class="table-tr-td"
                  style="position: relative;"
                  :class="
                    getClientCard && getClientCard.value > 7
                      ? 'blink-b-taixiu'
                      : ''
                  "
                >
                  <h4>BIG</h4>
                  <h5>1:1.95</h5>
                  <div class="rx-kl-item">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[1]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                </td>
              </tr>
            </table>
            <table class="tb-tx-game">
              <tr>
                <td
                  class="table-tr-td"
                  style="position: relative;"
                  :class="
                    getClientCard && getClientCard.value == 1
                      ? 'blink-s-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[2]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  A
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 2
                      ? 'blink-s-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[3]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  2
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 3
                      ? 'blink-s-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[4]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  3
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 4
                      ? 'blink-s-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[5]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  4
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 5
                      ? 'blink-s-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[6]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  5
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 6
                      ? 'blink-s-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[7]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  6
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 7
                      ? 'blink-g-taixiu'
                      : ''
                  "
                  style="color: greenyellow"
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[8]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  7
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 8
                      ? 'blink-b-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[9]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  8
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 9
                      ? 'blink-b-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[10]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  9
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 10
                      ? 'blink-b-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[11]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  10
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 11
                      ? 'blink-b-taixiu'
                      : ''
                  "
                  style="padding: 0 22px"
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[12]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  J
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 12
                      ? 'blink-b-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[13]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  Q
                </td>
                <td
                  class="table-tr-td"
                  :class="
                    getClientCard && getClientCard.value == 13
                      ? 'blink-b-taixiu'
                      : ''
                  "
                >
                <div class="rx-kl-item-small">
                    <!-- <v-img v-if="getDdBetting.bettingid == passBettingTypeMaster[14]._id" 
                  :src="FindCoinOnGameContent(getBettingAmount)"
                    >
                    </v-img> -->
                  </div>
                  K
                </td>
              </tr>
            </table>
          </div>
        </v-img>
        <div class="td-case-x">
              <div class="td-case-taixiu td-x-hidden"
                :style="{ backgroundImage: 'url(\'' + 'images/a_game_access_global/cards/back_card.png' + '\')' }">
                <img id="f-result-x">
              </div>
              <div class="td-case">
              </div>
            </div>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>

import "~css/machine_game/taixiu/taixiu_shared_style.css";
import "~css/machine_game/taixiu/screen_max_480.css";
import "~css/machine_game/taixiu/screen_min_481.css";
import "~css/machine_game/taixiu/screen_min_768.css";
import "~css/machine_game/taixiu/screen_min_993.css";
import "~css/machine_game/taixiu/screen_min_1025.css";
import "~css/machine_game/taixiu/screen_min_1281.css";
import "~css/machine_game/taixiu/screen_min_1441.css";
import "~css/machine_game/taixiu/screen_min_1661.css";
import "~css/machine_game/taixiu/screen_min_1824.css";
import machineChannelInfo from "~global_components/component_machine_game/machineChannelInfo.vue";
import { mapGetters } from "vuex";
import clickMixin from "~mixin/MX_ShareFunctionCollection.js";
import $ from "jquery";
export default {
  components: {
    machineChannelInfo,
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s |  TAIXIU`
    },
  data() {
    return {
      gameName: "taixiu",
      card: "images/a_game_access_global/cards/back_card.png",
      mixins: [clickMixin],
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      getBettingAmount: "getBettingAmount",
      getDdBetting: "getBettingDdAmount",
    }),
    ...mapGetters("$_dashboard_live", {
      getSelectedBtn: "getSelectedBtn",
      getCurrentGameResult: "getCurrentGameResult",
      getClientRandom: "getClientRandom",
      channelObj: "getChannel",
      getFirstCard: "getFirstCard",
      getSecondCard: "getSecondCard",
      getClientCard: "getClientCard",
      getClearStart: "getKlakloukClearStart",
      passBettingTypeMaster: "getBettingTypeMaster",
    }),
  },
  watch: {
    getFirstCard: function () {
      if (this.getFirstCard && this.getFirstCard.barcode) {
        this.tryFirstCard()
      }
    },
    getSecondCard: function () {
      if (this.getSecondCard && this.getFirstCard.barcode) {
        this.trySecondCard()
      }
    },
    getBackCard: function (newVal) {
      if (newVal) {
        this._playSoundTakeCard();
        this.myCard();
      }
    },
    getClientCard: function () {
      if(this.getClientCard && this.getClientCard.name ){
        this.card = require("~public_image/a_game_access_global/cards_animation/" +
        this.getClientCard.name +
        ".gif");
      }

    },
    getClearStart: function () {
      this.removeCard();
    },
  },
  methods: {
    tryFirstCard(){
      this.dtStopResult();
        this._playSoundTakeCard();
        $('.td-case-taixiu').removeClass('animate__animated');
        $('.td-case-taixiu').removeClass('animate__fadeOutTopLeft');
        document
        .getElementById("tx-card-animation")
        .classList.add("animate__animated");
      document
        .getElementById("tx-card-animation")
        .classList.add("animate__fadeOutTopLeft");
        this.card = "images/a_game_access_global/cards/back_card.png";
        this.__getFirstCard(require("~public_image/a_game_access_global/cards_big/" + this.getFirstCard.barcode + '.png'));
    },
    async trySecondCard(){
      await this.__xCasetSecondCard(this.getSecondCard.count, require("~public_image/a_game_access_global/cards/back_card.png"))
  
    },
    dtStopResult(){
        this.card = '';
        document
        .getElementById("tx-card-animation")
        .classList.remove("card-animation");
      },
      stopBurnCard(){
        this.__clearCase()
        $('.td-case-taixiu').addClass('animate__animated');
        $('.td-case-taixiu').addClass('animate__fadeOutTopLeft');
        $('.tb-tx-game').removeClass('hide-game');
      },
    __getFirstCard(x = null) {
      $('#f-result-x').hide();
      $('.td-case-taixiu').removeClass('td-x-hidden');
      $('.td-case-taixiu').addClass('td-case-taixiu-a');
      var clearCard = function () {
        $('.td-case-taixiu').removeClass('td-case-taixiu-a');
        $('#f-result-x').attr('src', x);
        $('#f-result-x').show(10);
      }
      setTimeout(clearCard, 2000);
    },
    async myCard() {
      this.stopBurnCard();
      await document
        .getElementById("tx-card-animation")
        .classList.remove("animate__fadeOutTopLeft");
         this.card = "images/a_game_access_global/cards/back_card.png";
      document
        .getElementById("tx-card-animation")
        .classList.add("card-animation");
    },
    async removeCard() {
      this.stopBurnCard();
      document
        .getElementById("tx-card-animation")
        .classList.remove("card-animation");
      // document.getElementById("tx-card-animation").classList.remove("animate__animated  animate__fadeOutTopLeft");
      document
        .getElementById("tx-card-animation")
        .classList.add("animate__animated");
      document
        .getElementById("tx-card-animation")
        .classList.add("animate__fadeOutTopLeft");
        this.card = "images/a_game_access_global/cards/back_card.png";
      setTimeout(function () {
        document
            .getElementById("tx-card-animation")
            .classList.remove("animate__fadeOutTopLeft");
        document
            .getElementById("tx-card-animation")
            .classList.add("card-animation");
      }, 1000);
    },
    __clearCase() {
      // $('#f-result-x').hide();
      $('.td-case-taixiu').addClass('td-x-hidden');
      $('.td-case').html('');
    },
    __xCasetSecondCard(x = null, y = null) {
      $('.tb-tx-game').addClass('hide-game');
      this.dtStopResult();
      if(this.getBackCard) this.__clearCase();
      $('.td-case').html('');
      $('.td-case-x').css('display', 'block');
      var i = 1,
        action = function () {
          var rxc = 'xrxkf' + (x.toString()) + ((i - 1).toString());
          var rxca = 'xrxkf' + (x.toString()) + ((i - 1).toString()) + '-a';
          var xc = 'xrxkf' + (x.toString()) + (i.toString());
          var xca = 'xrxkf' + (x.toString()) + (i.toString()) + '-a';
          $('.td-case').find('.' + rxc).removeClass(rxca);
          $('.td-case').append('<div class="' + xc + '"></div>');
          $('.td-case').find('.' + xc).css("background-image", "url(" + y + ")");
          $('.td-case').find('.' + xc).addClass(xca);
          let audio = new Audio("/sound/take_card.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
          i++;
          if (i <= (x + 1)) {
            setTimeout(action, 500);
          }
   
        };
      action();
      setTimeout(this.stopBurnCard, parseInt(this.getSecondCard.count * 800));
    },
  },
};
</script>
