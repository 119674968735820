export default {
    methods: {
      _disconnectSocketLottery(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
      },
      _connectSocketLottery(){
        if(this.$socket){
          this.$socket.disconnect()
          this.$socket.removeAllListeners()
        }
        this.$connectSocket();
        this.listenerSocketLottery()
      },
      listenerSocketLottery() {
        this.$socket.on("client-random", (data) => {
          if(data){
              this.fromFinalToResult(data[data.length - 1]);
              this.result_a = data;
            }

        });
        // socket get time,  is betting, is coldown
        this.$socket.on("client-time", (data) => {
          if (data) {
            // define client time to display count down time at play zone
            this.UPDATE_CLIENT_TIME(data);
  
            // play audio sound
            if (data.time == 118) {
              this._playSoundBetDown();
            }
            if (data.time < 11 && data.time != 0) this._playSoundTing();
            if (data.time == 0) this._playSoundGoodLuck();
          }
        });
        // get new game
        this.$socket.on("client-new-game", (data) => {
          if (data) {
            this.clientRandom = [];
            this.bonus = [];
            this.CLEAR_TO_DEFAULT();
            this._initialData();
            this.fetchUserInfo();
            this.result_a = ['??', '??', '??', "??", '??'];
            this.ass_a = null;
          }
        });
        // get game result and bonus
        this.$socket.on("client-result", (data) => {
          if (data) {
            this.clientResult = data;
          }
        });
        this.$socket.on("client-bonus", (data) => {
          if (data) {
            this.bonus = data;
            if (this.bonus.is_bonus) {
              // this.$confetti.start();
              this._playSoundCongratulation();
            }
          }
        });
        this.$socket.on("client-reset", (data) => {
          if (data) {
              // this.$confetti.start();
              this.CLEAR_TO_DEFAULT();
              this.result_a = ['??', '??', '??', "??", '??'];
              this.ass_a = null;
          }
        });
      },
    }
}