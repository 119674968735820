<template>
  <div>
    <v-col col-12 class="klaklouk-btn-bet-row pt-0 pr-0 mt-0">
      <div v-for="(j, index) in bettingTypeMaster" :key="j._id" class="klaklouk-btn-bet-item mt-0"
        @click="_selectButton(j._id, j.name)"
        :class="__getStautsClass(j.name)"
        >
        <div class="range-betting-payout font-weight-bold pt-0 mt-0"
          :class="j.color == 'cir-red' ? 'banker-color' : 'player-color'">
          <img :src="_detectImg(index)" class="betting-coin-range" />
        </div>
        <div class="text-display-payout">
          {{ j ? `1 : ${j.payout_display}` : "" }}
        </div>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Mixin from "~mixin/MX_ShareFunctionCollection.js";

export default {
  data() {
    return {
      mixins: [Mixin],
      bettingMoney: 0,
      selectedButton: {},
      activeItem: null,
      selectedBtnClassMeron: false,
      selectedBtnClassTie: false,
      selectedBtnClassWala: false,
      selectedCoin: false,
    };
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      channel: "getChannel",
      channelStatus: "getChannelStatus",
      bettingTypeMaster: "getBettingTypeMaster",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
      getBettingAmount: "getBettingAmount",
      getBettingKlakloukAmount: "getBettingKlakloukAmount",
    }),
  },
  methods: {
    ...mapMutations("$_modules", [
      "UPDATE_BETTING_KLAKLOUK_AMOUNT",
      "CLEAR_BETTING_KLAKLOUK_AMOUNT",
    ]),
    _selectButton(selectedButtonId, name = null) {
      /*let submitBettingData = {
        type_id: selectedButtonId,
        amount: this.getBettingAmount ,
      };
      this.$emit("sentSubmitBetting", submitBettingData);
      */
      this.CLEAR_BETTING_KLAKLOUK_AMOUNT();
      this.UPDATE_BETTING_KLAKLOUK_AMOUNT([name, this.getBettingAmount, selectedButtonId]);
    },
    ...mapActions("$_dashboard", ["fetchCreateBetting"]),
    ...mapMutations("$_modules", ["CLEAR_BETTING_AMOUNT"]),
    __getStautsClass(name) {
      //var gourds="gourd";
      //console.log(this.getBettingKlakloukAmount[gourds].display)
      switch (name) {
        case "ឃ្លោក":
          if (this.getBettingKlakloukAmount.gourd.display == true) { return 'klaklouk-btn-bet-item-active' } else { return '' }
        case "មាន់":
          if (this.getBettingKlakloukAmount.chicken.display == true) { return 'klaklouk-btn-bet-item-active' } else { return '' }

        case "បង្កង":
          if (this.getBettingKlakloukAmount.lobster.display == true) { return 'klaklouk-btn-bet-item-active' } else { return '' }

        case "ក្ដាម":
          if (this.getBettingKlakloukAmount.crab.display == true) { return 'klaklouk-btn-bet-item-active' } else { return '' }

        case "ត្រី":
          if (this.getBettingKlakloukAmount.fish.display == true) { return 'klaklouk-btn-bet-item-active' } else { return '' }

        case "ខ្លា":
          if (this.getBettingKlakloukAmount.tiger.display == true) { return 'klaklouk-btn-bet-item-active' } else { return '' }

        default:
          return ''
      }
    },
  },
};
</script>

<style>
.btn-switch-channel:has(.to-hidden) {
  display: none !important;
}

.video-section {
  position: relative;
  overflow: hidden;
  background-color: #012d98
}

.show-on-mobile {
  display: none;
}

@media (max-width: 768px) {
  .show-on-mobile {
    display: block;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.klaklouk-btn-bet-row {
  text-align: center;
  display: grid !important;
  grid-template-columns: auto auto auto;
  gap: 0px;
  padding-left: 0px !important;
}

.klaklouk-btn-bet-item {
  border: 1px solid #f78e2b6c;
  background: #739cce36;
  padding-top: 0px;

  margin: 6px 8px 6px 8px;
  border-radius: 6px;
}

.banker-color {
  color: #ff0e1a;
}

.player-color {
  color: blue;
}

.range-betting-payout {
  text-align: center;
  margin-top: -2px;
  font-size: 2rem;
  padding-top: 10px;
  text-shadow: 0 1px 0 #ffffff, 0 1px 0 #ffffff, 0 1px 0 #ffffff,
    0 1px 0 #ffffff, 0 1px 0 #ffffff, 0 3px 1px rgba(77, 74, 74, 0.1),
    0 0 3px rgba(133, 122, 122, 0.1), 0 1px 3px rgba(157, 148, 148, 0.3),
    0 3px 1px rgba(177, 172, 172, 0.2), 0 1px 3px rgba(160, 156, 156, 0.25),
    0 3px 3px rgba(177, 176, 176, 0.2), 0 3px 1px rgba(165, 162, 162, 0.15);
}

.klaklouk-btn-bet-item:hover {
  cursor: pointer;
}

.text-display-payout {
  text-align: center;
  font-size: 1.3rem !important;
  font-weight: 600;
  color: rgb(187, 154, 47) !important;
  padding-top: 6px;
  text-shadow: 0px 1px rgb(214, 103, 103);
}

.betting-coin-range {
  margin-top: 0px;
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 5px;
  border-radius: 12px !important;
}

.klaklouk-btn-bet-item-active {
  background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%) !important;
}
</style>