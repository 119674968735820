const reportBetting = [
	{
		text: 'date_time',
		value: 'time',
		sortable: false,
		align: 'center',
		width: 'min-width:170px;width:14%'
	},
	{
		text: 'channel',
		align: 'center',
		sortable: false,
		value: 'channel_type',
		width: 'min-width:170px; width:14%'
	},
	{
		text: 'no',
		align: 'center',
		sortable: false,
		value: 'no',
		width: 'min-width:130px;width:13%'
	},
	{
		text: 'bet_type',
		align: 'center',
		sortable: false,
		value: 'bet_name',
		width: 'min-width:170px;width:15%'
	},

	{
		text: 'total_amount', value: 'total_amount', sortable: false, align: 'center',
		width: 'min-width:170px;width:15%'
	},
	{
		text: 'cast', value: 'cast', sortable: false, align: 'center',
		width: 'min-width:130px;width:15%'
	},
	{
		text: 'amount_win', value: 'amount_win', sortable: false, align: 'center',
		width: 'min-width:130px;width:15%'
	},
];

export default reportBetting;