<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>

import "@/assets/css/font.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/style.css";

import storeModules from "@/modules/_store";
import storeAuth from "@/modules/FolderAccounts/login/_store";
import storeCockfight from "@/modules/FolderGames/Cockfight/_store";
import storeDashboardLive from "@/modules/FolderGames/MachineGames/SubGameInMachineGame/_store";
import storeCasinoLive from "@/modules/FolderGames/CasinoLive/_store";
import storeMyInfo from "@/modules/FolderAccounts/myInfo/_store";
import storeDeposit from "@/modules/FolderStatement/deposit/_store";
import storeWithdraw from "@/modules/FolderStatement/withdraw/_store";
import storeAllBetting from "@/modules/FolderStatement/statement/_store";
import storeMoneyRequest from "@/modules/FolderTransaction/moneyrequest/_store";
import storeWithdrawRequest from "@/modules/FolderTransaction/depositrequest/_store";
import storeDepositRequest from "@/modules/FolderTransaction/withdrawrequest/_store";
import storeResult from "@/modules/FolderStatement/resultStatement/_store";
import storeMachine from "@/modules/FolderGames/LotteryMenu/Lotto/_store";
import storeMachineYuki from "@/modules/FolderGames/LotteryMenu/yuki/_store";
import storeMachineLottery from "@/modules/FolderGames/LotteryMenu/Lottery/_store";
import storeMachineGames from "@/modules/FolderGames/MachineGames/_store";
import storeCockfightAndCasinoLive from "@/modules/FolderGames/CockAndCasinoLive/_store";

import storeLottery3d from "@/modules/FolderGames/LotteryMenu/Lottery3d/_store";
import storeKeno from "@/modules/FolderGames/LotteryMenu/Keno/_store";
import storeLotteryVn from "@/modules/FolderGames/LotteryMenu/LotteryVN/_store";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'SBC2888',

  },
  created() {
    this.registerStore();
  },
  methods: {
    registerStore() {
      const LOTTERY_3D = "$_lottery3d";
      const MODULES = "$_modules";
      const AUTH = "$_auth";
      const COCKFIGHT = "$_dashboard";
      const CASINOLIVE = "$_casino_live";
      const DASHBOARDLIVE = "$_dashboard_live";
      const MYINFO = "$_myinfo";
      const DEPOSIT = "$_deposit";
      const WITHDRAW = "$_withdraw";
      const ALLBETTING = "$_allstatement";
      const RESULT = "$_result";
      const MONEYREQUEST = "$_moneyrequest";
      const DEPOSITREQUEST = "$_depositrequest";
      const WITHDRAWREQUEST = "$_withdrawrequest";
      const MACHINE = "$_machine";
      const MACHINEYUKI = "$_machine_yuki";
      const MACHINE_LOTTERY = "$_machine_lottery";
      const MACHINE_GAMES = "$_machine_games";
      const COCK_FIGHT_AND_CASINO_LIVE = "$_cockfight_casino_live";
      const KENO = "$_keno";
      const LOTTERYVN = "$_lottery_vn";

      if (!(LOTTERYVN in this.$store._modules.root._children)) {
        this.$store.registerModule(LOTTERYVN, storeLotteryVn);
      }
      if (!(KENO in this.$store._modules.root._children)) {
        this.$store.registerModule(KENO, storeKeno);
      }
      if (!(LOTTERY_3D in this.$store._modules.root._children)) {
        this.$store.registerModule(LOTTERY_3D, storeLottery3d);
      }
      if (!(COCK_FIGHT_AND_CASINO_LIVE in this.$store._modules.root._children)) {
        this.$store.registerModule(COCK_FIGHT_AND_CASINO_LIVE, storeCockfightAndCasinoLive);
      }
      if (!(MACHINE in this.$store._modules.root._children)) {
        this.$store.registerModule(MACHINE, storeMachine);
      }
      if (!(MACHINEYUKI in this.$store._modules.root._children)) {
        this.$store.registerModule(MACHINEYUKI, storeMachineYuki);
      }
      if (!(MACHINE_LOTTERY in this.$store._modules.root._children)) {
        this.$store.registerModule(MACHINE_LOTTERY, storeMachineLottery);
      }
      if (!(MODULES in this.$store._modules.root._children)) {
        this.$store.registerModule(MODULES, storeModules);
      }
      if (!(AUTH in this.$store._modules.root._children)) {
        this.$store.registerModule(AUTH, storeAuth);
      }
      if (!(COCKFIGHT in this.$store._modules.root._children)) {
        this.$store.registerModule(COCKFIGHT, storeCockfight);
      }
      if (!(CASINOLIVE in this.$store._modules.root._children)) {
        this.$store.registerModule(CASINOLIVE, storeCasinoLive);
      }
      if (!(DASHBOARDLIVE in this.$store._modules.root._children)) {
        this.$store.registerModule(DASHBOARDLIVE, storeDashboardLive);
      }
      if (!(MYINFO in this.$store._modules.root._children)) {
        this.$store.registerModule(MYINFO, storeMyInfo);
      }
      if (!(DEPOSIT in this.$store._modules.root._children)) {
        this.$store.registerModule(DEPOSIT, storeDeposit);
      }
      if (!(WITHDRAW in this.$store._modules.root._children)) {
        this.$store.registerModule(WITHDRAW, storeWithdraw);
      }
      if (!(ALLBETTING in this.$store._modules.root._children)) {
        this.$store.registerModule(ALLBETTING, storeAllBetting);
      }
      if (!(RESULT in this.$store._modules.root._children)) {
        this.$store.registerModule(RESULT, storeResult);
      }
      if (!(MONEYREQUEST in this.$store._modules.root._children)) {
        this.$store.registerModule(MONEYREQUEST, storeMoneyRequest);
      }
      if (!(DEPOSITREQUEST in this.$store._modules.root._children)) {
        this.$store.registerModule(DEPOSITREQUEST, storeDepositRequest);
      }
      if (!(WITHDRAWREQUEST in this.$store._modules.root._children)) {
        this.$store.registerModule(WITHDRAWREQUEST, storeWithdrawRequest);
      }
      if (!(MACHINE_GAMES in this.$store._modules.root._children)) {
        this.$store.registerModule(MACHINE_GAMES, storeMachineGames);
      }
    },
  },
}
</script>
