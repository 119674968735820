import machine from '@/modules/FolderGames/LotteryMenu/Lotto';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/LOTTO',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: machine,
                    name: 'LOTTO'
                }
            ]
    }
];

export default routes;