<template>
  <v-container id="main">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-img :lazy-src="require('~public_image/a_each_game/sicbo/Sicbo-UI.jpg')"
         :src="require('~public_image/a_each_game/sicbo/Sicbo-UI.jpg')">
         <machineChannelInfo :passGameName="gameName" />
          <div class="sicbo-container" v-if="bettingTypeMaster &&  bettingTypeMaster[0]">
            <table class="tb-sicbo-game">
              <tr>
                <td class="tb-sicbo-tr-td " rowspan="2"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : '' : ''">
                  <h3 v-if="bettingTypeMaster">{{ $t('title.sicbo_small') }}</h3>
                  <b class="sicbo-text1-xxx" style="color:#15ff00">{{ bettingTypeMaster &&
                    bettingTypeMaster[0] ? bettingTypeMaster[0].start_number : '' }}:{{ bettingTypeMaster &&  bettingTypeMaster[0] ?
    bettingTypeMaster[0].end_number : '' }}</b>
                  <span class="td-small sicbo-text2-xxx">{{ bettingTypeMaster &&  bettingTypeMaster[0] ? bettingTypeMaster[0].payout_display :
                    'loading...' }}</span>

                  <!-- <div class="sicbo-coin-display-xx-w" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[0]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 3 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[4]">{{ bettingTypeMaster[4].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[4]">{{ bettingTypeMaster[4].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if=" bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[4]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 4 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[5]">{{ bettingTypeMaster[5].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[5]">{{ bettingTypeMaster[5].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[5]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 11 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[12]">{{ bettingTypeMaster[12].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[12]">{{ bettingTypeMaster[12].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[12]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 12 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[13]">{{ bettingTypeMaster[13].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[13]">{{ bettingTypeMaster[13].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[13]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td" rowspan="2"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.result_small_big.name == 'big' ? 'blink-s-sicbo-b' : '' : ''">
                  <h4 v-if="bettingTypeMaster">{{ $t('title.sicbo_large') }}</h4>
                  <b class="sicbo-text1-xxx" v-if="bettingTypeMaster  && bettingTypeMaster[1]">{{ bettingTypeMaster[1].start_number
                  }}:{{ bettingTypeMaster[1].end_number }}</b>

                  <span class="td-big sicbo-text2-xxx" v-if="bettingTypeMaster[1]  && bettingTypeMaster[1]">{{ bettingTypeMaster[1].payout_display
                  }}</span>

                  <!-- <div class="sicbo-coin-display-xx-w" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[1]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
              </tr>
              <tr>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 5 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[6]">{{ bettingTypeMaster[6].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[6]">{{ bettingTypeMaster[6].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[6]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 6 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[7]">{{ bettingTypeMaster[7].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[7]">{{ bettingTypeMaster[7].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[7]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 13 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[14]">{{ bettingTypeMaster[14].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[14]">{{ bettingTypeMaster[14].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[14]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 14 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[15]">{{ bettingTypeMaster[15].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[15]">{{ bettingTypeMaster[15].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[15]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
              </tr>

              <tr>
                <td class="tb-sicbo-tr-td" rowspan="2"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.result_odd_even.name == 'odd' ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h3 v-if="bettingTypeMaster">{{ $t('title.sicbo_odd') }}</h3>
                  <span class="td-odd sicbo-text2-xxx" v-if="bettingTypeMaster  && bettingTypeMaster[2]">{{ bettingTypeMaster[2].payout_display
                  }}</span>
                  <!-- <div class="sicbo-coin-display-xx-w" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[2]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 7 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[8]">{{ bettingTypeMaster[8].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[8]">{{ bettingTypeMaster[8].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[8]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 8 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[9]">{{ bettingTypeMaster[9].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[9]">{{ bettingTypeMaster[9].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[9]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 15 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[16]">{{ bettingTypeMaster[16].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[16]">{{ bettingTypeMaster[16].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="getSicboBetting.bettingid == bettingTypeMaster[16]._id">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 16 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[17]">{{ bettingTypeMaster[17].name }}</h5>
                  <h6 v-if="bettingTypeMaster  && bettingTypeMaster[17]">{{ bettingTypeMaster[17].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[17]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td" rowspan="2"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.result_odd_even.name == 'even' ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h4 v-if="bettingTypeMaster">{{ $t('title.sicbo_even') }}</h4>
                  <span class="td-even sicbo-text2-xxx" v-if="bettingTypeMaster &&  bettingTypeMaster[3]">{{ bettingTypeMaster[3].payout_display
                  }}</span>
                  <!-- <div class="sicbo-coin-display-xx-w" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[3]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
              </tr>
              <tr>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 9 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster && bettingTypeMaster[10]">{{ bettingTypeMaster[10].name }}</h5>
                  <h6 v-if="bettingTypeMaster && bettingTypeMaster[10]">{{ bettingTypeMaster[10].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[10]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 10 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster && bettingTypeMaster[11]">{{ bettingTypeMaster[11].name }}</h5>
                  <h6 v-if="bettingTypeMaster && bettingTypeMaster[11]">{{ bettingTypeMaster[11].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[11]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 17 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster  && bettingTypeMaster[18]">{{ bettingTypeMaster[18].name }}</h5>
                  <h6 v-if="bettingTypeMaster && bettingTypeMaster[18]">{{ bettingTypeMaster[18].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[18]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
                <td class="tb-sicbo-tr-td"
                  :class="getCurrentGameResult && getCurrentGameResult.result_small_big && getCurrentGameResult.result_small_big.name ? getCurrentGameResult.total_random == 18 ? getCurrentGameResult.result_small_big.name == 'small' ? 'blink-s-sicbo' : 'blink-s-sicbo-b' : '' : ''">
                  <h5 v-if="bettingTypeMaster && bettingTypeMaster[19]">{{ bettingTypeMaster[19].name }}</h5>
                  <h6 v-if="bettingTypeMaster && bettingTypeMaster[19]">{{ bettingTypeMaster[19].payout_display }}</h6>
                  <!-- <div class="sicbo-coin-display-xx" v-if="bettingTypeMaster && (getSicboBetting.bettingid == bettingTypeMaster[19]._id)">
                    <div class="sicbo-coin-display-xx-content"
                      :style="{ backgroundImage: 'url(\'' + 'images/coins/red.png' + '\')' }">
                      <span>{{ getSicboBetting.amount }}</span>
                    </div>
                  </div> -->
                </td>
              </tr>
            </table>
          </div>



          <span id="sicbo-result-x-ri">
          </span>

        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "~css/machine_game/sicbo/sicbo.css";
import machineChannelInfo from "~global_components/component_machine_game/machineChannelInfo.vue";
import { mapGetters } from "vuex";
import $ from "jquery";
export default {
  components: {
    machineChannelInfo,
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: `%s |  SICBO`
    },
  data() {
    return {
      gameName : "Sicbo"
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      getBettingAmount: "getBettingAmount",
      getSicboBetting: 'getBettingSicboAmount',
    }),
    ...mapGetters("$_dashboard_live", {
      getSelectedBtn: "getSelectedBtn",
      getSelectedCoin: "getSelectedCoin",
      getCurrentGameResult: "getCurrentGameResult",
      getClientTime: "getClientTime",
      getClientRandom: "getClientRandom",
      amountMin: "getAmountMin",
      amountMax: "getAmountMax",
      channelObj: "getChannel",
      getPlayerCard1: "getPlayerCard1",
      getPlayerCard2: "getPlayerCard2",
      getPlayerCard3: "getPlayerCard3",
      getBankerCard1: "getBankerCard1",
      getBankerCard2: "getBankerCard2",
      getBankerCard3: "getBankerCard3",
      getBankerPoint: "getBankerPoint",
      getPlayerPoint: "getPlayerPoint",
      getFirstCard: "getFirstCard",
      getSecondCard: "getSecondCard",
      getClearStart: "getKlakloukClearStart",
      bettingTypeMaster: "getBettingTypeMaster",
    }),
  },
  watch: {
    getCurrentGameResult: function (newVal, oldVal) {
      if (newVal != oldVal && this.getCurrentGameResult != null && this.getClientTime.is_countdown == true) {
        this.__xvxOpen();
      }
    },
    getClearStart: function (newVal) {
      if (newVal) {

        this.__xvxStart();
      }
      //this.__xvxStart();
      // console.log("========xxxxcall startxxxxxx=======")

    }
  },

  mounted() {

    if ($('body').find('#sicbo-result-x-ri').length == 0) {
      this.__xvxStart();
    }
    /*
    else{
      console.log("========mounted dd =======")
      
    }*/

  },


  methods: {
    dtStartCurrentGameResult() {
      $('.td-win-lose-result-x').removeClass("td-x-hidden");
    },
    myCard() {
      document.getElementById("cardAnimation").classList.add("card-animation");
    },
    removeCard() {
      document.getElementById("cardAnimation").classList.remove("card-animation");
    },

    __xvxStart() {
      var action = function () {
        var ck = $('body').find('#sicbo-result-x-ri');
        if (ck.length > 0) {
          let audio = new Audio("/sound/dice-shake-.mp3");
          let promise = audio.play();
          if (promise) {
            promise.catch(function () { });
          }
          $('#sicbo-result-x-ri').html('');
          $('#sicbo-result-x-ri').html('<div class="sicbo-xri-plate"><div class="sicbo-xri-body"><div class="sicbo-xri-on-plate"></div></div></div>');
          $('#sicbo-result-x-ri').find('.sicbo-xri-plate').addClass('sicbo-xri-plate-an');
          //console.log("============__xvxStart==========")
        } else {
          //console.log("============wait__xvxStart==========")
          setTimeout(action, 200);
        }

      };
      action();
    },

    __xvxOpen() {
      //console.log("============__xvxOpen==========")
      $('#sicbo-result-x-ri').find('.sicbo-xri-plate').addClass('sicbo-xri-plate-an-down');
      $('#sicbo-result-x-ri').find('.sicbo-xri-plate').removeClass('sicbo-xri-plate-an');


      setTimeout(() => {
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').find('.sicbo-xri-body').html('<div class="sicbo-xri-item-1">' +
          '</div><div class="sicbo-xri-item-2">' +
          '</div><div class="sicbo-xri-item-3"></div>' +
          '<div class="sicbo-xri-on-plate"></div>');
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').find('.sicbo-xri-body').find('.sicbo-xri-item-1').css('background-image', 'url(' + this._getImgItemsResult(this.getCurrentGameResult.random[0]) + ')');
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').find('.sicbo-xri-body').find('.sicbo-xri-item-2').css('background-image', 'url(' + this._getImgItemsResult(this.getCurrentGameResult.random[1]) + ')');
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').find('.sicbo-xri-body').find('.sicbo-xri-item-3').css('background-image', 'url(' + this._getImgItemsResult(this.getCurrentGameResult.random[2]) + ')');
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').find('.sicbo-xri-body').find('.sicbo-xri-on-plate').addClass('sicbo-xri-plate-open-an-up');
      }, 700)

      setTimeout(() => {
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').find('.sicbo-xri-body').find('.sicbo-xri-on-plate').addClass('sicbo-xri-plate-open-an-down');
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').find('.sicbo-xri-body').find('.sicbo-xri-on-plate').removeClass('sicbo-xri-plate-open-an-up');
      }, 8000)

      setTimeout(() => {
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').find('.sicbo-xri-body').html('<div class="sicbo-xri-on-plate"></div>');
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').addClass('sicbo-xri-plate-an-up');
        $('#sicbo-result-x-ri').find('.sicbo-xri-plate').removeClass('sicbo-xri-plate-an-down');
      }, 8900)


    },

    _getImgItemsResult(x) {
      switch (x) {
        case 1:
          return require("/public/images/a_each_game/sicbo/sicbo_1.png");

        case 2:
          return require("/public/images/a_each_game/sicbo/sicbo_2.png");

        case 3:
          return require("/public/images/a_each_game/sicbo/sicbo_3.png");

        case 4:
          return require("/public/images/a_each_game/sicbo/sicbo_4.png");

        case 5:
          return require("/public/images/a_each_game/sicbo/sicbo_5.png");
        case 6:
          return require("/public/images/a_each_game/sicbo/sicbo_6.png");
        default:
          return "";
      }
    },
    _xplaySoundBang() {
      let audio = new Audio("/sound/dice-shake-.mp3");
      let promise = audio.play();
      //audio.loop=true;
      if (promise) {
        promise.catch(function () { });
      }
    },
  },


};
</script>
<style>
.sicbo-text1-xxx {
  display: block;
  width: 100%;
}

.sicbo-text2-xxx {
  display: block;
  text-align: left;
  position: absolute;
  left: 1.6%;
  bottom: 0;
  top: auto;
}

/*====*/
.tb-sicbo-game tr td {
  position: relative;
}

.sicbo-coin-display-xx-w {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 32%;
  right: 32%;
}

.sicbo-coin-display-xx {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 20%;
  right: 30%;
}

.sicbo-coin-display-xx-content {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  display: flex;
  align-items: center;

}

.sicbo-coin-display-xx-content span {
  color: #212427;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.sicbo-xri-plate {
  position: absolute;
  width: 7vw;
  height: 5vw;
  top: 6%;
  left: 75%;
  background-image: url('~@/assets/images/klaklok/img/Under_Plate.png');
  background-size: 100% 100%;
}

.sicbo-xri-body {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.sicbo-xri-on-plate {
  position: absolute;
  width: 88%;
  height: 88%;
  left: 5%;
  top: 0;
  background-image: url('~@/assets/images/klaklok/img/OnPlate.png');
  background-size: 100% 100%;
}

.sicbo-xri-plate-an {
  animation-name: klupdown;
  animation-duration: 0.7s;
  animation-iteration-count: forwards;
  /*animation: klupdown 0.7s forwards;*/
}

@keyframes klupdown {
  0% {
    top: 0.1%;
  }

  20% {
    top: 9%;
  }

  40% {
    top: 0.1%;
  }

  60% {
    top: 9%;
  }

  80% {
    top: 0.1%;
  }

  100% {
    top: 6%;
    display: none;
  }
}

.sicbo-xri-plate-an-down {
  animation: kldown 0.6s forwards;
}

@keyframes kldown {
  0% {
    top: 6%;
    left: 75%;
    width: 7vw;
    height: 5vw;
  }

  50% {
    top: 35%;
    left: 45%;
    width: 7vw;
    height: 5vw;
  }

  100% {
    top: 35%;
    left: 38%;
    width: 15.5vw;
    height: 9vw;
  }

}

.sicbo-xri-plate-an-up {
  animation: klup 0.6s forwards;
}

@keyframes klup {
  0% {
    top: 35%;
    left: 38%;
    width: 15.5vw;
    height: 9vw;
  }

  50% {
    top: 35%;
    left: 45%;
    width: 7vw;
    height: 5vw;
  }

  100% {
    top: 6%;
    left: 75%;
    width: 7vw;
    height: 5vw;
  }

}

.sicbo-xri-plate-open-an-up {
  animation: klopenup 1s forwards;
}

@keyframes klopenup {
  0% {
    top: 0;
    opacity: 100%;
  }

  50% {
    opacity: 100%;
  }

  100% {
    top: -100%;
    opacity: 0%;
  }
}

.sicbo-xri-plate-open-an-down {
  animation: klopendown 1s forwards;
}

@keyframes klopendown {
  0% {
    top: -100%;
    opacity: 0%;
  }

  50% {
    opacity: 100%;
  }

  100% {
    top: 0;
    opacity: 100%;
  }

}

.sicbo-xri-item-1 {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background-size: 100% 100%;
  left: 5.5vw;
  top: 1.5vw;

}

.sicbo-xri-item-2 {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background-size: 100% 100%;
  left: 4vw;
  top: 4vw;

}

.sicbo-xri-item-3 {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background-size: 100% 100%;
  left: 7.7vw;
  top: 3.5vw;

}



@media screen and (max-width: 1904px) {
  .rx-kl-cion {
    top: 40%
  }
}

@media screen and (max-width:1280px) {
  .rx-kl-cion {
    top: 40%
  }

  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 45px;
    height: 45px;
    bottom: 35%;
    right: 30%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 45px;
    height: 45px;
    bottom: 10%;
    right: 25%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 11px;
  }
}

@media screen and (max-width:982px) {
  .rx-kl-cion {
    top: 40%
  }

  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 45px;
    height: 45px;
    bottom: 28%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 45px;
    height: 45px;
    bottom: -5%;
    right: 20%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 11px;
  }
}

@media screen and (max-width:960px) {
  .rx-kl-cion {
    top: 40%
  }

  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 45px;
    height: 45px;
    bottom: 28%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 45px;
    height: 45px;
    bottom: -10%;
    right: 20%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 11px;
  }
}

@media screen and (max-width:959px) {
  .rx-kl-cion {
    top: 40%
  }
}

@media screen and (max-width:768px) {
  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 28%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10%;
    right: 15%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 30%
  }

  .rx-kl-cion span {
    font-size: .40rem;
    padding-top: 6%;
  }


  .sicbo-xri-plate {
    position: absolute;
    width: 8vw;
    height: 5vw;
    top: 6%;
    left: 73%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 73%;
      width: 8vw;
      height: 5vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

  }

  @keyframes klup {
    0% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 6%;
      left: 73%;
      width: 8vw;
      height: 5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 8.5vw;
    height: 8.5vw;
    left: 13vw;
    top: 1.7vw;
  }

  .sicbo-xri-item-2 {
    width: 8.5vw;
    height: 8.5vw;
    left: 18vw;
    top: 5vw;
  }

  .sicbo-xri-item-3 {
    width: 8.5vw;
    height: 8.5vw;
    left: 7.9vw;
    top: 5vw;
  }


}

@media screen and (max-width:700px) {
  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 28%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10%;
    right: 10%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 26%;
    width: 27%;
    height: 27%;
  }

  .sicbo-xri-plate {
    position: absolute;
    width: 8vw;
    height: 5vw;
    top: 6%;
    left: 73%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 73%;
      width: 8vw;
      height: 5vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

  }

  @keyframes klup {
    0% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 6%;
      left: 73%;
      width: 8vw;
      height: 5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 8.5vw;
    height: 8.5vw;
    left: 13vw;
    top: 1.7vw;
  }

  .sicbo-xri-item-2 {
    width: 8.5vw;
    height: 8.5vw;
    left: 18vw;
    top: 5vw;
  }

  .sicbo-xri-item-3 {
    width: 8.5vw;
    height: 8.5vw;
    left: 7.9vw;
    top: 5vw;
  }

}

@media screen and (max-width:600px) {
  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 28%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10%;
    right: 1%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 30%;
    width: 27%;
    height: 27%;
  }

  .sicbo-xri-plate {
    position: absolute;
    width: 8vw;
    height: 5vw;
    top: 6%;
    left: 73%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 73%;
      width: 8vw;
      height: 5vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

  }

  @keyframes klup {
    0% {
      top: 30%;
      left: 18%;
      width: 33vw;
      height: 17vw;
    }

    50% {
      top: 35%;
      left: 43%;
      width: 8vw;
      height: 5vw;
    }

    100% {
      top: 6%;
      left: 73%;
      width: 8vw;
      height: 5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 8.5vw;
    height: 8.5vw;
    left: 13vw;
    top: 1.7vw;
  }

  .sicbo-xri-item-2 {
    width: 8.5vw;
    height: 8.5vw;
    left: 18vw;
    top: 5vw;
  }

  .sicbo-xri-item-3 {
    width: 8.5vw;
    height: 8.5vw;
    left: 7.9vw;
    top: 5vw;
  }

}

@media screen and (max-width:529px) {
  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 38px;
    height: 38px;
    bottom: 28%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 38px;
    height: 38px;
    bottom: -10%;
    right: 20%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 36%;
    width: 25%;
    height: 25%;
  }

  .rx-kl-cion span {
    font-size: .60rem;
    padding-top: 6%;
  }

  .rx-kl-max p {
    font-size: 1.1vw;
  }

  .rx-kl-min p {
    font-size: 1.1vw;
  }

  .rx-close-open-box span {
    font-size: 1.4rem;
  }

  .rx-kl-game p {
    font-size: 2.3vw;
  }

  .sicbo-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 76%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .sicbo-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .sicbo-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width:450px) {
  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: 28%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: -10%;
    right: 18%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 32%;
    width: 27%;
    height: 27%;
  }

  .rx-kl-max p {
    font-size: 1.1vw;
  }

  .rx-kl-min p {
    font-size: 1.1vw;
  }

  .sicbo-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 76%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .sicbo-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .sicbo-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }



}

@media screen and (max-width:428px) {

  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: 28%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: -10%;
    right: 18%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 32%;
    width: 26%;
    height: 26%;
  }

  .rx-kl-cion span {
    font-size: .56rem;
    padding-top: 5%;
  }

  .sicbo-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 76%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .sicbo-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .sicbo-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width:414px) {
  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 25%;
    right: 25%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10%;
    right: 18%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 32%;
    width: 26%;
    height: 26%;
  }

  .sicbo-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 76%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .sicbo-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .sicbo-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width: 390px) {
  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 20%;
    right: 20%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10%;
    right: 15%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 32%;
    width: 28%;
    height: 28%;
  }

  .rx-kl-cion span {
    font-size: .50rem;
    padding-top: 6%;
  }

  .sicbo-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 76%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .sicbo-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .sicbo-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width: 384px) {

  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 20%;
    right: 20%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10%;
    right: 15%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 32%;
    width: 26%;
    height: 26%;
  }

  .rx-kl-cion span {
    font-size: .48rem;
    padding-top: 6%;
  }


  .sicbo-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 76%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .sicbo-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .sicbo-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }

}

@media screen and (max-width: 375px) {
  .sicbo-coin-display-xx-w {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 20%;
    right: 20%;
  }

  .sicbo-coin-display-xx {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10%;
    right: 15%;
  }

  .sicbo-coin-display-xx-content span {
    font-size: 10px;
  }

  .rx-kl-cion {
    top: 32%;
    width: 25%;
    height: 25%;
  }

  .sicbo-xri-plate {
    position: absolute;
    width: 11vw;
    height: 7.5vw;
    top: 6%;
    left: 76%;

  }

  @keyframes kldown {
    0% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

  }

  @keyframes klup {
    0% {
      top: 33%;
      left: 30%;
      width: 40vw;
      height: 26vw;
    }

    50% {
      top: 35%;
      left: 45%;
      width: 11vw;
      height: 7.5vw;
    }

    100% {
      top: 6%;
      left: 76%;
      width: 11vw;
      height: 7.5vw;
    }

  }

  .sicbo-xri-item-1 {
    width: 12.6vw;
    height: 12.6vw;
    left: 12.9vw;
    top: 2.9vw;
  }

  .sicbo-xri-item-2 {
    width: 12.6vw;
    height: 12.6vw;
    left: 18.9vw;
    top: 8.8vw;
  }

  .sicbo-xri-item-3 {
    width: 12.6vw;
    height: 12.6vw;
    left: 7.9vw;
    top: 9.8vw;
  }
}




.blink-s-sicbo {
  animation: blinking-s-taixiu 1s steps(5, start) infinite;
  -webkit-animation: blinking-s-taixiu 1s steps(5, start) infinite;
  animation: blinking-s-sicbo 1s infinite;
}



@keyframes blinking-s-sicbo {
  0% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }

  50% {
    /* background: #A14E4A;
    outline: 5px solid #90802F; */
    box-shadow: 0px 0px 0px 0px rgb(73, 33, 33) inset;
    background: rgba(0, 81, 255, 0.774);
  }

  100% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }
}


.blink-s-sicbo-a {
  animation: blinking-s-taixiu 1s steps(5, start) infinite;
  -webkit-animation: blinking-s-taixiu 1s steps(5, start) infinite;
  animation: blinking-s-sicbo-a 1s infinite;
}

@keyframes blinking-s-sicbo-a {
  0% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }

  50% {
    /* background: #A14E4A;
    outline: 5px solid #90802F; */
    box-shadow: 0px 0px 0px 0px rgb(73, 33, 33) inset;
    background: rgba(0, 255, 0, 0.774);
  }

  100% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }
}

.blink-s-sicbo-b {
  animation: blinking-s-taixiu 1s steps(5, start) infinite;
  -webkit-animation: blinking-s-taixiu 1s steps(5, start) infinite;
  animation: blinking-s-sicbo-b 1s infinite;
}

@keyframes blinking-s-sicbo-b {
  0% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }

  50% {
    /* background: #A14E4A;
    outline: 5px solid #90802F; */
    box-shadow: 0px 0px 0px 0px rgb(73, 33, 33) inset;
    background: rgba(255, 51, 0, 0.774);
  }

  100% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }
}</style>
