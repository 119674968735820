export default {
    permission_list: 'Danh sách cho phép',
    role_list: 'Danh sách vai trò',
    user_list: 'Danh sách người dùng',
    menu_list: 'danh sách giao diễn',
    create_permission: 'Phân quyền',
    update_permission: 'Cập nhật quyền',
    create_role: 'Tạo vai trò',
    update_role: 'Cập nhật vai trò',
    create_user: 'Tạo Tài khoản',
    update_user: 'Cập nhật tài khoản',
    create_menu: 'Tạo giao diễn chính',
    update_menu: 'Cập nhật giao diễn chính',
    change_password_user: 'Thay đổi mật khẩu người dùng',
    welcome: 'Chào mừng:',
    account_balance: 'Tài khoản số dư',
    user_role: 'Vai trò người dùng',
    No:'Số',
    no:'Số',
    your_balance:'Số dự của bạn',
    home:'Trang chính',
    sbc:'SBC',
    status:'Trạng thái',
    time:'Thời gian',
    result:'kết quả',
    five_k:'1.5 $',
    ten_k:'2.5 $',
    twenty_k:'5 $',
    five_k1:'12.5 $',
    ten_100k:'25 $',
    twenty_200k:'50 $',
    five_500k:'125 $',
    one_1m:'1M $',
    payout:'Trả',
    current_betting:'Bàn cược',
    thin:"Dưới",
    thick:"Trên",
    red:'Đỏ',
    blue:'Xanh',
    today_report:'Báo cáo cược hôm nay',
    fight:'Số cược',
    game:'Số cược',
    type:'Loại',
    bettingType:'Loại',
    amount:'Số tiền',
    old_balance:'Số dư cũ',
    new_balance:'cân bằng mới',
    total_amount: 'Tổng số tiền',
    action:'Hoạt động',
    my_profile:'Thông tin của tôi',
    persional_info:'Thông tin cá nhân',
    change_password:'Thay đổi mật khẩu',
    result_betting:'Kết quả cược',
    result_win:'Kết quả thắng',
    report:'Báo cáo',
    meron:'Đỏ',
    wala:'Xanh',
    tie:'Hòa',
    MERON:'Đỏ',
    WALA:'Xanh',
    TIE:'Hòa',
    cancel:'Hủy bỏ',
    CANCEL:'Hủy bỏ',
    login:'Đăng nhập',
    deposit:'Nạp tiền',
    withdraw:'Rút tiền',
    betting:'Cược',
    all_betting:'Tất cả cược',
    channel:'Kênh',
    channel1:'Kênh 1',
    channel2:'Kênh 2',
    betting1:'Cược',
    channel3:'Kênh 3',
    channel4:'Kênh 4',
    min:'Ít nhất',
    fight_sign:"#",
    max:'Nhiều nhất',
    bet_between : 'Đặt cược giữa',
    bet_small_large: 'Cược nhỏ / Lớn',
    return:"Trả Cược",
    bet_meron:'Cược Đỏ',
    bet_wala:'Cược Xanh',
    betting_status:'tình trạng cá cược',
    Not_Open:'Dừng cược',
    bet_draw:'Cược Hòa',
    credit:'SỐ DƯ',
    all_statement:'Báo cáo chung',
    start_date:'Thơi gian bắt đầu',
    end_date:'Thời gian kết thúc',
    date_time:'Ngày giờ',
    cast:'dàn diễn viên',
    amount_win:'Số tiền thắng',
    total_win_lose:'Tổng thắng / thua',
    LOG:'ĐĂNG NHẬP',
    SignIn:'ĐĂNG NHẬP',
    Open:'mở',
    Rdeposit:'báo cáo tiền gửi',
    Rwithdraw:'báo cáo rút',
    cash:'tiền mặt',
    date:"ngày",
    sender:"người gửi",
    reciever:"Người nhận",
    bank_reciever:"Người nhận ngân hàng",
    account_name:"Tên tài khoản",
    send_date:"gửi ngày",
    receive_date:"Nhận ngày",
    Rememberpassword:'Nhớ mật khẩu',
    log:'ĐĂNG NHẬP',
    Close:'Đóng cược',
    fight_no:"Mã cược",
    clear:'Xóa',
    draw:'Hòa',
    register:'Đăng Ký',
    promote_login_header:"Khuyến mãi nạp lần đầu lên đến 100%",
    chat_with_one:'Chat với sbc2888',
    need_help:"Xin chào tôi có thể giúp gì bạn?",
    chat_now:'Bắt đầu chat',
    register_member:"ĐĂNG KÝ THÀNH VIÊN",
    user_name:"Tên Đăng Nhập",
    full_name:"Họ Tên Giao Dịch Đầy Đủ",
    confirm_password:"Xác Nhận Mật Khẩu",
    contact_no:"Số Điện Thoại Chính Xác Và Liên Lạc Được",
    confirm_age:"Tôi xác nhận đã trên 18 tuổi, đã đọc và đồng ý ",
    confirm_age_red:"các điều kiện & điều khoản của sbc2888 khi tham gia.",
    password_register:"Mật Khẩu",
    check_confirm_password :'Không khớp',
    success_register:  "đăng ký thành công",
    account_number :'Số Tài khoản',
    note:'Nội dung',
    current_password:'Mật khẩu hiện tại',
    new_password:'Mật khẩu mới',
    hotline:"Hotline",
    required:'Vui lòng khớp với định dạng yêu cầu',
    DEPOSIT:'Nạp tiền',
    WITHDRAW:'Rút tiền',
    PENDING:'Chưa giải quyết',
    REJECT:"Từ chối",
    SUCCESS:"Thành công",
    choseBank:"Chọn ngân hàng",
    game_is_close:"Trò chơi đã đóng",
    not_enought_balance:"CưCợc thất bại",
    inputAmount:"Vui lòng nhập tiền",
    limit:"quyền",
    field_required:"Vui lòng khớp với định dạng yêu cầu",
    betting_count_over_limit:"số lần cá cược vượt quá giới hạn",
    copy :'sao chép',
    game_no:"Số trò chơi",
    betLimit:'Giới hạn cược',
    total_betting_an_invoice:'Tổng số trong một hoa đơn',
    yuki_wipe : "Chuột",
    yuki_street : "Bò",
    yuki_karl : "Hổ",
    yuki_thao : "Thỏ",
    yuki_factory : "Rồng",
    yuki_net : "Snake",
    yuki_thin : "Xỉu",
    yuki_thick : "Tài",
    yuki_mimi : "Ngựa",
    yuki_mom : "Dê",
    yuki_vk : "Khỉ",
    yuki_roka : "Gà",
    yuki_kor : "Lợn",
    yuki_odd : "Lẻ",
    yuki_pair : "Đôi",
    yuki_red : "Đỏ",
    yuki_blue : "Xanh",
    yuki_dog : "Chó",
    yuki_null : "null",
    outstanding:"Đang cược",
    lo_current_betting:"Cược trước",
    lo_previous_betting:"Cược sau",
    ticket:'Vé',
    invoice_detail: "Chi tiết hoa đơn",
    game_id: "Game ID",
    result_year: "Kết quả của năm",
    result_small_big: "Kết quả Xỉu/ Lẻ",
    small:'Xỉu',
    large:'Tài',
    តូច : "Xỉu",
    ធំ:'Tài',
    result_range: "Kết quả phạm vi",
    odd_even:'Lẻ/Chẵn',
    blue_red:'Xanh/Đỏ',
    no_data: "Không dữ liệu",
    total_betting_a_game:'Tổng trong một trò chơi',
    pleaseBet:'Vui lòng cược',
    submit_betting:"Chấp nhận",
    bet_type:"Loại cược",

    yuki_bet_amount:"Vui lòng nhập số tiền cược của bạn",
    yuki_enough_balance:"Không đủ số dư",
    yuki_bet_limit:"Số tiền đặt cược phải giữa giới hạn đặt cược",
    yuki_select_your_bet:"Vui lòng chọn cược của bạn",



    normal_betting:"Bình thường",
    go_with_tail:"Cược đuôi",
    go_with_head:"Cược đầu",
    all_post:"Tấc cả",
    post_channel:"Đài",
    lottery_result:"Kết quả số đề",
    game_close:"Đóng cược",
    total_amount_win:"Tổng số tiền hoàn trả",
    yuki_war:'War',
    bet_total:"Tổng số tiền",
    total_cast:"Total Cast",
    total_betting:'Tổng số tiền',
    result_number:"Kết quả số",
    x_win:"Thắng",
    x_lose:"Thua",
    select_bank : 'Tên ngân hàng',
    total:"Tổng số tiền",
    5:'5',
    4:'4',
    3:'3',
    2:'2',
    1:'1',
    មាន់:'Chicken',
    បង្កង:"Lobster",
    ខ្លា:"Tiger",
    ឃ្លោក:"ឃ្លោក",
    ត្រី:'Fish',
    ក្មាម:'Crab',
    Player1:"Player 1",
    Player2:"Player 2",
    Player3:"Player 3",
    Banker1:"Banker 1",
    Banker2:"Banker 2",
    Banker3:"Banker 3",
    Tie1:"Tie 1",
    Tie2:"Tie 2",
    Tie3:"Tie 3",

//sicbo

sicbo_small: "under",
    sicbo_large: "big",
 sicbo_even: 'even',
    sicbo_odd: 'odd',
    welcomeAccount:"Welcome"
};
