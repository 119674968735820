<template>
    <div class="bullet-cont d-inline-flex align-items-center">
        <span class="count rounded-circle" :class="bulletLabelColor">
            {{ bulletLabelValue }}
        </span>
        <span class=" count-label">{{ bulletLabelName }}</span>
    </div>
</template>
<script>

export default {
    props: ["bulletLabelColor", "bulletLabelValue", "bulletLabelName"],
}
</script>