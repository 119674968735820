<template>
    <div>
        <TigerDragonLiveBettingComponent v-if="passSelectedChannel == 15 || passSelectedChannel == 16 || passSelectedChannel == 18 || passSelectedChannel == 19" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />
        <KlaklokBettingComponent v-if="passSelectedChannel == 5" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />

        <BaccaratBettingComponent v-if="passSelectedChannel == 6 || passSelectedChannel == 7"
            :passCurrentBetting="passCurrentBetting" :passChannel="passChannel" :passGetButton="passGetButton"
            :passBettingTypeMaster="passBettingTypeMaster" :passWeight="passWeight"
            v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />
        <!-- <BaccaratLikeCockBettingComponent v-if="passSelectedChannel == 18" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" /> -->
        <TigerDragonBettingComponent
            v-if="passSelectedChannel == 8 || passSelectedChannel == 9 "
            :passCurrentBetting="passCurrentBetting" :passChannel="passChannel" :passGetButton="passGetButton"
            :passBettingTypeMaster="passBettingTypeMaster" :passWeight="passWeight"
            v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />

        <LottoBettingComponent v-if="passSelectedChannel == 10" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />

        <YukiBettingComponent v-if="passSelectedChannel == 11" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />

        <AbongBettingComponent v-if="passSelectedChannel == 12 || passSelectedChannel == 17"
            :passCurrentBetting="passCurrentBetting" :passChannel="passChannel" :passGetButton="passGetButton"
            :passBettingTypeMaster="passBettingTypeMaster" :passWeight="passWeight"
            v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />
        <FanTanBettingComponent v-if="passSelectedChannel == 13" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
            :passSelectedChannel="passSelectedChannel" :passUserCurrency="passUserCurrency" />

        <TaiSievBettingComponent v-if="passSelectedChannel == 14" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
            :passSelectedChannel="passSelectedChannel" :passUserCurrency="passUserCurrency" />

        <Han2BettingComponent v-if="passSelectedChannel == 20" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />
        <Han3BettingComponent v-if="passSelectedChannel == 21" :passCurrentBetting="passCurrentBetting"
            :passChannel="passChannel" :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
            :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting" :passUserCurrency="passUserCurrency"
            :passSelectedChannel="passSelectedChannel" />
    </div>
</template>

<script>


import "@/assets/css/homepage.css";

import BaccaratBettingComponent from "../_BettingComponent/BaccaratBettingComponent.vue";
import TigerDragonLiveBettingComponent from "../_BettingComponent/TigerDragonLiveBettingComponent.vue";


import TigerDragonBettingComponent from "../_BettingComponent/TigerDragonBettingComponent.vue";
import KlaklokBettingComponent from "../_BettingComponent/KlaklokBettingComponent.vue";
import LottoBettingComponent from "../_BettingComponent/LottoBettingComponent.vue";
import YukiBettingComponent from "../_BettingComponent/YukiBettingComponent.vue";
import AbongBettingComponent from "../_BettingComponent/AbongBettingComponent.vue";
import FanTanBettingComponent from "../_BettingComponent/FanTanBettingComponent.vue";
import TaiSievBettingComponent from "../_BettingComponent/TaiSievBettingComponent.vue";
import Han2BettingComponent from "../_BettingComponent/Han2BettingComponent.vue";
import Han3BettingComponent from "../_BettingComponent/Han3BettingComponent.vue";

export default {
    components: { TigerDragonLiveBettingComponent, Han2BettingComponent, Han3BettingComponent, AbongBettingComponent, YukiBettingComponent, LottoBettingComponent, KlaklokBettingComponent, BaccaratBettingComponent, TigerDragonBettingComponent, FanTanBettingComponent, TaiSievBettingComponent },
    props: [
        "passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel',
        "passUserCurrency"
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
        }
    },
    watch: {
    },
    computed: {

    },
    methods: {
        receiveSubmitBetting(recieveBettingData) {
            this.$emit("sentSubmitBetting", recieveBettingData);
        },
    }
}
</script>
