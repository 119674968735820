<template>
  <div>
          <div>
            <div class="title-current">
              {{ $t("title.lo_previous_betting") }}
            </div>
            <h6
            class="sub-title pr-1 pl-2"
            style="color: #212427"
            v-if="
            previousBetting &&
            previousBetting.length &&
            previousBetting.length > 0
            "
          >
            {{
              previousBetting
                ? moment(previousBetting.time).format("YYYYMMDD") +
                  "#" +
                  previousBetting[0].no
                : "loading..."
            }}
            <span class="float-right">
              <button
                class="btn-link link print-all"
                @click="openPrintMultiple()"
              >
                {{ $t("title.ticket") }}
              </button>
            </span>
          </h6>
          </div>
          <div class="list-result-body previous-betting-list">
            <table class="tb-bet">
              <tr
                v-for="(item, i) in previousBetting"
                :key="i"
                class="ng-star-inserted current-betting-tr"
              >
                <td>
                  <span style="font-weight:700">
                    {{bettingFormat(item.betting)}}
                  </span>
                </td>
                <td>
                  {{ formatMoney(item.bet_amount) + ' x ' + item.payout + ' = ' }} <span :style="item.amount_win >= 0 ? 'color:#003466' :'color:#cd4848' ">{{  formatMoney(item.case)  }}</span>
                </td>
                <td class="print">
                  <button
                    class="btn-link link"
                    @click="printTicket(item, item._id)"
                  >
                    <span class="fontKhmer">{{ $t("title.ticket") }}</span>
                  </button>
                  <!-- <input
                    type="checkbox"
                    :value="[{ item }]"
                    v-model="selectedAddOns"
                    class="myCheck"
                  /> -->
                </td>
              </tr>
            </table>
          </div>
    <!-- Print One Ticket On Select -->
    <v-dialog v-model="dialogPrintTicket" persistent max-width="500px">
          <div id="printMe" hidden>
            <div style="max-width: 260px; margin-left: 0px" v-if="items">
              <div class="kh-mef-14" style="text-align: center">
                <span style="font-size: 18px" class="fontKhmer">
                  {{ userInfor.user_name }}</span
                >
                <div>
                  <span style="font-size: 14px" class="fontKhmer">{{
                    drawTicketLineOnPrint
                  }}</span>
                </div>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.ticket_id") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items._id }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.game_id") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{
                    items.time
                      ? items.time.split("-").join("").slice(0, 8) + "#" + items.no
                      : "loading..."
                  }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.date") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items.time }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.type_betting") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                {{bettingFormat(items.betting)}}
                  <!-- <span v-if="$i18n.locale == 'khmer'">
                    {{
                      items.betting && items.betting[0] == "s"
                        ? $t('title.small')
                        : items.betting && items.betting[0] == "l"
                        ? $t('title.large')
                        : items.betting
                        ? items.betting[0]
                        : "loading..."
                    }}
                  </span>
                  <span v-else-if="$i18n.locale != 'khmer'">
                    {{
                      items.betting && items.betting[0] == "s"
                        ? $t('title.small')
                        : items.betting && items.betting[0] == "l"
                        ? $t('title.large')
                        : items.betting
                        ? items.betting[0]
                        : "loading..."
                    }}
                  </span> -->
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.bet_point") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ formatMoney(items.bet_amount) + 'x' + items.payout + '='+ '???' }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.win_loss") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items.is_win == null ? "???" : items.is_win }}
                </span>
              </div>
              <div style="margin-left: 10px">
                <span style="font-size: 14px" class="fontKhmer">{{
                  drawTicketLineOnPrint
                }}</span>
              </div>
              <div style="text-align: center; margin-top: -30px">
                <p
                  class="fontKhmer"
                  style="font-size: 16px; text-align: center; margin-left: -10px"
                >
                  {{ WebsiteName }}
                </p>
              </div>
            </div>
          </div>
          <v-card class="justify-center">
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" style="width:15%"  @click="closePrintDialog()">   <span></span> {{
             $t("invoice.cancel")
              }}</v-btn>
              <v-btn color="red darken-1"  style="width:15%"   @click="print()">{{
                $t("invoice.print") 
              }}</v-btn>
            </v-card-actions>
            <v-card-title class="justify-center username-title">
              {{ userInfor.user_name }}
            </v-card-title>
            <v-card-title class="justify-center draw-ticket-line">
              <span>{{ drawTicketLine }}</span>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.ticket_id") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items._id }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.game_id") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{
                  items.time
                    ? items.time.split("-").join("").slice(0, 8) + "#" + items.no
                    : ""
                }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.date") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items.time }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.type_betting") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1 pl-5">
                <!-- <span v-if="$i18n.locale == 'khmer'">
                  {{
                    items.betting && items.betting[0] == "s"
                      ? $t('title.small')
                      : items.betting && items.betting[0] == "l"
                      ?  $t('title.large')
                      : items.betting
                      ? items.betting[0]
                      : "loading..."
                  }}
                </span>
                <span v-else-if="$i18n.locale != 'khmer'">
                  {{
                    items.betting && items.betting[0] == "s"
                      ? $t('title.small')
                      : items.betting && items.betting[0] == "l"
                      ? $t('title.large')
                      : items.betting
                      ? items.betting[0]
                      : "loading..."
                  }}
                </span> -->
                {{bettingFormat(items.betting)}}gfxxgxg
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.bet_point") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items.amount }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.win_loss") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items.is_win == null ? "???" : items.is_win }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center draw-ticket-line">
              <span>{{ drawTicketLine }}</span>
            </v-card-title>
            <v-card-title style="margin-top: -25px" class="justify-center">
              <span style="font-size: 14px; opacity: 0.5">{{ WebsiteName }}</span>
            </v-card-title>
          </v-card>
        </v-dialog>
        <!-- Print selected tickets -->
        <v-dialog
          v-model="dialogPrintTicketMulti"
          persistent
          max-width="500px"
          style="margin-top: 170px !important"
        >
          <div id="printMeMultiTicket" hidden>
            <div
              style="max-width: 260px; margin-left: 0px"
              v-for="(item, i) in this.selectedAddOns"
              :key="i"
            >
              <div class="kh-mef-14">
                <div class="kh-mef-14" style="text-align: center">
                  <span
                    class="fontKhmer"
                    style="font-size: 28px; margin-left: -25px"
                  >
                    {{ userInfor.user_name }}</span
                  >
                  <div>
                    <span class="fontKhmer" style="font-size: 18px">{{
                      drawTicketLine
                    }}</span>
                  </div>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.ticket_id") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item._id }}
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.game_id") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{
                      item[0].item.time
                        ? item[0].item.time.split("-").join("").slice(0, 8) +
                          "#" +
                          item[0].item.no
                        : "loading..."
                    }}
                  </span>
                </div>
                <div class="row">
                  <div
                    class="col-5 fontKhmer"
                    style="font-weight: bold; font-size: 12.5px"
                  >
                    {{ $t("invoice.date") }}:
                  </div>
                  <div
                    class="col-5 fontKhmer"
                    style="
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: -25px;
                    "
                  >
                    {{ item[0].item.time }}
                  </div>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.type_betting") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >

                {{bettingFormat(items.betting)}}
                    <!-- <span v-if="$i18n.locale == 'khmer'">
                      {{
                        item[0].item.betting && item[0].item.betting[0] == "s"
                          ?  $t('title.small')
                          : item[0].item.betting && item[0].item.betting[0] == "l"
                          ?  $t('title.large')
                          : item[0].item.betting
                          ? item[0].item.betting[0]
                          : "loading..."
                      }}
                    </span>
                    <span v-else-if="$i18n.locale != 'khmer'">
                      {{
                        item[0].item.betting && item[0].item.betting[0] == "s"
                          ? "Under"
                          : item[0].item.betting && item[0].item.betting[0] == "l"
                          ? "Over"
                          : item[0].item.betting
                          ? item[0].item.betting[0]
                          : "loading..."
                      }}
                    </span> -->
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.bet_point") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item.amount }}
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.win_loss") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item.is_win == null ? "???" : item[0].item.is_win }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 18px; opacity: 0.9">{{
                    drawTicketLine
                  }}</span>
                </div>
                <div style="text-align: center">
                  <p
                    class="fontKhmer"
                    style="font-size: 16px; text-align: center; margin-left: -10px"
                  >
                    {{ WebsiteName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <v-card class="justify-center">
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1"  style="width:15%"  @click="closePrintMultiDialog()">{{
                $t("invoice.cancel")
              }}</v-btn>
              <v-btn color="red darken-1"  style="width:15%"  @click="printMulti()">{{
                $t("invoice.print")
              }}</v-btn>
            </v-card-actions>
            <div v-for="(item, i) in this.selectedAddOns" :key="i">
              <v-card-title class="justify-center username-title">
                {{ userInfor.user_name }}
              </v-card-title>
              <v-card-title class="justify-center draw-ticket-line">
                <span>{{ drawTicketLine }}</span>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.ticket_id") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item._id }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.game_id") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{
                    item[0].item.time
                      ? item[0].item.time.split("-").join("").slice(0, 8) +
                        "#" +
                        item[0].item.no
                      : ""
                  }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.date") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.time }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.type_betting") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1 pl-5">
                  <!-- <span v-if="$i18n.locale == 'khmer'">
                    {{
                      item[0].item.betting && item[0].item.betting[0] == "s"
                        ?  $t('title.small')
                        : item[0].item.betting && item[0].item.betting[0] == "l"
                        ?  $t('title.large')
                        : item[0].item.betting
                        ? item[0].item.betting[0]
                        : "loading..."
                    }}
                  </span>
                  <span v-else-if="$i18n.locale != 'khmer'">
                    {{
                      item[0].item.betting && item[0].item.betting[0] == "s"
                        ? "Under"
                        : item[0].item.betting && item[0].item.betting[0] == "l"
                        ? "Over"
                        : item[0].item.betting
                        ? item[0].item.betting[0]
                        : "loading..."
                    }}
                  </span> -->
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.bet_point") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.amount }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.win_loss") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.is_win == null ? "???" : item[0].item.is_win }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center draw-ticket-line">
                <span>{{ drawTicketLine }}</span>
              </v-card-title>
              <v-card-title style="margin-top: -25px" class="justify-center">
                <span style="font-size: 14px; opacity: 0.5">{{ WebsiteName }}</span>
              </v-card-title>
            </div>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Mixin from "~mixin";
export default {
    mixins: [Mixin],
    // DEFINE local VARIABLE
    data() {
        return {

            previousGame: {},
            current: {},
            dialogPrintTicket: false,
            dialogPrintTicketMulti: false,
            format: "YYYY-MM-DD",
            selectedAddOns: [],
            items: [],
            ticket_id: "",
            drawTicketLine: "--------------------------------------------------------",
            drawTicketLineOnPrint: "------------------------------------------------",
            WebsiteName: "pp77",
            headersDetail: [
                { text: this.$t("title.date_time"), value: 'time' },
                { text: this.$t("title.game"), value: 'no' },
                { text: this.$t("title.bet_type"), value: 'bet_type' },
                { text: this.$t("title.amount"), value: 'amount' },
                { text: this.$t("title.total_cast"), value: 'total_cast' },
                { text: this.$t("title.result"), value: 'result' },
                { text: this.$t("title.result_number"), value: 'result_number' }

            ],
            detailData: [],
            dialog: false,
            footerProps: {
                "items-per-page-options": [10, 20, 50, 100, 200],
            },
            dataRequest: {
                descendingFormat: [true],
                sortByFormat: ["_id"],
                descending: true,
                sortBy: null,
                page: 1,
                rowsPerPage: 10,
            },
            getLoadingDetail: false,
            invoice_id: null,
            betALength: {},
            betBLength: {},
            betCLength: {},
            betDLength: {},
            betELength: {},
            labelA: null,
            labelB: null,
            labelC: null,
            labelD: null,
            labelE: null,
            selectedItem: {}
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_keno", {
            currentBetting: "getCurrentBetting",
            previousBetting: "getPreviousBetting",
        }),
    },
    methods: {
        moment,
        bettingFormat(type){
          switch(type){
            case  "small":
                return 'UNDER'
            case  "small_odd":
                return 'UNDER_ODD'
            case  "small_even":
                return 'UNDER_EVEN'
            case  "large":
                return 'OVER'
            case  "large_odd":
                return 'OVER_ODD'
            case  "large_even":
                return 'OVER_EVEN'
            case  "odd":
                return 'ODD'
            case  "even":
                return 'EVEN'
            default:
                return type;
          }
        },
        _goToDetail(data) {
          this.$emit('detailInvoice', data)
            // this.selectedItem = data;
            // this.invoice_id = data.invoice_no;
            // this.loadDetailData(data.invoice_no);
            // this.dialog = true;
        },
        updatePage() {
            this.getLoadingDetail = true;
            this.loadDetailData(this.invoice_id);
            this.getLoadingDetail = false;
        },
        async loadDetailData(_id) {
            this.detailData = [];
            this.getLoadingDetail = true;
            this.dataRequest.sortByFormat.length < 1
                ? (this.dataRequest.sortBy = "_id._id")
                : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
            this.dataRequest.descendingFormat.length > 0
                ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
                : (this.dataRequest.descending = true);

            const response = await this.$request.getSlot({
                url: 'm_keno/report/detail?module_bet_id=' + _id
            });
            if (response.data.code) {
                this.detailData = response.data.data;
                this.getLoadingDetail = false;
            }
        },
        printTicket(item, ticket_id) {
            this.items = item;
            this.ticket_id = ticket_id;
            this.dialogPrintTicket = true;
        },
        printTicket2(item) {
            // console.log('@item:',item);
            this.items = item;
            this.betALength = item.bet_a.number.length
            this.betBLength = item.bet_b.number.length
            this.betCLength = item.bet_c.number.length
            this.betDLength = item.bet_d.number.length
            this.betELength = item.bet_e.number.length
            this.labelA = item.bet_a.label
            this.labelB = item.bet_b.label
            this.labelC = item.bet_c.label
            this.labelD = item.bet_d.label
            this.labelE = item.bet_e.label
            this.dialogPrintTicket2 = true;
        },
        print() {
            this.$htmlToPaper("printMe");
        },
        closePrintDialog() {
            this.dialogPrintTicket = false;
        },
        openPrintMultiple() {
            if (this.selectedAddOns != "") {
                this.dialogPrintTicketMulti = true;
            }
        },
        printMulti() {
            this.$htmlToPaper("printMeMultiTicket");
        },
        closePrintMultiDialog() {
            var inputs = document.getElementsByClassName("myCheck");
            for (var i = 0, l = inputs.length; i < l; ++i) {
                if (inputs[i].checked) {
                    inputs[i].checked = false;
                }
            }
            this.selectedAddOns = [];
            this.dialogPrintTicketMulti = false;
        },
    },
};
</script>