<template>
    <div>
        <ButtonBetting class="pt-0" v-on:sentSelectedButton="receiveSelectedButton" />
        <CoinButton :passGetButton="getButton" :passChannel="passChannel" :passPlaceCoin="bettingMoney"
            v-on:handleInputMonay="_inputAmount" class="mt-2"/>
        <SubmitBtn :passCheckAllowSubmit="_checkAllowSubmit" v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney" class="mb-3" />

        <TigerDragonMachineReportBettingComponent :passLoadingTodayReport="passLoadingTodayReport" />
        <v-row class="mx-0 justify-center w-100 mt-2">
            <v-col col-12 style="padding: 0;">
                <div class="show-on-mobile pt-5">
                    <TableResult :rows="6" :cols="35" />
                </div>
            </v-col>
        </v-row>

    </div>
</template>
<script>

import { mapGetters, mapMutations } from "vuex";
import CoinButton from "~global_components/component_machine_game/CoinBettingButton.vue";
import ButtonBetting from './ButtonBetting/ButtonBettingTigerDragon.vue'
import SubmitBtn from "~global_components/component_casino/SubmitBtn.vue";
import Mixin from "~mixin/MX_ShareFunctionCollection.js"
import TigerDragonMachineReportBettingComponent from "../../_ReportBettingComponent/TigerDragonMachineReportBettingComponent.vue";
import TableResult from "../../TableResults.vue";


export default {
    components: { ButtonBetting, TigerDragonMachineReportBettingComponent, TableResult, CoinButton, SubmitBtn },
    props: ["passChannel",
        'passResult',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passTodayReport',
        'passLoadingTodayReport',
        "passChannelList",
        "passSelectedChannel",
        "passCurrentBetting",
        "passNewChannelList",
        "passChannelType",
        "passUserCurrency"
    ],
    data() {
        return {
            mixins: [Mixin],
            bettingMoney: 0,
            selectedButton: {},
            activeItem: null,
            selectedBtnClassMeron: false,
            selectedBtnClassTie: false,
            selectedBtnClassWala: false,
            selectedCoin: false,
        }
    },
    computed: {
        meronButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        ...mapGetters("$_dashboard_live", {
            channelObj: "getChannel",
            getButton: "getButton",
            currentBetting: "getCurrentBetting",
            loading: "getLoading",
            errorMessage: "getErrorMessage",
            getSelectedDragonButton:"getSelectedDragonButton"
        }),
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
            getBettingAmount: "getBettingAmount",
        }),
    },
    methods: {
        receiveSubmitBetting(recieveBettingData) {
            this.$emit("sentSubmitBetting", recieveBettingData);
        },
        receiveSelectedButton(selectedBtn) {
            this.selectedButton = selectedBtn
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.getSelectedDragonButton,
                amount: this.getBettingAmount
            };
            this.$emit("sentSubmitBetting", submitBettingData);
        },
        _inputAmount(money) {
            this.UPDATE_BETTING_AMOUNT(money);
        },
        _clearMoney() {
            this.INPUT_BETTING_AMOUNT(0)
            this.UPDATE_SELECT_DRAGON_BUTTON(null)
        },
        _checkAllowSubmit() {
            if (this.getBettingAmount > 0
                && this.getSelectedDragonButton )
                return true;
            else return false;
        },
        ...mapMutations("$_modules", [
            "INPUT_BETTING_AMOUNT",
            "UPDATE_BETTING_AMOUNT"
        ]),
        ...mapMutations("$_dashboard_live", [
            "UPDATE_SELECT_DRAGON_BUTTON"
        ]),
    }
}
</script>
 