<template>
   <div>
    <ChannelInfo />
            <v-row class="mx-0 justify-center w-100 mt-7 ">
            <v-col col-12 class="han2-btn-bet-row">
                <div v-for="j in  passBettingTypeMaster" :key="j._id" class="han2-btn-bet-item"
                    @click="_x_submitBetting(j._id)">
                    <div class="range-betting-payout font-weight-bold" :class="j.color=='cir-red'?'banker-color' : j.color=='cir-blue'?'player-color' : 'tie-color' ">{{ j ? `${j.name} ` : '' }}</div>
                    <div class="text-display-payout">{{ j ? j.payout_display : '' }}</div>
                </div>
            </v-col>
        </v-row>
            <v-row class="mx-0 justify-center w-100 mt-7 ">
            <v-col col-12>
                <div class="form-group mb-2 no-error">
                    <div class="input-group mb-3">
                        <input v-model="bettingMoney" type="number" inputmode="numeric" pattern="[0-9]*" id="betAmount"
                            placeholder="ENTER BET AMOUNT" class="form-control betAmount numbers betAmountValid"
                            style="height:41px">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-secondary btnClear p-2" @click="_clearMoney"
                                style="color:white">{{ $t('title.clear') }}</button>
                        </div>
                    </div>
                </div>

                <div class="text-left currentPointsDisplay text-default pb-5"
                    style="font-weight: bold; margin-top:10px">
                    <div style="height: 8px"></div>
                    {{ $t('title.credit') }}:
                    <span id="playerPoints">{{ userInfo ? formatMoney(userInfo.balance) : '' }}</span>
                    <span class="han2-min-max-limit">
                        <LimitBetting />
                    </span>
                    
                </div>
            </v-col>
            </v-row>
             <!--<ListLiveCoins />-->

        <v-row class="mx-0 justify-center w-100 mt-0 mb-3">
            <v-col col-12>
                <div class="w-100 justify-center row col-12 px-0 mx-0 py-0 " style="justify-content:center">
                    <div v-for="(i, index) in getButton" :key="index"
                        class="col-4 col-sm-3 col-md-3 col-lg-2 px-1 w-100 justify-center my-0 pa-0 mb-3">
                        <v-btn v-if="index < 6" @click="_inputAmount(i.amount)" type="button"
                            class="btnPreBet col justify-center btn waves-effect wave-light btn-outline-warning btn-smc btn100 "
                            style=" color:white!important; font-weight:400;  font-size: 1.95rem; letter-spacing: .05em; padding-top:1px">{{
                                i.label }}</v-btn>
                        <v-btn v-else style="display:none; height:0px; padding:0px!important"></v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
           
            <Han3ReportBettingComponent :passLoadingTodayReport="passLoadingTodayReport" /> 
            <v-row class="mx-0 justify-center w-100 mt-2">
                <v-col col-12 style="padding: 0;">
                    <div class="show-on-mobile pt-5">
                        <TableResult :rows="6" :cols="35" />
                    </div>
                </v-col>
            </v-row>
           
    </div>
</template>
<script>

import { mapGetters } from "vuex";
import ChannelInfo from '../../../../../../components/component_cock/ChannelInfo.vue';
import Mixin from "../../../../../../mixin/MX_ShareFunctionCollection.js"
import Han3ReportBettingComponent from "../../_ReportBettingComponent/Han3ReportBettingComponent.vue";
import TableResult from "../../TableResults.vue";
 import LimitBetting from '../../../../../../components/component_cock/LimitBetting.vue';

export default {
    components: { Han3ReportBettingComponent,ChannelInfo,LimitBetting, TableResult },
    props: ["passChannel",
        'passResult',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passTodayReport',
        'passLoadingTodayReport',
        "passChannelList",
        "passSelectedChannel",
        "passCurrentBetting",
        "passNewChannelList",
        "passChannelType",
        "passUserCurrency"
    ],
    data() {
        return {
            mixins: [Mixin],
            bettingMoney:0,
            selectedButton:{},
            activeItem: null,
            selectedBtnClassMeron : false,
            selectedBtnClassTie : false,
            selectedBtnClassWala : false,
            selectedCoin : false,
        }
    },
    computed: {
        meronButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        ...mapGetters("$_dashboard", {
            channelObj: "getChannel",
            getButton: "getButton",
            currentBetting: "getCurrentBetting",
            loading: "getLoading",
            errorMessage:"getErrorMessage"
        }),
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
        }),
    },
    methods: {
        receiveSubmitBetting(recieveBettingData) {
            this.$emit("sentSubmitBetting", recieveBettingData);
        },
        _switchChannel(selectedChannel) {
            this.$emit("sentSetChannel", selectedChannel);
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _inputAmount(money) {
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)
        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassMeron = false;
            this.selectedBtnClassTie = false;
            this.selectedBtnClassWala = false;
        },
        _allowBtnBet(selectedBtn) {
            switch (selectedBtn) {
                case 'b1':
                    this.selectedButton = this.meronButton;
                    this._submitBetting()
                    break;
                case 'b2':
                    this.selectedButton = this.walaButton;
                    this._submitBetting()
                    break;
                case 'b3':
                    this.selectedButton = this.tieButton;
                    this._submitBetting()
                    break;
            }
        },
        _checkAllowSubmit() {
            if ( this.bettingMoney > 0
                && ( this.selectedBtnClassSix || this.selectedBtnClassFive || this.selectedBtnClassFour
                    || this.selectedBtnClassThree || this.selectedBtnClassTwo || this.selectedBtnClassOne 
                    || this.selectedBtnClassTwoThree || this.selectedBtnClassThreeFour || this.selectedBtnClassFourFive)
                ) return true;
            else  return false;
        },
        _x_submitBetting(_id){
            let submitBettingData = {
                type_id: _id,
                amount: this.bettingMoney
            };
            //console.log("==========")
            //console.log(submitBettingData)
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        }
    }
}
</script>

<style>
.tie-color{ color: green;}

.btn-switch-channel:has(.to-hidden) {
    display: none !important
}

.video-section {
    position: relative;
    overflow: hidden;
    background-color: rgba(2, 90, 179, 0.6)
}
.show-on-mobile{
    display: none;
}
@media(max-width:768px) { 
    .show-on-mobile{
        display: block;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1904px) {
    .text-display-payout {
        font-size: 1rem !important;
    }

    .range-betting-payout {
        font-size: 2rem;
    }
}

@media screen and (max-width:1280px) {
    .text-display-payout {
        font-size: 1rem !important;
    }

    .range-betting-payout {
        font-size: 1.3rem;
    }
}

@media screen and (max-width:982px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: 1.1rem;
    }
}

@media screen and (max-width:960px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: 1rem
    }
}

@media screen and (max-width:959px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: 1.2rem
    }
}

@media screen and (max-width:768px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: 1.1rem
    }
}

@media screen and (max-width:700px) {
    .text-display-payout {
        font-size: .80rem;
    }

    .range-betting-payout {
        font-size: .80rem
    }
}

@media screen and (max-width:600px) {
    .range-betting-payout {
        font-size: 1.0rem
    }
}

@media screen and (max-width:529px) {
    .range-betting-payout {
        font-size: 1.3rem
    }
}

@media screen and (max-width: 390px) {
    .text-display-payout {
        font-size: .75rem;
    }

    .range-betting-payout {
        font-size: 1.30rem
    }
}

@media screen and (max-width: 375px) {
    .range-betting-payout {
        font-size: .90rem;
    }
}
</style>