<template>
  <v-container class="bg-surface-variant pa-0 ">
    <div class="lottery3d-main-container">
      <div class="main-body">
        <div class="page-wrapper" style="overflow: hidden">
          <div class="page-body" style="overflow: hidden">
            <div class="lottery-menu-nav-head">
              <MainMenu />
            </div>
            <LotteryMenuTab />
            <v-row no-gutters class="mx-0">
              <LeftSection />
              <MiddleSection :ass_a="ass_a" :ass_b="ass_b" :ass_d="ass_d" :ass_c="ass_c" :ass_e="ass_e" />
              <RightSection />
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>

// IMPORT STYLE
import "~css/lottery_menu/lotteryVn/screen_max_480.css";
import "~css/lottery_menu/lotteryVn/screen_min_481.css";
import "~css/lottery_menu/lotteryVn/screen_min_768.css";
import "~css/lottery_menu/lotteryVn/screen_min_993.css";
import "~css/lottery_menu/lotteryVn/screen_min_1025.css";
import "~css/lottery_menu/lotteryVn/screen_min_1281.css";
import "~css/lottery_menu/lotteryVn/screen_min_1441.css";
import "~css/lottery_menu/lotteryVn/screen_min_1661.css";
import "~css/lottery_menu/lotteryVn/screen_min_1824.css";

import LeftSection from "./components/leftSection.vue";
import MiddleSection from "./components/MiddleSection.vue";
import RightSection from "./components/RightSection.vue";
import MainMenu from "~global_components/MainMenu.vue";
import LotteryMenuTab from "~global_components/component_lottery_menu/lottery_menu_tab.vue";
import MX_Socket_LottoMenu_LotteryVn from "~mixin/SocketGame/LottoMenu/MX_Socket_Lottery_VN.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  mixins: [MX_Socket_LottoMenu_LotteryVn],
  components: {
    LeftSection,
    MiddleSection,
    RightSection,
    MainMenu,
    LotteryMenuTab
  },
  metaInfo: {
    titleTemplate: `%s |  VN 1`
  },
  data() {
    return {
      orderResult: {},
      orderResultUnderOver: {},
      clientRandom: [],
      randomNumber: 0,
      clientResult: [],
      result_a: [],
      result_b: [],
      result_c: [],
      result_d: [],
      result_e: [],
      outData: 0,
      ass_a: null,
      ass_b: null,
      ass_c: null,
      ass_d: null,
      ass_e: null,
      heartbeat: false,

    };
  },
  watch: {
    channelId: async function (newVal, oldVal) {
      if (newVal != oldVal && oldVal != 1) {
        this.ass_a = null;
        this.ass_b = null;
        this.ass_c = null;
        this.ass_d = null;
        this.ass_e = null;
        await this._initialData();
        await  this.CLEAR_RESULT_LOTTERY3();
        this._connectSocketLotteryVn();

      }
    },
  },
  computed: {
    ...mapGetters("$_modules", {
      channelId: "channelId",
      isSound: "getIsSound",
    }),
    ...mapGetters("$_lottery3d", {
      getClientTime: 'getClientTime'
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  created() {
    this.CLEAR_TO_DEFAULT();
    this._connectSocketLotteryVn();
    this._initialData();
  },
  methods: {
    async _initialData() {
      await this.fetchLastResults();
      await this.fetchCurrentBetting();
      await this.fetchCurrentGame();
      await this.fetchPreviousBetting();
      await this.fetchBettingType();
      await this.fetchButton();
      await this.fetchLimit();
    },
    fromFinalToResult(result, forUse) {
      let _ts = this;
      if (result && result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          (function initPerCharAnimation(c) {
            setTimeout(() => {
              shuffleResult(c)
            }, 1500 * i)
          }(result.substring(0, i + 1).split('')))
        }
      }

      function shuffleResult(final) {
        const original = final.concat()
        let i = 0
        for (; i < 9; i++) {
          setTimeout(() => {
            // TODO use random chars instead of numbers when final.at(-1) is a char
            final[final.length - 1] = Math.random() * 10 | 0
            _ts.output(final.join(''), forUse);
          }, 120 * i);
        }

        setTimeout(() => {
          _ts.output(original.join(''), forUse);
          _ts._playSoundBang();
        }, 120 * i)
      }
    },
    output(data, forUse) {
      if (forUse == 'a') {
        this.ass_a = data;
        this.result_a[this.result_a.length > 1 ? this.result_a.length - 1 : 0] = data;
        let sentData = {
          data: this.result_a,
          forUse: forUse
        }
        this.passResultRandom(sentData);
      }
      if (forUse == 'b') {
        this.ass_b = data;
        this.result_b[this.result_b.length > 1 ? this.result_b.length - 1 : 0] = data;
        let sentData = {
          data: this.result_b,
          forUse: forUse
        }
        this.passResultRandom(sentData);
      }
      if (forUse == 'c') {
        this.ass_c = data;
        this.result_c[this.result_c.length > 1 ? this.result_c.length - 1 : 0] = data
        let sentData = {
          data: this.result_c,
          forUse: forUse
        }
        this.passResultRandom(sentData);
      }

      if (forUse == 'd') {
        this.ass_d = data;
        this.result_d[this.result_d.length > 1 ? this.result_d.length - 1 : 0] = data;
        let sentData = {
          data: this.result_d,
          forUse: forUse
        }
        this.passResultRandom(sentData);
      }

      if (forUse == 'e') {
        this.ass_e = data;
        this.result_e[this.result_e.length > 1 ? this.result_e.length - 1 : 0] = data;
        let sentData = {
          data: this.result_e,
          forUse: forUse
        }
        this.passResultRandom(sentData);
      }


    },
    ...mapMutations("$_lottery_vn", [
      "CLEAR_RESULT_LOTTERY3",
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
      "CLEAR_TO_DEFAULT",
      "UPDATE_CLIENT_TIME",
      "LATEST_ONE_RESULTS",
      "UPDATE_LRY3_RESULT_A",
      "UPDATE_LRY3_RESULT_B",
      "UPDATE_LRY3_RESULT_C",
      "UPDATE_LRY3_RESULT_D",
      "UPDATE_LRY3_RESULT_E",
      "APPEND_RESULT_STATEMENT",
      "CURRENT_GAME"
    ]),
    ...mapActions("$_lottery_vn", [
      "fetchLastResults",
      "fetchCurrentBetting",
      "fetchPreviousBetting",
      "fetchCurrentGame",
      "SocketInAction",
      "fetchButton",
      "fetchBettingType",
      "fetchLimit",
      "passResultRandom"
    ]),
    ...mapActions("$_modules", ["fetchUserInfo"]),
  },

  beforeRouteLeave(to, from, next) {
    this._disconnectSocketLotteryVn();
    next();
  },
};
</script>
