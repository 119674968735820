<template>
    <div class="mc-min-max" >
      <p class="mc-title">
        {{ passGameName }}
      </p>
      <div>
        <div class=" justify-content-between mx-0">
          <v-container>
            <v-row class="reserver-line-height">
              <v-col class="pb-0 pt-0 pr-0 pl-2">
                <v-row>
                  <v-col class="pr-0 py-0 mc-text">
                    {{ $t('title.min') }}
                  </v-col>
                  <v-col class="pr-0 py-0 mc-text">
                    {{ formatMoney(amountMin) }},
                  </v-col>
                </v-row>
                
              </v-col>
              <v-col class="py-0 pr-0 mt-0">
                <v-row>
                  <v-col class="pr-0 pl-2 py-0 mc-text">
                    NO:
                  </v-col>
                  <v-col class="pa-0 mb-2 mc-text">
                   #{{ channelObj.fight_no }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="reserver-line-height">
              <v-col class="pr-0 pl-2  mc-text">
                {{ $t('title.max') }}
              </v-col>
              <v-col class="pr-0 pl-2  mc-text">
                {{ formatMoney(amountMax) }}
              </v-col>
            </v-row>
          </v-container>

        </div>
      </div>
            <!-- <div class="mc-min-max-box">
              <div class="mc-game" v-if="channelObj.fight_no">
                <p>#{{ channelObj.fight_no }}</p>
              </div>
              <div class="mc-min" v-if="amountMin">
                <p>{{ formatMoney(amountMin) }}</p>
              </div>
              <div class="mc-max" v-if="amountMax">
                <p>{{ formatMoney(amountMax) }}</p>
              </div>
            </div> -->
          </div>
</template>

<script>

import "../../assets/css/machine-general.css";
import "~mixin/MX_ShareFunctionCollection.js"
import { mapGetters } from "vuex";
export default {
    props: [
    "passGameName",
  ],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      amountMin: "getAmountMin",
      amountMax: "getAmountMax",
      channelObj: "getChannel",
    }),
  },
  methods: {    
    getMachineGameInfoImage(gameName){
        switch (gameName){
            case 'taixiu':
                return require('../../../public/images/a_each_game/taixiu/taixiu_game_info.png')
            case 'doden':
              return require('../../../public/images/a_each_game/doden/limit_doden.png')
        }
        
     }
  },

};
</script>
