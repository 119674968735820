<template>
  <div class="game-machine-component-left">
    <DragonTigerResult :dragonCard="dragonCard" :tigerCard="tigerCard" :passTime="passTime" :passChDesc="passChDesc" :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 94 || channelType == 941 || channelType == 942 || channelType == 943 || channelType == 944 || channelType == 945 || channelType == 946 || channelType == 947 || channelType == 948 || channelType == 949" :passIndex="passIndex" :passGame="passGame" :showResult="showResult"/>
    <BaccaratResult :baccaratScanCard="baccaratScanCard" :passTime="passTime" :passChDesc="passChDesc" :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 95 || channelType == 951 || channelType == 952 || channelType == 953 || channelType == 954 || channelType == 955 || channelType == 956 || channelType == 957 || channelType == 958 || channelType == 959" :passIndex="passIndex" :passGame="passGame" :showResult="showResult"/>
    <TaixiuResult :passChDesc="passChDesc" :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 98 || channelType == 981 || channelType == 982 || channelType == 983 || channelType == 984" :passIndex="passIndex" :passGame="passGame" :showResult="showResult"/>
    <DodenResult :passChDesc="passChDesc" :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 96 || channelType == 961 || channelType == 962 || channelType == 963 || channelType == 964" :passIndex="passIndex" :passGame="passGame" :showResult="showResult"/>
    <SicboResult :passChDesc="passChDesc" :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 97 || channelType == 971 || channelType == 972 || channelType == 973 || channelType == 974" :passIndex="passIndex" :passGame="passGame" :showResult="showResult"/>
    <KlakloukResult :passChDesc="passChDesc" :resultObjS="resultObjS" :channelType="channelType" v-if="channelType == 93 || channelType == 931 || channelType == 932 || channelType == 933 || channelType == 934" :passIndex="passIndex" :passGame="passGame" :showResult="showResult"/>
  </div>
</template>
<script>
import DragonTigerResult from "../GameResult/DragonTigerResult.vue";
import BaccaratResult from "../GameResult/BaccaratResult.vue";
import TaixiuResult from "../GameResult/TaixiuResult.vue";
import DodenResult from "../GameResult/DodenResult.vue";
import SicboResult from "../GameResult/SicboResult.vue";
import KlakloukResult from "../GameResult/KlakloukResult.vue";

export default {
  components: {
    DragonTigerResult,
    BaccaratResult,
    TaixiuResult,
    DodenResult,
    SicboResult,
    KlakloukResult
  },
  props:['baccaratScanCard', 'passGame', 'passTime' , 'passIndex', 'channelType', 'resultObjS', 'passChDesc', 'showResult' ,'tigerCard', 'dragonCard']
}
</script>
<style>
  .v-application .pb-4{
    margin-bottom: 0 !important;
  }
  .trend-legend-custome{
    padding: 6px !important;
  }
  /* p.count-label{
    font-size: smaller;
  } */
  .v-icon .v-icon{
    font-size: 12px !important
  }
</style>