import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    myInfo: true,
    updateMyInfo: {},
    changePassword: {},
    reportWin: {},
    reportBetting: {},
    reportStatement: null,
    loading: true,
    isUpdateUser: false,
    isChangePwd: false,
    updateUserInfo: {
        balance: null,
        user_name: null
    },
    bankList: []
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};