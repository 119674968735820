var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"g-header pa-0"},[_c('div',{staticClass:"g-summary pl-4 pr-2 pt-1"},[_c('div',{staticClass:"g-num-status w-100 justify-content-between align-items-center pt-0"},[_c('div',{staticClass:"g-num w-50 w-sm-25 text-uppercase text-left pt-0 mt-0",staticStyle:{"font-size":"1.7rem"}},[_vm._v(" # "),_c('span',{staticStyle:{"color":"rgb(240, 197, 89)","font-weight":"800"}},[_vm._v(_vm._s(_vm.passChannel.fight_no))])]),_c('div',{staticClass:"side-title w-50 w-sm-25 g-status font-weight-bold text-uppercase text-right pt-3"},[_c('span',{class:_vm.textColorBaseOnChannelStatus(_vm.passChannel.is_open)},[_vm._v(" "+_vm._s(_vm.$t(`title.${_vm.checkChannelStatus(_vm.passChannel.is_open)}`))+" ")])])])])]),_c('div',{staticClass:"text-center"},[(_vm.passChannel.is_maintain)?_c('marquee',{staticClass:"open-betting-countdown red--text mb-3"},[_c('div',[_vm._v(" "+_vm._s(_vm.passChannel.maintain_desc)+" ")])]):(
        !_vm.passChannel.is_maintain && _vm.getCountdown && _vm.getCountdown.type == 'open'
      )?_c('div',{staticClass:"mb-2 open-betting-countdown",class:_vm.getCountdown.countDown > 10 ? 'open-betting' : 'close-betting',attrs:{"width":"100%","direction":"left;"}},[_vm._v(" "+_vm._s(_vm.getCountdown.countDown - 5)+" ")]):(
        !_vm.passChannel.is_maintain &&
        (_vm.passChannel.is_open == null || _vm.passChannel.is_open == false) &&
        _vm.passTotalLotto &&
        _vm.passTotalLotto > 0
      )?_c('div',{staticClass:"mb-2 pa-0 open-betting-countdown",class:_vm.getCountdown.type == 'open' ? 'open-betting' : 'result-betting-loto',staticStyle:{"margin-top":"-6px"},attrs:{"width":"100%","direction":"left;"}},[_c('span',{staticStyle:{"color":"#013366"}},[_vm._v(_vm._s(_vm.$t("title._result"))+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.passTotalLotto ? _vm.passTotalLotto : "")+" ")])]):(
        !_vm.passTotalLotto &&
        !_vm.passChannel.is_maintain &&
        ( _vm.passChannel.is_open == false ||
          (_vm.getCountdown && _vm.getCountdown.type) == 'close')
      )?_c('marquee',{staticClass:"mb-2 open-betting-countdown",class:_vm.getCountdown.type == 'open' ? 'open-betting' : 'close-betting',attrs:{"scrollamount":"15","width":"100%","direction":"left;"}},[_vm._v(" "+_vm._s(_vm.$t("title.gameIsClose"))+" ")]):(
      _vm.passChannel.is_open == null || _vm.passChannel.is_open == true
    )?_c('marquee',{staticClass:"open-betting-countdown mt-1 mb-0",class:_vm.passChannel.is_open == null ?'close-betting':  _vm.passChannel.is_open == true
    ? 'open-betting' : '',attrs:{"scrollamount":"15","width":"100%","direction":"left;"}},[_vm._v(" "+_vm._s(_vm.passChannel.is_open == null ? _vm.$t("title.gameIsLive"): _vm.passChannel.is_open == true ? _vm.$t("title.gameIsOpen") : '')+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }