import * as moment from 'moment';
// import {mapActions} from 'vuex'

export default {
    methods: {
        formatMoneyButton(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 99999999):
                    return parseFloat(amount / 1000).toLocaleString() + "K";
                default:
                    return amount
            }
        },
        formatMoney(amount) {
            amount = parseFloat(amount);
            return parseFloat(amount).toLocaleString();
        },
        correctChannelName(channelType) {
            switch (true) {
                case (channelType == 1):
                    return require('../../public/images/a_page_access_global/game-logo/cockfight_1.png')
                case (channelType == 2):
                    return require('../../public/images/a_page_access_global/game-logo/cockfight 2.png')
                case (channelType == 3):
                    return require('../../public/images/a_page_access_global/game-logo/cockfight 3.png')
                case (channelType == 4):
                    return require('../../public/images/a_page_access_global/game-logo/cockfight 4.png')
                case (channelType == 5):
                    return require('../../public/images/a_page_access_global/game-logo/television-logo.png')
                case (channelType == 6):
                    return require('../../public/images/a_page_access_global/game-logo/television-logo.png')
                case (channelType == 7):
                    return require('../assets/images/channel-icons/baccarat icon 2.png');
                case (channelType == 8):
                    return require('../assets/images/channel-icons/dragon tiger icon 1.png');
                case (channelType == 9):
                    return require('../assets/images/channel-icons/dragon tiger icon 2.png');
                case (channelType == 10):
                    return require('../assets/images/channel-icons/lotto icon 1.png');
                case (channelType == 11):
                    return require('../assets/images/channel-icons/yuki icon 1.png');
                case (channelType == 12):
                    return require('../assets/images/channel-icons/aporng icon 1.png');
                case channelType == 13:
                    return require('../assets/images/channel-icons/fantan icon 1.png');
                case channelType == 14:
                    return require('../assets/images/channel-icons/taisiev icon 1.png');
                case (channelType == 15):
                    return require('../../public/images/a_page_access_global/game-logo/television-logo.png')
                case (channelType == 16):
                    return require('../../public/images/a_page_access_global/game-logo/television-logo.png')
                case (channelType == 17):
                    return require('../assets/images/channel-icons/aporng icon 2.png');
                case (channelType == '18'):
                    return require('../assets/images/channel-icons/baccarat icon 3.png');
                case (channelType == '19'):
                    return require('../assets/images/channel-icons/dragon tiger icon 3.png');
                case (channelType == '20'):
                    return require('../assets/images/channel-icons/Han_2.png');
                case (channelType == '21'):
                    return require('../assets/images/channel-icons/Han_3.png');
                default:
                    return '';
            }
        },
        correctCasinoChannelName(channelType) {
            switch (true) {
                case (channelType == 1):
                    return require('../../public/images/a_page_access_global/game-logo/cockfight_1.png')
                case (channelType == 2):
                    return require('../../public/images/a_page_access_global/game-logo/cockfight 2.png')
                case (channelType == 3):
                    return require('../../public/images/a_page_access_global/game-logo/cockfight 3.png')
                case (channelType == 4):
                    return require('../../public/images/a_page_access_global/game-logo/cockfight 4.png')
                case (channelType == 5):
                    return require('../assets/images/channel-icons/kla-klok icon 1.png')
                case (channelType == 6):
                    return require('../assets/images/channel-icons/baccarat icon 1.png')
                case (channelType == 7):
                    return require('../assets/images/channel-icons/baccarat icon 2.png');
                case (channelType == 8):
                    return require('../assets/images/channel-icons/dragon tiger icon 1.png');
                case (channelType == 9):
                    return require('../assets/images/channel-icons/dragon tiger icon 2.png');
                case (channelType == 10):
                    return require('../assets/images/channel-icons/lotto icon 1.png');
                case (channelType == 11):
                    return require('../assets/images/channel-icons/yuki icon 1.png');
                case (channelType == 12):
                    return require('../assets/images/channel-icons/aporng icon 1.png');
                case channelType == 13:
                    return require('../assets/images/channel-icons/fantan icon 1.png');
                case channelType == 14:
                    return require('../assets/images/channel-icons/taisiev icon 1.png');
                case (channelType == 15):
                    return require('../assets/images/channel-icons/baccarat icon 4.png')
                case (channelType == 16):
                    return require('../assets/images/channel-icons/Roulette icon.png')
                case (channelType == 17):
                    return require('../assets/images/channel-icons/aporng icon 2.png');
                case (channelType == '18'):
                    return require('../assets/images/channel-icons/baccarat icon 3.png');
                case (channelType == '19'):
                    return require('../assets/images/channel-icons/dragon tiger icon 3.png');
                case (channelType == '20'):
                    return require('../assets/images/channel-icons/Han_2.png');
                case (channelType == '21'):
                    return require('../assets/images/channel-icons/Han_3.png');
                default:
                    return '';
            }
        },
        _displayCurrencySymbol(user_account_currency) {
            switch (user_account_currency) {
                case 1:
                    return '$'
                case 2:
                    return '៛'
                case 3:
                    return 'THB'
                case 4:
                    return '₫'
                case 5:
                    return 'THB'
                default:
                    return '₫'
            }
        },
        showResultByColor(result) {
            if (result.results[0]) {
                if (result.results[0].name == "1" || result.results[0].name == "4") {
                    return "red"
                } else {
                    return "black"
                }
            } else {
                return "primary"
            }
        },
        getBettingStatus() {
            if (this.passChannel.is_open && this.userInfor.enable && this.passChannel.enable) {
                return true
            }
            return false
        },

        resultStatue() {
            if (this.passChannel.is_open == null && this.resultObj.results[0]) {
                return true
            } else {
                return false
            }
        },
        resultChannelColor(index) {
            // console.log(`channelStatus: ${JSON.stringify(this.channelStatus)}`)
            if (this.passChannelStatus) {
                for (var i = 0; i < this.passChannelStatus.length; i++) {
                    if (this.passChannelStatus[i].channel_type == index) {
                        if (this.passChannelStatus[i].status == null) {
                            return "orange"
                        } else {
                            if (this.passChannelStatus[i].status) {
                                return "green"
                            }
                            return "red"
                        }
                    }
                }
            }
        },
        _formatAmountCurrency(userInfo, amount) {
            switch (userInfo.account_currency) {
                case 1:
                    return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                case 2:
                    return parseInt(amount).toLocaleString();
                case 3:
                    return parseInt(amount).toLocaleString();
                case 4:
                    return parseInt(amount).toLocaleString();
                case 5:
                    return parseInt(amount).toLocaleString();
                default:
                    return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
        },
        _formatCurrency(userInfo) {
            switch (userInfo.account_currency) {
                case 1:
                    return parseFloat(userInfo.balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                case 2:
                    return parseInt(userInfo.balance).toLocaleString();
                case 3:
                    return parseInt(userInfo.balance).toLocaleString();
                case 4:
                    return parseInt(userInfo.balance).toLocaleString();
                case 5:
                    return parseInt(userInfo.balance).toLocaleString();
                default:
                    return parseInt(userInfo.balance).toLocaleString();
            }
        },
        _selectedGame(selectedChannel) {
            switch (true) {
                case (selectedChannel == 1 || selectedChannel == 2
                    || selectedChannel == 3 || selectedChannel == 4
                    || selectedChannel == 15 || selectedChannel == 16):
                    return 'cock'
                case selectedChannel == 5:
                    return 'klaklouk'
                case selectedChannel == 6 || selectedChannel == 7 || selectedChannel == 18:
                    return 'baccarat'
                case selectedChannel == 8 || selectedChannel == 9 || selectedChannel == 19:
                    return 'tiger-dragon'
                case selectedChannel == 10 || selectedChannel == 11 || selectedChannel == 20:
                    return 'do-den'
                case selectedChannel == 10:
                    return 'lotto'
                case selectedChannel == 11:
                    return 'yuki'
                case (selectedChannel == 12 || selectedChannel == 17):
                    return 'apong'
                case selectedChannel == 13:
                    return 'fantan'
                case selectedChannel == 14:
                    return 'taisiev'
                case selectedChannel == 20:
                    return 'han2'
                case selectedChannel == 21:
                    return 'han3'
                case selectedChannel == 93:
                    return 'slot-klaklouk'
                case selectedChannel == 931:
                    return 'slot-klaklouk2'
                case selectedChannel == 932:
                    return 'slot-klaklouk3'
                case selectedChannel == 933:
                    return 'slot-klaklouk4'
                case selectedChannel == 934:
                    return 'slot-klaklouk5'
                case selectedChannel == 935:
                    return 'slot-klaklouk6'
                case selectedChannel == 936:
                    return 'slot-klaklouk7'
                case selectedChannel == 937:
                    return 'slot-klaklouk8'
                case selectedChannel == 938:
                    return 'slot-klaklouk9'
                case selectedChannel == 939:
                    return 'slot-klaklouk10'
                case selectedChannel == 94:
                    return 'slot-dragon'
                case selectedChannel == 941:
                    return 'slot-dragon2'
                case selectedChannel == 942:
                    return 'slot-dragon3'
                case selectedChannel == 943:
                    return 'slot-dragon4'
                case selectedChannel == 944:
                    return 'slot-dragon5'
                case selectedChannel == 945:
                    return 'slot-dragon6'
                case selectedChannel == 946:
                    return 'slot-dragon7'
                case selectedChannel == 947:
                    return 'slot-dragon8'
                case selectedChannel == 948:
                    return 'slot-dragon9'
                case selectedChannel == 949:
                    return 'slot-dragon10'
                case selectedChannel == 95:
                    return 'slot-baccarat'
                case selectedChannel == 951:
                    return 'slot-baccarat2'
                case selectedChannel == 952:
                    return 'slot-baccarat3'
                case selectedChannel == 953:
                    return 'slot-baccarat4'
                case selectedChannel == 954:
                    return 'slot-baccarat5'
                case selectedChannel == 955:
                    return 'slot-baccarat6'
                case selectedChannel == 956:
                    return 'slot-baccarat7'
                case selectedChannel == 957:
                    return 'slot-baccarat8'
                case selectedChannel == 958:
                    return 'slot-baccarat9'
                case selectedChannel == 959:
                    return 'slot-baccarat10'
                case selectedChannel == 96:
                    return 'slot-doden'
                case selectedChannel == 961:
                    return 'slot-doden2'
                case selectedChannel == 962:
                    return 'slot-doden3'
                case selectedChannel == 963:
                    return 'slot-doden4'
                case selectedChannel == 964:
                    return 'slot-doden5'
                case selectedChannel == 965:
                    return 'slot-doden6'
                case selectedChannel == 966:
                    return 'slot-doden7'
                case selectedChannel == 967:
                    return 'slot-doden8'
                case selectedChannel == 968:
                    return 'slot-doden9'
                case selectedChannel == 969:
                    return 'slot-doden10'
                case selectedChannel == 97:
                    return 'slot-sicbo'
                case selectedChannel == 971:
                    return 'slot-sicbo2'
                case selectedChannel == 972:
                    return 'slot-sicbo3'
                case selectedChannel == 973:
                    return 'slot-sicbo4'
                case selectedChannel == 974:
                    return 'slot-sicbo5'
                case selectedChannel == 975:
                    return 'slot-sicbo6'
                case selectedChannel == 976:
                    return 'slot-sicbo7'
                case selectedChannel == 977:
                    return 'slot-sicbo8'
                case selectedChannel == 978:
                    return 'slot-sicbo9'
                case selectedChannel == 979:
                    return 'slot-sicbo10'

                case selectedChannel == 98:
                    return 'slot-taixiu'
                case selectedChannel == 981:
                    return 'slot-taixiu2'
                case selectedChannel == 982:
                    return 'slot-taixiu3'
                case selectedChannel == 983:
                    return 'slot-taixiu4'
                case selectedChannel == 984:
                    return 'slot-taixiu5'
                case selectedChannel == 985:
                    return 'slot-taixiu6'
                case selectedChannel == 986:
                    return 'slot-taixiu7'
                case selectedChannel == 987:
                    return 'slot-taixiu8'
                case selectedChannel == 988:
                    return 'slot-taixiu9'
                case selectedChannel == 989:
                    return 'slot-taixiu10'
                case selectedChannel == 99:
                        return 'slot-ham'
                case selectedChannel == 100:
                            return 'slot-three_face'
                default:
                    return ''
            }
        },
        _detectImg(index) {
            switch (index) {
                case 0:
                    return '/images/a_each_game/klaklok/0001.png';
                case 1:
                    return '/images/a_each_game/klaklok/0002.png';
                case 2:
                    return '/images/a_each_game/klaklok/0003.png';
                case 3:
                    return '/images/a_each_game/klaklok/0004.png';
                case 4:
                    return '/images/a_each_game/klaklok/0005.png';
                case 5:
                    return '/images/a_each_game/klaklok/0006.png';
                default:
                    return ''
            }
        },
        _findChannelType(url) {
            switch (url) {
                case 'LOTTO'
                    : return 90;
                case 'YUKI'
                    : return 91;
                case 'LOTTERY'
                    : return 92;
                case 'KLAKLOUK'
                    : return 93;
                case 'DRAGON'
                    : return 94;
                case 'BACCARAT'
                    : return 95;
                case 'DODEN'
                    : return 96;
                case 'SICBO'
                    : return 97;
                case 'TAIXIU'
                    : return 98;
            }
        },
        _findSelectedSlotGame(url) {
            switch (url) {
                case 'LOTTO'
                    : return 90;
                case 'YUKI'
                    : return 91;
                case 'LOTTERY'
                    : return 92;
                case 'KLAKLOUK'
                    : return 93;
                case 'DRAGON'
                    : return 94;
                case 'BACCARAT'
                    : return 95;
                case 'DODEN'
                    : return 96;
                case 'SICBO'
                    : return 97;
                case 'TAIXIU'
                    : return 98;
                case 'Ham'
                    : return 99;
                case 'ThreeFace'
                    : return 100;
            }
        },
        _calculateRequestApiKeyword(url) {

            switch (true) {
                case (url == '90')
                    : return 90;
                case  (url == '91')
                    : return 91;
                case  (url == '92')
                    : return 92;
                case  (url == '93'|| url == '931' || url == '932'|| url == '933' || url == '934' || url == '935' || url == '936' || url == '937' || url == '938' || url == '939' )
                    : return "m_klaklouk";
                case  ( url == '94' || url == '941' || url == '942'|| url == '943' || url == '944' || url == '945' || url == '946' || url == '947' || url == '948' || url == '949' )
                    : return "m_dragon";
                case  ( url == '95' || url == '951' || url == '952'|| url == '953' || url == '954' || url == '955' || url == '956' || url == '957' || url == '958' || url == '959' )
                    : return "m_baccarat";
                case ( url == '96' || url == '961' || url == '962'|| url == '963' || url == '964' || url == '965' || url == '966' || url == '967' || url == '968' || url == '969' )
                    : return "m_doden";
                case ( url == '97' || url == '971' || url == '972'|| url == '973' || url == '974' || url == '975' || url == '976' || url == '977' || url == '978' || url == '979' )
                    : return "m_sicbo";
                case ( url == '98' || url == '981' || url == '982'|| url == '983' || url == '984' || url == '985' || url == '986' || url == '987' || url == '988' || url == '989' )
                    : return "m_taixiu";
            }
        },
        _calculateChannelType(url) {
            switch (url) {
                case '/KLAKLOUK'
                    : return 93;
                case '/DRAGON'
                    : return 94;
                case '/BACCARAT'
                    : return 95;
                case '/DODEN'
                    : return 96;
                case '/SICBO'
                    : return 97;
                case '/TAIXIU'
                    : return 98;
                default:
                    return false
            }
        },
        _playSoundBang() {
            if (this.isSound) {
                // console.log('hii');
                let audio = new Audio("/sound/bangbang.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
            }
        },
        _playSoundBetDown() {
            if (this.isSound) {
                let audio = new Audio("/sound/betdown_please.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
            }
        },
        _playSoundTing() {
            if (this.isSound) {
                let audio = new Audio("/sound/ting1s.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
            }
        },
        _playSoundGoodLuck() {
            if (this.isSound) {
                let audio = new Audio("/sound/good_luck.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
            }
        },
        _playSoundCongratulation() {
            let audio = new Audio("/sound/congratulations.mp3");
            let promise = audio.play();
            if (promise) {
                promise.catch(function () { });
            }
        },
        _playSoundBankerWin() {
            let audio = new Audio("/sound/banker_win.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
        },
        _playSoundPlayerWin() {
            let audio = new Audio("/sound/player_win.mp3");
            let promise = audio.play();
            if (promise) {
                promise.catch(function () { });
            }
        },
        _playSoundOpenCard() {
            let audio = new Audio("/sound/open_card.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
        },
        _playSoundTakeCard() {
            
            let audio = new Audio("/sound/take_card.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
        },
        _playSoundTieGame() {
            let audio = new Audio("/sound/tie_game.mp3");
            let promise = audio.play();
            if (promise) {
                promise.catch(function () { });
            }
        },
        _playSoundTigerWin() {
            let audio = new Audio("/sound/Tiger_Win.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
        },
        _playSoundDorgerWin() {
            let audio = new Audio("/sound/Dragon_win.mp3");
                let promise = audio.play();
                if (promise) {
                    promise.catch(function () { });
                }
        },
     
        dateBySearchType(type) {
            let data = {
                startDate: '',
                endDate: ''
            }
            if (type == "all") {
                data = {
                    startDate: '',
                    endDate: ''
                }
            }
            else if (type == "today") {
                let _today = moment(new Date()).format("YYYY-MM-D");
                data = {
                    startDate: _today,
                    endDate: _today
                }
            }
            else if (type == "last_day") {
                let _yesterday = moment().subtract(1, "days").format("YYYY-MM-D");
                data = {
                    startDate: _yesterday,
                    endDate: _yesterday
                }
            }
            else if (type == "this_week") {
                let startOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
                let endOfWeek = moment().endOf("isoWeek").format("YYYY-MM-DD");
                data = {
                    startDate: startOfWeek,
                    endDate: endOfWeek
                }
            }
            else if (type == "last_week") {
                let startOfLastWeek = moment()
                    .subtract(1, "weeks")
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD");
                let endOfLastWeek = moment()
                    .subtract(1, "weeks")
                    .endOf("isoWeek")
                    .format("YYYY-MM-DD");
                data = {
                    startDate: startOfLastWeek,
                    endDate: endOfLastWeek
                }
            }
            else if (type == "this_month") {
                let startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
                let endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
                data = {
                    startDate: startOfMonth,
                    endDate: endOfMonth
                }
            }
            else if (type == "last_month") {
                let startOfLastMonth = moment()
                    .subtract(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD");
                let endOfLastMonth = moment()
                    .subtract(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD");
                data = {
                    startDate: startOfLastMonth,
                    endDate: endOfLastMonth
                }
            }
            else {
                data = {
                    startDate: null,
                    endDate: null
                }
            }
            return data;
        },
        calculateRequestReportKeyword(ct){
            // console.log(ct);
            switch (true) {
                case (ct == 1 || ct == 2 || ct == 3 || ct == 4 || ct == 15 || ct == 16 ):
                    return "report/cock/?module_bet_id=";
                case (ct == 5):
                    return "report/klaklouk/?module_bet_id=";
                case (ct == 6 || ct == 7 || ct==18):
                    return "report/baccarat/?module_bet_id=";
                case (ct == 8 || ct ==9 || ct==19):
                    return "report/dragon/?module_bet_id=";
                case (ct == 10):
                    return "report/lotto/?module_bet_id=";
                case (ct == 11):
                    return "report/yuki/?module_bet_id=";
                case (ct == 12 || ct == 17):
                    return "report/abong/?module_bet_id=";
                case ct == 13:
                    return "report/fantan/?module_bet_id=";
                case ct == 14:
                    return "report/taisiev/?module_bet_id=";
                case (ct == 20):
                    return "report/han2/?module_bet_id=";
                case (ct == 21):
                    return "report/han3/?module_bet_id=";
                case (ct == 90 || ct == 901 ||ct == 902 || ct == '901' || ct == '901' ||ct == '902'):
                    return "m_lotto/report/?module_bet_id=";
                case (ct == 91 || ct == 911 ||ct == 912 || ct == '91' || ct == '911' ||ct == '912'):
                    return "m_yuki/report/?module_bet_id=";
                case (ct == '92' || ct == '921' || ct =='922' || ct == '923' || ct=='924' || ct == '925' || ct == '926' || ct == '927' || ct=='928' ||ct =='929'):
                    return "m_lottery/report/?module_bet_id=";
                case (ct == 93 || ct == 931 || ct ==932 || ct == 933 || ct==934 || ct == 935 || ct == 936 || ct == 937 || ct==938 ||ct ==939):
                    return "m_klaklouk/report/?module_bet_id=";
                case (ct == 94 || ct == 941 || ct ==942 || ct == 943 || ct==944 || ct == 945 || ct == 946 || ct == 947 || ct==948 ||ct ==949):
                    return "m_dragon/report/?module_bet_id=";
                case (ct == 95 || ct == 951 || ct ==952 || ct == 953 || ct==954 || ct == 955 || ct == 956 || ct == 957 || ct==958 ||ct ==959):
                    return "m_baccarat/report/?module_bet_id=";
                case (ct == 96 || ct == 961 || ct ==962 || ct == 963 || ct==964 || ct == 965 || ct == 966 || ct == 967 || ct==968 ||ct ==969):
                    return "m_doden/report/?module_bet_id=";
                case (ct == 97 || ct == 971 || ct ==972 || ct == 973 || ct==974 || ct == 975 || ct == 976 || ct == 977 || ct==978 ||ct ==979):
                    return "m_sicbo/report/?module_bet_id=";
                case (ct == 98 || ct == 981 || ct ==982 || ct == 983 || ct==984 || ct == 985 || ct == 986 || ct == 987 || ct==988 ||ct ==989):
                    return "m_taixiu/report/?module_bet_id=";
                    case ( ct == 89 || ct == 891 || ct == 892 || ct == "89" || ct == "891" || ct == "892"  ):
                        return "m_lottery3/report/?module_bet_id=";
                    case ( ct == 99 || ct == 991 || ct == 992 || ct == "99" || ct == '991' || ct == '992' ):
                        return "m_keno/report/?module_bet_id=";
                default:
                    return '';
            }
        },
        calculateChannelTypeKeyword(ct){
            switch (true) {
                case (ct == 1 || ct == 2 || ct == 3 || ct == 4 || ct == 15 || ct == 16 ):
                    return "CockFight";
                case (ct == 5):
                    return "Klaklouk";
                case (ct == 6 || ct == 7 || ct==18):
                    return "Baccarat";
                case (ct == 8 || ct ==9 || ct==19):
                    return "Dragon";
                case (ct == 10):
                    return "LottoPlus";
                case (ct == 11):
                    return "Yuki";
                case (ct == 12 || ct == 17):
                    return "Apong";
                case ct == 13:
                    return "Fatan";
                case ct == 14:
                    return "Taisiev";
                case (ct == 20):
                    return "han2";
                case (ct == 21):
                    return "han3";
                case (ct == 90 || ct == 901 || ct ==902 || ct == 903 || ct==904 || ct == 905 || ct == 906 || ct == 907 || ct==908 ||ct ==909):
                    return "m_lotto";
                case (ct == 91 || ct == 911 || ct ==912 || ct == 913 || ct==914 || ct == 915 || ct == 916 || ct == 917 || ct==918 ||ct ==919):
                    return "m_yuki";
                case (ct == '92' || ct == '921' || ct =='922' || ct == '923' || ct=='924' || ct == '925' || ct == '926' || ct == '927' || ct=='928' ||ct =='929' ||ct == 92 || ct == 921 || ct == 922 || ct == 923 || ct==924 || ct == 925 || ct == 926 || ct == 927 || ct==928 ||ct ==929):
                    return "m_lottery";
                case (ct == 93 || ct == 931 || ct == 932 || ct == 933 || ct==934 || ct == 935 || ct == 936 || ct == 937 || ct==938 ||ct ==939):
                    return "m_klaklouk";
                case (ct == 94 || ct == 941 || ct == 942 || ct == 943 || ct==944 || ct == 945 || ct == 946 || ct == 947 || ct==948 ||ct ==949):
                    return "m_dragon";
                case (ct == 95 || ct == 951 || ct == 952 || ct == 953 || ct==954 || ct == 955 || ct == 956 || ct == 957 || ct==958 ||ct ==959):
                    return "m_baccarat";
                case (ct == 96 || ct == 961 || ct == 962 || ct == 963 || ct==964 || ct == 965 || ct == 966 || ct == 967 || ct==968 ||ct ==969):
                    return "m_doden";
                case (ct == 97 || ct == 971 || ct == 972 || ct == 973 || ct==974 || ct == 975 || ct == 976 || ct == 977 || ct==978 ||ct ==979):
                    return "m_sicbo";
                case (ct == 98 || ct == 981 || ct == 982 || ct == 983 || ct==984 || ct == 985 || ct == 986 || ct == 987 || ct==988 ||ct ==989):
                    return "m_taixiu";
                case ( ct == 89 || ct == 891 || ct == 892 || ct == '89' || ct == '891' || ct == '892' ):
                    return "m_lottery3";
                case ( ct == 99 || ct == 991 || ct == 992 || ct == '99' || ct == '991' || ct == '992' ):
                    return "m_keno";
                default:
                    return '';
            }
        },
        checkIsMachineGame(ct){
            switch (true) {
                case (ct == 90 || ct == 901 || ct ==902 || ct == 903 || ct==904 || ct == 905 || ct == 906 || ct == 907 || ct==908 
                    ||ct ==909 || ct == 91 || ct == 911 || ct ==912 || ct == 913 || ct==914 || ct == 915 || ct == 916 || ct == 917 
                    || ct==918 ||ct ==919 
                    || ct == 92 || ct == 921 || ct ==922 || ct == 923 || ct==924 || ct == 925 || ct == 926 || ct == 927 || ct==928 ||ct ==929 
                    || ct == '92' || ct == '921' || ct =='922' || ct == '923' || ct=='924' || ct == '925' || ct == '926' || ct == '927' || ct=='928' ||ct =='929' 
                    || ct == 93 || ct == 931 || ct ==932 || ct == 933 || ct==934 || ct == 935 
                    || ct == 936 || ct == 937 || ct==938 ||ct ==939 || ct == 94 || ct == 941 || ct ==942 || ct == 943 || ct==944 
                    || ct == 945 || ct == 946 || ct == 947 || ct==948 ||ct ==949 || ct == 95 || ct == 951 || ct ==952 || ct == 953 
                    || ct==954 || ct == 955 || ct == 956 || ct == 957 || ct==958 ||ct ==959 || ct == 96 || ct == 961 || ct ==962 
                    || ct == 963 || ct==964 || ct == 965 || ct == 966 || ct == 967 || ct==968 ||ct ==969  || ct == 97 || ct == 971 
                    || ct ==972 || ct == 973 || ct==974 || ct == 975 || ct == 976 || ct == 977 || ct==978 ||ct ==979 || ct == 98 
                    || ct == 981 || ct ==982 || ct == 983 || ct==984 || ct == 985 || ct == 986 || ct == 987 || ct==988 ||ct ==989 
                    || ct == 89 || ct == 891 || ct == 892 || ct == 99 || ct == 991 || ct == 992 
                    || ct == '89' || ct == '891' || ct == '892' || ct == '99' || ct == '991' || ct == '992'):
                    return true;
                default:
                    return false;
            }
        },
        calculateSwitchChannel(ct){
            // switch (true) {
            //     case (ct == 93 || ct == 94 || ct == 95 || ct == 96 || ct == 97 || ct == 98 ):
            //         return 1;
            //     default:
            //         return ct;
            // }
            return ct;
        },
        getChannelTypeName(ct) {
            switch (true) {
                case (ct == 1 || ct == 2 || ct == 3 || ct == 4 || ct == 15 || ct == 16 ):
                    return "🐔 "+ct;
                case (ct == 5):
                    return "🦀 1";
                case (ct == 6):
                    return "🃏 1";
                case (ct == 7):
                    return "🃏 2";
                case (ct==18):
                    return "🃏 3";
                case (ct == 8):
                    return "🐲 1";
                case (ct ==9):
                    return "🐲 2";
                case (ct == 19):
                    return "🐲 3";
                case (ct == 10):
                    return "🎱 1";
                case (ct == 11):
                    return "☯️ 1";
                case (ct == 12):
                    return "🎲 1";
                case (ct == 17):
                    return "🎲 2";
                case ct == 13:
                    return "⚃ 1";
                case ct == 14:
                    return "⚅ 1";
                case ct == 15:
                    return "🐔 5";
                case ct == 16:
                    return "🐔 6";
                case (ct == 20):
                    return "4️⃣ 2";
                case (ct == 21):
                    return "4️⃣ 3";
                case ct == 90:
                    return "🎱 1";
                case ct == 901:
                    return "🎱 2";
                case ct == 902:
                    return "🎱 3";
                case (ct == 91):
                    return "🐯🐂 1";
                case (ct == 911):
                    return "🐯🐂 2";
                case (ct == 912):
                    return "🐯🐂 3";
                case (ct == 92):
                    return "🧿 1";
                case (ct == 921):
                    return "🧿 2";
                case (ct == 922):
                    return "🧿 3";

                case (ct == 93):
                    return "Slot (🦀) 1";
                case (ct == 931):
                    return "Slot (🦀) 2";
                case (ct == 932):
                    return "Slot (🦀) 3";
                case (ct == 933):
                    return "Slot (🦀) 4";
                case (ct == 934):
                    return "Slot (🦀) 5";
                case (ct == 935):
                    return "Slot (🦀) 6";
                case (ct == 936):
                    return "Slot (🦀) 7";
                case (ct == 937):
                    return "Slot (🦀) 8";
                case (ct == 938):
                    return "Slot (🦀) 9";
                case (ct == 939):
                    return "Slot (🦀) 10";
                case (ct == 94):
                    return "Slot (🐲) 1";
                case (ct == 941):
                    return "Slot (🐲) 2";
                case (ct == 942):
                    return "Slot (🐲) 3";
                case (ct == 943):
                    return "Slot (🐲) 4";
                case (ct == 944):
                    return "Slot (🐲) 5";
                case (ct == 945):
                    return "Slot (🐲) 6";
                case (ct == 946):
                    return "Slot (🐲) 7";
                case (ct == 947):
                    return "Slot (🐲) 8";
                case (ct == 948):
                    return "Slot (🐲) 9";
                case (ct == 949):
                    return "Slot (🐲) 10";
                case (ct == 95):
                    return "Slot (🃁) 1";
                case (ct == 951):
                    return "Slot (🃁) 2";
                case (ct == 952):
                    return "Slot (🃁) 3";
                case (ct == 953):
                    return "Slot (🃁) 4";
                case (ct == 954):
                    return "Slot (🃁) 5";
                case (ct == 955):
                    return "Slot (🃁) 6";
                case (ct == 956):
                    return "Slot (🃁) 7";
                case (ct == 957):
                    return "Slot (🃁) 8";
                case (ct == 958):
                    return "Slot (🃁) 9";
                case (ct == 959):
                    return "Slot (🃁) 10";
                case (ct == 96):
                    return "Slot (♥️♣️) 1";
                case (ct == 961):
                    return "Slot (♥️♣️) 2";
                case (ct == 962):
                    return "Slot (♥️♣️) 3";
                case (ct == 963):
                    return "Slot (♥️♣️) 4";
                case (ct == 964):
                    return "Slot (♥️♣️) 5";
                case (ct == 965):
                    return "Slot (♥️♣️) 6";
                case (ct == 966):
                    return "Slot (♥️♣️) 7";
                case (ct == 967):
                    return "Slot (♥️♣️) 8";
                case (ct == 968):
                    return "Slot (♥️♣️) 9";
                case (ct == 969):
                    return "Slot (♥️♣️) 10";
                case (ct == 97):
                    return "Slot (🎲) 1";
                case (ct == 971):
                    return "Slot (🎲) 2";
                case (ct == 972):
                    return "Slot (🎲) 3";
                case (ct == 973):
                    return "Slot (🎲) 4";
                case (ct == 974):
                    return "Slot (🎲) 5";
                case (ct == 975):
                    return "Slot (🎲) 6";
                case (ct == 976):
                    return "Slot (🎲) 7";
                case (ct == 977):
                    return "Slot (🎲) 8";
                case (ct == 978):
                    return "Slot (🎲) 9";
                case (ct == 979):
                    return "Slot (🎲) 10";
                case (ct == 98):
                    return "Slot (🎴) 1";
                case (ct == 981):
                    return "Slot (🎴) 2";
                case (ct == 982):
                    return "Slot (🎴) 3";
                case (ct == 983):
                    return "Slot (🎴) 4";
                case (ct == 984):
                    return "Slot (🎴) 5";
                case (ct == 985):
                    return "Slot (🎴) 6";
                case (ct == 986):
                    return "Slot (🎴) 7";
                case (ct == 987):
                    return "Slot (🎴) 8";
                case (ct == 988):
                    return "Slot (🎴) 9";
                case (ct == 89):
                    return "🎱🎱🎱 1";
                case (ct == 891):
                    return "🎱🎱🎱 2";
                case (ct == 892):
                    return "🎱🎱🎱 3";
                    case (ct == 99):
                        return "KENO 160s";
                    case (ct == 991):
                        return "KENO 100s";
                    case (ct == 992):
                        return "KENO 60s";
                default:
                    return ct;
            }
            
          },
        findImageOfEachKlaklouk(animalName) {
            switch (animalName) {
                case "ក្តាន់":
                  return "/images/a_each_game/klaklok/0001.png";
                case "ឃ្លោក":
                    return "/images/a_each_game/klaklok/0002.png";
                case "មាន់":
                    return "/images/a_each_game/klaklok/0003.png";
                case "បង្កង":
                    return "/images/a_each_game/klaklok/0004.png";
                case "ក្ដាម":
                    return "/images/a_each_game/klaklok/0005.png";
                case "ត្រី":
                    return "/images/a_each_game/klaklok/0006.png";
                case "ខ្លា":
                  return "/images/a_each_game/klaklok/0001.png";
                default:
                    return ''
            // code block
            }
        },
        FindCoinOnGameContent(betAmount){
            switch (true) {
                case (betAmount == 1):
                    return require('../../public/images/a_game_access_global/coins/coin_on_game_content/coin 1.png');
                case (betAmount == 5):
                    return require('../../public/images/a_game_access_global/coins/coin_on_game_content/coin 5.png');
                case (betAmount == 20):
                    return require('../../public/images/a_game_access_global/coins/coin_on_game_content/coin 20.png');
                case (betAmount == 50):
                    return require('../../public/images/a_game_access_global/coins/coin_on_game_content/coin 50.png');
                case (betAmount == 100):
                    return require('../../public/images/a_game_access_global/coins/coin_on_game_content/coin 100.png');
                case (betAmount == 200):
                        return require('../../public/images/a_game_access_global/coins/coin_on_game_content/coin 100.png');
                default:
                    return false;
            }
        },
      
        getGameName(channelType) {
            switch (true) {
                case (channelType == '1' || channelType == 2 || channelType == 3 || channelType == 4|| channelType == 15|| channelType == 16):
                    return 'COCK FIGHT(' + channelType + ')'
                case channelType == 5:
                    return 'KLA KLOUK'
                case channelType == 6:
                    return 'BACCARAT(1)'
                case channelType == 7:
                    return 'BACCARAT(2)'
                case (channelType == 8):
                    return 'DRAGON TIGER(1)'
                case (channelType == 9):
                    return 'DRAGON TIGER(2)'
                case channelType == 10:
                    return 'LOTTO'
                case channelType == 11:
                    return 'YUKI'
                case (channelType == 12 || channelType == 17):
                    return 'APONG'
                case channelType == 13:
                    return 'FAN TAN'
                case channelType == 14:
                    return 'TAI SIEV';
                case (channelType == 19):
                    return 'DRAGON TIGER(3)'
                case (channelType == 18):
                    return 'BACCARAT(3)'
                case (channelType == 20):
                    return 'Han 2'
                case (channelType == 21):
                    return 'Han 3'
            }
        },
        sameGameFindName(channelType) {
            switch (true) {
                case (channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4):
                    return 'title.c_'
                case (channelType == 15):
                    return 'title.bac4_'
                case (channelType == 16):
                    return 'title.roulette_'
                default:
                    return 'title.'
            }
        },
        _sameGameCheckTieColorStyle(channelType) {
            switch (true) {
                case (channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4):
                    return 'tie-section'
                case (channelType == 15):
                    return 'tie-section'
                case (channelType == 16):
                    return 'black-section'
            }
        },
        _sameGameCheckBlueColorStyle(channelType) {
            switch (true) {
                case (channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 ):
                    return 'wala-section';
                case (channelType == 15):
                    return 'wala-section'
                case (channelType == 16):
                    return 'black-section'
            }
        },
        _sameGameCheckPayout(channelType, displayFor, payout) {
            switch (true) {
                case (channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4):
                    return payout
                case (channelType == 15):
                    return payout
                case (channelType == 16 && displayFor == 'meron'):
                    return payout
                case (channelType == 16 && displayFor == 'wala'):
                    return payout
                case (channelType == 16 && displayFor == 'tie'):
                    return '26'
            }
        },
        detectTrendColor(channelType, displayFor) {
            switch (true) {
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 16 || channelType == 18 || channelType == 19) && displayFor == 'meron'):
                    return 'trend-meron'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15       || channelType == 18 || channelType == 19) && displayFor == 'wala'):
                    return 'trend-wala'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && displayFor == 'tie'):
                    return 'trend-draw'
                case (channelType == 16 && displayFor == 'wala'):
                    return 'trend-black'
                case (channelType == 16 && displayFor == 'tie'):
                    return 'trend-draw'
            }
        },
        _resultColor(channelType, color) {
            switch (true) {
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 16 || channelType == 18 || channelType == 19) && color == 'cir-red'):
                    return 'trend-meron'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && color == 'cir-blue'):
                    return 'trend-wala'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && color == 'cir-green'):
                    return 'trend-draw'
                case (channelType == 16 && color == 'cir-blue'):
                    return 'trend-black'
                case (channelType == 16 && color == 'cir-green'):
                    return 'trend-draw'
                case color == 'cir-cancel':
                    return 'trend-cancel'
            }
        },
        _bettingColor(channelType, color) {
            switch (true) {
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 16 || channelType == 18 || channelType == 19) && color == 'cir-red'):
                    return 'cir-red'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && color == 'cir-blue'):
                    return 'cir-blue'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && color == 'cir-green'):
                    return 'cir-green'
                case (channelType == 16 && color == 'cir-blue'):
                    return 'cir-black'
                case (channelType == 16 && color == 'cir-green'):
                    return 'cir-green'
                case color == 'cir-cancel':
                    return 'trend-cancel'
            }
        },
        _klakloukNameToimg(name){
            switch (true) {
                case (name == 'Tiger' || name == 'ខ្លា'):
                    return require('../../public/images/a_each_game/klaklok/0007.png')
                case (name == 'Cock' || name == 'មាន់'):
                    return require('../../public/images/a_each_game/klaklok/0003.png')
                case (name == 'Groud' || name == 'ឃ្លោក'):
                    return require('../../public/images/a_each_game/klaklok/0002.png')
                case (name == 'Lobster' || name == 'បង្គង'):
                    return require('../../public/images/a_each_game/klaklok/0004.png')
                case (name == 'Crab' || name == 'ក្ដាម'):
                    return require('../../public/images/a_each_game/klaklok/0005.png')
                case (name == 'Fish' || name == 'ត្រី'):
                    return require('../../public/images/a_each_game/klaklok/0006.png')
                case (name == 'CANCEL' || name == 'Cancel'):
                        return require('../../public/images/a_each_game/klaklok/0010.png')
                default: 
                    return name
            }
        },
        calColor(data){
            switch(data){
              case 'cir-blue' : return 'blue';
              case 'cir-red' : return 'red';
              case 'cir-green' : return 'green';
              case 'cir-cancel' : return 'grey';
              case 'cir-black' : return 'black';
            }
    }}
};
