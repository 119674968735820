<template>
    <v-col cols="12" class="lottery3d-content-right-result">
        <div class="lottery3d-content-box">
            <div class="lottery3d-content-box-title">
                <span>{{ $t('title.lottery_result') }}</span>
            </div>
            <v-tabs background-color="#0437b4" dark style="text-align: center" align-tabs="center" class="w-100">
                <v-tabs-slider color="#e02033" style="height:7px !important"></v-tabs-slider>
                <v-tab style=" font-weight:600; font-size:1.3em" class="w-50 pt-1">
                    {{ $t("title.2digit") }}
                </v-tab>
                <v-tab style=" font-weight:600; font-size:1.3em" class="w-50 pt-1">
                    {{ $t("title.3digit") }}
                </v-tab>
                <v-tab-item>
                    <div style="height:340px; overflow-y:scroll" class="pr-0">
                        <div class="lottery3d-content-item " v-for="(data, index) in lastTwentyResults" :key="data._id">
                            <div class="lottery3d-content-item-head">
                                <span class="list-value">{{ formatDateId(data) }}</span>
                            </div>
                            <div class="lottery3d-content-row-col mb-1 pb-2 mx-auto" v-if="index == 0">
                                <div v-if="data.result_a" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#f4c905' : 'background:#feffff'"
                                        v-for="(a, index) in data.result_a.name" :key="a">
                                        <span>{{ a.slice(-2) }}</span>
                                    </div>
                                </div>
                                <div v-if="data.result_b" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#1dcb26' : 'background:#feffff'"
                                        v-for="(b, index) in data.result_b.name" :key="b">
                                        <span>{{ b.slice(-2) }}</span>
                                    </div>
                                </div>
                                <div v-if="data.result_c" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#c705d2' : 'background:#feffff'"
                                        v-for="(c, index) in data.result_c.name" :key="c">
                                        <span>{{ c.slice(-2) }}</span>
                                    </div>
                                </div>
                                <div v-if="data.result_d" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#d50201' : 'background:#feffff'"
                                        v-for="(d, index) in data.result_d.name" :key="d">
                                        <span>{{ d.slice(-2) }}</span>
                                    </div>
                                </div>
                                <div v-if="data.result_e" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#02c3b7' : 'background:#feffff'"
                                        v-for="(e, index) in data.result_e.name" :key="e">
                                        <span>{{ e.slice(-2) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="lottery3d-content" v-else>
                                <div v-if="data.result_a" class="mx-auto ">
                                    <v-expansion-panels class="pa-0">
                                        <v-expansion-panel class="pa-0" style="background-color:#eadcdc">
                                            <v-expansion-panel-header class="pa-0 w-100 mx-auto mt-2">
                                                <div class="w-100" style="min-width: 100%;">
                                                    <v-row class="ma-0 w-100">
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2 "
                                                            :style="'background:#f4c905'">
                                                            <span>{{ data.result_a.name[0] ?
                    data.result_a.name[0].slice(-2) :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="'background:#1dcb26'">
                                                            <span>{{ data.result_b.name[0] ?
                    data.result_b.name[0].slice(-2) :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="'background:#c705d2'">
                                                            <span>{{ data.result_c.name[0] ?
                    data.result_c.name[0].slice(-2) :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2 "
                                                            :style="'background:#d50201'">
                                                            <span>{{ data.result_d.name[0] ?
                    data.result_d.name[0].slice(-2) :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="'background:#02c3b7'">
                                                            <span>{{ data.result_e.name[0] ?
                    data.result_e.name[0].slice(-2) :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                    </v-row>
                                                    <div class="w-100 text-center py-2"
                                                        style="color:#0437b4; font-weight:500">
                                                        See More >
                                                    </div>
                                                </div>

                                                <template v-slot:actions>
                                                    <v-icon color="primary">
                                                    </v-icon>
                                                </template>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="background-color:#eadcdc">
                                                <v-row class="ma-0 w-100 mt-2">
                                                    <div v-if="data.result_a" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(a, index) in data.result_a.name" :key="index">
                                                            <span v-if="index != 0">{{ a.slice(-2) }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.result_b" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index2 == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(b, index2) in data.result_b.name" :key="b">
                                                            <span v-if="index2 != 0">{{ b.slice(-2) }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.result_c" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index3 == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(c, index3) in data.result_c.name" :key="c">
                                                            <span v-if="index3 != 0">{{ c.slice(-2) }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.result_d" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index4 == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(d, index4) in data.result_d.name" :key="d">
                                                            <span v-if="index4 != 0">{{ d.slice(-2) }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.result_e" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index5 == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(e, index5) in data.result_e.name" :key="e">
                                                            <span v-if="index5 != 0">{{ e.slice(-2) }}</span>
                                                        </div>
                                                    </div>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div style="height:340px; overflow-y:scroll" class="pr-0">
                        <div class="lottery3d-content-item " v-for="(data, index) in lastTwentyResults" :key="data._id">
                            <div class="lottery3d-content-item-head">
                                <span class="list-value">{{ formatDateId(data) }}</span>
                            </div>
                            <div class="lottery3d-content-row-col mb-1 pb-2 mx-auto" v-if="index == 0">
                                <div v-if="data.result_a" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#f4c905' : 'background:#feffff'"
                                        v-for="(a, index) in data.result_a.name" :key="a">
                                        <span>{{ a }}</span>
                                    </div>
                                </div>
                                <div v-if="data.result_b" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#1dcb26' : 'background:#feffff'"
                                        v-for="(b, index) in data.result_b.name" :key="b">
                                        <span>{{ b }}</span>
                                    </div>
                                </div>
                                <div v-if="data.result_c" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#c705d2' : 'background:#feffff'"
                                        v-for="(c, index) in data.result_c.name" :key="c">
                                        <span>{{ c }}</span>
                                    </div>
                                </div>
                                <div v-if="data.result_d" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#d50201' : 'background:#feffff'"
                                        v-for="(d, index) in data.result_d.name" :key="d">
                                        <span>{{ d }}</span>
                                    </div>
                                </div>
                                <div v-if="data.result_e" class="mx-auto">
                                    <div class="lottery3d-content-col mb-2"
                                        :style="index == 0 ? 'background:#02c3b7' : 'background:#feffff'"
                                        v-for="(e, index) in data.result_e.name" :key="e">
                                        <span>{{ e }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="lottery3d-content" v-else>
                                <div v-if="data.result_a" class="mx-auto ">
                                    <v-expansion-panels class="pa-0">
                                        <v-expansion-panel class="pa-0" style="background-color:#eadcdc">
                                            <v-expansion-panel-header class="pa-0 w-100 mx-auto mt-2">
                                                <div class="w-100" style="min-width: 100%;">
                                                    <v-row class="ma-0 w-100">
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2 "
                                                            :style="'background:#f4c905'">
                                                            <span>{{ data.result_a.name[0] ? data.result_a.name[0] :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="'background:#1dcb26'">
                                                            <span>{{ data.result_b.name[0] ? data.result_b.name[0] :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="'background:#c705d2'">
                                                            <span>{{ data.result_c.name[0] ? data.result_c.name[0] :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2 "
                                                            :style="'background:#d50201'">
                                                            <span>{{ data.result_d.name[0] ? data.result_d.name[0] :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="'background:#02c3b7'">
                                                            <span>{{ data.result_e.name[0] ? data.result_e.name[0] :
                    "???"
                                                                }}</span>
                                                        </div>
                                                        <v-spacer></v-spacer>
                                                    </v-row>
                                                    <div class="w-100 text-center py-2"
                                                        style="color:#0437b4; font-weight:500">
                                                        See More >
                                                    </div>
                                                </div>

                                                <template v-slot:actions>
                                                    <v-icon color="primary">
                                                    </v-icon>
                                                </template>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="background-color:#eadcdc">
                                                <v-row class="ma-0 w-100 mt-2">
                                                    <div v-if="data.result_a" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(a, index) in data.result_a.name" :key="index">
                                                            <span v-if="index != 0">{{ a }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.result_b" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index2 == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(b, index2) in data.result_b.name" :key="b">
                                                            <span v-if="index2 != 0">{{ b }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.result_c" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index3 == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(c, index3) in data.result_c.name" :key="c">
                                                            <span v-if="index3 != 0">{{ c }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.result_d" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index4 == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(d, index4) in data.result_d.name" :key="d">
                                                            <span v-if="index4 != 0">{{ d }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="data.result_e" class="mx-auto">
                                                        <div class="lottery3d-content-col mb-2"
                                                            :style="index5 == 0 ? 'display:none' : 'background:#feffff'"
                                                            v-for="(e, index5) in data.result_e.name" :key="e">
                                                            <span v-if="index5 != 0">{{ e }}</span>
                                                        </div>
                                                    </div>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs>
        </div>
    </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default ({
    computed: {
        ...mapGetters("$_lottery_vn", {
            lastTwentyResults: "getLastTwentyResult",
        }),
    },
    methods: {
        formatDateId(data) {
            return moment(data.date).format("YYYYMMDD") + "#" + data.no
        }
    }

})
</script>