var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"ma-0 w-100"},[_c('div',{staticClass:"machine-baccarat-three-section flex-grow-1",staticStyle:{"width":"100% !important","max-width":"100% !important"}},[_c('div',{staticClass:"machine-baccrat-button machine-red-title machine-play-red-bg",class:_vm.getSelectedFantanBtn && _vm.getSelectedFantanBtn.button && _vm.getSelectedFantanBtn.button.name == _vm.passBetType[2].name 
        && _vm.getSelectedFantanBtn.channelType == _vm.passChannelType ? 'machine-game-active' : '',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[2])}}},[_c('p',{staticClass:"machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button"},[_vm._v(" "+_vm._s(_vm.passBetType[2] ? _vm.passBetType[2].payout : '1.95')+" "),_c('br'),_c('span',{staticClass:"machine-button-name machine-button-font fantan-red"},[_vm._v(_vm._s(_vm.passBetType[2] ? _vm.passBetType[2].name.toUpperCase() : 'NA'))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6",staticStyle:{"margin-right":"0","padding-right":"2px"}},[_c('div',{staticClass:"machine-baccrat-button reserver-margin machine-play-green-bg",class:_vm.getSelectedFantanBtn && _vm.getSelectedFantanBtn.button && _vm.getSelectedFantanBtn.button.name == _vm.passBetType[12].name 
            && _vm.getSelectedFantanBtn.channelType == _vm.passChannelType ? 'machine-game-active' : '',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[12])}}},[_c('p',{staticClass:"machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button"},[_vm._v(" "+_vm._s(_vm.passBetType[12] && _vm.passBetType[12].name.toUpperCase() === '1' ? 'FAN 1' : 'NA')+" "),_c('br'),_c('span',{staticClass:"machine-button-name machine-button-font"},[_vm._v(" "+_vm._s(_vm.passBetType[12] && _vm.passBetType[12].payout === '4' ? '1:4' : '1:4')+" ")])])])]),_c('div',{staticClass:"col-6",staticStyle:{"margin-left":"0","padding-left":"2px"}},[_c('div',{staticClass:"machine-baccrat-button reserver-margin machine-play-green-bg",class:_vm.getSelectedFantanBtn && _vm.getSelectedFantanBtn.button && _vm.getSelectedFantanBtn.button.name == _vm.passBetType[13].name 
            && _vm.getSelectedFantanBtn.channelType == _vm.passChannelType ? 'machine-game-active' : '',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[13])}}},[_c('p',{staticClass:"machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button"},[_vm._v(" "+_vm._s(_vm.passBetType[13] && _vm.passBetType[13].name.toUpperCase() === '2' ? 'FAN 2' : 'NA')+" "),_c('br'),_c('span',{staticClass:"machine-button-name machine-button-font"},[_vm._v(" "+_vm._s(_vm.passBetType[13] && _vm.passBetType[13].payout === '4' ? '1:4' : '1:4')+" ")])])])])])]),_c('div',{staticStyle:{"width":"10px"}}),_c('div',{staticClass:"machine-baccarat-three-section machine-blue-title flex-grow-1",staticStyle:{"width":"100% !important","max-width":"100% !important"}},[_c('div',{staticClass:"machine-baccrat-button machine-play-blue-bg",class:_vm.getSelectedFantanBtn && _vm.getSelectedFantanBtn.button && _vm.getSelectedFantanBtn.button.name == _vm.passBetType[3].name 
        && _vm.getSelectedFantanBtn.channelType == _vm.passChannelType ? 'machine-game-active' : '',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[3])}}},[_c('p',{staticClass:"machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button"},[_vm._v(" "+_vm._s(_vm.passBetType[3] ? _vm.passBetType[3].payout : '1.95')+" "),_c('br'),_c('span',{staticClass:"machine-button-name machine-button-font fantan-blue"},[_vm._v(_vm._s(_vm.passBetType[3] ? _vm.passBetType[3].name.toUpperCase() : 'NA'))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6",staticStyle:{"margin-right":"0","padding-right":"2px"}},[_c('div',{staticClass:"machine-baccrat-button reserver-margin machine-play-green-bg",class:_vm.getSelectedFantanBtn && _vm.getSelectedFantanBtn.button && _vm.getSelectedFantanBtn.button.name == _vm.passBetType[14].name 
            && _vm.getSelectedFantanBtn.channelType == _vm.passChannelType ? 'machine-game-active' : '',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[14])}}},[_c('p',{staticClass:"machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button"},[_vm._v(" "+_vm._s(_vm.passBetType[14] && _vm.passBetType[14].name.toUpperCase() === '3' ? 'FAN 3' : 'NA')+" "),_c('br'),_c('span',{staticClass:"machine-button-name machine-button-font"},[_vm._v(" "+_vm._s(_vm.passBetType[14] && _vm.passBetType[14].payout === '4' ? '1:4' : '1:4')+" ")])])])]),_c('div',{staticClass:"col-6",staticStyle:{"margin-left":"0","padding-left":"2px"}},[_c('div',{staticClass:"machine-baccrat-button reserver-margin machine-play-green-bg",class:_vm.getSelectedFantanBtn && _vm.getSelectedFantanBtn.button && _vm.getSelectedFantanBtn.button.name == _vm.passBetType[15].name 
            && _vm.getSelectedFantanBtn.channelType == _vm.passChannelType ? 'machine-game-active' : '',on:{"click":function($event){return _vm._updatedSelectedBtn(_vm.passBetType[15])}}},[_c('p',{staticClass:"machine-payout-name machine-button-font margin-fantan-button-name fantan-top-button"},[_vm._v(" "+_vm._s(_vm.passBetType[15] && _vm.passBetType[15].name.toUpperCase() === '4' ? 'FAN 4' : 'NA')+" ")]),_c('p',{staticClass:"machine-button-name machine-button-font"},[_vm._v(" "+_vm._s(_vm.passBetType[15] && _vm.passBetType[15].payout === '4' ? '1:4' : '1:4')+" ")])])])])])]),_c('CockXCasinoSubmitButton',{attrs:{"passChannelType":_vm.passChannelType,"selectedSubmit":'selectedSubmitFantan'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }