<template>
  <div>
    <div>
      <div class="lottery3d-title-current">
        {{ $t("title.lo_previous_betting") }}
      </div>
      <h6 class="sub-title-lottery3d pr-1 pl-2" style="color: #212427" v-if="previousBetting &&
          previousBetting.length &&
          previousBetting.length > 0
          ">
        {{
          previousBetting
            ? moment(previousBetting.time).format("YYYYMMDD") +
            "#" +
            previousBetting[0].no
            : "loading..."
        }}
        <span class="float-right">
          <button class="btn-link link print-all" @click="openPrintMultiple()">
            {{ $t("title.ticket") }}
          </button>
        </span>
      </h6>
    </div>
    <div class="previous-betting-lottery-vn-list">
      <table v-if="previousBetting.length > 0" class="tb-lottery3d-bet">
        <tr v-for="(item, i) in previousBetting" :key="i" class="ng-star-inserted current-betting-lottery3d-tr">
          <td>
            <div class="current-betting-lottery3d-view ">
              <div class="current-betting-element">
                {{ item && item.total_amount ? (item.total_amount).toLocaleString() : '' }} {{
          _displayCurrencySymbol(userInfor.account_currency) }}
              </div>
              <div class="current-betting-element my-text-g-left">
                <button class="btn-link link" @click="_goToDetail(item)">

                  <span class="fontKhmer">{{ $t("button.detail") }}</span>
                </button>
              </div>
              <div class="current-betting-element text-right">
                <button class="btn-link link" @click="printTicket(item, item.invoice_no)">

                  <span class="fontKhmer">{{ $t("title.ticket") }}</span>
                </button>
              </div>
              <div class="current-betting-element-colspan xx-detail xx-detail-hide my-text-g-left" :class="parseInt(item.total_amount_win) > 0 ? 'win-bgcolor' :'lose-bgcolor'">
                <ul>
                  <li>{{ $t("print.invoice_no") }}:<span class="list-value">{{ item._id }}</span></li>
                  <li>{{ $t("invoice.game_id") }}: <span class="list-value">{{ item.no }}</span> </li>
                  <li>{{ $t("title.outstanding") }}:<span class="list-value"> {{ item && item.total_amount ?
          (item.total_amount).toLocaleString() + _displayCurrencySymbol(userInfor.account_currency) : ''
                      }}</span></li>
                  <li>{{ $t("title.total_amount_win") }}: <span class="list-value">{{ item && item.total_amount_win ?
          (item.total_amount_win).toLocaleString() : '' }}</span> </li>
                  <li>{{ $t("invoice.date") }}: <span class="list-value">{{ item.time }}</span></li>
                  <li>{{ $t("title.post_channel") }}: <span class="list-value">
                      <span v-if="item.bet_a.number.length > 0">A</span>
                      <span v-if="item.bet_b.number.length > 0"> B</span>
                      <span v-if="item.bet_c.number.length > 0"> C</span>
                      <span v-if="item.bet_d.number.length > 0"> D</span>
                      <span v-if="item.bet_e.number.length > 0"> E</span>
                      <span v-if="item.bet_lo.number.length > 0"> E</span>
                    </span>
                  </li>
                  <li v-if="item.bet_a.number.length > 0">{{ $t("invoice.bet_point") }}:
                    <span class="list-value">
                      {{ item.bet_a.payout }}
                    </span>
                  </li>
                  <li v-else-if="item.bet_b.number.length > 0">{{ $t("invoice.bet_point") }}:
                    <span class="list-value">
                      {{ item.bet_b.payout }}
                    </span>
                  </li>
                  <li v-else-if="item.bet_c.number.length > 0">{{ $t("invoice.bet_point") }}:
                    <span class="list-value">
                      {{ item.bet_c.payout }}
                    </span>
                  </li>
                  <li v-else-if="item.bet_d.number.length > 0">{{ $t("invoice.bet_point") }}:
                    <span class="list-value">
                      {{ item.bet_d.payout }}
                    </span>
                  </li>
                  <li v-else-if="item.bet_e.number.length > 0">{{ $t("invoice.bet_point") }}:
                    <span class="list-value">
                      {{ item.bet_e.payout }}
                    </span>
                  </li>
                  <li v-else-if="item.bet_lo.number.length > 0">{{ $t("invoice.bet_point") }}:
                    <span class="list-value">
                      {{ item.bet_lo.payout }}
                    </span>
                  </li>
                </ul>

              </div>
            </div>
          </td>
        </tr>
      </table>
      <div v-else class=" mx-auto justify-center no-data-statement">
                <img :src="require('~public_image/a_page_access_global/no-data-icon copy.png')" style="width:70px!important;" height="80px"  alt="" />
                <p class="pr-3 pt-2">{{ $t('title.no_data') }}</p>
            </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Mixin from "~mixin";
export default {
  mixins: [Mixin],
  // DEFINE local VARIABLE
  data() {
    return {

      previousGame: {},
      current: {},
      dialogPrintTicket: false,
      dialogPrintTicketMulti: false,
      format: "YYYY-MM-DD",
      selectedAddOns: [],
      items: [],
      ticket_id: "",
      drawTicketLine: "--------------------------------------------------------",
      drawTicketLineOnPrint: "------------------------------------------------",
      WebsiteName: "pp77",
      headersDetail: [
        { text: this.$t("title.date_time"), value: 'time' },
        { text: this.$t("title.game"), value: 'no' },
        { text: this.$t("title.bet_type"), value: 'bet_type' },
        { text: this.$t("title.amount"), value: 'amount' },
        { text: this.$t("title.total_cast"), value: 'total_cast' },
        { text: this.$t("title.result"), value: 'result' },
        { text: this.$t("title.result_number"), value: 'result_number' }

      ],
      detailData: [],
      dialog: false,
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      dataRequest: {
        descendingFormat: [true],
        sortByFormat: ["_id"],
        descending: true,
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
      },
      getLoadingDetail: false,
      invoice_id: null,
      betALength: {},
      betBLength: {},
      betCLength: {},
      betDLength: {},
      betELength: {},
      labelA: null,
      labelB: null,
      labelC: null,
      labelD: null,
      labelE: null,
      selectedItem: {}
    }
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_lottery_vn", {
      currentBetting: "getCurrentBetting",
      previousBetting: "getPreviousBetting",
    }),
  },
  methods: {
    moment,
    _goToDetail(data) {
      this.$emit('detailInvoice', data)
      // this.selectedItem = data;
      // this.invoice_id = data.invoice_no;
      // this.loadDetailData(data.invoice_no);
      // this.dialog = true;
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.invoice_id);
      this.getLoadingDetail = false;
    },
    async loadDetailData(_id) {
      this.detailData = [];
      this.getLoadingDetail = true;
      this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);

      const response = await this.$request.getSlot({
        url: 'm_lottery/report/detail?module_bet_id=' + _id
      });
      if (response.data.code) {
        this.detailData = response.data.data;
        this.getLoadingDetail = false;
      }
    },
    printTicket(item, ticket_id) {
      this.items = item;
      this.ticket_id = ticket_id;
      this.dialogPrintTicket = true;
    },
    printTicket2(item) {
      // console.log('@item:',item);
      this.items = item;
      this.betALength = item.bet_a.number.length
      this.betBLength = item.bet_b.number.length
      this.betCLength = item.bet_c.number.length
      this.betDLength = item.bet_d.number.length
      this.betELength = item.bet_e.number.length
      this.labelA = item.bet_a.label
      this.labelB = item.bet_b.label
      this.labelC = item.bet_c.label
      this.labelD = item.bet_d.label
      this.labelE = item.bet_e.label
      this.dialogPrintTicket2 = true;
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    closePrintDialog() {
      this.dialogPrintTicket = false;
    },
    openPrintMultiple() {
      if (this.selectedAddOns != "") {
        this.dialogPrintTicketMulti = true;
      }
    },
    printMulti() {
      this.$htmlToPaper("printMeMultiTicket");
    },
    closePrintMultiDialog() {
      var inputs = document.getElementsByClassName("myCheck");
      for (var i = 0, l = inputs.length; i < l; ++i) {
        if (inputs[i].checked) {
          inputs[i].checked = false;
        }
      }
      this.selectedAddOns = [];
      this.dialogPrintTicketMulti = false;
    },
  },
};
</script>
<style>
.win-bgcolor{
  background-color: #6fd7f775!important;
}
.lose-bgcolor{
  background-color: #f05f5f88!important;
}</style>