<template>
  <div class="game-machine-component-right">
    <DragonTigerButton :passBetType="passBetType"  :passChannelType="channelType" v-if="channelType == 94 || channelType == 941 || channelType == 942 || channelType == 943 || channelType == 944 || channelType == 945 || channelType == 946 || channelType == 947 || channelType == 948 || channelType == 949"/>
    <BaccaratButton :passBetType="passBetType" :passIndex="passIndex" :passChannelType="channelType" v-if="channelType == 95 || channelType == 951 || channelType == 952 || channelType == 953 || channelType == 954 || channelType == 955 || channelType == 956 || channelType == 957 || channelType == 958 || channelType == 959"/>
    <TaixiuButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 98 || channelType == 981 || channelType == 982 || channelType == 983 || channelType == 984"/>
    <DodenButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 96 || channelType == 961 || channelType == 962 || channelType == 963 || channelType == 964"/>
    <SicboButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 97 || channelType == 971 || channelType == 972 || channelType == 973 || channelType == 974"/>
    <KlakloukButton :passBetType="passBetType" :passChannelType="channelType" v-if="channelType == 93 || channelType == 931 || channelType == 932 || channelType == 933 || channelType == 934"/>

    <div class="announce-result" v-if="_checkIfAnnounceResult(passTime)">
      <marquee behavior="" direction="" class="announce-result-run-text">{{ $t('title.waitingResult') }}</marquee>
    </div>
  </div>
</template>
<script>
import DragonTigerButton from "../GameButton/DragonTigerButton.vue";
import BaccaratButton from "../GameButton/BaccaratButton.vue";
import TaixiuButton from "../GameButton/TaixiuButton.vue";
import DodenButton from "../GameButton/DodenButton.vue";
import SicboButton from "../GameButton/SicboButton.vue";
import KlakloukButton from "../GameButton/KlakloukButton.vue";

export default {
  components: {
    DragonTigerButton,
    BaccaratButton,
    TaixiuButton,
    DodenButton,
    KlakloukButton,
    SicboButton
  },
  props:['passGame', 'passBetType', 'channelType', 'passTime', 'passIndex'],
  methods:{
    _checkIfAnnounceResult(passTime){
      if(!passTime || (passTime && passTime.is_open_betting == false )) return true
      else return false
    }
  }
}
</script>
