<template>
    <div class="game-machine-component-mid">
        <div class="game-machine-status-container">
            <v-row class="ma-0">
                <div class="game-machine-status">
                    <p class="machine-status-text" :class="textColorBaseOnChannelStatus( passTime.is_open_betting ? passTime.is_open_betting: chInfo.is_open)">
                      {{ $t(`title.${checkChannelStatus( passTime.is_open_betting ? passTime.is_open_betting : chInfo.is_open)}`)}}
                    </p>
                </div>
                <v-spacer></v-spacer>
                <div class="game-machine-countdown" v-if="passTime.time && passTime.time > 0">
                    <p class="machine-countdown-text">{{ passTime.time }}</p>
                </div>
                <div class="game-machine-countdown" v-else>
                    <p class="machine-countdown-text" style="font-size:0">  
                        <v-progress-circular
                        indeterminate
                        :width="2"
                        style=""
                        class="loading-time"
                        color="amber"
                      ></v-progress-circular></p>
                </div>
            </v-row>
        </div>
        <div class="game-machine-component-mid-logo-result">
            <img  :src="findMachineGameLogo(channelType)" alt=""/>
            <div class="game-info-result mc-cir " 
            v-if="resultObjS && resultObjS.co_type_of_betting"
            :class="resultObjS && resultObjS.co_type_of_betting && resultObjS.co_type_of_betting.color ? 'mc-'+ resultObjS.co_type_of_betting.color :''" >
                <span class="zoom-in-zoom-out">
                    {{ resultObjS.co_type_of_betting.name}}
                </span>
            </div>
        </div>
        <div class="game-machine-component-mid-enter">
            <div @click="_goToSubGame(channelType)">
                <span><v-icon>mdi-play-circle</v-icon> {{$t('title.enter')}}</span>
            </div>
        </div>
    </div>
</template>
<script>

import findImg from "~mixin/MX_FindImageFunctionCollection.js"
import { mapMutations } from "vuex";

export default {
    mixins: [findImg],
    props:['passGame', 'channelType', 'passTime', 'chInfo', "resultObjS"],
    methods:{
        _goToSubGame(channelType){
            let path;
            switch(true){
                case (  channelType == '94'  || channelType == '941' || channelType == '942' ||
                        channelType == '943' || channelType == '944' || channelType == '945' || 
                        channelType == '946' || channelType == '947' || channelType == '948' || 
                        channelType == '949' ):
                    path = '/DRAGON';
                    this.$cookies.set("sbc_channel_type", this.channelType)
                    break;
                case (  channelType == '95'  || channelType == '951' || channelType == '952' ||
                        channelType == '953' || channelType == '954' || channelType == '955' || 
                        channelType == '956' || channelType == '957' || channelType == '958' || 
                        channelType == '959') :
                    path = '/BACCARAT';
                    this.$cookies.set("sbc_channel_type", this.channelType)
                    break;
                case (  channelType == '98'  || channelType == '981' || channelType == '982' ||
                        channelType == '983' || channelType == '984' || channelType == '985' || 
                        channelType == '986' || channelType == '987' || channelType == '988' || 
                        channelType == '989' ):
                    path = '/TAIXIU';
                    this.$cookies.set("sbc_channel_type", this.channelType)
                    break;
                case (  channelType == '96'  || channelType == '961' || channelType == '962' ||
                        channelType == '963' || channelType == '964' || channelType == '965' || 
                        channelType == '966' || channelType == '967' || channelType == '968' || 
                        channelType == '969' ):
                    path = '/DODEN';
                    this.$cookies.set("sbc_channel_type", this.channelType)
                    break;
                case (  channelType == '97'  || channelType == '971' || channelType == '972' ||
                        channelType == '973' || channelType == '974' || channelType == '975' || 
                        channelType == '976' || channelType == '977' || channelType == '978' || 
                        channelType == '979' ):
                    path = '/SICBO';
                    this.$cookies.set("sbc_channel_type", this.channelType)
                    break;
                case (  channelType == '93'  || channelType == '931' || channelType == '932' ||
                        channelType == '933' || channelType == '934' || channelType == '935' || 
                        channelType == '936' || channelType == '937' || channelType == '938' || 
                        channelType == '939' ):
                    path = '/KLAKLOUK';
                    this.$cookies.set("sbc_channel_type", this.channelType)
                    break;
            }
            this.$router.push({ path }).catch(() => {
                /* ignore */
            });
        },
        checkChannelStatus(data) {
            if (data == null) {
                return "Not_Open"
            } else {
                if (data) {
                    return "Open"
                }
                return "Close"
            }
        },
        textColorBaseOnChannelStatus(data) {
            if (data == null) {
                return "text-warning"
            } else {
                if (data) {
                    return "open-betting"
                }
                return "text-danger"
            }
        },
        ...mapMutations("$_modules", [
            "UPDATE_CHANNEL_ID",
            "UPDATE_USER_INFO",
            "UPDATE_NOTI_DATA",
            "CLEAR_BETTING_AMOUNT"
            ]),
}
}
</script>
<style>

.open-betting {
    color: #62da7e;
   
}
.close-betting {
    color: #dc3545;
}
</style>