/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'

let request = new Request;

const fetchLogout = async () => {
	await request.post({
		url: 'logout'
	});
};

const fetchLogIn = async ({}, data) => {
	// commit('IS_LOGGING', true);
	return await request.post({
		url: 'user/login',
		data
	})
	// .then(()=>{
	// 	commit('IS_LOGGING', false);
	// });
	
};

const fetchRegister = async ({}, data) => {
	const response = await request.post({
		url: 'user/register',
		data
	});
	return response;
}

const updateSelectedLoginMenu = async ({commit}, data) => {
	commit('UPDATE_LIST_LOGIN_GAME', data.sub_element);
	commit('UPDATE_SELECTED_LOGIN_MENU', data.id);
}


export default {
	fetchLogout,
	fetchLogIn,
	fetchRegister,
	updateSelectedLoginMenu
};
